.font-family {
    font-family: 'Manrope', sans-serif;
	margin: 2% 5%;
	box-shadow: 0px 10px 20px 0px #ddd;
	padding: 0px 0 30px;
}

.logo {
	max-width: 87px;
    margin-top: -1%;
}
.logo2{
	max-height: 25px;
}
.back {
	font-size: 16px;
	font-weight: 600; 
line-height: 22px;  
letter-spacing: 0.5px;
border-left: 2px solid #FF7700;
color: #000000;
	cursor: pointer;  
	box-shadow: inset 0 0 0 0 #ff7700;
	-webkit-transition: ease-out 0.4s;
	-moz-transition: ease-out 0.4s;
	transition:ease-out 0.4s;
	padding: 3px 20px 7px;
	margin: 0;

	display: inline-block;
	text-align: center;
}
.back span{ 
    font-weight: 900; 
    font-size: 22px;  
}
 .back:hover{
    border-radius: 5px;
	box-shadow: inset 200px 0 0 0 #ff7700;
color: #fff;
}
/*.back:hover span{
	padding-left: 10px;
	padding-right: 5px;
} */
.create-account {
	font-size: 16px; 
	letter-spacing: 0.2px;
	font-family: 'Roboto', sans-serif;
	color: #979797;
	font-weight: 600; 
line-height: 25px; 
margin: 0;

}

.is-link {
	color: #ff7700;
	text-decoration: none;
	cursor: pointer;
}
.forget-pass{ 
	font-weight: 600;
	font-size: 12px;
	line-height: 16px; 
	align-items: center;
	letter-spacing: 0.2px; 
	color: #696F79;
	padding: 15px 0 20px;
}
.forget-pass .is-link {
	color: #696F79; 
}
.is-link:hover{
	color: #ff7700;
}
.is-primary {
	color: #ff7700;
}

.top-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0 0;
}

.is-link:hover { 
	color: #ff7700;
	text-decoration: none;
}

.brand {
	font-size: 26px;
	color: #3C4043;
	font-weight: 500;
	line-height: 44px;
	letter-spacing: 0.5px;  
align-items: center; 
margin-bottom: 15px;;
}
.brand span{
	border-bottom: 2px solid #ff7700;
	padding-bottom: 4px;
}
.line-divider {
	background-color: rgba(0,0,0,0.2);
	width: 1px;
	height: 100%;
}

.line-divider_or{
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.2px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: #787878;
	padding: 20px;
	text-align: center;
}

.line-container {
	display: flex;
	-webkit-box-align: center;
	justify-content: stretch;
	align-items: center;
	flex-direction: column;
	align-self: stretch;
}

.input-container {
	/* border-bottom: 0.4px solid #C0C0C0; */
	padding: 5px;
	margin: 1rem auto;
}

.input-box .inp {
	border: none;
	border-bottom:1px solid #ff770047;
	width: 100%;
	outline: none;
	font-weight: 600;
font-size: 16px;
line-height: 22px; 
letter-spacing: 0.2px; 
color: #979797;
}

.input-box > .spa {
	position: absolute;
	left: 0;
	top: 2px;
	border-right: 1px solid #ff770047;
	padding-right: 5px;
	display: inline-block; 
	font-weight: 600;
font-size: 16px;
line-height: 22px;  
letter-spacing: 0.2px; 
color: #979797;
}

.input-box > .spa {
	letter-spacing: 0.2px;
    font-size: 16px;
    color: #979797;
    line-height: 22px;
}

.input-box > .lab {
	letter-spacing: 0.2px;
    padding: 0;
    font-size: 16px;
    color: #979797;
    line-height: 22px;
}

.input-box > .bor {
	position: absolute;
	width: 10px;
	height: 1px;
	background-color: #ff7700;
	bottom: 0;
	left: 45%;
	visibility: hidden;
	transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.input-box .inp:focus{
	border-bottom: 1.5px solid #ff7700e8;
}
.form-floating {
    position: relative;
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}

.input-box > .inp:focus~.lab,
.input-box > .inp~.lab.filled
 {
	opacity: 0.65;
	transform: scale(0.85) translateY(-1.25rem) translateX(.15rem);
}

.inp:focus~.bor {
	width: 100%;
	left: 0;
	visibility: visible;
}

.inp:disabled {
    display: none;
}

.error-box,
.password-error-box {
	color: #EB4335;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	font-weight: 500;
	position: absolute;
	bottom: -20px;
	left: 0;
	text-align: left;
}

.error-box a {
	color: #EB4335;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	font-weight: 500;
}

.resend-otp {
	color: #ff7700;
	font-size: 14px;
	line-height: 19px;
	font-weight: 500;
	letter-spacing: 0.2px;
	position: absolute;
	right: 0;
	cursor: pointer;
	display: none;
}

.request-btn {
	color: #fff; 
	padding: 0 10px;
	min-height: 40px;
	font-size: 15px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.2px;
	width: calc(100% - 50px);
	border: 1px solid #444444; 
	background: #444444;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
width: 100%;
}

.submit-btn {
	color: #fff;
	min-height: 40px;
    width: 100%;
    padding: 0 2rem;
    font-size: 15px;
    line-height: 20px;
	letter-spacing: 0.2px;
	font-weight: bold;
	border: none;
	background: #FF7700;
	box-shadow: 0px 3px 4px rgba(255, 146, 0, 0.25);
	border-radius: 5px;
}

.google-btn {
	background-color: transparent;
	min-height: 50px;
	padding: 0 2rem;
	font-size: 16px;
	line-height: 22px;
	font-weight: bold;
	letter-spacing: 0.2px;
	color: #2874F0;
	width: calc(100% - 50px);
}

.pl-40 {
	padding-left: 40px;
}

/***************************************  Reset Password  *****************************************/
#secondary-container {
	background-color: #FFFFFF;
	word-break: break-all;
	border-radius: 13px;
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.51);
}

.form-header {
	font-family: Segoe UI;
	color: #27A4A9;
	font-weight: bold;
	font-size: 2rem;
}

.btn1 {
	background-color: #27A4A9;
	border: none;
	color: white;
	padding: 9px 38px 9px 37px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
	border-radius: 20px;
}

#nav-css {
	background-color: #263238;
	color: white;
}

.form-element-deco {
	text-align: left;
	font-size: 14px;
	font-family: Segoe UI, Regular;
	padding: 15px;
	width: 100%;
	border-radius: 5px;
}

.forgetFormWrapper {
	margin: 15% 30%;
}

.login.error-box {
	background-color: #FFE4EA;
	border-radius: 5px;
	padding: 0.5rem 1rem;
	text-align: left;
	font-size: 12px;
	display: none;
}
 
/********************************************  Forgot Password  ****************************************************/
.alert-deco {
	font-family: Segoe UI;
	color: #27A4A9;
	font-weight: bold;
	font-size: 2rem;
}

.btn1 {
	background-color: #27A4A9;
	border: none;
	color: white;
	padding: 9px 38px 9px 37px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
	border-radius: 20px;
}

#nav-css {
	background-color: #263238;
	color: white;
}

.form-element-deco {
	text-align: left;
	font-size: 14px;
	font-family: Segoe UI, Regular;
	padding: 15px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #E1E1E1;
}

.fs-14 {
	font-size: 14px;
}

.swicthToLogin {
	margin-top: 3rem;
}

@media screen and (min-width: 1200px) {
	#secondary-container {
		background-color: #FFFFFF;
		word-break: break-all;
		border-radius: 13px;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.51);
	}
	.forgotFormWrapper {
		margin: 15% 20%
	}
}

.box {
	max-width: 970px;
	margin-top: 30px;
}

.text-field::placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.mdc input::placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.text-field-email:focus, .text-field-pswd:focus {
	border: 1px solid #27A4A9 !important;
}

.text-field-email, .text-field-pswd {
	border: 1px solid #ddd !important;
}

@media screen and (min-width: 767px) {
	.box {
		background-color: #fff;
		border-radius: 5px;
		/* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5); */
	}
}

.grid {
	display: grid;
	justify-content: center;
	align-items: center;
	height: 100%;
	grid-template-columns: 38% 62%;
}

.vh-100 {
	height: 100vh !important;
}

/************************** Notch Styling *****************************/
.mdc {
	max-width: 345px;
	display: inline-flex;
	position: relative;
	border: none;
	overflow: visible;
	box-sizing: border-box;
	height: 48px;
	width: 345px;
}

.mdc input, .mdc select {
	display: flex;
	border: none;
	z-index: 1;
	box-sizing: border-box;
	padding: 12px 16px 14px;
	width: 100%;
	height: 100%;
	background: transparent;
	outline: none;
	border-radius: 5px;
}

.mdc select option {
	padding: 12px 16px 14px;
}

.notch-outline {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	box-sizing: border-box;
	text-align: left;
	pointer-events: none;
}

.notch-leading {
	border-left: 1px solid #5eb3d1;
	border-right: none;
	border-bottom: 1px solid #5eb3d1;
	border-top: 1px solid #5eb3d1;
	width: 12px;
	box-sizing: border-box;
	pointer-events: none;
	border-radius: 4px 0 0 4px;
}

.notch {
	border-top: 1px solid #5eb3d1;
	border-bottom: 1px solid #5eb3d1;
	box-sizing: border-box;
	pointer-events: none;
	width: auto;
	height: 100%;
	flex: 0 0 auto;
}

.notch label {
	top: 7px;
	left: 4px;
	right: auto;
	display: inline-block;
	position: relative;
	bottom: auto;
	color: #000;
}

.notch-trailing {
	border-right: 1px solid #5eb3d1;
	border-top: 1px solid #5eb3d1;
	border-bottom: 1px solid #5eb3d1;
	border-left: none;
	width: 100%;
	flex-grow: 1;
	pointer-events: none;
	border-radius: 0 4px 4px 0;
}

.notch.active label {
	left: 0;
	transform: translateY(-120%) scale(0.75);
	transition: transform 0.4s ease-in;
	color: #27A4A9;
}

.notch.active {
	width: 56.75px !important;
	border-top: none;
	transition: transform 0.4s ease-in;
}

.notch.valid {
	/* width: 56.75px !important; */
	width: auto;
	border-top: none;
}

/* For chrome autofill styling */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
	transition: background-color 5000s ease-in-out 0s;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}

@media screen and (max-width: 767px) {
	.grid-item.hide-sm {
		display: none;
	}
	.grid {
		grid-template-columns: 1fr;
	}
	.mob-pad-0 {
		padding: 0px !important;
	}
	.box {
		width: 90%;
	}
	.mdc input, .mdc select {
		background-color: #FFF;
		border: 1px solid #27A4A9;
	}
	.forgetFormWrapper {
		margin: 0;
	}
	.form-header {
		font-size: 25px;
	}
	#secondary-container {
		background-color: transparent;
		word-break: break-all;
		border-radius: 0px;
		box-shadow: none;
	}
	.swicthToLogin {
		font-size: 14px;
	}
	
	.top-container {
		padding: 0px;
	}
	
	.line-container {
		flex-direction: row;
	}
	
	.line-divider {
		height: 1px;
		width: 100%;
	}
	
	.logo {
		max-width: 45px;
	}
	
	.brand {
		font-size: 20px;
		margin-top: 0;
	}
	
	.request-btn {
		width: 100%;
		font-size: 13px;
	}
	
	.google-btn {
		width: 100%;
	}
	
	.hide-in-mobile {
		display: none;
	}
}
.loading-image {
  position: absolute;
  top: 50%;
  z-index: 10;
}
.loader
{
    display: none;
    width:auto;
    height: auto;
    top: 50%;
    text-align:center;
    margin-left: -100px;
    margin-top: -100px;
    z-index:2;
    overflow: auto;
}

#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.photographer-signIn {
	color: #787878;
	font-size: 24px;
    line-height: 25px;
	font-family: 'Manrope';
	font-weight: 700;
}

.photographer-signIn > span {
	color: #00C488;
}
.success{
    font-weight: 500;
font-size: 20px;
line-height: 33px; 
align-items: center;
letter-spacing: 0.5px; 
color: #00C488;
}

@media screen and (max-width: 800px){
	.font-family {
		padding: 20px 15px 30px;
		margin: 0;
		box-shadow: 0 0 0;
	}
	.back {
		padding: 3px 10px 7px;
	}
	.photographer-signIn {
		color: #787878;
		font-size: 20px;
		margin-bottom: 10px;
	}
	.logo2 {
		max-height: 22px;
	}
}

.bg-try-mob {
	display: none;
  }
  
  @media screen and (max-width: 768px) {
	.bg-try {
	  display: none;
	}
	.bg-try-mob {
	  margin: 120px 0 100px;
	  display: block !important;
	}
	.bg-try-mob h4 {
	  font-size: 24px;
	  color: #18191f;
	  font-weight: 600;
	}
	.bg-try-mob h6 {
	  font-size: 14px;
	  color: #929292;
	  margin-top: 25px;
	}
	.bg-try-mob p {
	  font-size: 14px;
	  color: #929292;
	  margin-top: 25px;
	  font-weight: 600;
	}
	.bg-try-mob img {
	  margin-top: 20px;
	}
  }