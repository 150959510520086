.left-sec img{
    width: 100%;
}
.left-sec{
    text-align: center;
    margin-top: 0;
    border-right: 0;
    padding: 0px 50px;
}
.right-sec{
    margin-top: 5px;
    width: 100%;
    padding: 0px 50px;
}
.email{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #696F79;
}
.send-otp{
    width: 130px;
    height: 40px;
    background: #0579DF;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #FFFFFF;
    margin: 17px auto;
    padding-left: 30px;
    padding-top: 10px;
}
.otp-send-info{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4A4A4A;
}
.otp-input{
    width: 288.11px;
}
.verify{
    width: 150px;
    height: 40px;
    background: #0579DF;
    border-radius: 5px;
    margin: 17px auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding-left: 55px;
}
.verification{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #4A4A4A;
}
.footwear-image{
    /* width: auto !important; */
    height: 221.71px !important;
    object-fit: contain;
}
.dynamic-logo-popup{
    width: 30%;
}
.dynamic-logo-popup img{
    width: 100%;
    height: auto;
}

.right-sec form input{
    width: 100%;
}

@media(max-width:800px){
    .left-sec img{
        width: 100%;
    }
    .left-sec{ 
        padding: 0px 15px;
    }
    .right-sec{ 
        padding: 0px 15px;
    }
    .email{
       
    }
    .otp-input{
        width: 100%;
    }
    .footwear-image{
        height: auto !important;
    }
    .modal-open .modal{
        padding: 0px 15px!important;
    }
}