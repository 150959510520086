.change-tabs {
  padding: 10px 0 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
  justify-content: left;
  list-style: none;
  margin-left: 10px;
}

.change-tabs li {
  padding: 0;
}

.change-tabs li button {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  border-radius: 0;
  border-bottom: 2px solid transparent;
}
.change-tabs li button.active,
.change-tabs li button:hover,
.change-tabs li button.activate {
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700;
}

.change-tabs li button {
  background: transparent;
}

.dropdown-btn {
  margin-top: 8px;
  margin-left: 36px;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  padding: 5px 9px;
  color: #ff7700;
}

select:focus-visible {
  outline: none;
}

select {
  color: #000 !important;
}

/* option:hover {
  background-color: #ff7700;
}

select.dropdown-btn option:hover {
  box-shadow: 0 0 10px 100px #ff7700 inset;
} */
