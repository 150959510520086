.admin-area{
    padding: 100px 0 60px;
}
.admin-form{
    box-shadow: 0px 2px 10px 0px #ddd;
    padding: 15px;
}
.admin-form form {

}
.admin-form form label{
    width: 100%;
    display: block;
    margin-bottom: 15px;
}
.admin-form form input{
    width: 75%;
    margin-right: 0;
    margin-left: 10%;
    font-size: 14px;
    box-shadow: 0 0 0;
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px 15px;
    font-weight: 200;
}
.admin-area table{
width: 100%;
}
.admin-area table select{
    padding: 5px 15px;
    border: 1px solid #ddd;
    width: 70%;
    margin-right: 0;
    margin-left: 10%;
}

.enterprise-list{
    display: grid;
    grid-template-columns: 50% 50%;
}

.table-area{
    box-shadow: 0px 2px 10px 0px #ddd;
    padding: 15px;
    text-align: center;
}

/* @Responsive  */

@media screen and (max-width: 767px) {
    .admin-area {
        padding: 100px 10px 60px;
    }
    .admin-form form input {
        width: 100%;
        margin: 10px  0;
            border: 1px solid #ddd;
 
    }
    .admin-area table td{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .admin-area table td select{
        width: 100%;
        margin: 0;
    }
}


