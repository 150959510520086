/* .forget-ps{
    
}.forget-pass{

}
.forget-pass-single{
    
} */
.forget-pass-box p{
    font-weight: 500;
font-size: 12px;
line-height: 16px; 
align-items: center;
letter-spacing: 0.5px; 
color: #696F79;
margin-bottom: 20px;
}
.forget-pass-box{
    padding: 15px 15px 40px;
    background: #FFFFFF;
border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 10px;
}
.forget-pass-box button{
    margin: 20px 0px ;
}
.logo{
    max-width: 70px;
    margin-top: 0;
    padding: 15px 0px;
}

form p{
    text-align: center;
    margin: 20px 0 0!important;
    font-weight: 500;
font-size: 12px;
line-height: 16px; 
align-items: center;
letter-spacing: 0.5px; 
color: #696F79;;
}

@media screen and (max-width: 800px){
    .forget-pass-box{
        padding: 15px 0px 20px;
        margin: 20px 0 0;
    }
}