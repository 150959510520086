.credits-data{
    padding: 100px 6% 15px;
    text-align: right;
}
.credits-data span{
    border: 2px solid #ffe7d2;
    border-radius: 10px; 
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 12px 10px;
    letter-spacing: .5px;
    margin-top: 7px;
    color: #232121;
    /* position: absolute;
    right: 20px; */
   
}
.credits-data span img {
    margin-right: 15px;
}
.credits-data span b {
    color: #f70;
}

