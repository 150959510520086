.pricing-section {
  padding: 100px 0 0;
}
.pricing-title {
}
.pricing-title h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  color: #1c1e52;
  text-align: center;

  margin-bottom: 15px;
}
.pricing-title h2 span {
  border-bottom: 4px solid #ff7700;
  padding-bottom: 5px;
}
.pricing-title p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.pricing-title p span {
  border-bottom: 1px solid #000;
}
.pricing-title h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ff7700;
}
.pricing-area {
  padding: 80px 50px 50px;
}
.pricing-cols {
}

.pricing-single {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px 20px;
  border-right: 0;
  z-index: 1;
  position: relative;
}
::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff7700;
}
.pricing-single img {
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 7px #ddd;
  border-radius: 50%;
  width: auto;
  margin-top: -13%;
  background: #ffff;
}
.pricing-single h2 {
  font-weight: 600;
  font-size: 45px;
  line-height: 72px;
  text-align: center;
  color: #ff7700;
  padding: 10px;
  margin: 0;
}
.pricing-single h2 strike {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #000000;
}
.pricing-single h2 span {
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
}
.pay-as-you-go {
  text-align: center !important;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #1c1e52;
}
.pricing-single h6 span {
  border-bottom: 4px solid #ff7700;
  padding: 5px 0;
}
/* .pricing-right h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #232121;
  text-align: center;
} */
.pricing-single .rate span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #ff3503;
}
.pricing-single .rate {
  color: #1c1e52;
  font-size: 12px;
}
.pricing-right {
  padding: 15px 20px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  margin: 0;
  box-shadow: 1px 2px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin-top: 5%;
  border-left: 0;
}
.pricing-right h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 72px;
  text-align: center;
  color: #ff7700;
  padding: 10px;
  margin: 0;
}
.pricing-right h2 strike {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #000000;
}
.pricing-right h2 span {
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
}
.pricing-right h6 span {
  border-bottom: 4px solid #ff7700;
  padding: 5px 0;
}
/* .pricing-right ul {
  list-style: none;
}
.pricing-right ul li {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #232121;
  padding: 15px 0;
}
.pricing-right ul li img {
  padding-right: 5px;
} */
.credit strike {
  font-weight: 500;
  font-size: 13px;
  line-height: initial;
  color: #5f5f63;
  display: block;
}
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ff3503;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.input-box-div {
  width: 100%;
  transition: 0.3s ease-in-out;
  /* display: flex; */
  margin: 0;
  padding: 5px 0;
  cursor: pointer;
}
.input-box-div:hover {
  background: #f5f5f5;
}
.premium-img {
}
.premium-img img {
  position: absolute;
  top: -5%;
  right: 5px;
}
.save-price {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 35px;
  color: #232121;
  margin: 0;
  text-align: center;
}
.save-price span {
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #5f5f63;
  display: block;
}
.pricing-list-single {
  padding: 0 5px;
}
.main-price-container {
  padding: 15px 0;
}
.reminder {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 35px;
}
.reminder span {
  color: #ff7700;
}
.mybtn {
  color: #fff;
  background: #ff7700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 178px;
  height: 43px;
  margin-top: 5px;
}
.mybtn:hover {
  color: #fff;
}
.per-price-credit {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #1c1e52;
}
.price-breakup {
  margin: 0;
  padding: 0px 10px 50px;
}
.price-breakup h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1c1e52;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 45px;
}

.price-breakup h2 span {
  display: inline-block;
  border-bottom: 2px solid #000;
  position: relative;
}
.price-breakup h2 span:hover {
  cursor: pointer;
}
.price-breakup h2 span::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #f8f8f8;
  transition: width 0.3s;
  position: absolute;
}

.price-breakup h2 span:hover::after {
  width: 80%;
}
.price-table {
  background: #ffffff;
  box-shadow: 1px 8px 85px rgb(0 0 0 / 15%);
  border-radius: 18px;
}
.price-table-area {
  /* background: #FFFFFF;
box-shadow: 1px 8px 85px rgba(0, 0, 0, 0.15);
border-radius: 18px; */
  text-align: center;
  border-bottom: 1px solid #ededed;
}
.price-table-left {
  padding: 0px 0 15px;
}
.price-table-middle {
  padding: 0px 0 15px;
}
.price-table-right {
  padding: 0px 0 15px;
}
.price-table h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1c1e51;
  border-bottom: 1px solid #ededed;
  margin: 0;
  padding: 15px;
}
.price-table p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #464767;
  border-bottom: 1px solid #ededed;
  margin: 0;
  padding: 5px 10px;
}
.price-table p:nth-last-child(1) {
  border: 0;
}
.price-table-left p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #1c1e51;
  border: 0;
  padding: 10px 30%;
  margin-top: 11%;
}

.price-table-middle {
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}
.price-table-middle p:nth-of-type(1),
.price-table-right p:nth-of-type(1) {
  padding-top: 15px;
}
.credit-radio {
  padding-left: 5px;
}
.price-rate-cr {
}
.main-price-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-price-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* @Responsive  */
@media screen and (max-width: 800px) and (min-width: 200px) {
  .pricing-title {
    font-size: 26px;
    line-height: 30px;
  }
  .pricing-cols {
    margin-bottom: 50px;
  }
  .premium-img img {
    top: -6%;
    right: -15px;
  }
  .pricing-area {
    overflow: hidden;
    padding: 0;
    padding-top: 60px;
  }
  .price-table p {
    font-size: 11px;
    line-height: 18px;

    padding: 5px 8px;
  }
  .price-table-right p span {
    display: none;
  }
  .price-table-left p {
    padding: 10px;
    margin-top: 6%;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  .price-table p:first-child {
    padding-top: 5px !important;
  }

  .price-table-middle {
    border: 1px solid #ededed;
    border-bottom: 0;
  }
  .price-table-right {
    border: 1px solid #ededed;
    border-bottom: 0;
    border-left: 0;
    padding: 0;
  }
  .price-table-middle {
    padding: 0;
  }
  .price-breakup {
    margin: 0;
    padding: 0px 0px 50px;
  }
  .price-table h4 {
    font-size: 13px;
    line-height: 19px;
    padding: 10px;
  }
  .price-table-left {
    padding: 0;
  }
  .price-table {
    padding-bottom: 20px;
  }
  .price-table-area {
    text-align: left;
  }
  .price-table-right p {
    text-align: center;
  }
  .price-table-middle p:nth-of-type(1),
  .price-table-right p:nth-of-type(1) {
    padding-top: 5px;
  }
  .price-table-right h4 {
    text-align: center;
  }
  .pricing-single {
    padding: 25px 0 40px;
    height: auto;
  }
  .pricing-right {
    display: none;
  }
  .pricing-title h2 {
    font-size: 22px;
    text-align: center;
    line-height: 33px;
    margin: 0 0 15px;
  }
  .pricing-title h2 span {
    border: none;
  }
  .pricing-title p {
    font-size: 11px;
    display: none;
  }
  .input-box-div {
    padding: 5px;
  }
  .pricing-single img {
    margin-top: -22%;
  }
  .credit-radio {
    padding: 0 5px;
  }
  .price-rate-cr {
    padding: 0;
    text-align: center;
  }
  .pricing-list-single {
    text-align: right;
  }
}

.pricing-modal-cross {
  text-align: end;
}

.pricing-modal-cross img {
  cursor: pointer;
}

.pricing-modal-text h4 {
  text-align: center;
  font-size: 23px;
}

.pricing-modal-text {
  text-align: center;
}

.pricing-modal-text button {
  text-align: center;
  border-radius: 100px;
  margin: 20px;
}

.advantage {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  font-family: Poppins;
  font-style: normal;
}

.advantage h3 {
  text-align: center;
  font-weight: 600;
  margin-top: -28px;
  margin-bottom: 25px;
}

.advantage-content {
  display: flex;
  flex-direction: column;
}

.advantage-content-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.advantage-content-row div {
  width: 30%;
  justify-content: center;
}
.advantage-content-row div img {
  display: block;
  margin: auto;
}
.advantage-content-row div p {
  display: block;
  text-align: center;
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .pricing-area {
    padding: 80px 0px 50px;
  }
}
