/* CarsThreeSixtyVideo  */
.video-section {
  padding: 100px 0 60px;
}
.video-title {
  margin-bottom: 35px;
}
.video-title h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 150%;
  text-align: center;

  color: #232121;
}
.video-title p {
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #232121;
}
.video-area-right {
  padding: 20px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
  background: #fff;
  border-radius: 5px;
}
.video-area-simple {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}
.video-area-simple p {
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  padding: 5px 0 0;
}
.video-area-simple h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin-bottom: 30px;

  color: #232121;
}
.video-area-simple img {
  width: auto;
  margin: 0 auto 20px;
}

.create-three-sixty {
  padding: 60px 0 60px;
}

.create-three-sixty .video-area-simple h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 20px 0 10px;
}
.create-three-sixty .video-area-simple p {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #55575b;
}
.create-three-sixty .video-area-simple p span {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
}
.create-three-sixty .video-area-simple button {
  filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
  min-width: 150px;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin: 15px auto 0;
  border: 2px solid #ff7700;
}
.highlighter-box {
}
.highlighter-box-single {
  height: 150px;
}
.no-reupload {
  background: #fff;
  color: #ff7700 !important;
  border: 2px solid #ff7700;
  padding: 7px 20px;
}
.video-area-simple img {
  width: 100%;
}
.video-area-simple p button {
  margin: 15px 30px 0 !important;
}
.video-title button {
  padding: 8px 20px;
}
.video-title button img {
  width: 25px;
}
.video-area-simple p b {
  font-weight: 600;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}
.video-gif img {
  width: 30%;
  margin: 10px auto 20px;
}
.notification {
  font-style: normal;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 21px !important;
  color: rgba(244, 6, 6, 0.75) !important;
  margin: 0;
}
.i-frame-360{
  text-align: center;
}
.i-frame-360 iframe{
  width:100%;
height: 640px;

  
}