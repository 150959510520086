.left-menu {
  position: fixed;
  height: 100vh;
  box-shadow: 12px 0px 66px -53px rgb(124 124 124);
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 12px 0px 66px -53px rgb(124 124 124 / 25%);
  left: 0;
  z-index: 999;
  padding: 10px;
}

.sidebar-logo {
  margin-bottom: 20px;
}

.logo {
  width: 100%;
}

.side-tab {
  cursor: pointer;
  margin-top: 10px !important;
  padding: 15px 15px 15px 0 !important;
  border-left: 4px solid transparent;
  text-align: left;
}

.menu-icon {
  padding: 0;
}

.tab-active .menu-icon span,
.side-tab:hover .menu-icon span,
.tab-active .menu-icon i,
.side-tab:hover .menu-icon i {
  color: #ff7700;
}

.menu-icon span {
  font-size: 16px;
  line-height: 23px;
  color: #abaeaf;
}

.tab-active,
.side-tab:hover {
  background: linear-gradient(90deg, #fad8bb 0%, rgba(255, 255, 255, 0) 78.6%);
  border-left: 4px solid #ff7700;
}

.menu-icon i {
  color: #5f6368;
  font-size: 24px;
  padding: 0px 10px;
  vertical-align: middle;
}
