body{
    font-family: 'Poppins';
}
.common-header{
    margin-top: 100px;
}
.back-button{
    padding-top: 100px !important;
    background-color: #fff;
    border: unset;
    border-radius: 11px;
    justify-content: left;
  }
.common-header h1{ 
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #1C1E52;
    margin: 0px;
    padding: 0px;
}
.common-header p{ 
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 45px;
    color: #444444;
    margin: 0px;
    padding: 0px;
}
.common-header span{
    color: #FF7700;
}
.image-upload {
    margin-bottom: 5%;
    /* display: flex;
    flex-direction: row; */
}
.left-box{
    margin-top: 8%;
}

.drag-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#FF7700;
}
.browse-image-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4F4444;
}
.browse-image-text span{
    color: #FF7700;
}
.support-image-type{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #B2B2B2
}
.left-box button{
    position:  relative !important;
    /* left: 8%; */
    color: white;
    background-color: #FF7700;
    border: unset;
    margin: 30px 0; 
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: initial;
    color: #FFFFFF;
    height: 45px;
}
.left-box button:hover{
    background-color: #FF7700;
    border: unset;
}
.upload-image-box{
    width: 324.76px;
    height: 261.94px;
    background: #FFFFFF;
    border: 1px dashed #77797B;
    box-sizing: border-box;
    border-radius: 10px;
}
 
.right-box img{
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 133px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}
  
 .heading-text{
    padding: 0 20px;
  }
  /* Upload screen User credits */
  .heading-text span{
    border: 2px solid #FFE7D2;
    border-radius: 10px;
    float: right;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    padding: 7px 10px;
    letter-spacing: 0.5px;
    margin-top: 7px;
    color: #232121;
    position: absolute;
    right: 20px;
  }
  .heading-text span b{
    color: #ff7700;
  }
  .heading-text span img{
    margin-right: 20px;
  }
.border-box{ 
    border: 2px dashed #9a9a9a;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: none!important;
    /* opacity: .2; */
}
.dropbox-box{
padding: 20px 30px;
}
.outer-box-section{
    text-align: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin: 0 auto;
}











/* @Responsive */
@media(max-width:800px){
    .common-header h1{  
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        text-align: center; 
    } 
    .left-box div{
        padding: 15px;
    }
    .left-box button{ 
        width: auto;
        margin: 0px 0 10px; 
    }
    .image-upload {
        margin-bottom: 30px;
    }
    .drag-text{
        margin: 5px 0;
    }
    .browse-image-text{
        margin: 0px 0 5px;
    }
    .support-image-type{
    margin: 0;
    }
}




@media screen and (max-width:767px) and (min-width: 200px){

.border-box {
    padding: 15px;
}
.common-header{
    margin-top: 80px;
}
.common-header p {  
    font-size: 12px;
    line-height: 26px;
    margin-bottom: 20px;
}
.left-box {
    margin-top: 0;  
      margin-bottom: 30px;
}
.dropbox-box {
    padding: 0px;
}
}


@media screen and (max-width:900px) and (min-width: 768px){
    
.image-upload{
    display: flex;
}
.left-box {
    margin-top: 0;
}
.dropbox-box{
    padding: 0;
}
.common-header p {
    line-height: 30px;
    margin: 0px 0 30px;
}
}