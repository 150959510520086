/* .dealership-logo-section-wrapper{
    border: 2px solid red;
    margin: 0px 21.39px;
} */
.right-mid-setion-content {
  display: flex;
  text-align: center;
  /* justify-content: space-around; */
  width: 100%;
  margin: 0;
  margin-top: 20px;
}
.logo-image p {
  margin: 10px 0 0;
  display: initial;
  font-size: 14px;
}
.logo-image {
  height: 50px;
  background: #e4e4e4;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  padding: 12px 0px;
}
.logo-image img {
  height: -webkit-fill-available !important;
}
.upload-button {
  width: 140px;
  height: 40px;
  border-color: #ff7700;
  border-radius: 5px;
  margin-top: 8px;
  margin-left: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ff7700;
  border: 2px solid #ff7700;
}
.upload-button button:hover {
  color: #ff7700 !important;
}
.upload-logo-button button:hover {
  color: #ff7700 !important;
}
.logo-select {
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}
.logo-select input {
  margin-top: 5px;
}
.left-top-logo-preview {
  position: absolute;
  top: 5%;
  width: 100px;
  /* height: 0; */
  left: 13%;
}
.left-bottom-logo-preview {
  position: absolute;
  top: 43%;
  width: 100px;
  /* height: 0; */
  left: 13%;
}
.right-top-logo-preview {
  position: absolute;
  top: 5%;
  right: 42.8%;
  width: 100px;
}
.right-bottom-logo-preview {
  position: absolute;
  right: 42.8%;
  width: 100px;
  /* height: 0; */
  top: 43%;
}
.left-top-logo-preview img,
.left-bottom-logo-preview img,
.right-top-logo-preview img,
.right-bottom-logo-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.small-image-checkboxes {
  padding: 0;
}

input.checkboxField[type='checkbox']:checked {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  outline: 0;
}
.checkboxField:before {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 0;
  border-radius: 0;
  background-color: white;
  border-color: transparent;
  box-sizing: border-box;
  color: grey;
  content: close-quote;
  display: inline-block;
  height: 13px;
  outline: 1px solid grey;
  transition-duration: 0.5s;
  transition-property: background-color, border-color;
  width: 13px;
  position: absolute;
}

.checkboxField:checked:before {
  background-color: grey;
  border-color: grey;
}

/*== Moving checkbox Icons ==*/
.sm-car-checkbox-1 {
  width: 30px;
  height: 2px;
  margin: 5px 0 0;
  border-bottom: 1px solid grey;
}
.sm-car-checkbox-2 {
  width: 2px;
  height: 40px;
  margin: 0 0px 0 5px;
  border-left: 1px solid grey;
}

.sm-car-checkbox-3 {
  width: 2px;
  height: 40px;
  border-left: 1px solid grey;
  margin-left: 50px;
  margin-top: -50px;
}
.sm-car-checkbox-4 {
  width: 30px;
  height: 2px;
  border-bottom: 1px solid grey;
  margin-top: 5px;
}

.logo-text {
  font-size: 14px;
  font-family: poppins;
  text-align: left;
  font-weight: 600;
}

.logo-text span {
  font-size: 10px;
}

.logo-pos-text {
  font-size: 14px;
  font-family: poppins;
  text-align: left;
  font-weight: 600;
}

.logo-image .cross-logo {
  width: 10px;
  height: 10px !important;
  position: absolute;
  top: 7%;
  right: 12%;
  z-index: 999;
  cursor: pointer;
}

/* ==//Moving checkbox Icons== */

@media (max-width: 800px) {
  .right-mid-section {
    margin-top: 0px !important;
  }
  .right-mid-setion-content {
    justify-content: space-around !important;
  }
  .logo-image {
    order: 1;
    padding: 12px 5px 5px;
  }
  .logo-image p {
    /* font-size: 14px; */
  }
  .upload-logo-button {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .small-image-checkboxes {
    padding-left: 70px;
    order: 2;
    text-align: right;
  }
  /* .left-top-logo-preview, .left-bottom-logo-preview, .right-top-logo-preview, .right-bottom-logo-preview{
        display: none;
    } */
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .upload-logo-button {
    order: 3;
    margin: 10px 0px 0px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .upload-logo-button {
    order: 3;
    margin: 0;
    text-align: right;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 320px) and (min-width: 200px) {
  .small-image-checkboxes {
    padding-left: 45px !important;
  }
  .logo-image {
    padding: 3px 5px 5px !important;
    margin-top: 5px;
  }
  .left-top-logo-preview {
    top: 42%;
    width: 60px;
    left: 50px;
  }
  .right-top-logo-preview {
    top: 42%;
    right: 16%;
    width: 60px;
  }
  .right-bottom-logo-preview {
    right: 16%;
    width: 60px;
    bottom: 120px;
  }
  .left-bottom-logo-preview {
    width: 60px;
    bottom: 120px;
    left: 50px;
  }
}
@media screen and (max-width: 360px) and (min-width: 321px) {
  .right-top-logo-preview {
    top: 37%;
    right: 15%;
    width: 60px;
  }
  .left-top-logo-preview {
    width: 60px;
    left: 50px;
  }

  .left-bottom-logo-preview {
    bottom: 200px;
    width: 60px;
    left: 50px;
  }
  .right-bottom-logo-preview {
    bottom: 200px;
    width: 60px;
    right: 15%;
  }
}

@media screen and (max-width: 374px) and (min-width: 361px) {
  .right-top-logo-preview {
    top: 33%;
    right: 14%;
    width: 60px;
  }
  .left-top-logo-preview {
    width: 60px;
    left: 55px;
    top: 33%;
  }

  .left-bottom-logo-preview {
    bottom: 285px;
    width: 60px;
    left: 55px;
  }
  .right-bottom-logo-preview {
    bottom: 285px;
    width: 60px;
    right: 13%;
  }
}

@media screen and (max-width: 410px) and (min-width: 375px) {
  .right-top-logo-preview {
    top: 36%;
    right: 14%;
    width: 60px;
  }
  .left-top-logo-preview {
    width: 60px;
    left: 55px;
    top: 36%;
  }

  .left-bottom-logo-preview {
    bottom: 225px;
    width: 60px;
    left: 55px;
  }
  .right-bottom-logo-preview {
    bottom: 225px;
    width: 60px;
    right: 15%;
  }
}

@media screen and (max-width: 767px) and (min-width: 411px) {
  .right-top-logo-preview {
    top: 33%;
    right: 14%;
    width: 60px;
  }
  .left-top-logo-preview {
    width: 60px;
    left: 55px;
    top: 33%;
  }

  .left-bottom-logo-preview {
    bottom: 295px;
    width: 60px;
    left: 55px;
  }
  .right-bottom-logo-preview {
    bottom: 295px;
    width: 60px;
    right: 15%;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .right-top-logo-preview {
    top: 21%;
    right: 8%;
    width: 60px;
  }
  .left-top-logo-preview {
    width: 60px;
    left: 55px;
    top: 21%;
  }

  .left-bottom-logo-preview {
    bottom: 59%;
    width: 60px;
    left: 55px;
  }
  .right-bottom-logo-preview {
    bottom: 59%;
    width: 60px;
    right: 8%;
  }
}

/* @media screen and (max-width: 1280px) and (min-width: 900px) {
  .upload-button {
    width: 80%;
    font-size: 13px;
  }
  .logo-image {
    padding: 5px 5px;
  }
  .upload-logo-button {
    padding: 0;
  }

  .sm-car-checkbox-3 {
    margin-left: 44px;
  }
  .left-top-logo-preview {
    top: 31%;
    width: 100px;
    left: 95px;
  }
  .right-top-logo-preview {
    position: absolute;
    top: 31%;
    right: 35%;
  }
  .right-bottom-logo-preview {
    right: 35%;
    bottom: 150px;
  }
  .left-bottom-logo-preview {
    bottom: 150px;
    left: 95px;
  }
} */

/* @media screen and (max-width: 1300px) and (min-width: 1200px) {
  .logo-image p {
    line-height: 2.5;
  }
  .sm-car-checkbox-3 {
    margin-left: 49px;
  }
}

@media screen and (max-width: 1498px) and (min-width: 1440px) {
  .left-top-logo-preview {
    top: 31%;
    left: 90px;
  }
  .right-top-logo-preview {
    top: 31%;
    right: 42%;
  }
  .right-bottom-logo-preview {
    right: 42%;
    bottom: 22%;
  }
  .left-bottom-logo-preview {
    left: 90px;
    bottom: 22%;
  }
}

@media screen and (max-width: 1599px) and (min-width: 1499px) {
  .left-top-logo-preview {
    top: 25%;
    left: 55px;
  }
  .right-top-logo-preview {
    top: 25%;
    right: 45%;
  }
  .right-bottom-logo-preview {
    right: 47%;
    bottom: 20%;
  }
  .left-bottom-logo-preview {
    left: 90px;
    bottom: 20%;
  }
}

@media screen and (min-width: 1601px) {
  .left-top-logo-preview {
    top: 25%;
    left: 45px;
  }
  .right-top-logo-preview {
    top: 25%;
    right: 51%;
  }
  .right-bottom-logo-preview {
    right: 51%;
    bottom: 22%;
  }

  .left-bottom-logo-preview {
    bottom: 22%;
    left: 45px;
  }
} */

.radio-logo {
  margin-right: 10px;
}
.feature-position{
  display: inline-flex;
  justify-content: left;
  width: 100%;
}
.big {
  display:block;
  width:100%;
  height: 100%;
  cursor:pointer;
}