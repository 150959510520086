@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.drop-box-text {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.drop-box-text div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.car-main-wrapper {
  background-size: cover !important;
  padding-bottom: 60px;
}
.header-section {
  text-align: center;
  padding-top: 100px !important;
}
.heading-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.heading-para {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.heading-para span {
  color: #ff7700 !important;
}

.wrapper {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.left-section {
  width: 40%;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.mid-section {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.sample-button {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.sample-button:hover {
  color: black;
}
.dropzone {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.dropzone:hover {
  opacity: 1;
}
.dropzone:focus {
  outline: none !important;
  border: none !important;
}
.para-1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.para-2 { 
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #4f4444;
  margin: 0px;
}
.para-2 span {
  color: #0579df;
}
.supports { 
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.choose-button {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.choose-button button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.choose-button button:hover {
  color: #ffffff;
}
.choose-button button:focus {
  outline: none !important;
}
 
.right-section img {
  
  width: 100%;
}
 
 

.request-demo {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.mob-area .why-use .request-demo2 {
  display: block;
}

.mob-area .why-use p img {
  margin-top: 10px;
}

.request-demo2 img {
  height: 42px;
  /* margin-left: 20px; */
}

.custom-backgrounds {
  padding: 60px 5% 40px;
}

.title {
  text-align: center;
}

.title h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

 
.try-images-area {
  padding: 5px 15px;
}
.car-left-section {
  margin: 25px auto 30px;
}
.try-drag-drop {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
}
.try-drag-drop .dropzone1 {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
  padding: 20px 20px 10px;
  cursor: pointer;
}
.try-drag-drop .dropzone1:hover {
  opacity: 1;
}
.try-drag-drop .dropzone1 img {
    width: auto;
}
.para-11 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px; 
    color: #FF7700;
}
.para-f1 {
    color: #FF7700;
}
.para-f1 span{
    background: #FF7700;
border-radius: 10px;
font-weight: 500;
font-size: 12px;
line-height: 18px; 
color: #FFFFFF;
padding: 5px 10px;
margin-left: 10px;
}
.para-f1 span img{
    padding-right: 5px;
    width: 20px!important;
}
.supports1 {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
 
.choose-output {
}
.heading h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.heading h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.heading h3 .border-1 {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
 
.bg-try {
    padding: 100px 0 0;
}

.choose-button {
}
.choose-button button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: max-content;
}
.bg-recommended {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  /* overflow: hidden;
  overflow-y: scroll; */
}
 
 
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}




  .signup-popup{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    padding: 0;
  }
.signup-image{
    padding: 20px 0 20px 15px;
}
.signup-image img{
width: 100%;
}
.signup-content{

}

.circle-area {
    padding: 0;
    background: #f79c4c;
   
  }
  .signup-inner{
      margin: 0%;
      padding:30px 25px;
      height: 100%;
      width: 100%;
  }
  .circle-area-inner {
    border: 2px dashed #ffffff;
    box-sizing: border-box;
    padding: 23% 35px 20px; 
    height: 100%;
    width: 100%;
  }
  .circle-area-inner h3 {
    font-weight: 600;
font-size: 34px;
line-height: 139.5%; 
text-align: center; 
color: #FFFFFF;
margin-bottom: 20px;
  }
  .circle-area-inner p {
    font-weight: normal;
    font-size: 14px;
    line-height: 139.5%;
    margin-bottom: 35px;
    text-align: center;
  
    color: #ffffff;
  }
.signup-btn{
    background: #FFFFFF;
    border-radius: 28.4704px;
    font-weight: 600;
    font-size: 18px;
    line-height: 139.5%;
    text-align: center;
    color: #FF7700;
    width: 100%;
}

.signup-btn:hover{
    background: #FFFFFF; 
    color: #FF7700; 
}



/* Footwear-Background Slider Popup CSS */
.footwear-slider{

}
.footwear-slide-content{

}
.footwear-slide-title{

    text-align: center;
}
.footwear-slide-title h4{
    font-weight: 600;
font-size: 20px;
line-height: 190.5%; 
letter-spacing: 0.03em;

color: #232121;
}

.footwear-slide{
    margin-bottom: 15px;
}
.footwear-single{

}
.footwear-single p{
    font-weight: normal;
font-size: 14px;
line-height: 190.5%;
margin: 0;
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.footwear-single img{
    width: 100%;
}
.footwear-single h6{
    font-weight: 600;
font-size: 16px;
line-height: 190.5%;
/* or 34px */

text-align: center;
letter-spacing: 0.03em;

color: #232121;
}
.footwear-slide-title button{
    background: #FF7700;
border-radius: 26px;
margin-bottom: 20px;
}
.footwear-slide-title p{
    font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 190.5%; 
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.bulk-images-area{
  padding: 15px 10px 10px;
}
.bulk-image{
  background: #FFF0ED; 
  border-radius: 5px;
  padding: 11px 15px 15px;
}
.bulk-image p{
  font-weight: normal;
font-size: 14px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;
margin: 0 0 8px;
color: #444444;
}
.bulk-image img{
  border-radius: 5px;
  padding: 0;
  width: 100%;
}
.cross-icon{
  background: #FFFFFF;
  position: absolute;
  width: 25px!important;
  z-index: 1;
  border-radius: 50%!important;
  padding: 5px!important;
  height: 25px;
  right: 5px;
  font-weight: 300;
  top: -5%;
  box-shadow: 0px 2px 5px 0px #ddd;
}

.proceed-btn button{
  background: #fff!important;
  color: #FF7700!important;
  border: 1.5px solid #FF7700;
  border-radius: 5px;
}

.bulk-images-slider .bulk-image{
  position: relative;   
   border: 2px solid #E6A56C;
}
.bulk-images-slider{
  width: 80%;
  margin: 0 auto 0 20%;
}
.bulk-images-slider .cross-icon{
  right: -5%;
  top: -10%;
}



@media (max-width: 800px) {
  .car-main-wrapper {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .wrapper {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .right-section {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .left-section {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .heading-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .heading-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .section-two {
    padding: 27px;
  }
  .section-two-one {
    margin-top: 70px;
  }
  .number-image {
    display: none;
  }
  .section-two-one h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .section-two-one p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .section-two-one-right {
    margin-top: 0px;
  }
  .section-3-right div {
    margin-left: 0px;
  }
  .mob-change {
    display: flex;
    flex-direction: column-reverse;
  }
  .mob-change h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .section-two-three-right img {
    margin-top: 120px;
  }
  .section-3 {
    padding: 27px;
  }
  .section-3-right p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .section-3-right button {
    width: 150px;
    height: 50px;
  }
  .try-images-area {
    padding: 5px 0;
    order: 1;
  }
  .car-left-section {
    order: 3;
  }
  .perfect-output-images p {
    text-align: center;
    margin: 0;
  }
  .try-images-area img {
    width: 100%;
    margin: 20px 0;
  }
  .choose-button {
    padding: 10% 10px;
  }
  .bg-try {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .perfect-output-images {
    order: 3;
    padding: 15px 0;
  }
  .bg-recomended-area {
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .bg-recomended-right img {
    margin: 0px 0px 30px;
  }
  .custom-big {
    display: none;
  }
  .bg-recomended-right {
    padding: 0;
  }
  .bg-recommended {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
  .active-background-sec {
    padding: 0 10px;
    border-radius: 5px;
  }
  .inactive-background-sec {
    padding: 3px;
    border: 2px solid transparent;
  }
  .perfect-output-images p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .heading h3 {
    font-size: 16px;
  }
}
.custom-backgrounds {
  padding: 60px 0%;
}

.title {
  text-align: center;
}
.title h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 45px;
  margin-top: 0;
}
.title h1 span {
  border-bottom: 2px solid #fd8061;
  color: #1c1e52;
}

.custom-image {
  padding-top: 4%;
  position: relative;
  text-align: center;
}
.custom-image img {
  width: 100%;
  position: relative;
  height: 595px;
}
.custom-links {
}
.custom-backgrounds .container-fluid {
  width: 100% !important;
}

.custom-links ul {
  display: block;
  list-style: none;
  padding: 0;
  width: 65%;
  margin: 0 0 0 auto;
}
.ecom-links {
  float: left;
}
.custom-links ul li {
  padding: 10px 10px 0;
  width: 100%;
  text-align: center;
}

.custom-links ul li button {
  background: #fff;
  padding: 3px;
  border: 2px solid #fff;
  position: relative;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
}
.custom-links ul li button.active {
  border: 2px solid #fd8061;
}
.custom-links ul li button img {
  width: 100%;
  height: 95px;
}
.abs-img {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 40% !important;
  height: auto !important;
}
.frame-img {
  position: absolute !important;
  left: 10px !important;
  right: 15px !important;
  width: 97.5% !important;
  margin: 0 auto;
}

.left-arrow {
  width: 17%;
  float: right;
  height: 60%;
  /* margin-top: 20%; */
  position: relative;
  top: 18%;
}
.btm-arrow {
  position: relative;
  width: 70% !important;
  margin: 4% auto;
  height: auto !important;
}

.gif-area {
  padding: 0px 0 60px;
}
.no-border {
  border: none !important;
}

.mac-pro-image {
  text-align: center;
}
.mac-pro-image img {
  margin-bottom: 50px;
  width: 100%;
}

.graph-area {
  padding: 60px 0px;
}
.graph-area .title {
}
.graph-area .title h1 {
  margin-bottom: 10px;
}
.graph-image {
  padding: 0 3%;
  text-align: center;
}
.graph-image img {
  width: 100%;
  margin-bottom: 7%;
}

.calendly-area {
  padding: 60px 0px 8%;
}

.cars-demo {
  height: 450px;
  text-align: center;
}
.cars-demo p {
  margin-top: 30px;
}
.cars-demo iframe {
  height: 100%;
}
.cars-video {
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  height: 450px;
  background: #fff;
}
.cars-calender {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px;
}
.cars-calender h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.calendly-inline-widget {
  width: 100%;
}
.main {
  padding: 50px 0px;
}
.book-demo {
  margin-bottom: 30px;
}
.book-demo h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  letter-spacing: 0.2px;
  color: #5d5d6d;
  margin-bottom: 15px;
  padding-left: 5%;
}
.book-demo h1 span {
  color: #ff7700;
}
.book-demo p span {
  color: #ff7700;
}
.book-demo p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}
.book-demo img {
  float: left;
}
.cars-calender {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px 10px 20px;
  height: 100%;
  overflow: hidden;
  background: #fff;
}
.cars-calender h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.calendly-inline-widget {
  height: 100% !important;
}

.ticker-headline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 15px 0;
  margin: 0;
  font-size: 18px;
}

.carousel.vertical .carousel-inner {
  height: 100%;
  width: auto;
}
.carousel.vertical .carousel-inner > .item {
  width: auto;
  padding-right: 50px;
  transition: 0.6s ease-in-out top;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel.vertical .carousel-inner > .item {
    transition: 0.6s ease-in-out;
  }
  .carousel.vertical .carousel-inner > .item.next,
  .carousel.vertical .carousel-inner > .item.active.right {
    transform: translate3d(0, 100%, 0);
    top: 0;
  }
  .carousel.vertical .carousel-inner > .item.prev,
  .carousel.vertical .carousel-inner > .item.active.left {
    transform: translate3d(0, -100%, 0);
    top: 0;
  }
  .carousel.vertical .carousel-inner > .item.next.left,
  .carousel.vertical .carousel-inner > .item.prev.right,
  .carousel.vertical .carousel-inner > .item.active {
    transform: translate3d(0, 0, 0);
    top: 0;
  }
}
.carousel.vertical .carousel-inner > .active,
.carousel.vertical .carousel-inner > .next,
.carousel.vertical .carousel-inner > .prev {
  display: block;
}
.carousel.vertical .carousel-inner > .active {
  top: 0;
}
.carousel.vertical .carousel-inner > .next,
.carousel.vertical .carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel.vertical .carousel-inner > .next {
  top: 100%;
}
.carousel.vertical .carousel-inner > .prev {
  top: -100%;
}
.carousel.vertical .carousel-inner > .next.left,
.carousel.vertical .carousel-inner > .prev.right {
  top: 0;
}
.carousel.vertical .carousel-inner > .active.left {
  top: -100%;
}
.carousel.vertical .carousel-inner > .active.right {
  top: 100%;
}

.carousel.vertical .carousel-control {
  left: auto;
  width: 50px;
}
.carousel.vertical .carousel-control.up {
  top: 0;
  right: 0;
  bottom: 50%;
}
.carousel.vertical .carousel-control.down {
  top: 50%;
  right: 0;
  bottom: 0;
}
.carousel.vertical .carousel-control .icon-prev,
.carousel.vertical .carousel-control .icon-next,
.carousel.vertical .carousel-control .glyphicon-chevron-up,
.carousel.vertical .carousel-control .glyphicon-chevron-down {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.carousel.vertical .carousel-control .icon-prev,
.carousel.vertical .carousel-control .glyphicon-chevron-up {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.carousel.vertical .carousel-control .icon-next,
.carousel.vertical .carousel-control .glyphicon-chevron-down {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.carousel.vertical .carousel-control .icon-up,
.carousel.vertical .carousel-control .icon-down {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}
.carousel.vertical .carousel-control .icon-prev:before {
  content: '‹';
}
.carousel.vertical .carousel-control .icon-next:before {
  content: '›';
}

.slide-images .item img {
  width: 100%;
  height: 100vh;
}

.calendly-area .title {
  margin-bottom: 25px;
}
.calendly-area .title h1 {
  margin-bottom: 0;
}
.mob-area .why-use {
  text-align: left;
  padding: 1% 5% 0;
}
.calendly-area {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.mob-area {
  padding-top: 1%;
}
.custom-backgrounds .title h1 {
  margin-bottom: 10px;
}
.custom-backgrounds .title p {
  margin-bottom: 30px;
}
.calendly-area .request-demo2 img {
  margin: 10px 0;
}

.mob-image {
}
.mob-image img {
  width: 100%;
}
.mob-area .why-use p {
  margin-bottom: 30px;
}
.mob-area .why-use p img {
  margin-top: 30px;
  width: 140px;
}
.mob-area .why-use .request-demo2 {
  display: block;
}

.exterior-tabs ul li.selected {
  background: #fff;
  color: black;
  border-radius: 0;
  border: 0;
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.exterior-tabs ul li button {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background: transparent;
  color: #444444;
  padding: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 0 !important;
}
.exterior-tabs ul li button.activ,
.exterior-tabs ul li button:hover,
.exterior-tabs ul li button.virtual {
  color: #ff7700 !important;
  border-bottom: 1px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.exterior-tabs ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 0;
}
.exterior-tabs ul li {
  padding: 3px;
  margin: 15px 20%;
  background: transparent;
  list-style: none;
  display: inline-block;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  box-shadow: 0px 2px 3px 0px #ddd;
}
.slates-tabs li {
  padding: 5px 10px !important;
  margin: 10px !important;
}
.custom-links ul li {
  padding: 0px 5px 0;
  margin-bottom: 5px;
}

.custom-backgrounds {
  padding: 75px 0px;
}
/* Virtual section  */
.virtual-images {
}
.virtual-images img {
  width: 100%;
}

.slates-tabs li.selected {
  border-top: 0px !important;
}

.v-image {
  padding: 0 15px !important;
}
.tab-middle {
  padding-left: 0;
}

@media (max-width: 600px) {
  .heading-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .heading-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .section-two-one p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .mob-change h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .try-images-area img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .heading-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .heading-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .left-section {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .left-section {
    margin-top: 10%;
  }
}

@media (max-width: 800px){
  .bulk-images-slider {
      width: 100%;
      margin: 0 auto ;
  }
}
@media screen and (max-width: 800px) and (min-width: 200px) {
  .title-area h1 {
    display: none;
  }
  .mac-pro-image img {
    width: 100%;
    margin-bottom: 20px;
  }

  .request-demo2 img {
    height: 40px;
    width: 130px;
  }
  .abs-img {
    top: 33%;
    width: 30% !important;
    left: 35%;
  }
  .graph-image img {
    width: 100%;
  }
  .graph-area .title {
    margin-bottom: 0px;
    text-align: center;
    padding-top: 0%;
  }
  .title h1 {
    font-size: 18px;
    line-height: 30px;

    margin-bottom: 20px;
  }
  .request-demo {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    line-height: initial;
    height: 40px;
    width: 130px;
    margin: 0 auto;
  }
  .graph-area {
    padding: 0 0;
  }

  .car-v2-left .why-tabs span {
    font-size: 18px;
    line-height: initial;
  }
  .car-v2-left .why-tabs p {
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: initial;
  }
  .why-tabs li button {
    padding: 10px;
  }
  .why-tabs img {
    width: 15%;
    margin-top: 10px;
  }
  .why-tabs li + li button img {
    margin-top: 7%;
  }
  .car-v2-left p {
    text-align: center;
  }
  .car-v2-tabs {
    padding: 0;
    margin-bottom: 20px;
  }
  .title-area {
    margin: 0;
  }
  .car-v2-left {
    order: 2;
    padding: 0;
  }
  .why-use h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .right-sections div {
    margin: 0;
    text-align: center;
  }
  .car-v2-left p,
  .why-use p {
    font-size: 12px;
    line-height: inherit;
    margin-bottom: 15px;
    text-align: center;
  }

  .first-tab-content img {
    width: 70%;
    margin: 3% auto 5%;
  }
  .gif-area {
    padding: 0px 0 40px;
  }
  .banner-slider {
    margin-bottom: 20px;
    margin-top: 55px;
  }
  .why-text {
    width: 80%;
    float: right;
  }
  .why-text h6 {
    font-size: 18px;
  }
  .graph-image {
    padding: 0px 20px;
  }
  .mac-pro-image {
    padding: 0 25px;
  }

  .cars-demo {
    height: auto;
    text-align: center;
    margin-top: 30px;
  }
  .title h6 {
    font-size: 14px;
    margin: 0;
  }
  .cars-video {
    height: auto;
  }

  .calendly-area {
    padding: 60px 0px 15%;
    margin-bottom: 0px;
  }
  .scroll-tab .why-use {
    padding: 0;
    margin: 0;
  }

  .graph-image img {
    padding: 0;
  }
  
  .mob-image {
    order: 2;
  }
  .calendly-area .request-demo2 img {
    margin: 0;
  }
  .mob-area .why-use p img {
    margin-top: 0;
    margin-left: 15px;
  }
  .mob-area .why-use .request-demo2 {
    display: inline-block;
  }
  .mob-area .why-use {
    text-align: left;
    padding: 10% 2%;
  }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .custom-request {
    order: 4;
    margin-bottom: 20px;
  }
  .custom-links {
    order: 2;
  }
  .custom-image img {
    height: 200px;
  }
  .custom-image {
    padding: 0;
    height: max-content;
  }
  .custom-links ul {
    display: flex;
    width: 100%;
  }
  .custom-links ul li button img {
    width: 100%;
    height: auto;
  }
  .custom-links ul li button {
  }

  .custom-links ul li {
    padding: 10px 5px 0;
  }
  .custom-backgrounds {
    padding: 40px 0px 20px;
  }
  .left-arrow {
    width: auto;
    float: unset;
    height: 70%;
    position: absolute;
    top: 15%;
  }
  .frame-img {
    left: 0px !important;
    right: auto;
    width: 100% !important;
    margin: 0 auto;
  }
  .arrows {
    text-align: center;
  }
  .btm-arrow {
    margin: 4% auto 0;
  }
  .exterior-tabs ul {
    display: flex;
    padding: 5px 10% !important;
  }
  .exterior-tabs ul li {
    margin: 5px 6px 0 !important;
    font-size: 12px;
    padding: 2px !important;
  }
  .tab-middle {
    padding: 0 15px;
  }
  .mob-area .why-use h1 {
    text-align: center;
  }
  .custom-backgrounds .title p { 
    font-weight: 400;
}
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .graph-area {
    padding: 0 0 60px !important;
  }
  .first-tab-content img {
    width: 100% !important;
    margin: 0 auto;
    margin-top: 30% !important;
  }
  .car-v2-left {
    order: 1 !important;
  }
  .right-sections {
    order: 2;
  }
  .graph-area .title {
    order: 1 !important;
    padding-top: 8%;
    margin: 0;
  }
  .graph-image {
    order: 2;
  }
  .first-tab-content img {
    width: 85%;
    margin-top: 10%;
  }
  .cars-video {
    height: 350px !important;
  }
  .cars-demo {
    height: 350px !important;
    margin-top: 0 !important;
  }
  .calendly-area {
    padding: 60px 0px 12% !important;
    margin-bottom: 0px;
  }
  .custom-links {
    order: initial !important;
  }
  .custom-links ul li button img {
    width: 100%;
    height: auto;
  }
  .custom-links ul {
    width: 80%;
  }
  .custom-image img {
    height: 270px;
  }
  .left-arrow {
    width: 15%;
    height: 50%;
    top: 15%;
  }
  .frame-img {
    width: 95% !important;
    margin: 0 auto;
  }
  .mob-area .why-use {
    padding: 5% 0% 0% !important;
  }
  .mob-image img {
    margin-top: 10%;
  }
  .mob-area .why-use p { 
    text-align: left;
}
.car-v2-left p, .why-use p { 
  text-align: left;
}

}

@media screen and (max-width: 1200px) and (min-width: 901px) {
  .why-text p {
    font-size: 12px;
    line-height: initial;
  }
  .why-text h6 {
    font-size: 18px;
    line-height: initial;
  }
  .first-tab-content img {
    width: 75%;
  }
  .custom-links ul li {
    padding: 30px 20px 0;
  }
  .graph-area {
    padding: 0 0 50px;
  }
  .custom-links ul li button img {
    width: 100%;
    height: 45px;
  }
  .custom-image img {
    height: 420px;
  }
  .custom-links ul {
    width: 75%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .custom-image img {
    width: 100%;
    position: relative;
    height: 505px;
  }
  .custom-links ul li button img {
    width: 100%;
    height: 80px;
  }
}
