.upload-image{
    text-align: center;
}
.upload-heading h1{
    font-weight: 500;
    font-size: 22px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.397475px; 
    color: #232121;
    margin-bottom: 30px;
}
.upload-heading h1 span{
    border-bottom: 3.1798px solid #FF7700;
}
.upload-go{
    background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
margin-bottom: 30px;
min-width: 180px;
filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
}
.upload-heading img{
    width: 100%;
    margin-bottom: 30px;
}