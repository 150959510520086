.upload-btn{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.upload-btn img{ 
        width: 23px;
        margin-right: 10px; 
}

.upload-btn span{

}
.download-section{
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}
.download-section-inner{
  margin-bottom: 25px;
}
.upload-sec{
    padding-bottom: 20px;
}
 .upload-top-btn{
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.screen-2-drop{
    width: fit-content;
    text-align: center;
}

.upload-area{
    text-align: center;
    justify-content: center;
}


.try-drag-drop .dropzone1 {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.try-drag-drop .dropzone1:hover {
  opacity: 1;
}
.try-drag-drop .dropzone1 img {
  margin-bottom: -30px;
  width: 15%;
}

.drop-box-text {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.drop-box-text div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.bg-main{
    background: #fff;
}
.remove-bg-box{
background: #FFFFFF;
border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
}
.remove-bg-area{
    padding: 10px 5% 5%;
}
.credit-text img {
    margin-right: 10px;
    width: 40px;
}
.credit-text span {
    color: #f70;
}
.cross-btn {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:max-content;
  }
  .cross-img {
    width: auto !important;
    cursor: pointer;
  }
  .credit-text{
    text-align: center;
    width: 85%;
    margin: 10px 0 15px;
    float: left;
  }

  .download-popup p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 10px;
  }
  .download-popup button.activate,
  .download-popup button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .download-popup {
    
  }
  
  .download-popup button {
    margin-top: 100px;
    margin-left: 15%;
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 70%;
  }
  .cross-btn {
    text-align: right;
    padding: 0 0 10px;
  }
  .download-popup button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .download-popup ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .download-popup ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .download-popup p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .download-popup p span {
    color: #ff7700;
  }
  .download-popup h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .border-1 {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
  }
  .download-popup input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .download-popup input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .download-btn-hd {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    /* padding: 10px 28px !important; */
    margin-top: 40%;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .containers {
    max-width: 600px !important;
    
    border-radius: 10px;
}
  .containers img {
    
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    /* margin-top: 100px; */
    margin: 50px auto 0;
}
.left-sec-upload-image {
    width: auto;
    height: 415px;
}
.rating-area{

}
.rating-area p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.rating-area p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.sec-2-right-card{
  
    padding: 15px;
}
.get-mrktplace{
    line-height: 50px!important;
    
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.get-mrktplace a{
    margin-left: 10px;
}

.css-1eg0sfo-skeletonStyles-Skeleton {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    margin-left: 50px;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: animation-bzdot9 1.2s ease-in-out infinite;
    animation: animation-bzdot9 1.2s ease-in-out infinite;
}
.categories{

}
.categories h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.categories h2 span{
    font-weight: 600;
font-size: 24px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.categories h2:before {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .categories h2:after {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .product-hunt-tag{
    width: auto;
    position: absolute;
    right: 115px;
    top: 0px;
}
  @media screen and (max-width: 1290px) and (min-width: 1025px){
.cross-btn {
    padding: 0 0 20px;
}
}
.myclass1{
  background-color: #fff;
  padding-left: 5% !important;
 
}
.btn2{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  /* or 17px */
  letter-spacing: 1.7px;
  border-bottom: 4px solid rgba(153, 150, 150, 0.1);
  color: rgba(24, 25, 31, 0.51);
      background-color:white; 
    text-align: center;
}
.btn2:hover {
    color:#ff7700 !important;
    border-bottom: 4px solid #ff7700 !important; 
}
.mactive1 {
  background-color:white !important;
  color:#ff7700 !important; 
  border-bottom: 4px solid #ff7700 !important; 
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  /* or 17px */
  letter-spacing: 1.7px;
  }
