.user-section {
  padding: 60px 0 50px;
}
.user-form {
}
.user-heading h6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  color: #232323;
  margin-bottom: 35px;
  text-align: center;
}
.user-heading h6 span {
  border-bottom: 2px solid #ff7700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.user-heading h6 img {
  width: 60px;
  margin-right: 10px;
}
.user-form form {
}
.user-form form label {
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #ff7700;
  display: block;
  width: 100%;
}
.user-form form input,
.user-form form select {
  display: block;
  width: 100%;
  padding: 6px 10px;
  border: 2px solid #ff770063;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 10px;
  margin: 10px 0;
  outline: 0 !important;
  height: 45px;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  /* color: #313131; 
opacity: 0.5; */
}
.user-form form input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #f9a826;
  opacity: 0.5;
}
.user-form form button {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  background: #ff7700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 6px 20px;
}
.bg-select input {
  width: 60% !important;
  float: left;
  border-right: 0 !important;
  border-radius: 10px 0px 0px 10px !important;
}
.bg-select button {
  width: 40%;
  float: right;
  height: 45px;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 0px 10px 10px 0px !important;
}
.bg-select {
}

.user-form form input:focus-visible {
  outline: 0 0 0 !important;
  border-color: #ff7700;
  box-shadow: 0 0 0 0 !important;
}
.bg-select button span {
  float: right;
  font-size: 25px;
}
.user-form form label span {
  font-weight: 400;
  font-size: 12px;
  line-height: initial;
  color: #000000;
}

.try-drag-drop {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin: 0px 0 40px;
  cursor: pointer;
}
.try-drag-drop .dropzone1 {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
  padding: 10px 10px 20px;
}
.try-drag-drop .dropzone1:hover {
  opacity: 1;
}
.try-drag-drop .dropzone1 img {
  margin-bottom: 0px;
  width: 15%;
}
.try-drag-drop .dropzone1 p {
  margin: 10px 0 0 !important;
}
.para-11 {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 1.7px;
  margin: 0 0 !important;
}

.para-2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 10px 0 0px !important;
}
.para-2 span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 10px;
  font-size: 14px;
}
.upload-dealership h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #ff7700;
  text-align: right;
  margin-top: 0%;
  padding-right: 10%;
  cursor: pointer;
}
.user-heading h3 {
  text-align: center;
  margin: 0% 0 5%;
}
.user-heading h3 img {
  width: 100px;
  height: 90px;
  border: 0.7px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
  object-fit: contain;
  margin-right: 20px;
}
.user-heading h3 button {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 5px 15px;
}
.user-heading h3 span {
  position: relative;
}
.user-heading h3 span svg {
  position: absolute;
  box-shadow: 0px 2px 5px 0px #ddd;
  padding: 7px;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  right: 10px;
  margin-top: -12px;
  background: #fff;
  z-index: 1;
  cursor: pointer;
}
.user-heading h4 {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  color: #000000;
  margin-bottom: 20px;
}
.logo-positioning {
  position: relative;
}
.logo-positioning img {
  width: 68%;
  margin: 0 auto;
}
.logo-area {
  position: absolute;
  top: 0;
  bottom: 0;
}
.logo-area .left {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  left: 12%;
  z-index: 2;
  margin-top: 2%;
  cursor: pointer;
}

.logo-area .right {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  right: 12%;
  z-index: 2;
  margin-top: 2%;
  cursor: pointer;
}
.logo-area .right-bottom {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  right: 5%;
  z-index: 2;
  margin-bottom: 2%;
  bottom: 0;
}
.logo-area .left-bottom {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  left: 5%;
  z-index: 2;
  margin-bottom: 2%;
  bottom: 0;
}
.logo-area img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 5px;
}

.upload-logo-btn {
  border-radius: 5px !important;
  margin-top: 30px;
}
