.remove-foreground{
 }

.remove-foreground .p-1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF3503;
    margin: 25px 0 0;
}
.remove-foreground .p-2{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #4F4444;
margin: 0;
}
.remove-foreground .p-2 span{
    color: #FF3503;
}
.remove-foreground .choose-button button{
    background: #FF3503;
border-radius: 20px;
    font-family: Poppins;
font-style: normal;
width: 178px;
height: 43px;
font-weight: 500;
font-size: 16px; 
color: #FFFFFF;

}







