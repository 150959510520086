.hd-download-popup{

}.hd-download-popup img{
    width: 30%;
}
.hd-download-popup h4{
    font-weight: 600;
    font-size: 22px;
    line-height: 39px; 
    letter-spacing: 0.03em; 
    color: #202225;
    margin: 0;
}
.hd-download-popup p{
    font-weight: 500;
    font-size: 13px;
    line-height: 30px; 
    letter-spacing: 0.03em; 
    color: #5E5E5E;
    margin: 10px 0;

}
.download-btn{
    background: #FF7700;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px; 
    letter-spacing: 0.03em; 
    margin:0 0 20px ;
    min-width: 180px;
}

.hd-download-popup p span{
    color: #FF7700;
}
.basic-download-popup{
    background: rgba(255, 166, 77, 0.2);
border-radius: 5px;
padding: 20px;
}
.basic-download-popup h5{
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 35px;
    color: #232121;}
    
/* img{
    width: 100%;
} */

.shoe-image{
    padding: 0;
    margin-bottom: 20px;
}
.dislike-text{

}
.dislike-text h5{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px; 
    color: #FF7700;
}
.dislike-text p{
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    
    color: #5F5F5F;
}
.dislike-text textarea{
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #7C7C7C;
width: 100%;
padding: 10px;
margin: 15px 0;
min-height: 160px;
}
.submit-btn{
    background: #FF7700;
    border-radius: 26px;
    font-weight: 600;
font-size: 14px;
min-width: 180px;
color: #FFFFFF;
}
.dislike-text form {
    text-align: center;
}

.upload-btn{
    border: unset;
    background: #FF7700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px; 
    color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
} 

.upload-top-btn{
    width: max-content;
    text-align: center;
    margin: 0 auto;
}

.screen-2-drop{
    width: fit-content;
    text-align: center;
}
