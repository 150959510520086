

.fabric-section{
    margin: 75px 0 0px;
    background: url('https://storage.googleapis.com/spyne-website/try-categories/fab-bg.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.06));
    margin-bottom: 30px;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 6%);
}


.fabric{
    
}
.fabric-back{

}
.fabric-back span{
color: #18191F;
font-size: 45px;
margin: 0px 10px 0 0;
vertical-align: top;

}
.fabric-back img{
    width: 90%;
}
.fabric-content{
    padding: 30px 15px 0;
}
.fabric-content h4{
   
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 54px;
    letter-spacing: 0.03em;
    color: #18191F;

}
.fabric-content h4 span{
    color: #ff7700;
}

.fabric-content p{
    font-weight: 500;
font-size: 20px;
line-height: 44px; 
text-transform: capitalize; 
}
.img-container img {
     
    width: 100%;
    object-fit: contain; 
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal9.svg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: 55%!important;
    height: 250px;
}
.card h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    text-align: center;
    margin: 10px 0 0;
}
 
.fabric12 img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal10.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.fabric13 img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal11.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.card { 
    
    background: #FFFFFF;
mix-blend-mode: normal;
border: 1px solid #FF9200;
box-sizing: border-box;
box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
backdrop-filter: blur(10px); 
border-radius: 9px;
padding: 20px;
transition: .2s ease-in-out;
}   
.card:hover{ 
    border: 1.5px solid #FF9200; 
    box-shadow: 4px 5px 12px rgb(255 152 0 / 27%);
}  
.fabric-main{
    padding: 30px 0 50px;
}
link:hover{
    text-decoration: none;
}
.text-black:hover {
    color: #000;
    text-decoration: none;
}

/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px){
    
    .fabric-section{ 
        background-position: top right;
        margin: 60px 0 0;
    }
    .fabric-back span {
        font-size: 36px;
        vertical-align: sub;
        line-height: initial;
    }
    .fabric-back img {
        width: 80%;
    }
    .fabric-back{
        text-align: center;
    }
    .fabric-content h4 { 
        font-size: 24px;
        line-height: 35px;
    }
    .fabric-back a{
        float: left;
    }
    .fabric-content p { 
        font-size: 14px;
        line-height: 25px;
    }
    .card {
        margin: 15px 0;
    }
    
    }
    

