

.suit-upload{
    padding: 0 10%;
}
.upload-screen{
    padding: 100px 0 60px;
    }
    .upload{
    text-align: center;
    }
    .upload h1{
        font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    color: #18191F;
    margin: 0;
    }
    .upload p{
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;  
        color: #000000;
        margin-bottom: 30px;
    }
    .upload p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .float-right{
        float: right;
    }
    .upload-cols{
        text-align: center;
    }
    .upload-single{
    
    }
   
    .upload-single p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .upload-single p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .rotate-btn{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 1%;
        margin-top: 5%;
    }
    .active{
        color: #FF7700!important;
        }
        .upload-single p.active {
            color: #FF7700!important;
        }
        .upload-single p.active span{
            background: #FF7700!important;
        }
    .upload-box{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 0px;
    }
    .upload-box img{
        width: auto;
        margin: 0 auto 10px;
    }
    .upload-box h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .upload-box p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .upload-text{
        text-align: center;
    }
    .upload-text h3{
        font-weight: 500;
        font-size: 20px;
        line-height: 45px; 
        color: #18191F;
        margin-bottom: 35px;
    }
     
    
    .next-btn{ 
        border-radius: 5px;
        padding: 5px 15px;
        min-width: 120px;
        border: 2px solid #FF7700;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
    }
    .next-btn img{
        width: 20px;
        vertical-align: sub;
        margin-right: 5px;
    }
    .heading{
    
    }
    .heading h2{
        font-weight: normal;
    font-size: 24px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #000000;
    margin-bottom: 30px;
    }
    .fabric-upload{

    }
.fabric-upload h3{
    font-size: 16px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: normal;
    color: #FF7700;
    margin-bottom: 20px;
}
.fabric-upload img{
    width: 75%;
}
.fabric-upload span{
    color: #ff7700;
    font-size: 20px;
    border: 1px solid #ff7700;
    border-radius: 50%;
    padding: 0px 8px;
    line-height: 0px;
    vertical-align: top;
    position: relative;
    margin-left: -15px;
    cursor: pointer;
}



.create-model{
    background: url('https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/bg1.png');
  
    background-repeat: no-repeat;
    padding: 100px 0 50px;
    background-size: 100% 95%;
    font-family: 'Manrope';
}
.heading h6{
    font-weight: bold;
font-size: 20px;
line-height: 38px; 
color: #929292;
font-family: 'Manrope';
}
.heading{
    padding: 5% 15px 0 5%;

}
.heading h1{
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 50px;
    line-height: 70px;  
    color: #18191F;
    margin: 10px 0px 30px;
}
.heading h1 span{
    color: #FF7700;
}
.heading p{
    font-family: 'Manrope';
}

.demo{
    background: #FFFFFF;
border: 2px solid #FF7700;
box-sizing: border-box;
box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px; 
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #ff7700; 
min-width: 180px; 
margin-left: 10px;;
}
.nxt-btn{
    background: #FF7700;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.model-gif{
    text-align: center;
}
.model-gif img{
    max-width: 100%;
    width: 75%;
}

/* ########## Category ############# */

.category-select{
    padding: 120px 0 50px;
}

.heading{

}
.heading h1{
    font-weight: 800;
    font-size: 45px;
    line-height: 72px;  
    color: #18191F;
    text-align: center;
    margin-bottom: 30px;
}
.category-list ul{
padding: 2% 0 5%;
list-style: none;
}
.category-list ul li{
    display: inline-block;
    width: 20%;
    padding: 10px 15px;
}
.category-list ul li:hover{
    border: 2px solid black;
    cursor: pointer;
}
 
.selected{
    border: 2px solid red; 
}

.category-list ul li div{
    width: 100%; 
    text-align: center;
}
.category-list ul li img{
    width: 100%;
}
.category-list ul li p{
    font-weight: 500;
font-size: 18px;
line-height: 36px; 
color: #4F4F4F;
}

.nxt-btn{
    background: #FF7700;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.upload-image{
    text-align: center;
}
.upload-heading h1{
    font-weight: 500;
    font-size: 22px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.397475px; 
    color: #232121;
    margin-bottom: 30px;
}
.upload-heading h1 span{
    border-bottom: 3.1798px solid #FF7700;
}
.upload-go{
    background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
margin-bottom: 30px;
min-width: 180px;
filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
}
.upload-heading img{
    width: 100%;
    margin-bottom: 30px;
}


/* upload Section */

.back-category-btn{
    /* position: absolute; */
    margin-left: 3%;
}

.upload-screen{
    padding: 100px 0 60px;
    }
    .upload{
    
    }
    .upload h1{
        font-weight: 800;
        font-size: 35px;
        line-height: 50px;  
        color: #18191F;
    }
    .upload p{
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;  
        color: #000000;
    }
    .upload p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .float-right{
        float: right;
    }
    .upload-cols{
        text-align: center;
    }
    .upload-single{
    
    }
    .upload-single:after{
        display: inline-block;
        content: "";
        position: absolute;
        top: 25%;
        left: 50%;
        width: 100%;
        /* height: 10px; */
        /* border-top: 1px dashed #B5B4B4; */
        z-index: -1;
    }
    .upload-single+.upload-single:after{
        border: 0!important;
    }
    .upload-single p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .upload-single p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .upload-box{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 20px;
    }
    .upload-box h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .upload-box p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .upload-text{
        text-align: center;
    }
   
     
    .skip-btn{
        text-align: center;
        background: #FFFFFF;
    border: 2px solid #FF7700;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #FF7700;
    min-width: 120px;
    padding: 7px 15px;
    margin-right: 15px;
    }
    .next-btn{
        text-align: center;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    padding: 7px 15px;
    min-width: 120px;
    border: 2px solid #FF7700;
    }

    .disabled-next{
        text-align: center;
        border-radius: 8px;
        padding: 7px 15px;
        min-width: 120px;
        background-color:#B5B4B4
    }

/* dropzone */
.upload-kurti{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 3%;
    padding-left: 3%;
}
.select-model{
    text-align: center;
    padding: 5%;
}
.try-drag-drop {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
    cursor: pointer;
  }

  .drop-box-text {
    position: fixed;
    width: 50%;
    height: 50%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .drop-box-text div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
.uploaded-img{
    padding: 100px 0 60px;
    width: 33.3%;
    margin-left: 33.34%;
    text-align: center;
}
.select-template{
    text-align: center;
    padding: 5%;
}
.back-button{
    border: unset;
    border-radius: 11px;
    padding: 0;
  }


  /* @Responsive  */
  @media screen and (max-width: 767px) and (min-width: 200px){
  .upload-screen{
    padding: 80px 0 60px 60px;
}
.upload h1 {
    font-size: 20px;
    line-height: 30px;
}
.upload-box {
    margin: 0;
}
.upload-box img {
    width: 50px;
}
.upload-box h4 {
    font-size: 15.6182px;
}
.upload-box{
    margin-bottom: 5px;
}
.upload-text h3{
font-size: 16px;
margin-bottom: 0;
line-height: 25px;
}
.uploaded-img {
    padding: 80px 0 40px;
    width: 100%;
    margin-left: 0; 
}
.select-template button{
    padding: 7px 20px;
}
  }



