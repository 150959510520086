.container-category{
    width: 100%;
    padding: 0px 15px;
}
.poppins {
    font-family: 'Poppins', sans-serif;
    margin-top: 65px;
}
.categories-main-right{
    margin-top: 25px!important;
    padding-left: 15px;
}
.card { 
    /* box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 15px; */
    padding: 15px;
    /* position: relative;
    height: 100%; */
    background: #FFFFFF;
mix-blend-mode: normal;
border: 1px solid #FF9200;
box-sizing: border-box;
box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
backdrop-filter: blur(10px); 
border-radius: 9px;
transition: .2s ease-in-out;
}   
.card:hover{ 
    border: 1.5px solid #FF9200; 
    box-shadow: 4px 5px 12px rgb(255 152 0 / 27%);
}
.bg-blue {
    background-color: #D8F4FF;
}

.bg-skin {
    background-color: #96ECD2;
}

.bg-yellow {
    background-color: #FDF08E;
}

.bg-purple {
    background-color: #CABEBE;
}

.bg-indigo {
    background-color: #C7D0DE;
}

.bg-pink {
    background-color: #FDDCAA;
}
.card h6{
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
    margin: 0;
    line-height: 28px;
}
.card h6 span{
    background: #000000;
    border-radius: 9px 0px 0px 0px;
    padding: 5px;
    color: #fff;
    font-size: 14px; 
    font-weight: 500;
}
.card h6 span img{
    width: auto;
    background: none;
    background-size: auto;
    height: auto;
}
.grid1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px 25px;
    margin-bottom: 45px;
}

.grid > * {
    min-height: 190px;
}

.img-container {
    /* position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 55.25%; */
}

.img-container img {
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%; */
    width: 100%;
    object-fit: contain;
    /* object-position: right; */
    /* filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.25)); */
    background: url('https://storage.googleapis.com/spyne-website/try-categories/car-bg1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%!important;
    height: 160px;
}

.fab {
    position: fixed;
    right: 10%;
    bottom: 10%;
    border-radius: 50%;
    width: 87px;
    height: 87px; 
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    right: 25px;
    bottom: 25px;
    background: #717070;
    z-index: 2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dash-marker{
    bottom: 15%;
    position: fixed;
    right: 3.4%;
    display: none;
    transition: .2s ease-in-out;
}
.fab:hover .dash-marker{
    display: block;
}
.text-black {
    color: #000;
    text-decoration: none;
    padding: 0;
}
.text-black:hover {
    color: #000;
    text-decoration: none;
}
link:hover {
    color: #000;
    text-decoration: none;
}
.category-title{

}
.category-title h2{
    font-weight: 600;
font-size: 28px;
line-height: 48px; 
color: #494949;
margin: 0px 0 20px;
}
.category-title h2 span{
color: #ff7700;
}
.card h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    text-align: center;
    margin: 10px 0 0;
}
.comng-soon{
    position: absolute;
    bottom: 0%;
    left: 0;
    margin: 0;
    background: #48CA45;
    border-radius: 0px 10px 0px 15px;
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-align: center;
    min-width: 100px;
    z-index: 1;
} 
.beta{
    background: #ff7700;
}
.cars-try{
    /* padding: 5px;
    box-shadow: 0px 3px 10px 0px #ff77004a;
    border-radius: 20px; */
    margin-bottom: 25px;
}
.non-border{

}
.foot img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.fashion img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.product img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.fabric img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.furnish img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.cars-2{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bluecar-bg.png')!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
}
.cars-2{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/food-bg.png')!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
}
.card p {
    
    position: fixed;
    right: 0;
    font-weight: 500;
    top: 3px;
}
.card p span{
    background: linear-gradient(91.1deg, #FFBE18 59.64%, #FFD53E 116.92%);
    border-radius: 0 8px 0 8px; 
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;  
    font-weight: 500;
    
}
/* .drawer-links{
    padding: 5px;
}
.drawer-links Link , .drawer-links a{
    font-weight: bold;
font-size: 14px;
line-height: 142%; 
align-items: center;
text-align: center;
letter-spacing: 0.005em;
color: #616161;

}
.dropdown-btn{
    text-align: center;
}
.drawer-links img{ 
    margin: 0 10px 0 0;
} */

/* .drawer-links:hover{
    background: rgba(255, 119, 0, 0.45);
opacity: 0.5;
border-radius: 0px;
}
.drawer-links:hover Link{
   color: #ff7700;
} */


/* Sidebar-Drawer */
 


.main-menu:hover,nav.main-menu.expanded {
width:250px;
overflow:visible;
}

.main-menu {
background:#fff;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
position:fixed;
top:65px;
bottom:0;
height:100%;
left:0;
width:70px;
overflow:hidden;
padding: 0;
-webkit-transition:width .2s linear;
transition:width .2s linear;
-webkit-transform:translateZ(0) scale(1,1);
z-index:1000;
}

.main-menu>ul {
    padding: 15px 0px;
  height: 100%;
}

.main-menu li {
position:relative;
display:block;
width:250px;
padding: 0px;
margin: 8px 0;
}

.main-menu li>a {
position:relative;
display:table;
border-collapse:collapse;
border-spacing:0;
font-weight: normal;
font-size: 15px;
line-height: 138%;  
letter-spacing: 0.0075em;
padding: 5px 0px 5px 10px;
color: #444444;
text-decoration:none;
-webkit-transform:translateZ(0) scale(1,1);
-webkit-transition:all .1s linear;
transition:all .1s linear;
  width: 100%; 
  font-family: 'Poppins';
}

.main-menu .nav-icon {
position:relative;
display:table-cell;
width:30%;
height:36px;
text-align:center;
vertical-align:middle;
font-size:18px;
}
.main-menu  img{
    padding: 5px 5px 5px 15px;
    width: 55px;
    margin: 0;
}
.main-menu .nav-text {
position:relative;
display:table-cell;
vertical-align:middle;
width:80%; 
padding: 5px 10px 5px 20px;
}

 
.no-touch .scrollable.hover {
overflow-y:hidden;
}

.no-touch .scrollable.hover:hover {
overflow-y:auto;
overflow:visible;
}

a:hover,a:focus {
text-decoration:none;
}

nav {
-webkit-user-select:none;
-moz-user-select:none;
-ms-user-select:none;
-o-user-select:none;
user-select:none;
}

/* nav ul,nav li {
outline:0;
margin:0;
padding:0;
} */
.main-menu li:hover>a,nav.main-menu li.active>a,.dropdown-menu>li>a:hover,.dropdown-menu>li>a:focus,.dropdown-menu>.active>a,.dropdown-menu>.active>a:hover,.dropdown-menu>.active>a:focus,.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,.dashboard-page nav.dashboard-menu ul li.active a {
    color: #ED8909; 
background: rgba(255, 214, 178, 0.52);
}
.area {
float: left;
background: #e2e2e2;
width: 100%;
height: 100%;
}
 .nav-text button{
    position: relative;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    padding: 5px 0px 5px 10px;
    color: #444444;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1,1);
    transition: all .1s linear; 
    margin: 5px 0;
    color: #444444!important;
    padding: 0;
    background: transparent!important;
    border: 0;
    font-family: 'Poppins';
    width: 100%;
    text-align: left;
 }
 .nav-text button:hover, .nav-text button:focus{
    color: #ED8909!important; 
 }
.accor-btn{
    width: 100%;
    display: flex;
}
.accor-btn:hover{
    color: #ED8909; 
    background: rgba(255, 214, 178, 0.52);
}
.accor-btn span{
    padding: 5px 10px 5px 15px!important;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1,1);
    transition: all .1s linear; 
    margin: 5px 0;
    color: #444444;
    font-size: 15px;
    font-family: 'Poppins';
}
.accor-btn:hover span{
    color: #ED8909; 
}
.inner-links{
    padding: 0px 0px 0px 70px;
}
.inner-links li{
width: 100%;
border-left: 2px solid #E0E0E0;
margin: 0;
}
.inner-links li:hover{
    border-left: 2px solid  #ED8909; 
}
.inner-links li a{
    /* border-left: 2px solid #E0E0E0; */
    margin: 0;
}
.inner-links li a span{
    width: 100%;
    padding: 10px 10px;
    display: block;
    color: #444444;
}
.inner-links li:hover span{
    color: #ED8909; 
}
.accor-btn i{
    float: right;
    line-height: inherit;
}
a.disabled{
    pointer-events: none;
}
.car-img{
    height: 182px!important;
}
.categories-block{
    padding: 0 0 0 80px;
}







/* @Responsive  */

@media screen and  (max-width: 800px){
    .grid1{ 
        grid-template-columns: 48% 48%;
        grid-gap: 30px 10px; 
    }
    .card h4 {
        font-weight: 500;
        font-size: 14px;
    }

    .comng-soon{ 
        padding: 5px 8px; 
    min-width: 80px;
    }
    .category-title h2 { 
        font-size: 20px;
        margin: 0px 0 20px;
    }
    .grid > * {
        min-height: 120px;
    }
    .fab { 
        width: 60px;
        height: 60px; 
        right: 15px;
        bottom: 15px;
    }
    .fab svg{
        
    height: 22px;

    }
    .dash-marker{
        bottom: 11%;
        position: fixed;
        right: 10%;
        width: 100px;
        height: auto!important;
    }
    .poppins { 
        margin-top: 45px;
    }
    .main-menu{ 
        position: fixed; 
            width: 60px;
            top:60px;
    }
    .categories-block {
        padding: 0 10px 0 55px;
    }
    .cars-try{ 
        padding: 0;
    }
}

@media screen and  (max-width: 800px) and (min-width: 768px){
.grid1 {
    grid-template-columns: 31% 31% 31%!important;

}
}


@media screen and  (min-width: 1400px){
.dash-marker {
    bottom: 12%;
    position: fixed;
    right: 2.9%;
}
.category-title  h2 { 
    margin: 10px 0 40px;
}
.main-menu .nav-text { 
    width: 75%;
}
.main-menu img {
    margin-right: 10px;
}

}


