.request-demo{
    font-style: normal;
    font-size: 16px; 
    text-align: center;
    letter-spacing: 0.1px;
    background: #00a8ff;
    border-radius: 10px;
    color: #FFFFFF;
    margin: 0;
    padding: 8px 25px;
    transition: .2s ease-in-out;
    border: 2px solid #00a8ff;
    height: 42px;
    font-weight: bold;
    border-radius: 5px;
    line-height: initial;
    width: fit-content;
    box-shadow: 0px 0px 5px 0px #6c757d;
}

.request-demo:hover{
    border: 2px solid #00a8ff;
    background: #fff;
    color: #00a8ff;
}
.car-v2-left{

}
 
.why-use h1 span{
    border-bottom: 2px solid #00a8ff;
    padding-bottom: 5px;
    color: #1C1E52;
}
 

.why-use h1{
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 42px;   
     margin-bottom: 25px;
    letter-spacing: 0.02em;
    color: #323232;
    padding: 0;
}
 
 
.why-tabs li button { 
    background: #FFFFFF!important;
    box-shadow: 0px 4px 11px rgb(0 0 0 / 25%)!important;
    border-radius: 10px!important;
    margin: 0 0px 10px!important;
    padding: 15px 20px;
    text-align: left;

    width: 100%;
    height: 100%;

}
.why-tabs{
    padding: 0;
    list-style: none;
    margin: 0 0 10px;
}
.why-tabs li button{
    font-weight: 600!important;
    font-size: 24px!important;
    border-radius: 10px!important;
    line-height: 45px!important; 
    color: #1D293F!important;
    border-left: 5px solid #fff;
    background-color: #fff!important; 
    transition: .3s ease-in-out;
}
.why-tabs img{
    float: left;
    margin-right: 0;
    width: 11%;
    vertical-align: middle;
    margin-top: 1%;
}
.why-text{
    width: 87%;
    float: right;
}
.why-text h6{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: #1D293F;
    margin: 0;
}
.why-text p{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px; 
    color: #7C7C7C;
    margin: 0;
    display: flex;
}
.why-tabs li button.activate, .why-tabs li button:hover {
    /* box-shadow: 0px 0px 20px #ff7700!important;  */
    border-left: 5px solid #00a8ff;
  }
  .banner-text{
    margin-top: 5%;
    text-align: left;
    }
    .banner-text h1{
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 40px;
        color: #1C1E52;
        margin: 0 0 25px;
        letter-spacing: 1px;
    }
    .banner-text p{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 45px; 
        color: #444444;
    }
.banner-text p span{
color: #00a8ff;
}
.title{
text-align: center;
}
.title h1{
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 35px; 
color: #00a8ff;
margin-bottom: 45px; 
margin-top: 0;
}
.title h1 span{
    border-bottom: 2px solid #00a8ff;
    color: #1C1E52;
}    
.exterior-tabs .title ul li button.activ, .exterior-tabs .title ul li button:hover, .exterior-tabs .title ul li button.virtual{
    color:  #00a8ff!important;
    border-bottom: 1px solid  #00a8ff!important;
    border-radius: 0;
    background: transparent;
    }
    .graph-area .title{
    text-align: left;
    padding-top: 10%;
}
.graph-area{
    padding: 60px 0px;
}
.graph-area .title{
    text-align: left;
    padding-top: 10%;
}

.graph-image{

}
.graph-image img{
width: 100%;
}        
.exterior-tabs  ul{
    text-align: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 25px;
}
.exterior-tabs  ul li{ 
    padding: 3px;
    margin: 10px 5px;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    box-shadow: 0px 2px 3px 0px #ddd;
}
.slates-tabs li{
    padding: 5px 10px!important; 
    margin: 10px!important;
}
.exterior-tabs  ul li.selected{
    background: #fff; 
    color: black;
    border-radius: 0;
    border: 0;
    color: #ff7700 !important;
    border-bottom: 2px solid #FF7700 !important;
    border-radius: 0;
    background: transparent;
}
.exterior-tabs  ul li button{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    border-radius: 0!important;
}





@media screen and (max-width: 800px) and (min-width: 200px){
.title h1 { 
    font-size: 18px;
    line-height: 30px;

margin-bottom: 20px;

}
.request-demo{
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    line-height: initial;
    height: 40px;
    margin: 0 auto;
}
.graph-area {
padding: 0 0 ;
}
.banner-text {
    margin-top: 10%;
    text-align: center;
    order: 2;
}
.banner-text h1 { 
    font-size: 20px;
    line-height: initial; 
    margin: 0 0 15px;
}
.banner-text p { 
    font-size: 12px;
    line-height: initial; 
}

.why-tabs li button { 
    padding: 10px;
}
 
.why-tabs img {
    width: 15%;
    margin-top: 10px;
}
.why-tabs p{
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: initial;
    }
    .exterior-tabs ul li {
        margin: 5px 6px 0!important;
        font-size: 12px;
        padding: 2px!important;
    }
    .graph-area .title {
        text-align: center;
        padding-top: 5px;
        order: 2;
        margin-bottom: 20px;
    }
    .title-area p {
        display: none;
    }
    
    .banner-text .request-demo {
        margin: 0 0;
    }
}

@media screen and (max-width: 900px) and (min-width: 768px){
.banner-text {
    margin-top: 5%;
    text-align: left;
    order: inherit;
}
}