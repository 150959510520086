.bulk-processing {
  padding: 120px 0 75px;
}
.bulk-area {
  border: 2px dashed #c4c4c4;
  box-sizing: border-box;
  filter: drop-shadow(-10px 33px 100px rgba(0, 0, 0, 0.25));
  border-radius: 13px;
  padding: 30px 70px;
  background: #fff;
  text-align: center;
  min-height: 500px;
  transition: 0.2s ease-in-out;
}
.bulk-area h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #1c1e52;
  text-align: center;
  margin: 0;
}
.bulk-area p {
  font-weight: 600;
  font-size: 14px;
  line-height: 45px;
  color: #444444;
  text-align: center;
}
.bulk-image-left {
}
.bulk-image-left ul {
  list-style: none;
  text-align: left;
  justify-content: left;
  padding: 0;
}
.bulk-image-left ul li {
}
.bulk-image-left ul li img {
}
.autowidth {
  width: 30px;
}
.bulk-image-left ul li span {
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  color: #5a5a5a;

  padding: 0 10px;
}
.bulk-image-right {
}
.bulk-image-right p {
  margin: 0;
  text-align: right;
}
.bulk-image-right p a {
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  text-decoration-line: underline;
  color: #ff3503;
}

.bulk-image-right p a img {
  margin-left: 10px;
}
.mybtn {
  background: #ff3503;
  border-radius: 20px;
  width: 178px;
  height: 43px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.bulk-area .mybtn {
  margin: 3% auto;
}

.spinner {
  width: 80px;
  height: 80px;
  margin: 15% auto 0;
  /* border: .55em solid currentColor!important;
    border-right-color: transparent; */
}

.bulk-area .outer-box {
  margin-top: 10% !important;
}

.bulk-area .outer-box > a {
  color: #ff3503;
  font-weight: 500;
  font-size: 18px;
  line-height: 45px;
  animation: color-chn 4s;
}

.complete-process {
  animation: color-chn 4s;
}

/* a {
    color: #FF3503
} */

a:hover {
  text-decoration: none;
  color: #ff3503;
}

.proceed-btn a:hover {
  color: #fff !important;
}

/* @keyframes color-chn {
    0% {
        color: #6666;
    }
    100% {
        color: #FF3503
    }
} */

@keyframes color-chn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}
