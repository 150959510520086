.main-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}
.main-inner-wrapper{
    padding: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    width: 898px;
    height: 516px;
}
.main-wrapper h1{
    font-size: 30px;
    letter-spacing: 1.8px;
    text-align: left;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: normal;
    color: #272727;
}
.user-email{
    font-size: 20px;
    letter-spacing: 1.2px;
    text-align: left;
    color: #0579DF;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: medium;
}
.boxes{
    margin-top: 40px;
    display: grid;
    grid-template-columns: 32% 32% 31%;
    grid-gap: 20px;
}
.dashboard-small-box{
    border: 0.5px solid #707070;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 20px 20px;
    font-size: 20px;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: bold;
    letter-spacing: 1.38px;
    text-align: left;
    color: #272727;
}
.dashboard-small-box p{
    margin: 0;
}
.upgrade-plan{
    margin-top: 40px;
    border: 0.5px solid #707070;
    width: 100%;
    padding: 10px 10px 20px 20px;
    font-size: 20px;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: bold;
    letter-spacing: 1.38px;
    text-align: left;
    color: #272727;
   
}
.upgrade-plan p {
    margin: 0;
}
.upgrade-plan-para{
    font-size: 20px;
    letter-spacing: 1.2px;
    color: #0579DF;
}
@media (max-width:800px){
    .main-wrapper{
      position:unset;
      padding: 100px 10px 0px 10px;
      transform: none;
    }
    .main-inner-wrapper{
      width: 100%;
      height: 100%;
    }
    .boxes{
      display: flex;
      flex-direction: column;
    }
    .main-wrapper h1{
        font-size: 20px;
        letter-spacing: 1.8pt;
        font-weight: bold;
    }
    .user-email{
        font-size: 14px;
        letter-spacing: 0.84pt;
        font-weight: bold;
    }
    .dashboard-small-box,.upgrade-plan p {
        font-size: 18px;
    }
}