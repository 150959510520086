.main-banner{
  
    text-align: center;
    background: linear-gradient(180deg, #FD8061 0%, #F39755 100%);
}
.left-banner-text, .right-banner-text{
    padding: 0;
}
.left-banner-text p, .right-banner-text p{
color: #FFFFFF;
font-weight: 600;
font-size: 12px;
line-height: 18px;
text-align: center;

color: #FFFFFF;
}
.left-banner-text p::before {
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 60%;
    height: 0.7px;
    top: 31.1%;
    right: -15px;
}
.left-banner-text p::after{
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 0.7px;
    height: 5%;
    top: 26.3%;
    right: 52%;
}
 .right-banner-text p{
    padding-top: 55%;
 }
 
.right-banner-text p::before {
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 60%;
    height: 0.7px;
    top: 21%;
    left: -15px;
}
.right-banner-text p::after{
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 0.7px;
    height: 5%;
    top: 21%;
    right: 48%;
}
.banner-row{
    padding-top: 12%;
}
.banner-box {
    background:#fff;
    text-align: center;
    margin: 0;
    
    padding: 20px 12%;
}
.banner-box img{
    width: auto;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}
.banner-box h1{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}
.banner-box p{
    color: #444444;
    font-weight: 400;
    margin-bottom: 20px;
}
.main-banner form{
    border: 1px dashed #696969;
box-sizing: border-box;
border-radius: 12px;
padding: 20px;
    text-align: -webkit-center;
    margin-bottom: 30px;
    cursor: pointer;
}
.main-banner form:hover{
    background: linear-gradient(180deg, #FFFBF4 0%, rgba(250, 189, 175, 0) 100%);
border: 1px dashed #FF3503; 
}
.main-banner form img{
    display: block;

}
.main-banner form label{
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
}
.main-banner form label span{
display: block;
}
.main-banner form strong{
    font-weight: 600;
}
.after-image-section{
    background: #fff;
    padding-bottom: 5%;
}
.after-image{

}
.image-area img{
    width: 100%;
}
.mybutton{
    background: #E85F3D;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 8px;
}



/* Responsive Media Query  */

@media screen and (max-width: 767px) and (min-width: 200px){
    .banner-row{ 
        padding-top: 35%;
    }
    .banner-box {  
        padding: 20px 20px 40px;
    }
.mybutton{
    width: 80%;
    margin: 0 auto;
}
.image-area img{
width: 100%;
    margin-bottom: 50px;
    padding: 0;
    background: #FFFFFF;
    padding-bottom: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.image-area{
    margin: 0;
}



}



@media screen and (max-width: 900px) and (min-width: 768px){
.right-banner-text p{
    padding-top: 65%;
 }
.banner-box{
    padding: 20px 10%;
}
.main-banner form{
    padding: 20px 15px;
}
.left-banner-text p::after{
top: 23.3%;
}
.left-banner-text p::before{
    top: 28%;
    }


}




@media screen and (max-width: 1200px) and (min-width: 901px){
    .left-banner-text p::before{
        top: 40%;
     }
     .left-banner-text p::after{
        top: 35.1%;
    right: 51%;
     }

     .right-banner-text p::before { 
        top: 15%;


    }
    .right-banner-text p::after{
    top: 15%;
    right: 49%;
}

}
