.footer-main {
  background: #0b0d17;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 50px 50px 40px;
}
.footer-main input {
  width: 100%;
  height: 45px;
  background: #ffffff;
  box-shadow: 10px 15px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25.5px;
  border: unset;
  padding-left: 20px;
}
.footer-main input:focus {
  outline: none;
}
.footer-main a {
  text-decoration: none;
  transition: 0.2s ease-out;
  font-weight: 100;
  vertical-align: sub;
  color: #fff !important;
}
.footer-main a:hover {
  text-decoration: none;
  color: #ff7700;
  margin-left: 5px;
}
.footer-main h6 a:hover {
  margin-left: 0;
}
.footer-logo {
  padding-top: 8%;
}
.footer-logo-image {
  width: 80%;
  margin: 0 auto;
}
.social a {
  color: white;
}
.footer-links {
}
.footer-links h2,
.social h2 {
  font-weight: 600;
  margin: 0px 0px 15px;
  text-transform: capitalize;
}
.footer-links p,
.social p {
  font-weight: 100;
  margin-bottom: 10px;
}
.footer-links p a span {
  font-family: cursive;
  font-size: 16px;
  color: #ff3503;
  vertical-align: super;
}

.social p img {
  margin-right: 5px;
}

.news-letter {
  padding-top: 7%;
}
.news-letter p {
  font-size: 1.2rem;
}
.footer-bottom {
  background-color: #000;
  padding: 10px 50px;
}
.footer-bottom p,
.footer-bottom p a {
  color: #d9dbe1;
  font-size: 13px;
  margin: 0;
}
.footer-bottom ul {
  margin: 0;
  padding: 0;
  justify-content: right;
  text-align: right;
  width: 100%;
  list-style: none;
}
.footer-bottom ul li {
  padding-left: 20px;
  display: inline-block;
}

.play-store-badge{
  height: auto;
    margin-top: 10px;
    width: 80%;
}



@media (max-width: 800px) {
  .footer-main {
    font-size: 10px;
    line-height: 15px;
    color: #ffffff;
    padding: 50px 0px 30px;
  }
  .footer-main input {
    width: 100%;
  }

  .news-letter {
    text-align: center;
  }
  .footer-links h2,
  .social h2 {
    font-weight: 600;
    margin: 0px 0px 15px;
    text-transform: capitalize;
    font-size: 18px;
  }
  .footer-bottom {
    padding: 15px 10px;
    text-align: center;
  }
  .footer-bottom p {
    margin-bottom: 10px;
  }
  .footer-bottom ul {
    text-align: center;
  }
}

@media (min-width: 1400px) {
  .news-letter {
    padding-top: 5%;
  }
  .footer-logo {
    padding-top: 5%;
  }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .footer-logo-image {
    margin-bottom: 20px;
  }
  .footer-logo {
    padding-top: 0;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  .footer-logo-image {
    width: 80%;
    margin-bottom: 20px;
    object-fit: contain;
    margin-top: 35%;
  }
  .footer-logo {
    padding-top: 0;
    text-align: center;
  }
  .footer-links h2,
  .social h2 {
    font-size: 25px;
  }
  .footer-links p,
  .social p {
    font-size: 18px;
  }
  .news-letter {
    padding-top: 4%;
  }
  .footer-main input {
    width: 60%;
  }
  .footer-main {
    padding: 50px 0px;
  }
  .footer-main a {
    font-size: 14px;
  }
}
