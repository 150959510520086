:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.one {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* position: absolute; */
  /* left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); */
  text-align: center;
  margin: auto;
  margin-top: 5%;
  color: #ff7700;
}

.two {
  height: 20vh;
  width: 12vw;
  display: block;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
}

.three {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */
  text-align: center;
  margin-top: 4%;
  color: #232121;
}

.button {
  text-align: center;
  margin: auto;
  margin-bottom: 10%;
}
