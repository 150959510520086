.upload-footwear-image{
  padding: 0px 0 25px;
}
.left-sku{
  margin: 0;

}
.left-sku h5{
  font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 36px;
color: #FF7700;
margin: 0;

}
.upload-sec button img {
  width: 23px;
  margin-right: 10px;
}
.right-credits{
  font-size: 14px;
 text-align: right;
  color: #232121;
  line-height: 36px;
}
.right-credits img{
  margin-right: 10px;
  width: 45px;
  vertical-align: text-top;
}
.right-credits span{
  color: #ff7700;
}
.background-tabs{
list-style: none;
padding: 0;
justify-content: center;
}
.background-tabs li{
  padding: 20px;
  display: inline-block;
  width: auto;
  margin: 0 30px;
}
.background-tabs li button{
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  border-bottom: 2px solid transparent;
  text-align: center;
  letter-spacing: 0.5px;
  background: transparent;
  color: #5E5E5E;
  border-radius: 0;
  padding: 0;
}

.background-tabs li button.active, .background-tabs li button:hover{
  border-bottom: 2px solid #FF7700;
  color: #FF7700;
}
.left-frame{
  width: 20%;
  /* display: inline-block; */
  height: 360px;
  object-fit: contain;
}
.background-tabs div.edited_image{
  width: 80%;
  height: 360px;
  position: relative;
  object-fit: contain;
}
.main-image{
  width: 100%!important;
  height: 100%!important;
  object-fit: contain!important;
  object-position: center; 
  position: inherit!important;
}
.frame{
  position: absolute;
  width: 80%;
  right: 0%;
  height: 360px;
  border:1px solid #e9ecef;
}
.bottom-frame{
  width: 80%;
  margin: 0 0 auto 20%;
  padding: 20px;
}


.footwear-main{
  padding: 10px 0 -1px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  margin-bottom: 50px;
  width: 76%;
  margin-left: 12%;
}
.background-content{
  padding: 10px;
    width: 31%;
    margin-left: 1.6%;
    box-shadow: -1px 0 10px rgb(0 0 0 / 15%);

}
.background-content form{
  margin: 0;
  margin-bottom: 10px; 
}
.background-content form select{
  background: #FEFEFE;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 8px 0px 0px 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.15px;
  padding: 6px 10px;
  color: #999696;
  outline: 0;
  box-shadow: 0px 0px 0px;
  width: 90%;
  border-right: 0;
}
.background-content form select option{
 
}

.background-content form img{
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  /* border-radius: 8px; */
  padding: 12px;
  height: 45px;
  width: 10%;
  border-left: 0;
  border-radius: 0px 8px 8px 0px;
}
.background-content h4{
  text-align: center;
  font-weight: bold;
font-size: 22px;
line-height: 110%; 
letter-spacing: 0.5px;
margin: 0 0 20px;
color: #232121;
}
.background-content h5{
  font-weight: 500;
  font-size: 16px;
  line-height: 110%; 
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 0 15px;
  color: #232121;
}
.default-countries{
  list-style: none;
  padding: 0;
  /* display: flex; */
  justify-content: center;
  text-align: center;
}
.default-countries li{
  padding: 7px;
  display: inline-block;
  text-align: center;
}
.default-countries li span{
  font-weight: normal;
  font-size: 10px;
  line-height: 16px; 
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px; 
  color: #343C44;
  background: #FFE5B1;
border-radius: 16px;
padding: 7px 10px;
}
.brand-tabs{
  padding: 0;
  list-style: none;
  text-align: center;
}
.brand-tabs li{
  padding: 10px 15px;
  display: inline-block;

}
.brand-tabs li button{
  padding: 10px; 
background: #FFFFFF;
border: 2px solid #E2E2E2;
border-radius: 5px;
width: 85px;
height: 85px;
position: relative;
}
.cross-icon{ 
  width: 25px!important;
  position: absolute!important;
  top: -11px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  box-shadow: -3px 2px 6px 0px #ddd;
  right: -15%;  
}
.brand-tabs li button.active, .brand-tabs li button:hover{
  border: 2px solid #FF7700;
  color: #FF7700;
}
/* .brand-tabs li button:hover .cross-btn{
display: block;
} */
.brand-tabs li button img{
  width: 100%;
}
/* .apply-btn{
  margin-left: 12%;
}
.apply-btn button{
  width: 250px;
  margin: 3% auto;
} */

.note{
  margin: 20px 0;
}

.note p{
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 27px;

color: #000000;
}

.brand-slider{

}


.brand-slider .slide-content{
  padding: 15px 0px 0;

}
.brand-slider .slide-content button{
  padding: 10px; 
background: #FFFFFF;
border: 2px solid #E2E2E2;
border-radius: 5px;
width: 85px;
height: 85px;
position: relative;
}

.brand-slider .slide-content button.active, .brand-slider .slide-content button:hover{
  border: 1px solid #EAEAEA;
box-sizing: border-box;
box-shadow: 0px 1px 8px #FF7700;
}
.brand-slider .slide-content input{
  display: block;
  margin: 10px auto 0;
  outline: 0;
  box-shadow: 0px 0px 0px;
}

.brand-slider .slide-content button img{
  width: 100%;
}
.slide-content p{
  border: 2px solid #C9CDD3;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px auto;
  cursor: pointer;
}
.slide-content p.colored{
  background: #C9CDD3;
}
.download-popup{
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 10px;
}
.download-popup .cross-btn{
  text-align: right;
  padding: 0 0 10px;
}
.download-popup .cross-btn img{
  width: auto!important;
  cursor: pointer;
}
.download-popup button h6{
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: .5px;
  color: #232121;
  margin: 0 0 10px;
}
.download-popup input {
  margin-right: 10%;
  border: 0;
  outline: 0;
}
.download-popup ul{
  padding-left: 15%;
  list-style: none;
  margin: 0 ;
}
.download-popup ul li{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: .5px;
  color: #232121;
}
.download-popup p{
  font-weight: 600;
  line-height: 25px;
  letter-spacing: .5px;
  color: #232121;
  padding-left: 15%;
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0!important;
}
.download-popup p span{
  color: #f70;
}
.border-1{
width: 100%;
border-top: 1px solid #ddd;
margin-bottom: 25px;
}
.download-popup button {
  border: 1px solid transparent;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 0;
  color: #000;
  padding: 10px 40px;
  text-align: left;
  margin-bottom: 10px;
}
.download-popup button.activ, .download-popup button:hover {
  background: rgba(5,121,223,.02);
  border: 1px solid #0579df;
  box-sizing: border-box;
  border-radius: 10px;
}

.download-popup .apply-btn button{
  text-align: center!important;
  background: #f70!important;
  color: #fff!important;
border-radius: 5px !important;
border-color: #f70;
width: 80%;
}
.bg-box{
  position: absolute;
  z-index: 9;
  left: 4%;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgb(128 91 58 / 40%);
  border-radius: 5px;
  margin-top: 10px;
}
.bg-box ul{
  padding: 0;
  list-style: none;
  /* background: #FFFFFF; */
  /* box-shadow: 0px 2px 8px rgb(128 91 58 / 40%); */
  display: flex;
}
.bg-box ul li{
display: inline-block; 
margin: 0 auto;   
padding: 5px 10px;
}
.bg-box ul li p{
  font-weight: 500;
  font-size: 9px;
  line-height: 110%; 
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0;
  color: #5E5E5E;
}
.bg-box ul li p span{
  text-align: right; 
     margin-left: 10px;
}
.bg-box ul li img{
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.bg-box h6{
  font-weight: 500;
font-size: 12px;
line-height: 110%; 
text-align: center;
letter-spacing: 0.5px;

color: #5E5E5E;
}

.bg-box ul li p span img{
  width: 8px!important;
  height: auto!important;
  padding-left: 1px;
  border: 0!important;
}
.bg-box:after {
  content: '';
  position: absolute; 
  top: -10px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  left: 45%;
  margin: 0 auto;
}
.add-more {
  position: absolute;
  left:58%;
  font-size: 30px;
  z-index: 1;
  background: #ff7700;
  font-weight: 100;
  color: #fff;
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
  top: 2%;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  cursor: pointer;
  top:20%
}

.more-text {
  position: absolute;
  z-index: 999;
  left: 72%;
  top: 13.5%;
  display: none;
}


.add-more:hover + .more-text {
  display: block !important;
}

.add-more button {
  padding: 0 !important;
}


.upload-car-name-gif img {
  width: 40%;
  max-height: 20px;
}

.tab-content {
  overflow: hidden;
  height: 550px;
  overflow-y: scroll;
  margin: 0;
  padding: 22px 0px;
}

.container-fluid {
  max-width: 100% !important;
  margin: 0 15px !important;
}

.uploaded-cars {
}
.uploaded-cars-single {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  margin: 0 0 20px;
  padding: 5px 0px;
}
.uploaded-cars-single img {
  width: 100%;
  padding: 0;
  height: 105px;
  object-fit: cover;
  cursor: pointer;
}
.uploaded-cars-single p {
  text-align: center;
  padding: 5px 5px;
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-car-name-gif img {
  width: 40%;
  max-height: 20px;
}

.reload-cancel span img {
}
.reload-cancel {
  opacity: 1;
}

.uploaded-cars-single:hover .reload-cancel {
  opacity: 1;
}

.reload-cancel2 {
  text-align: right;
  justify-content: flex-end;
  padding: 0;
  height: 20px;
  align-items: center;
}
.reload-cancel2 img {
  /* padding: 5px; */
  /* background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%; */
  height: 35px;
  width: 35px;
  margin: 0 0 5px 10px;
}
.reload-cancel2 span img {
  position: absolute;
  right: -2%;
  top: -10%;
  margin: 0;
  cursor: pointer;
}


.bulk-bg button {
  border-radius: 5px;
  width: auto;
  margin: 10px auto;
}
.bulk-bg {
  text-align: center;
}
.bulk-bg {
  margin: 0;
}
.bulk-bg img {
  margin-bottom: 10px;
}

.background-grid h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  margin: 20px 0px;
  color: #ff7700;
}

.background-grid {
  padding: 0 5px;
}

.background-grid img {
  width: 100%;
  height: auto;
  padding: 0;
}
.background-grid h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.5px;

  color: #232121;
}

.apply-btn-section {
  text-align: center;
  padding: 20px;
}
.apply-btn,
.apply-btn-section button {
  background: #ff7700;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  width: 100%;
}

.apply-btn-section h6 {
  font-size: 18px;
  color: #2b2a2a;
  font-weight: 500;
}

.apply-btn-section p {
  font-size: 16px;
  color: #646464;
  font-weight: 400;
}

.apply-btn-section img {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0 5px;
}

.right-top-credits{
  text-align: right;
  padding: 0;
  margin-bottom: 10px;
}
.right-top-credits button{
  padding: 5px 10px;
  width: auto;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #FF7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.right-top-credits img{
  width:25px !important;
}
.credit-text {
  font-size: 14px;
  font-family: poppins;
  color: #232121;
  text-align: center;
  width: 86%;
    /* padding-left: 55%; */
  /* display: flex;
  justify-content:flex-end; */
}

.credit-text span {
  color: #ff7700;
}

.credit-text img {
  margin-right: 10px;
  width: 40px;
}
.add-credit{
  color: white !important;
}
.add-credit img{
 
}

.left-sec{
  width: 66.66%;
}

.border-1 {
  width: 100%;
  /* height: 1px; */
  border-top: 1px solid #ddd;
  margin-bottom: 25px;
  margin-top: 20px;
}
.marketplaces{
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15%;
  grid-row-gap: 10px;
  margin-left: 20px;
}

.toggle1 {
  position: relative;
  color: #f39e53;
}

.toggle1 input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider2 {
  position: relative;
  display: block;
  cursor: pointer;
  background: #d64d4d;
  margin-left: 190px;

box-shadow: 0px 4px 4px rgb(255, 255, 255);
border-radius: 20px;
  transition: 0.2;
  width: 50px;
  height: 28px;
}

.slider2::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 22px;
  background: rgb(255, 255, 255);
  transition: 0.2s;
  border-radius: 50%;
  left: 3px;
  top:3px

}

input:checked + .slider2 {
  background-color: #78f353;
}

input:checked + .slider2::before {
  transform: translateX(21.5px);
}

@media (max-width: 800px){
    
}












