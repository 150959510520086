
.navbarRemovebg {
  height: 100px;
  width: 100%;
  background-color: transparent;
  position: fixed;
  padding: 20px 40px;
  z-index: 200;
  /* box-shadow: 0px 3px 6px #00000038; */
}
.header-design{
  background: #fff;
  box-shadow: 0px 2px 14px #C4C4C4;
}
.login{
  cursor: pointer;
}

.navbarRemovebg > .navbarRemovebg-header {
  display: inline;
}

.navbarRemovebg > .navbarRemovebg-header > .navbarRemovebg-title {
  display: inline-block;
  font-size: 22px;
  color: black;
  padding: 10px 10px 10px 10px;
}

.navbarRemovebg > .navbarRemovebg-btn {
  display: none;
}
.dropdown-menu {
  width: auto;
}
.navbarRemovebg > .navbarRemovebg-links {
  display: inline;
  float: right;
  font-size: 18px;
}

.navbarRemovebg > .navbarRemovebg-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: black;
  transition: .2s ease-in-out;
}
.navbarRemovebg > .navbarRemovebg-links > div {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: black;
}
select{
  border: unset;
  background-color: unset;
}

.navbarRemovebg > #navbarRemovebg-check {
  display: none;
}
.dropdown{
  cursor: pointer;
}
.dropdown :hover{
  color: #fff !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #ff7700;
}
#use-cases{
  background-color: transparent !important;
  color: black !important;
  border: unset !important;
  border-radius: 0 !important;
  padding: 13px 20px;
  font-family: 'Quicksand';
  font-weight: 600;
  font-size: 15px;
  transition: .2s ease-in-out;
}
.btn:focus{
  box-shadow:  none !important;
}
.nav-left{ 
      margin-left: auto;
      margin-right: 0; 
}
.nav-right{
  /* text-align: end;
  justify-content: flex-end; */

}
.nav-right ul{
list-style: none;
  /* display: flex; */
  margin: 0;
  padding: 0;
}
.nav-right ul li{
  display: inline-block;
  padding: 13px 15px;
  text-decoration: none ;
  color: #000 ;
  font-size: 15px;
  font-family: 'Quicksand';
}
.nav-right ul li a.order-shoot{
  font-weight: 800;
font-size: 15px; 
line-height: 30px;

}
.nav-right ul li a span{
  color: #FF7700;
  border-bottom: 2px solid; 
}
.nav-right ul li a{
  font-size: 15px;
  transition: .2s ease-in-out;
}
.nav-right ul li a.sign-in{
  background: #FF7700;
  border-radius: 5px;
  color: #fff;
  padding: 7px 25px;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 45%); */
min-width: 130px
}
.nav-right ul li a:hover{
  color: #fff!important;
}
.nav-inner-list{

}
.nav-inner-list button{

}
.nav-inner-list ul{
display: none;
}
.nav-inner-list .dropdown-menu.show {
  display: grid;
    width: inherit;
    right: 0;
    left: auto;
    padding: 0;
}
.nav-inner-list li{
  padding: 0px!important;
}
#navbarTogglerDemo02{
  text-align: right!important;
  margin-left: auto;
  margin-right: 0!important;
  
  padding: 0;

}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  font-size: 14px;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #ccc;
  border: 0;
  transition: .3s ease-in-out;
}

.dropdown-item {
  padding: 6px 10px;
  border-radius: 3px;
  transition: .3s ease-in-out;
}
.nav-item{

}
.navbar-nav{
  padding: 0;
  margin: 0;
}

.logo-image{
  width: 160px;
  height: auto;
}








@media (max-width:800px) {
  .navbarRemovebg{
    z-index: 9999;
  }
  .navbarRemovebg-btn{
    margin-top: 20px;
    margin-right: 10px;
  }
  .navbarRemovebg > .navbarRemovebg-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .navbarRemovebg > .navbarRemovebg-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .navbarRemovebg > .navbarRemovebg-btn > label:hover,.navbarRemovebg  #navbarRemovebg-check:checked ~ .navbarRemovebg-btn > label {
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  .navbarRemovebg > .navbarRemovebg-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .navbarRemovebg > .navbarRemovebg-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color:white;
    height: 100px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    margin-top: 25px;
    text-align: center;
  }
  .navbarRemovebg > .navbarRemovebg-links > a {
    display: block;
    width: 100%;
  }
  .navbarRemovebg > .navbarRemovebg-links > div {
    display: block;
    width: 100%;
  }
  .navbarRemovebg > #navbarRemovebg-check:not(:checked) ~ .navbarRemovebg-links {
    height: 0px;
  }
  .navbarRemovebg > #navbarRemovebg-check:checked ~ .navbarRemovebg-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
    height: auto;
    padding-bottom: 20px;
  }
  .login{
    border: 1px solid blue;
    width: 100px !important;
    margin: 0px auto;
    padding: 0px !important;
    border-radius: 5px;
    outline: none;
  }

  #use-cases {
    padding: 10px;
  }

  .nav-right { 
    justify-content: flex-start;
}
.nav-right ul {
  margin: 0;
  padding: 0;
}
.header-design {
  padding: 5px 0px;
}
.navbar-nav { 
  text-align: left;
}
#navbarTogglerDemo02{
  padding: 0;
}
.nav-right ul li { 
  padding: 13px 0px;
}


}



@media (min-width: 1366px) {
  .logo-image{
    width: 200px;
    height: auto;
  }
  .navbar-light .navbar-nav .nav-link{
    font-size: 17px;
  }
  .nav-right ul li a.sign-in{
    font-size: 17px;
  }
}
@media (min-width: 1680px) {
  .logo-image{
    width: 250px;
    height: auto;
  }
  .navbar-light .navbar-nav .nav-link{
    font-size: 20px;
  }
  .nav-right ul li a.sign-in{
    font-size: 20px;
  }
}
@media (min-width: 1920px) {
  .logo-image{
    width: 260px;
    height: auto;
  }
  .navbar-light .navbar-nav .nav-link{
    font-size: 22px;
  }
  .nav-right ul li a.sign-in{
    font-size: 22px;
  }
}





