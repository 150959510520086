.tab-section{
  display: flex;
  justify-content: center;
}
.tab-section button{
    border: unset;
    background: #FFFFFF;;
    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    padding: 12px 45px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    margin-left: 24px;
}
.tab-section button:hover{
    color: #272727 !important;
}

.active-usecase-button{
    border: unset;
    background: #FF7700 !important;
    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    box-shadow: none !important;
    border-radius: 13px;
    padding: 12px 45px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    margin-left: 24px;
}
#disabled-button{
    margin: 0px !important;
    padding: 0px !important;
    border: unset !important;
    background: transparent;
    box-shadow: unset;
    width: 216px;
    margin-left: 24px !important;
    margin-top: -10px !important;
    opacity: 0.5;
}
#disabled-button img{
    width: 100%;
}
@media(max-width:800px){
    .tab-section{
        display: grid;
        grid-template-columns: repeat( 4, 1fr );
        grid-gap: 10px !important;
        grid-row-gap: 10px !important;
        padding: 0px 20px;
    }
    .tab-section button{
        grid-column:  span 2;
    }
    .tab-section button:nth-last-child(1):nth-child(odd) {
        grid-column: 2 / span 2;
    }
    .tab-section button{
        border: unset;
        background: #FFFFFF;
        border: 1px solid #9C9C9C;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 13px;
        padding: 10px 7px !important;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
        width: auto;
        margin-left: 0px;
    }
    .tab-section button:hover{
        color: #272727 !important;
    }
    .active-usecase-button{
        border: unset;
        background: #FFFFFF;
        border: 1px solid #9C9C9C;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 13px;
        padding: 10px 7px !important;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: #FFFFFF !important;
        width: auto;
        margin-left: 0px;
    }
    #disabled-button{
        margin: 0px !important;
        padding: 0px !important;
        border: unset !important;
        background: transparent;
        box-shadow: unset;
        width: auto;
    }
    #disabled-button img{
        width: 100%;
    }
}