.scan {
  /* width: 98%; */
  text-align: center;
  padding: 6px 2px;
  /* position: absolute; */
  left: 50%;
  right: 0;
  top: 0%;
  margin: auto;
  /* border: dashed 0.25
    em
     rgb(90, 85, 85); */
     bottom: 0%;
     height: 100%;
  transform: translate(-50%);
  opacity: 0.8;
}

.scan::after {
  content: '';
  /* background: conic-gradient(
    from 250.23deg at 50% 50%,
    #eee28d -15.84deg,
    #b19de3 109.29deg,
    #ef8b9c 256.45deg,
    #eee28d 344.16deg,
    #b19de3 469.29deg
  ); */
  background: #ef8b9c;
  width: 100%;
  height: 0.5em;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.8;
  z-index: 2;
  animation: 2s infinite ease-in-out roll;
}

/* .scan img {
  height: 30px;
  width: 98%;
} */



@keyframes roll {
  0% {
    transform: translatey(-150px); 
  }
  50% {
    transform: translatey(150px); 
  }
  100% {
    transform: translatey(-150px);
  }
}

/* @Responsive */
@media screen and (min-width: 1440px) {
@keyframes roll {
  0% {
    transform: translatey(-210px); 
  }
  50% {
    transform: translatey(210px); 
  }
  100% {
    transform: translatey(-210px);
  }
}

}

@media screen and (min-width: 1680px) {
  @keyframes roll {
    0% {
      transform: translatey(-240px); 
    }
    50% {
      transform: translatey(240px); 
    }
    100% {
      transform: translatey(-240px);
    }
  }
  
  }
  @media screen and (min-width: 1920px) {
    @keyframes roll {
      0% {
        transform: translatey(-270px); 
      }
      50% {
        transform: translatey(270px); 
      }
      100% {
        transform: translatey(-270px);
      }
    }
    
    }
  @media screen and (max-width: 767px) {
    @keyframes roll {
      0% {
        transform: translatey(-135px); 
      }
      50% {
        transform: translatey(135px); 
      }
      100% {
        transform: translatey(-135px);
      }
    }
    
    }


