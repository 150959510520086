
.grocery-banner{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Grocery/Group%205892432%20(1).png');
    margin-top: 45px;
    padding: 25px 3%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 760px;
}
.grocery-banner-text{
    padding-top: 10%;
    text-align: left;
}
.grocery-banner-text h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    color: #323232;
}
.grocery-banner-text p{
    font-weight: 600;
    font-size: 24px;
    line-height: 45px; 
    color: #444444;
    margin: 20px 0px;
}
.grocery-banner-text p span{
    color: #FF7700;
}
.grocery-banner-image{

}
.grocery-banner-image img{
    width: 100%;
}
.gif-area{
    padding: 70px 0px 50px;
}
.twenty-area .title h1{
    margin-bottom: 10px;
}
.slider {
    height: 100%;
    width: 3px;
    background: #fff;
}
.slider img {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 43%;
    margin-left: -25px;
}
.twenty-image{
    margin-bottom: 40px;
}
.twenty-area{
    padding: 60px 0px;
}
.twenty-area .title h1{
    margin-bottom: 10px;
}
.twenty-area .title{
    margin-bottom: 25px;
}
.title {
    text-align: center;
}
 
.title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 45px;
    color: #FF7700;
    margin-bottom: 45px;
    margin-top: 0;
}
.title h1 span {
    border-bottom: 2px solid #FD8061;
    color: #1C1E52;
}
.title h1 span {
    border-bottom: 2px solid #FD8061;
    color: #1C1E52;
}
.no-border {
    border: none!important;
}



.grocery-tabs .exterior-tabs ul li {
   
}
.exterior-tabs  ul li button{
    transition: .2s ease-in-out;
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    border-radius: 5px!important;
    margin: 0; 
    padding: 5px 15px; 
    box-shadow: -1px 2px 5px 0px #ddd;
}
.exterior-tabs  ul li button.active, .exterior-tabs  ul li button:hover{
    color: #fff !important;
 
 border-radius: 0;
 background: #FF7700;
 }
 .exterior-tabs  ul{
     text-align: center;
     justify-content: center;
     padding: 0px;
     margin: 10px 0;
 }
 .exterior-tabs  ul li{  
    padding: 3px;
    margin: 0 10px 5%;
     background: transparent;
     list-style: none;
     display: inline-block; 
     border-radius: 0; 
 }
.v-image{
    margin-top: 5%;
}
.category-images{

}
.category-images img{
width: 80%;
}

/* @Responsive  */

@media screen and (max-width: 767px) and (min-width: 200px){
    .grocery-banner-text{
        text-align: center;
    }
.grocery-banner-text h2 { 
    font-size: 20px;
    line-height: 30px;
}
.grocery-banner-text p { 
    font-size: 13px;
    line-height: 20px;
    margin: 0px 0px 10px;
}
.grocery-banner-text{
    padding: 8% 0 0;   
}

.grocery-banner { 
    height: auto;
}
.exterior-tabs ul li button {
    font-size: 12px;
    line-height: 20px;
    padding: 5px;
}

.exterior-tabs ul li {
    padding: 5px;
    margin: 0 0 0%; 
    width: 25%;
}
.virtual-images img {
    width: 90%;
    margin: 0 auto;
}
.v-image { 
    padding: 10px 15%!important;
}

.twenty-area .title h1 { 
    font-size: 18px;
    line-height: 30px;
}

.twenty-area{
    padding: 30px 0px 0;
}
.alendly-area .title {
    margin-bottom: 25px;
    padding: 0;
}








}


@media screen and (max-width: 900px) and (min-width: 768px){
    .grocery-banner {
       height: auto;
        padding: 25px 0% 20%;
    }
    .grocery-banner-text h2 { 
        font-size: 35px;
    }
    .grocery-banner-image {
        padding-top: 5%;
    }
    .gif-area {
        padding: 70px 0px 0px;
    }




}















