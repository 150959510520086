@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inria+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family:'Inria Sans','Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'sans-serif', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth !important;
}

body {
  background-image: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Landing+Page+%E2%80%93+1%402x.png);
  background-size: cover;
  /* height: 100vh; */
  background-repeat: repeat-y;
  font-family: "Quicksand";
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  display: inline-block !important;
  padding: 13px 10px !important;
  text-decoration: none !important;
  color: #000 !important;
  font-weight: 800;
  font-family: "Quicksand" !important;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-block !important;
  padding: 13px 10px !important;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 15px;
  font-family: "Quicksand" !important;
}
.navbar-light .navbar-toggler {
  border: unset !important;
  border-color: white;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-nav {
  margin-left: 20px;
}
.navbar-nav ul li {
  padding: 0 10px;
}
.container {
  max-width: 75%;
}
.container-fluid {
  max-width: 95%;
}
.landing-main-wrapper {
  background-image: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Landing+Page+%E2%80%93+1%402x.png);
  background-size: cover;
  background-repeat: repeat-y;
  font-family: "Quicksand" !important;
  font-weight: 600;
  height: 500px;
}
.left-box {
  padding: 80px;
}
.outer-box {
  max-width: 378px;
  max-width: 410px;
  box-shadow: 0px 3px 6px #00000075;
  border-radius: 10px;
  text-align: center;
  /* padding-bottom: 35px; */
}
.outer-box-section-1 {
  flex-direction: column;
  text-align: center;
  padding-top: 60px !important;
  box-shadow: none !important;
}
.outer-box-section-1 div {
  margin: 50px auto 0;
  max-width: 300px;
  max-height: 200px;
  border: 2px dashed #9a9a9a;
  padding: 40px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: none !important;
  opacity: 0.2;
}
.outer-box-section-1 div:hover {
  opacity: 1;
}
.outer-box-section-1 div:focus {
  outline: none;
}
.outer-box-section-1 p {
  margin: 0;
  padding: 0;
}
.para-1 {
  font-size: 18px;
  font-weight: normal;
  margin: 5px 0;
}
.para-2 {
  margin: 0;
  font-size: 12px;
}
.choose-image-button {
  border-radius: 30px;
  max-width: 200px;
  height: 58px;
  outline: none;
  padding-left: 40px;
  padding-right: 40px;
}
.right-box {
  text-align: center;
  color: #0579df;
  padding: 70px;
}
.category-list {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  margin-top: 20px;
  grid-gap: 10px;
}
.cards {
  width: 120px;
  height: 120px;
  margin: 0px auto;
  /* box-shadow: 0px 3px 6px #00000038; */
  padding: 20px;
  border-radius: 10px;
  border: 1px solid grey;
}
.close {
  outline: none;
}
.close:focus {
  outline: none;
}
.close:active {
  outline: none;
}

.cards p {
  margin-top: 20px;
}
.comming-soon {
  font-size: 11px;
  background-color: #0579df;
  opacity: 1;
  position: relative;
  top: -40px;
  width: 120px;
  left: -20px;
  border-radius: 10px 10px 0px 0px;
}
.comming-soon p {
  color: white;
}
.hide {
  display: none;
}
.comming-soon-img {
  position: relative !important;
  top: -40px !important;
}
.comming-soon-p {
  position: relative !important;
  top: -50px !important;
}
.modal-header {
  border: unset !important;
  padding: 0;
}
.screen-2-top {
  margin: 0 auto;
  border: 2px dotted #0579df;
  border-radius: 10px;
  width: auto;
  padding: 10px;
}
.screen-2-top img {
  margin: 0px;
  padding: 0px;
}
.screen-2-top p {
  margin: 0px;
  padding: 0px;
}
.sec-2-para-1 {
  font-size: 20px;
  font-family: "Inria Sans", sans-serif;
}
.sec-2-para-2 {
  color: #0579df;
  font-size: 18px;
  font-family: "Inria Sans", sans-serif;
}
.sec-2-para-2 b {
  color: black;
}
.sec-2-left-card {
  background-color: white;
  border-radius: 10px;
  padding-top: 40px;
  padding-bottom: 20px;
}
.sec-2-toggle {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.containers {
  max-width: 600px !important;
  border-radius: 10px;
}
.containers img {
  width: auto;
  height: 400px;
  max-width: 100%;
  object-fit: contain;
  /* margin-top: 100px; */
  margin: 100px auto;
}
.sec-2-right-card {
  padding: 35px;
  background-color: white;
  height: 185px;
  border-radius: 10px;
}
.category-container {
  border: 1px solid #00000029;
  box-shadow: 0px 3px 6px #00000029;
  height: 120px;
  border-radius: 10px;
}
.category {
  color: #0579df;
  font-style: 18px;
  font-family: "Inria Sans", sans-serif;
  text-align: center;
  border: 1.5px solid #0579df;
  max-width: 150px;
  padding: 10px;
  margin: 0px auto;
  margin-top: 30px;
  font-weight: bold;
}
.category-header {
  font-size: 14px;
  font-family: "Inria Sans", sans-serif;
  color: #5d5d5d;
  width: 133px;
  margin: 0px auto;
  padding-left: 12px;
  margin-top: -58px;
  background: white;
}
.download-section {
  height: 350px;
  background-color: white;
  margin-top: 70px;
  border-radius: 10px;
  margin-left: -35px !important;
  width: auto;
  padding: 35px;
  margin-right: -35px;
}
.download-section-inner {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 35px;
  width: auto;
  height: 278px;
  margin: 0px auto;
  text-align: center;
}

.download-section-inner p:nth-child(0) {
  font-size: 25px;
  color: #000000;
  line-height: 33px;
  font-family: "Inria Sans", sans-serif;
}
.download-section-inner button {
  width: 183px;
  height: 56px;
  border-radius: 30px;
}
.download-section-inner p:nth-child(2) {
  font-size: 10px;
  color: #707070;
  margin: 0px;
}
.download-section-inner p:nth-child(3) {
  margin-top: 10px;
  font-size: 12px !important;
  color: #0579df !important;
  font-family: "Inria Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
}

.download-section-inner span {
  color: #0579df;
  font-size: 16px;
  font-family: "Inria Sans", sans-serif;
  letter-spacing: 0.32px;
  margin: 0px;
  position: unset;
  cursor: pointer;
}
.download-section-inner p:nth-child(5) {
  margin: 0px;
}
.download-section-inner p:nth-child(6) {
  font-size: 10px;
  color: #707070;
  margin: 0px;
}
.download-section-inner p:nth-child(7) {
  margin-top: 7px;
  font-size: 18px;
  color: #0579df;
  font-family: "Inria Sans", sans-serif;
}
.otp-field {
  display: flex;
  flex-direction: row;
  margin-left: -20px;
}
.otp-field input {
  margin: 0px auto;
}
.otpScreen-image {
  margin-left: 120px;
}
.modal-content {
  border-radius: 20px !important;
}
button:disabled {
  cursor: not-allowed !important;
}
.form-control:focus {
  border-color: #ccc;
  outline: 0;
  box-shadow: none;
}
.magnifier_zoom {
  position: absolute !important;
  z-index: 100;
  background-size: 24px;
  background-color: #fff;
  height: 100%;
  width: 80% !important;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.screen-2-drop {
  width: 500px;
}
.left-sec-upload-image {
  width: auto;
  height: 450px;
}
.screen-2-upload {
  cursor: pointer;
  width: auto;
}
.screen-2-upload :focus {
  outline: none;
}
.loader-edited-screen-2 {
  width: 80px !important;
  height: 80px !important;
  margin-top: 30%;
}
.left-sec-upload-image > div:first-child > div:first-child {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.left-sec-upload-image > div:first-child {
  height: 100%;
}
.left-sec-upload-image > div:first-child > div:first-child > img {
  width: auto !important;
  background-size: cover;
  object-fit: fill;
  height: 100%;
}
.image-overlay-box {
  position: relative;
  min-height: 450px;
  max-width: 56% !important;
  border: 1px solid #eeeeee;
  margin-bottom: 20px;
  padding-top: 20px;
}
.magnifier_zoom_bg {
  position: absolute !important;
  z-index: 100;
  width: 80% !important;
  background-size: 24px;
  background-color: #fff;
  height: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+);
  box-shadow: 5px 0px 5px #00000038;
  border-radius: 10px;
}
.email-verification {
  margin: 0px auto;
}
.loader-edited {
  width: 80px;
  height: 80px;
  margin-top: 160px !important;
  margin-bottom: 150px !important;
}
.otpScreen-email {
  display: flex;
  justify-content: center;
}
.submit-button-otpScreen {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}
.otpScreen-image {
  display: flex;
  justify-content: center;
  margin: 0px auto;
}
.otpScreen-success-image {
  display: flex;
  justify-content: center;
  margin: 0px auto;
}
.get-5-banner-text {
  background-color: #0579df;
  height: 80px;
  padding-top: 25px;
  color: white;
  font-family: "Inria Sans", sans-serif;
  font-size: 20px;
}
.pay-as-you-go {
  text-align: center;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  color: #232121;
  margin: 0;
  padding: 10px 0;
}
.rate span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #ff3503;
}
.rate {
  color: #1c1e52;
  font-size: 12px;
  margin-bottom: 25px;
}
.saving {
  color: green;
  font-size: 12px;
  font-weight: bold;
}
.credit {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 35px;
  color: #232121;
  display: inline-block;
}
.credit span,
.credit strike {
  font-weight: 500;
  font-size: 13px;
  line-height: normal;
  line-height: initial;
  color: #5f5f63;
  display: block;
}
.price-container {
  padding: 0;
  margin: 0px 0px 10px;
}
.main-price-container {
  max-height: 350px;
  padding: 15px 0px 10px 0;
  overflow: hidden;
  overflow-y: scroll;
}
.main-price-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-price-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.main-price-container input {
  vertical-align: middle;
  margin-right: 15px;
}
/* .price-container::-webkit-scrollbar{
  display: none;
} */
.total_credit {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #1c1e52;
  display: inline-block;
  padding-right: 3px;
}
.bulk-text {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #0579df;
  line-height: 0.1em;
  margin: 10px 0 20px;
  margin-top: 55px !important;
}
.bulk-text span {
  background: #fff;
  padding: 0 10px;
  position: unset !important;
}
.buy-now-pricing {
  color: white !important;
  background-color: #0579df;
  border-radius: 30px !important;
  padding: 10px 33px;
  font-size: 20px !important;
  font-family: "Inria Sans", sans-serif;
}
.bulk-contact-button {
  background-color: white !important;
  border: 2px solid #0579df !important;
  color: #0579df !important;
  font-size: 18px !important;
  border-radius: 30px !important;
  font-family: "Inria Sans", sans-serif;
}
.bulk-sec-para {
  color: #0579df;
  font-size: 18px;
  font-family: "Inria Sans", sans-serif;
}
.dropdown {
  /* margin-top: -8px; */
}
.dropdown-toggle {
  color: #ff7700;
  font-size: 15px;
  font-family: "Inria Sans", sans-serif;
}
#cars-try-modal .modal-header {
  padding: 0;
}

.form-control:disabled,
.form-control[readonly] {
  display: none;
}

/* ---------- Loader Codes ----------*/
.spinner-div {
  position: absolute;
  left: 50%;
  top: 50%;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);
}
.sk-cube-grid {
  width: 80px;
  height: 80px;
  margin: 100px auto;
  margin-top: 100px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: white;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

/* --------Loader Section Ends --------*/

@media (max-width: 800px) {
  body {
    background-size: cover;
    background-repeat: repeat-y;
  }
  .small-nav-color {
    background-color: white;
  }

  .modal-content {
    margin-top: 35px !important;
  }
  .left-box {
    padding: 0px !important;
  }
  .category-list {
    /* display: none; */
    grid-template-columns: 50% 50%;
  }
  .outer-box {
    box-shadow: none;
    /* border:2px dashed #9A9A9A; */
    margin: 0px 12%;
  }

  .outer-box-section-1 div {
    border: unset;
    width: 100%;
    margin: 50px auto;
    padding: 0px;
  }
  .choose-image-button {
    width: 70%;
  }
  .right-box {
    padding: 30px;
  }
  .download-section {
    margin-left: 0px !important;
    padding: 0px;
    width: 100% !important;
    height: auto;
  }
  .download-section-inner {
    padding: 35px 0px;
    height: auto;
    padding-bottom: 0px;
    width: auto;
  }
  .sec-2-right-card {
    margin-left: 0px !important;
    height: auto;
    margin-top: 20px;
  }
  .screen-2-top {
    /* margin: 50px 40px 0px 40px; */
    width: auto;
  }
  .otpScreen-image {
    margin-left: auto;
    display: flex;
    justify-content: center;
  }
  /* .small-screen-card{
    width: 75% !important;
  } */
  .loader-edited-screen-2 {
    width: 80px !important;
    height: 80px !important;
    margin-top: 60%;
  }
  .screen-2-drop {
    width: auto !important;
  }
  .para-1 {
    font-size: 20px;
  }
  .footer-links h2,
  .social h2 {
    font-size: 20px;
  }
  .main-price-container {
    max-height: -webkit-max-content;
    max-height: max-content;
    overflow-y: auto;
    padding: 0;
    width: 100%;
    margin: 0;
  }
  .pay-as-you-go {
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .slick-next {
    right: -17px;
  }
  .slick-prev {
    left: -17px;
  }
}

.slick-dots {
  display: none !important;
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .container {
    max-width: 100%;
  }
  .outer-box {
    padding: 40px;
  }
  .outer-box-section-1 {
    padding-top: 0px !important;
    border: 2px dashed #9a9a9a;
    padding-top: 0px !important;
    border: 2px dashed #9a9a9a;
    padding: 30px !important;
    margin-bottom: 20px !important;
  }
  .slick-slide img {
    display: block;
    width: 100%;
    height: auto;
  }
  #custom-bg-slider .slick-prev {
    left: -15px;
  }
  #custom-bg-slider .slick-next {
    right: -15px;
  }
  .credit {
    font-size: 13px;
    line-height: 35px;
  }
  .save-price {
    font-size: 13px;
    line-height: 35px;
  }
  .credit span {
    font-size: 13px;
    line-height: 35px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .container {
    max-width: 100%;
    padding: 0 15px;
  }
  .outer-box-section-1 {
    padding-top: 0px !important;
    border: 2px dashed #9a9a9a;
  }
  .container-fluid {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .container {
    max-width: 94%;
  }
}

/* Car-v2 Landing page css */
/* 
ul.why-tabs {
  top: 20px;
  position: fixed;
}
.why-tabs .nav-link { 
  background: #FFFFFF!important;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 25%)!important;
  border-radius: 10px!important;
  margin: 10px 0px!important;
}
.why-tabs .nav-link.active, .why-tabs .nav-link:hover {
  
  box-shadow: 0px 0px 20px #ff7700!important;
 
}
.why-tabs .nav-link{
  font-weight: 600!important;
  font-size: 24px!important;
  border-radius: 10px!important;
  line-height: 45px!important; 
  color: #1D293F!important;
  background-color: #fff!important; 
}
.why-tabs  .nav-item .nav-link img{
  float: left!important;
  margin-right: 15px!important;
} */

.why-tabs .nav-link.active,
.why-tabs .nav-link:hover {
  box-shadow: 0px 0px 20px #ff7700 !important;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  /* display: none!important; */
}

/* .slick-dots{
  padding: 0;
    list-style: none;
    display: flex!important;
    justify-content: center;
    margin: 0;
}
.slick-dots li{
padding: 10px 5px;
}
.slick-dots li button{
  border: 2px solid #ff7700;
  background: #fff;
  color: #ff7700;
  padding: 2px 5px;
  font-size: 10px;
}
.slick-dots li.slick-active button{
  background: #ff7700;
  color: #fff;
} */

.slick-next:before,
.slick-prev:before {
  color: #000;
}
.slick-prev:before {
  content: "\2190";
}
#slick-slider .slick-next:before,
#slick-slider .slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#slick-slider .slick-next:before,
#slick-slider .slick-prev:before {
  color: #000;
}
#slick-slider .slick-prev:before {
  content: "\2190";
}
#slick-slider .slick-next:before,
#slick-slider .slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#slick-slider .slick-next:before {
  content: "\2192";
}
#slick-slider .slick-prev {
  left: -10px;
}
#slick-slider .slick-next {
  right: -5px;
}
.bg-white {
  background: #fff;
}

.react-tabs__tab--selected {
  background: #fff;
  border-radius: 0;
  border: 0 !important;
  color: #ff7700 !important;
  border: 2px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.exterior-tabs .title ul .react-tabs__tab--selected {
  border: 2px solid #ff7700 !important;
}
.slates-tabs li.selected {
  border-top: 0px !important;
}

.display-none {
  display: none;
}
.react-tabs__tab-panel--selected {
  display: block !important;
}
.custom-backgrounds .react-tabs__tab--selected {
  background: #fff;
  border-radius: 0;
  border: 0 !important;
  color: #00a8ff !important;
  border: 2px solid #00a8ff !important;
  border-radius: 0;
  background: transparent;
}
.trusted-active {
}
.navbar-light .navbar-nav .nav-link.active.trusted-active {
  background-color: #00a8ff !important;
  color: #fff !important;
  padding: 5px 15px !important;
  border-radius: 30px !important;
}
.model-tabs .react-tabs__tab--selected {
  border: 0 !important;
}
.model-tabs .react-tabs__tab--selected span {
  background: #ff7700 !important;
}
.custom-tabs .react-tabs__tab--selected {
  border: 0.735807px solid #ff7700 !important;
  box-sizing: border-box;
  -webkit-filter: drop-shadow(0px 0px 5px #ff7700);
          filter: drop-shadow(0px 0px 5px #ff7700);
  border-radius: 11.0371px;
}

/* Affiliate page tab CSS */

.tab-names .react-tabs__tab {
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: rgba(255, 119, 0, 0.35);
  transition: 0.3s ease-in-out;
  border-bottom: 2px solid transparent !important;
  padding: 5px 17px;
  box-shadow: 0 0 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
}
.tab-names .react-tabs__tab--selected,
.tab-names .react-tabs__tab:hover,
.tab-names .react-tabs__tab:focus {
  border: 0 !important;
  border-bottom: 2px solid #ff7700 !important;
  box-shadow: 0 0 0;
  border-radius: 0;
  outline: 0;
  color: #ff7700;
  margin: 0;
}
/* Image Scoring UI css  */
.image-slider .slick-next {
  right: 0;
}
.image-slider .slick-prev {
  left: 0;
}


.background-color-modal .modal-body {
  position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px) {
  .tab-names .react-tabs__tab {
    font-size: 10px;
    line-height: 18px;
    padding: 5px;
    width: 100%;
    margin-bottom: 20px;
  }
  .container-fluid {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .tab-names .react-tabs__tab {
    font-size: 14px;
    padding: 5px 9px;
  }
}
/* END Affiliate page tab CSS */
@media (min-width: 1366px) {
  .banner-area .container,
  .form-section .container,
  .application-loaded .container,
  .amazing-web .container {
    max-width: 85% !important;
  }
}
@media (min-width: 1440px) {
  .banner-area .container,
  .form-section .container,
  .application-loaded .container,
  .amazing-web .container {
    max-width: 85% !important;
  }
}

.LoginModal_left-sec__2t2CD img{
    width: 100%;
}
.LoginModal_left-sec__2t2CD{
    text-align: center;
    margin-top: 0;
    border-right: 0;
    padding: 0px 50px;
}
.LoginModal_right-sec__R3fkk{
    margin-top: 5px;
    width: 100%;
    padding: 0px 50px;
}
.LoginModal_email__39FTo{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #696F79;
}
.LoginModal_send-otp__1p3Zo{
    width: 130px;
    height: 40px;
    background: #0579DF;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #FFFFFF;
    margin: 17px auto;
    padding-left: 30px;
    padding-top: 10px;
}
.LoginModal_otp-send-info__2W7JN{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4A4A4A;
}
.LoginModal_otp-input__1bZtN{
    width: 288.11px;
}
.LoginModal_verify__3bukP{
    width: 150px;
    height: 40px;
    background: #0579DF;
    border-radius: 5px;
    margin: 17px auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding-left: 55px;
}
.LoginModal_verification__3asED{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #4A4A4A;
}
.LoginModal_footwear-image__1tSnf{
    /* width: auto !important; */
    height: 221.71px !important;
    object-fit: contain;
}
.LoginModal_dynamic-logo-popup__3DKsx{
    width: 30%;
}
.LoginModal_dynamic-logo-popup__3DKsx img{
    width: 100%;
    height: auto;
}

.LoginModal_right-sec__R3fkk form input{
    width: 100%;
}

@media(max-width:800px){
    .LoginModal_left-sec__2t2CD img{
        width: 100%;
    }
    .LoginModal_left-sec__2t2CD{ 
        padding: 0px 15px;
    }
    .LoginModal_right-sec__R3fkk{ 
        padding: 0px 15px;
    }
    .LoginModal_email__39FTo{
       
    }
    .LoginModal_otp-input__1bZtN{
        width: 100%;
    }
    .LoginModal_footwear-image__1tSnf{
        height: auto !important;
    }
    .LoginModal_modal-open__KzYzf .LoginModal_modal__3kbgL{
        padding: 0px 15px!important;
    }
}
.ScreenTwo_upload-btn__aPei-{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.ScreenTwo_upload-btn__aPei- img{ 
        width: 23px;
        margin-right: 10px; 
}

.ScreenTwo_upload-btn__aPei- span{

}
.ScreenTwo_download-section__3Ukdr{
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}
.ScreenTwo_download-section-inner__1LqwX{
  margin-bottom: 25px;
}
.ScreenTwo_upload-sec__1r3k1{
    padding-bottom: 20px;
}
 .ScreenTwo_upload-top-btn__lYrJ5{
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.ScreenTwo_screen-2-drop__CKEBm{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.ScreenTwo_upload-area__2igiM{
    text-align: center;
    justify-content: center;
}


.ScreenTwo_try-drag-drop__3kjvg .ScreenTwo_dropzone1__2S9ep {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.ScreenTwo_try-drag-drop__3kjvg .ScreenTwo_dropzone1__2S9ep:hover {
  opacity: 1;
}
.ScreenTwo_try-drag-drop__3kjvg .ScreenTwo_dropzone1__2S9ep img {
  margin-bottom: -30px;
  width: 15%;
}

.ScreenTwo_drop-box-text__kVbIj {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.ScreenTwo_drop-box-text__kVbIj div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.ScreenTwo_bg-main__Bi1ga{
    background: #fff;
}
.ScreenTwo_remove-bg-box__1zqph{
background: #FFFFFF;
border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
}
.ScreenTwo_remove-bg-area__2tfnz{
    padding: 10px 5% 5%;
}
.ScreenTwo_credit-text__LKSVV img {
    margin-right: 10px;
    width: 40px;
}
.ScreenTwo_credit-text__LKSVV span {
    color: #f70;
}
.ScreenTwo_cross-btn__1IUfL {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:-webkit-max-content;
    width:max-content;
  }
  .ScreenTwo_cross-img__2MeDd {
    width: auto !important;
    cursor: pointer;
  }
  .ScreenTwo_credit-text__LKSVV{
    text-align: center;
    width: 85%;
    margin: 10px 0 15px;
    float: left;
  }

  .ScreenTwo_download-popup__279oa p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 10px;
  }
  .ScreenTwo_download-popup__279oa button.ScreenTwo_activate__20YeU,
  .ScreenTwo_download-popup__279oa button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .ScreenTwo_download-popup__279oa {
    
  }
  
  .ScreenTwo_download-popup__279oa button {
    margin-top: 100px;
    margin-left: 15%;
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 70%;
  }
  .ScreenTwo_cross-btn__1IUfL {
    text-align: right;
    padding: 0 0 10px;
  }
  .ScreenTwo_download-popup__279oa button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .ScreenTwo_download-popup__279oa ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .ScreenTwo_download-popup__279oa ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .ScreenTwo_download-popup__279oa p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .ScreenTwo_download-popup__279oa p span {
    color: #ff7700;
  }
  .ScreenTwo_download-popup__279oa h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .ScreenTwo_border-1__1A2AS {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
  }
  .ScreenTwo_download-popup__279oa input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .ScreenTwo_download-popup__279oa input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .ScreenTwo_download-btn-hd__2CH5G {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 83% !important;
    margin-left: 20% !important;
    text-align: center !important;
    /* padding: 10px 28px !important; */
    margin-top: 40%;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .ScreenTwo_containers__pfjTv {
    max-width: 600px !important;
    
    border-radius: 10px;
}
  .ScreenTwo_containers__pfjTv img {
    
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    /* margin-top: 100px; */
    margin: 50px auto 0;
}
.ScreenTwo_left-sec-upload-image__1Fw4q {
    width: auto;
    height: 415px;
}
.ScreenTwo_rating-area__12O5k{

}
.ScreenTwo_rating-area__12O5k p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.ScreenTwo_rating-area__12O5k p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.ScreenTwo_sec-2-right-card__128bY{
  
    padding: 15px;
}
.ScreenTwo_get-mrktplace__1nilD{
    line-height: 50px!important;
    
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.ScreenTwo_get-mrktplace__1nilD a{
    margin-left: 10px;
}

.ScreenTwo_css-1eg0sfo-skeletonStyles-Skeleton__3q1mf {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: ScreenTwo_animation-bzdot9__1qXWy 1.2s ease-in-out infinite;
    animation: ScreenTwo_animation-bzdot9__1qXWy 1.2s ease-in-out infinite;
}
.ScreenTwo_categories__3Ptvq{

}
.ScreenTwo_categories__3Ptvq h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.ScreenTwo_categories__3Ptvq h2 span{
    font-weight: 600;
font-size: 24px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.ScreenTwo_categories__3Ptvq h2:before {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_categories__3Ptvq h2:after {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_product-hunt-tag__PCx-Y{
    width: auto;
    position: absolute;
    right: 115px;
    top: 0px;
}
  @media screen and (max-width: 1290px) and (min-width: 1025px){
.ScreenTwo_cross-btn__1IUfL {
    padding: 0 0 20px;
}


}

.one{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    /* position: absolute; */
    /* left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); */
    text-align: center;
    margin: auto;
    margin-top: 5%;
    color: #FF7700;

}

.two{
    height: 20vh;
    width: 12vw;
    display: block;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
}

.three{
 

font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;

/* identical to box height */
text-align: center;
margin-top: 4%;
color: #232121;
}

.button{
    text-align: center;
    margin: auto;
    margin-bottom: 10%;
}
.DownloadclipprModal_hd-download-popup__3dtcZ{

}.DownloadclipprModal_hd-download-popup__3dtcZ img{
    width: 30%;
}
.DownloadclipprModal_hd-download-popup__3dtcZ h4{
    font-weight: 600;
    font-size: 22px;
    line-height: 39px; 
    letter-spacing: 0.03em; 
    color: #202225;
    margin: 0;
}
.DownloadclipprModal_hd-download-popup__3dtcZ p{
    font-weight: 500;
    font-size: 13px;
    line-height: 30px; 
    letter-spacing: 0.03em; 
    color: #5E5E5E;
    margin: 10px 0;

}
.DownloadclipprModal_download-btn__2AWKM{
    background: #FF7700;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px; 
    letter-spacing: 0.03em; 
    margin:0 0 20px ;
    min-width: 180px;
}

.DownloadclipprModal_hd-download-popup__3dtcZ p span{
    color: #FF7700;
}
.DownloadclipprModal_basic-download-popup__ykmmX{
    background: rgba(255, 166, 77, 0.2);
border-radius: 5px;
padding: 20px;
}
.DownloadclipprModal_basic-download-popup__ykmmX h5{
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 35px;
    color: #232121;}
    
/* img{
    width: 100%;
} */

.DownloadclipprModal_shoe-image__bjlsU{
    padding: 0;
    margin-bottom: 20px;
}
.DownloadclipprModal_dislike-text__yBvsE{

}
.DownloadclipprModal_dislike-text__yBvsE h5{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px; 
    color: #FF7700;
}
.DownloadclipprModal_dislike-text__yBvsE p{
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    
    color: #5F5F5F;
}
.DownloadclipprModal_dislike-text__yBvsE textarea{
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #7C7C7C;
width: 100%;
padding: 10px;
margin: 15px 0;
min-height: 160px;
}
.DownloadclipprModal_submit-btn__1tfh6{
    background: #FF7700;
    border-radius: 26px;
    font-weight: 600;
font-size: 14px;
min-width: 180px;
color: #FFFFFF;
}
.DownloadclipprModal_dislike-text__yBvsE form {
    text-align: center;
}

.DownloadclipprModal_upload-btn__2YXIa{
    border: unset;
    background: #FF7700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px; 
    color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
} 

.DownloadclipprModal_upload-top-btn__192Wc{
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    margin: 0 auto;
}

.DownloadclipprModal_screen-2-drop__ZvS20{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.upload-btn{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.upload-btn img{ 
        width: 23px;
        margin-right: 10px; 
}

.upload-btn span{

}
.download-section{
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
}
.download-section-inner{
  margin-bottom: 25px;
}
.upload-sec{
    padding-bottom: 20px;
}
 .upload-top-btn{
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.screen-2-drop{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.upload-area{
    text-align: center;
    justify-content: center;
}


.try-drag-drop .dropzone1 {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.try-drag-drop .dropzone1:hover {
  opacity: 1;
}
.try-drag-drop .dropzone1 img {
  margin-bottom: -30px;
  width: 15%;
}

.drop-box-text {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.drop-box-text div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.bg-main{
    background: #fff;
}
.remove-bg-box{
background: #FFFFFF;
border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
}
.remove-bg-area{
    padding: 10px 5% 5%;
}
.credit-text img {
    margin-right: 10px;
    width: 40px;
}
.credit-text span {
    color: #f70;
}
.cross-btn {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:-webkit-max-content;
    width:max-content;
  }
  .cross-img {
    width: auto !important;
    cursor: pointer;
  }
  .credit-text{
    text-align: center;
    width: 85%;
    margin: 10px 0 15px;
    float: left;
  }

  .download-popup p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 10px;
  }
  .download-popup button.activate,
  .download-popup button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .download-popup {
    
  }
  
  .download-popup button {
    margin-top: 100px;
    margin-left: 15%;
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 70%;
  }
  .cross-btn {
    text-align: right;
    padding: 0 0 10px;
  }
  .download-popup button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .download-popup ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .download-popup ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .download-popup p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .download-popup p span {
    color: #ff7700;
  }
  .download-popup h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .border-1 {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
  }
  .download-popup input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .download-popup input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .download-btn-hd {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    /* padding: 10px 28px !important; */
    margin-top: 40%;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .containers {
    max-width: 600px !important;
    
    border-radius: 10px;
}
  .containers img {
    
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    /* margin-top: 100px; */
    margin: 50px auto 0;
}
.left-sec-upload-image {
    width: auto;
    height: 415px;
}
.rating-area{

}
.rating-area p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.rating-area p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.sec-2-right-card{
  
    padding: 15px;
}
.get-mrktplace{
    line-height: 50px!important;
    
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.get-mrktplace a{
    margin-left: 10px;
}

.css-1eg0sfo-skeletonStyles-Skeleton {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    margin-left: 50px;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: animation-bzdot9 1.2s ease-in-out infinite;
    animation: animation-bzdot9 1.2s ease-in-out infinite;
}
.categories{

}
.categories h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.categories h2 span{
    font-weight: 600;
font-size: 24px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.categories h2:before {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .categories h2:after {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .product-hunt-tag{
    width: auto;
    position: absolute;
    right: 115px;
    top: 0px;
}
  @media screen and (max-width: 1290px) and (min-width: 1025px){
.cross-btn {
    padding: 0 0 20px;
}
}
.myclass1{
  background-color: #fff;
  padding-left: 5% !important;
 
}
.btn2{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  /* or 17px */
  letter-spacing: 1.7px;
  border-bottom: 4px solid rgba(153, 150, 150, 0.1);
  color: rgba(24, 25, 31, 0.51);
      background-color:white; 
    text-align: center;
}
.btn2:hover {
    color:#ff7700 !important;
    border-bottom: 4px solid #ff7700 !important; 
}
.mactive1 {
  background-color:white !important;
  color:#ff7700 !important; 
  border-bottom: 4px solid #ff7700 !important; 
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  /* or 17px */
  letter-spacing: 1.7px;
  }

body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.bgRemoval_drop-box-text__3x52c {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.bgRemoval_drop-box-text__3x52c div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.bgRemoval_car-main-wrapper__2xlGi {
  background-size: cover !important;
  padding-bottom: 0px;
}
.bgRemoval_header-section__3TBEH {
  text-align: center;
  padding-top: 100px !important;
}
.bgRemoval_heading-text__3o9oI {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.bgRemoval_heading-para__3y-g7 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.bgRemoval_heading-para__3y-g7 span {
  color: #ff7700 !important;
}
.bgRemoval_active-walls-sec__3ygrS img {
  height: 35px;
}
.bgRemoval_inactive-walls-sec__1Xsbc img {
  height: 35px;
}
.bgRemoval_inactive-walls-sec__1Xsbc {
  padding: 5px;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.bgRemoval_backgrounds-grid-custom__2vbEl {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 7px;
}
.bgRemoval_inactive-walls-sec__1Xsbc span {
  font-size: 13px;
}
.bgRemoval_active-walls-sec__3ygrS {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.bgRemoval_active-walls-sec__3ygrS span {
  font-size: 13px;
}
.bgRemoval_active-walls-sec__3ygrS img {
  width: 100%;
  height: 90px;
}
.bgRemoval_inactive-walls-sec__1Xsbc img {
  width: 100%;
  height: 90px;
}
.bgRemoval_inactive-walls-sec__1Xsbc {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.bgRemoval_wrapper__1WrID {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.bgRemoval_left-section__21dg2 {
  width: 40%;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.bgRemoval_mid-section__2eZRV {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.bgRemoval_sample-button__RjCsC {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.bgRemoval_sample-button__RjCsC:hover {
  color: black;
}
.bgRemoval_dropzone__3CZ_3 {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.bgRemoval_dropzone__3CZ_3:hover {
  opacity: 1;
}
.bgRemoval_dropzone__3CZ_3:focus {
  outline: none !important;
  border: none !important;
}
.bgRemoval_para-1__19_yt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.bgRemoval_para-2__2fvc- {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 0px 0 10px;
}
.bgRemoval_para-2__2fvc- span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
}
.bgRemoval_supports__2kGqK {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.bgRemoval_choose-button__30G1a {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.bgRemoval_choose-button__30G1a button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.bgRemoval_choose-button__30G1a button:hover {
  color: #ffffff;
}
.bgRemoval_choose-button__30G1a button:focus {
  outline: none !important;
}
.bgRemoval_right-section__4EILV {
  width: 55%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 25px;
  margin-left: 5%;
  height: auto;
}
/* .right-section div{
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
} */
.bgRemoval_right-section__4EILV img {
  /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  width: 100%;
}
.bgRemoval_section-two__2btPn {
  padding: 57px;
  background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Desktop+-+2+1.png');
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-repeat: repeat-x;
  background-color: white;
}
.bgRemoval_section-two-one__3l5W8 {
  margin-top: 125px;
}
.bgRemoval_section-two-one__3l5W8 h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 132%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  margin-top: -40px;
}
.bgRemoval_section-two-one__3l5W8 p {
  max-width: 519px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}
.bgRemoval_section-two-one__3l5W8 span {
  color: #ff7700;
}
.bgRemoval_section-two-one-right__3s1JH {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5886+1.png');
  padding: 70px 50px;
  margin-top: -125px;
  align-items: center;
}
.bgRemoval_section-two-one-right__3s1JH div {
  max-width: 571px;
}
.bgRemoval_section-two-three-right__2Bg_1 {
  margin-top: -125px;
  align-items: center;
}
.bgRemoval_section-3__3_fM- {
  padding: 57px;
}
.bgRemoval_slider__5NhCG {
  height: 100%;
  width: 3px;
  background: white;
}
.bgRemoval_slider__5NhCG img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  margin-left: -25px;
}
.bgRemoval_section-3__3_fM- {
  background: #ffffff;
}
.bgRemoval_section-3-right__22DcA div {
  vertical-align: center;
  /* position: absolute;
    top: 50%;
    transform: translate(-50%,-50%); */
  margin-left: 135px;
  margin-top: 20%;
  text-align: center;
}
.bgRemoval_section-3-right__22DcA p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.bgRemoval_section-3-right__22DcA button {
  border: unset;
  width: 220px;
  height: 60px;
  background: #ff7700;
  border-radius: 30.5px;
}
.bgRemoval_section-3-right__22DcA a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
}

.bgRemoval_mob-area__3VD25 {
  padding-top: 0%;
}

.bgRemoval_mob-area__3VD25 .bgRemoval_why-use__2Yy0j {
  text-align: left;
  padding: 10% 5% 0;
}

.bgRemoval_why-use__2Yy0j h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}

.bgRemoval_mob-area__3VD25 .bgRemoval_why-use__2Yy0j p {
  margin-bottom: 30px;
}

.bgRemoval_request-demo__UF2D2 {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.bgRemoval_mob-area__3VD25 .bgRemoval_why-use__2Yy0j .bgRemoval_request-demo2__3tshR {
  display: block;
}

.bgRemoval_mob-area__3VD25 .bgRemoval_why-use__2Yy0j p img {
  margin-top: 30px;
}

.bgRemoval_request-demo2__3tshR img {
  height: 42px;
  /* margin-left: 20px; */
}

.bgRemoval_custom-backgrounds__3uPw8 {
  padding: 60px 5% 40px;
}

.bgRemoval_title__pcs50 {
  text-align: center;
}

.bgRemoval_title__pcs50 h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.bgRemoval_exterior-tabs__2G1TB ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}

.bgRemoval_car-try-wrapper__3URU_ {
}
.bgRemoval_try-images-area__30m0Z {
  padding: 5px 15px;
}
.bgRemoval_car-left-section__gVqVI {
  margin-top: 25px;
}
.bgRemoval_try-drag-drop__2p8f1 {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  cursor: pointer;
}
.bgRemoval_try-drag-drop__2p8f1 .bgRemoval_dropzone1__2kAxM {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.bgRemoval_try-drag-drop__2p8f1 .bgRemoval_dropzone1__2kAxM:hover {
  opacity: 1;
}
.bgRemoval_try-drag-drop__2p8f1 .bgRemoval_dropzone1__2kAxM img {
  margin-bottom: -30px;
  width: 15%;
}
.bgRemoval_para-11__3YNaI {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
}
.bgRemoval_supports1__2hnev {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
.bgRemoval_perfect-output__2vD0v {
}
.bgRemoval_choose-output__2O-F0 {
}
.bgRemoval_heading__3VJ3e h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.bgRemoval_heading__3VJ3e h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.bgRemoval_heading__3VJ3e h3 .bgRemoval_border-1__Y--pw {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.bgRemoval_perfect-output-images__2QzKY {
  text-align: center;
  padding: 20px 15px 0;
}
.bgRemoval_perfect-output-images__2QzKY img {
  width: 100%;
}
.bgRemoval_perfect-output-images__2QzKY p {
  text-align: center;
}
.bgRemoval_perfect-output-images__2QzKY p span a {
  font-style: normal;
  color: #ff7700 !important;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  cursor: pointer;
}

.bgRemoval_perfect-output-images__2QzKY p a {
  background: #ff7700;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.bgRemoval_bg-try__2QmFB {
  /* background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
  background-position: top right;
  background-repeat: no-repeat; */
}

.bgRemoval_choose-button__30G1a {
}
.bgRemoval_choose-button__30G1a button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: -webkit-max-content;
  width: max-content;
}
.bgRemoval_bg-recommended__2O2xU {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  /* overflow: hidden;
  overflow-y: scroll; */
}
.bgRemoval_bg-recommended__2O2xU h6 {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
  font-weight: 600;
}
.bgRemoval_bg-recommended__2O2xU h6 span {
  color: #ff7700;
}
.bgRemoval_bg-recomended-images__1DBhd {
  list-style: none;
  display: block;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.bgRemoval_bg-recomended-images__1DBhd li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 10px;
  cursor: pointer;
  width: 50%;
}
.bgRemoval_bg-recomended-images__1DBhd li img {
  width: 100%;
  height: 56px;
}
.bgRemoval_wall-cross__3che8 {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.bgRemoval_active-background-sec__1gdJS img {
  /* border: 2px solid #000;
  transform: scale(1.1); */
}
.bgRemoval_active-background-sec__1gdJS {
  border: 2px solid #000;
  padding: 3px !important;
}
.bgRemoval_bg-recomended-images__1DBhd li span {
  font-size: 11px;
  font-weight: 500;
}
.bgRemoval_perfect-output-1__2bYtw {
  padding: 40px 0px;
  background: #fff;
}
.bgRemoval_perfect-output-1__2bYtw .bgRemoval_heading__3VJ3e {
  margin-bottom: 20px;
}
.bgRemoval_bg-recomended-area__nZuxM {
  overflow-y: scroll;
  height: 400px;
}

.bgRemoval_bg-recomended-right__1pjqz img {
  width: 100%;
}

.bgRemoval_bg-recommended__2O2xU p {
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
}

.bgRemoval_bg-recomended-right__1pjqz {
}
.bgRemoval_bg-recomended-right__1pjqz img {
  margin-top: 20%;
}

.bgRemoval_bg-recomended-area__nZuxM .bgRemoval_perfect-output-images__2QzKY p {
  margin-top: 2%;
}
.bgRemoval_bg-recomended-area__nZuxM .bgRemoval_perfect-output-images__2QzKY p a {
  padding: 10px;
}

.bgRemoval_download-popup__r4qPe {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.bgRemoval_download-popup__r4qPe button {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}
.bgRemoval_download-popup__r4qPe ul {
  padding-left: 15%;
  margin: 0 0 5px;
}
.bgRemoval_download-popup__r4qPe ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.bgRemoval_download-popup__r4qPe p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 10%;
}
.bgRemoval_download-popup__r4qPe p span {
  color: #ff7700;
}
.bgRemoval_download-popup__r4qPe h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
}
.bgRemoval_download-popup__r4qPe input {
  margin-right: 7%;
  border: 0;
  outline: 0;
}
.bgRemoval_download-popup__r4qPe input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.bgRemoval_download-popup__r4qPe button {
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: left;
}
.bgRemoval_download-popup__r4qPe p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.bgRemoval_download-popup__r4qPe button.bgRemoval_activate__1WQCV,
.bgRemoval_download-popup__r4qPe button:hover {
  background: rgba(5, 121, 223, 0.05) !important;
  border: 1px solid #78838d !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

/* .app-section {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
} */

@media (max-width: 800px) {
  .bgRemoval_car-main-wrapper__2xlGi {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .bgRemoval_wrapper__1WrID {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .bgRemoval_right-section__4EILV {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .bgRemoval_left-section__21dg2 {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .bgRemoval_heading-text__3o9oI {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__3y-g7 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .bgRemoval_section-two__2btPn {
    padding: 27px;
  }
  .bgRemoval_section-two-one__3l5W8 {
    margin-top: 70px;
  }
  .bgRemoval_number-image__34_tt {
    display: none;
  }
  .bgRemoval_section-two-one__3l5W8 h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .bgRemoval_section-two-one__3l5W8 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-two-one-right__3s1JH {
    margin-top: 0px;
  }
  .bgRemoval_section-3-right__22DcA div {
    margin-left: 0px;
  }
  .bgRemoval_mob-change__2Rawk {
    display: flex;
    flex-direction: column-reverse;
  }
  .bgRemoval_mob-change__2Rawk h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-two-three-right__2Bg_1 img {
    margin-top: 120px;
  }
  .bgRemoval_section-3__3_fM- {
    padding: 27px;
  }
  .bgRemoval_section-3-right__22DcA p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-3-right__22DcA button {
    width: 150px;
    height: 50px;
  }
  .bgRemoval_try-images-area__30m0Z {
    padding: 5px 0;
    order: 1;
  }
  .bgRemoval_car-left-section__gVqVI {
    order: 3;
  }
  .bgRemoval_perfect-output-images__2QzKY p {
    text-align: center;
    margin: 0;
  }
  .bgRemoval_try-images-area__30m0Z img {
    width: 100%;
    margin: 20px 0;
  }
  .bgRemoval_choose-button__30G1a {
    padding: 10% 10px;
  }
  .bgRemoval_bg-try__2QmFB {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .bgRemoval_perfect-output-images__2QzKY {
    order: 3;
    padding: 15px 0;
  }
  .bgRemoval_bg-recomended-area__nZuxM {
    overflow: visible;
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .bgRemoval_bg-recomended-right__1pjqz img {
    margin: 0px 0px 30px;
  }
  .bgRemoval_custom-big__3LWyF {
    display: none;
  }
  .bgRemoval_bg-recomended-right__1pjqz {
    padding: 0;
  }
  .bgRemoval_bg-recommended__2O2xU {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .bgRemoval_active-background-sec__1gdJS {
    padding: 0 10px;
    border-radius: 5px;
  }
  .bgRemoval_custom-wall__3Hfyj {
    height: 90px !important;
    width: 100%;
    margin-bottom: 16px;
  }

  .bgRemoval_inactive-background-sec__24Amd {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .bgRemoval_active-background-sec__1gdJS {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 5px;
    border: 2px solid #ff7700;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .bgRemoval_perfect-output-images__2QzKY p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .bgRemoval_heading__3VJ3e h3 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .bgRemoval_heading-text__3o9oI {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__3y-g7 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .bgRemoval_section-two-one__3l5W8 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_mob-change__2Rawk h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_try-images-area__30m0Z img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .bgRemoval_heading-text__3o9oI {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__3y-g7 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .bgRemoval_left-section__21dg2 {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .bgRemoval_left-section__21dg2 {
    margin-top: 10%;
  }
}



.bgRemoval_product-hunt-strip__2JX5R {
  width: 100%;
  background-color: #cc4d29;
  position: fixed;
  top: 12%;
  z-index: 999;
}

.bgRemoval_product-hunt-strip__2JX5R h6 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding-top: 4px;
}

.LandingModal_firstStep__1IkJh {
  /* background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/ezgif%201.png'); */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: contain;
}
.LandingModal_firstStep__1IkJh img {
  width: 69%;
}
.LandingModal_drop-menu___rpiF{
  padding: 0 10px !important;
  /* color: #5b646d !important; */
  font-weight: 500 !important;
  margin: 0px 0px 20px;

}
.LandingModal_drop-menu___rpiF button{
  color: #5b646d !important;
  padding: 0px 10px;
}
.LandingModal_drop-menu___rpiF button:hover{
  color: #5b646d !important;
}
.LandingModal_drop-list__2sV0s{
  width: 79.5% !important;

}
.LandingModal_distributor__2HJdn {
  width: 81.5% !important;
  background: white !important;
  /* border-bottom: 0px solid #928b8b; */
  /* color: #5b646d !important; */
  border: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #ced4da !important;
  /* color:#5b646d !important; */
  text-align: left;
}

.LandingModal_signup-button__pKXqr {
  /* position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%); */
  background-color: white !important;
  color: #ff7700 !important;
  width: 150px;
  border-radius: 45px;
  font-size: 16px !important;
  margin-bottom: 12px;
}

.LandingModal_circle-area-inner__3SOGV a {
  border-bottom: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.LandingModal_login-check-modal__2gBxC img {
  width: 60px;
  height: auto;
}

.LandingModal_got-btn__34V8x {
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 140px;
  height: 40px;
  margin: 20px 0;
}

.LandingModal_cars-img__2CdyN {
  width: 100%;
}

.LandingModal_img-sec__19hVa {
  height: 355px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 62px;
  text-align: center;
  width: 85%;
}

.LandingModal_close-btn__-jOpk {
  width: 15px !important;
  cursor: pointer;
}

.LandingModal_cars-circle__1J_OX {
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 50%;
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.LandingModal_cars-circle-active__10bh8 {
  border: 1px solid #ff7700 !important;
  background: #f1d7bf !important;
  border-radius: 50%;
  margin-top: 40px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.LandingModal_cars-circle__1J_OX img {
  margin-top: -13px;
  margin-left: 14px;
}

.LandingModal_cars-circle-active__10bh8 img {
  margin-top: -13px;
  margin-left: 14px;
}

.LandingModal_login-here__2NqD- {
  cursor: pointer;
  border-bottom: 1px solid #ff0000;
}

.LandingModal_forgot-password__1CX1o {
  font-size: 15px;
  color: #ff7700;
  /* border-bottom: 1px solid #ff7700; */
  cursor: pointer;
}

.LandingModal_login-check-modal__2gBxC input {
  width: 80%;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  margin: 25px 0 20px;
}
.LandingModal_login-check-modal__2gBxC p {
  padding-top: 1%;
  font-size: 25px;
  margin-bottom: 0%;
}
.LandingModal_login-check-modal__2gBxC form p {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #2d2b29;
  font-weight: 400;
  padding: 0;
}
.LandingModal_login-check-modal__2gBxC form p a {
  color: #ff7700;
}
.LandingModal_login-check-modal__2gBxC button {
  border: unset;
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 50%;
  height: 45px;
  margin: 25px 0 25px;
}

.LandingModal_or__f9N8Q {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.2px;
  color: #787878;
  border-top: 1px solid #ddd;
  margin: 0px 20% 0px;
}
.LandingModal_or__f9N8Q span {
  background: #fff;
  position: relative;
  top: -12px;
  padding: 10px;
}

.LandingModal_img-sec__19hVa img {
  object-fit: contain;
  width: 82%;
  text-align: center;
  padding-bottom: 40px;
}

.LandingModal_car-text__1jnlo h1 {
  font-size: 30px;
}
.LandingModal_circle-area__3stMK {
  padding: 25px;
  background: #f79c4c;
  width: 62%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  margin: 0 auto 0;
  margin-right: -10%;
  top: 0;
  right: 0;
}
.LandingModal_circle-area-inner__3SOGV {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  padding: 28% 35px 20px;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  text-align: center;
}
.LandingModal_circle-area-inner__3SOGV h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 139.5%;
  text-align: center;

  color: #ffffff;
}
.LandingModal_circle-area-inner__3SOGV p {
  font-weight: normal;
  font-size: 17px;
  line-height: 139.5%;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 22px !important;
  color: #ffffff;
}

.LandingModal_circle-area-inner__3SOGV span {
  font-weight: 600;
}
.LandingModal_overlays-models-text__3isMq {
}
.LandingModal_overlays-models-text__3isMq p {
  padding: 10px 0 0;
  margin: 0;
}

.LandingModal_login-check-modal__2gBxC h6 {
  text-align: left;
  padding-left: 87px;
  color: red;
  font-size: 12px;
}
.LandingModal_product-hunt__3hKtZ img{
  width: 250px;  
  margin: 0 0 0 10px;
}
.LandingModal_product-hunt-tag__19a8x{
  padding: 0;
    margin: -10% 0 10px!important;
}
.LandingModal_product-hunt-tag__19a8x a{
border: 0!important;
}




@media (max-width: 768px) {
  .LandingModal_hide-in-mobile__3eNMI {
    display: none !important;
  }
  .LandingModal_car-text__1jnlo h1 {
    font-size: 19px;
  }

  .LandingModal_firstStep__1IkJh {
    height: 370px;
    width: 100%;
  }

  .LandingModal_signup-button__pKXqr {
    width: 100px;
    height: 30px;
    padding: 0;
    font-size: 11px !important;
  }

  .LandingModal_login-check-modal__2gBxC img {
    width: 40px;
    height: auto;
  }

  .LandingModal_login-check-modal__2gBxC p {
    padding-top: 10px;
    font-size: 18px;
  }
  .LandingModal_login-check-modal__2gBxC input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ced4da;
    margin-top: 35px;
  }

  .LandingModal_login-check-modal__2gBxC button {
    border: unset;
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 230px;
    height: 40px;
    margin: 35px 0 20px;
  }

  .LandingModal_cars-circle__1J_OX {
    width: 55px;
    height: 55px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
  }

  .LandingModal_cars-circle-active__10bh8 {
    border: 1px solid #ff7700 !important;
    background: #f1d7bf !important;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 55px;
  }

  .LandingModal_cars-circle__1J_OX img {
    margin-top: 0px;
    margin-left: 0px;
  }

  .LandingModal_cars-circle-active__10bh8 img {
    margin-top: 0px;
    margin-left: 0px;
  }
  .LandingModal_img-sec__19hVa {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 26px;
    text-align: center;
    width: 98%;
    height: 275px;
  }
  .LandingModal_img-sec__19hVa img {
    width: 57%;
  }

  .LandingModal_firstStep__1IkJh img {
    display: block;
  }
  .LandingModal_circle-area__3stMK {
    padding: 10px;
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-right: 0;
    position: relative;
  }
  .LandingModal_circle-area-inner__3SOGV {
    border-radius: 0;
    padding: 10px;
  }
  .LandingModal_circle-area-inner__3SOGV h3 {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .LandingModal_circle-area-inner__3SOGV p {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .LandingModal_firstStep__1IkJh {
    height: 450px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 901px) {
  .LandingModal_circle-area__3stMK {
    margin-right: -15%;
  }
}

.ScreenTwo_upload-btn__1A9ZG{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.ScreenTwo_upload-btn__1A9ZG img{ 
        width: 23px;
        margin-right: 10px; 
}

.ScreenTwo_upload-btn__1A9ZG span{

}
.ScreenTwo_upload-sec__3bUzN{
    padding-bottom: 20px;
}
 .ScreenTwo_upload-top-btn__Pd2kA{
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.ScreenTwo_screen-2-drop__2lV71{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.ScreenTwo_upload-area__rVQZI{
    text-align: center;
    justify-content: center;
}
.ScreenTwo_bg-main__2FvyQ{
    background: #FBFBFB;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
}
.ScreenTwo_remove-bg-box__1kH88{
background: #FFFFFF;
border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
}
.ScreenTwo_remove-bg-area__1Gq6u{
    padding: 10px 5% 5%;
}
.ScreenTwo_credit-text__3wqx4 img {
    margin-right: 10px;
    width: 40px;
}
.ScreenTwo_credit-text__3wqx4 span {
    color: #f70;
}
.ScreenTwo_cross-btn__1Zn2Y {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:-webkit-max-content;
    width:max-content;
  }
  .ScreenTwo_cross-img__3cP6m {
    width: auto !important;
    cursor: pointer;
  }
  .ScreenTwo_credit-text__3wqx4{
    text-align: center;
    width: 85%;
    margin: 10px 0 15px;
    float: left;
  }

  .ScreenTwo_download-popup__3wB9p p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 15px;
  }
  .ScreenTwo_download-popup__3wB9p button.ScreenTwo_activate__qeqck,
  .ScreenTwo_download-popup__3wB9p button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .ScreenTwo_download-popup__3wB9p {
    
  }
  
  .ScreenTwo_download-popup__3wB9p button {
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 85%;;
  }
  .ScreenTwo_cross-btn__1Zn2Y {
    text-align: right;
    padding: 0 0 10px;
  }
  .ScreenTwo_download-popup__3wB9p button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .ScreenTwo_download-popup__3wB9p ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .ScreenTwo_download-popup__3wB9p ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .ScreenTwo_download-popup__3wB9p p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .ScreenTwo_download-popup__3wB9p p span {
    color: #ff7700;
  }
  .ScreenTwo_download-popup__3wB9p h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .ScreenTwo_border-1__2GagR {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
  }
  .ScreenTwo_download-popup__3wB9p input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .ScreenTwo_download-popup__3wB9p input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .ScreenTwo_download-btn-hd__ERurU {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .ScreenTwo_containers__2UoA9 {
    max-width: 600px !important;
    border-radius: 10px;
}
  .ScreenTwo_containers__2UoA9 img {
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    /* margin-top: 100px; */
    margin: 50px auto 0;
}
.ScreenTwo_left-sec-upload-image__3L7zl {
    width: auto;
    height: 415px;
}
.ScreenTwo_rating-area__2fyN0{

}
.ScreenTwo_rating-area__2fyN0 p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.ScreenTwo_rating-area__2fyN0 p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.ScreenTwo_sec-2-right-card__3aZsM{
    padding: 15px;
}
.ScreenTwo_get-mrktplace__3Vddm{
    line-height: 50px!important;
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.ScreenTwo_get-mrktplace__3Vddm a{
    margin-left: 10px;
}

.ScreenTwo_css-1eg0sfo-skeletonStyles-Skeleton__21uPe {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: ScreenTwo_animation-bzdot9__kwa7F 1.2s ease-in-out infinite;
    animation: ScreenTwo_animation-bzdot9__kwa7F 1.2s ease-in-out infinite;
}
.ScreenTwo_categories__1YAHG{

}
.ScreenTwo_categories__1YAHG h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.ScreenTwo_categories__1YAHG h2 span{
    font-weight: 600;
font-size: 28px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.ScreenTwo_categories__1YAHG h2:before {
    content: "";
    display: block;
    width: 38%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_categories__1YAHG h2:after {
    content: "";
    display: block;
    width: 38%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }



  @media screen and (max-width: 1290px) and (min-width: 1025px){
.ScreenTwo_cross-btn__1Zn2Y {
    padding: 0 0 20px;
}


}
.TryCategories_container-category__3STj9{
    width: 100%;
    padding: 0px 15px;
}
.TryCategories_poppins__2CF1G {
    font-family: 'Poppins', sans-serif;
    margin-top: 65px;
}
.TryCategories_categories-main-right__3bFnm{
    margin-top: 25px!important;
    padding-left: 15px;
}
.TryCategories_card__3VyEq { 
    /* box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 15px; */
    padding: 15px;
    /* position: relative;
    height: 100%; */
    background: #FFFFFF;
mix-blend-mode: normal;
border: 1px solid #FF9200;
box-sizing: border-box;
box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
-webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px); 
border-radius: 9px;
transition: .2s ease-in-out;
}   
.TryCategories_card__3VyEq:hover{ 
    border: 1.5px solid #FF9200; 
    box-shadow: 4px 5px 12px rgb(255 152 0 / 27%);
}
.TryCategories_bg-blue__1k0Qr {
    background-color: #D8F4FF;
}

.TryCategories_bg-skin__DrKHV {
    background-color: #96ECD2;
}

.TryCategories_bg-yellow__wDPbb {
    background-color: #FDF08E;
}

.TryCategories_bg-purple__1ueDG {
    background-color: #CABEBE;
}

.TryCategories_bg-indigo__2JxNK {
    background-color: #C7D0DE;
}

.TryCategories_bg-pink__3mOCf {
    background-color: #FDDCAA;
}
.TryCategories_card__3VyEq h6{
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
    margin: 0;
    line-height: 28px;
}
.TryCategories_card__3VyEq h6 span{
    background: #000000;
    border-radius: 9px 0px 0px 0px;
    padding: 5px;
    color: #fff;
    font-size: 14px; 
    font-weight: 500;
}
.TryCategories_card__3VyEq h6 span img{
    width: auto;
    background: none;
    background-size: auto;
    height: auto;
}
.TryCategories_grid1__3Jtr4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px 25px;
    margin-bottom: 45px;
}

.TryCategories_grid__3yt9o > * {
    min-height: 190px;
}

.TryCategories_img-container__3l53S {
    /* position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 55.25%; */
}

.TryCategories_img-container__3l53S img {
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%; */
    width: 100%;
    object-fit: contain;
    /* object-position: right; */
    /* filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.25)); */
    background: url('https://storage.googleapis.com/spyne-website/try-categories/car-bg1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%!important;
    height: 160px;
}

.TryCategories_fab__2YwJ9 {
    position: fixed;
    right: 10%;
    bottom: 10%;
    border-radius: 50%;
    width: 87px;
    height: 87px; 
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    right: 25px;
    bottom: 25px;
    background: #717070;
    z-index: 2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.TryCategories_dash-marker__3-m8Y{
    bottom: 15%;
    position: fixed;
    right: 3.4%;
    display: none;
    transition: .2s ease-in-out;
}
.TryCategories_fab__2YwJ9:hover .TryCategories_dash-marker__3-m8Y{
    display: block;
}
.TryCategories_text-black__URZon {
    color: #000;
    text-decoration: none;
    padding: 0;
}
.TryCategories_text-black__URZon:hover {
    color: #000;
    text-decoration: none;
}
link:hover {
    color: #000;
    text-decoration: none;
}
.TryCategories_category-title__2w1ki{

}
.TryCategories_category-title__2w1ki h2{
    font-weight: 600;
font-size: 28px;
line-height: 48px; 
color: #494949;
margin: 0px 0 20px;
}
.TryCategories_category-title__2w1ki h2 span{
color: #ff7700;
}
.TryCategories_card__3VyEq h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    text-align: center;
    margin: 10px 0 0;
}
.TryCategories_comng-soon__19Low{
    position: absolute;
    bottom: 0%;
    left: 0;
    margin: 0;
    background: #48CA45;
    border-radius: 0px 10px 0px 15px;
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-align: center;
    min-width: 100px;
    z-index: 1;
} 
.TryCategories_beta__1folf{
    background: #ff7700;
}
.TryCategories_cars-try__1UmGW{
    /* padding: 5px;
    box-shadow: 0px 3px 10px 0px #ff77004a;
    border-radius: 20px; */
    margin-bottom: 25px;
}
.TryCategories_non-border__2-PbB{

}
.TryCategories_foot__2gp7C img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.TryCategories_fashion__L2u7Z img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.TryCategories_product__2BNR- img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.TryCategories_fabric__3Qzmm img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.TryCategories_furnish__2Lhr9 img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.TryCategories_cars-2__16OpN{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bluecar-bg.png')!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
}
.TryCategories_cars-2__16OpN{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/food-bg.png')!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
}
.TryCategories_card__3VyEq p {
    
    position: fixed;
    right: 0;
    font-weight: 500;
    top: 3px;
}
.TryCategories_card__3VyEq p span{
    background: linear-gradient(91.1deg, #FFBE18 59.64%, #FFD53E 116.92%);
    border-radius: 0 8px 0 8px; 
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;  
    font-weight: 500;
    
}
/* .drawer-links{
    padding: 5px;
}
.drawer-links Link , .drawer-links a{
    font-weight: bold;
font-size: 14px;
line-height: 142%; 
align-items: center;
text-align: center;
letter-spacing: 0.005em;
color: #616161;

}
.dropdown-btn{
    text-align: center;
}
.drawer-links img{ 
    margin: 0 10px 0 0;
} */

/* .drawer-links:hover{
    background: rgba(255, 119, 0, 0.45);
opacity: 0.5;
border-radius: 0px;
}
.drawer-links:hover Link{
   color: #ff7700;
} */


/* Sidebar-Drawer */
 


.TryCategories_main-menu__Ll8Dn:hover,nav.TryCategories_main-menu__Ll8Dn.TryCategories_expanded__1ZfW4 {
width:250px;
overflow:visible;
}

.TryCategories_main-menu__Ll8Dn {
background:#fff;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
position:fixed;
top:65px;
bottom:0;
height:100%;
left:0;
width:70px;
overflow:hidden;
padding: 0;
transition:width .2s linear;
-webkit-transform:translateZ(0) scale(1,1);
z-index:1000;
}

.TryCategories_main-menu__Ll8Dn>ul {
    padding: 15px 0px;
  height: 100%;
}

.TryCategories_main-menu__Ll8Dn li {
position:relative;
display:block;
width:250px;
padding: 0px;
margin: 8px 0;
}

.TryCategories_main-menu__Ll8Dn li>a {
position:relative;
display:table;
border-collapse:collapse;
border-spacing:0;
font-weight: normal;
font-size: 15px;
line-height: 138%;  
letter-spacing: 0.0075em;
padding: 5px 0px 5px 10px;
color: #444444;
text-decoration:none;
-webkit-transform:translateZ(0) scale(1,1);
transition:all .1s linear;
  width: 100%; 
  font-family: 'Poppins';
}

.TryCategories_main-menu__Ll8Dn .TryCategories_nav-icon__aH8Je {
position:relative;
display:table-cell;
width:30%;
height:36px;
text-align:center;
vertical-align:middle;
font-size:18px;
}
.TryCategories_main-menu__Ll8Dn  img{
    padding: 5px 5px 5px 15px;
    width: 55px;
    margin: 0;
}
.TryCategories_main-menu__Ll8Dn .TryCategories_nav-text__25l0y {
position:relative;
display:table-cell;
vertical-align:middle;
width:80%; 
padding: 5px 10px 5px 20px;
}

 
.TryCategories_no-touch__2n2lS .TryCategories_scrollable__DbRwz.TryCategories_hover__30ZRY {
overflow-y:hidden;
}

.TryCategories_no-touch__2n2lS .TryCategories_scrollable__DbRwz.TryCategories_hover__30ZRY:hover {
overflow-y:auto;
overflow:visible;
}

a:hover,a:focus {
text-decoration:none;
}

nav {
-webkit-user-select:none;
-ms-user-select:none;
-o-user-select:none;
user-select:none;
}

/* nav ul,nav li {
outline:0;
margin:0;
padding:0;
} */
.TryCategories_main-menu__Ll8Dn li:hover>a,nav.TryCategories_main-menu__Ll8Dn li.TryCategories_active__2_Sh1>a,.TryCategories_dropdown-menu__bu1rG>li>a:hover,.TryCategories_dropdown-menu__bu1rG>li>a:focus,.TryCategories_dropdown-menu__bu1rG>.TryCategories_active__2_Sh1>a,.TryCategories_dropdown-menu__bu1rG>.TryCategories_active__2_Sh1>a:hover,.TryCategories_dropdown-menu__bu1rG>.TryCategories_active__2_Sh1>a:focus,.TryCategories_no-touch__2n2lS .TryCategories_dashboard-page__1AmBJ nav.TryCategories_dashboard-menu__BUI-E ul li:hover a,.TryCategories_dashboard-page__1AmBJ nav.TryCategories_dashboard-menu__BUI-E ul li.TryCategories_active__2_Sh1 a {
    color: #ED8909; 
background: rgba(255, 214, 178, 0.52);
}
.TryCategories_area__3pbzi {
float: left;
background: #e2e2e2;
width: 100%;
height: 100%;
}
 .TryCategories_nav-text__25l0y button{
    position: relative;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    padding: 5px 0px 5px 10px;
    color: #444444;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1,1);
    transition: all .1s linear; 
    margin: 5px 0;
    color: #444444!important;
    padding: 0;
    background: transparent!important;
    border: 0;
    font-family: 'Poppins';
    width: 100%;
    text-align: left;
 }
 .TryCategories_nav-text__25l0y button:hover, .TryCategories_nav-text__25l0y button:focus{
    color: #ED8909!important; 
 }
.TryCategories_accor-btn__1AyhH{
    width: 100%;
    display: flex;
}
.TryCategories_accor-btn__1AyhH:hover{
    color: #ED8909; 
    background: rgba(255, 214, 178, 0.52);
}
.TryCategories_accor-btn__1AyhH span{
    padding: 5px 10px 5px 15px!important;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1,1);
    transition: all .1s linear; 
    margin: 5px 0;
    color: #444444;
    font-size: 15px;
    font-family: 'Poppins';
}
.TryCategories_accor-btn__1AyhH:hover span{
    color: #ED8909; 
}
.TryCategories_inner-links__3BHfT{
    padding: 0px 0px 0px 70px;
}
.TryCategories_inner-links__3BHfT li{
width: 100%;
border-left: 2px solid #E0E0E0;
margin: 0;
}
.TryCategories_inner-links__3BHfT li:hover{
    border-left: 2px solid  #ED8909; 
}
.TryCategories_inner-links__3BHfT li a{
    /* border-left: 2px solid #E0E0E0; */
    margin: 0;
}
.TryCategories_inner-links__3BHfT li a span{
    width: 100%;
    padding: 10px 10px;
    display: block;
    color: #444444;
}
.TryCategories_inner-links__3BHfT li:hover span{
    color: #ED8909; 
}
.TryCategories_accor-btn__1AyhH i{
    float: right;
    line-height: inherit;
}
a.TryCategories_disabled__2oAc_{
    pointer-events: none;
}
.TryCategories_car-img__2Z4OZ{
    height: 182px!important;
}
.TryCategories_categories-block__PMTOP{
    padding: 0 0 0 80px;
}







/* @Responsive  */

@media screen and  (max-width: 800px){
    .TryCategories_grid1__3Jtr4{ 
        grid-template-columns: 48% 48%;
        grid-gap: 30px 10px; 
    }
    .TryCategories_card__3VyEq h4 {
        font-weight: 500;
        font-size: 14px;
    }

    .TryCategories_comng-soon__19Low{ 
        padding: 5px 8px; 
    min-width: 80px;
    }
    .TryCategories_category-title__2w1ki h2 { 
        font-size: 20px;
        margin: 0px 0 20px;
    }
    .TryCategories_grid__3yt9o > * {
        min-height: 120px;
    }
    .TryCategories_fab__2YwJ9 { 
        width: 60px;
        height: 60px; 
        right: 15px;
        bottom: 15px;
    }
    .TryCategories_fab__2YwJ9 svg{
        
    height: 22px;

    }
    .TryCategories_dash-marker__3-m8Y{
        bottom: 11%;
        position: fixed;
        right: 10%;
        width: 100px;
        height: auto!important;
    }
    .TryCategories_poppins__2CF1G { 
        margin-top: 45px;
    }
    .TryCategories_main-menu__Ll8Dn{ 
        position: fixed; 
            width: 60px;
            top:60px;
    }
    .TryCategories_categories-block__PMTOP {
        padding: 0 10px 0 55px;
    }
    .TryCategories_cars-try__1UmGW{ 
        padding: 0;
    }
}

@media screen and  (max-width: 800px) and (min-width: 768px){
.TryCategories_grid1__3Jtr4 {
    grid-template-columns: 31% 31% 31%!important;

}
}


@media screen and  (min-width: 1400px){
.TryCategories_dash-marker__3-m8Y {
    bottom: 12%;
    position: fixed;
    right: 2.9%;
}
.TryCategories_category-title__2w1ki  h2 { 
    margin: 10px 0 40px;
}
.TryCategories_main-menu__Ll8Dn .TryCategories_nav-text__25l0y { 
    width: 75%;
}
.TryCategories_main-menu__Ll8Dn img {
    margin-right: 10px;
}

}



.ScreenTwo_upload-btn__1pbLk{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.ScreenTwo_upload-btn__1pbLk img{ 
        width: 23px;
        margin-right: 10px; 
}

.ScreenTwo_upload-btn__1pbLk span{

}
.ScreenTwo_download-section-inner__3V4DX{
  margin-bottom: 25px;
}
.ScreenTwo_upload-sec__1cM5p{
    /* padding-bottom: 20px; */
}
 .ScreenTwo_upload-top-btn__1LhOP{
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.ScreenTwo_screen-2-drop__2-s9k{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}
.ScreenTwo_solid-background__2kSdu{
  padding: 25px 0px 25px 25px;
  display: grid;
  grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  grid-gap: 9px;
}
.ScreenTwo_solid-__1OpCY span{
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0!important;
  line-height: normal;
}
.ScreenTwo_solid-background__2kSdu img{
  width: 100%;
  /* padding: 5px; */
  height: 65px;
}
.ScreenTwo_right-top-selection__32N5n{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0px;
  margin: 0px auto 7px;
  justify-content: center;
  text-align: center;
  /* border-top: 2px solid #e0e0e0; */
 
}
.ScreenTwo_angle-sec__11M5O {
  /* background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  width: 90%;
  margin-left: 5%; */
  background-color: #fbfbfb;
    border-bottom: 1px solid #ddd;
}
.ScreenTwo_top-bar__285es {
  padding: 10px 0px;
  padding: 15px 0px 10px;
}
.ScreenTwo_credit-text__2ohWn {
  font-size: 14px;
  font-family: poppins;
  color: #232121;
  text-align: center;
  /* display: flex;
  justify-content:flex-end; */
}
.ScreenTwo_avail-credit__sIbHs{
  text-align: left!important;
}
.ScreenTwo_right-top-credits__2TEin{
  text-align: right;
  padding: 0;
  margin-bottom: 0px;
  
}
.ScreenTwo_credit-pos__7w1_H{
 
}
.ScreenTwo_right-top-credits__2TEin button{
  padding: 5px 10px;
  width: auto;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #FF7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.ScreenTwo_right-top-credits__2TEin img{
  width:25px !important;
}
.ScreenTwo_add-credit__c9CuE{
  color: white !important;
}
.ScreenTwo_overlay-pos__3ExFM {
  position: absolute;
  width: 100%;
}
.ScreenTwo_more-btn-overlay-2__xdFgj {
  position: absolute;
  top: 25%;
    left: 20%;
    color: black;
    font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.ScreenTwo_more-btn-overlay-2__xdFgj span{
  font-size: 17px;
  display: block;
}
.ScreenTwo_credit-left-area__1BjWH{
  width:64%
}
.ScreenTwo_upload-area__1iE5x{
    text-align: center;
    justify-content: center;
}
.ScreenTwo_bg-main__3KDZ9{
    /* background: #FBFBFB;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);  */
padding: 35px 0;
margin-top: 70px;
}
.ScreenTwo_inactive-background-sec-below__5aia4 img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}

.ScreenTwo_inactive-background-sec__2nB2B{
  padding: 5px;
  box-shadow: 0 2px 5px 0 #ddd;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  width: auto;
}
.ScreenTwo_inactive-background-sec__2nB2B span{
  font-size: 13px;
}
.ScreenTwo_inactive-background-sec__2nB2B img {
  width: 100%;
  height: 46px;
  cursor: pointer;
  border-radius: 5px;
  /* border: 0.4px solid #333; */
}
.ScreenTwo_img-overlay__3fVqM {
  position: absolute;
  /* background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  ); */
  height: 100%;
  bottom: 0%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
}
.ScreenTwo_marketplaces__3Hea1{
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-row-gap: 9px;
  margin-left: 20px;
  padding: 25px 0;
}
.ScreenTwo_marketplaces__3Hea1 img{
  width: 100%;
  height: 80px;
  object-fit: contain;
  padding: 10px;
}
.ScreenTwo_more-box__2NKX6{
  box-shadow: 0 0 0!important;
  padding: 5px;
}
.ScreenTwo_more-box__2NKX6 img{
  border: 0!important;
  margin-top: 0;
  height: 100%!important;
  padding: 10px;
}
.ScreenTwo_active-visual-selection__3LkaK{ 
  border-top: 2px solid #FF7700;
  color: #FF7700;
  background: #fff;
  padding: 10px;
}
.ScreenTwo_inactive-visual-selection__1Rj1R{
  /* border-bottom: 2px solid #FF7700; */
  color: #000;
  padding: 10px;
  background: #F5F5F5;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
}
.ScreenTwo_custom-background-list__6gzVJ{
  padding: 20px 10px 20px 35px ;
}
.ScreenTwo_custom-background-list__6gzVJ:before, .ScreenTwo_adjust-height-area__2JRa3:before{
  display: inline-block ;
  content: '' ; 
  position: absolute ;
  top: 0 ;
  left: 15px ;
  width: 10px ;
  height: 100% ;
  /* border-left: 1px dashed #ff7700 ; */
}
 
.ScreenTwo_custom-wall__30o19{
  padding-top: 10px;
}
.ScreenTwo_side-margin__2AH9d{
  border-left: 1px dashed #ff7700;
    margin: 0 16px;
}
.ScreenTwo_custom-wall__30o19 p, .ScreenTwo_adjust-height__3nXcZ p{
  margin: 0 ;
}
.ScreenTwo_adjust-height__3nXcZ p{
  padding: 5px 0 ;
  font-size: 16px ;
}
.ScreenTwo_custom-wall__30o19 p span{
  background: #ff7700 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 13px ;
  margin-right: 10px ;
}
.ScreenTwo_adjust-height__3nXcZ p span{
  background: #E5E5E5 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 12px ;
  margin-right: 10px ;
}



.ScreenTwo_custom-walls-area__1Myqc{
  padding-left: 10px ;
}
.ScreenTwo_adjust-height-area__2JRa3{
  padding: 5px 0px ;
  position: relative ;
}
.ScreenTwo_adjust-height-area__2JRa3 ul{
  padding: 5px 10% ;
  list-style: none ;
  text-align: center ;
  display: flex ;
}
.ScreenTwo_adjust-height-area__2JRa3 ul li{
  text-align: center ;
  width: 35% ;
  padding: 10px ;
  
  margin: 0 auto ;
}
.ScreenTwo_adjust-height-area__2JRa3 ul li button{
  background: rgba(235 215 198 / 0%);
  border: 1px solid #000000;
  box-sizing: border-box ;
  padding: 10px ;
  width: 100% ;    
  height: auto ;
  border-radius: 0 ;
}
.ScreenTwo_adjust-height-area__2JRa3 ul li button.ScreenTwo_active__1zNM9, .ScreenTwo_adjust-height-area__2JRa3 ul li button:hover{
  background: rgb(235 215 198 / 0%);
  border: 1px solid #FF7700; 
}
.ScreenTwo_adjust-height-area__2JRa3 ul li h6{ 
  font-weight: normal ;
  font-size: 14px ; 
  color: #000000 ;
  margin: 0 ;
}
.ScreenTwo_adjust-height-area__2JRa3 ul li img{
  width: 100% ;
}

.ScreenTwo_backgrounds-grid-custom__C_Px4 .ScreenTwo_inactive-background-sec__2nB2B img{
  height: 60px ;
  border: 0 ;
}
.ScreenTwo_active-background-sec__2I1TS:hover .ScreenTwo_white-icon__3B_Of, .ScreenTwo_inactive-background-sec__2nB2B:hover .ScreenTwo_white-icon__3B_Of{
  display: none;
}
.ScreenTwo_active-background-sec__2I1TS:hover .ScreenTwo_hoverable-icon__1-Xze, .ScreenTwo_inactive-background-sec__2nB2B:hover .ScreenTwo_hoverable-icon__1-Xze{
  display: inline-block;
  }
.ScreenTwo_backgrounds-grid__1VRUY .ScreenTwo_inactive-background-sec__2nB2B {
  position: relative;
}
.ScreenTwo_inactive-background-sec__2nB2B img {
  height: 47px;
}

.ScreenTwo_backgrounds-grid__1VRUY {
  display: grid ;
  grid-template-columns: 30.8% 30.8% 30.8%;
  grid-gap: 9px ;
}
.ScreenTwo_backgrounds-grid-custom__C_Px4 {
  display: grid ;
  grid-template-columns: 18.5% 18.5% 18.5% 18.5% 18.5%;
  grid-gap: 7px ;
  margin-top: 10px;
}
.ScreenTwo_backgrounds-grid-below__11Dlg {
  display: grid ;
  /* justify-content: center ; */
  grid-template-columns: 50% 50% ;
  grid-gap: 9px ;
}
.ScreenTwo_below-button__ZGWqL {
  background: unset !;
  /* color; */
  border: 2px solid #ff7700  ;
  height: -webkit-fit-content !;
  height: -moz-fit-content !;
  height: fit-content !;
  margin-top: 2% !;
  border-radius: 30px  ;
}
.ScreenTwo_active-background-sec__2I1TS span{
  font-size: 13px ;
}
.ScreenTwo_inactive-walls-sec__3Iz7j {
  padding: 5px ;
  cursor: pointer ;
  -webkit-transform: scale(1) ;
          transform: scale(1) ;
  cursor: pointer ;
  /* height: 85px ; */
  width: auto ;
}
.ScreenTwo_inactive-walls-sec__3Iz7j span{
  font-size: 13px ;
}
.ScreenTwo_active-walls-sec__37QDF {
  /* box-shadow: 0 2px 5px 0 #ddd ; */
  padding: 3px ;
  border: 2px solid #ff7700 ;
  -webkit-transform: scale(1) ;
          transform: scale(1) ;
  cursor: pointer ;
  /* height: 110px ; */
  width: auto ;
}
.ScreenTwo_active-walls-sec__37QDF span{
  font-size: 13px ;
}
.ScreenTwo_active-walls-sec__37QDF img {
  width: 100% ;
  /* height: 60px ; */
}
.ScreenTwo_inactive-walls-sec__3Iz7j img {
  width: 100% ;
  /* height: 60px ; */
  border: 0.4px solid #333 ;
}
.ScreenTwo_inactive-walls-sec__3Iz7j {
  padding: 3px ;
  border: 2px solid transparent ;
  box-shadow: 0 2px 5px 0 #ddd ;
}



.ScreenTwo_custom__xLIvj {
  font-size: 13px !important;
  font-weight: 500 ;
  /* margin-left: 14% ; */
}
.ScreenTwo_resizable__2SZ9v {
  width: 120px ;
  /* border-radius: 0.75rem ;
  padding: 20px ;
  margin: 1rem ;
  background-color: #29e ;
  color: white ;
  font-size: 20px ;
  font-family: sans-serif ; */
  overflow: hidden ;

  touch-action: none ;

  /* This makes things *much* easier */
  object-fit: fill ;
  box-sizing: border-box ;
  position: absolute ;
  opacity: 0.5 ;
  border-bottom: 10px solid #FF7700 ;
  z-index: 2 ;
}
.ScreenTwo_drag-button__15uJo{
  width: 50px !;
  height: auto ;
  -webkit-transform: rotate(-90deg) ;
          transform: rotate(-90deg) ;
  /* overflow: hidden ; */
  margin-top: -27px ;
}
.ScreenTwo_wall-cross__3uwyn{
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width : 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 0!important;
}
.ScreenTwo_car-text__iym0K h1 {
  font-size: 30px;
}
.ScreenTwo_original-image-div__jgEab {
  width: 100%;
  height: 100%;
}
.ScreenTwo_remove-bg-box__2yYor{
  background: #FFFFFF;
  margin: 0;
/* border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
width:90%;
margin-left: 5%; */
}
.ScreenTwo_remove-bg-area__iOvmo{
    padding: 0;
    background: #fbfbfb;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    border-radius: 5px;

}
.ScreenTwo_credit-text__2ohWn img {
    margin-right: 10px;
    width: 40px;
}
.ScreenTwo_credit-text__2ohWn span {
    color: #f70;
}
.ScreenTwo_cross-btn__31aBh {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:-webkit-max-content;
    width:max-content;
  }
  .ScreenTwo_cross-img__2WnEl {
    width: auto !important;
    cursor: pointer;
    margin-left: 50%;
  }
  .ScreenTwo_credit-text__2ohWn{
    
  }

  .ScreenTwo_download-popup__2CQCj p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 15px;
  }
  .ScreenTwo_download-popup__2CQCj button.ScreenTwo_activate__B37zF,
  .ScreenTwo_download-popup__2CQCj button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .ScreenTwo_download-popup__2CQCj {
    box-shadow: 0px 4px 20px #00000038;
    
  }
  .ScreenTwo_try-drag-drop__cR-_n .ScreenTwo_dropzone1__2WqoZ {
    border: 1px dashed #ffa64d;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    opacity: 0.7;
  }
  .ScreenTwo_try-drag-drop__cR-_n .ScreenTwo_dropzone1__2WqoZ:hover {
    opacity: 1;
  }
  .ScreenTwo_try-drag-drop__cR-_n .ScreenTwo_dropzone1__2WqoZ img {
    margin-bottom: -30px;
    width: 15%;
  }
  
  .ScreenTwo_drop-box-text__3ko0j {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .ScreenTwo_drop-box-text__3ko0j div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .ScreenTwo_download-popup__2CQCj button {
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 85%;;
  }
  .ScreenTwo_cross-btn__31aBh {
    text-align: right;
    padding: 0 0 10px;
  }
  .ScreenTwo_download-popup__2CQCj button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .ScreenTwo_download-popup__2CQCj ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .ScreenTwo_download-popup__2CQCj ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .ScreenTwo_download-popup__2CQCj p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .ScreenTwo_download-popup__2CQCj p span {
    color: #ff7700;
  }
  .ScreenTwo_download-popup__2CQCj h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .ScreenTwo_border-1__3QcTJ {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
    margin-top: 20px;
  }
  .ScreenTwo_download-popup__2CQCj input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .ScreenTwo_download-popup__2CQCj input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .ScreenTwo_download-btn-hd__1Jovb {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .ScreenTwo_containers__1828s {
    max-width: 600px !important;
    border-radius: 10px;
}
  .ScreenTwo_containers__1828s img {
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    margin-top: 100px;
    /* margin: 50px auto 0; */
    margin-top: 20px;
    position: relative !important
}
.ScreenTwo_left-sec-upload-image__25UdA {
    width: auto;
    height: 415px;
}
.ScreenTwo_rating-area__3lJn3{

}
.ScreenTwo_rating-area__3lJn3 p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.ScreenTwo_rating-area__3lJn3 p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.ScreenTwo_sec-2-right-card__cLaUJ{ 
    width:41%;
    padding: 0 0  24.6px;
    background: #fff;
    border-radius: 0;  
  
    border-left: 1px solid #ebeaea
   
}
.ScreenTwo_sec-2-left__345yG{
  width:58%
}
.ScreenTwo_get-mrktplace__3INy2{
    line-height: 50px!important;
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.ScreenTwo_get-mrktplace__3INy2 a{
    margin-left: 10px;
}

.ScreenTwo_css-1eg0sfo-skeletonStyles-Skeleton__3Bgpq {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: ScreenTwo_animation-bzdot9__y3LX0 1.2s ease-in-out infinite;
    animation: ScreenTwo_animation-bzdot9__y3LX0 1.2s ease-in-out infinite;
}
.ScreenTwo_categories__2cGSF{

}
.ScreenTwo_categories__2cGSF h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.ScreenTwo_categories__2cGSF h2 span{
    font-weight: 600;
font-size: 24px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.ScreenTwo_categories__2cGSF h2:before {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_categories__2cGSF h2:after {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_product-hunt-tag__1Xb8N{
    width: auto;
    position: absolute;
    right: 115px;
    top: 0px;
}

.ScreenTwo_last-enhancement__2sys9{
  margin-left: 40px;
  padding: 20px 0;
}
.ScreenTwo_last-enhancement__2sys9 h6{
  font-style: normal;
font-weight: normal;
font-size: 11.3151px;
line-height: 17px; 
color: #FF7700;
}
.ScreenTwo_skin-retouch__gUYA1{

}
.ScreenTwo_skin-retouch__gUYA1 snap{
  font-weight: 500;
font-size: 14.5882px;
line-height: 35px; 
color: #5F5F5F;
}
.ScreenTwo_second-side__gOada{
  border: 0!important;
}

.ScreenTwo_btn-sec__2Iztt button {
  width: 120px;
  height: 44px;
  border-radius: 5px;
}
.ScreenTwo_bg-fashion__1Hj6x{
  padding: 5px;
}

.ScreenTwo_info-box__2Luap{
  background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 6px;
    opacity: 0;
    padding: 10px;
    width: 100px;
    min-height: 50px; 
    z-index: 9;
    position: absolute;
    left: 10px;
    top: 0;
}
.ScreenTwo_info__1dJJF:hover .ScreenTwo_info-box__2Luap{
  opacity: 1;
  /* width: 2px; */
}
.ScreenTwo_info-box__2Luap p{
  padding: 0;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px; 
  color: #FF7700;
}



.ScreenTwo_toggle1__3GjqF {
  position: relative;
  color: #f39e53;
}

.ScreenTwo_toggle1__3GjqF input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


.ScreenTwo_slider1__1tJII {
  position: relative;
  display: block;
  cursor: pointer;
  background: #f39e53;;
  margin-left: 190px;

box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
border-radius: 6px;
  transition: 0.4s;
  width: 100px;
  height: 22px;
}

.ScreenTwo_slider1__1tJII::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 50px;
  background: rgba(39, 35, 35, 0.38);
  transition: 0.7s;
  border-radius: 6px;

}

input:checked + .ScreenTwo_slider1__1tJII {
  background-color: #f39e53;
}

input:checked + .ScreenTwo_slider1__1tJII::before {
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
}

.ScreenTwo_slider2__2BoWn {
  position: relative;
  display: block;
  cursor: pointer;
  background: #d64d4d;
  margin-left: 190px;

box-shadow: 0px 4px 4px rgb(255, 255, 255);
border-radius: 20px;
  transition: 0.2;
  width: 50px;
  height: 28px;
}

.ScreenTwo_slider2__2BoWn::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 22px;
  background: rgb(255, 255, 255);
  transition: 0.2s;
  border-radius: 50%;
  left: 3px;
  top:3px

}

input:checked + .ScreenTwo_slider2__2BoWn {
  background-color: #78f353;
}

input:checked + .ScreenTwo_slider2__2BoWn::before {
  -webkit-transform: translateX(21.5px);
          transform: translateX(21.5px);
}


























/* @Responsive */





  @media screen and (max-width: 1290px) and (min-width: 1025px){
.ScreenTwo_cross-btn__31aBh {
    padding: 0 0 20px;
}


}

.ScreenTwo_main-box__A0pT-{
  margin: 0px 0 50px;
  background: #fff;
}

@media (min-width: 1366px) {
  .ScreenTwo_main-box__A0pT- {
    max-width: 93.5%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1440px) {
  .ScreenTwo_main-box__A0pT- {
    max-width: 89%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
  .ScreenTwo_product-hunt-tag__1Xb8N {
    right: 20%;
  }
}

@media (min-width: 1680px) {
  .ScreenTwo_main-box__A0pT- {
    max-width: 76.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1920px) {
  .ScreenTwo_main-box__A0pT- {
    max-width: 66.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2560px) {
  .ScreenTwo_main-box__A0pT- {
    max-width: 50%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2880px) {
  .ScreenTwo_main-box__A0pT- {
    max-width: 44.2%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.FashionListModal_drop-box-text__mFYkb {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.FashionListModal_drop-box-text__mFYkb div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.FashionListModal_car-main-wrapper__1u54O {
  background-size: cover !important;
  padding-bottom: 60px;
}
.FashionListModal_header-section__1rqka {
  text-align: center;
  padding-top: 100px !important;
}
.FashionListModal_heading-text__3kQqv {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.FashionListModal_heading-para__9KoMq {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.FashionListModal_heading-para__9KoMq span {
  color: #ff7700 !important;
}
.FashionListModal_active-walls-sec__2PNcs img {
  /* height: 35px; */
}
.FashionListModal_inactive-walls-sec__BdHpz img {
  /* height: 35px; */
}
.FashionListModal_inactive-walls-sec__BdHpz {
  padding: 5px;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.FashionListModal_backgrounds-grid-custom__3g2fz {
  display: grid;
  grid-template-columns: 24.25% 24.25% 24.25% 24.25%;
  grid-gap: 1%;
}
.FashionListModal_inactive-walls-sec__BdHpz span {
  font-size: 13px;
}
.FashionListModal_active-walls-sec__2PNcs {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.FashionListModal_active-walls-sec__2PNcs span {
  font-size: 13px;
}
.FashionListModal_active-walls-sec__2PNcs img {
  width: 100%;
  height: auto;
}
.FashionListModal_inactive-walls-sec__BdHpz img {
  width: 100%;
  height: auto;
}
.FashionListModal_inactive-walls-sec__BdHpz {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.FashionListModal_wrapper__2S1jS {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.FashionListModal_left-section__3NZAj {
  width: 40%;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.FashionListModal_mid-section__3dGcU {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.FashionListModal_sample-button__2sipy {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.FashionListModal_sample-button__2sipy:hover {
  color: black;
}
.FashionListModal_dropzone__2_Zlk {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.FashionListModal_dropzone__2_Zlk:hover {
  opacity: 1;
}
.FashionListModal_dropzone__2_Zlk:focus {
  outline: none !important;
  border: none !important;
}
.FashionListModal_para-1__MXpan {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.FashionListModal_para-2__L1ier {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 0px 0 10px;
  padding-top: 15px;
}
.FashionListModal_para-2__L1ier span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
}
.FashionListModal_supports__2jJKE {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.FashionListModal_choose-button__2Bjt9 {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.FashionListModal_choose-button__2Bjt9 button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.FashionListModal_choose-button__2Bjt9 button:hover {
  color: #ffffff;
}
.FashionListModal_choose-button__2Bjt9 button:focus {
  outline: none !important;
}
.FashionListModal_right-section__PjWA3 {
  width: 55%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 25px;
  margin-left: 5%;
  height: auto;
}
/* .right-section div{
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
} */
.FashionListModal_right-section__PjWA3 img {
  /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  width: 100%;
}
.FashionListModal_section-two__1s8wR {
  padding: 57px;
  background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Desktop+-+2+1.png');
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-repeat: repeat-x;
  background-color: white;
}
.FashionListModal_section-two-one__2MC1a {
  margin-top: 125px;
}
.FashionListModal_section-two-one__2MC1a h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 132%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  margin-top: -40px;
}
.FashionListModal_section-two-one__2MC1a p {
  max-width: 519px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}
.FashionListModal_section-two-one__2MC1a span {
  color: #ff7700;
}
.FashionListModal_section-two-one-right__3DN1q {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5886+1.png');
  padding: 70px 50px;
  margin-top: -125px;
  align-items: center;
}
.FashionListModal_section-two-one-right__3DN1q div {
  max-width: 571px;
}
.FashionListModal_section-two-three-right__2y8Zh {
  margin-top: -125px;
  align-items: center;
}
.FashionListModal_section-3__2wHS2 {
  padding: 57px;
}
.FashionListModal_slider__3vdjp {
  height: 100%;
  width: 3px;
  background: white;
}
.FashionListModal_slider__3vdjp img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  margin-left: -25px;
}
.FashionListModal_section-3__2wHS2 {
  background: #ffffff;
}
.FashionListModal_section-3-right__2itG3 div {
  vertical-align: center;
  /* position: absolute;
    top: 50%;
    transform: translate(-50%,-50%); */
  margin-left: 135px;
  margin-top: 20%;
  text-align: center;
}
.FashionListModal_section-3-right__2itG3 p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.FashionListModal_section-3-right__2itG3 button {
  border: unset;
  width: 220px;
  height: 60px;
  background: #ff7700;
  border-radius: 30.5px;
}
.FashionListModal_section-3-right__2itG3 a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
}

.FashionListModal_mob-area__3OFhD {
  padding-top: 0%;
}

.FashionListModal_mob-area__3OFhD .FashionListModal_why-use__3rknv {
  text-align: left;
  padding: 10% 5% 0;
}

.FashionListModal_why-use__3rknv h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}

.FashionListModal_mob-area__3OFhD .FashionListModal_why-use__3rknv p {
  margin-bottom: 30px;
}

.FashionListModal_request-demo__3HHXU {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.FashionListModal_mob-area__3OFhD .FashionListModal_why-use__3rknv .FashionListModal_request-demo2__1pSp6 {
  display: block;
}

.FashionListModal_mob-area__3OFhD .FashionListModal_why-use__3rknv p img {
  margin-top: 30px;
}

.FashionListModal_request-demo2__1pSp6 img {
  height: 42px;
  /* margin-left: 20px; */
}

.FashionListModal_custom-backgrounds__43-N- {
  padding: 60px 5% 40px;
}

.FashionListModal_title__tV2Tr {
  text-align: center;
}

.FashionListModal_title__tV2Tr h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.FashionListModal_exterior-tabs__3-WAN ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}

.FashionListModal_car-try-wrapper__2TDFo {
}
.FashionListModal_try-images-area__1nGvN {
  padding: 5px 15px;
}
.FashionListModal_car-left-section__w6Dj1 {
  margin-top: 0px;
}
.FashionListModal_try-drag-drop__2ZO_3 {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  cursor: pointer;
}
.FashionListModal_try-drag-drop__2ZO_3 .FashionListModal_dropzone1__3uEBx {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.FashionListModal_vertical__3kUYj {
  border-left: 6px solid black;
  height: 200px;
  position: relative;
  left: 50%;
}
.FashionListModal_try-drag-drop__2ZO_3 .FashionListModal_dropzone1__3uEBx img {
  margin-bottom: -30px;
  width: 15%;
}
.FashionListModal_para-11__34W9L {
  font-size: 100px;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
}
.FashionListModal_supports1__Forll {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
.FashionListModal_perfect-output__3GZZX {
}
.FashionListModal_choose-output__mVvVU {
}
.FashionListModal_heading__3wodc h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.FashionListModal_heading__3wodc h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.FashionListModal_heading__3wodc h3 .FashionListModal_border-1__3lGsT {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.FashionListModal_perfect-output-images__1XILU {
  text-align: center;
  margin-top: 5%;
  padding: 20px 15px 0;
}
.FashionListModal_perfect-output-images__1XILU img {
  width: 100%;
}
.FashionListModal_perfect-output-images__1XILU p {
  text-align: center;
}
.FashionListModal_perfect-output-images__1XILU p span a {
  font-style: normal;
  color: #ff7700 !important;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  cursor: pointer;

  box-shadow: 0px 4px 6px 0px #ccc;
}

.FashionListModal_perfect-output-images__1XILU p a {
  background: #ff7700;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 10px;
  box-shadow: 0px 4px 6px 0px #ccc;
}
.FashionListModal_bg-try__2F2nE {
  /* background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
  background-position: top right;
  background-repeat: no-repeat; */
}

.FashionListModal_choose-button__2Bjt9 {
}
.FashionListModal_choose-button__2Bjt9 button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: -webkit-max-content;
  width: max-content;
}
.FashionListModal_bg-recommended__3UZ-Z {
  /* border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 5px 5px;
  
  margin-bottom: 20px; */
}
.FashionListModal_bg-recommended__3UZ-Z h6 {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
  font-weight: 600;
}
.FashionListModal_bg-recommended__3UZ-Z h6 span {
  color: #ff7700;
}
.FashionListModal_bg-recomended-images__3r8U4 {
  list-style: none;
  display: block;
  padding: 0;
  width: 100%;
  /* border-bottom: 1px solid #ddd; */
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
  /* max-height: 480px; */
}

.FashionListModal_bg-recomended-images__3r8U4 li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 10px;
  cursor: pointer;
  width: 50%;
  position: relative;
}
.FashionListModal_bg-recomended-images__3r8U4 li img {
  width: 100%;
  /* height: 100px; */
  border-radius: 5px;
  border: 0.4px solid #333;
}
.FashionListModal_wall-cross__5PkV9 {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.FashionListModal_active-background-sec__3X1tQ img {
  /* border: 2px solid #000;
  transform: scale(1.1); */
  border: 0;
}
.FashionListModal_active-background-sec__3X1tQ {
  border: 2px solid #ff7700;
  padding: 3px !important;
}
.FashionListModal_bg-recomended-images__3r8U4 li span {
  font-size: 11px;
  font-weight: 500;
}
.FashionListModal_perfect-output-1__lPF62 {
  padding: 40px 0px;
  background: #fff;
}
.FashionListModal_perfect-output-1__lPF62 .FashionListModal_heading__3wodc {
  margin-bottom: 20px;
}
.FashionListModal_bg-recomended-area__2pHFQ {
  /* overflow-y: scroll;
  height: 400px; */
}

.FashionListModal_bg-recomended-area-height__3DCYO {
  overflow-y: scroll;
  height: 400px;
}

.FashionListModal_bg-recomended-area__2pHFQ h3 {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.5px;
  padding: 0 10px;
}
.FashionListModal_bg-recomended-area__2pHFQ h3 span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.FashionListModal_bg-recomended-right__1igF_ img {
  width: 100%;
}

.FashionListModal_bg-recommended__3UZ-Z p {
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
}

.FashionListModal_bg-recomended-right__1igF_ {
}
.FashionListModal_bg-recomended-right__1igF_ img {
}

.FashionListModal_bg-recomended-area__2pHFQ .FashionListModal_perfect-output-images__1XILU p {
  margin-top: 2%;
}
.FashionListModal_bg-recomended-area__2pHFQ .FashionListModal_perfect-output-images__1XILU p a {
  padding: 10px;
}

.FashionListModal_download-popup__3q2DD {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.FashionListModal_download-popup__3q2DD button {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}
.FashionListModal_download-popup__3q2DD ul {
  padding-left: 15%;
  margin: 0 0 5px;
}
.FashionListModal_download-popup__3q2DD ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.FashionListModal_download-popup__3q2DD p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 10%;
}
.FashionListModal_download-popup__3q2DD p span {
  color: #ff7700;
}
.FashionListModal_download-popup__3q2DD h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
}
.FashionListModal_download-popup__3q2DD input {
  margin-right: 7%;
  border: 0;
  outline: 0;
}
.FashionListModal_download-popup__3q2DD input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.FashionListModal_download-popup__3q2DD button {
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: left;
}
.FashionListModal_download-popup__3q2DD p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.FashionListModal_download-popup__3q2DD button.FashionListModal_activate__2F4QM,
.FashionListModal_download-popup__3q2DD button:hover {
  background: rgba(5, 121, 223, 0.05) !important;
  border: 1px solid #78838d !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

/* .app-section {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
} */

/* //Favroute background css */

.FashionListModal_fav-icon__DNGo0 {
  position: absolute;
  top: 1px;
  right: 11px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 0px 5px;
  padding: 3px 7px;
}
.FashionListModal_fav-icon__DNGo0 svg {
}
.FashionListModal_white-icon__3hrtg {
}
.FashionListModal_hoverable-icon__1NRLC {
  display: none;
}
.FashionListModal_active-background-sec__3X1tQ:hover .FashionListModal_white-icon__3hrtg,
.FashionListModal_inactive-background-sec__ijYhK:hover .FashionListModal_white-icon__3hrtg {
  display: none;
}
.FashionListModal_active-background-sec__3X1tQ:hover .FashionListModal_hoverable-icon__1NRLC,
.FashionListModal_inactive-background-sec__ijYhK:hover .FashionListModal_hoverable-icon__1NRLC {
  display: inline-block;
}
.FashionListModal_active-background-sec__3X1tQ .FashionListModal_fav-icon__DNGo0 {
  top: 3px;
  right: 3px;
}
.FashionListModal_more-btn-overlay__K7FZF span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ff7700;
  display: block;
  text-align: center;
}

.FashionListModal_bg-recomended-right__1igF_ h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 30px 0;
}
.FashionListModal_colored__3dUaZ {
  color: #ff7700;
}
.FashionListModal_border-bt__2MENv {
  border-bottom: 3px solid #ff7700;
}
.FashionListModal_fav-icon-2__3awSf span {
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 100px;
  margin-right: 5px;
}
.FashionListModal_fav-icon-2__3awSf {
  font-weight: 800;
  font-size: 10px;
  line-height: 30px;
  color: #000000;
}
.FashionListModal_bg-accordion-tab-main__215ab{
    height:100%
}
.FashionListModal_bg-accordion-tab__DkRWv {
  /* border: 1px solid #e0e0e0; */
  box-sizing: border-box;
  padding: 10px 5px 5px;
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  height: 88%;
}
.FashionListModal_multi-dropzone__3mwxN {
  margin: 15px 0;
}
.FashionListModal_multi-bulk__6WBKW {
  padding: 15px;
}
.FashionListModal_multi-try__2K3uY {
  padding: 15px 10px;
}
.FashionListModal_multi-automobile__1plmA {
}
.FashionListModal_multi-automobile__1plmA h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #000000;
}
.FashionListModal_multi-automobile__1plmA p {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  color: #000000;
}
.FashionListModal_multi-automobile__1plmA p span {
  color: #ff7700;
}
.FashionListModal_sku-list__3DmMG {
  text-align: left;
  border-right: 1px solid #ddd;
}
.FashionListModal_sku-list__3DmMG ul {
  padding: 5px 25px;
  list-style: disc;
}
.FashionListModal_sku-list__3DmMG ul li {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.FashionListModal_sku-list__3DmMG ul li span {
  color: #ff7700;
}
.FashionListModal_para-11__34W9L h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  color: #232121;
  padding-top: 15px;
}
.FashionListModal_multi-tab-button__PL-_k {
  text-align: center;
}
.FashionListModal_multi-button__2dki6 button {
  background: #ffe7d2;
  border-radius: 20px;
  font-size: 18px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  width: 85%;
  margin: 0 auto;
  color: #000000;
}
.FashionListModal_multi-button__2dki6 button.FashionListModal_active__39vAW,
.FashionListModal_multi-button__2dki6 button:hover {
  background: #ff7700 !important;
  color: #fff !important;
}
.FashionListModal_multi-button__2dki6 {
}
.FashionListModal_supports__2jJKE {
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #5e5e5e;
  padding-top: 15px;
}
.FashionListModal_image-naming__JlpwA {
}
.FashionListModal_image-naming__JlpwA h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: left;
  color: #000000;
}
.FashionListModal_image-naming__JlpwA h6 span {
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #ff7700;
  float: right;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.FashionListModal_image-naming__JlpwA h3 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #6b9860;
}
.FashionListModal_red__3JJFh {
  color: #ee3434;
}
.FashionListModal_purple__3SoPJ {
  color: #b465d0;
}
.FashionListModal_sku-name__CvGRT {
  background: #ffffff;
  box-shadow: 0px 4px 4px #6b9860;
  padding: 10px;
}
.FashionListModal_sku-name__CvGRT h3 {
}
.FashionListModal_sku-name__CvGRT p strong {
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #000000;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.FashionListModal_sku-cards__3zXAd {
  padding: 0 !important;
  list-style: none;
  justify-content: center;
  display: flex;
}
.FashionListModal_sku-cards__3zXAd li {
  display: inline-block;
  width: 24%;
  margin: 25px 4.5% 10px;
}
.FashionListModal_sku-name__CvGRT {
  height: 100%;
  min-height: 150px;
}
.FashionListModal_sku-cards__3zXAd li:nth-child(2) .FashionListModal_sku-name__CvGRT {
  background: #ffffff;
  box-shadow: 0px 4px 4px #ee3434 !important;
}
.FashionListModal_sku-cards__3zXAd li:nth-child(3) .FashionListModal_sku-name__CvGRT {
  background: #ffffff;
  box-shadow: 0px 4px 4px #b465d0 !important;
}
.FashionListModal_sku-name__CvGRT small {
  font-weight: 300;
  font-size: 10px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #000000;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.FashionListModal_sku-name__CvGRT p {
  font-weight: 300;
  font-size: 10px;
  line-height: 150%;
  align-items: center;
  color: #858585;
  margin: 0;
}
.FashionListModal_sku-name__CvGRT p b {
  font-weight: 600;
}
.FashionListModal_sku-texts__38fTe {
}
.FashionListModal_sku-texts__38fTe p {
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  align-items: center;
  text-align: left;
  color: #6b9860;
}
.FashionListModal_vin-no__2rxXQ {
  font-weight: 600;
  font-size: 10px !important;
  line-height: 100%;
  align-items: center;
  text-align: left;

  color: #000000;
}

/* @Responsive */
@media (max-width: 800px) {
  .FashionListModal_car-main-wrapper__1u54O {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .FashionListModal_wrapper__2S1jS {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .FashionListModal_right-section__PjWA3 {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .FashionListModal_left-section__3NZAj {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .FashionListModal_heading-text__3kQqv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .FashionListModal_heading-para__9KoMq {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .FashionListModal_section-two__1s8wR {
    padding: 27px;
  }
  .FashionListModal_section-two-one__2MC1a {
    margin-top: 70px;
  }
  .FashionListModal_number-image__2ZS6j {
    display: none;
  }
  .FashionListModal_section-two-one__2MC1a h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .FashionListModal_section-two-one__2MC1a p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .FashionListModal_section-two-one-right__3DN1q {
    margin-top: 0px;
  }
  .FashionListModal_section-3-right__2itG3 div {
    margin-left: 0px;
  }
  .FashionListModal_mob-change__2uK6s {
    display: flex;
    flex-direction: column-reverse;
  }
  .FashionListModal_mob-change__2uK6s h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .FashionListModal_section-two-three-right__2y8Zh img {
    margin-top: 120px;
  }
  .FashionListModal_section-3__2wHS2 {
    padding: 27px;
  }
  .FashionListModal_section-3-right__2itG3 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .FashionListModal_section-3-right__2itG3 button {
    width: 150px;
    height: 50px;
  }
  .FashionListModal_try-images-area__1nGvN {
    padding: 5px 0;
    order: 1;
  }

  .FashionListModal_car-left-section__w6Dj1 {
    order: 3;
  }
  .FashionListModal_perfect-output-images__1XILU p {
    text-align: center;
    margin: 0;
  }
  .FashionListModal_try-images-area__1nGvN img {
    width: 100%;
    margin: 20px 0;
  }
  .FashionListModal_choose-button__2Bjt9 {
    padding: 10% 10px;
  }
  .FashionListModal_bg-try__2F2nE {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .FashionListModal_perfect-output-images__1XILU {
    order: 3;
    padding: 15px 0;
  }
  .FashionListModal_bg-recomended-area__2pHFQ {
    overflow: visible;
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .FashionListModal_myclass__1x-Lh {
    margin-bottom: 10px;
  }
  .FashionListModal_vertical__3kUYj {
    border-left: 600px dashed black;
    height: 500px;
  }
  .FashionListModal_mycl__1wzlV {
    padding-top: 200px;
  }
  .FashionListModal_bg-recomended-right__1igF_ img {
    margin: 0px 0px 30px;
  }
  .FashionListModal_custom-big__rMoRG {
    display: none;
  }
  .FashionListModal_bg-recomended-right__1igF_ {
    padding: 0;
  }
  .FashionListModal_bg-recommended__3UZ-Z {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .FashionListModal_active-background-sec__3X1tQ {
    padding: 0 10px;
    border-radius: 5px;
  }
  .FashionListModal_custom-wall__2TJbE {
    height: 90px !important;
    width: 100%;
    margin-bottom: 16px;
  }

  .FashionListModal_inactive-background-sec__ijYhK {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .FashionListModal_active-background-sec__3X1tQ {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 5px;
    border: 2px solid #ff7700;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .FashionListModal_perfect-output-images__1XILU p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .FashionListModal_heading__3wodc h3 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .FashionListModal_heading-text__3kQqv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .FashionListModal_heading-para__9KoMq {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .FashionListModal_section-two-one__2MC1a p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .FashionListModal_mob-change__2uK6s h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .FashionListModal_try-images-area__1nGvN img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .FashionListModal_heading-text__3kQqv {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .FashionListModal_heading-para__9KoMq {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .FashionListModal_left-section__3NZAj {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .FashionListModal_left-section__3NZAj {
    margin-top: 10%;
  }
}

.FashionListModal_product-hunt-strip__30Dmx {
  width: 100%;
  background-color: #cc4d29;
  position: fixed;
  top: 12%;
  z-index: 999;
}

.FashionListModal_product-hunt-strip__30Dmx h6 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding-top: 4px;
}

.FashionListModal_bg-try-mob__IEjSH {
  display: none;
}

@media screen and (max-width: 768px) {
  .FashionListModal_bg-try__2F2nE {
    display: none;
  }
  .FashionListModal_bg-try-mob__IEjSH {
    margin: 120px 0 100px;
    display: block !important;
  }
  .FashionListModal_bg-try-mob__IEjSH h4 {
    font-size: 24px;
    color: #18191f;
    font-weight: 600;
  }
  .FashionListModal_bg-try-mob__IEjSH h6 {
    font-size: 14px;
    color: #929292;
    margin-top: 25px;
  }
  .FashionListModal_bg-try-mob__IEjSH p {
    font-size: 14px;
    color: #929292;
    margin-top: 25px;
    font-weight: 600;
  }
  .FashionListModal_bg-try-mob__IEjSH img {
    margin-top: 20px;
  }
}

.FashionListModal_max-accordion-height__8srYW {
  max-height: 380px;
  overflow-y: scroll;
  height: auto;
}

.CreditModal_credit-modal-text__2ebtQ h4 {
  font-size: 24px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 10px;
}

.CreditModal_credit-modal-text__2ebtQ p {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #5f5f5f;
  margin-top: 11px;
}

.CreditModal_credit-modal-text__2ebtQ {
  margin-top: 0px;
}

.CreditModal_credit-modal-text__2ebtQ button {
  border-radius: 26px;
  color: #fff;
  border: 1px solid #fff;
  background-color: #ff7700;
  margin: 20px 0px 0;
}

.CreditModal_credit-modal-text__2ebtQ img {
  width: 60%;
  margin-top: 15px;
}

.CreditModal_credit-modal-text__2ebtQ h6 {
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 0px;
  color: #000;
  margin-top: 10px;
}

@media (max-width: 768px) {
  /* .credit-modal-text h4 {
    font-size: 30px;
  }

  .credit-modal-text p {
    font-size: 20px;
  } */
}

.DownloadCompleteModal_download-img__sBUkW img {
  width: 100%;
  margin-bottom: 15px;
}

.DownloadCompleteModal_download-right__2XnBq h4 {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 15px;
}

.DownloadCompleteModal_download-right__2XnBq p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  margin-bottom: 10px;
}

.DownloadCompleteModal_download-right__2XnBq {
  padding-top: 0px;
}

.DownloadCompleteModal_gif-sec__17jQS img {
  width: 125px;
}

.DownloadCompleteModal_download-right__2XnBq img {
  padding-right: 10px;
  cursor: pointer;
  width: auto !important;
}

.DownloadCompleteModal_success-cmnt__2kKhs img {
  padding-right: 15px;
  width: 6%;
}

.DownloadCompleteModal_success-cmnt__2kKhs p {
  font-size: 25px;
  margin-bottom: 0;
}

.DownloadCompleteModal_close-btn__19tyV {
  cursor: pointer;
}

.DownloadCompleteModal_cnmt-sec__1siqm h6 {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #ff7700;
}

.DownloadCompleteModal_cnmt-sec__1siqm p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  margin-bottom: 20px;
  color: #5f5f5f;
}

.DownloadCompleteModal_cnmt-sec__1siqm textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px !important;
  min-height: 150px;
}

.DownloadCompleteModal_cnmt-sec__1siqm button {
  background-color: #ff7700;
  color: #fff;
  width: 380px;
  height: 52px;
  font-size: 18px;
  margin: 25px 0;
  border-radius: 25px;
}

::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #7c7c7c;
}

:-ms-input-placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #7c7c7c;
}

::placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #7c7c7c;
}

@media (max-width: 768px) {
  .DownloadCompleteModal_download-right__2XnBq {
    padding-top: 0px;
  }
  .DownloadCompleteModal_download-right__2XnBq h4 {
    font-size: 20px;
  }
  .DownloadCompleteModal_success-cmnt__2kKhs img {
    padding-right: 11px;
    width: 11%;
  }
  .DownloadCompleteModal_success-cmnt__2kKhs p {
    font-size: 18px;
  }

  .DownloadCompleteModal_cnmt-sec__1siqm button {
    background-color: #ff7700;
    color: #fff;
    width: 245px;
    height: 42px;
    font-size: 17px;
    margin: 25px 0;
    border-radius: 25px;
    padding: 0;
  }

  .DownloadCompleteModal_cnmt-sec__1siqm h6 {
    font-size: 20px;
  }

  .DownloadCompleteModal_cnmt-sec__1siqm p {
    font-size: 12px;
  }
  .DownloadCompleteModal_cnmt-sec__1siqm textarea {
    margin-top: 0px !important;
  }
}

body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.bgRemoval_drop-box-text__zi53w {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.bgRemoval_drop-box-text__zi53w div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.bgRemoval_car-main-wrapper__2r15Z {
  background-size: cover !important;
  padding-bottom: 0px;
}
.bgRemoval_back-button__1JeKO{
  padding-top: 100px !important;
  background-color: #fff;
  border: unset;
  border-radius: 11px;
  justify-content: left;
}
.bgRemoval_header-section__2JRlg {
  text-align: center;
  padding-top: 100px;
}
.bgRemoval_heading-text__3ehTz {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.bgRemoval_heading-para__1IsJL {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.bgRemoval_heading-para__1IsJL span {
  color: #ff7700 !important;
}
.bgRemoval_active-walls-sec__3wYb- img {
  height: 35px;
}
.bgRemoval_inactive-walls-sec__19iMJ img {
  height: 35px;
}
.bgRemoval_inactive-walls-sec__19iMJ {
  padding: 5px;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.bgRemoval_backgrounds-grid-custom__P4g5A {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 7px;
}
.bgRemoval_inactive-walls-sec__19iMJ span {
  font-size: 13px;
}
.bgRemoval_active-walls-sec__3wYb- {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.bgRemoval_active-walls-sec__3wYb- span {
  font-size: 13px;
}
.bgRemoval_active-walls-sec__3wYb- img {
  width: 100%;
  height: 90px;
}
.bgRemoval_inactive-walls-sec__19iMJ img {
  width: 100%;
  height: 90px;
}
.bgRemoval_inactive-walls-sec__19iMJ {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.bgRemoval_wrapper__1V5nX {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.bgRemoval_left-section__dTfV0 {
  width: 40%;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.bgRemoval_mid-section__3OpJI {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.bgRemoval_sample-button__Ne_Ah {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.bgRemoval_sample-button__Ne_Ah:hover {
  color: black;
}
.bgRemoval_dropzone__3uFzw {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.bgRemoval_dropzone__3uFzw:hover {
  opacity: 1;
}
.bgRemoval_dropzone__3uFzw:focus {
  outline: none !important;
  border: none !important;
}
.bgRemoval_para-1__2lWNI {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.bgRemoval_para-2__2cLA6 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 0px 0 10px;
}
.bgRemoval_para-2__2cLA6 span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
}
.bgRemoval_supports__2s7LQ {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.bgRemoval_choose-button__3AA35 {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.bgRemoval_choose-button__3AA35 button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.bgRemoval_choose-button__3AA35 button:hover {
  color: #ffffff;
}
.bgRemoval_choose-button__3AA35 button:focus {
  outline: none !important;
}
.bgRemoval_right-section__2GU5d {
  width: 55%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 25px;
  margin-left: 5%;
  height: auto;
}
/* .right-section div{
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
} */
.bgRemoval_right-section__2GU5d img {
  /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  width: 100%;
}
.bgRemoval_section-two__3hrbc {
  padding: 57px;
  background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Desktop+-+2+1.png');
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-repeat: repeat-x;
  background-color: white;
}
.bgRemoval_section-two-one__aVtH0 {
  margin-top: 125px;
}
.bgRemoval_section-two-one__aVtH0 h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 132%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  margin-top: -40px;
}
.bgRemoval_section-two-one__aVtH0 p {
  max-width: 519px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}
.bgRemoval_section-two-one__aVtH0 span {
  color: #ff7700;
}
.bgRemoval_section-two-one-right__9ZV4_ {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5886+1.png');
  padding: 70px 50px;
  margin-top: -125px;
  align-items: center;
}
.bgRemoval_section-two-one-right__9ZV4_ div {
  max-width: 571px;
}
.bgRemoval_section-two-three-right__3HCJU {
  margin-top: -125px;
  align-items: center;
}
.bgRemoval_section-3__1mMGc {
  padding: 57px;
}
.bgRemoval_slider__2NpOU {
  height: 100%;
  width: 3px;
  background: white;
}
.bgRemoval_slider__2NpOU img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  margin-left: -25px;
}
.bgRemoval_section-3__1mMGc {
  background: #ffffff;
}
.bgRemoval_section-3-right__1VXh- div {
  vertical-align: center;
  /* position: absolute;
    top: 50%;
    transform: translate(-50%,-50%); */
  margin-left: 135px;
  margin-top: 20%;
  text-align: center;
}
.bgRemoval_section-3-right__1VXh- p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.bgRemoval_section-3-right__1VXh- button {
  border: unset;
  width: 220px;
  height: 60px;
  background: #ff7700;
  border-radius: 30.5px;
}
.bgRemoval_section-3-right__1VXh- a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
}

.bgRemoval_mob-area__1UCLo {
  padding-top: 0%;
}

.bgRemoval_mob-area__1UCLo .bgRemoval_why-use__1RqE8 {
  text-align: left;
  padding: 10% 5% 0;
}

.bgRemoval_why-use__1RqE8 h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}

.bgRemoval_mob-area__1UCLo .bgRemoval_why-use__1RqE8 p {
  margin-bottom: 30px;
}

.bgRemoval_request-demo__110to {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.bgRemoval_mob-area__1UCLo .bgRemoval_why-use__1RqE8 .bgRemoval_request-demo2__2gV0u {
  display: block;
}

.bgRemoval_mob-area__1UCLo .bgRemoval_why-use__1RqE8 p img {
  margin-top: 30px;
}

.bgRemoval_request-demo2__2gV0u img {
  height: 42px;
  /* margin-left: 20px; */
}

.bgRemoval_custom-backgrounds__27J4z {
  padding: 60px 5% 40px;
}

.bgRemoval_title__3LdSo {
  text-align: center;
}

.bgRemoval_title__3LdSo h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.bgRemoval_exterior-tabs__2cYbO ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}

.bgRemoval_car-try-wrapper__3agB4 {
}
.bgRemoval_try-images-area__3PEkl {
  padding: 5px 15px;
}
.bgRemoval_car-left-section__3vnDZ {
  margin-top: 25px;
}
.bgRemoval_try-drag-drop__2rqSr {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  cursor: pointer;
}
.bgRemoval_try-drag-drop__2rqSr .bgRemoval_dropzone1__xOmta {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.bgRemoval_try-drag-drop__2rqSr .bgRemoval_dropzone1__xOmta:hover {
  opacity: 1;
}
.bgRemoval_try-drag-drop__2rqSr .bgRemoval_dropzone1__xOmta img {
  margin-bottom: -30px;
  width: 15%;
}
.bgRemoval_para-11__1-cw1 {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
}
.bgRemoval_supports1__1i32u {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
.bgRemoval_perfect-output__3aI8x {
}
.bgRemoval_choose-output__2Ec-0 {
}
.bgRemoval_heading__2ppzb h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.bgRemoval_heading__2ppzb h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.bgRemoval_heading__2ppzb h3 .bgRemoval_border-1__3_eNb {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.bgRemoval_perfect-output-images__392Rg {
  text-align: center;
  padding: 20px 15px 0;
}
.bgRemoval_perfect-output-images__392Rg img {
  width: 100%;
}
.bgRemoval_perfect-output-images__392Rg p {
  text-align: center;
}
.bgRemoval_perfect-output-images__392Rg p span a {
  font-style: normal;
  color: #ff7700 !important;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  cursor: pointer;
}

.bgRemoval_perfect-output-images__392Rg p a {
  background: #ff7700;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.bgRemoval_bg-try__273MV {
  /* background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
  background-position: top right;
  background-repeat: no-repeat; */
}

.bgRemoval_choose-button__3AA35 {
}
.bgRemoval_choose-button__3AA35 button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: -webkit-max-content;
  width: max-content;
}
.bgRemoval_bg-recommended__3wcex {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  /* overflow: hidden;
  overflow-y: scroll; */
}
.bgRemoval_bg-recommended__3wcex h6 {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
  font-weight: 600;
}
.bgRemoval_bg-recommended__3wcex h6 span {
  color: #ff7700;
}
.bgRemoval_bg-recomended-images__px_h7 {
  list-style: none;
  display: block;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.bgRemoval_bg-recomended-images__px_h7 li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 10px;
  cursor: pointer;
  width: 50%;
}
.bgRemoval_bg-recomended-images__px_h7 li img {
  width: 100%;
  height: 56px;
}
.bgRemoval_wall-cross__1D3Qf {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.bgRemoval_active-background-sec__13_gz img {
  /* border: 2px solid #000;
  transform: scale(1.1); */
}
.bgRemoval_active-background-sec__13_gz {
  border: 2px solid #000;
  padding: 3px !important;
}
.bgRemoval_bg-recomended-images__px_h7 li span {
  font-size: 11px;
  font-weight: 500;
}
.bgRemoval_perfect-output-1__lGqQ1 {
  padding: 40px 0px;
  background: #fff;
}
.bgRemoval_perfect-output-1__lGqQ1 .bgRemoval_heading__2ppzb {
  margin-bottom: 20px;
}
.bgRemoval_bg-recomended-area__3oLLf {
  overflow-y: scroll;
  height: 400px;
}

.bgRemoval_bg-recomended-right__23ZNG img {
  width: 100%;
}

.bgRemoval_bg-recommended__3wcex p {
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
}

.bgRemoval_bg-recomended-right__23ZNG {
}
.bgRemoval_bg-recomended-right__23ZNG img {
  margin-top: 20%;
}

.bgRemoval_bg-recomended-area__3oLLf .bgRemoval_perfect-output-images__392Rg p {
  margin-top: 2%;
}
.bgRemoval_bg-recomended-area__3oLLf .bgRemoval_perfect-output-images__392Rg p a {
  padding: 10px;
}

.bgRemoval_download-popup__1gZsg {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.bgRemoval_download-popup__1gZsg button {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}
.bgRemoval_download-popup__1gZsg ul {
  padding-left: 15%;
  margin: 0 0 5px;
}
.bgRemoval_download-popup__1gZsg ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.bgRemoval_download-popup__1gZsg p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 10%;
}
.bgRemoval_download-popup__1gZsg p span {
  color: #ff7700;
}
.bgRemoval_download-popup__1gZsg h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
}
.bgRemoval_download-popup__1gZsg input {
  margin-right: 7%;
  border: 0;
  outline: 0;
}
.bgRemoval_download-popup__1gZsg input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.bgRemoval_download-popup__1gZsg button {
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: left;
}
.bgRemoval_download-popup__1gZsg p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.bgRemoval_download-popup__1gZsg button.bgRemoval_activate__3iQF7,
.bgRemoval_download-popup__1gZsg button:hover {
  background: rgba(5, 121, 223, 0.05) !important;
  border: 1px solid #78838d !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

/* .app-section {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
} */

.bgRemoval_heading-text__3ehTz{
  padding: 0 20px;
}
/* Upload screen User credits */
.bgRemoval_heading-text__3ehTz span{
  border: 2px solid #FFE7D2;
  border-radius: 10px;
  float: right;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding: 7px 10px;
  letter-spacing: 0.5px;
  margin-top: 7px;
  color: #232121;
  position: absolute;
  right: 20px;
}
.bgRemoval_heading-text__3ehTz span b{
  color: #ff7700;
}
.bgRemoval_heading-text__3ehTz span img{
  margin-right: 20px;
}











/* @Responsive */
@media (max-width: 800px) {
  .bgRemoval_car-main-wrapper__2r15Z {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .bgRemoval_wrapper__1V5nX {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .bgRemoval_right-section__2GU5d {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .bgRemoval_left-section__dTfV0 {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .bgRemoval_heading-text__3ehTz {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__1IsJL {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .bgRemoval_section-two__3hrbc {
    padding: 27px;
  }
  .bgRemoval_section-two-one__aVtH0 {
    margin-top: 70px;
  }
  .bgRemoval_number-image__YcsqG {
    display: none;
  }
  .bgRemoval_section-two-one__aVtH0 h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .bgRemoval_section-two-one__aVtH0 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-two-one-right__9ZV4_ {
    margin-top: 0px;
  }
  .bgRemoval_section-3-right__1VXh- div {
    margin-left: 0px;
  }
  .bgRemoval_mob-change__1q-hD {
    display: flex;
    flex-direction: column-reverse;
  }
  .bgRemoval_mob-change__1q-hD h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-two-three-right__3HCJU img {
    margin-top: 120px;
  }
  .bgRemoval_section-3__1mMGc {
    padding: 27px;
  }
  .bgRemoval_section-3-right__1VXh- p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-3-right__1VXh- button {
    width: 150px;
    height: 50px;
  }
  .bgRemoval_try-images-area__3PEkl {
    padding: 5px 0;
    order: 1;
  }
  .bgRemoval_car-left-section__3vnDZ {
    order: 3;
  }
  .bgRemoval_perfect-output-images__392Rg p {
    text-align: center;
    margin: 0;
  }
  .bgRemoval_try-images-area__3PEkl img {
    width: 100%;
    margin: 20px 0;
  }
  .bgRemoval_choose-button__3AA35 {
    padding: 10% 10px;
  }
  .bgRemoval_bg-try__273MV {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .bgRemoval_perfect-output-images__392Rg {
    order: 3;
    padding: 15px 0;
  }
  .bgRemoval_bg-recomended-area__3oLLf {
    overflow: visible;
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .bgRemoval_bg-recomended-right__23ZNG img {
    margin: 0px 0px 30px;
  }
  .bgRemoval_custom-big__2GxwV {
    display: none;
  }
  .bgRemoval_bg-recomended-right__23ZNG {
    padding: 0;
  }
  .bgRemoval_bg-recommended__3wcex {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .bgRemoval_active-background-sec__13_gz {
    padding: 0 10px;
    border-radius: 5px;
  }
  .bgRemoval_custom-wall__3XIOf {
    height: 90px !important;
    width: 100%;
    margin-bottom: 16px;
  }

  .bgRemoval_inactive-background-sec__BMO0a {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .bgRemoval_active-background-sec__13_gz {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 5px;
    border: 2px solid #ff7700;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .bgRemoval_perfect-output-images__392Rg p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .bgRemoval_heading__2ppzb h3 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .bgRemoval_heading-text__3ehTz {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__1IsJL {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .bgRemoval_section-two-one__aVtH0 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_mob-change__1q-hD h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_try-images-area__3PEkl img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .bgRemoval_heading-text__3ehTz {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__1IsJL {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .bgRemoval_left-section__dTfV0 {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .bgRemoval_left-section__dTfV0 {
    margin-top: 10%;
  }
}

.bgRemoval_product-hunt-strip__1VcvN {
  width: 100%;
  background-color: #cc4d29;
  position: fixed;
  top: 12%;
  z-index: 999;
}

.bgRemoval_product-hunt-strip__1VcvN h6 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding-top: 4px;
}

.LandingModalFashion_firstStep__17hBH {
      height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: contain;
  }
  .LandingModalFashion_firstStep__17hBH img {
    width: 69%;
  }
  .LandingModalFashion_signup-button__1O57i {
     background-color: white !important;
    color: #ff7700 !important;
    width: 150px;
    border-radius: 45px;
    font-size: 16px !important;
    margin-bottom: 12px;
  }
  
  .LandingModalFashion_circle-area-inner__3jHhv a {
    border-bottom: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
  }
  
  .LandingModalFashion_login-check-modal__2jPYg img {
    width: 60px;
    height: auto;
  }
  
  .LandingModalFashion_got-btn__3aOg- {
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 140px;
    height: 40px;
    margin: 20px 0;
  }
  
  .LandingModalFashion_cars-img__2f6S8 {
    width: 100%;
  }
  
  .LandingModalFashion_img-sec__1KfHn {
    height: 355px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 62px;
    text-align: center;
    width: 85%;
  }
  
  .LandingModalFashion_close-btn__1vlUa {
    width: 15px !important;
    cursor: pointer;
  }
  
  .LandingModalFashion_cars-circle__3MCTK {
    width: 80px;
    height: 80px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 50%;
    margin-top: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .LandingModalFashion_cars-circle-active__2GgDn {
    border: 1px solid #ff7700 !important;
    background: #f1d7bf !important;
    border-radius: 50%;
    margin-top: 40px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .LandingModalFashion_cars-circle__3MCTK img {
    margin-top: -13px;
    margin-left: 14px;
  }
  
  .LandingModalFashion_cars-circle-active__2GgDn img {
    margin-top: -13px;
    margin-left: 14px;
  }
  
  .LandingModalFashion_login-here__1toP9 {
    cursor: pointer;
    border-bottom: 1px solid #ff0000;
  }
  
  .LandingModalFashion_forgot-password__2Ayzt {
    font-size: 15px;
    color: #ff7700;
    /* border-bottom: 1px solid #ff7700; */
    cursor: pointer;
  }
  
  .LandingModalFashion_login-check-modal__2jPYg input {
    width: 80%;
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    margin: 25px 0 20px;
  }
  .LandingModalFashion_login-check-modal__2jPYg p {
    padding-top: 1%;
    font-size: 25px;
    margin-bottom: 0%;
  }
  .LandingModalFashion_login-check-modal__2jPYg form p {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #2d2b29;
    font-weight: 400;
    padding: 0;
  }
  .LandingModalFashion_login-check-modal__2jPYg form p a {
    color: #ff7700;
  }
  .LandingModalFashion_login-check-modal__2jPYg button {
    border: unset;
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 50%;
    height: 45px;
    margin: 25px 0 25px;
  }
  
  .LandingModalFashion_or__1IYuq {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    letter-spacing: 0.2px;
    color: #787878;
   border-top: 1px solid #ddd;
    margin: 0px 20% 0px;
  }
  .LandingModalFashion_or__1IYuq span {
    background: #fff;
    position: relative;
    top: -12px;
    padding: 10px;
  }
  
  .LandingModalFashion_img-sec__1KfHn img {
    object-fit: contain;
    width: 82%;
    text-align: center;
    padding-bottom: 40px;
  }
  
  .LandingModalFashion_car-text__31FDk h1 {
    font-size: 30px;
  }
  .LandingModalFashion_circle-area__1nWQf {
    padding: 25px;
    background: #f79c4c;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 50%;
    /* position: absolute; */
    margin: 0 auto 0;
    /* margin-right: -10%; */
    top: 0;
    right: 0;
  }
  .LandingModalFashion_circle-area-inner__3jHhv {
    border: 2px dashed #ffffff;
    box-sizing: border-box;
    padding: 25% 35px 20px;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .LandingModalFashion_circle-area-inner__3jHhv h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 139.5%;
    text-align: center;
  
    color: #ffffff;
  }
  .LandingModalFashion_circle-area-inner__3jHhv p {
    font-weight: normal;
    font-size: 17px;
    line-height: 139.5%;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 20px !important;
    color: #ffffff;
  }
  
  .LandingModalFashion_circle-area-inner__3jHhv span {
    font-weight: 600;
  }
   
  .LandingModalFashion_overlays-models-text__2HuTf p {
    padding: 10px 0 0;
    margin: 0;
  }
  
  .LandingModalFashion_login-check-modal__2jPYg h6 {
    text-align: left;
    padding-left: 87px;
    color: red;
    font-size: 12px;
  }
  .LandingModalFashion_product-hunt__1ThpO img{
    width: 250px;  
    margin: 0 0 0 10px;
  }
  .LandingModalFashion_product-hunt-tag__2Iv7L{
    padding: 0;
      margin: -10% 0 10px!important;
  }
  .LandingModalFashion_product-hunt-tag__2Iv7L a{
  border: 0!important;
  }
  
  
  
  
  @media (max-width: 768px) {
    .LandingModalFashion_hide-in-mobile__3wOkc {
      display: none !important;
    }
    .LandingModalFashion_car-text__31FDk h1 {
      font-size: 19px;
    }
  
    .LandingModalFashion_firstStep__17hBH {
      height: 370px;
      width: 100%;
    }
  
    .LandingModalFashion_signup-button__1O57i {
      width: 100px;
      height: 30px;
      padding: 0;
      font-size: 11px !important;
    }
  
    .LandingModalFashion_login-check-modal__2jPYg img {
      width: 40px;
      height: auto;
    }
  
    .LandingModalFashion_login-check-modal__2jPYg p {
      padding-top: 10px;
      font-size: 18px;
    }
    .LandingModalFashion_login-check-modal__2jPYg input {
      width: 100%;
      border: 0;
      border-bottom: 1px solid #ced4da;
      margin-top: 35px;
    }
  
    .LandingModalFashion_login-check-modal__2jPYg button {
      border: unset;
      background-color: #ff7700;
      border-radius: 50px;
      color: #ffffff;
      padding: 0;
      width: 230px;
      height: 40px;
      margin: 35px 0 20px;
    }
  
    .LandingModalFashion_cars-circle__3MCTK {
      width: 55px;
      height: 55px;
      border: 1px solid #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      background: #fff;
      border-radius: 50%;
      margin-top: 20px;
      cursor: pointer;
    }
  
    .LandingModalFashion_cars-circle-active__2GgDn {
      border: 1px solid #ff7700 !important;
      background: #f1d7bf !important;
      border-radius: 50%;
      margin-top: 20px;
      cursor: pointer;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      width: 55px;
      height: 55px;
    }
  
    .LandingModalFashion_cars-circle__3MCTK img {
      margin-top: 0px;
      margin-left: 0px;
    }
  
    .LandingModalFashion_cars-circle-active__2GgDn img {
      margin-top: 0px;
      margin-left: 0px;
    }
    .LandingModalFashion_img-sec__1KfHn {
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 26px;
      text-align: center;
      width: 98%;
      height: 275px;
    }
    .LandingModalFashion_img-sec__1KfHn img {
      width: 57%;
    }
  
    .LandingModalFashion_firstStep__17hBH img {
      display: block;
    }
    .LandingModalFashion_circle-area__1nWQf {
      padding: 10px;
      /* width: 100%; */
      /* height: auto; */
      border-radius: 0;
      margin-right: 0;
      position: relative;
    }
    .LandingModalFashion_circle-area-inner__3jHhv {
      border-radius: 0;
      padding: 10px;
    }
    .LandingModalFashion_circle-area-inner__3jHhv h3 {
      font-size: 16px;
      margin: 0 0 10px;
    }
    .LandingModalFashion_circle-area-inner__3jHhv p {
      font-size: 10px;
    }
  }
    .LandingModalFashion_trans__k68W7{
      background: transparent !important;
      border-color: transparent !important;
    }
  
  @media screen and (max-width: 900px) and (min-width: 768px) {
    .LandingModalFashion_firstStep__17hBH {
      height: 450px;
    }
  }
  
  @media screen and (max-width: 1100px) and (min-width: 901px) {
    .LandingModalFashion_circle-area__1nWQf {
      margin-right: -15%;
    }
  }
  
.Pricing_pricing-section__27n7E {
  padding: 100px 0 0;
}
.Pricing_pricing-title__bmZxJ {
}
.Pricing_pricing-title__bmZxJ h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  color: #1c1e52;
  text-align: center;

  margin-bottom: 15px;
}
.Pricing_pricing-title__bmZxJ h2 span {
  border-bottom: 4px solid #ff7700;
  padding-bottom: 5px;
}
.Pricing_pricing-title__bmZxJ p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.Pricing_pricing-title__bmZxJ p span {
  border-bottom: 1px solid #000;
}
.Pricing_pricing-title__bmZxJ h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ff7700;
}
.Pricing_pricing-area__3d02V {
  padding: 80px 50px 50px;
}
.Pricing_pricing-cols__2MRkX {
}

.Pricing_pricing-single__2c4z6 {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px 20px;
  border-right: 0;
  z-index: 1;
  position: relative;
}
::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff7700;
}
.Pricing_pricing-single__2c4z6 img {
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 7px #ddd;
  border-radius: 50%;
  width: auto;
  margin-top: -13%;
  background: #ffff;
}
.Pricing_pricing-single__2c4z6 h2 {
  font-weight: 600;
  font-size: 45px;
  line-height: 72px;
  text-align: center;
  color: #ff7700;
  padding: 10px;
  margin: 0;
}
.Pricing_pricing-single__2c4z6 h2 strike {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #000000;
}
.Pricing_pricing-single__2c4z6 h2 span {
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
}
.Pricing_pay-as-you-go__nGBZN {
  text-align: center !important;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #1c1e52;
}
.Pricing_pricing-single__2c4z6 h6 span {
  border-bottom: 4px solid #ff7700;
  padding: 5px 0;
}
/* .pricing-right h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #232121;
  text-align: center;
} */
.Pricing_pricing-single__2c4z6 .Pricing_rate__1j5MP span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #ff3503;
}
.Pricing_pricing-single__2c4z6 .Pricing_rate__1j5MP {
  color: #1c1e52;
  font-size: 12px;
}
.Pricing_pricing-right__2QsIk {
  padding: 15px 20px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  margin: 0;
  box-shadow: 1px 2px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin-top: 5%;
  border-left: 0;
}
.Pricing_pricing-right__2QsIk h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 72px;
  text-align: center;
  color: #ff7700;
  padding: 10px;
  margin: 0;
}
.Pricing_pricing-right__2QsIk h2 strike {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #000000;
}
.Pricing_pricing-right__2QsIk h2 span {
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
}
.Pricing_pricing-right__2QsIk h6 span {
  border-bottom: 4px solid #ff7700;
  padding: 5px 0;
}
/* .pricing-right ul {
  list-style: none;
}
.pricing-right ul li {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #232121;
  padding: 15px 0;
}
.pricing-right ul li img {
  padding-right: 5px;
} */
.Pricing_credit__mVpJn strike {
  font-weight: 500;
  font-size: 13px;
  line-height: normal;
  line-height: initial;
  color: #5f5f63;
  display: block;
}
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ff3503;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.Pricing_input-box-div__IhNd6 {
  width: 100%;
  transition: 0.3s ease-in-out;
  /* display: flex; */
  margin: 0;
  padding: 5px 0;
  cursor: pointer;
}
.Pricing_input-box-div__IhNd6:hover {
  background: #f5f5f5;
}
.Pricing_premium-img__3Yex4 {
}
.Pricing_premium-img__3Yex4 img {
  position: absolute;
  top: -5%;
  right: 5px;
}
.Pricing_save-price__lGBim {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 35px;
  color: #232121;
  margin: 0;
  text-align: center;
}
.Pricing_save-price__lGBim span {
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #5f5f63;
  display: block;
}
.Pricing_pricing-list-single__7KmXV {
  padding: 0 5px;
}
.Pricing_main-price-container__jTn24 {
  padding: 15px 0;
}
.Pricing_reminder__30jkM {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 35px;
}
.Pricing_reminder__30jkM span {
  color: #ff7700;
}
.Pricing_mybtn__ll0dN {
  color: #fff;
  background: #ff7700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 178px;
  height: 43px;
  margin-top: 5px;
}
.Pricing_mybtn__ll0dN:hover {
  color: #fff;
}
.Pricing_per-price-credit__3CQ3e {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #1c1e52;
}
.Pricing_price-breakup__2VEQ3 {
  margin: 0;
  padding: 0px 10px 50px;
}
.Pricing_price-breakup__2VEQ3 h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1c1e52;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 45px;
}

.Pricing_price-breakup__2VEQ3 h2 span {
  display: inline-block;
  border-bottom: 2px solid #000;
  position: relative;
}
.Pricing_price-breakup__2VEQ3 h2 span:hover {
  cursor: pointer;
}
.Pricing_price-breakup__2VEQ3 h2 span::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #f8f8f8;
  transition: width 0.3s;
  position: absolute;
}

.Pricing_price-breakup__2VEQ3 h2 span:hover::after {
  width: 80%;
}
.Pricing_price-table__1WNYv {
  background: #ffffff;
  box-shadow: 1px 8px 85px rgb(0 0 0 / 15%);
  border-radius: 18px;
}
.Pricing_price-table-area__3nMd3 {
  /* background: #FFFFFF;
box-shadow: 1px 8px 85px rgba(0, 0, 0, 0.15);
border-radius: 18px; */
  text-align: center;
  border-bottom: 1px solid #ededed;
}
.Pricing_price-table-left__2qcbN {
  padding: 0px 0 15px;
}
.Pricing_price-table-middle__HG-Ry {
  padding: 0px 0 15px;
}
.Pricing_price-table-right__2TJfy {
  padding: 0px 0 15px;
}
.Pricing_price-table__1WNYv h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1c1e51;
  border-bottom: 1px solid #ededed;
  margin: 0;
  padding: 15px;
}
.Pricing_price-table__1WNYv p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #464767;
  border-bottom: 1px solid #ededed;
  margin: 0;
  padding: 5px 10px;
}
.Pricing_price-table__1WNYv p:nth-last-child(1) {
  border: 0;
}
.Pricing_price-table-left__2qcbN p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #1c1e51;
  border: 0;
  padding: 10px 30%;
  margin-top: 11%;
}

.Pricing_price-table-middle__HG-Ry {
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}
.Pricing_price-table-middle__HG-Ry p:nth-of-type(1),
.Pricing_price-table-right__2TJfy p:nth-of-type(1) {
  padding-top: 15px;
}
.Pricing_credit-radio__EF4Fa {
  padding-left: 5px;
}
.Pricing_price-rate-cr__2alxP {
}
.Pricing_main-price-container__jTn24::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Pricing_main-price-container__jTn24 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* @Responsive  */
@media screen and (max-width: 800px) and (min-width: 200px) {
  .Pricing_pricing-title__bmZxJ {
    font-size: 26px;
    line-height: 30px;
  }
  .Pricing_pricing-cols__2MRkX {
    margin-bottom: 50px;
  }
  .Pricing_premium-img__3Yex4 img {
    top: -6%;
    right: -15px;
  }
  .Pricing_pricing-area__3d02V {
    overflow: hidden;
    padding: 0;
    padding-top: 60px;
  }
  .Pricing_price-table__1WNYv p {
    font-size: 11px;
    line-height: 18px;

    padding: 5px 8px;
  }
  .Pricing_price-table-right__2TJfy p span {
    display: none;
  }
  .Pricing_price-table-left__2qcbN p {
    padding: 10px;
    margin-top: 6%;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  .Pricing_price-table__1WNYv p:first-child {
    padding-top: 5px !important;
  }

  .Pricing_price-table-middle__HG-Ry {
    border: 1px solid #ededed;
    border-bottom: 0;
  }
  .Pricing_price-table-right__2TJfy {
    border: 1px solid #ededed;
    border-bottom: 0;
    border-left: 0;
    padding: 0;
  }
  .Pricing_price-table-middle__HG-Ry {
    padding: 0;
  }
  .Pricing_price-breakup__2VEQ3 {
    margin: 0;
    padding: 0px 0px 50px;
  }
  .Pricing_price-table__1WNYv h4 {
    font-size: 13px;
    line-height: 19px;
    padding: 10px;
  }
  .Pricing_price-table-left__2qcbN {
    padding: 0;
  }
  .Pricing_price-table__1WNYv {
    padding-bottom: 20px;
  }
  .Pricing_price-table-area__3nMd3 {
    text-align: left;
  }
  .Pricing_price-table-right__2TJfy p {
    text-align: center;
  }
  .Pricing_price-table-middle__HG-Ry p:nth-of-type(1),
  .Pricing_price-table-right__2TJfy p:nth-of-type(1) {
    padding-top: 5px;
  }
  .Pricing_price-table-right__2TJfy h4 {
    text-align: center;
  }
  .Pricing_pricing-single__2c4z6 {
    padding: 25px 0 40px;
    height: auto;
  }
  .Pricing_pricing-right__2QsIk {
    display: none;
  }
  .Pricing_pricing-title__bmZxJ h2 {
    font-size: 22px;
    text-align: center;
    line-height: 33px;
    margin: 0 0 15px;
  }
  .Pricing_pricing-title__bmZxJ h2 span {
    border: none;
  }
  .Pricing_pricing-title__bmZxJ p {
    font-size: 11px;
    display: none;
  }
  .Pricing_input-box-div__IhNd6 {
    padding: 5px;
  }
  .Pricing_pricing-single__2c4z6 img {
    margin-top: -22%;
  }
  .Pricing_credit-radio__EF4Fa {
    padding: 0 5px;
  }
  .Pricing_price-rate-cr__2alxP {
    padding: 0;
    text-align: center;
  }
  .Pricing_pricing-list-single__7KmXV {
    text-align: right;
  }
}

.Pricing_pricing-modal-cross__2NsdA {
  text-align: end;
}

.Pricing_pricing-modal-cross__2NsdA img {
  cursor: pointer;
}

.Pricing_pricing-modal-text__1Kv3U h4 {
  text-align: center;
  font-size: 23px;
}

.Pricing_pricing-modal-text__1Kv3U {
  text-align: center;
}

.Pricing_pricing-modal-text__1Kv3U button {
  text-align: center;
  border-radius: 100px;
  margin: 20px;
}

.Pricing_advantage__3Xddp {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  font-family: Poppins;
  font-style: normal;
}

.Pricing_advantage__3Xddp h3 {
  text-align: center;
  font-weight: 600;
  margin-top: -28px;
  margin-bottom: 25px;
}

.Pricing_advantage-content__19ES_ {
  display: flex;
  flex-direction: column;
}

.Pricing_advantage-content-row__1WMiQ {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Pricing_advantage-content-row__1WMiQ div {
  width: 30%;
  justify-content: center;
}
.Pricing_advantage-content-row__1WMiQ div img {
  display: block;
  margin: auto;
}
.Pricing_advantage-content-row__1WMiQ div p {
  display: block;
  text-align: center;
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .Pricing_pricing-area__3d02V {
    padding: 80px 0px 50px;
  }
}

/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex='-1']:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.min.css.map */

#Spinner_modal-root__3i7SZ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
  box-sizing: border-box;
  padding: 20px;
  color: #fff;
}

.Spinner_spinner-box__10F0u {
  position: absolute;
  left: 50%;
  top: 50%;
}

.Spinner_loading-icon__1GXzm {
  display: flex;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #ff7700; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-animation-name: Spinner_spin__igSNi;
          animation-name: Spinner_spin__igSNi;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  transition-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation: Spinner_spin__igSNi 2s linear infinite;
          animation: Spinner_spin__igSNi 2s linear infinite;
}

.Spinner_loading-icon__1GXzm div {
  margin: auto;
}

@-webkit-keyframes Spinner_spin__igSNi {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__igSNi {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Spinner_spinner-background__1LaJ3 {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  );
  top: 0;
}

.Spinner_spinner-box__10F0u span {
  color: #fff;
}


.navbarRemovebg {
  height: 100px;
  width: 100%;
  background-color: transparent;
  position: fixed;
  padding: 20px 40px;
  z-index: 200;
  /* box-shadow: 0px 3px 6px #00000038; */
}
.header-design{
  background: #fff;
  box-shadow: 0px 2px 14px #C4C4C4;
}
.login{
  cursor: pointer;
}

.navbarRemovebg > .navbarRemovebg-header {
  display: inline;
}

.navbarRemovebg > .navbarRemovebg-header > .navbarRemovebg-title {
  display: inline-block;
  font-size: 22px;
  color: black;
  padding: 10px 10px 10px 10px;
}

.navbarRemovebg > .navbarRemovebg-btn {
  display: none;
}
.dropdown-menu {
  width: auto;
}
.navbarRemovebg > .navbarRemovebg-links {
  display: inline;
  float: right;
  font-size: 18px;
}

.navbarRemovebg > .navbarRemovebg-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: black;
  transition: .2s ease-in-out;
}
.navbarRemovebg > .navbarRemovebg-links > div {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: black;
}
select{
  border: unset;
  background-color: unset;
}

.navbarRemovebg > #navbarRemovebg-check {
  display: none;
}
.dropdown{
  cursor: pointer;
}
.dropdown :hover{
  color: #fff !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #ff7700;
}
#use-cases{
  background-color: transparent !important;
  color: black !important;
  border: unset !important;
  border-radius: 0 !important;
  padding: 13px 20px;
  font-family: 'Quicksand';
  font-weight: 600;
  font-size: 15px;
  transition: .2s ease-in-out;
}
.btn:focus{
  box-shadow:  none !important;
}
.nav-left{ 
      margin-left: auto;
      margin-right: 0; 
}
.nav-right{
  /* text-align: end;
  justify-content: flex-end; */

}
.nav-right ul{
list-style: none;
  /* display: flex; */
  margin: 0;
  padding: 0;
}
.nav-right ul li{
  display: inline-block;
  padding: 13px 15px;
  text-decoration: none ;
  color: #000 ;
  font-size: 15px;
  font-family: 'Quicksand';
}
.nav-right ul li a.order-shoot{
  font-weight: 800;
font-size: 15px; 
line-height: 30px;

}
.nav-right ul li a span{
  color: #FF7700;
  border-bottom: 2px solid; 
}
.nav-right ul li a{
  font-size: 15px;
  transition: .2s ease-in-out;
}
.nav-right ul li a.sign-in{
  background: #FF7700;
  border-radius: 5px;
  color: #fff;
  padding: 7px 25px;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 45%); */
min-width: 130px
}
.nav-right ul li a:hover{
  color: #fff!important;
}
.nav-inner-list{

}
.nav-inner-list button{

}
.nav-inner-list ul{
display: none;
}
.nav-inner-list .dropdown-menu.show {
  display: grid;
    width: inherit;
    right: 0;
    left: auto;
    padding: 0;
}
.nav-inner-list li{
  padding: 0px!important;
}
#navbarTogglerDemo02{
  text-align: right!important;
  margin-left: auto;
  margin-right: 0!important;
  
  padding: 0;

}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  font-size: 14px;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #ccc;
  border: 0;
  transition: .3s ease-in-out;
}

.dropdown-item {
  padding: 6px 10px;
  border-radius: 3px;
  transition: .3s ease-in-out;
}
.nav-item{

}
.navbar-nav{
  padding: 0;
  margin: 0;
}

.logo-image{
  width: 160px;
  height: auto;
}








@media (max-width:800px) {
  .navbarRemovebg{
    z-index: 9999;
  }
  .navbarRemovebg-btn{
    margin-top: 20px;
    margin-right: 10px;
  }
  .navbarRemovebg > .navbarRemovebg-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .navbarRemovebg > .navbarRemovebg-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .navbarRemovebg > .navbarRemovebg-btn > label:hover,.navbarRemovebg  #navbarRemovebg-check:checked ~ .navbarRemovebg-btn > label {
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  .navbarRemovebg > .navbarRemovebg-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .navbarRemovebg > .navbarRemovebg-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color:white;
    height: 100px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    margin-top: 25px;
    text-align: center;
  }
  .navbarRemovebg > .navbarRemovebg-links > a {
    display: block;
    width: 100%;
  }
  .navbarRemovebg > .navbarRemovebg-links > div {
    display: block;
    width: 100%;
  }
  .navbarRemovebg > #navbarRemovebg-check:not(:checked) ~ .navbarRemovebg-links {
    height: 0px;
  }
  .navbarRemovebg > #navbarRemovebg-check:checked ~ .navbarRemovebg-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
    height: auto;
    padding-bottom: 20px;
  }
  .login{
    border: 1px solid blue;
    width: 100px !important;
    margin: 0px auto;
    padding: 0px !important;
    border-radius: 5px;
    outline: none;
  }

  #use-cases {
    padding: 10px;
  }

  .nav-right { 
    justify-content: flex-start;
}
.nav-right ul {
  margin: 0;
  padding: 0;
}
.header-design {
  padding: 5px 0px;
}
.navbar-nav { 
  text-align: left;
}
#navbarTogglerDemo02{
  padding: 0;
}
.nav-right ul li { 
  padding: 13px 0px;
}


}



@media (min-width: 1366px) {
  .logo-image{
    width: 200px;
    height: auto;
  }
  .navbar-light .navbar-nav .nav-link{
    font-size: 17px;
  }
  .nav-right ul li a.sign-in{
    font-size: 17px;
  }
}
@media (min-width: 1680px) {
  .logo-image{
    width: 250px;
    height: auto;
  }
  .navbar-light .navbar-nav .nav-link{
    font-size: 20px;
  }
  .nav-right ul li a.sign-in{
    font-size: 20px;
  }
}
@media (min-width: 1920px) {
  .logo-image{
    width: 260px;
    height: auto;
  }
  .navbar-light .navbar-nav .nav-link{
    font-size: 22px;
  }
  .nav-right ul li a.sign-in{
    font-size: 22px;
  }
}






.main-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 99;
}
.main-inner-wrapper{
    padding: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    width: 898px;
    height: 516px;
}
.main-wrapper h1{
    font-size: 30px;
    letter-spacing: 1.8px;
    text-align: left;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: normal;
    color: #272727;
}
.user-email{
    font-size: 20px;
    letter-spacing: 1.2px;
    text-align: left;
    color: #0579DF;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: medium;
}
.boxes{
    margin-top: 40px;
    display: grid;
    grid-template-columns: 32% 32% 31%;
    grid-gap: 20px;
}
.dashboard-small-box{
    border: 0.5px solid #707070;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 20px 20px;
    font-size: 20px;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: bold;
    letter-spacing: 1.38px;
    text-align: left;
    color: #272727;
}
.dashboard-small-box p{
    margin: 0;
}
.upgrade-plan{
    margin-top: 40px;
    border: 0.5px solid #707070;
    width: 100%;
    padding: 10px 10px 20px 20px;
    font-size: 20px;
    font-family: 'Inria Sans', sans-serif;;
    font-weight: bold;
    letter-spacing: 1.38px;
    text-align: left;
    color: #272727;
   
}
.upgrade-plan p {
    margin: 0;
}
.upgrade-plan-para{
    font-size: 20px;
    letter-spacing: 1.2px;
    color: #0579DF;
}
@media (max-width:800px){
    .main-wrapper{
      position:unset;
      padding: 100px 10px 0px 10px;
      -webkit-transform: none;
              transform: none;
    }
    .main-inner-wrapper{
      width: 100%;
      height: 100%;
    }
    .boxes{
      display: flex;
      flex-direction: column;
    }
    .main-wrapper h1{
        font-size: 20px;
        letter-spacing: 1.8pt;
        font-weight: bold;
    }
    .user-email{
        font-size: 14px;
        letter-spacing: 0.84pt;
        font-weight: bold;
    }
    .dashboard-small-box,.upgrade-plan p {
        font-size: 18px;
    }
}
body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.CarsBackgroundReplacement_drop-box-text__2NHxV {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.CarsBackgroundReplacement_drop-box-text__2NHxV div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.CarsBackgroundReplacement_car-main-wrapper__1iwlX {
  background-size: cover !important;
  padding-bottom: 60px;
}
.CarsBackgroundReplacement_header-section__gywTk {
  text-align: center;
  padding-top: 100px !important;
}
.CarsBackgroundReplacement_back-button__3VHxx{
  padding-top: 100px !important;
  background-color: #fff;
  border: unset;
  border-radius: 11px;
  justify-content: left;
}

.CarsBackgroundReplacement_heading-text__2SLrd {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.CarsBackgroundReplacement_heading-para__3jv2g {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  /* margin: 0px; */
  padding: 0px;
}
.CarsBackgroundReplacement_heading-para__3jv2g span {
  color: #ff7700 !important;
}
.CarsBackgroundReplacement_active-walls-sec__-RGKq img {
  height: 35px;
}
.CarsBackgroundReplacement_inactive-walls-sec__1ZX39 img {
  height: 35px;
}
.CarsBackgroundReplacement_inactive-walls-sec__1ZX39 {
  padding: 5px;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.CarsBackgroundReplacement_backgrounds-grid-custom__6QFU0 {
  display: grid;
  grid-template-columns: 24.25% 24.25% 24.25% 24.25%;
  grid-gap: 1%;
}
.CarsBackgroundReplacement_inactive-walls-sec__1ZX39 span {
  font-size: 13px;
}
.CarsBackgroundReplacement_active-walls-sec__-RGKq {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.CarsBackgroundReplacement_active-walls-sec__-RGKq span {
  font-size: 13px;
}
.CarsBackgroundReplacement_active-walls-sec__-RGKq img {
  width: 100%;
  height: auto;
}
.CarsBackgroundReplacement_inactive-walls-sec__1ZX39 img {
  width: 100%;
  height: auto;
}
.CarsBackgroundReplacement_inactive-walls-sec__1ZX39 {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.CarsBackgroundReplacement_wrapper__1178J {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.CarsBackgroundReplacement_left-section__1egC5 {
  width: 40%;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.CarsBackgroundReplacement_mid-section__3BGad {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.CarsBackgroundReplacement_sample-button__2cvai {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.CarsBackgroundReplacement_sample-button__2cvai:hover {
  color: black;
}
.CarsBackgroundReplacement_dropzone__15GPu {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.CarsBackgroundReplacement_dropzone__15GPu:hover {
  opacity: 1;
}
.CarsBackgroundReplacement_dropzone__15GPu:focus {
  outline: none !important;
  border: none !important;
}
.CarsBackgroundReplacement_para-1__y0fX4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.CarsBackgroundReplacement_para-2__30nqT {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 0px 0 10px;
  padding-top: 15px;
}
.CarsBackgroundReplacement_para-2__30nqT span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
}
.CarsBackgroundReplacement_supports__3ijfa {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.CarsBackgroundReplacement_choose-button__2wByl {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.CarsBackgroundReplacement_choose-button__2wByl button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.CarsBackgroundReplacement_choose-button__2wByl button:hover {
  color: #ffffff;
}
.CarsBackgroundReplacement_choose-button__2wByl button:focus {
  outline: none !important;
}
.CarsBackgroundReplacement_right-section__1-SSr {
  width: 55%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 25px;
  margin-left: 5%;
  height: auto;
}
/* .right-section div{
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
} */
.CarsBackgroundReplacement_right-section__1-SSr img {
  /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  width: 100%;
}
.CarsBackgroundReplacement_section-two__3AFQt {
  padding: 57px;
  background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Desktop+-+2+1.png');
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-repeat: repeat-x;
  background-color: white;
}
.CarsBackgroundReplacement_section-two-one__1a6oz {
  margin-top: 125px;
}
.CarsBackgroundReplacement_section-two-one__1a6oz h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 132%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  margin-top: -40px;
}
.CarsBackgroundReplacement_section-two-one__1a6oz p {
  max-width: 519px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}
.CarsBackgroundReplacement_section-two-one__1a6oz span {
  color: #ff7700;
}
.CarsBackgroundReplacement_section-two-one-right__2twt5 {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5886+1.png');
  padding: 70px 50px;
  margin-top: -125px;
  align-items: center;
}
.CarsBackgroundReplacement_section-two-one-right__2twt5 div {
  max-width: 571px;
}
.CarsBackgroundReplacement_section-two-three-right__2VP1l {
  margin-top: -125px;
  align-items: center;
}
.CarsBackgroundReplacement_section-3__1RIln {
  padding: 57px;
}
.CarsBackgroundReplacement_slider__2i-Id {
  height: 100%;
  width: 3px;
  background: white;
}
.CarsBackgroundReplacement_slider__2i-Id img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  margin-left: -25px;
}
.CarsBackgroundReplacement_section-3__1RIln {
  background: #ffffff;
}
.CarsBackgroundReplacement_section-3-right__FkIIf div {
  vertical-align: center;
  /* position: absolute;
    top: 50%;
    transform: translate(-50%,-50%); */
  margin-left: 135px;
  margin-top: 20%;
  text-align: center;
}
.CarsBackgroundReplacement_section-3-right__FkIIf p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.CarsBackgroundReplacement_section-3-right__FkIIf button {
  border: unset;
  width: 220px;
  height: 60px;
  background: #ff7700;
  border-radius: 30.5px;
}
.CarsBackgroundReplacement_section-3-right__FkIIf a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
}

.CarsBackgroundReplacement_mob-area__-XjfS {
  padding-top: 0%;
}

.CarsBackgroundReplacement_mob-area__-XjfS .CarsBackgroundReplacement_why-use__32MZm {
  text-align: left;
  padding: 10% 5% 0;
}

.CarsBackgroundReplacement_why-use__32MZm h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}

.CarsBackgroundReplacement_mob-area__-XjfS .CarsBackgroundReplacement_why-use__32MZm p {
  margin-bottom: 30px;
}

.CarsBackgroundReplacement_request-demo__Z6vOd {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.CarsBackgroundReplacement_mob-area__-XjfS .CarsBackgroundReplacement_why-use__32MZm .CarsBackgroundReplacement_request-demo2__2Xmxt {
  /* display: block; */
}

.CarsBackgroundReplacement_mob-area__-XjfS .CarsBackgroundReplacement_why-use__32MZm p img {
  margin-top: 10px;
}

.CarsBackgroundReplacement_request-demo2__2Xmxt img {
  height: 42px;
  /* margin-left: 20px; */
}

.CarsBackgroundReplacement_custom-backgrounds__3L4tr {
  padding: 60px 5% 40px;
}

.CarsBackgroundReplacement_title__2kza- {
  text-align: center;
}

.CarsBackgroundReplacement_title__2kza- h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.CarsBackgroundReplacement_exterior-tabs__1A5U6 ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}

.CarsBackgroundReplacement_car-try-wrapper__1pGNG {
}
.CarsBackgroundReplacement_try-images-area__BXzwp {
  padding: 5px 15px;
}
.CarsBackgroundReplacement_car-left-section__2174G {
  margin-top: 0px;
}
.CarsBackgroundReplacement_try-drag-drop__X4PiX {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  cursor: pointer;
}
.CarsBackgroundReplacement_try-drag-drop__X4PiX .CarsBackgroundReplacement_dropzone1__1-e8y {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.CarsBackgroundReplacement_vertical__3zhaA {
  border-left: 6px solid black;
  height: 200px;
  position: relative;
  left: 50%;
}
.CarsBackgroundReplacement_try-drag-drop__X4PiX .CarsBackgroundReplacement_dropzone1__1-e8y img {
  margin-bottom: -30px;
  width: 15%;
}
.CarsBackgroundReplacement_para-11__3wnvs {
  font-size: 100px;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
}
.CarsBackgroundReplacement_supports1__1bkRq {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
.CarsBackgroundReplacement_perfect-output__2N8P2 {
}
.CarsBackgroundReplacement_choose-output__1gIvw {
}
.CarsBackgroundReplacement_heading__3VBds h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.CarsBackgroundReplacement_heading__3VBds h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.CarsBackgroundReplacement_heading__3VBds h3 .CarsBackgroundReplacement_border-1__3mvCv {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.CarsBackgroundReplacement_perfect-output-images__AB5zf {
  text-align: center;
  margin-top: 5%;
  padding: 20px 15px 0;
}
.CarsBackgroundReplacement_perfect-output-images__AB5zf img {
  width: 100%;
}
.CarsBackgroundReplacement_perfect-output-images__AB5zf p {
  text-align: center;
}
.CarsBackgroundReplacement_perfect-output-images__AB5zf p span a {
  font-style: normal;
  color: #ff7700 !important;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  cursor: pointer;

  box-shadow: 0px 4px 6px 0px #ccc;
}

.CarsBackgroundReplacement_perfect-output-images__AB5zf p a {
  background: #ff7700;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 10px;
  box-shadow: 0px 4px 6px 0px #ccc;
}
.CarsBackgroundReplacement_bg-try__12jyF {
  /* background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
  background-position: top right;
  background-repeat: no-repeat; */
}

.CarsBackgroundReplacement_choose-button__2wByl {
}
.CarsBackgroundReplacement_choose-button__2wByl button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: -webkit-max-content;
  width: max-content;
}
.CarsBackgroundReplacement_bg-recommended__1nryX {
  /* border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 5px 5px;
  
  margin-bottom: 20px; */
}
.CarsBackgroundReplacement_bg-recommended__1nryX h6 {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
  font-weight: 600;
}
.CarsBackgroundReplacement_bg-recommended__1nryX h6 span {
  color: #ff7700;
}
.CarsBackgroundReplacement_bg-recomended-images__18f1q {
  list-style: none;
  display: block;
  padding: 0;
  width: 100%;
  /* border-bottom: 1px solid #ddd; */
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 480px;
}

.CarsBackgroundReplacement_bg-recomended-images__18f1q li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 10px;
  cursor: pointer;
  width: 50%;
  position: relative;
}
.CarsBackgroundReplacement_bg-recomended-images__18f1q li img {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 0.4px solid #333;
}
.CarsBackgroundReplacement_wall-cross__NeXgZ {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.CarsBackgroundReplacement_active-background-sec__28zss img {
  /* border: 2px solid #000;
  transform: scale(1.1); */
  border: 0;
}
.CarsBackgroundReplacement_active-background-sec__28zss {
  border: 2px solid #000;
  padding: 3px !important;
}
.CarsBackgroundReplacement_bg-recomended-images__18f1q li span {
  font-size: 11px;
  font-weight: 500;
}
.CarsBackgroundReplacement_perfect-output-1__1JBUc {
  padding: 40px 0px;
  background: #fff;
}
.CarsBackgroundReplacement_perfect-output-1__1JBUc .CarsBackgroundReplacement_heading__3VBds {
  margin-bottom: 20px;
}
.CarsBackgroundReplacement_bg-recomended-area__2TII1 {
  /* overflow-y: scroll;
  height: 400px; */
}

.CarsBackgroundReplacement_bg-recomended-area-height__dxHVZ {
  overflow-y: scroll;
  height: 400px;
}

.CarsBackgroundReplacement_bg-recomended-area__2TII1 h3 {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.5px;
  padding: 0 10px;
}
.CarsBackgroundReplacement_bg-recomended-area__2TII1 h3 span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.CarsBackgroundReplacement_bg-recomended-right__1q6WG img {
  width: 100%;
}

.CarsBackgroundReplacement_bg-recommended__1nryX p {
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
}

.CarsBackgroundReplacement_bg-recomended-right__1q6WG {
}
.CarsBackgroundReplacement_bg-recomended-right__1q6WG img {
}

.CarsBackgroundReplacement_bg-recomended-area__2TII1 .CarsBackgroundReplacement_perfect-output-images__AB5zf p {
  margin-top: 2%;
}
.CarsBackgroundReplacement_bg-recomended-area__2TII1 .CarsBackgroundReplacement_perfect-output-images__AB5zf p a {
  padding: 10px;
}

.CarsBackgroundReplacement_download-popup__3wHqp {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.CarsBackgroundReplacement_download-popup__3wHqp button {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}
.CarsBackgroundReplacement_download-popup__3wHqp ul {
  padding-left: 15%;
  margin: 0 0 5px;
}
.CarsBackgroundReplacement_download-popup__3wHqp ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsBackgroundReplacement_download-popup__3wHqp p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 10%;
}
.CarsBackgroundReplacement_download-popup__3wHqp p span {
  color: #ff7700;
}
.CarsBackgroundReplacement_download-popup__3wHqp h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsBackgroundReplacement_download-popup__3wHqp input {
  margin-right: 7%;
  border: 0;
  outline: 0;
}
.CarsBackgroundReplacement_download-popup__3wHqp input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.CarsBackgroundReplacement_download-popup__3wHqp button {
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: left;
}
.CarsBackgroundReplacement_download-popup__3wHqp p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.CarsBackgroundReplacement_download-popup__3wHqp button.CarsBackgroundReplacement_activate__2d1wF,
.CarsBackgroundReplacement_download-popup__3wHqp button:hover {
  background: rgba(5, 121, 223, 0.05) !important;
  border: 1px solid #78838d !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

/* .app-section {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
} */

/* //Favroute background css */

.CarsBackgroundReplacement_fav-icon__1SkDj {
  position: absolute;
  top: 1px;
  right: 11px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 0px 5px;
  padding: 3px 7px;
}
.CarsBackgroundReplacement_fav-icon__1SkDj svg {
}
.CarsBackgroundReplacement_white-icon__1MUo6 {
}
.CarsBackgroundReplacement_hoverable-icon__3IWka {
  display: none;
}
.CarsBackgroundReplacement_active-background-sec__28zss:hover .CarsBackgroundReplacement_white-icon__1MUo6,
.CarsBackgroundReplacement_inactive-background-sec__27ChV:hover .CarsBackgroundReplacement_white-icon__1MUo6 {
  display: none;
}
.CarsBackgroundReplacement_active-background-sec__28zss:hover .CarsBackgroundReplacement_hoverable-icon__3IWka,
.CarsBackgroundReplacement_inactive-background-sec__27ChV:hover .CarsBackgroundReplacement_hoverable-icon__3IWka {
  display: inline-block;
}
.CarsBackgroundReplacement_active-background-sec__28zss .CarsBackgroundReplacement_fav-icon__1SkDj {
  top: 3px;
  right: 3px;
}
.CarsBackgroundReplacement_more-btn-overlay__1ouSL span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ff7700;
  display: block;
  text-align: center;
}

.CarsBackgroundReplacement_bg-recomended-right__1q6WG h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 30px 0;
}
.CarsBackgroundReplacement_colored__2h6wj {
  color: #ff7700;
}
.CarsBackgroundReplacement_border-bt__2JrhX {
  border-bottom: 3px solid #ff7700;
}
.CarsBackgroundReplacement_fav-icon-2__15Xiy span {
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 100px;
  margin-right: 5px;
}
.CarsBackgroundReplacement_fav-icon-2__15Xiy {
  font-weight: 800;
  font-size: 10px;
  line-height: 30px;
  color: #000000;
}
.CarsBackgroundReplacement_bg-accordion-tab__1Qk2H {
  /* border: 1px solid #e0e0e0; */
  box-sizing: border-box;
  padding: 10px 5px 5px;
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}
.CarsBackgroundReplacement_multi-dropzone__FFh5q {
  margin: 15px 0;
}
.CarsBackgroundReplacement_multi-bulk__XF1Ti {
  padding: 15px;
}
.CarsBackgroundReplacement_multi-try__34-SD {
  padding: 15px 10px;
}
.CarsBackgroundReplacement_multi-automobile__2IrxH {
}
.CarsBackgroundReplacement_multi-automobile__2IrxH h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #000000;
}
.CarsBackgroundReplacement_multi-automobile__2IrxH p {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  color: #000000;
}
.CarsBackgroundReplacement_multi-automobile__2IrxH p span {
  color: #ff7700;
}
.CarsBackgroundReplacement_sku-list__1O18e {
  text-align: left;
  border-right: 1px solid #ddd;
}
.CarsBackgroundReplacement_sku-list__1O18e ul {
  padding: 5px 25px;
  list-style: disc;
}
.CarsBackgroundReplacement_sku-list__1O18e ul li {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.CarsBackgroundReplacement_sku-list__1O18e ul li span {
  color: #ff7700;
}


.CarsBackgroundReplacement_sku-list2__3BG9- {
  text-align: left;
  border-right: 1px solid #ddd;
}
.CarsBackgroundReplacement_sku-list2__3BG9- ul {
  padding-bottom: 30px !important;
  border-bottom: 1px solid #E4E4E4 ;
  padding: 5px 25px;
  list-style: disc;
}
.CarsBackgroundReplacement_sku-list2__3BG9- ul li {
  font-family: poppins;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.CarsBackgroundReplacement_sku-list2__3BG9- ul li span {
  color: #ff7700;
}
.CarsBackgroundReplacement_para-11__3wnvs h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  color: #232121;
  padding-top: 15px;
}
.CarsBackgroundReplacement_multi-tab-button__3P9wZ {
  margin-left: 20%;
  /* text-align: center; */
}
.CarsBackgroundReplacement_multi-button__3UmSh button {
  background: #ffe7d2;
  border-radius: 20px;
  font-size: 18px;
  line-height: 100%;
  /* align-items: center;
  text-align: center; */
  width: 55%;
  margin: 0 auto;
  color: #000000;
}
.CarsBackgroundReplacement_multi-button__3UmSh button.CarsBackgroundReplacement_active__2_QWt,
.CarsBackgroundReplacement_multi-button__3UmSh button:hover,
.CarsBackgroundReplacement_multi-button__3UmSh button.CarsBackgroundReplacement_mactive__2LVZp {
  background: #ff7700 !important;
  color: #fff !important;
}
.CarsBackgroundReplacement_multi-button__3UmSh {
}
.CarsBackgroundReplacement_supports__3ijfa {
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #5e5e5e;
  padding-top: 15px;
}
.CarsBackgroundReplacement_image-naming__zJh8H {
  margin-top: 5%;
}
.CarsBackgroundReplacement_image-naming__zJh8H h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: left;
  color: #000000;
}
.CarsBackgroundReplacement_image-naming__zJh8H h6 span {
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #ff7700;
  float: right;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.CarsBackgroundReplacement_image-naming__zJh8H h3 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #6b9860;
}


.CarsBackgroundReplacement_image-naming2__N1ZlI {
}
.CarsBackgroundReplacement_image-naming2__N1ZlI h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  text-align: left;
  color: #000000;
}
.CarsBackgroundReplacement_image-naming2__N1ZlI h2 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  align-items: center;
  text-align: center;
  color: black;
}
.CarsBackgroundReplacement_sku-buttons__R-6zU{
  margin-left: auto;
  margin-right: auto;
  padding: 0 !important;
  border-radius: 4.4444px;
  width:40%;
  min-width: 300px;

  background-color: #FFE7D2;
  margin-top: 6px;
  text-align: center !important;
}
.CarsBackgroundReplacement_image-naming2__N1ZlI button.CarsBackgroundReplacement_notactive__gYDZ3 {
  width: 50% !important;
  min-width: 150px !important;
 
  font-size: 12px;
  color:#ff7700;
  border-radius: 10px;
  background-color: #FFE7D2;

  transition: 1.0s;
}
.CarsBackgroundReplacement_image-naming2__N1ZlI button.CarsBackgroundReplacement_mactive1__3Mq8o, 
.CarsBackgroundReplacement_image-naming2__N1ZlI button:hover{ 
  font-size: 12px;
  width: 50% !important;
  min-width: 150px !important;
 
  border-radius: 10px;
  transition: 1.0s; 
  color:#fff;
  background-color: #ff7700;
}
.CarsBackgroundReplacement_image-naming2__N1ZlI h3 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #6b9860;
}
.CarsBackgroundReplacement_arrow__31-AC{
  margin-top: 60px;
}
.CarsBackgroundReplacement_sku-arrow1__3lvHf {
  padding-right: 10px;
}
.CarsBackgroundReplacement_sku-arrow2__3ygpf {
  padding-left: 10px;
}
.CarsBackgroundReplacement_red__1x_o5 {
  color: #ee3434!important;
}
.CarsBackgroundReplacement_purple__3NS-9 {
  color: #b465d0!important;
}
.CarsBackgroundReplacement_purple2__30qY1 {
  color: #b465d0 !important;
}
.CarsBackgroundReplacement_sku-name__TIkz9 {
  background: #ffffff;
  box-shadow: 0px 4px 4px #6b9860;
  padding: 10px;
}
.CarsBackgroundReplacement_sku-name__TIkz9 h3 {
  position: relative;
}
.CarsBackgroundReplacement_sku-name__TIkz9 p strong {
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #000000;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.CarsBackgroundReplacement_sku-cards__12cLe {
  padding: 0 !important;
  list-style: none;
  justify-content: center;
  display: flex;
}
.CarsBackgroundReplacement_sku-cards__12cLe li {
  display: inline-block;
  width: 24%;
  margin: 25px 4.5% 10px;
}
.CarsBackgroundReplacement_sku-name__TIkz9 {
  height: 100%;
  min-height: 150px;
}
.CarsBackgroundReplacement_sku-cards__12cLe li:nth-child(2) .CarsBackgroundReplacement_sku-name__TIkz9 {
  background: #ffffff;
  box-shadow: 0px 4px 4px #ee3434 !important;
}
.CarsBackgroundReplacement_sku-cards__12cLe li:nth-child(3) .CarsBackgroundReplacement_sku-name__TIkz9 {
  background: #ffffff;
  box-shadow: 0px 4px 4px #b465d0 !important;
}
.CarsBackgroundReplacement_sku-name__TIkz9 small {
  font-weight: 300;
  font-size: 10px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  color: #000000;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.CarsBackgroundReplacement_sku-name__TIkz9 p {
  font-weight: 300;
  font-size: 10px;
  line-height: 150%;
  align-items: center;
  color: #858585;
  margin: 0;
}
.CarsBackgroundReplacement_sku-name__TIkz9 p b {
  font-weight: 600;
}
.CarsBackgroundReplacement_sku-texts__T0Sx_ {
}

.CarsBackgroundReplacement_sku-texts__T0Sx_ p {
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  align-items: center;
  text-align: left;
  color: #6b9860;
}
.CarsBackgroundReplacement_vin-no__37baE {
  font-weight: 600;
  font-size: 10px !important;
  line-height: 100%;
  align-items: center;
  text-align: left;

  color: #000000;
}
.CarsBackgroundReplacement_vin-no-s2__2J9sl {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 100%;
  align-items: center;
  text-align: left;

  color: #000000;
}
.CarsBackgroundReplacement_heading-text__2SLrd{
  padding: 0 20px;
}
/* Upload screen User credits */
.CarsBackgroundReplacement_heading-text__2SLrd span{
  border: 2px solid #FFE7D2;
  border-radius: 10px;
  float: right;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding: 7px 10px;
  letter-spacing: 0.5px;
  margin-top: 7px;
  color: #232121;
  position: absolute;
  right: 20px;
}
.CarsBackgroundReplacement_heading-text__2SLrd span b{
  color: #ff7700;
}
.CarsBackgroundReplacement_heading-text__2SLrd span img{
  margin-right: 20px;
}
.CarsBackgroundReplacement_applestore__1_1OI{
  margin-left: 15px!important;
}

.CarsBackgroundReplacement_skuname-text__1sJK8 {
  
}
.CarsBackgroundReplacement_skuname-details__3Zc9F {
  
}
.CarsBackgroundReplacement_skuname-details__3Zc9F ul {
  border-bottom: none;
  list-style: none;
  padding: 0;
}
.CarsBackgroundReplacement_skuname-details__3Zc9F ul li {
  color: #585858;
}
.CarsBackgroundReplacement_image-naming__zJh8H .CarsBackgroundReplacement_sku-nm__iGDMF, .CarsBackgroundReplacement_ext-nm__x_zu_, .CarsBackgroundReplacement_num-nm__yElE1{
  position: relative;
}
.CarsBackgroundReplacement_sku-nm__iGDMF:after{
  content: '';
  position: absolute;
  width: 1px;
  background: #6b9860;
  height: 130px;
  left: -36px;
  -webkit-transform: rotate(72deg);
          transform: rotate(72deg);
  top: -85%;
}
.CarsBackgroundReplacement_ext-nm__x_zu_:after{
  content: '';
  position: absolute;
  width: 1.5px;
  background: #ee3434;
  height: 65px;
  left: -35%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 48%;
}
.CarsBackgroundReplacement_num-nm__yElE1:after{
  content: '';
    position: absolute;
    width: 3px;
    background: #b465d0;
    height: 50px;
    -webkit-transform: skew(65deg, 10deg);
            transform: skew(65deg, 10deg);
    top: 23px;
    right: -56px;

}
.CarsBackgroundReplacement_multi-main-bg__1lBDR{
  background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png');
  background-size: 40% 100%;
  background-position: right;
  background-repeat: no-repeat;
}
.CarsBackgroundReplacement_credits-data__36HxX span{
  background: #FFE7D2;
  border-radius: 10px;
  float: right;
  font-weight: normal;
  font-size: 14px;
  /* line-height: 150%; */
  padding: 7px 10px;
  letter-spacing: 0.5px;
  margin-top: 5px;
  color: #232121;
  position: absolute;
  right: 20px;
  margin: 100px 0 0;
}
.CarsBackgroundReplacement_credits-data__36HxX span img {
  margin-right: 15px;
}
.CarsBackgroundReplacement_credits-data__36HxX span b {
  color: #f70;
}










/* @Responsive */
@media (max-width: 800px) {
  .CarsBackgroundReplacement_car-main-wrapper__1iwlX {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .CarsBackgroundReplacement_wrapper__1178J {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .CarsBackgroundReplacement_right-section__1-SSr {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .CarsBackgroundReplacement_left-section__1egC5 {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .CarsBackgroundReplacement_heading-text__2SLrd {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .CarsBackgroundReplacement_heading-para__3jv2g {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .CarsBackgroundReplacement_section-two__3AFQt {
    padding: 27px;
  }
  .CarsBackgroundReplacement_section-two-one__1a6oz {
    margin-top: 70px;
  }
  .CarsBackgroundReplacement_number-image__-SecC {
    display: none;
  }
  .CarsBackgroundReplacement_section-two-one__1a6oz h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .CarsBackgroundReplacement_section-two-one__1a6oz p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_section-two-one-right__2twt5 {
    margin-top: 0px;
  }
  .CarsBackgroundReplacement_section-3-right__FkIIf div {
    margin-left: 0px;
  }
  .CarsBackgroundReplacement_mob-change__14rX_ {
    display: flex;
    flex-direction: column-reverse;
  }
  .CarsBackgroundReplacement_mob-change__14rX_ h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_section-two-three-right__2VP1l img {
    margin-top: 120px;
  }
  .CarsBackgroundReplacement_section-3__1RIln {
    padding: 27px;
  }
  .CarsBackgroundReplacement_section-3-right__FkIIf p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_section-3-right__FkIIf button {
    width: 150px;
    height: 50px;
  }
  .CarsBackgroundReplacement_try-images-area__BXzwp {
    padding: 5px 0;
    order: 1;
  }

  .CarsBackgroundReplacement_car-left-section__2174G {
    order: 3;
    padding: 0;
  }
  .CarsBackgroundReplacement_perfect-output-images__AB5zf p {
    text-align: center;
    margin: 0;
  }
  .CarsBackgroundReplacement_try-images-area__BXzwp img {
    width: 100%;
    margin: 20px 0;
  }
  .CarsBackgroundReplacement_choose-button__2wByl {
    padding: 10% 10px;
  }
  .CarsBackgroundReplacement_bg-try__12jyF {
    background-position: right;
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png');
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .CarsBackgroundReplacement_perfect-output-images__AB5zf {
    order: 3;
    padding: 15px 0;
  }
  .CarsBackgroundReplacement_bg-recomended-area__2TII1 {
    overflow: visible;
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .CarsBackgroundReplacement_myclass__3FitN {
    margin-bottom: 10px;
  }
  .CarsBackgroundReplacement_vertical__3zhaA {
    border-left: 600px dashed black;
    height: 500px;
  }
  .CarsBackgroundReplacement_mycl__L6NBF {
    padding-top: 200px;
  }
  .CarsBackgroundReplacement_bg-recomended-right__1q6WG img {
    margin: 0px 0px 30px;
  }
  .CarsBackgroundReplacement_custom-big__2Y-V2 {
    display: none;
  }
  .CarsBackgroundReplacement_bg-recomended-right__1q6WG {
    padding: 0;
  }
  .CarsBackgroundReplacement_bg-recommended__1nryX {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .CarsBackgroundReplacement_active-background-sec__28zss {
    padding: 0 10px;
    border-radius: 5px;
  }
  .CarsBackgroundReplacement_custom-wall__y7P7k {
    height: 90px !important;
    width: 100%;
    margin-bottom: 16px;
  }

  .CarsBackgroundReplacement_inactive-background-sec__27ChV {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .CarsBackgroundReplacement_active-background-sec__28zss {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 5px;
    border: 2px solid #ff7700;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .CarsBackgroundReplacement_perfect-output-images__AB5zf p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .CarsBackgroundReplacement_heading__3VBds h3 {
    font-size: 16px;
  }
  .CarsBackgroundReplacement_applestore__1_1OI img{
    margin-top: 20px;
  }

}


@media (max-width: 600px) {
  .CarsBackgroundReplacement_heading-text__2SLrd {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .CarsBackgroundReplacement_heading-para__3jv2g {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .CarsBackgroundReplacement_section-two-one__1a6oz p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_mob-change__14rX_ h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_try-images-area__BXzwp img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .CarsBackgroundReplacement_heading-text__2SLrd {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .CarsBackgroundReplacement_heading-para__3jv2g {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .CarsBackgroundReplacement_left-section__1egC5 {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .CarsBackgroundReplacement_left-section__1egC5 {
    margin-top: 10%;
  }
}

.CarsBackgroundReplacement_product-hunt-strip__1E3e9 {
  width: 100%;
  background-color: #cc4d29;
  position: fixed;
  top: 12%;
  z-index: 999;
}

.CarsBackgroundReplacement_product-hunt-strip__1E3e9 h6 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding-top: 4px;
}

.CarsBackgroundReplacement_bg-try-mob__1W-hs {
  display: none;
}

@media screen and (max-width: 768px) {
  .CarsBackgroundReplacement_bg-try__12jyF {
    display: none;
  }
  .CarsBackgroundReplacement_bg-try-mob__1W-hs {
    margin: 120px 0 100px;
    display: block !important;
  }
  .CarsBackgroundReplacement_bg-try-mob__1W-hs h4 {
    font-size: 24px;
    color: #18191f;
    font-weight: 600;
  }
  .CarsBackgroundReplacement_bg-try-mob__1W-hs h6 {
    font-size: 14px;
    color: #929292;
    margin-top: 25px;
  }
  .CarsBackgroundReplacement_bg-try-mob__1W-hs p {
    font-size: 14px;
    color: #929292;
    margin-top: 25px;
    font-weight: 600;
  }
  .CarsBackgroundReplacement_bg-try-mob__1W-hs img {
    margin-top: 20px;
  }
  .CarsBackgroundReplacement_header-section__gywTk{
    padding-top: 80px !important;
}
.CarsBackgroundReplacement_multi-tab-button__3P9wZ{
  margin-left: 0;
}
.CarsBackgroundReplacement_multi-button__3UmSh button {
  font-size: 14px;
  width: auto;
  padding: 8px 15px;
}
.CarsBackgroundReplacement_multi-button__3UmSh{
  text-align: center;
}
.CarsBackgroundReplacement_multi-bulk__XF1Ti {
  padding: 15px 0px;
  margin: 0;
}
.CarsBackgroundReplacement_multi-automobile__2IrxH h5 {
  font-size: 15px;
}
.CarsBackgroundReplacement_image-naming__zJh8H h6 {
  font-size: 12px;
}
.CarsBackgroundReplacement_image-naming__zJh8H h6 span {
  font-size: 9px;
}
.CarsBackgroundReplacement_image-naming__zJh8H{
  padding: 0;
  margin-top: 10px;
}
.CarsBackgroundReplacement_try-drag-drop__X4PiX {
  padding: 20px 10px;

}
.CarsBackgroundReplacement_sku-nm__iGDMF:after { 
  height: 90px;
  left: 0px; 
  top: -45%;
}
.CarsBackgroundReplacement_ext-nm__x_zu_:after {
  height: 40px;
  left: -13%;
  top: 75%;
}
.CarsBackgroundReplacement_sku-cards__12cLe li { 
  width: 33%;
  margin: 25px 1% 10px;
}
.CarsBackgroundReplacement_num-nm__yElE1:after { 
  height: 30px;
  -webkit-transform: skew(55deg, 10deg);
          transform: skew(55deg, 10deg);
  top: 20px;
  right: -25px;
}

.CarsBackgroundReplacement_sku-name__TIkz9 p strong { 
  font-size: 10px;
}
.CarsBackgroundReplacement_sku-name__TIkz9 p { 
  font-size: 7px;
}
.CarsBackgroundReplacement_sku-name__TIkz9 small { 
  font-size: 8px;
}
.CarsBackgroundReplacement__sku-name__2DQcI {
  padding: 10px 5px;
}
.CarsBackgroundReplacement_sku-list__1O18e ul {
  margin: 0;
}
.CarsBackgroundReplacement_try-drag-drop__X4PiX .CarsBackgroundReplacement_dropzone1__1-e8y img {
  margin-bottom: -30px;
  width: 30%;
}
.CarsBackgroundReplacement_para-11__3wnvs h4 { 
  font-size: 20px;
}
.CarsBackgroundReplacement_para-2__30nqT {
  font-size: 14px;
  padding-top: 0px;
}
.CarsBackgroundReplacement_supports__3ijfa { 
  font-size: 9px;
  line-height: 155%; 
  padding-top: 0px; 
}
.CarsBackgroundReplacement_multi-automobile__2IrxH h3{
  font-size: 20px;
  margin-top: 10px;
}
.CarsBackgroundReplacement_image-naming__zJh8H h3 {
  font-size: 13px;
}
.CarsBackgroundReplacement_sku-list2__3BG9- ul li {
  font-size: 12px;
  line-height: 165%;
}
.CarsBackgroundReplacement_image-naming2__N1ZlI h2 {
  font-size: 14px;
  margin-bottom: 10px;
}
.CarsBackgroundReplacement_image-naming2__N1ZlI button {
  padding: 5px 15px;
}
.CarsBackgroundReplacement_drag-file-area__3KwGS{
  margin: 0 0 30px!important;
  padding: 0!important;
}
.CarsBackgroundReplacement_multi-automobile__2IrxH {
  order: 2;
}
}

@media screen and (max-width: 900px) and (min-width: 768px) {
.CarsBackgroundReplacement_image-naming2__N1ZlI button.CarsBackgroundReplacement_notactive__gYDZ3 { 
  padding: 7px 10px;
}
}
.CarsBackgroundReplacement_max-accordion-height__3kQ4A {
  max-height: 380px;
  overflow-y: scroll;
  height: auto;
}

.FootwearV2_bg-white__2PfNI {
  background: #fff;
}
.FootwearV2_banner-slider__gajOn {
  margin: 65px 0;
}

.FootwearV2_car-v2-tabs__1cTU4 {
  padding: 60px 0px 0px;
}
.FootwearV2_title-area__3dLg3 {
  margin-bottom: 7%;
}
.FootwearV2_title-area__3dLg3 h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  color: #323232;
  text-align: center;
  margin: 0;
  margin-bottom: 50px;
}
.FootwearV2_title-area__3dLg3 p {
  text-align: center;
}
.FootwearV2_title__2IEb9 h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #323232;
}
.FootwearV2_title__2IEb9 h6 span {
  color: #ff7700;
}
.FootwearV2_colored__2nLZU {
  color: #ff7700 !important;
  border: none !important;
}

.FootwearV2_request-demo__2y8w3 {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 18px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}
.FootwearV2_get-app-now__2VYfO {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  background: #fd8061;
}
.FootwearV2_request-demo__2y8w3:hover {
  border: 2px solid #ff7700;
  background: #fff;
  color: #ff7700;
}
.FootwearV2_car-v2-left__1P9FO {
}
.FootwearV2_why-use__3S6X2 h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}
.FootwearV2_why-use__3S6X2 h1 span {
  border-bottom: 2px solid #fd8061;
  padding-bottom: 10px;
  color: #1c1e52;
}

.FootwearV2_car-v2-left__1P9FO p,
.FootwearV2_why-use__3S6X2 p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  color: #7c7c7c;
  padding: 0;
}
.FootwearV2_why-tabs__2lz-A li button.FootwearV2_activate__HMGZ-,
.FootwearV2_why-tabs__2lz-A li button:hover {
  /* box-shadow: 0px 0px 20px #ff7700!important;  */
  border-left: 5px solid #ff7700;
}
.FootwearV2_car-v2-tabs__1cTU4 {
  position: relative;
}

.FootwearV2_why-section__ZkL0n {
  padding: 0 5%;
}
.FootwearV2_why-tabs__2lz-A li button {
  font-weight: 600 !important;
  font-size: 24px !important;
  border-radius: 5px !important;
  line-height: 45px !important;
  color: #1d293f !important;
  border-left: 5px solid #fff;
  background-color: #fff !important;
}
.FootwearV2_why-tabs__2lz-A img {
  float: left;
  margin-right: 0;
  width: 11%;
  vertical-align: middle;
  margin-top: 1%;
}
.FootwearV2_car-v2-left__1P9FO .FootwearV2_why-tabs__2lz-A span {
}
.FootwearV2_car-v2-left__1P9FO .FootwearV2_why-tabs__2lz-A p {
}
.FootwearV2_first-tab-content__s-65_ {
  text-align: right;
  margin-bottom: 5% !important;
}
.FootwearV2_why-text__m7HjO {
  width: 87%;
  float: right;
}
.FootwearV2_why-text__m7HjO h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #1d293f;
  margin: 0;
}
.FootwearV2_why-text__m7HjO p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #7c7c7c;
  margin: 0;
  display: flex;
}

.FootwearV2_scroll-tab__i-4NC {
}
.FootwearV2_why-tabs__2lz-A li button {
  background: #ffffff !important;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 25%) !important;
  border-radius: 10px !important;
  margin: 0 0px 10px !important;
  padding: 15px 10px 15px 5px;
  text-align: left;
}
.FootwearV2_why-tabs__2lz-A {
  padding: 0;
  list-style: none;
  margin: 0;
}
.FootwearV2_request-demo2___WXl6 img {
  height: 42px;
  margin-left: 10px;
}
.FootwearV2_right-sections__23k3E div {
}
.FootwearV2_right-sections__23k3E {
  position: relative;
}
.FootwearV2_why-tabs__2lz-A li {
  margin: 5px 0px !important;
}
.FootwearV2_first-tab-content__s-65_ img {
  width: 70%;
  height: auto;
  margin: auto;
  margin-top: 2%;
}
.FootwearV2_buttons-div__3HtLb {
  margin: 5% 0;
}
.FootwearV2_why-use__3S6X2{
  margin: 0;
  padding: 0;
}
.FootwearV2_custom-backgrounds__wUOjT {
  padding: 60px 0%;
}

.FootwearV2_title__2IEb9 {
  text-align: center;
}
.FootwearV2_title__2IEb9 h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 45px;
  margin-top: 0;
}
.FootwearV2_title__2IEb9 h1 span {
  border-bottom: 2px solid #fd8061;
  color: #1c1e52;
}

.FootwearV2_custom-image__KSkZV {
  padding-top: 4%;
  position: relative;
  text-align: center;
}
.FootwearV2_custom-image__KSkZV img {
  width: 100%;
  position: relative;
  height: 595px;
}
.FootwearV2_custom-links__2gzo- {
}
.FootwearV2_custom-backgrounds__wUOjT .FootwearV2_container-fluid__1IcdR {
  width: 100% !important;
}

.FootwearV2_custom-links__2gzo- ul {
  display: block;
  list-style: none;
  padding: 0;
  width: 65%;
  margin: 0 0 0 auto;
}
.FootwearV2_ecom-links__3embr {
  float: left;
}
.FootwearV2_custom-links__2gzo- ul li {
  padding: 10px 10px 0;
  width: 100%;
  text-align: center;
}

.FootwearV2_custom-links__2gzo- ul li button {
  background: #fff;
  padding: 3px;
  border: 2px solid #fff;
  position: relative;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
}
.FootwearV2_custom-links__2gzo- ul li button.FootwearV2_active__3LIEj {
  border: 2px solid #fd8061;
}
.FootwearV2_custom-links__2gzo- ul li button img {
  width: 100%;
  height: 95px;
}
.FootwearV2_abs-img__1FuNL {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 40% !important;
  height: auto !important;
}
.FootwearV2_frame-img__Os57S {
  position: absolute !important;
  left: 10px !important;
  right: 15px !important;
  width: 97.5% !important;
  margin: 0 auto;
}

.FootwearV2_left-arrow__23Fgj {
  width: 17%;
  float: right;
  height: 60%;
  /* margin-top: 20%; */
  position: relative;
  top: 18%;
}
.FootwearV2_btm-arrow__2iBVt {
  position: relative;
  width: 70% !important;
  margin: 4% auto;
  height: auto !important;
}

.FootwearV2_gif-area__3emFF {
  padding: 0px 0 60px;
}
.FootwearV2_no-border__3cj2s {
  border: none !important;
}

.FootwearV2_mac-pro-image__1GgSX {
  text-align: center;
}
.FootwearV2_mac-pro-image__1GgSX img {
  margin-bottom: 50px;
  width: 100%;
}

.FootwearV2_graph-area__mG4go {
  padding: 60px 0px;
}
.FootwearV2_graph-area__mG4go .FootwearV2_title__2IEb9 {
}
.FootwearV2_graph-area__mG4go .FootwearV2_title__2IEb9 h1 {
  margin-bottom: 10px;
}
.FootwearV2_graph-image__621wy {
  padding: 0 3%;
  text-align: center;
}
.FootwearV2_graph-image__621wy img {
  width: 100%;
  margin-bottom: 7%;
}

.FootwearV2_calendly-area__9rL5o {
  padding: 60px 0px 8%;
}

.FootwearV2_cars-demo__3sm7i {
  height: 450px;
  text-align: center;
}
.FootwearV2_cars-demo__3sm7i p {
  margin-top: 30px;
}
.FootwearV2_cars-demo__3sm7i iframe {
  height: 100%;
}
.FootwearV2_cars-video__1juOS {
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  height: 450px;
  background: #fff;
}
.FootwearV2_cars-calender__mXq6N {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px;
}
.FootwearV2_cars-calender__mXq6N h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.FootwearV2_calendly-inline-widget__3ygbH {
  width: 100%;
}
.FootwearV2_main__20XKO {
  padding: 50px 0px;
}
.FootwearV2_book-demo__1VXHD {
  margin-bottom: 30px;
}
.FootwearV2_book-demo__1VXHD h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  letter-spacing: 0.2px;
  color: #5d5d6d;
  margin-bottom: 15px;
  padding-left: 5%;
}
.FootwearV2_book-demo__1VXHD h1 span {
  color: #ff7700;
}
.FootwearV2_book-demo__1VXHD p span {
  color: #ff7700;
}
.FootwearV2_book-demo__1VXHD p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}
.FootwearV2_book-demo__1VXHD img {
  float: left;
}
.FootwearV2_cars-calender__mXq6N {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px 10px 20px;
  height: 100%;
  overflow: hidden;
  background: #fff;
}
.FootwearV2_cars-calender__mXq6N h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.FootwearV2_calendly-inline-widget__3ygbH {
  height: 100% !important;
}

.FootwearV2_ticker-headline__ASc9e {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 15px 0;
  margin: 0;
  font-size: 18px;
}

.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 {
  height: 100%;
  width: auto;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi {
  width: auto;
  padding-right: 50px;
  transition: 0.6s ease-in-out top;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi {
    transition: 0.6s ease-in-out;
  }
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi.FootwearV2_next__1uRuo,
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi.FootwearV2_active__3LIEj.FootwearV2_right__1Q8QP {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    top: 0;
  }
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi.FootwearV2_prev__3C9Dv,
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi.FootwearV2_active__3LIEj.FootwearV2_left__2OOda {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    top: 0;
  }
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi.FootwearV2_next__1uRuo.FootwearV2_left__2OOda,
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi.FootwearV2_prev__3C9Dv.FootwearV2_right__1Q8QP,
  .FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_item__1GhSi.FootwearV2_active__3LIEj {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    top: 0;
  }
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_active__3LIEj,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_next__1uRuo,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_prev__3C9Dv {
  display: block;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_active__3LIEj {
  top: 0;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_next__1uRuo,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_prev__3C9Dv {
  position: absolute;
  top: 0;
  width: 100%;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_next__1uRuo {
  top: 100%;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_prev__3C9Dv {
  top: -100%;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_next__1uRuo.FootwearV2_left__2OOda,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_prev__3C9Dv.FootwearV2_right__1Q8QP {
  top: 0;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_active__3LIEj.FootwearV2_left__2OOda {
  top: -100%;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-inner__34jF6 > .FootwearV2_active__3LIEj.FootwearV2_right__1Q8QP {
  top: 100%;
}

.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC {
  left: auto;
  width: 50px;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC.FootwearV2_up__3gQds {
  top: 0;
  right: 0;
  bottom: 50%;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC.FootwearV2_down__2IOQk {
  top: 50%;
  right: 0;
  bottom: 0;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-prev__2S0ri,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-next__2oeD8,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_glyphicon-chevron-up__gDCnC,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_glyphicon-chevron-down__1y22R {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-prev__2S0ri,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_glyphicon-chevron-up__gDCnC {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-next__2oeD8,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_glyphicon-chevron-down__1y22R {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-up__3Dj-G,
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-down__1y-hS {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-prev__2S0ri:before {
  content: '‹';
}
.FootwearV2_carousel__hvbiD.FootwearV2_vertical__309Cy .FootwearV2_carousel-control__2FRFC .FootwearV2_icon-next__2oeD8:before {
  content: '›';
}

.FootwearV2_slide-images__3Dhs- .FootwearV2_item__1GhSi img {
  width: 100%;
  height: 100vh;
}

.FootwearV2_calendly-area__9rL5o .FootwearV2_title__2IEb9 {
  margin-bottom: 25px;
}
.FootwearV2_calendly-area__9rL5o .FootwearV2_title__2IEb9 h1 {
  margin-bottom: 0;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 {
  text-align: left;
  padding: 10% 5% 0;
}
.FootwearV2_calendly-area__9rL5o {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.FootwearV2_mob-area__3_fEV {
  padding-top: 12%;
}
.FootwearV2_custom-backgrounds__wUOjT .FootwearV2_title__2IEb9 h1 {
  margin-bottom: 10px;
}
.FootwearV2_custom-backgrounds__wUOjT .FootwearV2_title__2IEb9 p {
  margin-bottom: 30px;
}
.FootwearV2_calendly-area__9rL5o .FootwearV2_request-demo2___WXl6 img {
  margin: 10px 0;
}

.FootwearV2_mob-image__35TrR {
}
.FootwearV2_mob-image__35TrR img {
  width: 100%;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 p {
  margin-bottom: 30px;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 p img {
  margin-top: 10px;
  width: 140px;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 .FootwearV2_request-demo2___WXl6 {
  /* display: block; */
}

.FootwearV2_exterior-tabs__2RW69 ul li.FootwearV2_selected__2xoth {
  background: #fff;
  color: black;
  border-radius: 0;
  border: 0;
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.FootwearV2_exterior-tabs__2RW69 ul li button {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background: transparent;
  color: #444444;
  padding: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 0 !important;
}
.FootwearV2_exterior-tabs__2RW69 ul li button.FootwearV2_activ__3X4Xp,
.FootwearV2_exterior-tabs__2RW69 ul li button:hover,
.FootwearV2_exterior-tabs__2RW69 ul li button.FootwearV2_virtual__1D9pq {
  color: #ff7700 !important;
  border-bottom: 1px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.FootwearV2_exterior-tabs__2RW69 ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 0;
}
.FootwearV2_exterior-tabs__2RW69 ul li {
  padding: 3px;
  margin: 15px 20%;
  background: transparent;
  list-style: none;
  display: inline-block;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  box-shadow: 0px 2px 3px 0px #ddd;
}
.FootwearV2_slates-tabs__mlPmh li {
  padding: 5px 10px !important;
  margin: 10px !important;
}
.FootwearV2_custom-links__2gzo- ul li {
  padding: 0px 5px 0;
  margin-bottom: 5px;
}

.FootwearV2_custom-backgrounds__wUOjT {
  padding: 75px 0px;
}
/* Virtual section  */
.FootwearV2_virtual-images__2E3sf {
}
.FootwearV2_virtual-images__2E3sf img {
  width: 100%;
}

.FootwearV2_slates-tabs__mlPmh li.FootwearV2_selected__2xoth {
  border-top: 0px !important;
}

.FootwearV2_v-image__38E9N {
  padding: 0 15px !important;
}
.FootwearV2_tab-middle__2d5aX {
  padding-left: 0;
}
.FootwearV2_get-apple-app__12lx9 .FootwearV2_applestore__3yfOL{
  margin-left: 15px!important;
}









/* Responsive Media Query */


@media screen and (max-width: 767px) and (min-width: 361px) {
  .FootwearV2_car-v2-left__1P9FO p {
    text-align: left!important;
}
.FootwearV2_first-tab-content__s-65_ img {
  width: 75%;
    margin: 20px auto ;
    padding-right: 0;
}
.FootwearV2_car-v2-left__1P9FO {
  padding: 0 15px;
  order: 2;
}
.FootwearV2_why-section__ZkL0n {
  padding: 0;
}
.FootwearV2_exterior-tabs__2RW69 ul {
  display: block;
  padding: 5px 1% !important;
}
.FootwearV2_retail-graph__2DXNm{
  width: 49%!important;
  display: inline-block!important;
}
.FootwearV2_mob-image__35TrR img { 
  padding: 15% 0 0;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 {
  text-align: left;
  padding: 1% 2%;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 p {
  margin-bottom: 20px;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 p img {
  margin-top: 0;
  height: auto;
  margin-left: 10px;
}
.FootwearV2_app-pitch__Te-Ws p img{
  width: 105px!important;
}
.FootwearV2_calendly-area__9rL5o {
  padding: 60px 0px 5%;
}

}
@media screen and (max-width: 360px){
 
.FootwearV2_first-tab-content__s-65_ img {
  width: 70%;
  margin: 3% auto 5%;
}
.FootwearV2_car-v2-left__1P9FO{
  order: 2;
  padding: 0;
}

.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 {
  text-align: left;
  padding: 10% 2%;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 p img {
  margin-top: 0;
  /* margin-left: 15px; */
  width: auto;
}
.FootwearV2_calendly-area__9rL5o {
  padding: 60px 0px 15%;
  margin-bottom: 0px;
}
}

@media screen and (max-width: 800px) and (min-width: 200px) {
  .FootwearV2_title-area__3dLg3 h1 {
    display: none;
  }
  .FootwearV2_mac-pro-image__1GgSX img {
    width: 100%;
    margin-bottom: 20px;
  }

  .FootwearV2_request-demo2___WXl6 img {
    height: 40px;
    width: 130px;
  }
  .FootwearV2_abs-img__1FuNL {
    top: 33%;
    width: 30% !important;
    left: 35%;
  }
  .FootwearV2_graph-image__621wy img {
    width: 100%;
  }
  .FootwearV2_graph-area__mG4go .FootwearV2_title__2IEb9 {
    margin-bottom: 0px;
    text-align: center;
    padding-top: 0%;
  }
  .FootwearV2_title__2IEb9 h1 {
    font-size: 18px;
    line-height: 30px;

    margin-bottom: 20px;
  }
  .FootwearV2_request-demo__2y8w3 {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    line-height: normal;
    line-height: initial;
    height: 40px;
    width: 130px;
    margin: 0 auto;
  }
  .FootwearV2_graph-area__mG4go {
    padding: 0 0;
  }

  .FootwearV2_car-v2-left__1P9FO .FootwearV2_why-tabs__2lz-A span {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_car-v2-left__1P9FO .FootwearV2_why-tabs__2lz-A p {
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left!important;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_why-tabs__2lz-A li button {
    padding: 10px;
  }
  .FootwearV2_why-tabs__2lz-A img {
    width: 15%;
    margin-top: 10px;
  }
  .FootwearV2_why-tabs__2lz-A li + li button img {
    margin-top: 7%;
  }
  .FootwearV2_car-v2-left__1P9FO p {
    text-align: center;
  }
  .FootwearV2_car-v2-tabs__1cTU4 {
    padding: 0;
    margin-bottom: 20px;
  }
  .FootwearV2_title-area__3dLg3 {
    margin: 0;
  }
  
  .FootwearV2_why-use__3S6X2 h1 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }
  .FootwearV2_right-sections__23k3E div {
    margin: 0;
    text-align: center;
  }
  .FootwearV2_car-v2-left__1P9FO p,
  .FootwearV2_why-use__3S6X2 p {
    font-size: 12px;
    line-height: inherit;
    margin-bottom: 15px;
    margin-bottom: 15px;
    text-align: center!important;
  }

  
  .FootwearV2_gif-area__3emFF {
    padding: 0px 0 40px;
  }
  .FootwearV2_banner-slider__gajOn {
    margin-bottom: 20px;
    margin-top: 55px;
  }
  .FootwearV2_why-text__m7HjO {
    width: 80%;
    float: right;
  }
  .FootwearV2_why-text__m7HjO h6 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .FootwearV2_graph-image__621wy {
    padding: 0px 20px;
  }
  .FootwearV2_mac-pro-image__1GgSX {
    padding: 0 25px;
  }

  .FootwearV2_cars-demo__3sm7i {
    height: auto;
    text-align: center;
    margin-top: 30px;
  }
  .FootwearV2_title__2IEb9 h6 {
    font-size: 14px;
    margin: 0;
  }
  .FootwearV2_cars-video__1juOS {
    height: auto;
  } 
  .FootwearV2_scroll-tab__i-4NC .FootwearV2_why-use__3S6X2 {
    padding: 0;
    margin: 0;
  }

  .FootwearV2_graph-image__621wy img {
    padding: 0;
  }
  .FootwearV2_mob-area__3_fEV {
    padding-top: 5%;
  }
  .FootwearV2_mob-image__35TrR {
    order: 2;
  }
  .FootwearV2_calendly-area__9rL5o .FootwearV2_request-demo2___WXl6 img {
    margin: 0;
  }
  
  .FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 .FootwearV2_request-demo2___WXl6 {
    display: inline-block;
  }
  
  .FootwearV2_applestore__3yfOL img{
    margin-top: 20px;
  }
  .FootwearV2_get-apple-app__12lx9{
    margin: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .FootwearV2_custom-request__1JgxZ {
    order: 4;
    margin-bottom: 20px;
  }
  .FootwearV2_custom-links__2gzo- {
    order: 2;
  }
  .FootwearV2_custom-image__KSkZV img {
    height: 200px;
  }
  .FootwearV2_custom-image__KSkZV {
    padding: 0;
    height: -webkit-max-content;
    height: max-content;
  }
  .FootwearV2_custom-links__2gzo- ul {
    display: flex;
    width: 100%;
  }
  .FootwearV2_custom-links__2gzo- ul li button img {
    width: 100%;
    height: auto;
  }
  .FootwearV2_custom-links__2gzo- ul li button {
  }

  .FootwearV2_custom-links__2gzo- ul li {
    padding: 10px 5px 0;
  }
  .FootwearV2_custom-backgrounds__wUOjT {
    padding: 40px 0px 20px;
  }
  .FootwearV2_left-arrow__23Fgj {
    width: auto;
    float: unset;
    height: 70%;
    position: absolute;
    top: 15%;
  }
  .FootwearV2_frame-img__Os57S {
    left: 0px !important;
    right: auto;
    width: 100% !important;
    margin: 0 auto;
  }
  .FootwearV2_arrows__3Iy2A {
    text-align: center;
  }
  .FootwearV2_btm-arrow__2iBVt {
    margin: 4% auto 0;
  }
 
  .FootwearV2_exterior-tabs__2RW69 ul li {
    margin: 5px 6px 0 !important;
    font-size: 12px;
    padding: 2px !important;
  }
  .FootwearV2_tab-middle__2d5aX {
    padding: 0 15px;
  }
  .FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 h1 {
    text-align: center;
  }
  .FootwearV2_custom-backgrounds__wUOjT .FootwearV2_title__2IEb9 p { 
    font-weight: 400;
}
.FootwearV2_exterior-tabs__2RW69 ul {
  display: flex;
  padding: 5px 10% !important;
}
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .FootwearV2_graph-area__mG4go {
    padding: 0 0 60px !important;
  }
  .FootwearV2_first-tab-content__s-65_ img {
    width: 100% !important;
    margin: 0 auto;
    margin-top: 30% !important;
  }
  .FootwearV2_car-v2-left__1P9FO {
    order: 1 !important;
  }
  .FootwearV2_right-sections__23k3E {
    order: 2;
  }
  .FootwearV2_graph-area__mG4go .FootwearV2_title__2IEb9 {
    order: 1 !important;
    padding-top: 8%;
    margin: 0;
  }
  .FootwearV2_graph-image__621wy {
    order: 2;
  }
  .FootwearV2_first-tab-content__s-65_ img {
    width: 85%;
    margin-top: 10%!important;
  }
  .FootwearV2_cars-video__1juOS {
    height: 350px !important;
  }
  .FootwearV2_cars-demo__3sm7i {
    height: 350px !important;
    margin-top: 0 !important;
  }
  .FootwearV2_calendly-area__9rL5o {
    padding: 60px 0px 12% !important;
    margin-bottom: 0px;
  }
  .FootwearV2_custom-links__2gzo- {
    order: initial !important;
  }
  .FootwearV2_custom-links__2gzo- ul li button img {
    width: 100%;
    height: auto;
  }
  .FootwearV2_custom-links__2gzo- ul {
    width: 80%;
  }
  .FootwearV2_custom-image__KSkZV img {
    height: 270px;
  }
  .FootwearV2_left-arrow__23Fgj {
    width: 15%;
    height: 50%;
    top: 15%;
  }
  .FootwearV2_frame-img__Os57S {
    width: 95% !important;
    margin: 0 auto;
  }
  .FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 {
    padding: 10% 0% 0% !important;
  }
  .FootwearV2_mob-image__35TrR img {
    margin-top: 10%;
  }
  .FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 p { 
    text-align: left;
}
.FootwearV2_car-v2-left__1P9FO p, .FootwearV2_why-use__3S6X2 p { 
  text-align: left;
}
.FootwearV2_mob-area__3_fEV .FootwearV2_why-use__3S6X2 p img {
  margin-top: 0px; 
 width: auto;
  margin-left: 10px;
}
}

@media screen and (max-width: 1200px) and (min-width: 901px) {
  .FootwearV2_why-text__m7HjO p {
    font-size: 12px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_why-text__m7HjO h6 {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_first-tab-content__s-65_ img {
    width: 75%;
  }
  .FootwearV2_custom-links__2gzo- ul li {
    padding: 30px 20px 0;
  }
  .FootwearV2_graph-area__mG4go {
    padding: 0 0 50px;
  }
  .FootwearV2_custom-links__2gzo- ul li button img {
    width: 100%;
    height: 45px;
  }
  .FootwearV2_custom-image__KSkZV img {
    height: 420px;
  }
  .FootwearV2_custom-links__2gzo- ul {
    width: 75%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .FootwearV2_custom-image__KSkZV img {
    width: 100%;
    position: relative;
    height: 505px;
  }
  .FootwearV2_custom-links__2gzo- ul li button img {
    width: 100%;
    height: 80px;
  }
}

 

.active-button{
    background: #FFFFFF;
    border: 2px solid #0084FE;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100px;
    height: 95px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    border: unset;
    padding: 0px;
    margin: 0px;
}
.inactive-button{
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100px;
    height: 95px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    border: unset;
    padding: 0px;
    margin: 0px;
}  
.new-bg-imges{
    height: 220px;
    overflow: auto;
}
.car-step-text{
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}
.car-step-text-fixed{
    color: #0579DF;
    position: absolute;
    top: 10px;
    left: 40px;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
}
.angle-seclection-sec{
    border: 1px solid #A8A8A880;
    border-radius: 20px;
    height: 120px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}
.angle-seclection-sec  p {
    padding-top:10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-family: 'Roboto';
    color: black;
    font-weight: normal;
}
.angle-seclection-sec button{
    border: unset;
    background: transparent !important;
}
.right-top-section{
    display: grid;
    width: auto;
    grid-template-columns: 50% 48%;
    grid-gap: 10px;
}
.delarship-logo-sec{
    border: 1px solid #A8A8A880;
    border-radius: 20px;
    height: 120px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px !important;
}
.delarship-logo-sec button{
    height: 48px;
    color: white;
}
.angle-seclection-sec button:focus {
    border: unset !important;
    outline: none;
}
.dealership-text-fixed{
    color: #0579DF;
    position: absolute;
    top: 10px;
    left: 350px;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
}
.high-opecity{
    opacity: 1;
}
.low-opecity{
    opacity: 0.1;
}
.white_bg {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    margin-top: 5px;
}
.cat-img-cont button{
    /* width: 60px; */
    border: unset;
    background: transparent;
}

@media(max-width:800px){
   .new-bg-imges{
       display: grid !important;
       grid-template-columns: 33% 33% 33% !important;
   }
   .active-button{
       width: 100px;
       height: 90px;
   }
   .inactive-button{
       width: 100px;
       height: 90px;
   }
   .small-screen-buttons{
       justify-content: space-between !important;
       padding: 20px;
       height: 120px;
   }
   .right-top-section{
        display: grid;
        grid-template-columns: 100%;
    }
    .delarship-logo-sec{
        margin-top:20px
    }
    .dealership-text-fixed{
        color: #0579DF;
        position: absolute;
        top: 160px;
        left:30px;
        background: white;
        padding-left: 5px;
        padding-right: 5px;
    }
}
.CarsScreenTwo_drop-box-text__1L_in {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(44, 40, 40, 0.7);
    top: 0;
}

.CarsScreenTwo_slider-button__36MqL {
    display: grid;
    grid-template-columns: 100%;
    font-family: Arial, Helvetica, sans-serif;
    grid-gap: 23px;
    padding: 10px 26px;
}

.CarsScreenTwo_toggle1__1JrUA {
    position: relative;
    color: #f39e53;
    font-size: 13px;
}

.CarsScreenTwo_toggle1__1JrUA input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.CarsScreenTwo_greyscale__2U_Yt {
    position: absolute;
    width: 100%;
}

.CarsScreenTwo_slider1__3Llue {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 200px;
    box-shadow: 0px 4px 4px rgb(255, 255, 255);
    border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
}

.CarsScreenTwo_slider1__3Llue::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top: 3px
}

input:checked+.CarsScreenTwo_slider1__3Llue {
    background-color: #78f353;
}

input:checked+.CarsScreenTwo_slider1__3Llue::before {
    -webkit-transform: translateX(21.5px);
            transform: translateX(21.5px);
}

.CarsScreenTwo_drop-box-text__1L_in div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
}

.CarsScreenTwo_screen-two-main-wrapper__3RE2H {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
    margin: 100px 50px;
}

.CarsScreenTwo_blured__FX4Jy {
    -webkit-filter: blur(4px);
}

.CarsScreenTwo_left-section-with-image__1Kn91 {
    width: 780.57px;
    min-height: 520px;
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
    border-radius: 10px;
    padding: 7.87px 21.87px 10px;
}

.CarsScreenTwo_left-section-with-image-2__2mQFt {
    width: 100%;
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
    border-radius: 10px;
    padding: 7.87px 21.87px 10px;
}

.CarsScreenTwo_blured__FX4Jy {
    -webkit-filter: blur(4px);
}

.CarsScreenTwo_angle__1VZXZ {}

.CarsScreenTwo_right-top-credits__2T9NB {
    text-align: right;
    padding: 0;
    margin-bottom: 10px;
}

.CarsScreenTwo_right-top-credits__2T9NB button {
    padding: 5px 10px;
    width: auto;
    background: linear-gradient( 180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient( 180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #ff7700;
    background-blend-mode: soft-light, normal, normal;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
    border-radius: 32px;
}

.CarsScreenTwo_right-top-credits__2T9NB img {
    width: 25px !important;
}

.CarsScreenTwo_add-credit__1ln7k {
    color: white !important;
}

.CarsScreenTwo_add-credit__1ln7k img {}

.CarsScreenTwo_on-off-row__2TbxE {
    padding: 15px 10px 0;
}

.CarsScreenTwo_angle__1VZXZ p {
    margin: 0;
    color: #ff7700;
    font-size: 17px;
}

.CarsScreenTwo_left-section-with-image__1Kn91 {
    width: 780.57px;
    min-height: 520px;
    background: #ffffff;
    /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
    border-radius: 10px;
    padding: 21.87px 38.87px 10px;
}

.CarsScreenTwo_with-image-inner__2X0JT {
    width: 100%;
    height: 90%;
    width: 780.57px;
    height: 441px;
    border: 0.6px solid #77797b;
    cursor: pointer;
    /* object-fit:fill; */
}

.CarsScreenTwo_with-image-inner__2X0JT {
    width: 100%;
    height: 90%;
    width: 705.57px;
    height: 397px;
    border: 0.6px solid #77797b;
    cursor: pointer;
    /* object-fit:fill; */
    /* object-fit: contain; */
    position: relative;
    overflow: hidden;
}

.CarsScreenTwo_with-image-inner__2X0JT>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}

.CarsScreenTwo_resizable__2bFcN {
    width: 120px;
    /* border-radius: 0.75rem;
  padding: 20px;
  margin: 1rem;
  background-color: #29e;
  color: white;
  font-size: 20px;
  font-family: sans-serif; */
    overflow: hidden;
    touch-action: none;
    /* This makes things *much* easier */
    object-fit: fill !important;
    box-sizing: border-box;
    position: absolute;
    opacity: 0.5;
    border-bottom: 10px solid #ff7700;
    z-index: 2;
}

.CarsScreenTwo_drag-button__3hFul {
    width: 50px !important;
    height: auto;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    /* overflow: hidden; */
    margin-top: -27px;
}

.CarsScreenTwo_with-image-inner-2__1xays {
    width: 100%;
    /* height: 90%; */
    /* width: 783.96px; */
    /* height: 56% ; */
    border: 0.6px solid #77797b;
    cursor: pointer;
    /* object-fit:fill; */
}

.CarsScreenTwo_btn-sec__3K9rm button {
    width: 120px;
    height: 44px;
    border-radius: 5px;
}

.CarsScreenTwo_btn-sec-2__2_9Kf button {
    width: 120px;
    height: 44px;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #ff7700 !important;
    text-align: center !important;
    color: #ff7700 !important;
}

.CarsScreenTwo_btn-sec__3K9rm {
    text-align: center;
    padding-top: 10px;
    /* border-top: 1px solid #ebeaea;
  margin-top: 15px; */
}

.CarsScreenTwo_btn-sec-2__2_9Kf {
    text-align: center;
    padding-top: 20px;
    margin-top: 15px;
}

.CarsScreenTwo_checkbox-section__9tBFb {
    text-align: center;
    /* padding-top: 20px;
  border-top: 1px solid #ebeaea;
  margin-top: 15px; */
}

.CarsScreenTwo_checkbox-section__9tBFb label {
    font-size: 14px;
    color: #232121;
    font-weight: 600;
    font-family: poppins;
}

.CarsScreenTwo_with-image-inner-2__1xays img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CarsScreenTwo_original_image__lm1i3 {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: center;
}

.CarsScreenTwo_toggle-section__27oxZ {
    margin-top: 15px !important;
    justify-content: center;
}


/* .toggle-section button{
  margin: 0% 1% 0 15%;
  padding: 5px 15px;
  font-size: 14px;
} */

.CarsScreenTwo_right-section-with-image__2c9RS {
    /* height: 520px; */
    padding: 0 0 24.6px;
    /* margin-left: 40px; */
    background: #ffffff;
    /* box-shadow: 0px 0px 21px rgb(119 121 123 / 50%); */
    border-radius: 0px;
    width: 100%;
    border-left: 1px solid #ebeaea;
}

.CarsScreenTwo_sample-button__2OZBe {
    font-size: 16px !important;
    background: none !important;
    color: black !important;
    border: 3px solid #ff7700 !important;
    box-shadow: 0px 0px 21px rgb(119 121 123 / 50%) !important;
    margin-top: -20px;
    margin-bottom: 10px;
}

.CarsScreenTwo_sample-button__2OZBe:hover {
    color: black;
}

.CarsScreenTwo_choose-angle__9nwAX {
    position: absolute;
    width: 308.95px;
    height: 115.27px;
    background: #ffffff;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    padding: 18.34px 29.59px 13.98px 29.59px;
    display: flex;
    grid-gap: 31.88px !important;
    border-bottom: none;
}

.CarsScreenTwo_angle-selected__112UN {
    position: absolute;
    width: 308.95px;
    height: 115.27px;
    background: #ffffff;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    padding: 18.34px 29.59px 13.98px 29.59px;
    display: flex;
    grid-gap: 31.88px !important;
    /* border-bottom: none; */
}

.CarsScreenTwo_choose-angle__9nwAX,
.CarsScreenTwo_angle-selected__112UN p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #77797b;
    margin-top: 5px;
}

.CarsScreenTwo_choose-angle__9nwAX p:hover,
.CarsScreenTwo_angle-selected__112UN p:hover {
    color: #77797b;
}

.CarsScreenTwo_active__3yqZK {
    background-color: transparent;
    border: unset;
    width: 62px;
    height: 62px;
    padding: 0px;
    opacity: 1;
    border: unset !important;
}

.CarsScreenTwo_inactive__1Zy9_ {
    background-color: transparent;
    border: unset;
    width: 62px;
    height: 62px;
    padding: 0px;
    opacity: 0.3;
}

button:focus {
    /* border:unset !important; */
    outline: none !important;
}

.CarsScreenTwo_choose-angle__9nwAX img,
.CarsScreenTwo_angle-selected__112UN img {
    width: 100%;
    height: 45px;
    object-fit: contain;
}

.CarsScreenTwo_choose-angle-text__TuQtT {
    position: absolute;
    top: 220px;
    left: 803.81px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
    z-index: 100;
    display: flex;
    background-color: white;
    padding-left: 5px;
}

.CarsScreenTwo_choose-angle-text__TuQtT p {
    background-color: white;
    margin: 0px;
}

.CarsScreenTwo_tooltip-text__3eKRU {
    padding-bottom: 10px;
}

.CarsScreenTwo_tooltip-text__3eKRU p {
    background-color: #0579df;
    color: white !important;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}

.CarsScreenTwo_tooltip-show__1ViA0 {
    width: 268px !important;
    align-items: center !important;
    padding: 16px 24px 20px !important;
}

.CarsScreenTwo_upload-button__1MLkQ button {
    width: 140px;
    height: 40px;
    margin-left: 340px;
    margin-top: 30px;
    background: #0579df;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}

.CarsScreenTwo_upload-button__1MLkQ button:hover {
    color: #ffffff;
}

.CarsScreenTwo_btn__g1svT:focus {
    outline: none !important;
    box-shadow: none !important;
}

.CarsScreenTwo_right-mid-section__qPsZ9 {
    margin-top: 0px !important;
}

.CarsScreenTwo_logo-text-sec__64uLO {
    position: absolute;
    height: 21px;
    left: 803.91px;
    top: 333.76px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
    background-color: white;
    display: flex;
    padding: 0px 5px;
}

.CarsScreenTwo_righ-mid-setion-content__35cBb {
    display: flex;
    margin: 13px auto 10px 25px;
    text-align: center;
    opacity: 0.5;
    justify-content: space-around;
}

.CarsScreenTwo_white-bg__P5g0v {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.CarsScreenTwo_position-text__2BkQe {
    position: absolute;
    left: 1160.91px;
    top: 333.76px;
    background-color: white;
    padding: 0px 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
}

.CarsScreenTwo_angle-sec__2Cppn {
    background-color: #fbfbfb;
    border-bottom: 1px solid #ddd;
}

.CarsScreenTwo_right-section-background-section__Awk18 {
    width: 95%;
    /* height: 120px; */
    background: #ffffff;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 10px;
    /* overflow: auto;
    overflow-x: hidden; */
    margin: 0 auto;
}

.CarsScreenTwo_background-text__2Y5kA {
    position: absolute;
    top: 335px;
    left: 803.91px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
    background: white;
    padding: 0px 5px;
}

.CarsScreenTwo_background-text__2Y5kA p {
    margin-bottom: 0px;
}

.CarsScreenTwo_overlay_edit__BPnFu {
    position: relative !important;
}

.CarsScreenTwo_original_image__lm1i3 {
    position: relative !important;
    /* object-fit: contain !important; */
}

.CarsScreenTwo_edited_image__JqyEa {
    position: relative;
    object-fit: contain;
    width: 783.96px;
    height: 441px;
    border: 0.6px solid #77797b;
    cursor: pointer;
}

.CarsScreenTwo_car-text__1zQXb h1 {
    font-size: 30px;
}

.CarsScreenTwo_got-btn__3PFNM {
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 140px;
    height: 40px;
    margin: 20px 0;
}

.CarsScreenTwo_overlay-img__1O1sP {
    /* object-fit: scale-down !important; */
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black !important;
    z-index: 91;
    object-fit: contain !important;
    height: 60% !important;
    margin-top: 10%;
}

.CarsScreenTwo_lower-section-with-overlay__3v3ID {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%); */
    background-color: #fff;
}

.CarsScreenTwo_lower-section-with-overlay-2__-jtwn {
    display: grid;
    flex-direction: row;
    width: 100%;
    position: relative;
    grid-template-columns: 59% 40%;
}

.CarsScreenTwo_overlay-box__1b1sj {
    display: flex;
    flex-direction: row;
    /* border-bottom: 1px solid #ddd; */
    padding: 5px 0;
    /* border-top: 1px solid #ddd;  */
}

.CarsScreenTwo_backgrounds-grid__3rwtl {
    display: grid;
    grid-template-columns: 18.5% 18.5% 18.5% 18.5% 18.5%;
    grid-gap: 9px;
}

.CarsScreenTwo_backgrounds-grid-custom__2ETxm {
    display: grid;
    grid-template-columns: 18.5% 18.5% 18.5% 18.5% 18.5%;
    grid-gap: 7px;
}

.CarsScreenTwo_backgrounds-grid-below__3FUmw {
    display: grid;
    /* justify-content: center; */
    grid-template-columns: 50% 50%;
    grid-gap: 9px;
}

.CarsScreenTwo_below-button__2TMUA {
    background: unset !important;
    /* color: #FF7700; */
    border: 2px solid #ff7700 !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    margin-top: 2% !important;
    border-radius: 30px !important;
}

.CarsScreenTwo_background-list__1cmAK {
    padding: 0px 10px 15px;
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
}

.CarsScreenTwo_background-list-2__1vco- {
    padding: 0;
    border-top: 0px solid #ebeaea;
    margin-top: 0px;
}

.CarsScreenTwo_back-credit__2sFDb img {
    width: auto !important;
    height: auto !important;
    padding-left: 4px;
    border: 0 !important;
}

.CarsScreenTwo_upload-sec__3zjpo {
    padding: 35px 0;
}

.CarsScreenTwo_upload-sec__3zjpo button img {
    width: 23px;
    margin-right: 10px;
}

.CarsScreenTwo_active-background-sec__1oToB img {
    width: 100%;
    height: 46px;
    border-radius: 5px;
}

.CarsScreenTwo_bg-below-sec__3W3ZS {
    justify-content: center;
    margin: 0;
    padding: 5px 60px 0;
}

.CarsScreenTwo_inactive-background-sec__10-P7 img {
    width: 100%;
    height: 46px;
    cursor: pointer;
    border-radius: 5px;
    border: 0.4px solid #333;
}

.CarsScreenTwo_select-img__fOO4X {
    height: 65%;
    margin-left: 10px;
    border-radius: 2px;
}

.CarsScreenTwo_below-bg-apply__bF1hN {
    border-radius: 22px !important;
    background: unset;
    color: rgb(255, 119, 0) !important;
    border: 1px solid #ff7700;
    margin-top: 4%;
    padding: 0;
    width: 78px;
    height: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ff7700;
}

.CarsScreenTwo_below-bg-apply__bF1hN :hover {
    border-radius: 30px !important;
    background: unset;
    color: rgb(255, 119, 0) !important;
    border: 2px solid #ff7700;
    margin-top: 4%;
}

.CarsScreenTwo_bg-complete-sec__3VTMR {
    /* display: flex; */
    justify-content: center;
    /* flex-direction: column; */
    text-align: center;
    margin-top: 14px;
    border-top: 1px solid rgba(222, 222, 222, 0.85);
    padding-top: 5px;
}

.CarsScreenTwo_bg-complete-sec__3VTMR h4 {
    font-size: 12px;
    color: #232121;
    font-weight: 400;
}

.CarsScreenTwo_select-sec__NzS5- {
    /* display: flex; */
    justify-content: center;
    margin-top: 3%;
}

.CarsScreenTwo_select-sec__NzS5- span {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.CarsScreenTwo_active-background-sec-below__2yUr5 {
    padding: 5px 5px 0;
    cursor: pointer;
}

.CarsScreenTwo_active-background-sec-below__2yUr5 img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.CarsScreenTwo_inactive-background-sec-below__2HpLg img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.CarsScreenTwo_overlay_edit__BPnFu {
    position: relative !important;
}

.CarsScreenTwo_overlay-img__1O1sP {
    /* object-fit: scale-down !important; */
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black !important;
    z-index: 91;
    object-fit: contain !important;
    height: 60% !important;
    margin-top: 10%;
}

.CarsScreenTwo_lower-section-with-overlay__3v3ID {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.CarsScreenTwo_continue-btn__AkyXB {
    display: flex;
    justify-content: center;
    padding: 2%;
}

.CarsScreenTwo_background-sec__14yc2 {
    /* margin-top: 18px; */
}

.CarsScreenTwo_download-popup__AnCpU {
    border: 1px solid #ddd;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-top: 20px;
}

.CarsScreenTwo_download-popup__AnCpU button {
    border: 1px solid transparent;
    margin-bottom: 35px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 95px;
    text-align: left;
}

.CarsScreenTwo_cross-btn__2wsRK {
    text-align: right;
    padding: 0 0 10px;
}

.CarsScreenTwo_download-popup__AnCpU button:nth-of-type(2) {
    margin-bottom: 15px;
}

.CarsScreenTwo_download-popup__AnCpU ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
}

.CarsScreenTwo_download-popup__AnCpU ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
}

.CarsScreenTwo_download-popup__AnCpU p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
}

.CarsScreenTwo_download-popup__AnCpU p span {
    color: #ff7700;
}

.CarsScreenTwo_download-popup__AnCpU h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
}

.CarsScreenTwo_border-1__2tJJJ {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
}

.CarsScreenTwo_download-popup__AnCpU input {
    margin-right: 10%;
    border: 0;
    outline: 0;
}

.CarsScreenTwo_download-popup__AnCpU input:focus {
    border: 0;
    outline: 0;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
}

.CarsScreenTwo_download-btn-hd__1kGTo {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px;
}

.CarsScreenTwo_request-demo-1__1pUrZ {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 153px !important;
    text-align: center;
    padding: 10px 28px !important;
    font-size: 17px;
}

.CarsScreenTwo_img-overlay__2PgnQ {
    position: absolute;
    /* background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  ); */
    height: 100%;
    bottom: 0%;
    width: 100%;
    opacity: 1;
    transition: 0.5s ease;
}

.CarsScreenTwo_more-btn-overlay__F5ZTl {
    position: absolute;
    top: 25%;
    left: 1%;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    width: 90%;
    line-height: normal;
    line-height: initial;
}

.CarsScreenTwo_more-btn-overlay-2__KHwbK {
    position: absolute;
    top: 28%;
    left: 8%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.CarsScreenTwo_overlay-pos__19hT7 {
    position: relative;
}

.CarsScreenTwo_cross-img__1Lkmc {
    width: auto !important;
    cursor: pointer;
}

.CarsScreenTwo_request-demo-1__1pUrZ:hover {
    background-color: #ff7700 !important;
    border: 0 !important;
}

.CarsScreenTwo_download-popup__AnCpU p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 15px;
}

.CarsScreenTwo_download-popup__AnCpU button.CarsScreenTwo_activate__3CNQU,
.CarsScreenTwo_download-popup__AnCpU button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
}

.CarsScreenTwo_back-name__lLU9A {
    font-size: 10px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
    line-height: normal;
    line-height: initial;
}

.CarsScreenTwo_overlay-toggle__1TNlc {
    display: block;
    margin-top: 4%;
}

.CarsScreenTwo_active-toggle-btn__3BZK9 {}

.CarsScreenTwo_car-overlay-cat__2nF_U {
    padding: 0 0 0 10px;
}

.CarsScreenTwo_overlays-click-btn__27xhS {
    /* display: flex; */
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 5px;
    margin: 5px 10px 5px;
    border: 2px solid transparent;
}

.CarsScreenTwo_overlays-click-btn__27xhS span {
    color: #000;
}

.CarsScreenTwo_overlays-click-btn__27xhS:hover span {
    color: #ff7700;
}

.CarsScreenTwo_back-credit__2sFDb {
    text-align: end;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 0;
    margin-bottom: 0 !important;
}

.CarsScreenTwo_logo-disp__Wl9ON {
    width: 86px;
    background-color: #e0e0e0;
    height: 34px;
}

.CarsScreenTwo_logo-upload__1fBqK {
    width: 157px;
    height: 44px;
    border-radius: 5px;
}

.CarsScreenTwo_logo-sec__10VEL {
    padding: 0px 13px 23px;
    border-bottom: 1px solid #ebeaea;
    margin: 22px 0;
}

.CarsScreenTwo_logo-sec__10VEL span {
    color: orange;
}

.CarsScreenTwo_background-list__1cmAK h6 {
    color: #232121;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}

.CarsScreenTwo_overlays-click-btn__27xhS img {
    /* height: 120px; */
    width: 90%;
    object-fit: contain;
    padding: 0;
}

.CarsScreenTwo_overlay-toggle__1TNlc {
    display: flex;
    margin-top: 0%;
    padding: 0;
}

.CarsScreenTwo_credit-text__3BFx7 {
    font-size: 14px;
    font-family: poppins;
    color: #232121;
    text-align: center;
    /* display: flex;
  justify-content:flex-end; */
}

.CarsScreenTwo_credit-text__3BFx7 span {
    color: #ff7700;
}

.CarsScreenTwo_credit-text__3BFx7 img {
    margin-right: 10px;
    width: 40px;
}

.CarsScreenTwo_active-toggle-btn__3BZK9 {}


/* .overlays-click-btn {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  padding: 5px;
  margin-top: 10px;
} */

.CarsScreenTwo_car-try-btns__2RQFL {
    display: inline-block;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0px;
    padding: 5px 5px 0;
    width: 33%;
    margin: 0;
    text-align: center;
    font-size: 10px;
    height: 100%;
    width: 50%;
}

.CarsScreenTwo_car-active-btns__3ce3u {
    border-bottom: #ff7700;
}

.CarsScreenTwo_car-try-btns__2RQFL img {
    height: auto;
    width: 70%;
    object-fit: contain;
    padding: 0;
    margin: 0 auto;
    object-fit: contain;
    padding: 0;
    margin: 0 auto;
    /* float: left; */
    display: block;
}

.CarsScreenTwo_car-try-btns__2RQFL span {
    display: block;
    font-size: 14px;
    vertical-align: middle;
    width: 100%;
}

.CarsScreenTwo_all-overlays__2rA65 {
    display: flex;
    justify-content: center;
    padding: 0;
}

.CarsScreenTwo_car_overlay_cat__aaiiQ {
    display: flex;
    flex-direction: column;
}

.CarsScreenTwo_overlay-section__35LAP {
    padding: 0;
}

.CarsScreenTwo_overlay-toggle__1TNlc p {
    font-size: 14px;
    margin: 0;
}

.CarsScreenTwo_active__3yqZK {
    /* border:2px solid #FF7700!important; */
}

.CarsScreenTwo_overlay-car-btn__xwkKx {
    padding: 0px 5px;
    background-color: rgb(255, 255, 255);
    color: rgb(10, 10, 10);
    text-align: center;
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    box-shadow: 0 2px 5px 0 #ddd;
    margin: 15px 0 10px;
}

.CarsScreenTwo_overlays-click-btn__27xhS:hover {
    border: 2px solid #ff7700;
    color: #ff7700;
}

.CarsScreenTwo_overlay-toggle__1TNlc label {
    margin: 0;
}

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #fae6d2;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #f1923e;
}

.CarsScreenTwo_overlay-car-btn__xwkKx:hover,
.CarsScreenTwo_overlays-click-btn__27xhS:focus {
    border-bottom: 3px solid #ff7700;
    color: #ff7700;
}

.CarsScreenTwo_backgrounds__MTsSL {
    overflow-y: scroll;
    display: flex;
    flex-flow: row wrap;
    height: 150px;
    margin-left: auto;
    white-space: nowrap;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center !important;
    margin: 10px 0;
    padding: 4px;
}

.CarsScreenTwo_backgrounds__MTsSL li {
    width: 20%;
    padding: 0px 5px;
}

.CarsScreenTwo_backgrounds__MTsSL li button {
    width: 100%;
    display: block;
    padding: 0 !important;
    height: 60px !important;
}

.CarsScreenTwo_inactive-background-images__3Hqzb {
    padding: 0 10px 0 0 !important;
    margin: 0 0 !important;
    display: inline-block;
    width: 90px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.CarsScreenTwo_inactive-background-images__3Hqzb p.CarsScreenTwo_bg_img-texts__3ZipE,
.CarsScreenTwo_active-background-images__2usl3 p.CarsScreenTwo_bg_img-texts__3ZipE {
    font-size: 10px;
    width: 100%;
    margin: 0 auto !important;
    text-align: center !important;
    white-space: normal;
}

.CarsScreenTwo_bg_img-texts__3ZipE {
    font-size: 71px;
    margin-bottom: 10px !important;
    color: #ff7700;
    margin-left: 14px !important;
    /* width:  65px; */
}

.CarsScreenTwo_active-background-images__2usl3 {
    height: 70px !important;
    padding: 0 !important;
    margin: 0 10px 0 0px !important;
}

.CarsScreenTwo_active-background-images__2usl3 img {
    border: 2px solid black;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    margin: 5px 0 0 0px !important;
}

.CarsScreenTwo_credit-para__2zIa6 {
    position: relative;
    /*top: -18%; */
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: -18%;
}

.CarsScreenTwo_inactive-background-images__3Hqzb img,
.CarsScreenTwo_active-background-images__2usl3 img {
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
    height: 65px;
    width: 65px;
}

.CarsScreenTwo_image-credit__215Ph p {
    margin-top: 0px;
    width: 65px;
    height: 16.6px;
}

.CarsScreenTwo_middle__2uai7 {
    transition: 0.5s ease;
    opacity: 0;
    position: relative;
    top: -60%;
    left: -10%;
    text-align: center;
    cursor: pointer;
}

.CarsScreenTwo_text__2Xc3K {
    background: rgba(0, 0, 0, 0.7) !important;
    border-radius: 0px 0px 5px 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    color: #ffffff !important;
    text-align: center;
    z-index: 9999;
    padding-top: 5px;
    padding: 16px 32px;
}

.CarsScreenTwo_inactive-background-images__3Hqzb:hover img,
.CarsScreenTwo_active-background-images__2usl3:hover img {
    opacity: 0.6;
}

.CarsScreenTwo_inactive-background-images__3Hqzb:hover .CarsScreenTwo_middle__2uai7,
.CarsScreenTwo_active-background-images__2usl3:hover .CarsScreenTwo_middle__2uai7 {
    opacity: 1;
}

.CarsScreenTwo_right-bottom-section__3eWMq {
    padding: 18.94px 15px 0;
    text-align: center;
}

.CarsScreenTwo_right-bottom-section__3eWMq p:nth-child(1) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    color: #0579df !important;
}

.CarsScreenTwo_right-bottom-section__3eWMq p:nth-child(2) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #000000;
    text-align: center;
    margin: 0;
}

.CarsScreenTwo_right-bottom-section__3eWMq span {
    color: #0579df !important;
}

.CarsScreenTwo_separation-border__3MitP {
    position: absolute;
    width: 47.31px;
    height: 0px;
    left: 1029.53px;
    top: 660.72px;
    border: 0.4px solid #707070;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.CarsScreenTwo_bottom-apply-area__2g-ga {
    padding-top: 5px;
    text-align: left;
}

.CarsScreenTwo_overlay-vertical__2GWJ8 {
    padding: 0;
    height: 225px;
    overflow-y: scroll;
}

.CarsScreenTwo_bottom-apply-area__2g-ga button {
    /* position: absolute; */
    width: 128.85px;
    height: 40px;
    left: 1091.92px;
    top: 634.63px;
    background: #ff7700;
    border-radius: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}

button:hover {
    color: #ffffff;
}

.CarsScreenTwo_bottom-apply-area__2g-ga p:nth-child(2) {
    position: absolute;
    left: 1111.84px;
    top: 679.76px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 12px;
    color: #4f4444;
    text-align: center;
}

.CarsScreenTwo_bulk-pricing__2ymKG {
    position: absolute;
    width: 221px;
    height: 19px;
    left: 917.59px;
    top: 750.81px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #77797b;
    text-decoration: none;
}

.CarsScreenTwo_bulk-pricing__2ymKG:hover {
    text-decoration: none;
    color: #77797b;
}

.CarsScreenTwo_bulk-pricing__2ymKG span {
    color: #0579df;
}

.CarsScreenTwo_spinner__1c4s7 {
    width: 80px;
    height: 80px;
    position: relative;
    left: 40%;
    top: 40%;
}

.CarsScreenTwo_dealership-logo-section__3WeTD {
    width: 100%;
    /* border: 1px solid grey;
  margin: 1px auto 20px; */
    border-radius: 10px;
}

.CarsScreenTwo_center-title__22ijG {
    text-align: center;
    margin-top: -10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #707070;
    display: block;
    width: 100%;
    margin-bottom: 0;
}

.CarsScreenTwo_center-title__22ijG strong {
    background: #fff;
    padding: 5px 10px;
}

.CarsScreenTwo_center-title2__3nVw_ {
    text-align: center;
    margin-top: -10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #707070;
    display: block;
    width: 100%;
    margin-bottom: 0;
    margin-bottom: -10px;
}

.CarsScreenTwo_center-title2__3nVw_ strong {
    background: #fff;
    padding: 5px 10px;
}

input[type='checkbox']:checked {
    background: #ddd;
}

.CarsScreenTwo_pricing-plan-area__27Si6 {
    border-right: 2px solid #707070a1;
}

.CarsScreenTwo_right-mid-section__qPsZ9 p.CarsScreenTwo_pricing-plan__X3zzW {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df !important;
    text-align: center;
    margin: 5px 0;
}

.CarsScreenTwo_popup__3jwql {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.CarsScreenTwo_popup_inner__31-Iz {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}

.CarsScreenTwo_wall-cross__2vPbV {
    position: absolute !important;
    right: -7%;
    top: -9%;
    margin: 0;
    cursor: pointer;
    height: 22px !important;
    width: 24% !important;
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px #ddd;
    border-radius: 80px;
    height: 25px;
    width: 25px;
    border: 0 !important;
}

.CarsScreenTwo_right-mid-section__qPsZ9 p.CarsScreenTwo_available-cre__CL2Pj {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
}

.CarsScreenTwo_image-box-area__311pV {
    height: 100%;
    padding: 0 10px;
    margin: 0 auto;
    justify-content: center;
}

.CarsScreenTwo_image-box-area__311pV video {
    width: 101.9%;
}


/* Animation */


/* @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
  }
  
  .animatedFadeInUp {
    opacity: 0;
  }
  
  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
   */

.CarsScreenTwo_box-text1__1Uw4J,
.CarsScreenTwo_box-text2__tO4mS,
.CarsScreenTwo_box-text3__3wQKZ,
.CarsScreenTwo_box-text4__1taKp,
.CarsScreenTwo_box-text5__1O2cY,
.CarsScreenTwo_box-text6__blwJP {
    padding: 0 10px;
    padding-top: 48%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    margin: 0;
    transition: 0.5s ease-in-out;
    /* -webkit-animation: fadeInOut 4s linear forwards;
    animation: fadeInOut 4s linear forwards; */
    text-align: left;
    width: 65%;
    height: 30rem;
}

.CarsScreenTwo_box-text1__1Uw4J span,
.CarsScreenTwo_box-text2__tO4mS span,
.CarsScreenTwo_box-text3__3wQKZ span,
.CarsScreenTwo_box-text4__1taKp span,
.CarsScreenTwo_box-text5__1O2cY span,
.CarsScreenTwo_box-text6__blwJP span {
    color: #ff7700;
}

.CarsScreenTwo_bg-right-top__3cDLz {
    display: flex;
    /* height: 21%; */
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 5px; */
    padding: 0 0 15px;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
}

.CarsScreenTwo_active-visual-selection__3EB2L {
    border-top: 2px solid #ff7700;
    color: #ff7700;
    background: #fff;
    padding: 10px;
}

.CarsScreenTwo_inactive-visual-selection__3P2-g {
    /* border-bottom: 2px solid #FF7700; */
    color: #000;
    padding: 10px;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 0px 0px 5px 5px;
}

.CarsScreenTwo_img-floor__1Ll8W {
    position: relative;
    width: 65px !important;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
    /* margin-left: 8%; */
    margin: 0 auto;
}

.CarsScreenTwo_right-top-selection__2jsiv {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0px;
    margin: 0px auto 7px;
    justify-content: center;
    text-align: center;
    /* border-top: 2px solid #e0e0e0; */
}

.CarsScreenTwo_img-floor__1Ll8W img {
    width: 100%;
    height: auto;
}

.CarsScreenTwo_backdrop__3V0hI {
    padding-top: 3%;
    padding-left: 2%;
    font-size: 100%;
    font-weight: 500;
    padding-bottom: 2%;
}

@-webkit-keyframes CarsScreenTwo_topFadeOut__3ceA3 {
    0% {
        position: absolute;
        top: -3rem;
        opacity: 0;
    }
    75% {
        position: absolute;
        top: 25%;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes CarsScreenTwo_topFadeOut__3ceA3 {
    0% {
        position: absolute;
        top: -3rem;
        opacity: 0;
    }
    75% {
        position: absolute;
        top: 25%;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes CarsScreenTwo_bottomFadeOut__1n6H2 {
    0% {
        bottom: -5rem;
        opacity: 0;
    }
    75% {
        bottom: 25%;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes CarsScreenTwo_bottomFadeOut__1n6H2 {
    0% {
        bottom: -5rem;
        opacity: 0;
    }
    75% {
        bottom: 25%;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* @keyframes topFadeOutVertical {
    0% {
      position: absolute;
      top: -3rem;
      opacity: 0;
    }
  
    75% {
      position: absolute;
      top: 45%;
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  } */


/* @keyframes rightFadeInOut {
    0% {
      position: absolute;
      right: -3rem;
      opacity: 0;
    }
  
    75% {
      position: absolute;
      right: 10rem;
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      right: 10rem;
    }
  } */

@-webkit-keyframes CarsScreenTwo_fadeInOut__1YYCT {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    100% {
        opacity: 0%;
    }
}

@keyframes CarsScreenTwo_fadeInOut__1YYCT {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    100% {
        opacity: 0%;
    }
}

.CarsScreenTwo_box-text1__1Uw4J {
    -webkit-animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
            animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
}

.CarsScreenTwo_box-text2__tO4mS {
    -webkit-animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
            animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
}

.CarsScreenTwo_box-text3__3wQKZ {
    -webkit-animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
            animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
}

.CarsScreenTwo_box-text4__1taKp {
    -webkit-animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
            animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
    -webkit-animation-delay: 10s;
            animation-delay: 10s;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

.CarsScreenTwo_box-text5__1O2cY {
    -webkit-animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
            animation-name: CarsScreenTwo_bottomFadeOut__1n6H2;
    -webkit-animation-delay: 13s;
            animation-delay: 13s;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

.CarsScreenTwo_box-img__1hGag {
    width: 35% !important;
    height: auto !important;
}

.CarsScreenTwo_bottom-contact-badge__1cAIz {
    background: #dee2e6;
    padding: 5px 15px;
    border-radius: 0px 0px 11px 10px;
    margin: 0px 50px;
    margin-bottom: 50px;
}

.CarsScreenTwo_bottom-contact-badge__1cAIz p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: -webkit-max-content;
    width: max-content;
    padding: 0px 15px;
    display: inline-block;
    margin: 0;
}

.CarsScreenTwo_bottom-contact-badge__1cAIz p span {
    color: #ff7700;
}

.CarsScreenTwo_bottom-contact-badge__1cAIz ul {
    list-style-type: none;
    display: inline-block;
    padding: 0;
    vertical-align: text-bottom;
    margin: 0;
}

.CarsScreenTwo_bottom-contact-badge__1cAIz ul li {
    display: inline-block;
    padding: 0 10px 0 0;
}


/* footer */

.CarsScreenTwo_footer__1-Sdr {
    background: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/light+CONTACT+10+1.png);
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 60px 0px 0px;
    color: #fff;
}

.CarsScreenTwo_footer-left-text__1B2On {}

.CarsScreenTwo_footer-left-text__1B2On h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 57px;
    margin-top: 18%;
    text-align: center;
}

.CarsScreenTwo_footer-left-text__1B2On h2 span {
    border-bottom: 2px solid #fff;
}

.CarsScreenTwo_footer-left-text__1B2On h2 span:after {
    content: '';
}

.CarsScreenTwo_footer-right-text__2i-7f {
    padding: 5% 15px 0;
}

.CarsScreenTwo_footer-right-text__2i-7f ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
}

.CarsScreenTwo_footer-right-text__2i-7f ul li {
    margin: 15px 20px;
    background: linear-gradient( 180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-radius: 50%;
    width: 85px;
    text-align: center;
    line-height: 80px;
}

.CarsScreenTwo_footer-right-text__2i-7f ul li img {
    padding: 15px;
}

.CarsScreenTwo_footer-right-text__2i-7f p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    font-weight: 400;
}


/* //footer */

@-webkit-keyframes CarsScreenTwo_fadeInUp__1E9ua {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes CarsScreenTwo_fadeInUp__1E9ua {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
                transform: none;
    }
}

.CarsScreenTwo_box-text__1OfSm {
    opacity: 0;
    -webkit-animation: CarsScreenTwo_fadeInUp__1E9ua 2s ease-in-out 0s forwards;
    animation: CarsScreenTwo_fadeInUp__1E9ua 2s ease-in-out 0s forwards;
}

.CarsScreenTwo_box-text__1OfSm {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.CarsScreenTwo_email__1mcPd {
    position: relative !important;
    top: 80px !important;
    left: 25% !important;
    z-index: 999 !important;
    height: 0px !important;
}

.CarsScreenTwo_email__1mcPd form {
    background: rgba(98, 98, 98, 0.7);
    max-width: 415px;
    border-radius: 16px;
    text-align: center;
    align-items: center;
    padding: 20px;
}

.CarsScreenTwo_email__1mcPd img {
    width: 100% !important;
}

.CarsScreenTwo_email__1mcPd label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.CarsScreenTwo_email__1mcPd input {
    height: 48px;
    margin-bottom: 40px;
}

.CarsScreenTwo_email__1mcPd button {
    width: 124px;
    height: 35px;
    left: 399px;
    top: 651px;
    background: #ffffff;
    border-radius: 9px;
    color: #616161;
}

.CarsScreenTwo_load-video__2XXTL {
    width: 100%;
    height: 461px;
}

.CarsScreenTwo_video-text-header__1VLGX h6 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.CarsScreenTwo_perfect-output__2h4MT {
    margin: 70px 0 50px;
    /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%);
  background-color: #fff; */
    background: #fff;
}

.CarsScreenTwo_top-bar__E7dlB {
    padding: 10px 0px;
    padding: 15px 0px 10px;
}

.CarsScreenTwo_video-sec__27ImV {
    margin: 100px 0 50px;
}

.CarsScreenTwo_heading__3rWYT h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1.5px;
    padding: 0 0 10px;
    color: #232121;
}

.CarsScreenTwo_heading__3rWYT h3 span {
    color: #ff7700;
    padding: 0 0 7px;
}

.CarsScreenTwo_heading__3rWYT h3 .CarsScreenTwo_border-1__2tJJJ {
    color: #232121;
    border-bottom: 2px solid #ff7700;
}

.CarsScreenTwo_perfect-output-images__3n2go {
    text-align: center;
    padding: 20px 15px 0;
}

.CarsScreenTwo_perfect-output-images__3n2go img {
    width: 100%;
    -webkit-filter: drop-shadow(-4px 4px 7px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(-4px 4px 7px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    cursor: pointer;
}

.CarsScreenTwo_perfect-output-images__3n2go p {
    text-align: center;
    margin-top: 10px;
    color: #232121;
}

.CarsScreenTwo_perfect-output-images__3n2go p span a {
    font-style: normal;
    color: #ff7700;
    margin-right: 20px;
    background: #fff;
    border: 1px solid #ff7700;
    box-sizing: border-box;
    border-radius: 20px;
}

.CarsScreenTwo_perfect-output-images__3n2go p a {
    background: #ff7700;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    padding: 5px 20px;
    border: 1px solid #ff7700;
    box-sizing: border-box;
    border-radius: 20px;
}

.CarsScreenTwo_bg-try__2K2wh {
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
    background-position: top right;
    background-repeat: no-repeat;
}

.CarsScreenTwo_floor-output-box__1OKUE {
    box-shadow: -1px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    background-color: #fff;
    padding: 30px 20px;
    margin-top: 20px;
}

.CarsScreenTwo_custom-container__1DCnW {
    max-width: 88%;
    margin: auto;
}

.CarsScreenTwo_try-again__30mnq h6 {
    text-align: center;
    font-size: 16px;
    color: #000;
    font-family: poppins;
    font-weight: 400;
}

.CarsScreenTwo_try-again__30mnq span {
    cursor: pointer;
    color: #ff7700;
}

.CarsScreenTwo_try-again__30mnq {
    margin-top: 30px;
}

.CarsScreenTwo_try-again__30mnq div {
    display: contents !important;
}

.CarsScreenTwo_perfect-output-images__3n2go button {
    background-color: transparent !important;
    padding: 0 !important;
    color: transparent !important;
}

.CarsScreenTwo_car-try-inner__3Ql0f {
    background: #fbfbfb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.CarsScreenTwo_overlay-box-mob__11nr0 {
    display: none;
}

.CarsScreenTwo_inactive-background-sec__10-P7 {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    /* height: 110px; */
    width: auto;
}

.CarsScreenTwo_inactive-background-sec__10-P7 span {
    font-size: 13px;
}

.CarsScreenTwo_active-background-sec__1oToB {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 3px;
    border: 2px solid #333;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
}

.CarsScreenTwo_active-background-sec__1oToB span {
    font-size: 13px;
}

.CarsScreenTwo_inactive-walls-sec__oRWD_ {
    padding: 5px;
    cursor: pointer;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    /* height: 85px; */
    width: auto;
}

.CarsScreenTwo_inactive-walls-sec__oRWD_ span {
    font-size: 13px;
}

.CarsScreenTwo_active-walls-sec__zcakD {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 3px;
    border: 2px solid #ff7700;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    /* height: 110px; */
    width: auto;
}

.CarsScreenTwo_active-walls-sec__zcakD span {
    font-size: 13px;
}

.CarsScreenTwo_active-walls-sec__zcakD img {
    width: 100%;
    height: auto;
}

.CarsScreenTwo_inactive-walls-sec__oRWD_ img {
    width: 100%;
    height: auto;
    border: 0.4px solid #333;
}

.CarsScreenTwo_inactive-walls-sec__oRWD_ {
    padding: 3px;
    border: 2px solid transparent;
    box-shadow: 0 2px 5px 0 #ddd;
}

.CarsScreenTwo_custom__379AE {
    font-size: 13px;
    font-weight: 500;
    /* margin-left: 14%; */
}

.CarsScreenTwo_cback__Iapmw {
    display: none;
}

.CarsScreenTwo_bcredit__1W5m8 {
    display: none;
}

.CarsScreenTwo_mob-image__3k9ge img {
    width: 100%;
    margin-top: 10%;
}

.CarsScreenTwo_mob-image__3k9ge .CarsScreenTwo_mac-gif__twT0N {
    position: absolute;
    width: 56.1%;
    right: 10.2%;
    top: 6%;
}

.CarsScreenTwo_cars-demo__1ZhlH {
    /* height: 580px; */
    text-align: center;
}

.CarsScreenTwo_cars-calender__3bb6R {}

.CarsScreenTwo_cars-calender__3bb6R h4 {
    text-align: center;
    width: 100%;
    color: #ff7700;
    margin-bottom: -20px;
}

.CarsScreenTwo_apply-below-btn__-qFEl {
    width: 100%;
    text-align: center;
    padding: 5px 0px;
}

.CarsScreenTwo_output-apply-btn__26C1g {
    text-align: center;
    padding: 5px 0px;
}


/* Custom Walls */

.CarsScreenTwo_custom-background-list__31lRs {
    padding: 20px 10px 20px 35px;
}

.CarsScreenTwo_custom-background-list__31lRs:before,
.CarsScreenTwo_adjust-height-area__1eqjs:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 1px dashed #ff7700;
}

.CarsScreenTwo_custom-wall__3-TfC {}

.CarsScreenTwo_custom-wall__3-TfC p,
.CarsScreenTwo_adjust-height__2lOQs p {
    margin: 0;
}

.CarsScreenTwo_adjust-height__2lOQs p {
    padding: 5px 0;
    font-size: 16px;
}

.CarsScreenTwo_custom-wall__3-TfC p span {
    background: #ff7700;
    color: #fff;
    border-radius: 50%;
    padding: 5px 13px;
    margin-right: 10px;
}

.CarsScreenTwo_adjust-height__2lOQs p span {
    background: #e5e5e5;
    color: #fff;
    border-radius: 50%;
    padding: 5px 12px;
    margin-right: 10px;
}

.CarsScreenTwo_custom-walls-area__3k1-C {
    padding-left: 10px;
}

.CarsScreenTwo_adjust-height-area__1eqjs {
    padding: 5px 0px;
    position: relative;
}

.CarsScreenTwo_adjust-height-area__1eqjs ul {
    padding: 5px 10%;
    list-style: none;
    text-align: center;
    display: flex;
}

.CarsScreenTwo_adjust-height-area__1eqjs ul li {
    text-align: center;
    width: 35%;
    padding: 10px;
    margin: 0 auto;
}

.CarsScreenTwo_adjust-height-area__1eqjs ul li button {
    background: rgba(235 215 198 / 0%) !important;
    border: 1px solid #000000 !important;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: auto;
    border-radius: 0;
}

.CarsScreenTwo_adjust-height-area__1eqjs ul li button.CarsScreenTwo_active__3yqZK,
.CarsScreenTwo_adjust-height-area__1eqjs ul li button:hover {
    background: rgb(235 215 198 / 0%) !important;
    border: 1px solid #ff7700 !important;
}

.CarsScreenTwo_adjust-height-area__1eqjs ul li h6 {
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    margin: 0;
}

.CarsScreenTwo_adjust-height-area__1eqjs ul li img {
    width: 100%;
    height: 115px;
    object-fit: contain;
}

.CarsScreenTwo_backgrounds-grid-custom__2ETxm .CarsScreenTwo_inactive-background-sec__10-P7 img {
    height: auto;
    border: 0;
}

.CarsScreenTwo_favroute-add__39nrc {
    margin: 0;
}

.CarsScreenTwo_favroute-add__39nrc img {
    width: 25%;
}

.CarsScreenTwo_favroute-add__39nrc span {
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.CarsScreenTwo_fav-icon__1HD0k {
    position: absolute;
    top: 6px;
    right: 6px;
    background: #ddddddd9;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border-radius: 0px 5px;
    padding: 0px 5px;
}

.CarsScreenTwo_fav-icon__1HD0k img {
    height: 12px;
    width: 12px;
    border: 0;
}

.CarsScreenTwo_white-icon__26aWA {}

.CarsScreenTwo_hoverable-icon__3MNMB {
    display: none;
}

.CarsScreenTwo_active-background-sec__1oToB:hover .CarsScreenTwo_white-icon__26aWA,
.CarsScreenTwo_inactive-background-sec__10-P7:hover .CarsScreenTwo_white-icon__26aWA {
    display: none;
}

.CarsScreenTwo_active-background-sec__1oToB:hover .CarsScreenTwo_hoverable-icon__3MNMB,
.CarsScreenTwo_inactive-background-sec__10-P7:hover .CarsScreenTwo_hoverable-icon__3MNMB {
    display: inline-block;
}

.CarsScreenTwo_active-background-sec__1oToB .CarsScreenTwo_fav-icon__1HD0k {
    top: 3px;
    right: 3px;
}

.CarsScreenTwo_more-btn-overlay__F5ZTl span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ff7700;
    display: block;
    text-align: center;
}

.CarsScreenTwo_colored__ZaiWL {
    color: #ff7700;
}

.CarsScreenTwo_background-list__1cmAK h6 span {
    text-decoration: underline;
}

.CarsScreenTwo_more-box__29BHB {
    box-shadow: 0 0 0 !important;
}

.CarsScreenTwo_more-box__29BHB img {
    border: 0 !important;
    margin-top: 10%;
    height: auto;
}

.CarsScreenTwo_crop-features__2Mq8I {}

.CarsScreenTwo_crop-options__37cHB {
    text-align: center;
    margin: 0px 0;
}

.CarsScreenTwo_crop-options__37cHB button {
    border: 1.5px dashed #444444;
    background: #fff;
    color: #444444;
    margin: 0 10px;
}

.CarsScreenTwo_crop-button__2KY_f {
    text-align: center;
    margin: 10px 0;
}

.CarsScreenTwo_crop-button__2KY_f button {
    margin: 0px 10px;
    text-transform: capitalize;
}

.CarsScreenTwo_crop-button__2KY_f button.CarsScreenTwo_cancel__4FKGp {
    background: #ffffff;
    border: 1.5px solid #ff7700;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ff7700;
}

.CarsScreenTwo_crop-active__21PMe {
    color: #ff7700 !important;
    border-color: #ff7700 !important;
}

.CarsScreenTwo_original_image__lm1i3 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.CarsScreenTwo_with-image-inner__2X0JT .CarsScreenTwo_cropreact__3IMkC div img,
.CarsScreenTwo_cropreact__3IMkC,
.CarsScreenTwo_cropreact__3IMkC div {
    object-fit: contain;
}

.CarsScreenTwo_original-image-div__1XnJx {
    width: 100%;
    height: 100%;
}

.CarsScreenTwo_cropper-image__2VUNe {
    width: auto !important;
    height: auto !important;
}


/* History Section Slider  */

.CarsScreenTwo_history-area__3yKeE {}

.CarsScreenTwo_history-slider__7BJTf {}

.CarsScreenTwo_history-slider-title__26xzb {}

.CarsScreenTwo_history-slider-title__26xzb h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #232121;
}

.CarsScreenTwo_history-slider-title__26xzb h6 span {
    float: right;
}

.CarsScreenTwo_history-slider-title__26xzb h6 span a {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FF9100;
}

.CarsScreenTwo_history-slide-single__3cweg {
    padding: 15px;
}

.CarsScreenTwo_history-slide__3Yp_0 {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 15px;
    /* width: 90%!important;
  margin: 0px 5%!important; */
    transition: .2s ease-in-out;
    text-align: center;
    border: 1.5px dashed transparent;
}

.CarsScreenTwo_history-slide__3Yp_0 img {
    width: 100%;
}

.CarsScreenTwo_history-slide__3Yp_0 p {
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    line-height: initial;
    letter-spacing: 0.2px;
    color: #787885;
    text-align: left;
    padding: 10px 0;
    margin: 0;
}

.CarsScreenTwo_history-slide__3Yp_0 p span {
    float: right;
}

.CarsScreenTwo_history-slide__3Yp_0 h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 79.5%;
    text-align: center;
    letter-spacing: 0.2px;
    color: #232121;
    margin-bottom: 15px;
}

.CarsScreenTwo_history-slide__3Yp_0 ul {
    list-style: none;
    padding: 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.CarsScreenTwo_history-slide__3Yp_0 ul li {}

.CarsScreenTwo_history-slide__3Yp_0 ul li img {
    background: rgba(246, 156, 76, 0.1);
    padding: 5px;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: none;
    margin-right: 10px;
}

.CarsScreenTwo_history-slide__3Yp_0 ul li p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #C4C4C4;
    display: grid;
    padding: 0;
}

.CarsScreenTwo_history-slide__3Yp_0 ul li p span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #232323;
}

.CarsScreenTwo_history-slide__3Yp_0 button {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #FF7700;
    border: 1px solid #FF7700;
    box-sizing: border-box;
    border-radius: 23.5px;
    background: #fff;
    transition: .2s ease-in-out;
}

.CarsScreenTwo_history-slide__3Yp_0:hover {
    box-shadow: 0px 4px 20px rgb(255 152 0 / 48%);
    border: 1.5px dashed #ff980061;
}

.CarsScreenTwo_history-slide__3Yp_0:hover button {
    background: #ff9800;
    color: #fff;
}

.CarsScreenTwo_enhancements__17cLn {
    font-size: 13px;
    /* margin-left: 10px; */
}

.CarsScreenTwo_enhancements__17cLn input {
    margin-left: 12px;
    margin-right: 4px;
    vertical-align: middle;
}

.CarsScreenTwo_enhancement-title__1IujW {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
}

.CarsScreenTwo_tint-opt__2WYEv {}

.CarsScreenTwo_tint-opt__2WYEv label {}

.CarsScreenTwo_tint-opt__2WYEv input {
    margin-right: 12px;
}

.CarsScreenTwo_enhancement-container__1eDcj {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ebeaea;
}

.CarsScreenTwo_brightness-container__1dn0a {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 10px 0px;
    margin-bottom: 10px !important;
    font-size: 13px;
    border-bottom: 1px solid #ebeaea;
}

.CarsScreenTwo_brightness-container__1dn0a input {
    vertical-align: middle;
}


/* Responsive  */

@media (max-width: 800px) {
    body {
        background-size: cover;
        background-repeat: repeat-y;
    }
    .CarsScreenTwo_toggle-tab__185Qk {
        justify-content: flex-end;
        margin: 0 0 -5px !important;
    }
    /* //footer */
    @-webkit-keyframes CarsScreenTwo_fadeInUp__1E9ua {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 100%, 0);
                    transform: translate3d(0, 100%, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: none;
                    transform: none;
        }
    }
    .CarsScreenTwo_screen-two-main-wrapper__3RE2H {
        display: block;
        margin: 0px 15px;
        /* display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 20px;
        margin: 0px; */
    }
    .CarsScreenTwo_left-section-with-image__1Kn91 {
        width: 100%;
        height: 343px;
    }
    .CarsScreenTwo_right-section-with-image__2c9RS {
        width: 100%;
        margin-left: 0px !important;
        margin-top: 0;
        padding-top: 0;
        height: auto;
    }
    .CarsScreenTwo_right-top-section__27J6_ {
        /* display: flex;
        flex-direction: column; */
    }
    .CarsScreenTwo_choose-angle-text__TuQtT {
        position: absolute;
        /* width: 40px; */
        height: 12px;
        left: 51.7px;
        top: 560.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .CarsScreenTwo_tooltip-text__3eKRU p {
        margin: 0px 5px;
    }
    .CarsScreenTwo_logo-text-sec__64uLO {
        position: absolute;
        height: 12px;
        left: 52.97px;
        top: 677.78px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .CarsScreenTwo_background-text__2Y5kA {
        position: absolute;
        height: 12px;
        left: 52.93px;
        top: 680.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .CarsScreenTwo_position-text__2BkQe {
        display: none;
    }
    .CarsScreenTwo_bottom-apply-area__2g-ga {
        position: absolute;
        left: -850px !important;
        top: 355px !important;
    }
    .CarsScreenTwo_bulk-pricing__2ymKG {
        position: absolute;
        left: 24%;
        top: 1150px;
        padding-bottom: 20px;
    }
    .CarsScreenTwo_separation-border__3MitP {
        display: none;
    }
    .CarsScreenTwo_with-image-inner__2X0JT {
        width: 100%;
        height: 243px;
        border: 0.6px solid #77797b;
    }
    .CarsScreenTwo_with-image-inner__2X0JT div {
        height: 100%;
        width: 100%;
    }
    .CarsScreenTwo_right-section-background-section__Awk18 {
        width: 95%;
        margin: 20px auto 20px;
        height: auto;
        overflow: hidden;
    }
    .CarsScreenTwo_right-mid-section__qPsZ9 {
        width: 100%;
        margin-top: 115px;
    }
    .CarsScreenTwo_with-image-inner__2X0JT img {
        width: 100% !important;
        /* height: 243px;
        object-fit: contain; */
        padding: 5px;
        /* object-fit: contain; */
    }
    .CarsScreenTwo_inactive-background-images__3Hqzb img,
    .CarsScreenTwo_active-background-images__2usl3 img {
        height: 45px;
        width: 45px;
    }
    .CarsScreenTwo_choose-angle__9nwAX {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .CarsScreenTwo_angle-selected__112UN {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .CarsScreenTwo_choose-angle__9nwAX button,
    .CarsScreenTwo_angle-selected__112UN button {
        width: 50px;
    }
    .CarsScreenTwo_upload-button__1MLkQ button {
        margin-left: 0px;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq {
        padding: 0px;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq div:nth-child(1) {
        /* display: none; */
        /* margin: -330px auto auto auto; */
    }
    .CarsScreenTwo_pricing-plan__X3zzW {}
    .CarsScreenTwo_upload-button__1MLkQ {
        position: absolute;
        left: 270px;
    }
    .CarsScreenTwo_upload-button__1MLkQ button {
        width: auto;
        height: 35px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 18px;
        color: #ffffff;
    }
    .CarsScreenTwo_upload-logo-button__2k6OP button {
        /* width: 105.98px; */
        height: 35px;
        left: 174.18px;
        top: 673.2px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* padding-left: 20px; */
        /* padding-right: 20px; */
        /* identical to box height */
        color: #ffffff;
    }
    .CarsScreenTwo_logo-select__1g5gu {
        margin-left: 22px;
    }
    .CarsScreenTwo_logo-image__2r44m {
        height: auto;
        width: 96.97px;
    }
    .CarsScreenTwo_logo-image__2r44m p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 11px;
        color: #888888;
    }
    .CarsScreenTwo_credit-para__2zIa6 {
        position: relative;
        top: -4%;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        width: 45px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 12px;
        color: #ffffff;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        /* margin: 10px 0;
        padding: 0px;
        display: flex;
        justify-content: space-evenly;
        height: 85px; */
        /* width: 100%; */
    }
    .CarsScreenTwo_bottom-apply-area__2g-ga p:nth-child(2) {
        display: none;
    }
    .CarsScreenTwo_center-title2__3nVw_ {
        margin-bottom: -30px;
    }
    .CarsScreenTwo_pricing-plan-area__27Si6 {
        border-right: 0;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq p:nth-child(2) {
        line-height: normal;
        line-height: initial;
        margin: 0 0 10px;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq p:nth-child(1) {
        line-height: normal;
        line-height: initial;
        margin-bottom: 10px;
    }
    .CarsScreenTwo_bottom-contact-badge__1cAIz {
        text-align: center;
        background: #dee2e6;
        padding: 5px 15px;
        border-radius: 0px 0px 11px 10px;
        margin: 0px 15px;
        margin-bottom: 50px;
    }
    .CarsScreenTwo_bg_img-texts__3ZipE {
        margin-left: 0px !important;
    }
    .CarsScreenTwo_email__1mcPd {
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
        z-index: 999 !important;
        height: 0px !important;
        width: 100% !important;
    }
    .CarsScreenTwo_email__1mcPd form {
        background: rgba(98, 98, 98, 0.7);
        border-radius: 0px;
        text-align: center;
        align-items: center;
        padding: 20px;
    }
    .CarsScreenTwo_email__1mcPd img {
        width: 100% !important;
    }
    .CarsScreenTwo_email__1mcPd label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 27px;
        color: #ffffff;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .CarsScreenTwo_email__1mcPd input {
        height: auto;
    }
    .CarsScreenTwo_email__1mcPd button {
        width: 124px;
        height: 35px;
        background: #ffffff;
        border-radius: 9px;
        color: #616161;
    }
    .CarsScreenTwo_download-popup__AnCpU button {
        padding: 15px;
    }
    .CarsScreenTwo_right-top-selection__2jsiv {
        grid-template-columns: 48% 48%;
        grid-gap: 10px;
    }
}

@media (max-width: 800px) {
    body {
        background-size: cover;
        background-repeat: repeat-y;
    }
    .CarsScreenTwo_screen-two-main-wrapper__3RE2H {
        display: block;
        margin: 0px 15px;
    }
    .CarsScreenTwo_left-section-with-image__1Kn91 {
        width: 100%;
        height: 343px;
    }
    .CarsScreenTwo_right-top-section__27J6_ {
        /* display: flex;
        flex-direction: column; */
    }
    .CarsScreenTwo_choose-angle-text__TuQtT {
        position: absolute;
        /* width: 40px; */
        height: 12px;
        left: 51.7px;
        top: 560.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .CarsScreenTwo_tooltip-text__3eKRU p {
        margin: 0px 5px;
    }
    .CarsScreenTwo_logo-text-sec__64uLO {
        position: absolute;
        height: 12px;
        left: 52.97px;
        top: 677.78px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .CarsScreenTwo_background-text__2Y5kA {
        position: absolute;
        height: 12px;
        left: 52.93px;
        top: 680.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .CarsScreenTwo_position-text__2BkQe {
        display: none;
    }
    .CarsScreenTwo_bottom-apply-area__2g-ga {
        position: absolute;
        left: -850px !important;
        top: 355px !important;
    }
    .CarsScreenTwo_bulk-pricing__2ymKG {
        position: absolute;
        left: 24%;
        top: 1150px;
        padding-bottom: 20px;
    }
    .CarsScreenTwo_separation-border__3MitP {
        display: none;
    }
    .CarsScreenTwo_with-image-inner__2X0JT {
        width: 100%;
        height: 243px;
        border: 0.6px solid #77797b;
    }
    .CarsScreenTwo_with-image-inner__2X0JT div {
        height: 100%;
        width: 100%;
    }
    .CarsScreenTwo_right-section-background-section__Awk18 {
        width: 95%;
        margin: 20px auto 20px;
        height: auto;
        overflow: hidden;
    }
    .CarsScreenTwo_right-mid-section__qPsZ9 {
        width: 100%;
        margin-top: 115px;
    }
    .CarsScreenTwo_with-image-inner__2X0JT img {
        width: 100% !important;
        /* height: 243px;
        object-fit: contain; */
        padding: 5px;
        /* object-fit: contain; */
    }
    .CarsScreenTwo_inactive-background-images__3Hqzb img,
    .CarsScreenTwo_active-background-images__2usl3 img {
        height: 45px;
        width: 45px;
    }
    .CarsScreenTwo_choose-angle__9nwAX {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .CarsScreenTwo_angle-selected__112UN {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .CarsScreenTwo_choose-angle__9nwAX button,
    .CarsScreenTwo_angle-selected__112UN button {
        width: 50px;
    }
    .CarsScreenTwo_upload-button__1MLkQ button {
        margin-left: 0px;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq {
        padding: 0px;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq div:nth-child(1) {}
    .CarsScreenTwo_pricing-plan__X3zzW {}
    .CarsScreenTwo_upload-button__1MLkQ {
        position: absolute;
        left: 270px;
    }
    .CarsScreenTwo_upload-button__1MLkQ button {
        width: auto;
        height: 35px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 18px;
        color: #ffffff;
    }
    .CarsScreenTwo_upload-logo-button__2k6OP button {
        /* width: 105.98px; */
        height: 35px;
        left: 174.18px;
        top: 673.2px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
    }
    .CarsScreenTwo_logo-select__1g5gu {
        margin-left: 22px;
    }
    .CarsScreenTwo_logo-image__2r44m {
        height: auto;
        width: 96.97px;
    }
    .CarsScreenTwo_logo-image__2r44m p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 11px;
        color: #888888;
    }
    .CarsScreenTwo_credit-para__2zIa6 {
        position: relative;
        top: -4%;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        width: 45px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 12px;
        color: #ffffff;
    }
    .CarsScreenTwo_backgrounds__MTsSL {}
    .CarsScreenTwo_bottom-apply-area__2g-ga p:nth-child(2) {
        display: none;
    }
    .CarsScreenTwo_center-title2__3nVw_ {
        margin-bottom: -30px;
    }
    .CarsScreenTwo_pricing-plan-area__27Si6 {
        border-right: 0;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq p:nth-child(2) {
        line-height: normal;
        line-height: initial;
        margin: 0 0 10px;
    }
    .CarsScreenTwo_right-bottom-section__3eWMq p:nth-child(1) {
        line-height: normal;
        line-height: initial;
        margin-bottom: 10px;
    }
    .CarsScreenTwo_bottom-contact-badge__1cAIz {
        text-align: center;
        background: #dee2e6;
        padding: 5px 15px;
        border-radius: 0px 0px 11px 10px;
        margin: 0px 15px;
        margin-bottom: 50px;
    }
    .CarsScreenTwo_bg_img-texts__3ZipE {
        margin-left: 0px !important;
    }
    .CarsScreenTwo_email__1mcPd {
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
        z-index: 999 !important;
        height: 0px !important;
        width: 100% !important;
    }
    .CarsScreenTwo_email__1mcPd form {
        background: rgba(98, 98, 98, 0.7);
        border-radius: 0px;
        text-align: center;
        align-items: center;
        padding: 20px;
    }
    .CarsScreenTwo_email__1mcPd img {
        width: 100% !important;
    }
    .CarsScreenTwo_email__1mcPd label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 27px;
        color: #ffffff;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .CarsScreenTwo_email__1mcPd input {
        height: auto;
    }
    .CarsScreenTwo_email__1mcPd button {
        width: 124px;
        height: 35px;
        background: #ffffff;
        border-radius: 9px;
        color: #616161;
    }
    .CarsScreenTwo_car-try-inner__3Ql0f {
        box-shadow: 0 0 0;
        padding-top: 20px;
    }
    .CarsScreenTwo_top-bar__E7dlB {
        padding: 0;
    }
    .CarsScreenTwo_backcredit____MYF {
        display: none;
    }
    .CarsScreenTwo_back-credit-div__kU2a- {}
    .CarsScreenTwo_back-credit__2sFDb {
        position: absolute;
        top: 0;
        right: 10px;
        padding: 0 !important;
        padding: 0;
    }
    .CarsScreenTwo_backgrounds-grid__3rwtl .CarsScreenTwo_inactive-background-sec__10-P7 {
        position: relative;
    }
    .CarsScreenTwo_inactive-background-sec__10-P7 img {
        height: 47px;
    }
    .CarsScreenTwo_more-btn-overlay__F5ZTl {
        font-size: 12px;
    }
    .CarsScreenTwo_rmaining__1Yer1 {
        padding: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
    }
    .CarsScreenTwo_rmaining__1Yer1 span {
        color: #0579df;
    }
    .CarsScreenTwo_perfect-output__2h4MT {
        margin: 70px 0 0;
    }
    .CarsScreenTwo_download-popup__AnCpU {
        border: 0;
        background: #ffffff;
        box-shadow: 0 0 0;
        padding: 20px 0;
    }
    .CarsScreenTwo_active-background-sec__1oToB img {
        width: 100%;
        height: auto;
    }
    .CarsScreenTwo_inactive-background-sec__10-P7 {
        padding: 3px;
        border: 2px solid transparent;
    }
    .CarsScreenTwo_download-popup__AnCpU {
        border: 0;
        background: #ffffff;
        box-shadow: 0 0 0;
        padding: 20px 0;
    }
    .CarsScreenTwo_backgrounds-grid__3rwtl {
        display: grid;
        grid-template-columns: 31% 31% 31%;
        grid-gap: 9px;
        margin: 0 auto;
    }
    .CarsScreenTwo_checkbox-section__9tBFb label {
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
    .CarsScreenTwo_all-overlays__2rA65 {
        padding: 15px 5px;
    }
    .CarsScreenTwo_select-sec__NzS5- {
        display: none;
    }
    .CarsScreenTwo_active-background-sec-below__2yUr5 img {
        width: 100%;
    }
    .CarsScreenTwo_apply-below-btn__-qFEl {
        width: 200%;
        text-align: center;
        padding: 10px 0px;
    }
    .CarsScreenTwo_bg-below-sec__3W3ZS {
        padding: 15px 0px;
    }
    .CarsScreenTwo_output-apply-btn__26C1g {
        padding: 15px 0px;
    }
    .CarsScreenTwo_right-top-selection__2jsiv {
        margin: 5px auto 20px;
    }
    .CarsScreenTwo_backgrounds-grid-custom__2ETxm .CarsScreenTwo_inactive-background-sec__10-P7 img {
        height: 35px;
    }
    .CarsScreenTwo_active-walls-sec__zcakD img {
        height: 35px;
    }
    .CarsScreenTwo_inactive-walls-sec__oRWD_ img {
        height: 35px;
    }
    .CarsScreenTwo_adjust-height-area__1eqjs ul li {
        width: 49%;
    }
}

@media screen and (max-width: 320px) and (min-width: 200px) {
    .CarsScreenTwo_bottom-apply-area__2g-ga button {
        width: 100%;
        position: fixed;
        top: 94%;
        z-index: 1000;
        left: 0;
        border-radius: 0;
    }
    .CarsScreenTwo_footer-left-text__1B2On h2 {
        font-size: 20px;
        line-height: 35px;
        margin-top: 0%;
    }
    .CarsScreenTwo_email__1mcPd input {
        height: auto;
        margin-bottom: 15px;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        width: 300px;
        justify-content: center !important;
    }
}

@media screen and (max-width: 359px) and (min-width: 200px) {
    .CarsScreenTwo_box-text__1OfSm {
        padding-top: 41%;
        font-size: 14px;
        width: 70%;
        line-height: 25px;
    }
}

@media screen and (max-width: 767px) and (min-width: 360px) {
    .CarsScreenTwo_box-text__1OfSm {
        padding-top: 32%;
        font-size: 22px;
        width: 70%;
    }
    .CarsScreenTwo_box-img__1hGag {
        width: 30% !important;
    }
    .CarsScreenTwo_original_image__lm1i3 {
        width: 100% !important;
        height: auto;
        object-fit: contain !important;
        object-position: center;
    }
}

@media screen and (max-width: 767px) and (min-width: 321px) {
    .CarsScreenTwo_bottom-apply-area__2g-ga button {
        width: 100%;
        position: fixed;
        top: 94%;
        z-index: 1000;
        left: 0;
        border-radius: 0;
    }
    .CarsScreenTwo_footer-left-text__1B2On h2 {
        font-size: 20px;
        line-height: 45px;
        margin-top: 0%;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        width: 400px;
        justify-content: center !important;
    }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
    .CarsScreenTwo_footer__1-Sdr {
        background: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5945+1.png);
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 10px 0px;
    }
    .CarsScreenTwo_footer-left-text__1B2On {
        font-size: 20px;
        line-height: 35px;
        margin-top: 0;
    }
    .CarsScreenTwo_footer-right-text__2i-7f {
        padding: 10px 10px 0;
    }
    .CarsScreenTwo_footer-right-text__2i-7f ul li {
        margin: 0px 10px;
        width: 10%;
        line-height: 27px;
    }
    .CarsScreenTwo_footer-right-text__2i-7f ul {
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    .CarsScreenTwo_footer-right-text__2i-7f ul li img {
        padding: 5px;
        width: 100%;
    }
    .CarsScreenTwo_footer__1-Sdr {
        height: 100%;
    }
    .CarsScreenTwo_footer-right-text__2i-7f p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 10px;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        justify-content: center !important;
    }
    .CarsScreenTwo_car-overlay-cat__2nF_U {
        border-right: 1px solid #ddd;
        padding: 0 5px;
    }
    .CarsScreenTwo_overlay-car-btn__xwkKx {
        padding: 0px 5px;
        display: inline-block;
        margin: 0 3px;
        width: 31%;
    }
    .CarsScreenTwo_car-try-btns__2RQFL img {}
    .CarsScreenTwo_overlay-box-mob__11nr0 {
        display: flex;
        border-bottom: 1px solid #ddd;
    }
    .CarsScreenTwo_car-mobile-angle__2ARfT {
        padding: 0 10px 0.5rem;
        font-size: 12px;
        color: #ff7700;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
    }
    .CarsScreenTwo_car-overlay-cat-mob__1JcaU {
        padding: 0;
    }
    .CarsScreenTwo_logo-sec__10VEL {
        display: none;
    }
    .CarsScreenTwo_lower-section-with-overlay-2__-jtwn {
        display: block;
        grid-template-columns: 100%;
    }
    .CarsScreenTwo_overlays-click-btn__27xhS img {
        height: 40px;
        margin: 0 auto;
    }
    .CarsScreenTwo_credit-text__3BFx7 {
        display: none;
    }
    .CarsScreenTwo_angle-sec__2Cppn {
        border-bottom: 0;
    }
    .CarsScreenTwo_overlays-click-btn__27xhS {
        margin: 5px 10px 5px 0px;
    }
    .CarsScreenTwo_all-overlays-slider__2S9eK {
        padding: 0;
    }
    .CarsScreenTwo_edited_image__JqyEa {
        height: 196px;
        width: 348px !important;
    }
    .CarsScreenTwo_with-image-inner-2__1xays img {
        height: 100%;
        object-fit: cover;
    }
    .CarsScreenTwo_left-section-with-image-2__2mQFt {
        width: 100%;
        padding: 15px 10px;
    }
    .CarsScreenTwo_mobile-uploads__3zRLN {
        line-height: 35px;
        padding: 5px 15px 0;
    }
    .CarsScreenTwo_upload-sm-img__3jSQs {
        float: right;
        border: 1px solid #ff7700;
        color: #ff7700 !important;
        background: #fff;
        padding: 5px 15px;
        border-radius: 3px;
        height: auto;
        width: 125px;
        line-height: 20px;
    }
    .CarsScreenTwo_left-section-with-image-2__2mQFt .CarsScreenTwo_mobile-uploads__3zRLN {
        padding: 20px 5px;
        border-bottom: 1px solid #ddd;
        line-height: 30px;
    }
    .CarsScreenTwo_mobile-uploads__3zRLN p span {
        font-size: 15px;
        line-height: 20px;
    }
    .CarsScreenTwo_toggle-section__27oxZ {
        display: none;
    }
    .CarsScreenTwo_mobile-uploads__3zRLN p {
        margin: 0;
    }
    .CarsScreenTwo_background-list__1cmAK {
        margin-top: 20px;
        padding: 15px 10px;
    }
    .CarsScreenTwo_upload-sec__3zjpo {
        display: none;
    }
    .CarsScreenTwo_backgrounds-content__Vu-2U {
        margin: 0;
    }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
    .CarsScreenTwo_car-text__1zQXb h1 {
        font-size: 19px;
    }
    .CarsScreenTwo_box-text__1OfSm {
        padding-top: 15%;
        font-size: 35px;
        width: 70%;
    }
    .CarsScreenTwo_footer__1-Sdr {
        height: auto;
    }
    .CarsScreenTwo_footer-right-text__2i-7f ul {
        margin: 0;
    }
    .CarsScreenTwo_footer-left-text__1B2On h2 {
        margin-top: 28%;
    }
    .CarsScreenTwo_footer-right-text__2i-7f ul li img {
        padding: 12px;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        width: 100%;
        justify-content: center !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 901px) {
    .CarsScreenTwo_footer-left-text__1B2On h2 {
        margin-top: 22%;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        justify-content: center !important;
    }
    .CarsScreenTwo_with-image-inner__2X0JT {
        width: 566.57px;
        height: 314px;
        border: 0.6px solid #77797b;
    }
    .CarsScreenTwo_left-section-with-image__1Kn91 {
        width: 607.57px;
        height: 347px;
        /* background: #ffffff; */
        /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
        padding: 21.87px 18.87px 10px;
    }
}

@media screen and (max-width: 1290px) and (min-width: 1025px) {
    .CarsScreenTwo_backgrounds__MTsSL {
        width: 300px;
        justify-content: center !important;
    }
    .CarsScreenTwo_car-overlay-cat__2nF_U {
        padding: 0 0 0 5px;
    }
    .CarsScreenTwo_overlay-car-btn__xwkKx {
        padding: 5px 0px;
    }
    .CarsScreenTwo_car-try-btns__2RQFL span {
        margin-top: 0%;
    }
    .CarsScreenTwo_overlay-vertical__2GWJ8 {
        height: 255px;
    }
    .CarsScreenTwo_download-popup__AnCpU button {
        padding: 15px 60px;
    }
    .CarsScreenTwo_download-popup__AnCpU {
        padding: 15px 30px;
    }
    .CarsScreenTwo_cross-btn__2wsRK {
        padding: 0 0 20px;
    }
}

@media (min-width: 1400px) {
    .CarsScreenTwo_footer-left-text__1B2On h2 {
        margin-top: 15%;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        width: 100%;
        justify-content: flex-start !important;
    }
    .CarsScreenTwo_overlay-toggle__1TNlc {
        display: flex;
        margin-top: 0%;
    }
    .CarsScreenTwo_overlay-vertical__2GWJ8 {
        height: 315px;
    }
}

@media (max-width: 1299px) {
    .CarsScreenTwo_footer-left-text__1B2On h2 {
        margin-top: 15%;
    }
    .CarsScreenTwo_backgrounds__MTsSL {
        width: 100%;
        justify-content: center !important;
    }
}

.CarsScreenTwo_container-2__BR-Sr {
    display: block;
    position: relative;
    padding-left: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.CarsScreenTwo_container-2__BR-Sr input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.CarsScreenTwo_checkmark__13qTw {
    position: absolute;
    top: 9%;
    left: 9%;
    height: 18px;
    width: 18px;
    border: 2px solid #b9b6b6;
}


/* On mouse-over, add a grey background color */

.CarsScreenTwo_container-2__BR-Sr:hover input~.CarsScreenTwo_checkmark__13qTw {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.CarsScreenTwo_container-2__BR-Sr input:checked~.CarsScreenTwo_checkmark__13qTw {
    background-color: #fff;
    border: 2px solid #ff7700;
}


/* Create the checkmark/indicator (hidden when not checked) */

.CarsScreenTwo_checkmark__13qTw:after {
    content: '';
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.CarsScreenTwo_container-2__BR-Sr input:checked~.CarsScreenTwo_checkmark__13qTw:after {
    display: block;
}


/* Style the checkmark/indicator */

.CarsScreenTwo_container-2__BR-Sr .CarsScreenTwo_checkmark__13qTw:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #ff7700;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.CarsScreenTwo_product-hunt-tag__1nsFH {
    width: auto;
    /* top: 0; */
    position: absolute;
    right: 115px;
    top: 105px;
}

.CarsScreenTwo_product-hunt-tag2__1Jpny {
    text-align: center;
    margin: -10% 0 20px;
}

.CarsScreenTwo_product-hunt__2VWBa img {
    width: 250px;
    margin: 0 0 0 10px;
}

@media screen and (max-width: 800px) {
    .CarsScreenTwo_checkmark__13qTw {
        left: 0;
        height: 15px;
        width: 15px;
    }
    .CarsScreenTwo_check-window__3V8_L {
        padding: 0;
    }
}

@media (min-width: 1366px) {
    .CarsScreenTwo_perfect-output__2h4MT {
        max-width: 93.5%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1440px) {
    .CarsScreenTwo_perfect-output__2h4MT {
        max-width: 89%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
    .CarsScreenTwo_product-hunt-tag__1nsFH {
        right: 20%;
    }
}

@media (min-width: 1680px) {
    .CarsScreenTwo_perfect-output__2h4MT {
        max-width: 76.3%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1920px) {
    .CarsScreenTwo_perfect-output__2h4MT {
        max-width: 66.3%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2560px) {
    .CarsScreenTwo_perfect-output__2h4MT {
        max-width: 50%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2880px) {
    .CarsScreenTwo_perfect-output__2h4MT {
        max-width: 44.2%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

.CarsScreenTwo_superscript__3mHZe {
    background-color: #ffdcbe;
    padding: 1px 2px;
    color: #ff7700;
    font-weight: 500;
    font-size: 9px;
    top: -1em;
}
.FootwearScreenTwo_upload-section__3VrGB{
    width: 95%;
    background: #FFFFFF;
    border: 1px dashed #0579DF;
    border-radius: 10px;
    margin: 0 auto 20px;
    text-align: center;
    padding: 10px;
}
.FootwearScreenTwo_screen-2-drop__2ahk0{
    width: 391.09px !important;
    height: 100%;
    outline: 0;
    border: 0;
}
.FootwearScreenTwo_upload-section-car__wqlY6{
    width: 95%;
    background: #FFFFFF;
    border: 1px dashed #0579DF;
    border-radius: 10px;
    margin: 0 auto 25px;
    text-align: center;
    padding: 10px;
}
.FootwearScreenTwo_screen-2-drop-car__DPUz8{
    width: 100%;
   
}
.FootwearScreenTwo_screen-2-drop-car__DPUz8:focus{
    outline: none;
}
.FootwearScreenTwo_right-section-background-section__23hCn{
    width: 95%;
    height: auto;
    background: #FFFFFF;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: auto;
    overflow-x: hidden;
    margin: 0 auto;
}
.FootwearScreenTwo_backgrounds__1dqOA{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-gap: 10px;
    padding: 0px 30px;
    margin: 13px 0px;
    padding-bottom: 20px;
}
@media(max-width:800px){
    .FootwearScreenTwo_screen-2-drop__2ahk0{
        width: auto;
    }
    .FootwearScreenTwo_screen-2-drop-car__DPUz8{
        width: auto !important;
    }
    .FootwearScreenTwo_right-section-background-section__23hCn { 
        margin-bottom: 20px;
    }
    .FootwearScreenTwo_backgrounds__1dqOA { 
        grid-gap: 0px;
        padding: 0px 10px;
        margin: 0px 0px 15px;
        padding-bottom: 20px;
    }
}
/* .dealership-logo-section-wrapper{
    border: 2px solid red;
    margin: 0px 21.39px;
} */
.dealerhipLogo_right-mid-setion-content__2QWbu {
  display: flex;
  text-align: center;
  /* justify-content: space-around; */
  width: 100%;
  margin: 0;
  margin-top: 20px;
}
.dealerhipLogo_logo-image__1tFUC p {
  margin: 10px 0 0;
  display: inline;
  display: initial;
  font-size: 14px;
}
.dealerhipLogo_logo-image__1tFUC {
  height: 50px;
  background: #e4e4e4;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  padding: 12px 0px;
}
.dealerhipLogo_logo-image__1tFUC img {
  height: -webkit-fill-available !important;
}
.dealerhipLogo_upload-button__2QJUE {
  width: 140px;
  height: 40px;
  border-color: #ff7700;
  border-radius: 5px;
  margin-top: 8px;
  margin-left: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ff7700;
  border: 2px solid #ff7700;
}
.dealerhipLogo_upload-button__2QJUE button:hover {
  color: #ff7700 !important;
}
.dealerhipLogo_upload-logo-button__3WNJm button:hover {
  color: #ff7700 !important;
}
.dealerhipLogo_logo-select__PShN8 {
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}
.dealerhipLogo_logo-select__PShN8 input {
  margin-top: 5px;
}
.dealerhipLogo_left-top-logo-preview__VAwSA {
  position: absolute;
  top: 5%;
  width: 100px;
  /* height: 0; */
  left: 13%;
}
.dealerhipLogo_left-bottom-logo-preview__g8zJp {
  position: absolute;
  top: 43%;
  width: 100px;
  /* height: 0; */
  left: 13%;
}
.dealerhipLogo_right-top-logo-preview__1SH5H {
  position: absolute;
  top: 5%;
  right: 42.8%;
  width: 100px;
}
.dealerhipLogo_right-bottom-logo-preview__1RRIm {
  position: absolute;
  right: 42.8%;
  width: 100px;
  /* height: 0; */
  top: 43%;
}
.dealerhipLogo_left-top-logo-preview__VAwSA img,
.dealerhipLogo_left-bottom-logo-preview__g8zJp img,
.dealerhipLogo_right-top-logo-preview__1SH5H img,
.dealerhipLogo_right-bottom-logo-preview__1RRIm img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.dealerhipLogo_small-image-checkboxes__p78Dz {
  padding: 0;
}

input.dealerhipLogo_checkboxField__2ua8p[type='checkbox']:checked {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  outline: 0;
}
.dealerhipLogo_checkboxField__2ua8p:before {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 0;
  border-radius: 0;
  background-color: white;
  border-color: transparent;
  box-sizing: border-box;
  color: grey;
  content: close-quote;
  display: inline-block;
  height: 13px;
  outline: 1px solid grey;
  transition-duration: 0.5s;
  transition-property: background-color, border-color;
  width: 13px;
  position: absolute;
}

.dealerhipLogo_checkboxField__2ua8p:checked:before {
  background-color: grey;
  border-color: grey;
}

/*== Moving checkbox Icons ==*/
.dealerhipLogo_sm-car-checkbox-1__1XtST {
  width: 30px;
  height: 2px;
  margin: 5px 0 0;
  border-bottom: 1px solid grey;
}
.dealerhipLogo_sm-car-checkbox-2__18na2 {
  width: 2px;
  height: 40px;
  margin: 0 0px 0 5px;
  border-left: 1px solid grey;
}

.dealerhipLogo_sm-car-checkbox-3__55WJM {
  width: 2px;
  height: 40px;
  border-left: 1px solid grey;
  margin-left: 50px;
  margin-top: -50px;
}
.dealerhipLogo_sm-car-checkbox-4__1NbUS {
  width: 30px;
  height: 2px;
  border-bottom: 1px solid grey;
  margin-top: 5px;
}

.dealerhipLogo_logo-text__17Br3 {
  font-size: 14px;
  font-family: poppins;
  text-align: left;
  font-weight: 600;
}

.dealerhipLogo_logo-text__17Br3 span {
  font-size: 10px;
}

.dealerhipLogo_logo-pos-text__srMme {
  font-size: 14px;
  font-family: poppins;
  text-align: left;
  font-weight: 600;
}

.dealerhipLogo_logo-image__1tFUC .dealerhipLogo_cross-logo__1jid- {
  width: 10px;
  height: 10px !important;
  position: absolute;
  top: 7%;
  right: 12%;
  z-index: 999;
  cursor: pointer;
}

/* ==//Moving checkbox Icons== */

@media (max-width: 800px) {
  .dealerhipLogo_right-mid-section__13Oag {
    margin-top: 0px !important;
  }
  .dealerhipLogo_right-mid-setion-content__2QWbu {
    justify-content: space-around !important;
  }
  .dealerhipLogo_logo-image__1tFUC {
    order: 1;
    padding: 12px 5px 5px;
  }
  .dealerhipLogo_logo-image__1tFUC p {
    /* font-size: 14px; */
  }
  .dealerhipLogo_upload-logo-button__3WNJm {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .dealerhipLogo_small-image-checkboxes__p78Dz {
    padding-left: 70px;
    order: 2;
    text-align: right;
  }
  /* .left-top-logo-preview, .left-bottom-logo-preview, .right-top-logo-preview, .right-bottom-logo-preview{
        display: none;
    } */
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .dealerhipLogo_upload-logo-button__3WNJm {
    order: 3;
    margin: 10px 0px 0px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .dealerhipLogo_upload-logo-button__3WNJm {
    order: 3;
    margin: 0;
    text-align: right;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 320px) and (min-width: 200px) {
  .dealerhipLogo_small-image-checkboxes__p78Dz {
    padding-left: 45px !important;
  }
  .dealerhipLogo_logo-image__1tFUC {
    padding: 3px 5px 5px !important;
    margin-top: 5px;
  }
  .dealerhipLogo_left-top-logo-preview__VAwSA {
    top: 42%;
    width: 60px;
    left: 50px;
  }
  .dealerhipLogo_right-top-logo-preview__1SH5H {
    top: 42%;
    right: 16%;
    width: 60px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1RRIm {
    right: 16%;
    width: 60px;
    bottom: 120px;
  }
  .dealerhipLogo_left-bottom-logo-preview__g8zJp {
    width: 60px;
    bottom: 120px;
    left: 50px;
  }
}
@media screen and (max-width: 360px) and (min-width: 321px) {
  .dealerhipLogo_right-top-logo-preview__1SH5H {
    top: 37%;
    right: 15%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__VAwSA {
    width: 60px;
    left: 50px;
  }

  .dealerhipLogo_left-bottom-logo-preview__g8zJp {
    bottom: 200px;
    width: 60px;
    left: 50px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1RRIm {
    bottom: 200px;
    width: 60px;
    right: 15%;
  }
}

@media screen and (max-width: 374px) and (min-width: 361px) {
  .dealerhipLogo_right-top-logo-preview__1SH5H {
    top: 33%;
    right: 14%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__VAwSA {
    width: 60px;
    left: 55px;
    top: 33%;
  }

  .dealerhipLogo_left-bottom-logo-preview__g8zJp {
    bottom: 285px;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1RRIm {
    bottom: 285px;
    width: 60px;
    right: 13%;
  }
}

@media screen and (max-width: 410px) and (min-width: 375px) {
  .dealerhipLogo_right-top-logo-preview__1SH5H {
    top: 36%;
    right: 14%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__VAwSA {
    width: 60px;
    left: 55px;
    top: 36%;
  }

  .dealerhipLogo_left-bottom-logo-preview__g8zJp {
    bottom: 225px;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1RRIm {
    bottom: 225px;
    width: 60px;
    right: 15%;
  }
}

@media screen and (max-width: 767px) and (min-width: 411px) {
  .dealerhipLogo_right-top-logo-preview__1SH5H {
    top: 33%;
    right: 14%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__VAwSA {
    width: 60px;
    left: 55px;
    top: 33%;
  }

  .dealerhipLogo_left-bottom-logo-preview__g8zJp {
    bottom: 295px;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1RRIm {
    bottom: 295px;
    width: 60px;
    right: 15%;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .dealerhipLogo_right-top-logo-preview__1SH5H {
    top: 21%;
    right: 8%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__VAwSA {
    width: 60px;
    left: 55px;
    top: 21%;
  }

  .dealerhipLogo_left-bottom-logo-preview__g8zJp {
    bottom: 59%;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1RRIm {
    bottom: 59%;
    width: 60px;
    right: 8%;
  }
}

/* @media screen and (max-width: 1280px) and (min-width: 900px) {
  .upload-button {
    width: 80%;
    font-size: 13px;
  }
  .logo-image {
    padding: 5px 5px;
  }
  .upload-logo-button {
    padding: 0;
  }

  .sm-car-checkbox-3 {
    margin-left: 44px;
  }
  .left-top-logo-preview {
    top: 31%;
    width: 100px;
    left: 95px;
  }
  .right-top-logo-preview {
    position: absolute;
    top: 31%;
    right: 35%;
  }
  .right-bottom-logo-preview {
    right: 35%;
    bottom: 150px;
  }
  .left-bottom-logo-preview {
    bottom: 150px;
    left: 95px;
  }
} */

/* @media screen and (max-width: 1300px) and (min-width: 1200px) {
  .logo-image p {
    line-height: 2.5;
  }
  .sm-car-checkbox-3 {
    margin-left: 49px;
  }
}

@media screen and (max-width: 1498px) and (min-width: 1440px) {
  .left-top-logo-preview {
    top: 31%;
    left: 90px;
  }
  .right-top-logo-preview {
    top: 31%;
    right: 42%;
  }
  .right-bottom-logo-preview {
    right: 42%;
    bottom: 22%;
  }
  .left-bottom-logo-preview {
    left: 90px;
    bottom: 22%;
  }
}

@media screen and (max-width: 1599px) and (min-width: 1499px) {
  .left-top-logo-preview {
    top: 25%;
    left: 55px;
  }
  .right-top-logo-preview {
    top: 25%;
    right: 45%;
  }
  .right-bottom-logo-preview {
    right: 47%;
    bottom: 20%;
  }
  .left-bottom-logo-preview {
    left: 90px;
    bottom: 20%;
  }
}

@media screen and (min-width: 1601px) {
  .left-top-logo-preview {
    top: 25%;
    left: 45px;
  }
  .right-top-logo-preview {
    top: 25%;
    right: 51%;
  }
  .right-bottom-logo-preview {
    right: 51%;
    bottom: 22%;
  }

  .left-bottom-logo-preview {
    bottom: 22%;
    left: 45px;
  }
} */

.dealerhipLogo_radio-logo__20xbE {
  margin-right: 10px;
}
.dealerhipLogo_feature-position__2ZQy-{
  display: inline-flex;
  justify-content: left;
  width: 100%;
}
.dealerhipLogo_big__31G7P {
  display:block;
  width:100%;
  height: 100%;
  cursor:pointer;
}
.BulkProcessingV2_bulk-try__3vyn_ {
    padding: 80px 0;
}

.BulkProcessingV2_change-tabs__2a5sJ {
    padding: 10px 0 20px;
    display: flex;
    justify-content: left;
    list-style: none;
    margin-left: 10px;
}

.BulkProcessingV2_change-tab2__y7U8q {
    padding: 10px 0 0 !important;
    border-bottom: 1px solid #ddd;
}

.BulkProcessingV2_change-tabs__2a5sJ li {
    padding: 0;
}

.BulkProcessingV2_right-top-credits__3Tlc8 {
    text-align: left;
    padding: 0;
}

.BulkProcessingV2_toggle1__bAlkr {
    position: relative;
    color: #f39e53;
}

.BulkProcessingV2_toggle1__bAlkr input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.BulkProcessingV2_slider1__1A-Y7 {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 190px;
    box-shadow: 0px 4px 4px rgb(255, 255, 255);
    border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
}

.BulkProcessingV2_slider1__1A-Y7::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top: 3px
}

input:checked+.BulkProcessingV2_slider1__1A-Y7 {
    background-color: #78f353;
}

input:checked+.BulkProcessingV2_slider1__1A-Y7::before {
    -webkit-transform: translateX(21.5px);
            transform: translateX(21.5px);
}

.BulkProcessingV2_right-top-credits__3Tlc8 button {
    padding: 11px 9px;
    background: linear-gradient( 180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient( 180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #ff7700;
    background-blend-mode: soft-light, normal, normal;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
    border-radius: 32px;
}

.BulkProcessingV2_right-top-credits__3Tlc8 img {
    width: 25px !important;
}

.BulkProcessingV2_add-credit__1XcOD {
    color: white !important;
}

.BulkProcessingV2_add-credit__1XcOD img {}

.BulkProcessingV2_change-tabs__2a5sJ li button {
    background: transparent;
}

.BulkProcessingV2_change-tabs__2a5sJ li button {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
    border-radius: 0;
    border-bottom: 2px solid transparent;
}

.BulkProcessingV2_change-tabs__2a5sJ li button.BulkProcessingV2_active__1Junz,
.BulkProcessingV2_change-tabs__2a5sJ li button:hover,
.BulkProcessingV2_change-tabs__2a5sJ li button.BulkProcessingV2_activate__3O7DG {
    color: #ff7700 !important;
    border-bottom: 2px solid #ff7700;
}

.BulkProcessingV2_tab-content__1ltPc {
    overflow: hidden;
    height: 550px;
    overflow-y: scroll;
    margin: 0;
    padding: 22px 0px;
}

.BulkProcessingV2_uploaded-cars__1jXXJ {}

.BulkProcessingV2_uploaded-cars-single__1G1fs {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    margin: 0 0 20px;
    padding: 5px 0px;
}

.BulkProcessingV2_uploaded-cars-single__1G1fs img {
    width: 100%;
    padding: 0;
    height: 105px;
    object-fit: cover;
    cursor: pointer;
}

.BulkProcessingV2_uploaded-cars-single__1G1fs p {
    text-align: center;
    padding: 5px 5px;
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BulkProcessingV2_reload-cancel__1DbIe {
    text-align: right;
    justify-content: flex-end;
    padding: 0;
    height: 30px;
    align-items: center;
    padding: 0 10px;
}


/* .reload-cancel img {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0 0 5px 10px;
} */

.BulkProcessingV2_reload-cancel__1DbIe span img {}

.BulkProcessingV2_reload-cancel__1DbIe {
    opacity: 1;
}

.BulkProcessingV2_uploaded-cars-single__1G1fs:hover .BulkProcessingV2_reload-cancel__1DbIe {
    opacity: 1;
}

.BulkProcessingV2_reload-cancel2__vSTRk {
    text-align: right;
    justify-content: flex-end;
    padding: 0;
    height: 20px;
    align-items: center;
}

.BulkProcessingV2_reload-cancel2__vSTRk img {
    /* padding: 5px; */
    /* background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%; */
    height: 35px;
    width: 35px;
    margin: 0 0 5px 10px;
}

.BulkProcessingV2_reload-cancel2__vSTRk span img {
    position: absolute;
    right: -2%;
    top: -10%;
    margin: 0;
    cursor: pointer;
}

.BulkProcessingV2_bulk-right-box__3_hUa {
    background: #ffffff;
    box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.15);
    height: 100%;
}

.BulkProcessingV2_credits-available__2xFGL {
    padding: 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    /* text-align: center; */
    letter-spacing: 0.5px;
    color: #232121;
    border-bottom: 1px solid #ebeaea;
    margin-left: 0px;
}

.BulkProcessingV2_credits-available__2xFGL span {
    color: #f70;
}

.BulkProcessingV2_credits-available__2xFGL img {
    margin-right: 10px;
    width: 35px;
}

.BulkProcessingV2_credits-available__2xFGL sub {
    background: #ff7700;
    border: 2px solid #ffffff;
    padding: 3px;
    color: #fff;
    font-weight: 500;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 8px;
    margin-left: -5%;
    bottom: -5px;
}

.BulkProcessingV2_bulk-accordion__2emBR {
    padding: 12px;
    border-bottom: 1px solid #ebeaea;
}

.BulkProcessingV2_background-grid__2ncml {
    padding: 0 5px;
}

.BulkProcessingV2_background-grid__2ncml img {
    width: 100%;
    height: auto;
    padding: 0;
}

.BulkProcessingV2_background-grid__2ncml h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.BulkProcessingV2_back-credit__c6E8I {}

.BulkProcessingV2_back-credit__c6E8I {
    font-weight: 500;
    font-size: 11px;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #232121;
    /* padding: 5px 0; */
}

.BulkProcessingV2_back-credit__c6E8I span {
    float: right;
    font-size: 10px;
}

.BulkProcessingV2_bulk-bg__2m143 {
    margin: 0;
}

.BulkProcessingV2_bulk-bg__2m143 img {
    margin-bottom: 10px;
}

.BulkProcessingV2_back-credit__c6E8I span img {
    width: 10px;
}

[role='button'] {
    cursor: pointer;
    outline: 0;
}

.BulkProcessingV2_logo-sec__3KlHQ {
    border-bottom: 1px solid #ebeaea;
    padding: 12px;
}

.BulkProcessingV2_bulk-accordion__2emBR h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    margin: 0 0 20px;
    letter-spacing: 0.5px;
    color: #232121;
}

.BulkProcessingV2_checkbox-section__1AHM_ {
    padding: 20px 0;
}

.BulkProcessingV2_checkbox-section__1AHM_ label {
    font-size: 14px;
    color: #232121;
    font-weight: 600;
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.BulkProcessingV2_container-2__3LwyU input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.BulkProcessingV2_container-2__3LwyU:hover input~.BulkProcessingV2_checkmark__3FMNZ {
    background-color: #ccc;
}

.BulkProcessingV2_checkmark__3FMNZ {
    position: absolute;
    top: 9%;
    left: 9%;
    height: 18px;
    width: 18px;
    border: 2px solid #b9b6b6;
}

.BulkProcessingV2_container-2__3LwyU input:checked~.BulkProcessingV2_checkmark__3FMNZ {
    background-color: #fff;
    border: 2px solid #f70;
}

.BulkProcessingV2_container-2__3LwyU input:checked~.BulkProcessingV2_checkmark__3FMNZ:after {
    display: block;
}

.BulkProcessingV2_container-2__3LwyU .BulkProcessingV2_checkmark__3FMNZ:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #f70;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.BulkProcessingV2_checkmark__3FMNZ:after {
    content: '';
    position: absolute;
    display: none;
}

.BulkProcessingV2_apply-btn-section__tGHCY {
    text-align: center;
    padding: 20px;
}

.BulkProcessingV2_apply-btn__ClhOP,
.BulkProcessingV2_apply-btn-section__tGHCY button {
    background: #ff7700;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    color: #ffffff;
    width: 100%;
}

.BulkProcessingV2_add-more__1EYhB {
    position: absolute;
    right: 20px;
    font-size: 30px;
    z-index: 1;
    background: #ff7700;
    font-weight: 100;
    color: #fff;
    width: 60px;
    height: 60px;
    vertical-align: middle;
    text-align: center;
    padding: 8px;
    border-radius: 50%;
    top: 11%;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.BulkProcessingV2_add-more__1EYhB span {}

.BulkProcessingV2_background-grid__2ncml h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.5px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    margin: 20px 0px;
    color: #ff7700;
}


/* Payment Success Page Css */

.BulkProcessingV2_payment-success__RtHHm {
    padding: 0;
    height: 100vh;
}

.BulkProcessingV2_payment-success__RtHHm img {
    width: 100%;
}

.BulkProcessingV2_payment-success__RtHHm h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.BulkProcessingV2_payment-success__RtHHm p {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0 0 25px;
    color: #5f5f5f;
}

.BulkProcessingV2_payment-success__RtHHm h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0 0 15px;
    color: #ff7700;
}

.BulkProcessingV2_payment-success__RtHHm h6 span {
    color: #5f5f5f;
}

.BulkProcessingV2_payment-success-content__3JOI6 {
    padding: 0 40px;
}

.BulkProcessingV2_proceed-btn__14zdZ {
    background: #ff7700;
    border-radius: 31px;
    margin-top: 26px;
    min-width: 180px;
}


/* Popups Css */

.BulkProcessingV2_popup-content__2c5lY {
    padding: 0px 0px 20px;
}

.BulkProcessingV2_popup-content__2c5lY h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #ff7700;
    margin: 0;
}

.BulkProcessingV2_popup-content__2c5lY img {
    width: 25%;
}

.BulkProcessingV2_popup-content__2c5lY p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 27px; */
    text-align: center;
    margin: 15px 0px;
    color: #232121;
}

.BulkProcessingV2_popup-content__2c5lY p span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #999696;
}

.BulkProcessingV2_popup-content__2c5lY h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #ff7700;
}

.BulkProcessingV2_popup-content__2c5lY h6 img {
    margin-left: 10px;
    width: auto;
    transition: 0.3s ease-in-out;
}

.BulkProcessingV2_popup-content__2c5lY h6:hover img {
    margin-left: 20px;
}

.BulkProcessingV2_proceed-btn2__3uT1j {
    background: #fff;
    color: #ff7700;
    border-radius: 31px;
    margin-top: 26px;
    min-width: 180px;
    border: 1px solid #ff7700;
    box-sizing: border-box;
}

.BulkProcessingV2_proceed-btn2__3uT1j:hover {
    color: #ff7700;
}

.BulkProcessingV2_popup-content__2c5lY h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    /* color: #ff7700; */
}

.BulkProcessingV2_popup-content__2c5lY h3 span {
    color: #232121;
}

.BulkProcessingV2_bulk-bg__2m143 button {
    border-radius: 5px;
    width: auto;
    margin: 10px auto;
}

.BulkProcessingV2_bulk-bg__2m143 {
    text-align: center;
}

.BulkProcessingV2_light-item__gHj9l {
    padding: 0 !important;
}

.BulkProcessingV2_react_lightgallery_item__1sbnt {
    padding: 0 !important;
}

.BulkProcessingV2_popup-content__2c5lY h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #232121;
    margin: 0 0 25px;
}

.BulkProcessingV2_popup-content__2c5lY h4 span {
    border-bottom: 4px solid #ff7700;
    padding-bottom: 5px;
}

.BulkProcessingV2_popup-content__2c5lY ul {
    list-style: none;
    padding: 10px 0;
}

.BulkProcessingV2_popup-content__2c5lY ul li {
    display: inline-block;
    padding: 20px 5px;
}

.BulkProcessingV2_popup-content__2c5lY ul li span {
    border: 1px solid #999696;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 10px;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #999696;
}

.BulkProcessingV2_popup-content__2c5lY textarea {
    /* border: none; */
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #999696 !important;
    min-height: 100px !important;
}

.BulkProcessingV2_popup-text__2eTrP p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #5f5f5f;
}


/* 360 View Tab page csss  */

.BulkProcessingV2_sixty-link__1lW6g {}

.BulkProcessingV2_sixty-content__3H0lQ {}

.BulkProcessingV2_sixty-link-copy__2jWHW {}

.BulkProcessingV2_sixty-link-copy__2jWHW h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #232121;
}

.BulkProcessingV2_sixty-link-copy__2jWHW h4 img {
    width: 30px;
    margin-right: 10px;
}

.BulkProcessingV2_sixty-link-copy__2jWHW p input {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;
    height: 45px;
    padding: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 70%;
    color: #292929;
}

.BulkProcessingV2_sixty-link-copy__2jWHW p button {
    border-radius: 0 5px 5px 0;
    height: 45px;
    width: 30%;
    float: right;
}

.BulkProcessingV2_sixty-link-copy__2jWHW textarea {
    width: 100%;
    padding: 15px 10px;
    color: #232121;
    border: 1px solid #ddd;
    background: #f0f0f0;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px;
    height: 105px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.BulkProcessingV2_sixty-satisfy__3zjsA p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.BulkProcessingV2_download-complete__3At2h {}

.BulkProcessingV2_download-complete__3At2h img {
    width: 50%;
}

.BulkProcessingV2_download-complete__3At2h h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #232121;
}

.BulkProcessingV2_upload-image__qd8uf {
    text-align: center;
    padding: 20px 0;
}

.BulkProcessingV2_upload-image__qd8uf button img {
    width: 20px;
    margin-right: 10px;
}

.BulkProcessingV2_perfect-output__2SAsf {
    margin: 80px 0 50px;
    /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%);
    background-color: #fff; */
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.BulkProcessingV2_logo-sec__3KlHQ {
    padding: 0px 13px 23px;
    border-bottom: 1px solid #ebeaea;
    margin: 22px 0;
}

.BulkProcessingV2_logo-sec__3KlHQ span {
    color: orange;
}

.BulkProcessingV2_dealership-logo-section__1pqcM {
    width: 100%;
    /* border: 1px solid grey;
  margin: 1px auto 20px; */
    border-radius: 10px;
}

.BulkProcessingV2_important-image-aspects__2l_CM img {
    display: block;
    max-width: 100%;
}

.BulkProcessingV2_page__2VksX {
    margin: 1em auto;
    max-width: 768px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
}

.BulkProcessingV2_box__1Quxb {
    padding: 0.5em;
    width: 100%;
    margin: 0.5em;
}

.BulkProcessingV2_box-2__6vUWn {
    padding: 0.5em;
    width: calc(100% / 2 - 1em);
}

.BulkProcessingV2_options__2hKwc label,
.BulkProcessingV2_options__2hKwc input {
    width: 4em;
    padding: 0.5em 1em;
}

.BulkProcessingV2_btn__3KFYM {
    background: white;
    color: black;
    border: 1px solid black;
    padding: 0.5em 1em;
    text-decoration: none;
    margin: 0.8em 0.3em;
    display: inline-block;
    cursor: pointer;
}

.BulkProcessingV2_hide__3O5u4 {
    display: none;
}

img {
    max-width: 100%;
}

.BulkProcessingV2_button-padding__1PoC8 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: 18%;
}

.BulkProcessingV2_enhancements__2D8g- {
    font-size: 13px;
    /* margin-left: 10px; */
}

.BulkProcessingV2_enhancements__2D8g- input {
    margin-left: 12px;
    margin-right: 4px;
    vertical-align: middle;
}

.BulkProcessingV2_enhancement-title___fiIx {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
}

.BulkProcessingV2_enhancement-container__woDxp {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ebeaea;
}

.BulkProcessingV2_brightness-container__5k2H5 {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 10px 0px;
    margin-bottom: 10px !important;
    font-size: 13px;
    border-bottom: 1px solid #ebeaea;
}

.BulkProcessingV2_brightness-container__5k2H5 input {
    vertical-align: middle;
}

.BulkProcessingV2_tint-opt__1Nxov {}

.BulkProcessingV2_tint-opt__1Nxov label {}

.BulkProcessingV2_tint-opt__1Nxov input {
    margin-right: 12px;
}

@media (min-width: 1366px) {
    .BulkProcessingV2_perfect-output__2SAsf {
        max-width: 93.5%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1440px) {
    .BulkProcessingV2_perfect-output__2SAsf {
        max-width: 89%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1680px) {
    .BulkProcessingV2_perfect-output__2SAsf {
        max-width: 76.3%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1920px) {
    .BulkProcessingV2_perfect-output__2SAsf {
        max-width: 66.3%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2560px) {
    .BulkProcessingV2_perfect-output__2SAsf {
        max-width: 50%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2880px) {
    .BulkProcessingV2_perfect-output__2SAsf {
        max-width: 44.2%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

.BulkProcessingV2_backgrounds-grid__1kAkK {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 9px;
}

.BulkProcessingV2_active-background-sec__1ijff img {
    width: 100%;
    height: 75px;
    border-radius: 5px;
}

.BulkProcessingV2_bg-below-sec__1aDsN {
    justify-content: center;
    margin: 0;
    padding: 5px 60px 0;
}

.BulkProcessingV2_inactive-background-sec__137sk img {
    width: 100%;
    height: 75px;
    cursor: pointer;
    border-radius: 5px;
}

.BulkProcessingV2_back-credit__c6E8I {
    text-align: end;
    font-size: 12px;
    padding-top: 11px;
    margin-bottom: 0 !important;
}

.BulkProcessingV2_bcredit__2kx2B {
    display: none;
}

.BulkProcessingV2_back-name__2RY3W {
    font-size: 11px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
}

.BulkProcessingV2_back-credit__c6E8I img {
    width: auto !important;
    height: auto !important;
    padding-left: 4px;
    border: 0 !important;
}

.BulkProcessingV2_active-background-sec-below__2X33T {
    padding: 5px 5px 0;
    cursor: pointer;
}

.BulkProcessingV2_active-background-sec-below__2X33T img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.BulkProcessingV2_inactive-background-sec-below__2U33M img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.BulkProcessingV2_overlay_edit__27kAe {
    position: relative !important;
}

.BulkProcessingV2_overlay-img__12BQ2 {
    /* object-fit: scale-down !important; */
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black !important;
    z-index: 91;
    object-fit: contain !important;
    height: 60% !important;
    margin-top: 10%;
}

.BulkProcessingV2_lower-section-with-overlay__3rOZs {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.BulkProcessingV2_continue-btn__3xehp {
    display: flex;
    justify-content: center;
    padding: 2%;
}

.BulkProcessingV2_overlay-pos__3Yea8 {
    position: relative;
}

.BulkProcessingV2_img-overlay__2Ltx6 {
    position: absolute;
    background: linear-gradient( 0deg, rgba(62, 60, 60, 0.7), rgba(62, 60, 60, 0.7));
    height: 100%;
    bottom: 0%;
    width: 100%;
    opacity: 1;
    transition: 0.5s ease;
}

.BulkProcessingV2_more-btn-overlay__30ze1 {
    position: absolute;
    top: 32%;
    left: 15%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.BulkProcessingV2_add-more__1EYhB button {
    padding: 0 !important;
}

.BulkProcessingV2_inactive-background-sec__137sk {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
}

.BulkProcessingV2_active-background-sec__1ijff {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 3px;
    border: 2px solid #333;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
}

.BulkProcessingV2_modal-header__2DQrX {
    background-color: rgba(255, 145, 0, 0.1);
    border-radius: 5px 5px 0px 0px;
}

.BulkProcessingV2_create-project__1I5Vs {
    text-align: center;
}

.BulkProcessingV2_modal-header__2DQrX h5 {
    padding-left: 29px;
    color: #232121;
    font-weight: 500;
    padding-top: 10px;
}

.BulkProcessingV2_modal-header__2DQrX img {
    margin-right: 8px;
    width: 17%;
    margin-bottom: 3px;
}

.BulkProcessingV2_download-card-gif__eu-ip {}

.BulkProcessingV2_upload-car-name-gif__3YTWr img {
    width: 40%;
    max-height: 20px;
}

.BulkProcessingV2_apply-btn-section__tGHCY h6 {
    font-size: 18px;
    color: #2b2a2a;
    font-weight: 500;
}

.BulkProcessingV2_apply-btn-section__tGHCY p {
    font-size: 16px;
    color: #646464;
    font-weight: 400;
}

.BulkProcessingV2_apply-btn-section__tGHCY img {
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px #ddd;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    margin: 0 5px;
}

.BulkProcessingV2_btn-book__3OTz3 {
    width: auto;
    height: 45px;
    margin: 9px;
    cursor: pointer;
    background-color: #fff !important;
    border: 1px solid #a1afb7 !important;
    color: #999696;
    font-size: 14px;
    border-radius: 100px;
}

.BulkProcessingV2_btn-book-selected__3hxH- {
    width: auto;
    height: 45px;
    margin: 9px;
    cursor: pointer;
    border: 1px solid #a1afb7 !important;
    font-size: 14px;
    border-radius: 100px;
    background-color: #a1afb7;
    color: #000;
}

.BulkProcessingV2_btn-book__3OTz3:hover {
    background-color: #a1afb7 !important;
    color: #000 !important;
}

.BulkProcessingV2_choose-button__VQ7qd {}

.BulkProcessingV2_choose-button__VQ7qd button {
    padding: 10px 20px;
    margin-top: 20px;
}

.BulkProcessingV2_container-fluid__FdCGW {
    max-width: 100% !important;
    margin: 0 15px !important;
}

.BulkProcessingV2_cross-popup__1rV6e {
    cursor: pointer;
}

.BulkProcessingV2_add-more__1EYhB:hover+.BulkProcessingV2_more-text__3s-7y {
    display: block !important;
}

.BulkProcessingV2_more-text__3s-7y {
    position: absolute;
    z-index: 999;
    left: 72%;
    top: 13.5%;
    display: none;
}

.BulkProcessingV2_controls__Ljthf {
    margin-top: 325px;
}

.BulkProcessingV2_cancel-crop__-ENv5 {
    background-color: #fff !important;
    color: #ff7700;
    border: 1px solid #ff7700;
    border-radius: 10px;
    width: 108px;
    height: 40px;
    padding: 0 !important;
}

.BulkProcessingV2_cancel-crop__-ENv5:hover {
    color: #ff7700 !important;
}

.BulkProcessingV2_save-crop__VGeBl {
    background-color: #ff7700 !important;
    color: #fff;
    border: 1px solid #ff7700;
    border-radius: 10px;
    width: 108px;
    height: 40px;
    padding: 0 !important;
}

.BulkProcessingV2_crop-header__Q0DKk p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.BulkProcessingV2_crop-header__Q0DKk img {
    cursor: pointer;
    text-align: right;
}

.BulkProcessingV2_video-sec__2eqcV {
    margin-top: 30px;
    margin-bottom: 15px;
}
.Footer_footer-main__bQsYx {
  background: #0b0d17;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 50px 50px 40px;
}
.Footer_footer-main__bQsYx input {
  width: 100%;
  height: 45px;
  background: #ffffff;
  box-shadow: 10px 15px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25.5px;
  border: unset;
  padding-left: 20px;
}
.Footer_footer-main__bQsYx input:focus {
  outline: none;
}
.Footer_footer-main__bQsYx a {
  text-decoration: none;
  transition: 0.2s ease-out;
  font-weight: 100;
  vertical-align: sub;
  color: #fff !important;
}
.Footer_footer-main__bQsYx a:hover {
  text-decoration: none;
  color: #ff7700;
  margin-left: 5px;
}
.Footer_footer-main__bQsYx h6 a:hover {
  margin-left: 0;
}
.Footer_footer-logo__k4gLn {
  padding-top: 8%;
}
.Footer_footer-logo-image__26At8 {
  width: 80%;
  margin: 0 auto;
}
.Footer_social__2oG0O a {
  color: white;
}
.Footer_footer-links__1R-Ze {
}
.Footer_footer-links__1R-Ze h2,
.Footer_social__2oG0O h2 {
  font-weight: 600;
  margin: 0px 0px 15px;
  text-transform: capitalize;
}
.Footer_footer-links__1R-Ze p,
.Footer_social__2oG0O p {
  font-weight: 100;
  margin-bottom: 10px;
}
.Footer_footer-links__1R-Ze p a span {
  font-family: cursive;
  font-size: 16px;
  color: #ff3503;
  vertical-align: super;
}

.Footer_social__2oG0O p img {
  margin-right: 5px;
}

.Footer_news-letter__3iokG {
  padding-top: 7%;
}
.Footer_news-letter__3iokG p {
  font-size: 1.2rem;
}
.Footer_footer-bottom__2Hb5S {
  background-color: #000;
  padding: 10px 50px;
}
.Footer_footer-bottom__2Hb5S p,
.Footer_footer-bottom__2Hb5S p a {
  color: #d9dbe1;
  font-size: 13px;
  margin: 0;
}
.Footer_footer-bottom__2Hb5S ul {
  margin: 0;
  padding: 0;
  justify-content: right;
  text-align: right;
  width: 100%;
  list-style: none;
}
.Footer_footer-bottom__2Hb5S ul li {
  padding-left: 20px;
  display: inline-block;
}

.Footer_play-store-badge__3AklO{
  height: auto;
    margin-top: 10px;
    width: 80%;
}



@media (max-width: 800px) {
  .Footer_footer-main__bQsYx {
    font-size: 10px;
    line-height: 15px;
    color: #ffffff;
    padding: 50px 0px 30px;
  }
  .Footer_footer-main__bQsYx input {
    width: 100%;
  }

  .Footer_news-letter__3iokG {
    text-align: center;
  }
  .Footer_footer-links__1R-Ze h2,
  .Footer_social__2oG0O h2 {
    font-weight: 600;
    margin: 0px 0px 15px;
    text-transform: capitalize;
    font-size: 18px;
  }
  .Footer_footer-bottom__2Hb5S {
    padding: 15px 10px;
    text-align: center;
  }
  .Footer_footer-bottom__2Hb5S p {
    margin-bottom: 10px;
  }
  .Footer_footer-bottom__2Hb5S ul {
    text-align: center;
  }
}

@media (min-width: 1400px) {
  .Footer_news-letter__3iokG {
    padding-top: 5%;
  }
  .Footer_footer-logo__k4gLn {
    padding-top: 5%;
  }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .Footer_footer-logo-image__26At8 {
    margin-bottom: 20px;
  }
  .Footer_footer-logo__k4gLn {
    padding-top: 0;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  .Footer_footer-logo-image__26At8 {
    width: 80%;
    margin-bottom: 20px;
    object-fit: contain;
    margin-top: 35%;
  }
  .Footer_footer-logo__k4gLn {
    padding-top: 0;
    text-align: center;
  }
  .Footer_footer-links__1R-Ze h2,
  .Footer_social__2oG0O h2 {
    font-size: 25px;
  }
  .Footer_footer-links__1R-Ze p,
  .Footer_social__2oG0O p {
    font-size: 18px;
  }
  .Footer_news-letter__3iokG {
    padding-top: 4%;
  }
  .Footer_footer-main__bQsYx input {
    width: 60%;
  }
  .Footer_footer-main__bQsYx {
    padding: 50px 0px;
  }
  .Footer_footer-main__bQsYx a {
    font-size: 14px;
  }
}

a{
    text-decoration: none;
}
.CarV2_bg-white__3jeSZ{
    background: #fff;
}
.CarV2_banner-slider__1xsDS{
    padding: 10% 0 5%;
}

.CarV2_car-v2-tabs__3Uw6l{
    padding: 60px 0px 0px;
}
.CarV2_title-area__1_1Is{
    margin-bottom: 7%;
}
.CarV2_title-area__1_1Is h1{
    font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 45px; 
color: #323232;
text-align: center; 
margin: 0;
margin-bottom: 50px;
}
.CarV2_title-area__1_1Is p {
text-align: center;
}
.CarV2_title__gT0yx h6{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.2px;
color: #323232;
}
.CarV2_title__gT0yx h6 span{
    color: #FF7700;
}
.CarV2_request-demo__28VKI{
    font-style: normal;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.1px;
    background: #FF7700;
    border-radius: 10px;
    color: #FFFFFF;
    margin: 0;
    padding: 8px 25px;
    transition: .2s ease-in-out;
    border: 2px solid #FF7700;
    height: 42px;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #6c757d;
}

.CarV2_request-demo__28VKI:hover{
    border: 2px solid #FF7700;
    background: #fff;
    color: #FF7700;
}
.CarV2_car-v2-left__4oBo_{

}
.CarV2_why-use__e1G3S h1{
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 42px;   
     margin-bottom: 25px;
    letter-spacing: 0.02em;
    color: #323232;
    padding: 0;
}
.CarV2_why-use__e1G3S h1 span{
    border-bottom: 2px solid #FD8061;
    padding-bottom: 10px;
    color: #1C1E52;
}

.CarV2_car-v2-left__4oBo_ p, .CarV2_why-use__e1G3S p{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
    color: #7C7C7C;
    padding: 0;
}
.CarV2_why-tabs__1v4N5 li button.CarV2_activate__4y4vj, .CarV2_why-tabs__1v4N5 li button:hover {
  /* box-shadow: 0px 0px 20px #ff7700!important;  */
  border-left: 5px solid #FF7700;
}
.CarV2_car-v2-tabs__3Uw6l{
    position: relative;
}



.CarV2_why-section__3pwW0{
    padding: 0 5%;
}
.CarV2_why-tabs__1v4N5 li button{
    font-weight: 600!important;
    font-size: 24px!important;
    border-radius: 10px!important;
    line-height: 45px!important; 
    color: #1D293F!important;
    border-left: 5px solid #fff;
    background-color: #fff!important; 
    transition: .3s ease-in-out;
}
.CarV2_why-tabs__1v4N5 img{
    float: left;
    margin-right: 0;
    width: 11%;
    vertical-align: middle;
    margin-top: 1%;
}
.CarV2_car-v2-left__4oBo_ .CarV2_why-tabs__1v4N5 span{
   
}
.CarV2_car-v2-left__4oBo_ .CarV2_why-tabs__1v4N5 p {
   
}
.CarV2_first-tab-content__3Qcfp{
    text-align: right;
    margin-bottom: 5%!important;
}
.CarV2_why-text__3-9wK{
    width: 87%;
    float: right;
}
.CarV2_why-text__3-9wK h6{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: #1D293F;
    margin: 0;
}
.CarV2_why-text__3-9wK p{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px; 
    color: #7C7C7C;
    margin: 0;
    display: flex;
}

.CarV2_scroll-tab__2SCXw {

  }
.CarV2_why-tabs__1v4N5 li button { 
    background: #FFFFFF!important;
    box-shadow: 0px 4px 11px rgb(0 0 0 / 25%)!important;
    border-radius: 10px!important;
    margin: 0 0px 10px!important;
    padding: 15px 20px;
    text-align: left;

    width: 100%;
    height: 100%;

}
.CarV2_why-tabs__1v4N5{
    padding: 0;
    list-style: none;
    margin: 0 0 10px;
}
.CarV2_request-demo2__iWjKQ img{
    height: 44px;
    margin-left: 10px;

}
.CarV2_right-sections__2L0pl div{
   
}
.CarV2_right-sections__2L0pl{
    position: relative;  
}
.CarV2_why-tabs__1v4N5 li{
    margin: 5px 0px!important;
}
.CarV2_first-tab-content__3Qcfp img{
    width: 65%;
    height: auto;
    margin: auto;
    margin-top: 2%;
}
.CarV2_buttons-div__24fKE{
margin: 5% 0;
}


.CarV2_custom-backgrounds__1a0vN{
    padding: 60px 5% 40px;
}

.CarV2_title__gT0yx{
text-align: center;
}
.CarV2_title__gT0yx h1{
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 35px; 
color: #FF7700;
margin-bottom: 45px; 
margin-top: 0;
}
.CarV2_title__gT0yx h1 span{
    border-bottom: 2px solid #FD8061;
    color: #1C1E52;
}
.CarV2_title__gT0yx h3{
    font-style: normal;
font-weight: 600;
font-size: 33px;
line-height: 45px; 
color: #FF7700;
margin-bottom: 10px; 
margin-top: 0;
    font-weight: 600;
font-size: 33px;
line-height: 54px; 
letter-spacing: 0.1px;
}

.CarV2_custom-image__1ZNVg{

}
.CarV2_custom-image__1ZNVg img{
    width: 100%;
    box-shadow: -2px 5px 10px 0px #6c757d63;
} 
 .CarV2_custom-links__flWzG{

 }
 .CarV2_custom-image-360__ktgBd{
     
 }
 .CarV2_custom-image-360__ktgBd div, .CarV2_custom-image-360__ktgBd div iframe {
    width: 100%!important;
    box-shadow: -2px 5px 10px 0px #6c757d63;
 }
 .CarV2_custom-image-360__ktgBd div ol{
     background: #969ca1d1!important;
 }
 .CarV2_custom-image-360__ktgBd img{
     width: 100%;
     padding: 0;
 }
.CarV2_custom-backgrounds__1a0vN .CarV2_container-fluid__2wki6{
    width: 100%!important;
}
.CarV2_custom-links__flWzG{

}
.CarV2_exterior-links__2bs98 ul{
    display: flex;
    list-style: none;
    padding: 30px 0 ;
    margin: 0;
}
.CarV2_exterior-links__2bs98 ul li{
    padding: 0 5px 0;
    width: 20%;
    text-align: center;
}


.CarV2_exterior-links__2bs98 ul li button{
    background: #fff;
    padding: 0px;
    border: 2px solid #fff;
    position: relative;
    border-radius: 0;
    transition: .3s ease-in-out;
    box-shadow: 0px 2px 2px 0px #6c757d63;
}




.CarV2_custom-links__flWzG ul{
    display: block;
    list-style: none;
    padding:  15px 0 0 ;
    margin: 0;
}
.CarV2_custom-links__flWzG ul li{
    padding: 0 5px 0;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}


.CarV2_custom-links__flWzG ul li button{
    background: #fff;
    padding: 2px;
    border: 2px solid #fff;
    position: relative;
    border-radius: 0;
    transition: .3s ease-in-out; 
    box-shadow: 0px 2px 2px 0px #6c757d63;
}
.CarV2_custom-links__flWzG ul li button.CarV2_active__3P9Du, .CarV2_custom-links__flWzG ul li button:hover{
   border: 2px solid #ff7700;
}
.CarV2_custom-links__flWzG ul li button img, .CarV2_exterior-links__2bs98 ul li button img{
    width: 100%;
    
}
.CarV2_exterior-links__2bs98 ul li button img{
    height: 75px;
}
.CarV2_exterior-links__2bs98 ul li button p{
    color: #fff;
    font-size: 11px;
    padding: 3px 5px ;
    margin: 0;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
.CarV2_exterior-tabs__2HVN0  ul{
    text-align: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 25px;
}
.CarV2_exterior-tabs__2HVN0  ul li{ 
    padding: 3px;
    margin: 10px 5px;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    box-shadow: 0px 2px 3px 0px #ddd;
}
.CarV2_slates-tabs__3_gPB li{
    padding: 5px 10px!important; 
    margin: 10px!important;
}
.CarV2_exterior-tabs__2HVN0  ul li.CarV2_selected__14_VJ{
    background: #fff; 
    color: black;
    border-radius: 0;
    border: 0;
    color: #ff7700 !important;
    border-bottom: 2px solid #FF7700 !important;
    border-radius: 0;
    background: transparent;
}
.CarV2_exterior-tabs__2HVN0  ul li button{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    border-radius: 0!important;
}
.CarV2_exterior-tabs__2HVN0  ul li button.CarV2_activ__FrUYT, .CarV2_exterior-tabs__2HVN0  ul li button:hover, .CarV2_exterior-tabs__2HVN0  ul li button.CarV2_virtual__1_6MB{
   color: #ff7700 !important;
border-bottom: 1px solid #FF7700 !important;
border-radius: 0;
background: transparent;
}


.CarV2_abs-img__32C83{
    position: absolute;
    left: 33%;
    top: 20%;
    width: 30%!important;
}

.CarV2_gif-area__OaJTf{
    padding:60px 0 40px; 
}
.CarV2_no-border__1XFYd{
    border: none!important;
}

.CarV2_mac-pro-image__paFHQ{
    text-align: center;
}
.CarV2_mac-pro-image__paFHQ img{
    margin-bottom: 50px;
    width: 100%;
}


.CarV2_graph-area__2zPBA{
    padding: 60px 0px;
}
.CarV2_graph-area__2zPBA .CarV2_title__gT0yx{
    text-align: left;
    padding-top: 10%;
}

.CarV2_graph-image__3ZA_U{

}
.CarV2_graph-image__3ZA_U img{
width: 100%;
}

.CarV2_calendly-area__1Khcr{
    padding: 60px 0px; 
}

.CarV2_cars-demo__3dIow{
height: 450px;
text-align: center;
}
 .CarV2_cars-demo__3dIow p{
    margin-top: 30px;
 }
.CarV2_cars-demo__3dIow iframe{
    height: 100%;
}
.CarV2_cars-video__3qwN9{
    padding: 0px;
    border-radius: 5px;
    overflow: hidden; 
    box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
    height: 450px;
}
.CarV2_cars-calender__2SDUb{
    box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
    border-radius: 10px;
    padding: 10px;
}
.CarV2_cars-calender__2SDUb h4{
    text-align: center;
    width: 100%;
    color: #FF7700;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.CarV2_calendly-inline-widget__2Ypzw{
    width: 100%;
}
.CarV2_main__3jEYu{
padding: 50px 0px;
}
.CarV2_book-demo__2fHQ7 { 
    margin-bottom: 30px;
}
.CarV2_book-demo__2fHQ7 h1{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 800;
font-size: 27px;  
letter-spacing: 0.2px; 
color: #5D5D6D;
margin-bottom: 15px;
padding-left: 5%;
}
.CarV2_book-demo__2fHQ7 h1 span{
    color: #FF7700;
}
.CarV2_book-demo__2fHQ7 p span{ 
    color: #FF7700;
}
.CarV2_book-demo__2fHQ7 p{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.2px;
margin-bottom: 15px;

}
.CarV2_book-demo__2fHQ7 img{
    float: left;

}
.CarV2_cars-calender__2SDUb {
    box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
    border-radius: 10px;
    padding: 10px 10px 20px;
    height: 100%;
    overflow: hidden;
}
.CarV2_cars-calender__2SDUb h4 {
    text-align: center;
    width: 100%;
    color: #FF7700;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.CarV2_calendly-inline-widget__2Ypzw{
    height: 100%!important;
}
 

.CarV2_ticker-headline__C6Yw9 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px 0;
    margin: 0;
    font-size: 18px;
  }
  
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv {
    height: 100%;
    width: auto;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq {
    width: auto;
    padding-right: 50px;
    transition: 0.6s ease-in-out top;
  }
  @media all and (transform-3d), (-webkit-transform-3d) {
    .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq {
      transition: 0.6s ease-in-out;
    }
    .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq.CarV2_next__1imHY, .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq.CarV2_active__3P9Du.CarV2_right__pSarA {
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
      top: 0;
    }
    .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq.CarV2_prev__373Nh, .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq.CarV2_active__3P9Du.CarV2_left__Glj5F {
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
      top: 0;
    }
    .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq.CarV2_next__1imHY.CarV2_left__Glj5F, .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq.CarV2_prev__373Nh.CarV2_right__pSarA, .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_item__39-Pq.CarV2_active__3P9Du {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      top: 0;
    }
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_active__3P9Du,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_next__1imHY,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_prev__373Nh {
    display: block;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_active__3P9Du {
    top: 0;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_next__1imHY,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_prev__373Nh {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_next__1imHY {
    top: 100%;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_prev__373Nh {
    top: -100%;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_next__1imHY.CarV2_left__Glj5F,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_prev__373Nh.CarV2_right__pSarA {
    top: 0;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_active__3P9Du.CarV2_left__Glj5F {
    top: -100%;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-inner__3DaAv > .CarV2_active__3P9Du.CarV2_right__pSarA {
    top: 100%;
  }
  
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj {
    left: auto;
    width: 50px;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj.CarV2_up__2miKL {
    top: 0;
    right: 0;
    bottom: 50%;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj.CarV2_down__A2kVO {
    top: 50%;
    right: 0;
    bottom: 0;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-prev__1oesr,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-next__3NHYV,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_glyphicon-chevron-up__TBL9X,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_glyphicon-chevron-down__IpqhC {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-prev__1oesr,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_glyphicon-chevron-up__TBL9X {
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-next__3NHYV,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_glyphicon-chevron-down__IpqhC {
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-up__3MAY0,
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-down__13KC- {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-prev__1oesr:before {
    content: "‹";
  }
  .CarV2_carousel__2ioug.CarV2_vertical__3q78y .CarV2_carousel-control__2GMKj .CarV2_icon-next__3NHYV:before {
    content: "›";
  }


  .CarV2_slide-images__SLYFz .CarV2_item__39-Pq img{
      width: 100%;
      height: 100vh;
  }

  .CarV2_calendly-area__1Khcr .CarV2_title__gT0yx{
      margin-bottom: 25px;
  }
.CarV2_calendly-area__1Khcr .CarV2_title__gT0yx h1{
    margin-bottom: 0;
}
.CarV2_slide-images__SLYFz img{
    max-height: calc(100vh - 65px);
 object-fit: cover;
}

/* Virtual section  */
.CarV2_virtual-images__1xAY_{

}
.CarV2_virtual-images__1xAY_ img{
    width: 100%;
}
 
.CarV2_slates-tabs__3_gPB li.CarV2_selected__14_VJ{
border-top: 0px!important;
}

.CarV2_v-image__1SD8K{
    padding: 0 15px!important;
}
.CarV2_banner-text__1sUai{
margin-top: 5%;
text-align: left;
}
.CarV2_banner-text__1sUai h1{
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: #1C1E52;
    margin: 0 0 25px;
    letter-spacing: 1px;
}
.CarV2_banner-text__1sUai p{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 45px; 
    color: #444444;
}
.CarV2_banner-text__1sUai p span{
color: #FF7700;
}

.CarV2_banner-gif__2pwGG img{
    width: 100%;
    position: relative;
}
.CarV2_banner-gif__2pwGG{
    
}
.CarV2_banner-gif__2pwGG .CarV2_mac-gif__2-Dj6{
    position: absolute;
    width: 56.1%; 
    right: 10.2%;
    top: 6%;
}
.CarV2_banner-text__1sUai p a img{
    width: 250px; 
}
.CarV2_product-hunt__J1qNv img{
    width: 250px;  
    margin: 0 0 0 10px;
}

/* History section CSS  */
.CarV2_history__3s8r5{

}
.CarV2_history-title__2GxwI{
  
}
.CarV2_history-title__2GxwI h3{
    font-weight: 600;
    font-size: 18px;
    line-height: 210%;
    letter-spacing: 0.5px;
    color: #232121;
    border-right: 1.5px solid #C4C4C4;
    margin-top: 20%;
    text-align: center;
    padding: 10px 0;
}
.CarV2_history-title__2GxwI h3 span{
  border-bottom: 4px solid #FF7700;
  padding: 5px 0;
}
.CarV2_upload-history__2CMYj{
    background: #FAFAFA;
    box-shadow: 0px 6px 20px rgb(0 0 0 / 20%);
    border-radius: 0px 0px 20px 20px;
    padding: 20px;
}
.CarV2_history-img__2JtJk{
  
 
}
.CarV2_history-img__2JtJk img{
width: 100%;
margin-bottom: 10px;
border: 1.5px dashed transparent;
transition: .2s ease-in-out;
}
.CarV2_history-img__2JtJk:hover img{
    box-shadow: 0px 2px 10px 0px rgb(255 152 0 / 48%);
      border: 1.5px dashed #ff980061;
  }
  .CarV2_history-img__2JtJk:hover h5, .CarV2_history-img__2JtJk:hover p{
      color: #ff7700;
  }
.CarV2_history-img__2JtJk h5{
  font-weight: 600;
  font-size: 12px;
  line-height: 79.5%; 
  text-align: center;
  letter-spacing: 0.2px; 
  color: #232121;
  margin-bottom: 10px;
  transition: .2s ease-in-out;
}
.CarV2_history-img__2JtJk p{
  font-weight: normal;
font-size: 10px;
line-height: normal;
line-height: initial; 
text-align: center;
letter-spacing: 0.2px; 
color: #000000;
margin: 0;
transition: .2s ease-in-out;
}
.CarV2_history-img-row__3EY5w{
    padding-right: 30px;
}
.CarV2_arrow-history__3ywt5{
    padding: 8% 0 0 15px; 
}
.CarV2_arrow-history__3ywt5 span{
    color: #2E3A59;
    border: 2px solid #2E3A59;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50%;
    padding: 1px 6px;
}
.CarV2_arrow-history__3ywt5 span a{
    color: #2E3A59;
    text-decoration: none;
}
.CarV2_no-upload-message__QmeVU{
    text-align: center;
  }

















/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 360px){
    .CarV2_banner-text__1sUai {
        margin-top: 0%;
    }
    .CarV2_applestore__YTOaS img {
        margin-top: 0;
    }
    .CarV2_request-demo__28VKI {
        min-width: 25%;
    }
    .CarV2_cars-demo__3dIow { 
        margin-top: 0px;
    }
}
@media screen and (max-width: 360px){
    .CarV2_banner-text__1sUai {
        margin-top: 10%;
    }
    .CarV2_applestore__YTOaS img{
        margin-top: 20px;
      }
    .CarV2_request-demo__28VKI {
        min-width: 46%;
    }
    .CarV2_cars-demo__3dIow { 
        margin-top: 30px;
    }
}

@media screen and (max-width: 767px) and (min-width: 200px){
    .CarV2_title-area__1_1Is h1 {
        display: none;
    }
    .CarV2_mac-pro-image__paFHQ img { 
        width: 100%;
        margin-bottom: 20px;
    }
    .CarV2_custom-links__flWzG ul li button img{
        width: 100%;
    }
    .CarV2_abs-img__32C83 {
        top: 18%;
        width: 30%!important;
    }
    .CarV2_graph-image__3ZA_U img{
    width: 100%;

    }
    .CarV2_graph-area__2zPBA .CarV2_title__gT0yx {
        margin-bottom: 0px;
        text-align: center;
        padding-top: 0%;
        order: 2;
    }
    .CarV2_title__gT0yx h1 { 
        font-size: 17px;
        line-height: 30px; 
    margin-bottom: 20px; 
    }
    .CarV2_request-demo__28VKI{
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
        width: auto;
        line-height: normal;
        line-height: initial;
        height: 40px;
        margin: 0 auto;
        
}
.CarV2_graph-area__2zPBA {
    padding: 0 0 ;
} 
.CarV2_custom-links__flWzG ul li {
    padding: 0px 5px 0;
    margin-bottom: 5px;
} 
.CarV2_custom-backgrounds__1a0vN {
    padding: 40px 0px 20px;
}
.CarV2_request-demo2__iWjKQ img {
    height: 40px;
}
.CarV2_car-v2-left__4oBo_ .CarV2_why-tabs__1v4N5 span{
font-size: 18px;
line-height: normal;
line-height: initial;
}
.CarV2_car-v2-left__4oBo_ .CarV2_why-tabs__1v4N5 p{
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: normal;
    line-height: initial;
    }
.CarV2_why-tabs__1v4N5 li button { 
    padding: 10px;
}
.CarV2_why-tabs__1v4N5 img { 
    width: 15%;
    margin-top: 10px;
}
.CarV2_why-tabs__1v4N5 li+li button img{
    margin-top: 7%;
}
.CarV2_car-v2-left__4oBo_  p { 
    text-align: center;
}
.CarV2_car-v2-tabs__3Uw6l{
    padding: 0;
    margin-bottom: 20px; 
}
.CarV2_title-area__1_1Is{
margin: 0;
}
.CarV2_car-v2-left__4oBo_ {
    order: 2;
    padding: 0;
}
.CarV2_why-use__e1G3S h1{
font-size: 18px;
margin-bottom: 10px;
}
.CarV2_right-sections__2L0pl div {
    margin: 0;
    text-align: center;
}
.CarV2_car-v2-left__4oBo_ p, .CarV2_why-use__e1G3S p{
    font-size: 12px;
    line-height: inherit;
    margin-bottom: 15px;
}

.CarV2_first-tab-content__3Qcfp img { 
    width: 50%;
    margin: 3% auto 5%;
}
.CarV2_gif-area__OaJTf {
    padding: 0px;
}
.CarV2_banner-slider__1xsDS {
    margin-bottom: 0;
    margin-top: 55px;
    padding-bottom: 0;
}
.CarV2_why-text__3-9wK {
    width: 80%;
    float: right;
}
.CarV2_why-text__3-9wK h6 { 
    font-size: 18px;
}
.CarV2_graph-image__3ZA_U{
    padding: 0 30px;
}
.CarV2_mac-pro-image__paFHQ { 
    padding: 0 25px;
}
.CarV2_custom-image__1ZNVg {
    padding: 0;
    margin: 10px 0;
}
.CarV2_cars-demo__3dIow {
    height: auto;
    text-align: center; 
}
.CarV2_title__gT0yx h6 { 
    font-size: 12px; 
    margin: 0;
}
.CarV2_cars-video__3qwN9{
    height: auto;
    margin-bottom: 25px!important;
}

.CarV2_calendly-area__1Khcr {
    padding: 60px 0px;
    margin-bottom: 0px;
}
.CarV2_custom-links__flWzG ul li button p {
    color: #323232;
    font-size: 9px;
}
.CarV2_custom-links__flWzG {
    padding: 0 5px;
}
.CarV2_scroll-tab__2SCXw .CarV2_why-use__e1G3S{
    padding: 0;
    margin: 0;

    }
    .CarV2_custom-links__flWzG ul { 
        padding: 0 0 0; 
        display: flex;
    }
    .CarV2_exterior-tabs__2HVN0  ul {
        display: flex;
        padding: 0 10px;
    }
    .CarV2_exterior-tabs__2HVN0  ul li {
        margin: 5px 5px 0!important;
        font-size: 12px;
        padding: 2px!important;
    }
    .CarV2_exterior-tabs__2HVN0  ul li button {
        font-size: 12px;
    }
    .CarV2_exterior-links__2bs98 ul {
        padding: 10px 0;
        width: 100%;
        overflow: auto;
        display: block;
    }
    .CarV2_exterior-links__2bs98 ul li{ 
    width: 12.5%; 
    display: inline-block;
    margin-bottom: 10px;
}
.CarV2_exterior-links__2bs98 ul li button img {
    height: 40px;
}
.CarV2_exterior-links__2bs98 ul li button p {
    color: #fff;
    font-size: 8px;
    }

.CarV2_virtual-tabs__1wm3E{
display: block!important;
}
.CarV2_custom-bg__1cNQI{
    margin-bottom: 10px;
}
.CarV2_virtual-sm__35VaP {
    display: block!important;
    padding: 0!important;
}
 
.CarV2_banner-text__1sUai {
   
    text-align: center;
    order: 2;
}
.CarV2_banner-text__1sUai h1 { 
    font-size: 20px;
    line-height: normal;
    line-height: initial; 
    margin: 0 0 15px;
}
.CarV2_banner-text__1sUai p { 
    font-size: 12px;
    line-height: normal;
    line-height: initial; 
}
.CarV2_why-section__3pwW0 {
    padding: 20px 5% 0;
}
.CarV2_banner-gif__2pwGG .CarV2_mac-gif__2-Dj6 { 
    width: 54%;
    right: 11.5%;
    top: 6%;
}
.CarV2_product-hunt__J1qNv img {
    margin: 0px 0 0 10px;
    width: 150px;
}

  .CarV2_virtual-images__1xAY_ {
    margin-bottom: 10px;
}
.CarV2_why-use__e1G3S .CarV2_product-hunt__J1qNv img{
    margin: 20px 0 0 10px!important;
    width: 150px;
}
}

@media screen and (max-width: 900px) and (min-width: 768px){
.CarV2_graph-area__2zPBA {
    padding: 0 0 60px!important;
}
.CarV2_first-tab-content__3Qcfp img {
    width: 100%!important;
    margin: 0 auto;
    margin-top: 30%!important;
}
.CarV2_car-v2-left__4oBo_ {
        order: 1!important; 
}
.CarV2_right-sections__2L0pl{
    order: 2;
}
.CarV2_graph-area__2zPBA .CarV2_title__gT0yx{
    order: 1!important;
    padding-top: 8%;
    margin: 0;
}
.CarV2_graph-image__3ZA_U{
    order: 2;
}
.CarV2_first-tab-content__3Qcfp img {
    width: 85%;
    margin-top: 10%;
}
.CarV2_cars-video__3qwN9{
    height: 350px!important;
}
.CarV2_cars-demo__3dIow {
    height: 350px!important; 
    margin-top: 0!important;
}
.CarV2_calendly-area__1Khcr {
    padding: 60px 0px;
    margin-bottom: 0px;
}
.CarV2_title-area__1_1Is p {
    display: none;
}
.CarV2_banner-text__1sUai {
    margin-top: 5%;
    text-align: left;
    order: inherit;
}
.CarV2_banner-text__1sUai .CarV2_request-demo__28VKI {
    margin: 0 0;
}
    
.CarV2_virtual-tabs__1wm3E {
    /* margin-bottom: 10px; */
}
.CarV2_graph-area__2zPBA .CarV2_title__gT0yx {
    text-align: left!important; 
}
.CarV2_banner-gif__2pwGG img { 
    margin-top: 15%;
}
.CarV2_banner-text__1sUai h1 { 
    font-size: 32px;
}
.CarV2_banner-text__1sUai p a img {
    width: 170px;
}
.CarV2_car-v2-left__4oBo_ p, .CarV2_why-use__e1G3S p { 
    font-size: 13px;
    line-height: 20px;
}
.CarV2_why-section__3pwW0 {
    padding: 0 1%;
}
.CarV2_product-hunt__J1qNv img {
    width: 155px;
    margin: 0px 0 0 10px!important;
}

.CarV2_request-demo__28VKI { 
    margin-bottom: 20px; 
}
.CarV2_gif-area__OaJTf{
    padding: 0;
}

}


@media screen and (max-width: 1200px) and (min-width: 901px){
    .CarV2_why-text__3-9wK p { 
        font-size: 12px;
        line-height: normal;
        line-height: initial; 
}
.CarV2_why-text__3-9wK h6 {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
}
.CarV2_first-tab-content__3Qcfp img {
    width: 75%;
}
.CarV2_custom-links__flWzG ul li {
    padding: 30px 20px 0;
}
.CarV2_graph-area__2zPBA {
    padding: 0 0 50px;
}



}

.CarsThreeSixty_video-upload__1-ppq {
}
.CarsThreeSixty_video-upload__1-ppq h6 {
  background: rgba(252, 121, 26, 0.26);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: #393939;
  font-family: 'Poppins';
  border-radius: 0 10px 10px 0;
  padding: 0px 0px 0 15px;
  margin: 20px auto 35px;
}
.CarsThreeSixty_video-upload__1-ppq h6 button {
  background: #ff7700;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  float: right;
  padding: 7px 15px;
}

.CarsThreeSixty_video-upload__1-ppq h6 span {
  font-size: 13px;
}

/* CarsThreeSixtyVideo  */
.CarsThreeSixty_video-section__2QXed {
  padding: 100px 0 60px;
}
.CarsThreeSixty_video-title__uSrY3 {
  margin-bottom: 35px;
}
.CarsThreeSixty_video-title__uSrY3 h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 150%;
  text-align: center;

  color: #232121;
}
.CarsThreeSixty_video-title__uSrY3 p {
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #232121;
}
.CarsThreeSixty_video-area-right__1r36E {
  padding: 20px;
  -webkit-filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
  background: #fff;
  border-radius: 5px;
}
.CarsThreeSixty_video-area-simple__3BM6u {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}
.CarsThreeSixty_video-area-simple__3BM6u p {
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  padding: 5px 0 0;
}
.CarsThreeSixty_video-area-simple__3BM6u h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin-bottom: 30px;

  color: #232121;
}
.CarsThreeSixty_video-area-simple__3BM6u img {
  width: auto;
  margin: 0 auto 20px;
}

.CarsThreeSixty_create-three-sixty__j2mz1 {
  padding: 100px 0 60px;
}
.CarsThreeSixty_notification__1D7vx {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
  color: rgba(244, 6, 6, 0.75);
}
.CarsThreeSixty_create-three-sixty__j2mz1 .CarsThreeSixty_video-area-simple__3BM6u h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 20px 0 10px;
}
.CarsThreeSixty_create-three-sixty__j2mz1 .CarsThreeSixty_video-area-simple__3BM6u p {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #55575b;
}
.CarsThreeSixty_create-three-sixty__j2mz1 .CarsThreeSixty_video-area-simple__3BM6u p span {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsThreeSixty_create-three-sixty__j2mz1 .CarsThreeSixty_video-area-simple__3BM6u button {
  -webkit-filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
  width: 150px;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  margin: 0px auto 0;
}
.CarsThreeSixty_highlighter-box__3b4w4 {
}
.CarsThreeSixty_highlighter-box-single__2Q3xk {
  /* height: 150px; */
}

.CarsThreeSixty_after-upload__1OgE7 {
  margin-top: 100px;
  margin-left: 50px;
}

.CarsThreeSixty_wrapper__3RGxq {
  width: 600px;
  max-width: 90%;
  /* background:black; */
  padding: 1rem;
  border-radius: 4px;
  /* margin:auto;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 5px 10px rgba(0,0,0,.3); */
}

.CarsThreeSixty_no-reupload__39O86 {
  background-color: #fff;
  border: 1px solid #ff7700;
  color: #ff7700 !important;
  width: 155px !important;
  margin-right: 25px !important;
}
.CarsThreeSixty_upload-btn-rotate__2nOMG{
  background-color: white;
}
/* CarsThreeSixtyVideo  */
.CarsThreeSixtyOutput_video-section__N1ChR {
  padding: 100px 0 60px;
}
.CarsThreeSixtyOutput_video-title__17VMf {
  margin-bottom: 35px;
}
.CarsThreeSixtyOutput_video-title__17VMf h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 150%;
  text-align: center;

  color: #232121;
}
.CarsThreeSixtyOutput_video-title__17VMf p {
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #232121;
}
.CarsThreeSixtyOutput_video-area-right__1dk0c {
  padding: 20px;
  -webkit-filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
  background: #fff;
  border-radius: 5px;
}
.CarsThreeSixtyOutput_video-area-simple__g9l8V {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}
.CarsThreeSixtyOutput_video-area-simple__g9l8V p {
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  padding: 5px 0 0;
}
.CarsThreeSixtyOutput_video-area-simple__g9l8V h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin-bottom: 30px;

  color: #232121;
}
.CarsThreeSixtyOutput_video-area-simple__g9l8V img {
  width: auto;
  margin: 0 auto 20px;
}

.CarsThreeSixtyOutput_create-three-sixty__3loKV {
  padding: 60px 0 60px;
}

.CarsThreeSixtyOutput_create-three-sixty__3loKV .CarsThreeSixtyOutput_video-area-simple__g9l8V h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 20px 0 10px;
}
.CarsThreeSixtyOutput_create-three-sixty__3loKV .CarsThreeSixtyOutput_video-area-simple__g9l8V p {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #55575b;
}
.CarsThreeSixtyOutput_create-three-sixty__3loKV .CarsThreeSixtyOutput_video-area-simple__g9l8V p span {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsThreeSixtyOutput_create-three-sixty__3loKV .CarsThreeSixtyOutput_video-area-simple__g9l8V button {
  -webkit-filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
  min-width: 150px;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin: 15px auto 0;
  border: 2px solid #ff7700;
}
.CarsThreeSixtyOutput_highlighter-box__8h9Ar {
}
.CarsThreeSixtyOutput_highlighter-box-single__i3kHU {
  height: 150px;
}
.CarsThreeSixtyOutput_no-reupload__ANAkC {
  background: #fff;
  color: #ff7700 !important;
  border: 2px solid #ff7700;
  padding: 7px 20px;
}
.CarsThreeSixtyOutput_video-area-simple__g9l8V img {
  width: 100%;
}
.CarsThreeSixtyOutput_video-area-simple__g9l8V p button {
  margin: 15px 30px 0 !important;
}
.CarsThreeSixtyOutput_video-title__17VMf button {
  padding: 8px 20px;
}
.CarsThreeSixtyOutput_video-title__17VMf button img {
  width: 25px;
}
.CarsThreeSixtyOutput_video-area-simple__g9l8V p b {
  font-weight: 600;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}
.CarsThreeSixtyOutput_video-gif__WKaGL img {
  width: 30%;
  margin: 10px auto 20px;
}
.CarsThreeSixtyOutput_notification__qQXEh {
  font-style: normal;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 21px !important;
  color: rgba(244, 6, 6, 0.75) !important;
  margin: 0;
}
.CarsThreeSixtyOutput_i-frame-360__2LaQc{
  text-align: center;
}
.CarsThreeSixtyOutput_i-frame-360__2LaQc iframe{
  width:100%;
height: 640px;

  
}
body{
    /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Landing+Page+%E2%80%93+1%402x.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 100vh !important; */
}
.CarsLanding_landing-main-wrapper__F4U5D{
    padding: 200px 40px;
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Landing+Page+%E2%80%93+1%402x.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 100vh !important;
}
.CarsLanding_mobile-view__1jBkr{
    display: none !important; 
}
.CarsLanding_left-section__3WBC3{
    padding-left: 100px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
}
.CarsLanding_banner-head__kIEAk{
    color: black;
    font-family: 'Inria Sans';
    font-size: 40px;
    letter-spacing: -1.37px;
    margin: 0px;
    padding: 0px;
    font-weight: bolder;
}
.CarsLanding_automated__1jITH{
    margin-top: 20px;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 20px;

}
.CarsLanding_email-input__3iJPb {
    height: 48px !important;
    border-radius: 40px !important;
    padding-left: 30px !important;
    color: #495057 !important;
    max-width: 365px !important;
}
.CarsLanding_free-trail-button__3xRbF{
    height: 46px;
    border-radius: 40px;
    border: unset;
    background-color: #0579DF;
    color: white;
    padding: 0px 20px;
    font-family: 'Roboto';
    cursor: pointer;
    margin-left: -27.5% !important;
    margin-top: 1px;
}
.CarsLanding_free-trail-button__3xRbF:disabled{
    background-color: #C6E4FF !important;
    opacity: 1 !important;
}
.CarsLanding_image-cnt__2ev-R{
    color: grey;
    opacity: 0.8;
    margin: 0px;
    margin-top: 10px;
    font-size: 16px;
}
.CarsLanding_try-now-button__2ZgY2{
    position: static;
    position: initial;
    width: 200px;
    margin: 0px !important;
    height: 45px;
    border-radius: 40px;
    border: unset;
    background-color: #0579DF;
    color: white;
    padding: 0px 20px;
    font-family: 'Roboto';
    cursor: pointer;
}
.CarsLanding_seo-content-sec__2Os5y{
    background-color: white !important;
    margin-top: 50px;
    font: normal normal 'Inria';
    font-size: 12px;
    color: #242424;
    opacity: 1;
    color: #979BA1;
    letter-spacing: 0.72px;
}
.CarsLanding_paraaa__1Uv2V{
    margin-top: 20px;
    color: #4A4A82;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
   text-align: justify;
    max-width: 500px;
}

@media(max-width:800px){
    body{
        background-repeat: repeat-y !important;
    }
    .CarsLanding_web-view__uZltO{
        display: none !important;
    }
    .CarsLanding_mobile-view__1jBkr{
        display: block !important;
    }
    .CarsLanding_landing-main-wrapper__F4U5D{
        padding: 100px 0px !important;
        padding-bottom: 50px !important;
    }
    .CarsLanding_mob-banner-section__2qoY0 {
        margin: 0px auto;
        padding: 0px 20px;
        display: block !important;
    }
    .CarsLanding_para-heading__16fmg {
        font-size: 24px;
        text-align: center;
        color: black;
        margin-top: 40px;
        font-family: 'Inria Sans';
    }
    .CarsLanding_automated__1jITH {
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        margin-top: -3%;
        color: black;
    }
    .CarsLanding_mob-gif-image__3yZOn {
        border-radius: 10px;
        margin-top: 30px;
    }
    .CarsLanding_banner-section-2-bottom-para-1__3WEF3 {
        color: #000000;
        font-size: 20px;
        letter-spacing: -0.72px;
        font-family: 'Roboto';
        font-weight: 400;
        text-align: center;
        margin-top: 30px;
    }
    .CarsLanding_mob-banner-section-2-bottom__2dVlP button {
        height: 48px;
        border-radius: 5px;
        right: 10px;
        border: unset;
        background-color: #0579DF;
        color: white;
        padding: 0px 20px;
        font-family: 'Inria Sans';
        width: 100%;
        margin-top: 15px;
    }
    .CarsLanding_try-now-mob__IyIGK{
        height: 48px;
        border-radius: 5px;
        right: 10px;
        border: unset;
        background-color: #0579DF;
        color: white;
        padding: 0px 20px;
        font-family: 'Inria Sans';
        /* margin-left: -10%; */
        width: 100%;
        margin-top: 15px;
    }
    .CarsLanding_mob-email-input__2s9eS {
        border-radius: 5px !important;
        padding: 0px auto !important;
        height: 48px !important;
    }
    .CarsLanding_image-processing__2H5oU {
        color: grey;
        opacity: 0.8;
        margin: 0px;
        margin-top: 10px;
        font-size: 16px;
        text-align: center;
        padding-bottom: 20px;
    }
}
.SkyReplacement_real-estate__Z_3o0{
    padding: 20px 0% 60px;
}
.SkyReplacement_real-estate-image-area__1kGPu{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    margin: 0;
    padding: 20px 10px;
    height: 630px;
 
}
.SkyReplacement_real-estate-process__1QHII{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    margin: 0;
    height: 100%;
    padding: 20px 10px;
   
 
}
.SkyReplacement_sky-image-inner__2eIH7{

}
.SkyReplacement_real-estate-image-area__1kGPu div:nth-child(1){
    height: 90%;
    background-repeat: no-repeat;
    background-size: cover;
}
.SkyReplacement_real-estate-image-area__1kGPu div.SkyReplacement_mt-0__3-GJo{
    height: 100%!important;
    background-repeat: no-repeat;
    background-size: cover;
}
.SkyReplacement_real-estate-image-area__1kGPu img{
    width: 100%!important;
    height: 100%;
    object-fit: contain;
}
.SkyReplacement_sky-header-section__2qswt{
margin-top: 100px;
text-align: center;
}
.SkyReplacement_sky-header-section__2qswt h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #1C1E52;
    margin: 0px;
    padding: 0px;
}
.SkyReplacement_sky-header-section__2qswt p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 45px;
    color: #444444;
    margin: 0px;
    padding: 0px;
}
.SkyReplacement_sky-header-section__2qswt p span{
    color: #FF7700;
}

.SkyReplacement_display-image__1JiFO{
    height: 90%;
    background-repeat: no-repeat;
    background-size: cover;
}
.SkyReplacement_display-image__1JiFO img{
    height: 100%;
    object-fit: contain;
}

.SkyReplacement_category-options__1BumN, .SkyReplacement_category-options2__26Fh6{

}
.SkyReplacement_category-options__1BumN ul{
width: 100%;
display: flex;
list-style-type: none;
padding: 0;
}
.SkyReplacement_category-options2__26Fh6 ul{
width: 100%;
display: block;
list-style-type: none;
padding: 0;
}
.SkyReplacement_category-options__1BumN ul li{
    margin-right: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    width: 50px;
    height: 45px;
    text-align: center;
}
.SkyReplacement_category-options2__26Fh6 ul li{
    margin-right: 0;
    background: #FFFFFF;
    border-radius: 50%;
    width: 20%;
    height: 45px;
    text-align: center;
    display: inline-block;
    height: auto;
}
.SkyReplacement_category-options__1BumN ul li img, .SkyReplacement_category-options2__26Fh6 ul li img{
    width: auto;
    height: auto;
    padding: 5px;
}
.SkyReplacement_category-options__1BumN ul li button {
    background: #FFFFFF;
border: 1px solid #fff;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
padding:0;
width: 48px;
    height: 48px;
}
.SkyReplacement_category-options2__26Fh6 ul li button {
    background: none;
    border-radius: 0;
    padding: 0;
}
.SkyReplacement_category-options__1BumN ul li button.SkyReplacement_active__3xQuF { 
border: 1px solid #FF3503; 
}

.SkyReplacement_mybtn__22VZZ{
    background: #FF3503;
border-radius: 20px;
width: 150px;
height: 45px;
}
.SkyReplacement_brush__3G34y{
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval.png'), auto;
    
}

.SkyReplacement_brush1__1AxtK {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(1).png'), auto;
}
.SkyReplacement_brush2__3w6IW {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(2).png'), auto;
}

.SkyReplacement_category-options2__26Fh6 ul li span{
    color: #fff;
    position: absolute;
    margin-left: -22px;
    font-size: 10px;
    background: #333;
    padding: 2px 6px;
    border-radius: 50%;
}

.SkyUseCases_tab-section__3ldsA{
    display: block;
    justify-content: center;
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
  .SkyUseCases_tab-section__3ldsA button{
    border: unset;
    background: #FFFFFF;
    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 13px;
    padding: 12px 45px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    margin-left: 10px;
    display: inline-block;
    margin-bottom: 20px;
  }
  .SkyUseCases_tab-section__3ldsA button:hover{
      color: #272727 !important;
  }
  
  .SkyUseCases_active-usecase-button__1a0lc{
      border: unset;
      background: #FF7700 !important;
      border: 1px solid #9C9C9C;
      box-sizing: border-box;
      box-shadow: none !important;
      border-radius: 13px;
      padding: 12px 45px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF !important;
      margin-left: 24px;
  }
  #SkyUseCases_disabled-button__34pPH{
      margin: 0px !important;
      padding: 0px !important;
      border: unset !important;
      background: transparent;
      box-shadow: unset;
      width: 216px;
      margin-left: 24px !important;
      margin-top: -10px !important;
      opacity: 0.5;
  }
  #SkyUseCases_disabled-button__34pPH img{
      width: 100%;
  }
  @media(max-width:800px){
      .SkyUseCases_tab-section__3ldsA{
          display: grid;
          grid-template-columns: repeat( 4, 1fr );
          grid-gap: 10px !important;
          grid-row-gap: 10px !important;
          padding: 0px 20px;
      }
      .SkyUseCases_tab-section__3ldsA button{
          grid-column:  span 2;
      }
      .SkyUseCases_tab-section__3ldsA button:nth-last-child(1):nth-child(odd) {
          grid-column: 2 / span 2;
      }
      .SkyUseCases_tab-section__3ldsA button{
          border: unset;
          background: #FFFFFF;
          border: 1px solid #9C9C9C;
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 13px;
          padding: 10px 7px !important;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          color: #000000;
          width: auto;
          margin-left: 0px;
      }
      .SkyUseCases_tab-section__3ldsA button:hover{
          color: #272727 !important;
      }
      .SkyUseCases_active-usecase-button__1a0lc{
          border: unset;
          background: #FFFFFF;
          border: 1px solid #9C9C9C;
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 13px;
          padding: 10px 7px !important;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          color: #FFFFFF !important;
          width: auto;
          margin-left: 0px;
      }
      #SkyUseCases_disabled-button__34pPH{
          margin: 0px !important;
          padding: 0px !important;
          border: unset !important;
          background: transparent;
          box-shadow: unset;
          width: auto;
      }
      #SkyUseCases_disabled-button__34pPH img{
          width: 100%;
      }
  }
body{
    font-family: 'Poppins';
}
.ScalingMargins_upload-section__76v4g{
    border: 1px dashed #696969;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 0px;
    margin: 0;
    text-align: center;
}
.ScalingMargins_para-1__1IN_f, .ScalingMargins_para-2__H5AWl{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000000;
    margin: 0;
    padding: 0;
}
.ScalingMargins_category-buttons__3ED0p{
    display: flex;
    justify-content: space-around;
}
.ScalingMargins_inactive-button__2c73Y, .ScalingMargins_inactive-button__2c73Y:hover{
    width: 112px;
    height: 29px;
    border: 1px solid #707070;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0;
    background-color: white !important;
    color: #000000 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}
.ScalingMargins_active-button__3FOHQ, .ScalingMargins_active-button__3FOHQ:hover,.ScalingMargins_active-button__3FOHQ:focus{
    width: 112px;
    height: 29px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0;
    background-color: white !important;
    color: #000000 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border: 1px solid #ff7700 !important;
}
.ScalingMargins_marketplaces__1DBSo{
    display: flex;
    justify-content: space-around;
}

.ScalingMargins_marketplaces__1DBSo img{
    width: 100%;
    width: 60px;
    height: auto;
    cursor: pointer;
}
.ScalingMargins_input-left__3_QWF{
    width: 83px !important;
}
.ScalingMargins_input-right__2u6cz{
    max-width: 60px !important;
}
.ScalingMargins_processing__1O9Sx{
    text-align: center;
}
@media(max-width:800px){
    .ScalingMargins_category-buttons__3ED0p{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
    }
    .ScalingMargins_category-buttons__3ED0p button:nth-last-child(1):nth-child(odd) {
        grid-column: 1 / span 2;
        margin-top: 20px;
    }
    .ScalingMargins_inactive-button__2c73Y, .ScalingMargins_inactive-button__2c73Y:hover{
        width: 100px;
        font-weight: 500;
    }
    .ScalingMargins_active-button__3FOHQ, .ScalingMargins_active-button__3FOHQ:hover,.ScalingMargins_active-button__3FOHQ:focus{
        width: 100px;
        font-weight: 500;
    }
    .ScalingMargins_marketplaces__1DBSo{
        display: grid;
        grid-template-columns: auto auto auto;
    }
    .ScalingMargins_input-left__3_QWF{
        width: 75px !important;
    }
    .ScalingMargins_input-right__2u6cz{
        max-width: 50px !important;
    }
    
}
body{
    font-family: 'Poppins';
}
.ModelLanding_common-header__1afZi{
    margin-top: 100px;
}
.ModelLanding_back-button__3lJCr{
    padding-top: 100px !important;
    background-color: #fff;
    border: unset;
    border-radius: 11px;
    justify-content: left;
  }
.ModelLanding_common-header__1afZi h1{ 
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #1C1E52;
    margin: 0px;
    padding: 0px;
}
.ModelLanding_common-header__1afZi p{ 
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 45px;
    color: #444444;
    margin: 0px;
    padding: 0px;
}
.ModelLanding_common-header__1afZi span{
    color: #FF7700;
}
.ModelLanding_image-upload__1vU-o {
    margin-bottom: 5%;
    /* display: flex;
    flex-direction: row; */
}
.ModelLanding_left-box__2igm1{
    margin-top: 8%;
}

.ModelLanding_drag-text__3XmYg{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#FF7700;
}
.ModelLanding_browse-image-text__1vzWb{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #4F4444;
}
.ModelLanding_browse-image-text__1vzWb span{
    color: #FF7700;
}
.ModelLanding_support-image-type__3gWBo{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #B2B2B2
}
.ModelLanding_left-box__2igm1 button{
    position:  relative !important;
    /* left: 8%; */
    color: white;
    background-color: #FF7700;
    border: unset;
    margin: 30px 0; 
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: normal;
    line-height: initial;
    color: #FFFFFF;
    height: 45px;
}
.ModelLanding_left-box__2igm1 button:hover{
    background-color: #FF7700;
    border: unset;
}
.ModelLanding_upload-image-box__3Eijn{
    width: 324.76px;
    height: 261.94px;
    background: #FFFFFF;
    border: 1px dashed #77797B;
    box-sizing: border-box;
    border-radius: 10px;
}
 
.ModelLanding_right-box__3LqfK img{
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 133px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}
  
 .ModelLanding_heading-text__2l4Dl{
    padding: 0 20px;
  }
  /* Upload screen User credits */
  .ModelLanding_heading-text__2l4Dl span{
    border: 2px solid #FFE7D2;
    border-radius: 10px;
    float: right;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    padding: 7px 10px;
    letter-spacing: 0.5px;
    margin-top: 7px;
    color: #232121;
    position: absolute;
    right: 20px;
  }
  .ModelLanding_heading-text__2l4Dl span b{
    color: #ff7700;
  }
  .ModelLanding_heading-text__2l4Dl span img{
    margin-right: 20px;
  }
.ModelLanding_border-box__oCHll{ 
    border: 2px dashed #9a9a9a;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: none!important;
    /* opacity: .2; */
}
.ModelLanding_dropbox-box__3QcHN{
padding: 20px 30px;
}
.ModelLanding_outer-box-section__1FDVG{
    text-align: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin: 0 auto;
}











/* @Responsive */
@media(max-width:800px){
    .ModelLanding_common-header__1afZi h1{  
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        text-align: center; 
    } 
    .ModelLanding_left-box__2igm1 div{
        padding: 15px;
    }
    .ModelLanding_left-box__2igm1 button{ 
        width: auto;
        margin: 0px 0 10px; 
    }
    .ModelLanding_image-upload__1vU-o {
        margin-bottom: 30px;
    }
    .ModelLanding_drag-text__3XmYg{
        margin: 5px 0;
    }
    .ModelLanding_browse-image-text__1vzWb{
        margin: 0px 0 5px;
    }
    .ModelLanding_support-image-type__3gWBo{
    margin: 0;
    }
}




@media screen and (max-width:767px) and (min-width: 200px){

.ModelLanding_border-box__oCHll {
    padding: 15px;
}
.ModelLanding_common-header__1afZi{
    margin-top: 80px;
}
.ModelLanding_common-header__1afZi p {  
    font-size: 12px;
    line-height: 26px;
    margin-bottom: 20px;
}
.ModelLanding_left-box__2igm1 {
    margin-top: 0;  
      margin-bottom: 30px;
}
.ModelLanding_dropbox-box__3QcHN {
    padding: 0px;
}
}


@media screen and (max-width:900px) and (min-width: 768px){
    
.ModelLanding_image-upload__1vU-o{
    display: flex;
}
.ModelLanding_left-box__2igm1 {
    margin-top: 0;
}
.ModelLanding_dropbox-box__3QcHN{
    padding: 0;
}
.ModelLanding_common-header__1afZi p {
    line-height: 30px;
    margin: 0px 0 30px;
}
}
.ModelUseCases_tab-section__2gu06{
  display: flex;
  justify-content: center;
}
.ModelUseCases_tab-section__2gu06 button{
    border: unset;
    background: #FFFFFF;;
    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    padding: 12px 45px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    margin-left: 24px;
}
.ModelUseCases_tab-section__2gu06 button:hover{
    color: #272727 !important;
}

.ModelUseCases_active-usecase-button__1Jab_{
    border: unset;
    background: #FF7700 !important;
    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    box-shadow: none !important;
    border-radius: 13px;
    padding: 12px 45px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    margin-left: 24px;
}
#ModelUseCases_disabled-button__eGCYq{
    margin: 0px !important;
    padding: 0px !important;
    border: unset !important;
    background: transparent;
    box-shadow: unset;
    width: 216px;
    margin-left: 24px !important;
    margin-top: -10px !important;
    opacity: 0.5;
}
#ModelUseCases_disabled-button__eGCYq img{
    width: 100%;
}
@media(max-width:800px){
    .ModelUseCases_tab-section__2gu06{
        display: grid;
        grid-template-columns: repeat( 4, 1fr );
        grid-gap: 10px !important;
        grid-row-gap: 10px !important;
        padding: 0px 20px;
    }
    .ModelUseCases_tab-section__2gu06 button{
        grid-column:  span 2;
    }
    .ModelUseCases_tab-section__2gu06 button:nth-last-child(1):nth-child(odd) {
        grid-column: 2 / span 2;
    }
    .ModelUseCases_tab-section__2gu06 button{
        border: unset;
        background: #FFFFFF;
        border: 1px solid #9C9C9C;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 13px;
        padding: 10px 7px !important;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
        width: auto;
        margin-left: 0px;
    }
    .ModelUseCases_tab-section__2gu06 button:hover{
        color: #272727 !important;
    }
    .ModelUseCases_active-usecase-button__1Jab_{
        border: unset;
        background: #FFFFFF;
        border: 1px solid #9C9C9C;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 13px;
        padding: 10px 7px !important;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: #FFFFFF !important;
        width: auto;
        margin-left: 0px;
    }
    #ModelUseCases_disabled-button__eGCYq{
        margin: 0px !important;
        padding: 0px !important;
        border: unset !important;
        background: transparent;
        box-shadow: unset;
        width: auto;
    }
    #ModelUseCases_disabled-button__eGCYq img{
        width: 100%;
    }
}
.BackgroundRemoval_wrapper__Wc6Qj {
  padding: 0px 72px;
  /* height: 521px; */

  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.BackgroundRemoval_left-box__1e6nK {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  height: 540px;
  text-align: center;
}
.BackgroundRemoval_left-box__1e6nK img {
  height: 100%;
  object-fit: contain;
}
.BackgroundRemoval_left-box-scaling__3xuMH {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  height: 630px;
  text-align: center;
}
.BackgroundRemoval_left-box-scaling__3xuMH img {
  height: 100%;
  object-fit: contain;
}
.BackgroundRemoval_display-image-with-bg__25GM9 {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/Model/background+3.png');
  height: 90%;
  background-repeat: no-repeat;
  background-size: cover;
}
.BackgroundRemoval_display-image__3wx7W {
  height: 90%;
  background-repeat: no-repeat;
  background-size: cover;
}
.BackgroundRemoval_right-box__3yVtH {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 540px;
  padding: 12px 32px;
  /* margin-left: 48px; */
}
.BackgroundRemoval_right-box__3yVtH span {
  background-color: grey;
  border-radius: 50%;
}
.BackgroundRemoval_right-box-scaling__15M9F {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  padding: 12px 32px;
  /* margin-left: 48px; */
}
.BackgroundRemoval_right-box-scaling__15M9F span {
  background-color: grey;
  border-radius: 50%;
}
.BackgroundRemoval_download-section__2auWH {
  margin-top: 100px;
  text-align: center;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.BackgroundRemoval_screen-2-drop__-wW0U {
  /* max-width: 100% !important; */
  /* padding: 0px !important; */
  /* margin: 0px !important; */
}
.BackgroundRemoval_screen-2-drop__-wW0U:focus {
  border: 0;
  outline: 0;
}
@media (max-width: 800px) {
  .BackgroundRemoval_wrapper__Wc6Qj {
    padding: 0px 25px;
    width: auto;
  }
  .BackgroundRemoval_left-box__1e6nK {
    height: auto;
  }
  .BackgroundRemoval_left-box-scaling__3xuMH {
    height: auto;
  }
  .BackgroundRemoval_display-image__3wx7W {
    height: auto;
  }
  .BackgroundRemoval_left-box__1e6nK img {
    height: auto;
  }
  .BackgroundRemoval_left-box-scaling__3xuMH img {
    height: auto;
  }
  .BackgroundRemoval_right-box__3yVtH {
    height: auto;
    margin-top: 20px;
    margin-left: 0px !important;
    padding: 20px 10px;
  }
  .BackgroundRemoval_right-box-scaling__15M9F {
    height: auto;
    margin-top: 20px;
    margin-left: 0px !important;
    padding: 20px 10px;
  }
  .BackgroundRemoval_right-box-scaling__15M9F .BackgroundRemoval_col-auto__3DYqe {
    padding: 0 5px !important;
  }
}

.Beautification_beautification-image__2eLv5{

}

.Beautification_beautification-image__2eLv5 img{
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.Beautification_upload-beauti__eKfQR p{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    color: #444444;
    margin: 0;
}
.Beautification_dropzone-section__37zdM{
    width: 100%;
    cursor: pointer;
}
.Beautification_dropzone-section__37zdM img{
    margin-bottom: 10px;
}
.Beautification_dropzone-section__37zdM p{
    font-weight: normal; 
    line-height: 25px;
    color: #000000; 
}
.Beautification_category-options__12ehD{

}
.Beautification_category-options__12ehD ul{
width: 100%;
display: flex;
list-style-type: none;
padding: 0;
}
.Beautification_category-options__12ehD ul li{
    margin-right: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    width: 50px;
    height: 45px;
    text-align: center;
}
.Beautification_category-options__12ehD ul li img{
    width: auto;
    height: auto;
    padding: 5px;
}
.Beautification_category-options__12ehD ul li button {
    background: #FFFFFF;
border: 1px solid #fff;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
padding:0;
width: 48px;
    height: 48px;
}

.Beautification_category-options__12ehD ul li button.Beautification_active__2xvp4 { 
border: 1px solid #FF3503; 
}

.Beautification_mybtn__O7hWp{
    background: #FF3503;
border-radius: 20px;
width: 150px;
height: 45px;
}
.Beautification_brush__cJruP{
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval.png'), auto;
    
}

.Beautification_brush1__2dLcw {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(1).png'), auto;
}
.Beautification_brush2__PvlH9 {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(2).png'), auto;
}



 









.Upscaling_main-banner__1DP0-{
  
    text-align: center;
    background: linear-gradient(180deg, #FD8061 0%, #F39755 100%);
}
.Upscaling_left-banner-text__26dqq, .Upscaling_right-banner-text__14YDY{
    padding: 0;
}
.Upscaling_left-banner-text__26dqq p, .Upscaling_right-banner-text__14YDY p{
color: #FFFFFF;
font-weight: 600;
font-size: 12px;
line-height: 18px;
text-align: center;

color: #FFFFFF;
}
.Upscaling_left-banner-text__26dqq p::before {
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 60%;
    height: 0.7px;
    top: 31.1%;
    right: -15px;
}
.Upscaling_left-banner-text__26dqq p::after{
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 0.7px;
    height: 5%;
    top: 26.3%;
    right: 52%;
}
 .Upscaling_right-banner-text__14YDY p{
    padding-top: 55%;
 }
 
.Upscaling_right-banner-text__14YDY p::before {
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 60%;
    height: 0.7px;
    top: 21%;
    left: -15px;
}
.Upscaling_right-banner-text__14YDY p::after{
    content: '';
    position: absolute;
    background-color: #FFFFFF;
    width: 0.7px;
    height: 5%;
    top: 21%;
    right: 48%;
}
.Upscaling_banner-row__Wt9T_{
    padding-top: 12%;
}
.Upscaling_banner-box__ViUNJ {
    background:#fff;
    text-align: center;
    margin: 0;
    
    padding: 20px 12%;
}
.Upscaling_banner-box__ViUNJ img{
    width: auto;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}
.Upscaling_banner-box__ViUNJ h1{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}
.Upscaling_banner-box__ViUNJ p{
    color: #444444;
    font-weight: 400;
    margin-bottom: 20px;
}
.Upscaling_main-banner__1DP0- form{
    border: 1px dashed #696969;
box-sizing: border-box;
border-radius: 12px;
padding: 20px;
    text-align: -webkit-center;
    margin-bottom: 30px;
    cursor: pointer;
}
.Upscaling_main-banner__1DP0- form:hover{
    background: linear-gradient(180deg, #FFFBF4 0%, rgba(250, 189, 175, 0) 100%);
border: 1px dashed #FF3503; 
}
.Upscaling_main-banner__1DP0- form img{
    display: block;

}
.Upscaling_main-banner__1DP0- form label{
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
}
.Upscaling_main-banner__1DP0- form label span{
display: block;
}
.Upscaling_main-banner__1DP0- form strong{
    font-weight: 600;
}
.Upscaling_after-image-section__1ZKR3{
    background: #fff;
    padding-bottom: 5%;
}
.Upscaling_after-image__7VNrS{

}
.Upscaling_image-area__2eozv img{
    width: 100%;
}
.Upscaling_mybutton__6Kq-D{
    background: #E85F3D;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 8px;
}



/* Responsive Media Query  */

@media screen and (max-width: 767px) and (min-width: 200px){
    .Upscaling_banner-row__Wt9T_{ 
        padding-top: 35%;
    }
    .Upscaling_banner-box__ViUNJ {  
        padding: 20px 20px 40px;
    }
.Upscaling_mybutton__6Kq-D{
    width: 80%;
    margin: 0 auto;
}
.Upscaling_image-area__2eozv img{
width: 100%;
    margin-bottom: 50px;
    padding: 0;
    background: #FFFFFF;
    padding-bottom: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.Upscaling_image-area__2eozv{
    margin: 0;
}



}



@media screen and (max-width: 900px) and (min-width: 768px){
.Upscaling_right-banner-text__14YDY p{
    padding-top: 65%;
 }
.Upscaling_banner-box__ViUNJ{
    padding: 20px 10%;
}
.Upscaling_main-banner__1DP0- form{
    padding: 20px 15px;
}
.Upscaling_left-banner-text__26dqq p::after{
top: 23.3%;
}
.Upscaling_left-banner-text__26dqq p::before{
    top: 28%;
    }


}




@media screen and (max-width: 1200px) and (min-width: 901px){
    .Upscaling_left-banner-text__26dqq p::before{
        top: 40%;
     }
     .Upscaling_left-banner-text__26dqq p::after{
        top: 35.1%;
    right: 51%;
     }

     .Upscaling_right-banner-text__14YDY p::before { 
        top: 15%;


    }
    .Upscaling_right-banner-text__14YDY p::after{
    top: 15%;
    right: 49%;
}

}

.landing_container-fluid__p8sR_{
    width: 95%!important; 
    margin: 0 auto;
}
a{
    text-decoration: none;
}
section {
    /* padding: 50px 0px; */
}
.landing_active__sbi88, .landing_activate__2UOCS{
    /* color: #fff;  */
    color: #FF3503 !important;
    border-bottom: 1px solid #FF3503 !important;
    border-radius: 0;
        background: transparent;
}
/**************==============
Bannner Section CSS
=============**************/
.landing_banner-area__3y0JW{
    margin-top: 50px;
    background: url('https://storage.googleapis.com/clippr-image-processing/Static/try.png');
    background-size: cover;
    height: 100vh;
    background-position: bottom center;
    transition: .2s ease-in-out;
}
.landing_banner-area2__3_1nn{
    margin-top: 50px;
    background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/landing+page+2.png');
    background-size: cover;
    height: 100vh;
    background-position: bottom center;
    transition: .2s ease-in-out;

}
.landing_drag-image-area__1JI8y{
    padding-top: 5%;
}
.landing_drag-image-area__1JI8y h1{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 42px;
line-height: 45px;
color: #1C1E52;
margin-bottom: 30px;
}
.landing_drag-image-area__1JI8y h1 span{
    display: block;
    font-size: 20px;
    line-height: 45px;
    font-weight: 500;
  
}
.landing_drag-image-area__1JI8y h1 span a{
    line-height: 45px;
    color: #FF3503;
    font-size: 20px;
    text-decoration: none;
}

label.landing_file-drag__2TWtG {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    text-align: -webkit-center;
    padding: 40px 20px;
    cursor: pointer;
 }
 label.landing_file-drag__2TWtG img{
     display: block;
 }

 label.landing_file-drag__2TWtG span{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px; 
    color: #000;
 }

.landing_button__3nCkt {
	background      : #005f95;
	border          : none;
	border-radius   : 3px;
	color           : white;
	display         : inline-block;
	font-size       : 19px;
	font-weight     : bolder;
	letter-spacing  : 0.02em;
	padding         : 10px 20px;
	text-align      : center;
	text-shadow     : 0px 1px 2px rgba(0, 0, 0, 0.75);
	text-decoration : none;
	text-transform  : uppercase;
	transition      : all 0.3s;
}

.landing_btn__3siRd:hover {
	background : #4499c9;
}

.landing_btn__3siRd:active {
	background : #49ADE5;
}

input[type="file"] {
	display : none;
}

.landing_file-drag__2TWtG { 
	width: 309px;
height: 218px;
background: #FEFEFE;
border: 1px dashed #000000;
box-sizing: border-box;
box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.15);
border-radius: 23px;
}

.landing_file-drag__2TWtG span {
     display: block;
    font-size: 15px;
}

.landing_file-drag__2TWtG:hover { 
     background: #FFF6F4;	
}
 
.landing_file-progress__8HVoU {
	display : none;
	margin  : 1em auto;
	width   : 100%;
}

.landing_file-upload-btn__acS5B {
	margin : auto;
}

.landing_file-upload-btn__acS5B:hover {
	background : #4499c9;
}

.landing_file-upload-form__1yVAH {
	margin : 0;	
   
    margin-bottom: 35px;
  
}

 
 
.landing_drag-image-area__1JI8y ul{
    padding: 0;
}
 .landing_drag-image-area__1JI8y ul li{
    font-size: 16px;
    line-height: 30px;
    /* margin: 0px 8px; */
    list-style: none;
 }
 .landing_drag-image-area__1JI8y ul li img{
    padding-right: 10px; 
 }
 
 
.landing_image-area2__bzzmB{
    /*background: url('../images/Group 5754 (1).png');*/
   
} 
.landing_image-area__6XcUX img{
    width: 100%;
    padding-top: 10%;
} 
 
.landing_file-upload-form__1yVAH:hover + .landing_banner-area__3y0JW{
    background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/try.png');
}
  
 /***************=================
Stand Out Section CSS
===============***************/ 
 
.landing_subject-stand-out__34RFT{
    padding: 50px 0px;
    background: #fff;
}
.landing_title__2DH2q{
    
} 
.landing_title__2DH2q h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 45px;
    color: #1C1E52;
    text-align: center;
} 
.landing_title__2DH2q h1 span{
        color: #FF3503;
} 

.landing_subject-tabs__1W8KV ul, .landing_marketplace-tabs__3C71Z ul{
        text-align: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 25px;
}
.landing_subject-tabs__1W8KV {
    
} 
.landing_marketplace-tabs__3C71Z{

}
.landing_subject-tabs__1W8KV ul li, .landing_marketplace-tabs__3C71Z ul li{ 
    padding: 0;
    margin: 5px 25px;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 1px solid transparent;
}
.landing_subject-tabs__1W8KV ul li button, .landing_marketplace-tabs__3C71Z ul li button{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    border-radius: 0!important;
    border-bottom: 1px solid transparent;
}
.landing_subject-tabs__1W8KV ul li button:hover, .landing_marketplace-tabs__3C71Z ul li button:hover {
     color: #FF3503;
    border-bottom: 1px solid #FF3503;
    border-radius: 0;
        background: transparent;
} 
 .landing_tab-contents__uvaT2{
    justify-content: center;
 }

 .landing_slider__MkFHs{
    height: 100%;
    width: 3px;
    background: #fff;
}
.landing_slider__MkFHs  img {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 43%;
    margin-left: -25px;
}


/***************=================
 Extract The Subject Section CSS
===============***************/ 
.landing_extract-the-subject__2KPfI{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);
    padding: 50px 0;
}
.landing_extract-image__afQ31{
    
} 
.landing_extract-image__afQ31 img{
     width: 100%;
}     
.landing_extract-content__uSTiN .landing_title__2DH2q{
         padding: 3% 18% 0;
} 
.landing_extract-content__uSTiN .landing_title__2DH2q h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    text-align: left;
} 
 .landing_extract-content__uSTiN p{
    font-weight: 100;
    color: #1C1E52;
} 
.landing_extract-content__uSTiN h6{
        margin-top: 20%;
    margin-bottom: 15px;
    color: #1C1E52;
        line-height: 27px;
        font-weight: 600;
} 
 
 .landing_extract-content__uSTiN h6 span{
       color: #FF3503;
} 
 a.landing_try-button__1qUsA{
    background: #FF3503;
    border-radius: 20px;
    padding: 5px 15px;
    color: #fff;
    display: block;
    width: 150px;
    width: 178px;
    height: 43px;
    text-align: center;
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 35px;
 }
.landing_subject-tabs__1W8KV{
       text-align: center; 
} 
 .landing_marketable-content__2Me_e{
    padding: 5% 0 0;
 }
.landing_marketable-content__2Me_e h6{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
margin-top: 30%;
margin-bottom: 15px;
color: #1C1E52;
line-height: 30px;
}
.landing_marketable-content__2Me_e h6 span{
    color: #FF3503;
}
.landing_marketable-content__2Me_e p{

}

 

.landing_best-useful__12oKL{
    padding: 50px 0px;
} 
 
.landing_best-useful-col__1Fo-b{
        padding: 0 50px;
            margin-bottom: 60px;
} 
.landing_best-useful-single__b0LPS, .landing_best-useful-single2__zIjJs{
    padding: 15px;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-top: 20px;
    width: 420px;
height: 267px;
} 
.landing_best-useful-single__b0LPS img{
     width: 100%;   
} 
.landing_best-content__rNNyh{
    
} 
.landing_best-content__rNNyh h4{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    color: #444;
    margin: 0;
} 
.landing_best-content__rNNyh p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #8B8B8B;
} 
   
 
 
.landing_best-useful-single__b0LPS::after{ 
content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 53, 3, 0.15) 0%, rgba(205, 109, 85, 0.0855) 100%);
    border-radius: 32px; 
    left: 0;
    z-index: -1;
    width: 245px;
    height: 218px;
    bottom: -45px;
}
 
.landing_best-useful-single2__zIjJs::after{ 
    content: '';
        position: absolute;
        background: linear-gradient(180deg, rgba(255, 53, 3, 0.15) 0%, rgba(205, 109, 85, 0.0855) 100%);
        border-radius: 32px; 
        left: auto;
        right: 0;
        z-index: -1;
        width: 245px;
        height: 218px;
        bottom: -45px;
    }
.landing_text-section__6OLZw{
background: linear-gradient(
    90deg
    , rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);
        padding: 50px 0;
    }
.landing_text-content__1NYjd{
    
} 
 .landing_text-content__1NYjd p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: black;
 }
  .landing_text-section__6OLZw{
      padding-bottom: 30px;
  }



/* Product Sell Section */

.landing_product-sell__3fVAA{
    background: linear-gradient(90deg, rgba(196, 196, 196, 0.15) 3.51%, rgba(243, 48, 0, 0.15) 100%);
    overflow: hidden;
}
  .landing_product-images__3oKrE{ 
    padding-right: 30px;
  }
  .landing_product-images__3oKrE img{
    width: auto;
    margin-top: 10%;
    margin-bottom: 46px;
    margin-right: 15px;
  }
  .landing_product-images__3oKrE img::after{
    content: '';
    position: absolute;
  }
.landing_pro-img2__2Enfr {
    
}
.landing_pro-img2__2Enfr img{
    width: auto;
    margin-bottom: 45px;    
}
.landing_pro-img2__2Enfr img:after{
    content: ''; 
}
.landing_product-sell__3fVAA  p{
    padding: 0px 15px 0 20px;
    width: 50%;
    text-align: left;
    margin: 0 0 0 auto;
 }
 .landing_pro-img2__2Enfr p{
     margin: 0 auto 0 0;
 }
.landing_product-sell__3fVAA .landing_title__2DH2q{
    margin-bottom: 50px;
}
.landing_progress-bar__2YXim{
    margin: 0;
    justify-content: flex-end;
}
.landing_progress-bar2__18hS6{
    margin: 0; 
}
/* .progress-bar-danger{
    width: 5%;
    background: #D10D0D;
    padding: 0;
    margin-bottom: 46px;
    
}
.progress-bar-danger div{
    width: 100%;
    height: 75%;
    background: #f4e8e4;
    -webkit-transition : height 3s ease linear;
    -moz-transition : height 3s ease linear;
      -o-transition : height 3s ease linear;
         transition : height 3s ease linear;
}
.progress-bar2 .progress-bar-danger{
   background: #26840E;
}
.progress-bar2 .progress-bar-danger div{
    width: 100%;
    height: 30%;
    background: #f6e0db;
} */



 
  .landing_progress-bar1__2dm8o, .landing_progress-barrr__1NuiI {
    position: relative;
    /* margin: 0 auto; */
    width: 15%;
    margin-top: 40%;
    /* width: 5%; */
    padding: 0;
    margin-bottom: 46px;
  }
  .landing_progress-bar1__2dm8o{
    position: relative;
    /* margin: 0 auto; */
    width: 5%;
    margin-top: 40%;
    /* width: 5%; */
    padding: 0;
    margin-bottom: 46px;
  }
  .landing_progress-barrr__1NuiI {
    position: relative; 
    width: 5%;
    margin-top: 10%; 
    padding: 0;
    margin-bottom: 46px;
  }
  .landing_progress-bar1__2dm8o span, .landing_progress-barrr__1NuiI span{
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    background: #f4e9e6;
    
    overflow: hidden;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
     
    height: 100%;
    background: #f4e8e4;
  }
  
  .landing_progress-bar1__2dm8o > span:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #D10D0D;
    z-index: 1;
    /* background-size: 50px 50px; */
    overflow: hidden;
  }
  .landing_progress-barrr__1NuiI > span:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #26840E;
    z-index: 1;
    /* background-size: 50px 50px; */
    overflow: hidden;
  }
  .landing_progress-bar1__2dm8o > span:after, .landing_animate__37byX > span > span{ 
    -webkit-animation: landing_expandHeight__rxmok 3s linear; 
            animation: landing_expandHeight__rxmok 3s linear;
  }
  .landing_progress-barrr__1NuiI > span:after, .landing_animate__37byX > span > span{ 
    -webkit-animation: landing_expandHeight__rxmok 3s linear; 
            animation: landing_expandHeight__rxmok 3s linear;
  }
  
  @-webkit-keyframes landing_expandHeight__rxmok {
     0% { height: 0; }
     100% { height: 100%; }
  }
  
  @keyframes landing_expandHeight__rxmok {
     0% { height: 0; }
     100% { height: 100%; }
  }
  


   









@-webkit-keyframes landing_fadeInRight__3nlEj {
    from {
        opacity:0;
        -webkit-transform: translatex(10px);
        transform: translatex(10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes landing_fadeInRight__3nlEj {
    from {
        opacity:0;
        -webkit-transform: translatex(100px);
        transform: translatex(100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        transform: translatex(0);
    }
}

/** fadeInLeft **/

@-webkit-keyframes landing_fadeInLeft__1ICZ8 {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes landing_fadeInLeft__1ICZ8 {
    from {
        opacity:0;
        -webkit-transform: translatex(-100px);
        transform: translatex(-100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        transform: translatex(0);
    }
}
/* .pro-img2 {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -o-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-duration:4s;
    animation-delay: 0s;
}
.product-images{
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -o-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-duration:4s;
    animation-delay: 0s;
} */

.landing_product-sell__3fVAA .landing_title__2DH2q h1{
    text-align: left!important;
    
    /* -webkit-animation-name: fadeInRight;
    -moz-animation-name: fadeInRight;
    -o-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -o-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-duration:4s;
    animation-delay: 0s; */
}
.landing_mybutton__83O8o {
    background:#e85f3d!important;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
}












/* @media ===Responsive === */

@media screen and (max-width: 767px) and (min-width: 200px){
    .landing_best-useful-single__b0LPS, .landing_best-useful-single2__zIjJs{ 
        width: 100%;
        height: 186px;
        border-radius: 13px;
}
.landing_best-content__rNNyh p { 
    font-size: 10px;
    line-height: 16px; 
    margin: 0;
}
.landing_best-content__rNNyh h4 { 
    font-size: 12px;
    line-height: 30px;
    margin: 0;
}
.landing_best-useful-single__b0LPS::after { 
    width: 144px;
    height: 130px; 
    left: 15px;
    border-radius: 8px;
    bottom: -30px;
}

.landing_best-useful-col__1Fo-b{
    padding: 0 40px; 
}

.landing_best-useful-single2__zIjJs::after { 
    width: 144px;
    height: 130px; 
    right: 15px;
    border-radius: 8px;
    bottom: -30px;
}
.landing_best-useful-single2__zIjJs img{
    width: 100%;
}

.landing_text-content__1NYjd p { 
    font-size: 10px; 
}
.landing_text-content__1NYjd {
    padding: 0px 25px;
}
.landing_container-fluid__p8sR_ {
    max-width: 100%!important;
}
.landing_title__2DH2q h1 { 
    font-size: 18px;
    line-height: 23px;

}
.landing_marketplace-tabs__3C71Z {
    padding: 0;
}
.landing_subject-tabs__1W8KV ul li, .landing_marketplace-tabs__3C71Z ul li {
    padding: 0;
    margin: 5px 7px;
}

.landing_subject-tabs__1W8KV ul li button, .landing_marketplace-tabs__3C71Z ul li button{
    font-size: 12px;
    line-height: 25px;
}
.landing_extract-image__afQ31 {
    order: 2;
}
.landing_extract-content__uSTiN   {
    order: 1;
}

.landing_extract-content__uSTiN .landing_title__2DH2q h1 { 
    font-size: 18px;
    line-height: 45px; 
}
.landing_extract-content__uSTiN .landing_title__2DH2q {
    padding: 0px 0px 0px;
}

.landing_extract-content__uSTiN h6 {
    margin-top: 0;
}
.landing_drag-image-area__1JI8y {
    padding: 0 0px;
}
.landing_drag-image-area__1JI8y h1 { 
    font-size: 23px;
    line-height: 25px;
    margin-bottom: 5px;
}
.landing_drag-image-area__1JI8y h1 span a { 
    font-size: 16px; 
}

 
label.landing_file-drag__2TWtG {
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    padding: 15px;
}
.landing_file-drag__2TWtG {
    width: 80%;
    height: auto; 
}
label.landing_file-drag__2TWtG img { 
    width: 30px;
}
label.landing_file-drag__2TWtG span { 
    font-size: 12px;
}
.landing_drag-image-area__1JI8y h1 span {
    display: block;
    font-size: 12px;
}
.landing_drag-image-area__1JI8y ul li {
    font-size: 10px;
    line-height: 20px;
}
.landing_banner-area__3y0JW {  
    background-repeat: no-repeat;
}
.landing_mybutton__83O8o{
    width: 45%;
    margin: 0;
    font-size: 11px;
    margin-bottom: 15px;
    /* line-height: 1; */
    padding: 10px 10px;
    border-radius: 5px;
}
.landing_product-images__3oKrE img {
    width: 80%;
    margin-top: 0;
    margin-bottom: 46px;
    margin-right: 0px;
}
.landing_progress-bar1__2dm8o { 
    margin-top: 40%; 
    padding: 0;
    margin-bottom: 46px;
}
.landing_product-sell__3fVAA p {
    padding: 0 10px;
    width: 50%;
    text-align: left;
    margin: 0 0 0 auto;
    float: right;
    font-size: 12px;
}
.landing_product-images__3oKrE {
    padding-right: 10px;
}
.landing_progress-bar__2YXim {
    margin: 0;
    justify-content: flex-start;
    width: 50%;
    float: left;
}
.landing_pro-img2__2Enfr img {
    width: 90%; 
}
.landing_progress-bar2__18hS6 {
    margin: 0;
    width: 50%;
    float: right;
}
 
.landing_marketable-content__2Me_e {
    padding: 5% 15px 0;
}
.landing_marketable-content__2Me_e h6 {
    
    display: none;
}

a.landing_try-button__1qUsA {
    background: #E85F3D;
    margin: 0 auto;
}
p{
    font-size: 12px;
}
.landing_footer-links__3yX-4 {
    margin-bottom: 15px;
}


}


@media (min-width: 1400px){
.landing_product-sell__3fVAA p { 
    width: 42%;
}
.landing_progress-bar1__2dm8o { 
    margin-top: 35%;
}



}



.LandingScreenTwo_landing-screen-two__3fG7u{
    padding: 50px;
    margin-top: 65px;
    background: #fff;
}
.LandingScreenTwo_screen-two-area__32Ja0{
    padding: 50px;
    margin: 0;
    border: 2px dashed #C4C4C4;
    box-sizing: border-box; 
    border-radius: 13px;
    background: #fff;
    text-align: center;
    transition: .2s ease-in-out;
}

.LandingScreenTwo_screen-two-area__32Ja0 h1{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 45px;
color: #1C1E52;
}
.LandingScreenTwo_screen-two-area__32Ja0 p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px; 
    color: #444444;
}

.LandingScreenTwo_screen-two-area__32Ja0 .LandingScreenTwo_mybtn__1Hyin{
    width: 178px;
height: 43px;
background: #FF3503;
color: #fff;
margin: 5% 0;
border-radius: 20px;
}

.LandingScreenTwo_screen-two-area__32Ja0 p span{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 45px;
color: #B4B4B4;
}
.LandingScreenTwo_screen-two-area__32Ja0:hover{
    background: linear-gradient(180deg, #FFFBF4 0%, rgba(250, 189, 175, 0) 100%);
}
.LandingScreenTwo_screen-two-area__32Ja0 ul{
    display: flex;
    margin: 0 auto;
    width: 75%;
    justify-content: center;
}
.LandingScreenTwo_screen-two-area__32Ja0 ul li{
    display: inline-block;
    padding: 10px 10px 0;
}
.LandingScreenTwo_screen-two-area__32Ja0 ul li img{
    width: 100%;
}








.LandingScreenThree_landing-screen-three__27QF7{
    padding: 50px 0;
    margin-top: 65px;
    background: #fff;
}
.LandingScreenThree_screen-three-area__1I7i_{
    padding: 30px 20px 10px;
    margin: 0;
    border: 2px solid  #C4C4C4;
    box-sizing: border-box; 
    border-radius: 13px 13px 0 0;
    background: #fff;
    border-bottom: 0;
    text-align: center;
    transition: .2s ease-in-out;
}

.LandingScreenThree_screen-three-area__1I7i_ h1{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 45px;
color: #1C1E52;
}
.LandingScreenThree_screen-three-area__1I7i_ p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px; 
    color: #444444;
}

.LandingScreenThree_screen-three-area__1I7i_ .LandingScreenThree_mybtn__56ofp{
    width: 178px;
height: 43px;
background: #FF3503;
color: #fff;
margin: 15% 0 0;
border-radius: 20px;
}

.LandingScreenThree_screen-three-area__1I7i_ p span{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 45px;
color: #B4B4B4;
}
/* .screen-three-area:hover{
    background: linear-gradient(180deg, #FFFBF4 0%, rgba(250, 189, 175, 0) 100%);
} */
.LandingScreenThree_screen-three-area__1I7i_ ul{
    display: flex;
    width: 100%;
    margin-bottom: 5px;
}
.LandingScreenThree_screen-three-area__1I7i_ ul li{
    display: inline-block;
    padding: 10px 10px 0;
}
.LandingScreenThree_screen-three-area__1I7i_ ul li img{
    width: 100%;
}

.LandingScreenThree_right-drag-drop__3TMQ0{
    padding-top: 20px;
}
.LandingScreenThree_image-editing__2h4Y6{
    padding: 10px 15px 0;
}
.LandingScreenThree_image-editing__2h4Y6 ul{

}
.LandingScreenThree_image-editing__2h4Y6 ul li{
    
}
.LandingScreenThree_image-editing__2h4Y6 ul li button{

}
.LandingScreenThree_active__3_ikF{ 
    color: #FF3503 !important;
    border-bottom: 1px solid #FF3503 !important;
    border-radius: 0;
        background: transparent;
}
.LandingScreenThree_image-editing__2h4Y6 ul {
    padding: 0px;
}
.LandingScreenThree_image-editing__2h4Y6 ul li {
    padding: 0;
    margin: 5px 25px 0 0;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 1px solid transparent;
}
.LandingScreenThree_image-editing__2h4Y6 ul li button {
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #696969;
    padding: 0;
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 25px;
    border-radius: 0!important;
    border-bottom: 1px solid transparent;
}
.LandingScreenThree_image-editing__2h4Y6 ul li button:hover {
    color: #FF3503;
    border-bottom: 1px solid #FF3503;
    border-radius: 0;
    background: transparent;
}
.LandingScreenThree_screen-3-drop-model__NYHir{
    border: 1px dashed #696969;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;
}
.LandingScreenThree_screen-3-drop-model__NYHir img{
    width: auto;
    margin: 0 auto;
    margin-bottom: 5px;
}
.LandingScreenThree_screen-3-drop-model__NYHir .LandingScreenThree_para-1__29ep4{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 25px;
    color: #000;
}
.LandingScreenThree_screen-3-drop-model__NYHir .LandingScreenThree_para-2__3NTB4{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 0;
    color: #000;
    line-height: 20px;
}
.LandingScreenThree_mybtn-border__2A82o{ 
    border: 1px solid #FF3503;
    box-sizing: border-box;
    border-radius: 20px;
    width: 178px;
    height: 43px;
}
.LandingScreenThree_right-drag-drop__3TMQ0 p{
    font-weight: 500;
font-size: 9px;
line-height: 25px;
color: #949494; 
}
.LandingScreenThree_tab-contents__2G8dY p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    color: #232323;
    text-align: left;
    margin: 0;
}
.LandingScreenThree_right-drag-drop__3TMQ0 a{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 16px; 
-webkit-text-decoration-line: underline; 
        text-decoration-line: underline;

color: #FF3503;

}
.LandingScreenThree_right-drag-drop__3TMQ0 h6{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 35px;
color: #292929;
margin: 0;
}
.LandingScreenThree_bottom-contact-badge__3mS-P{
    background: #dee2e6;
    padding: 0;
    border-radius: 0 0 11px 10px; 
    text-align: left;
}
.LandingScreenThree_bottom-contact-badge__3mS-P p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px; 
    padding: 5px 30px; 
    margin: 0;
}
.LandingScreenThree_bottom-contact-badge__3mS-P p span {
    color: #f70;
}
 
.LandingScreenThree_switch__xUgPK {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 29px;
  }
  
  .LandingScreenThree_switch__xUgPK input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .LandingScreenThree_toggle-switch__2Eq-C {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .LandingScreenThree_toggle-switch__2Eq-C:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .LandingScreenThree_toggle-switch__2Eq-C {
    background-color: #FF3503;
  }
  
  input:focus + .LandingScreenThree_toggle-switch__2Eq-C {
    box-shadow: 0 0 1px #FF3503;
  }
  
  input:checked + .LandingScreenThree_toggle-switch__2Eq-C:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
.LandingScreenThree_toggle-switch__2Eq-C{
    border-radius: 55px;
}
.LandingScreenThree_toggle-switch__2Eq-C:before{
    border-radius: 50%;
}
.LandingScreenThree_image-editing__2h4Y6 p{
    display: inline;
    font-size: 14px;
}





.RemoveForeground_remove-foreground__1A0Ps{
 }

.RemoveForeground_remove-foreground__1A0Ps .RemoveForeground_p-1__Gyum-{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF3503;
    margin: 25px 0 0;
}
.RemoveForeground_remove-foreground__1A0Ps .RemoveForeground_p-2__2tbWY{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #4F4444;
margin: 0;
}
.RemoveForeground_remove-foreground__1A0Ps .RemoveForeground_p-2__2tbWY span{
    color: #FF3503;
}
.RemoveForeground_remove-foreground__1A0Ps .RemoveForeground_choose-button__1SRHE button{
    background: #FF3503;
border-radius: 20px;
    font-family: Poppins;
font-style: normal;
width: 178px;
height: 43px;
font-weight: 500;
font-size: 16px; 
color: #FFFFFF;

}








.BulkProcessing_bulk-processing__364mV {
  padding: 120px 0 75px;
}
.BulkProcessing_bulk-area__1n7b7 {
  border: 2px dashed #c4c4c4;
  box-sizing: border-box;
  -webkit-filter: drop-shadow(-10px 33px 100px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(-10px 33px 100px rgba(0, 0, 0, 0.25));
  border-radius: 13px;
  padding: 30px 70px;
  background: #fff;
  text-align: center;
  min-height: 500px;
  transition: 0.2s ease-in-out;
}
.BulkProcessing_bulk-area__1n7b7 h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #1c1e52;
  text-align: center;
  margin: 0;
}
.BulkProcessing_bulk-area__1n7b7 p {
  font-weight: 600;
  font-size: 14px;
  line-height: 45px;
  color: #444444;
  text-align: center;
}
.BulkProcessing_bulk-image-left__e_ysm {
}
.BulkProcessing_bulk-image-left__e_ysm ul {
  list-style: none;
  text-align: left;
  justify-content: left;
  padding: 0;
}
.BulkProcessing_bulk-image-left__e_ysm ul li {
}
.BulkProcessing_bulk-image-left__e_ysm ul li img {
}
.BulkProcessing_autowidth__2zhsJ {
  width: 30px;
}
.BulkProcessing_bulk-image-left__e_ysm ul li span {
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  color: #5a5a5a;

  padding: 0 10px;
}
.BulkProcessing_bulk-image-right__1SlVN {
}
.BulkProcessing_bulk-image-right__1SlVN p {
  margin: 0;
  text-align: right;
}
.BulkProcessing_bulk-image-right__1SlVN p a {
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #ff3503;
}

.BulkProcessing_bulk-image-right__1SlVN p a img {
  margin-left: 10px;
}
.BulkProcessing_mybtn__26rhA {
  background: #ff3503;
  border-radius: 20px;
  width: 178px;
  height: 43px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.BulkProcessing_bulk-area__1n7b7 .BulkProcessing_mybtn__26rhA {
  margin: 3% auto;
}

.BulkProcessing_spinner__13p5c {
  width: 80px;
  height: 80px;
  margin: 15% auto 0;
  /* border: .55em solid currentColor!important;
    border-right-color: transparent; */
}

.BulkProcessing_bulk-area__1n7b7 .BulkProcessing_outer-box__2wKAe {
  margin-top: 10% !important;
}

.BulkProcessing_bulk-area__1n7b7 .BulkProcessing_outer-box__2wKAe > a {
  color: #ff3503;
  font-weight: 500;
  font-size: 18px;
  line-height: 45px;
  -webkit-animation: BulkProcessing_color-chn__1Gxq2 4s;
          animation: BulkProcessing_color-chn__1Gxq2 4s;
}

.BulkProcessing_complete-process__3dLTp {
  -webkit-animation: BulkProcessing_color-chn__1Gxq2 4s;
          animation: BulkProcessing_color-chn__1Gxq2 4s;
}

/* a {
    color: #FF3503
} */

a:hover {
  text-decoration: none;
  color: #ff3503;
}

.BulkProcessing_proceed-btn__3KhdP a:hover {
  color: #fff !important;
}

/* @keyframes color-chn {
    0% {
        color: #6666;
    }
    100% {
        color: #FF3503
    }
} */

@-webkit-keyframes BulkProcessing_color-chn__1Gxq2 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes BulkProcessing_color-chn__1Gxq2 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.ScreenTwo_upload-btn__1P80Z{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.ScreenTwo_upload-btn__1P80Z img{ 
        width: 23px;
        margin-right: 10px; 
}

.ScreenTwo_upload-btn__1P80Z span{

}
.ScreenTwo_download-section-inner__3X1hF{
  margin-bottom: 25px;
}
.ScreenTwo_upload-sec__3tjwv{
    /* padding-bottom: 20px; */
}
 .ScreenTwo_upload-top-btn__3tnLE{
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.ScreenTwo_screen-2-drop__3wfwF{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}
.ScreenTwo_solid-background__n6bTq{
  padding: 25px 0px 25px 25px;
  display: grid;
  grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  grid-gap: 9px;
}
.ScreenTwo_solid-__3nGZV span{
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0!important;
  line-height: normal;
}
.ScreenTwo_solid-background__n6bTq img{
  width: 100%;
  /* padding: 5px; */
  height: 65px;
}
.ScreenTwo_right-top-selection__3iXJK{
  /* display: grid; */
  /* grid-template-columns: 50% 50%; */
  /* grid-gap: 0px; */
  margin: 0px auto 7px;
  justify-content: center;
  text-align: center;
  /* border-top: 2px solid #e0e0e0; */
 
}
.ScreenTwo_angle-sec__1nu7c {
  /* background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  width: 90%;
  margin-left: 5%; */
  background-color: #fbfbfb;
    border-bottom: 1px solid #ddd;
}
.ScreenTwo_top-bar__3fhe4 {
  padding: 10px 0px;
  padding: 15px 0px 10px;
}
.ScreenTwo_credit-text__fjzs9 {
  font-size: 14px;
  font-family: poppins;
  color: #232121;
  text-align: center;
  /* display: flex;
  justify-content:flex-end; */
}
.ScreenTwo_avail-credit__1umL_{
  text-align: left!important;
}
.ScreenTwo_right-top-credits__3E5SG{
  text-align: right;
  padding: 0;
  margin-bottom: 0px;
  
}
.ScreenTwo_credit-pos__1KOE2{
 
}
.ScreenTwo_right-top-credits__3E5SG button{
  padding: 5px 10px;
  width: auto;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #FF7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.ScreenTwo_right-top-credits__3E5SG img{
  width:25px !important;
}
.ScreenTwo_add-credit__12VDy{
  color: white !important;
}
.ScreenTwo_overlay-pos__1-eWN {
  position: absolute;
  width: 100%;
}
.ScreenTwo_more-btn-overlay-2__10Zal {
  position: absolute;
  top: 25%;
    left: 20%;
    color: black;
    font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.ScreenTwo_more-btn-overlay-2__10Zal span{
  font-size: 17px;
  display: block;
}
.ScreenTwo_credit-left-area__1yxIs{
  width:64%
}
.ScreenTwo_upload-area__2CYVi{
    text-align: center;
    justify-content: center;
}
.ScreenTwo_bg-main__1KOm2{
    /* background: #FBFBFB;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);  */
padding: 35px 0;
margin-top: 70px;
}
.ScreenTwo_inactive-background-sec-below__1LqJg img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}

.ScreenTwo_inactive-background-sec__1nRXS{
  padding: 5px;
  box-shadow: 0 2px 5px 0 #ddd;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  width: auto;
}
.ScreenTwo_inactive-background-sec__1nRXS span{
  font-size: 13px;
}
.ScreenTwo_inactive-background-sec__1nRXS img {
  width: 100%;
  height: 46px;
  cursor: pointer;
  border-radius: 5px;
  /* border: 0.4px solid #333; */
}
.ScreenTwo_img-overlay__21ia5 {
  position: absolute;
  /* background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  ); */
  height: 100%;
  bottom: 0%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
}
.ScreenTwo_marketplaces__2Piuz{
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-row-gap: 9px;
  margin-left: 20px;
  padding: 25px 0;
}
.ScreenTwo_marketplaces__2Piuz img{
  width: 100%;
  height: 80px;
  object-fit: contain;
  padding: 10px;
}
.ScreenTwo_more-box__3tJyB{
  box-shadow: 0 0 0!important;
  padding: 5px;
}
.ScreenTwo_more-box__3tJyB img{
  border: 0!important;
  margin-top: 0;
  height: 100%!important;
  padding: 10px;
}
.ScreenTwo_active-visual-selection__3UDZX{ 
  color: #FF7700;
  background: #fff;
  padding: 10px;
}
.ScreenTwo_inactive-visual-selection__v92OU{
  /* border-bottom: 2px solid #FF7700; */
  /* color: #000; */
  /* padding: 10px; */
  /* background: #F5F5F5; */
  /* border: 1px solid #E0E0E0; */
  /* box-sizing: border-box; */
  /* border-radius: 0px 0px 5px 5px; */
}
.ScreenTwo_custom-background-list__2r_TD{
  padding: 20px 10px 20px 35px ;
}
.ScreenTwo_custom-background-list__2r_TD:before, .ScreenTwo_adjust-height-area__33_RW:before{
  display: inline-block ;
  content: '' ; 
  position: absolute ;
  top: 0 ;
  left: 15px ;
  width: 10px ;
  height: 100% ;
  /* border-left: 1px dashed #ff7700 ; */
}
 
.ScreenTwo_custom-wall__3B-yv{
  padding-top: 10px;
}
.ScreenTwo_side-margin__b5-Pc{
  border-left: 1px dashed #ff7700;
    margin: 0 16px;
}
.ScreenTwo_custom-wall__3B-yv p, .ScreenTwo_adjust-height__1slMF p{
  margin: 0 ;
}
.ScreenTwo_adjust-height__1slMF p{
  padding: 5px 0 ;
  font-size: 16px ;
}
.ScreenTwo_custom-wall__3B-yv p span{
  background: #ff7700 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 13px ;
  margin-right: 10px ;
}
.ScreenTwo_adjust-height__1slMF p span{
  background: #E5E5E5 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 12px ;
  margin-right: 10px ;
}



.ScreenTwo_custom-walls-area__LEZIX{
  padding-left: 10px ;
}
.ScreenTwo_adjust-height-area__33_RW{
  padding: 5px 0px ;
  position: relative ;
}
.ScreenTwo_adjust-height-area__33_RW ul{
  padding: 5px 10% ;
  list-style: none ;
  text-align: center ;
  display: flex ;
}
.ScreenTwo_adjust-height-area__33_RW ul li{
  text-align: center ;
  width: 35% ;
  padding: 10px ;
  
  margin: 0 auto ;
}
.ScreenTwo_adjust-height-area__33_RW ul li button{
  background: rgba(235 215 198 / 0%);
  border: 1px solid #000000;
  box-sizing: border-box ;
  padding: 10px ;
  width: 100% ;    
  height: auto ;
  border-radius: 0 ;
}
.ScreenTwo_adjust-height-area__33_RW ul li button.ScreenTwo_active__1npU3, .ScreenTwo_adjust-height-area__33_RW ul li button:hover{
  background: rgb(235 215 198 / 0%);
  border: 1px solid #FF7700; 
}
.ScreenTwo_adjust-height-area__33_RW ul li h6{ 
  font-weight: normal ;
  font-size: 14px ; 
  color: #000000 ;
  margin: 0 ;
}
.ScreenTwo_adjust-height-area__33_RW ul li img{
  width: 100% ;
}

.ScreenTwo_backgrounds-grid-custom__3zeeU .ScreenTwo_inactive-background-sec__1nRXS img{
  height: 60px ;
  border: 0 ;
}
.ScreenTwo_active-background-sec__3Pdu7:hover .ScreenTwo_white-icon__25TTG, .ScreenTwo_inactive-background-sec__1nRXS:hover .ScreenTwo_white-icon__25TTG{
  display: none;
}
.ScreenTwo_active-background-sec__3Pdu7:hover .ScreenTwo_hoverable-icon__1ZHTo, .ScreenTwo_inactive-background-sec__1nRXS:hover .ScreenTwo_hoverable-icon__1ZHTo{
  display: inline-block;
  }
.ScreenTwo_backgrounds-grid__e9-QY .ScreenTwo_inactive-background-sec__1nRXS {
  position: relative;
}
.ScreenTwo_inactive-background-sec__1nRXS img {
  height: 47px;
}

.ScreenTwo_backgrounds-grid__e9-QY {
  display: grid ;
  grid-template-columns: 30.8% 30.8% 30.8%;
  grid-gap: 9px ;
}
.ScreenTwo_backgrounds-grid-custom__3zeeU {
  display: grid ;
  grid-template-columns: 30.8% 30.8% 30.8%  ;
  grid-gap: 7px ;
  margin-top: 10px;
}
.ScreenTwo_backgrounds-grid-below__2Zzak {
  display: grid ;
  /* justify-content: center ; */
  grid-template-columns: 50% 50% ;
  grid-gap: 9px ;
}
.ScreenTwo_below-button__2sReo {
  background: unset !;
  /* color; */
  border: 2px solid #ff7700  ;
  height: -webkit-fit-content !;
  height: -moz-fit-content !;
  height: fit-content !;
  margin-top: 2% !;
  border-radius: 30px  ;
}
.ScreenTwo_active-background-sec__3Pdu7 span{
  font-size: 13px ;
}
.ScreenTwo_inactive-walls-sec___GlYK {
  padding: 5px ;
  cursor: pointer ;
  -webkit-transform: scale(1) ;
          transform: scale(1) ;
  cursor: pointer ;
  /* height: 85px ; */
  width: auto ;
}
.ScreenTwo_inactive-walls-sec___GlYK span{
  font-size: 13px ;
}
.ScreenTwo_active-walls-sec__NfnUu {
  /* box-shadow: 0 2px 5px 0 #ddd ; */
  padding: 3px ;
  border: 2px solid #ff7700 ;
  -webkit-transform: scale(1) ;
          transform: scale(1) ;
  cursor: pointer ;
  /* height: 110px ; */
  width: auto ;
}
.ScreenTwo_active-walls-sec__NfnUu span{
  font-size: 13px ;
}
.ScreenTwo_active-walls-sec__NfnUu img {
  width: 100% ;
  /* height: 60px ; */
}
.ScreenTwo_inactive-walls-sec___GlYK img {
  width: 100% ;
  /* height: 60px ; */
  border: 0.4px solid #333 ;
}
.ScreenTwo_inactive-walls-sec___GlYK {
  padding: 3px ;
  border: 2px solid transparent ;
  box-shadow: 0 2px 5px 0 #ddd ;
}



.ScreenTwo_custom__h_9P5 {
  font-size: 13px !important;
  font-weight: 500 ;
  /* margin-left: 14% ; */
}
.ScreenTwo_resizable__1XhPM {
  width: 120px ;
  /* border-radius: 0.75rem ;
  padding: 20px ;
  margin: 1rem ;
  background-color: #29e ;
  color: white ;
  font-size: 20px ;
  font-family: sans-serif ; */
  overflow: hidden ;

  touch-action: none ;

  /* This makes things *much* easier */
  object-fit: fill ;
  box-sizing: border-box ;
  position: absolute ;
  opacity: 0.5 ;
  border-bottom: 10px solid #FF7700 ;
  z-index: 2 ;
}
.ScreenTwo_drag-button__26URk{
  width: 50px !;
  height: auto ;
  -webkit-transform: rotate(-90deg) ;
          transform: rotate(-90deg) ;
  /* overflow: hidden ; */
  margin-top: -27px ;
}
.ScreenTwo_wall-cross__2hJp9{
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width : 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 0!important;
}
.ScreenTwo_car-text__P6RDD h1 {
  font-size: 30px;
}
.ScreenTwo_original-image-div__2GEkC {
  width: 100%;
  height: 100%;
}
.ScreenTwo_remove-bg-box__2Ntmz{
  background: #FFFFFF;
  margin: 0;
/* border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
width:90%;
margin-left: 5%; */
}
.ScreenTwo_remove-bg-area__2QqMF{
    padding: 0;
    background: #fbfbfb;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    border-radius: 5px;

}
.ScreenTwo_credit-text__fjzs9 img {
    margin-right: 10px;
    width: 40px;
}
.ScreenTwo_credit-text__fjzs9 span {
    color: #f70;
}
.ScreenTwo_cross-btn__3tybq {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:-webkit-max-content;
    width:max-content;
  }
  .ScreenTwo_cross-img__3CNSg {
    width: auto !important;
    cursor: pointer;
    margin-left: 50%;
  }
  .ScreenTwo_credit-text__fjzs9{
    
  }

  .ScreenTwo_download-popup__1Hg9S p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 15px;
  }
  .ScreenTwo_download-popup__1Hg9S button.ScreenTwo_activate__2fnsk,
  .ScreenTwo_download-popup__1Hg9S button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .ScreenTwo_download-popup__1Hg9S {
    box-shadow: 0px 4px 20px #00000038;
    
  }
  .ScreenTwo_try-drag-drop__-QH1l .ScreenTwo_dropzone1__1ZQow {
    border: 1px dashed #ffa64d;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    opacity: 0.7;
  }
  .ScreenTwo_try-drag-drop__-QH1l .ScreenTwo_dropzone1__1ZQow:hover {
    opacity: 1;
  }
  .ScreenTwo_try-drag-drop__-QH1l .ScreenTwo_dropzone1__1ZQow img {
    margin-bottom: -30px;
    width: 15%;
  }
  
  .ScreenTwo_drop-box-text__2pwH0 {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .ScreenTwo_drop-box-text__2pwH0 div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .ScreenTwo_download-popup__1Hg9S button {
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 85%;;
  }
  .ScreenTwo_cross-btn__3tybq {
    text-align: right;
    padding: 0 0 10px;
  }
  .ScreenTwo_download-popup__1Hg9S button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .ScreenTwo_download-popup__1Hg9S ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .ScreenTwo_download-popup__1Hg9S ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .ScreenTwo_download-popup__1Hg9S p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .ScreenTwo_download-popup__1Hg9S p span {
    color: #ff7700;
  }
  .ScreenTwo_download-popup__1Hg9S h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .ScreenTwo_border-1__18Nsp {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
    margin-top: 20px;
  }
  .ScreenTwo_download-popup__1Hg9S input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .ScreenTwo_download-popup__1Hg9S input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .ScreenTwo_download-btn-hd__Hig1a {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .ScreenTwo_containers__4UP5C {
    max-width: 600px !important;
    border-radius: 10px;
}
  .ScreenTwo_containers__4UP5C img {
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    margin-top: 100px;
    /* margin: 50px auto 0; */
    margin-top: 20px;
    position: relative !important
}
.ScreenTwo_left-sec-upload-image__6vvqT {
    width: auto;
    height: 415px;
}
.ScreenTwo_rating-area__2Xvkk{

}
.ScreenTwo_rating-area__2Xvkk p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.ScreenTwo_rating-area__2Xvkk p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.ScreenTwo_sec-2-right-card__2VFda{ 
    width:41%;
    padding: 0 0  24.6px;
    background: #fff;
    border-radius: 0;  
  
    border-left: 1px solid #ebeaea
   
}
.ScreenTwo_sec-2-left__d5UeX{
  width:58%
}
.ScreenTwo_get-mrktplace__H__QQ{
    line-height: 50px!important;
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.ScreenTwo_get-mrktplace__H__QQ a{
    margin-left: 10px;
}

.ScreenTwo_css-1eg0sfo-skeletonStyles-Skeleton__1blNe {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: ScreenTwo_animation-bzdot9__3Vp41 1.2s ease-in-out infinite;
    animation: ScreenTwo_animation-bzdot9__3Vp41 1.2s ease-in-out infinite;
}
.ScreenTwo_categories__17OnB{

}
.ScreenTwo_categories__17OnB h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.ScreenTwo_categories__17OnB h2 span{
    font-weight: 600;
font-size: 24px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.ScreenTwo_categories__17OnB h2:before {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_categories__17OnB h2:after {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_product-hunt-tag__2hJ_-{
    width: auto;
    position: absolute;
    right: 115px;
    top: 0px;
}

.ScreenTwo_last-enhancement__az5H_{
  margin-left: 40px;
  padding: 20px 0;
}
.ScreenTwo_last-enhancement__az5H_ h6{
  font-style: normal;
font-weight: normal;
font-size: 11.3151px;
line-height: 17px; 
color: #FF7700;
}
.ScreenTwo_skin-retouch__1cZsE{

}
.ScreenTwo_skin-retouch__1cZsE snap{
  font-weight: 500;
font-size: 14.5882px;
line-height: 35px; 
color: #5F5F5F;
}
.ScreenTwo_second-side__jaZeV{
  border: 0!important;
}

.ScreenTwo_btn-sec__2vTIQ button {
  width: 120px;
  height: 44px;
  border-radius: 5px;
}
.ScreenTwo_bg-fashion__qq7VY{
  padding: 5px;
}

.ScreenTwo_info-box__oTfz2{
  background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 6px;
    opacity: 0;
    padding: 10px;
    width: 100px;
    min-height: 50px; 
    z-index: 9;
    position: absolute;
    left: 10px;
    top: 0;
}
.ScreenTwo_info__3ZTB1:hover .ScreenTwo_info-box__oTfz2{
  opacity: 1;
  /* width: 2px; */
}
.ScreenTwo_info-box__oTfz2 p{
  padding: 0;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px; 
  color: #FF7700;
}



.ScreenTwo_toggle1__26R1J {
  position: relative;
  color: #f39e53;
  padding: 5px 0px 5px 5px;
}

.ScreenTwo_toggle1__26R1J input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


.ScreenTwo_slider1__3aU9C {
  position: relative;
  display: block;
  cursor: pointer;
  background: #d64d4d;
  margin-left: 190px;

  
box-shadow: 0px 4px 4px rgb(255, 255, 255);
border-radius: 20px;
  transition: 0.2;
  width: 50px;
  height: 28px;
}

.ScreenTwo_slider1__3aU9C::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 22px;
  background: rgb(255, 255, 255);
  transition: 0.2s;
  border-radius: 50%;
  left: 3px;
  top:3px

}

input:checked + .ScreenTwo_slider1__3aU9C {
  background-color: #78f353;
}

input:checked + .ScreenTwo_slider1__3aU9C::before {
  -webkit-transform: translateX(21.5px);
          transform: translateX(21.5px);
}



























/* @Responsive */





  @media screen and (max-width: 1290px) and (min-width: 1025px){
.ScreenTwo_cross-btn__3tybq {
    padding: 0 0 20px;
}


}

.ScreenTwo_main-box__I62IT{
  margin: 0px 0 50px;
  background: #fff;
}

@media (min-width: 1366px) {
  .ScreenTwo_main-box__I62IT {
    max-width: 93.5%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1440px) {
  .ScreenTwo_main-box__I62IT {
    max-width: 89%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
  .ScreenTwo_product-hunt-tag__2hJ_- {
    right: 20%;
  }
}

@media (min-width: 1680px) {
  .ScreenTwo_main-box__I62IT {
    max-width: 76.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1920px) {
  .ScreenTwo_main-box__I62IT {
    max-width: 66.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2560px) {
  .ScreenTwo_main-box__I62IT {
    max-width: 50%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2880px) {
  .ScreenTwo_main-box__I62IT {
    max-width: 44.2%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.bgRemoval_drop-box-text__308WB {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.bgRemoval_drop-box-text__308WB div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.bgRemoval_car-main-wrapper__34ADD {
  background-size: cover !important;
  padding-bottom: 0px;
}
.bgRemoval_back-button__3BmYT{
  padding-top: 100px !important;
  background-color: #fff;
  border: unset;
  border-radius: 11px;
  justify-content: left;
}
.bgRemoval_header-section__znz5n {
  text-align: center;
}
.bgRemoval_heading-text__175u5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.bgRemoval_heading-para__4hYEz {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.bgRemoval_heading-para__4hYEz span {
  color: #ff7700 !important;
}
.bgRemoval_active-walls-sec__2nS7o img {
  height: 35px;
}
.bgRemoval_inactive-walls-sec__1fmg2 img {
  height: 35px;
}
.bgRemoval_inactive-walls-sec__1fmg2 {
  padding: 5px;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.bgRemoval_backgrounds-grid-custom__2Gd4k {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 7px;
}
.bgRemoval_inactive-walls-sec__1fmg2 span {
  font-size: 13px;
}
.bgRemoval_active-walls-sec__2nS7o {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.bgRemoval_active-walls-sec__2nS7o span {
  font-size: 13px;
}
.bgRemoval_active-walls-sec__2nS7o img {
  width: 100%;
  height: 90px;
}
.bgRemoval_inactive-walls-sec__1fmg2 img {
  width: 100%;
  height: 90px;
}
.bgRemoval_inactive-walls-sec__1fmg2 {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.bgRemoval_wrapper__3NXpF {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.bgRemoval_left-section__2TJFY {
  width: 40%;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.bgRemoval_mid-section__1ZBcP {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.bgRemoval_sample-button__LI4aE {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.bgRemoval_sample-button__LI4aE:hover {
  color: black;
}
.bgRemoval_dropzone__2Kum7 {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.bgRemoval_dropzone__2Kum7:hover {
  opacity: 1;
}
.bgRemoval_dropzone__2Kum7:focus {
  outline: none !important;
  border: none !important;
}
.bgRemoval_para-1__-ufRK {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.bgRemoval_para-2__21L9J {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 0px 0 10px;
}
.bgRemoval_para-2__21L9J span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
}
.bgRemoval_supports__3j8m9 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.bgRemoval_choose-button__1hbpe {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.bgRemoval_choose-button__1hbpe button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.bgRemoval_choose-button__1hbpe button:hover {
  color: #ffffff;
}
.bgRemoval_choose-button__1hbpe button:focus {
  outline: none !important;
}
.bgRemoval_right-section__1pgR0 {
  width: 55%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 25px;
  margin-left: 5%;
  height: auto;
}
/* .right-section div{
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
} */
.bgRemoval_right-section__1pgR0 img {
  /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  width: 100%;
}
.bgRemoval_section-two__1VX3i {
  padding: 57px;
  background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Desktop+-+2+1.png');
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-repeat: repeat-x;
  background-color: white;
}
.bgRemoval_section-two-one__1WmO- {
  margin-top: 125px;
}
.bgRemoval_section-two-one__1WmO- h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 132%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  margin-top: -40px;
}
.bgRemoval_section-two-one__1WmO- p {
  max-width: 519px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}
.bgRemoval_section-two-one__1WmO- span {
  color: #ff7700;
}
.bgRemoval_section-two-one-right__RaY5x {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5886+1.png');
  padding: 70px 50px;
  margin-top: -125px;
  align-items: center;
}
.bgRemoval_section-two-one-right__RaY5x div {
  max-width: 571px;
}
.bgRemoval_section-two-three-right__1_HXr {
  margin-top: -125px;
  align-items: center;
}
.bgRemoval_section-3__29Kn5 {
  padding: 57px;
}
.bgRemoval_slider__uLAk_ {
  height: 100%;
  width: 3px;
  background: white;
}
.bgRemoval_slider__uLAk_ img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  margin-left: -25px;
}
.bgRemoval_section-3__29Kn5 {
  background: #ffffff;
}
.bgRemoval_section-3-right__20OiX div {
  vertical-align: center;
  /* position: absolute;
    top: 50%;
    transform: translate(-50%,-50%); */
  margin-left: 135px;
  margin-top: 20%;
  text-align: center;
}
.bgRemoval_section-3-right__20OiX p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.bgRemoval_section-3-right__20OiX button {
  border: unset;
  width: 220px;
  height: 60px;
  background: #ff7700;
  border-radius: 30.5px;
}
.bgRemoval_section-3-right__20OiX a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
}

.bgRemoval_mob-area__1Alzw {
  padding-top: 0%;
}

.bgRemoval_mob-area__1Alzw .bgRemoval_why-use__3hIgh {
  text-align: left;
  padding: 10% 5% 0;
}

.bgRemoval_why-use__3hIgh h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}

.bgRemoval_mob-area__1Alzw .bgRemoval_why-use__3hIgh p {
  margin-bottom: 30px;
}

.bgRemoval_request-demo__vn__E {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.bgRemoval_mob-area__1Alzw .bgRemoval_why-use__3hIgh .bgRemoval_request-demo2__1lS9B {
  display: block;
}

.bgRemoval_mob-area__1Alzw .bgRemoval_why-use__3hIgh p img {
  margin-top: 30px;
}

.bgRemoval_request-demo2__1lS9B img {
  height: 42px;
  /* margin-left: 20px; */
}

.bgRemoval_custom-backgrounds__swQfZ {
  padding: 60px 5% 40px;
}

.bgRemoval_title__Yt3K4 {
  text-align: center;
}

.bgRemoval_title__Yt3K4 h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.bgRemoval_exterior-tabs__1WcTb ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}

.bgRemoval_car-try-wrapper__aEi4i {
}
.bgRemoval_try-images-area__Ap0Bf {
  padding: 5px 15px;
}
.bgRemoval_car-left-section__30M2J {
  margin-top: 25px;
}
.bgRemoval_try-drag-drop__1TXz- {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  cursor: pointer;
}
.bgRemoval_try-drag-drop__1TXz- .bgRemoval_dropzone1__4RJpI {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.bgRemoval_try-drag-drop__1TXz- .bgRemoval_dropzone1__4RJpI:hover {
  opacity: 1;
}
.bgRemoval_try-drag-drop__1TXz- .bgRemoval_dropzone1__4RJpI img {
  margin-bottom: -30px;
  width: 15%;
}
.bgRemoval_para-11__Se2_s {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
}
.bgRemoval_supports1__17KVU {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
.bgRemoval_perfect-output__1JXGf {
}
.bgRemoval_choose-output__1TeHs {
}
.bgRemoval_heading__1W6vV h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.bgRemoval_heading__1W6vV h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.bgRemoval_heading__1W6vV h3 .bgRemoval_border-1__34-6m {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.bgRemoval_perfect-output-images__20-pe {
  text-align: center;
  padding: 20px 15px 0;
}
.bgRemoval_perfect-output-images__20-pe img {
  width: 100%;
}
.bgRemoval_perfect-output-images__20-pe p {
  text-align: center;
}
.bgRemoval_perfect-output-images__20-pe p span a {
  font-style: normal;
  color: #ff7700 !important;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  cursor: pointer;
}

.bgRemoval_perfect-output-images__20-pe p a {
  background: #ff7700;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.bgRemoval_bg-try__1gi7v {
  /* background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
  background-position: top right;
  background-repeat: no-repeat; */
}

.bgRemoval_choose-button__1hbpe {
}
.bgRemoval_choose-button__1hbpe button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: -webkit-max-content;
  width: max-content;
}
.bgRemoval_bg-recommended__Ft8gj {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  /* overflow: hidden;
  overflow-y: scroll; */
}
.bgRemoval_bg-recommended__Ft8gj h6 {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
  font-weight: 600;
}
.bgRemoval_bg-recommended__Ft8gj h6 span {
  color: #ff7700;
}
.bgRemoval_bg-recomended-images__UZJRM {
  list-style: none;
  display: block;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.bgRemoval_bg-recomended-images__UZJRM li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 10px;
  cursor: pointer;
  width: 50%;
}
.bgRemoval_bg-recomended-images__UZJRM li img {
  width: 100%;
  height: 56px;
}
.bgRemoval_wall-cross__2bULs {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.bgRemoval_active-background-sec__1xyMo img {
  /* border: 2px solid #000;
  transform: scale(1.1); */
}
.bgRemoval_active-background-sec__1xyMo {
  border: 2px solid #000;
  padding: 3px !important;
}
.bgRemoval_bg-recomended-images__UZJRM li span {
  font-size: 11px;
  font-weight: 500;
}
.bgRemoval_perfect-output-1__3SfE4 {
  padding: 40px 0px;
  background: #fff;
}
.bgRemoval_perfect-output-1__3SfE4 .bgRemoval_heading__1W6vV {
  margin-bottom: 20px;
}
.bgRemoval_bg-recomended-area__18Gib {
  overflow-y: scroll;
  height: 400px;
}

.bgRemoval_bg-recomended-right__S9BTs img {
  width: 100%;
}

.bgRemoval_bg-recommended__Ft8gj p {
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
}

.bgRemoval_bg-recomended-right__S9BTs {
}
.bgRemoval_bg-recomended-right__S9BTs img {
  margin-top: 20%;
}

.bgRemoval_bg-recomended-area__18Gib .bgRemoval_perfect-output-images__20-pe p {
  margin-top: 2%;
}
.bgRemoval_bg-recomended-area__18Gib .bgRemoval_perfect-output-images__20-pe p a {
  padding: 10px;
}

.bgRemoval_download-popup__2EJtH {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.bgRemoval_download-popup__2EJtH button {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}
.bgRemoval_download-popup__2EJtH ul {
  padding-left: 15%;
  margin: 0 0 5px;
}
.bgRemoval_download-popup__2EJtH ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.bgRemoval_download-popup__2EJtH p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 10%;
}
.bgRemoval_download-popup__2EJtH p span {
  color: #ff7700;
}
.bgRemoval_download-popup__2EJtH h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
}
.bgRemoval_download-popup__2EJtH input {
  margin-right: 7%;
  border: 0;
  outline: 0;
}
.bgRemoval_download-popup__2EJtH input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.bgRemoval_download-popup__2EJtH button {
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: left;
}
.bgRemoval_download-popup__2EJtH p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.bgRemoval_download-popup__2EJtH button.bgRemoval_activate__iZ0Ok,
.bgRemoval_download-popup__2EJtH button:hover {
  background: rgba(5, 121, 223, 0.05) !important;
  border: 1px solid #78838d !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.bgRemoval_heading-text__175u5{
  padding: 0 20px;
}
/* Upload screen User credits */

.bgRemoval_try-credit__1QWWV{
padding: 100px 6% 15px;
text-align: right;
}
 
.bgRemoval_try-credit__1QWWV span{
  border: 2px solid #ffe7d2;
    border-radius: 10px; 
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 12px 10px;
    letter-spacing: .5px;
    margin-top: 7px;
    color: #232121;
  /* position: absolute;
  right: 20px; */
}
.bgRemoval_try-credit__1QWWV span b{
  color: #ff7700;
}
.bgRemoval_try-credit__1QWWV span img{
  margin-right: 20px;
}









/* @Responsive */
@media (max-width: 800px) {
  .bgRemoval_car-main-wrapper__34ADD {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .bgRemoval_wrapper__3NXpF {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .bgRemoval_right-section__1pgR0 {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .bgRemoval_left-section__2TJFY {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .bgRemoval_heading-text__175u5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__4hYEz {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .bgRemoval_section-two__1VX3i {
    padding: 27px;
  }
  .bgRemoval_section-two-one__1WmO- {
    margin-top: 70px;
  }
  .bgRemoval_number-image__3dWbd {
    display: none;
  }
  .bgRemoval_section-two-one__1WmO- h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .bgRemoval_section-two-one__1WmO- p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-two-one-right__RaY5x {
    margin-top: 0px;
  }
  .bgRemoval_section-3-right__20OiX div {
    margin-left: 0px;
  }
  .bgRemoval_mob-change__2Dz-0 {
    display: flex;
    flex-direction: column-reverse;
  }
  .bgRemoval_mob-change__2Dz-0 h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-two-three-right__1_HXr img {
    margin-top: 120px;
  }
  .bgRemoval_section-3__29Kn5 {
    padding: 27px;
  }
  .bgRemoval_section-3-right__20OiX p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_section-3-right__20OiX button {
    width: 150px;
    height: 50px;
  }
  .bgRemoval_try-images-area__Ap0Bf {
    padding: 5px 0;
    order: 1;
  }
  .bgRemoval_car-left-section__30M2J {
    order: 3;
  }
  .bgRemoval_perfect-output-images__20-pe p {
    text-align: center;
    margin: 0;
  }
  .bgRemoval_try-images-area__Ap0Bf img {
    width: 100%;
    margin: 20px 0;
  }
  .bgRemoval_choose-button__1hbpe {
    padding: 10% 10px;
  }
  .bgRemoval_bg-try__1gi7v {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .bgRemoval_perfect-output-images__20-pe {
    order: 3;
    padding: 15px 0;
  }
  .bgRemoval_bg-recomended-area__18Gib {
    overflow: visible;
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .bgRemoval_bg-recomended-right__S9BTs img {
    margin: 0px 0px 30px;
  }
  .bgRemoval_custom-big__rO7Pv {
    display: none;
  }
  .bgRemoval_bg-recomended-right__S9BTs {
    padding: 0;
  }
  .bgRemoval_bg-recommended__Ft8gj {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .bgRemoval_active-background-sec__1xyMo {
    padding: 0 10px;
    border-radius: 5px;
  }
  .bgRemoval_custom-wall__w6fbG {
    height: 90px !important;
    width: 100%;
    margin-bottom: 16px;
  }

  .bgRemoval_inactive-background-sec__2Vapb {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .bgRemoval_active-background-sec__1xyMo {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 5px;
    border: 2px solid #ff7700;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .bgRemoval_perfect-output-images__20-pe p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .bgRemoval_heading__1W6vV h3 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .bgRemoval_heading-text__175u5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__4hYEz {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .bgRemoval_section-two-one__1WmO- p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_mob-change__2Dz-0 h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .bgRemoval_try-images-area__Ap0Bf img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .bgRemoval_heading-text__175u5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .bgRemoval_heading-para__4hYEz {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .bgRemoval_left-section__2TJFY {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .bgRemoval_left-section__2TJFY {
    margin-top: 10%;
  }
}

.bgRemoval_product-hunt-strip__2zQe6 {
  width: 100%;
  background-color: #cc4d29;
  position: fixed;
  top: 12%;
  z-index: 999;
}

.bgRemoval_product-hunt-strip__2zQe6 h6 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding-top: 4px;
}

.TrustedCars_request-demo__2yKU3{
    font-style: normal;
    font-size: 16px; 
    text-align: center;
    letter-spacing: 0.1px;
    background: #00a8ff;
    border-radius: 10px;
    color: #FFFFFF;
    margin: 0;
    padding: 8px 25px;
    transition: .2s ease-in-out;
    border: 2px solid #00a8ff;
    height: 42px;
    font-weight: bold;
    border-radius: 5px;
    line-height: normal;
    line-height: initial;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0px 0px 5px 0px #6c757d;
}

.TrustedCars_request-demo__2yKU3:hover{
    border: 2px solid #00a8ff;
    background: #fff;
    color: #00a8ff;
}
.TrustedCars_car-v2-left__2GIAj{

}
 
.TrustedCars_why-use__3vLKl h1 span{
    border-bottom: 2px solid #00a8ff;
    padding-bottom: 5px;
    color: #1C1E52;
}
 

.TrustedCars_why-use__3vLKl h1{
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 42px;   
     margin-bottom: 25px;
    letter-spacing: 0.02em;
    color: #323232;
    padding: 0;
}
 
 
.TrustedCars_why-tabs__2mgBX li button { 
    background: #FFFFFF!important;
    box-shadow: 0px 4px 11px rgb(0 0 0 / 25%)!important;
    border-radius: 10px!important;
    margin: 0 0px 10px!important;
    padding: 15px 20px;
    text-align: left;

    width: 100%;
    height: 100%;

}
.TrustedCars_why-tabs__2mgBX{
    padding: 0;
    list-style: none;
    margin: 0 0 10px;
}
.TrustedCars_why-tabs__2mgBX li button{
    font-weight: 600!important;
    font-size: 24px!important;
    border-radius: 10px!important;
    line-height: 45px!important; 
    color: #1D293F!important;
    border-left: 5px solid #fff;
    background-color: #fff!important; 
    transition: .3s ease-in-out;
}
.TrustedCars_why-tabs__2mgBX img{
    float: left;
    margin-right: 0;
    width: 11%;
    vertical-align: middle;
    margin-top: 1%;
}
.TrustedCars_why-text__2viXn{
    width: 87%;
    float: right;
}
.TrustedCars_why-text__2viXn h6{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: #1D293F;
    margin: 0;
}
.TrustedCars_why-text__2viXn p{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px; 
    color: #7C7C7C;
    margin: 0;
    display: flex;
}
.TrustedCars_why-tabs__2mgBX li button.TrustedCars_activate__2ZzY9, .TrustedCars_why-tabs__2mgBX li button:hover {
    /* box-shadow: 0px 0px 20px #ff7700!important;  */
    border-left: 5px solid #00a8ff;
  }
  .TrustedCars_banner-text__Ar-pL{
    margin-top: 5%;
    text-align: left;
    }
    .TrustedCars_banner-text__Ar-pL h1{
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 40px;
        color: #1C1E52;
        margin: 0 0 25px;
        letter-spacing: 1px;
    }
    .TrustedCars_banner-text__Ar-pL p{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 45px; 
        color: #444444;
    }
.TrustedCars_banner-text__Ar-pL p span{
color: #00a8ff;
}
.TrustedCars_title__1Rfza{
text-align: center;
}
.TrustedCars_title__1Rfza h1{
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 35px; 
color: #00a8ff;
margin-bottom: 45px; 
margin-top: 0;
}
.TrustedCars_title__1Rfza h1 span{
    border-bottom: 2px solid #00a8ff;
    color: #1C1E52;
}    
.TrustedCars_exterior-tabs__2d8uk .TrustedCars_title__1Rfza ul li button.TrustedCars_activ__3fflH, .TrustedCars_exterior-tabs__2d8uk .TrustedCars_title__1Rfza ul li button:hover, .TrustedCars_exterior-tabs__2d8uk .TrustedCars_title__1Rfza ul li button.TrustedCars_virtual__3HMsf{
    color:  #00a8ff!important;
    border-bottom: 1px solid  #00a8ff!important;
    border-radius: 0;
    background: transparent;
    }
    .TrustedCars_graph-area__2iihq .TrustedCars_title__1Rfza{
    text-align: left;
    padding-top: 10%;
}
.TrustedCars_graph-area__2iihq{
    padding: 60px 0px;
}
.TrustedCars_graph-area__2iihq .TrustedCars_title__1Rfza{
    text-align: left;
    padding-top: 10%;
}

.TrustedCars_graph-image__2Ins6{

}
.TrustedCars_graph-image__2Ins6 img{
width: 100%;
}        
.TrustedCars_exterior-tabs__2d8uk  ul{
    text-align: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 25px;
}
.TrustedCars_exterior-tabs__2d8uk  ul li{ 
    padding: 3px;
    margin: 10px 5px;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    box-shadow: 0px 2px 3px 0px #ddd;
}
.TrustedCars_slates-tabs__1MrSc li{
    padding: 5px 10px!important; 
    margin: 10px!important;
}
.TrustedCars_exterior-tabs__2d8uk  ul li.TrustedCars_selected__2Oz2L{
    background: #fff; 
    color: black;
    border-radius: 0;
    border: 0;
    color: #ff7700 !important;
    border-bottom: 2px solid #FF7700 !important;
    border-radius: 0;
    background: transparent;
}
.TrustedCars_exterior-tabs__2d8uk  ul li button{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    border-radius: 0!important;
}





@media screen and (max-width: 800px) and (min-width: 200px){
.TrustedCars_title__1Rfza h1 { 
    font-size: 18px;
    line-height: 30px;

margin-bottom: 20px;

}
.TrustedCars_request-demo__2yKU3{
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    line-height: normal;
    line-height: initial;
    height: 40px;
    margin: 0 auto;
}
.TrustedCars_graph-area__2iihq {
padding: 0 0 ;
}
.TrustedCars_banner-text__Ar-pL {
    margin-top: 10%;
    text-align: center;
    order: 2;
}
.TrustedCars_banner-text__Ar-pL h1 { 
    font-size: 20px;
    line-height: normal;
    line-height: initial; 
    margin: 0 0 15px;
}
.TrustedCars_banner-text__Ar-pL p { 
    font-size: 12px;
    line-height: normal;
    line-height: initial; 
}

.TrustedCars_why-tabs__2mgBX li button { 
    padding: 10px;
}
 
.TrustedCars_why-tabs__2mgBX img {
    width: 15%;
    margin-top: 10px;
}
.TrustedCars_why-tabs__2mgBX p{
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: normal;
    line-height: initial;
    }
    .TrustedCars_exterior-tabs__2d8uk ul li {
        margin: 5px 6px 0!important;
        font-size: 12px;
        padding: 2px!important;
    }
    .TrustedCars_graph-area__2iihq .TrustedCars_title__1Rfza {
        text-align: center;
        padding-top: 5px;
        order: 2;
        margin-bottom: 20px;
    }
    .TrustedCars_title-area__yEMkg p {
        display: none;
    }
    
    .TrustedCars_banner-text__Ar-pL .TrustedCars_request-demo__2yKU3 {
        margin: 0 0;
    }
}

@media screen and (max-width: 900px) and (min-width: 768px){
.TrustedCars_banner-text__Ar-pL {
    margin-top: 5%;
    text-align: left;
    order: inherit;
}
}

.GroceryLanding_grocery-banner__1Mv7L{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Grocery/Group%205892432%20(1).png');
    margin-top: 45px;
    padding: 25px 3%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 760px;
}
.GroceryLanding_grocery-banner-text__1UcKx{
    padding-top: 10%;
    text-align: left;
}
.GroceryLanding_grocery-banner-text__1UcKx h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    color: #323232;
}
.GroceryLanding_grocery-banner-text__1UcKx p{
    font-weight: 600;
    font-size: 24px;
    line-height: 45px; 
    color: #444444;
    margin: 20px 0px;
}
.GroceryLanding_grocery-banner-text__1UcKx p span{
    color: #FF7700;
}
.GroceryLanding_grocery-banner-image__5vzoT{

}
.GroceryLanding_grocery-banner-image__5vzoT img{
    width: 100%;
}
.GroceryLanding_gif-area__1H7Aw{
    padding: 70px 0px 50px;
}
.GroceryLanding_twenty-area__3ViOU .GroceryLanding_title__3IFDk h1{
    margin-bottom: 10px;
}
.GroceryLanding_slider__yhoVO {
    height: 100%;
    width: 3px;
    background: #fff;
}
.GroceryLanding_slider__yhoVO img {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 43%;
    margin-left: -25px;
}
.GroceryLanding_twenty-image__1Fy4i{
    margin-bottom: 40px;
}
.GroceryLanding_twenty-area__3ViOU{
    padding: 60px 0px;
}
.GroceryLanding_twenty-area__3ViOU .GroceryLanding_title__3IFDk h1{
    margin-bottom: 10px;
}
.GroceryLanding_twenty-area__3ViOU .GroceryLanding_title__3IFDk{
    margin-bottom: 25px;
}
.GroceryLanding_title__3IFDk {
    text-align: center;
}
 
.GroceryLanding_title__3IFDk h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 45px;
    color: #FF7700;
    margin-bottom: 45px;
    margin-top: 0;
}
.GroceryLanding_title__3IFDk h1 span {
    border-bottom: 2px solid #FD8061;
    color: #1C1E52;
}
.GroceryLanding_title__3IFDk h1 span {
    border-bottom: 2px solid #FD8061;
    color: #1C1E52;
}
.GroceryLanding_no-border__Wv15T {
    border: none!important;
}



.GroceryLanding_grocery-tabs__24WHr .GroceryLanding_exterior-tabs__3NGFd ul li {
   
}
.GroceryLanding_exterior-tabs__3NGFd  ul li button{
    transition: .2s ease-in-out;
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    border-radius: 5px!important;
    margin: 0; 
    padding: 5px 15px; 
    box-shadow: -1px 2px 5px 0px #ddd;
}
.GroceryLanding_exterior-tabs__3NGFd  ul li button.GroceryLanding_active__POVVa, .GroceryLanding_exterior-tabs__3NGFd  ul li button:hover{
    color: #fff !important;
 
 border-radius: 0;
 background: #FF7700;
 }
 .GroceryLanding_exterior-tabs__3NGFd  ul{
     text-align: center;
     justify-content: center;
     padding: 0px;
     margin: 10px 0;
 }
 .GroceryLanding_exterior-tabs__3NGFd  ul li{  
    padding: 3px;
    margin: 0 10px 5%;
     background: transparent;
     list-style: none;
     display: inline-block; 
     border-radius: 0; 
 }
.GroceryLanding_v-image__2qHeL{
    margin-top: 5%;
}
.GroceryLanding_category-images__2DV54{

}
.GroceryLanding_category-images__2DV54 img{
width: 80%;
}

/* @Responsive  */

@media screen and (max-width: 767px) and (min-width: 200px){
    .GroceryLanding_grocery-banner-text__1UcKx{
        text-align: center;
    }
.GroceryLanding_grocery-banner-text__1UcKx h2 { 
    font-size: 20px;
    line-height: 30px;
}
.GroceryLanding_grocery-banner-text__1UcKx p { 
    font-size: 13px;
    line-height: 20px;
    margin: 0px 0px 10px;
}
.GroceryLanding_grocery-banner-text__1UcKx{
    padding: 8% 0 0;   
}

.GroceryLanding_grocery-banner__1Mv7L { 
    height: auto;
}
.GroceryLanding_exterior-tabs__3NGFd ul li button {
    font-size: 12px;
    line-height: 20px;
    padding: 5px;
}

.GroceryLanding_exterior-tabs__3NGFd ul li {
    padding: 5px;
    margin: 0 0 0%; 
    width: 25%;
}
.GroceryLanding_virtual-images__14jYd img {
    width: 90%;
    margin: 0 auto;
}
.GroceryLanding_v-image__2qHeL { 
    padding: 10px 15%!important;
}

.GroceryLanding_twenty-area__3ViOU .GroceryLanding_title__3IFDk h1 { 
    font-size: 18px;
    line-height: 30px;
}

.GroceryLanding_twenty-area__3ViOU{
    padding: 30px 0px 0;
}
.GroceryLanding_alendly-area__1IaH0 .GroceryLanding_title__3IFDk {
    margin-bottom: 25px;
    padding: 0;
}








}


@media screen and (max-width: 900px) and (min-width: 768px){
    .GroceryLanding_grocery-banner__1Mv7L {
       height: auto;
        padding: 25px 0% 20%;
    }
    .GroceryLanding_grocery-banner-text__1UcKx h2 { 
        font-size: 35px;
    }
    .GroceryLanding_grocery-banner-image__5vzoT {
        padding-top: 5%;
    }
    .GroceryLanding_gif-area__1H7Aw {
        padding: 70px 0px 0px;
    }




}
















.FootwearLandingModal_firstStep__2XQq4 {
  /* background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/ezgif%201.png'); */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: contain;
}
.FootwearLandingModal_firstStep__2XQq4 img {
  width: 69%;
}
.FootwearLandingModal_drop-menu__1rgQ5{
  padding: 0 10px !important;
  /* color: #5b646d !important; */
  font-weight: 500 !important;
  margin: 0px 0px 20px;

}
.FootwearLandingModal_drop-menu__1rgQ5 button{
  color: #5b646d !important;
  padding: 0px 10px;
}
.FootwearLandingModal_drop-menu__1rgQ5 button:hover{
  color: #5b646d !important;
}
.FootwearLandingModal_drop-list__2_O5R{
  width: 79.5% !important;

}
.FootwearLandingModal_distributor__19e_u {
  width: 81.5% !important;
  background: white !important;
  /* border-bottom: 0px solid #928b8b; */
  /* color: #5b646d !important; */
  border: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #ced4da !important;
  /* color:#5b646d !important; */
  text-align: left;
}

.FootwearLandingModal_signup-button__2PEj8 {
  /* position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%); */
  background-color: white !important;
  color: #ff7700 !important;
  width: 150px;
  border-radius: 45px;
  font-size: 16px !important;
  margin-bottom: 12px;
}

.FootwearLandingModal_circle-area-inner__1hLHN a {
  border-bottom: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.FootwearLandingModal_login-check-modal__xjGWE img {
  width: 60px;
  height: auto;
}

.FootwearLandingModal_got-btn__2ydJy {
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 140px;
  height: 40px;
  margin: 20px 0;
}

.FootwearLandingModal_cars-img__1xdvE {
  width: 100%;
}

.FootwearLandingModal_img-sec__AMZxL {
  height: 355px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 62px;
  text-align: center;
  width: 85%;
}

.FootwearLandingModal_close-btn__3t33r {
  width: 15px !important;
  cursor: pointer;
}

.FootwearLandingModal_cars-circle__3Tzg5 {
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 50%;
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.FootwearLandingModal_cars-circle-active__3cJNL {
  border: 1px solid #ff7700 !important;
  background: #f1d7bf !important;
  border-radius: 50%;
  margin-top: 40px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.FootwearLandingModal_cars-circle__3Tzg5 img {
  margin-top: -13px;
  margin-left: 14px;
}

.FootwearLandingModal_cars-circle-active__3cJNL img {
  margin-top: -13px;
  margin-left: 14px;
}

.FootwearLandingModal_login-here__2upmj {
  cursor: pointer;
  border-bottom: 1px solid #ff0000;
}

.FootwearLandingModal_forgot-password__1sL80 {
  font-size: 15px;
  color: #ff7700;
  /* border-bottom: 1px solid #ff7700; */
  cursor: pointer;
}

.FootwearLandingModal_login-check-modal__xjGWE input {
  width: 80%;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  margin: 25px 0 20px;
}
.FootwearLandingModal_login-check-modal__xjGWE p {
  padding-top: 1%;
  font-size: 25px;
  margin-bottom: 0%;
}
.FootwearLandingModal_login-check-modal__xjGWE form p {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #2d2b29;
  font-weight: 400;
  padding: 0;
}
.FootwearLandingModal_login-check-modal__xjGWE form p a {
  color: #ff7700;
}
.FootwearLandingModal_login-check-modal__xjGWE button {
  border: unset;
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 50%;
  height: 45px;
  margin: 25px 0 25px;
}

.FootwearLandingModal_or__1jBjA {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.2px;
  color: #787878;
  border-top: 1px solid #ddd;
  margin: 0px 20% 0px;
}
.FootwearLandingModal_or__1jBjA span {
  background: #fff;
  position: relative;
  top: -12px;
  padding: 10px;
}

.FootwearLandingModal_img-sec__AMZxL img {
  object-fit: contain;
  width: 82%;
  text-align: center;
  padding-bottom: 40px;
}

.FootwearLandingModal_car-text__m5qRR h1 {
  font-size: 30px;
}
.FootwearLandingModal_circle-area__300hg {
  padding: 25px;
  background: #f79c4c;
  width: 62%;
  height: 100%;
  border-radius: 25%;
  position: absolute;
  margin: 0 auto 0;
  margin-right: -15%;
  top: 0;
  right: 0;
}
.FootwearLandingModal_circle-area-inner__1hLHN {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 25%;
  height: 100%;
  width: 100%;
  text-align: center;
}
.FootwearLandingModal_circle-area-inner__1hLHN h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 139.5%;
  text-align: center;

  color: #ffffff;
}
.FootwearLandingModal_circle-area-inner__1hLHN p {
  font-weight: normal;
  font-size: 17px;
  line-height: 139.5%;
  text-align: center;
  color: #ffffff;
}

.FootwearLandingModal_circle-area-inner__1hLHN span {
  font-weight: 600;
}
.FootwearLandingModal_overlays-models-text__38yj0 {
}
.FootwearLandingModal_overlays-models-text__38yj0 p {
  padding: 10px 0 0;
  margin: 0;
}

.FootwearLandingModal_login-check-modal__xjGWE h6 {
  text-align: left;
  padding-left: 87px;
  color: red;
  font-size: 12px;
}
.FootwearLandingModal_product-hunt__M9C2j img{
  width: 250px;  
  margin: 0 0 0 10px;
}
.FootwearLandingModal_product-hunt-tag__7on3f{
  padding: 0;
  margin-top: 5px;
}
.FootwearLandingModal_product-hunt-tag__7on3f a{
border: 0!important;
}




@media (max-width: 768px) {
  .FootwearLandingModal_hide-in-mobile__1JmVV {
    display: none !important;
  }
  .FootwearLandingModal_car-text__m5qRR h1 {
    font-size: 19px;
  }

  .FootwearLandingModal_firstStep__2XQq4 {
    height: 370px;
    width: 100%;
  }

  .FootwearLandingModal_signup-button__2PEj8 {
    width: 100px;
    height: 30px;
    padding: 0;
    font-size: 11px !important;
  }

  .FootwearLandingModal_login-check-modal__xjGWE img {
    width: 40px;
    height: auto;
  }

  .FootwearLandingModal_login-check-modal__xjGWE p {
    padding-top: 10px;
    font-size: 18px;
  }
  .FootwearLandingModal_login-check-modal__xjGWE input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ced4da;
    margin-top: 35px;
  }

  .FootwearLandingModal_login-check-modal__xjGWE button {
    border: unset;
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 230px;
    height: 40px;
    margin: 35px 0 20px;
  }

  .FootwearLandingModal_cars-circle__3Tzg5 {
    width: 55px;
    height: 55px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
  }

  .FootwearLandingModal_cars-circle-active__3cJNL {
    border: 1px solid #ff7700 !important;
    background: #f1d7bf !important;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 55px;
  }

  .FootwearLandingModal_cars-circle__3Tzg5 img {
    margin-top: 0px;
    margin-left: 0px;
  }

  .FootwearLandingModal_cars-circle-active__3cJNL img {
    margin-top: 0px;
    margin-left: 0px;
  }
  .FootwearLandingModal_img-sec__AMZxL {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 26px;
    text-align: center;
    width: 98%;
    height: 275px;
  }
  .FootwearLandingModal_img-sec__AMZxL img {
    width: 57%;
  }

  .FootwearLandingModal_firstStep__2XQq4 img {
    display: block;
  }
  .FootwearLandingModal_circle-area__300hg {
    padding: 10px;
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-right: 0;
    position: relative;
  }
  .FootwearLandingModal_circle-area-inner__1hLHN {
    border-radius: 0;
    padding: 10px;
  }
  .FootwearLandingModal_circle-area-inner__1hLHN h3 {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .FootwearLandingModal_circle-area-inner__1hLHN p {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .FootwearLandingModal_firstStep__2XQq4 {
    height: 450px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 901px) {
  .FootwearLandingModal_circle-area__300hg {
    margin-right: -15%;
  }
}

.FootwearBgV2_upload-footwear-image__2vhY3{
    padding: 0px 0 25px;
}
.FootwearBgV2_left-sku__3BIlt{
    margin: 0;

}
.FootwearBgV2_left-sku__3BIlt h5{
    font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 36px;
color: #FF7700;
margin: 0;

}
.FootwearBgV2_upload-sec__1IsJl button img {
    width: 23px;
    margin-right: 10px;
}
.FootwearBgV2_right-credits__yQp0H{
    font-size: 14px;
    color: #232121;
    line-height: 36px;

}
.FootwearBgV2_credit-sec__3ozGQ{
    width: 100%;
    padding-left: 60%;
    padding-bottom: 1%;
    display: grid;
    grid-template-columns: 50% 50%;
    /* box-shadow: 0px 0px 10px rgb(0 0 0 / 20%); */
}
.FootwearBgV2_right-top-credits__sTSJR{
    text-align: right;
    padding: 0;
    margin-bottom: 10px;
    width:18%
  }
  .FootwearBgV2_credit-pos__2Va3y{
    width: 100%; 
    margin-left: 50%;
  }
  .FootwearBgV2_right-top-credits__sTSJR button{
    padding: 5px 10px;
    width: auto;
    background:linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #FF7700;
    background-blend-mode: soft-light, normal, normal;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
    border-radius: 32px;
  }
  .FootwearBgV2_right-top-credits__sTSJR img{
    width:25px !important;
  }
.FootwearBgV2_right-credits__yQp0H img{
    margin-right: 10px;
    width: 45px;
    vertical-align: text-top;
}
.FootwearBgV2_right-credits__yQp0H span{
    color: #ff7700;
}
.FootwearBgV2_background-tabs__1i45M{
list-style: none;
padding: 10px 10px 0;
justify-content: center;
}
.FootwearBgV2_background-tabs__1i45M li{
    padding: 20px;
    display: inline-block;
    width: auto;
    margin: 0 30px;
}
.FootwearBgV2_background-tabs__1i45M li button{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    border-bottom: 2px solid transparent;
    text-align: center;
    letter-spacing: 0.5px;
    background: transparent;
    color: #5E5E5E;
    border-radius: 0;
    padding: 0;
}

.FootwearBgV2_background-tabs__1i45M li button.FootwearBgV2_active__A9TRF, .FootwearBgV2_background-tabs__1i45M li button:hover{
    border-bottom: 2px solid #FF7700;
    color: #FF7700;
}
.FootwearBgV2_left-frame__17xg4{
    width: 20%;
    /* display: inline-block; */
    height: 360px;
    object-fit: contain;
}
.FootwearBgV2_background-tabs__1i45M div.FootwearBgV2_edited_image__3mwJs{
    width: 90%;
    height: 360px;
    position: relative;
    object-fit: contain;
}
.FootwearBgV2_main-image__FH6v_{
    width: 100%!important;
    height: 100%!important;
    object-fit: contain!important;
    object-position: center; 
    position: inherit!important;
}
.FootwearBgV2_frame__3HAk4{
    width: 80%;
    height: 360px;
    position: absolute;
    object-fit: contain;
    border:1px solid #e9ecef;
}
.FootwearBgV2_bottom-frame__3GhUe{
    width: 80%;
    margin: 0 0 auto 20%;
    padding: 20px;
}
.FootwearBgV2_image-box-area__mcRbp {
  height: 100%;
  padding: 0 10px;
  margin: 0 auto;
  justify-content: center;
}
.FootwearBgV2_image-box-area__mcRbp video {
  width: 101.9%;
}

.FootwearBgV2_footwear-main__37juk{
    padding: 10px 0 -1px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    margin-bottom: 50px;
    width : 80%;
    margin-left: 10%;
}
.FootwearBgV2_background-content__1SivG{
    padding: 10px 10px 0;
    width:40%

}
.FootwearBgV2_background-content__1SivG form{
    margin: 0;
    margin-bottom: 10px; 
}
.FootwearBgV2_background-content__1SivG form select{
    background: #FEFEFE;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.15px;
    padding: 6px 10px;
    color: #999696;
    outline: 0;
    box-shadow: 0px 0px 0px;
    width: 90%;
    border-right: 0;
}
.FootwearBgV2_background-content__1SivG form select option{
   
}

.FootwearBgV2_background-content__1SivG form img{
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    /* border-radius: 8px; */
    padding: 12px;
    height: 45px;
    width: 10%;
    border-left: 0;
    border-radius: 0px 8px 8px 0px;
}
.FootwearBgV2_background-content__1SivG h4{
    text-align: center;
    font-weight: bold;
font-size: 22px;
line-height: 110%; 
letter-spacing: 0.5px;
margin: 0 0 20px;
color: #232121;
}
.FootwearBgV2_background-content__1SivG h5{
    font-weight: 500;
    font-size: 16px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0 0 15px;
    color: #232121;
}
.FootwearBgV2_default-countries__Z5xRZ{
    list-style: none;
    padding: 0;
    /* display: flex; */
    justify-content: center;
    text-align: center;
}
.FootwearBgV2_default-countries__Z5xRZ li{
    padding: 7px;
    display: inline-block;
    text-align: center;
}
.FootwearBgV2_default-countries__Z5xRZ li span{
    font-weight: normal;
    font-size: 10px;
    line-height: 16px; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px; 
    color: #343C44;
    background: #FFE5B1;
border-radius: 16px;
padding: 7px 10px;
}
.FootwearBgV2_brand-tabs__2RTOf{
    padding: 0;
    list-style: none;
    text-align: center;
}
.FootwearBgV2_brand-tabs__2RTOf li{
    padding: 10px 15px;
    display: inline-block;

}
.FootwearBgV2_brand-tabs__2RTOf li button{
padding: 10px; 
background: #FFFFFF;
border: 2px solid #E2E2E2;
border-radius: 5px;
width: 100px;
height: 100px;
position: relative;
}
.FootwearBgV2_cross-icon__HKJE6{ 
    width: 25px!important;
    position: absolute!important;
    top: -11px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    box-shadow: -3px 2px 6px 0px #ddd;
    right: -15%;  
}
.FootwearBgV2_brand-tabs__2RTOf li button.FootwearBgV2_active__A9TRF, .FootwearBgV2_brand-tabs__2RTOf li button:hover{
    border: 2px solid #FF7700;
    color: #FF7700;
}
/* .brand-tabs li button:hover .cross-btn{
 display: block;
} */
.FootwearBgV2_brand-tabs__2RTOf li button img{
    width: 100%;
}
.FootwearBgV2_apply-btn__2zucN{

}
.FootwearBgV2_apply-btn__2zucN button{
 width: 150px;
    margin: 3% auto;
}

.FootwearBgV2_note__3ILUj{
    margin: 20px 0;
}

.FootwearBgV2_note__3ILUj p{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 27px;

color: #000000;
}

.FootwearBgV2_brand-slider__2lLHm{

}

 
.FootwearBgV2_brand-slider__2lLHm .FootwearBgV2_slide-content__2Ht3v{
    padding: 15px 0px 0;

}
.FootwearBgV2_brand-slider__2lLHm .FootwearBgV2_slide-content__2Ht3v button{
    padding: 10px; 
background: #FFFFFF;
border: 2px solid #E2E2E2;
border-radius: 5px;
width: 85px;
height: 85px;
position: relative;
}
  
.FootwearBgV2_brand-slider__2lLHm .FootwearBgV2_slide-content__2Ht3v button.FootwearBgV2_active__A9TRF, .FootwearBgV2_brand-slider__2lLHm .FootwearBgV2_slide-content__2Ht3v button:hover{
    border: 1px solid #EAEAEA;
box-sizing: border-box;
box-shadow: 0px 1px 8px #FF7700;
}
.FootwearBgV2_brand-slider__2lLHm .FootwearBgV2_slide-content__2Ht3v input{
    display: block;
    margin: 10px auto 0;
    outline: 0;
    box-shadow: 0px 0px 0px;
}
 
.FootwearBgV2_brand-slider__2lLHm .FootwearBgV2_slide-content__2Ht3v button img{
    width: 100%;
}
.FootwearBgV2_slide-content__2Ht3v p{
    border: 2px solid #C9CDD3;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 10px auto;
    cursor: pointer;
}
.FootwearBgV2_slide-content__2Ht3v p.FootwearBgV2_colored__IGWH7{
    background: #C9CDD3;
}
.FootwearBgV2_download-popup__2LODj{
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px 20px;
    margin-top: 10px;
}
.FootwearBgV2_download-popup__2LODj .FootwearBgV2_cross-btn__TnDfd{
    text-align: right;
    padding: 0 0 10px;
}
.FootwearBgV2_download-popup__2LODj .FootwearBgV2_cross-btn__TnDfd img{
    width: auto!important;
    cursor: pointer;
}
.FootwearBgV2_download-popup__2LODj button h6{
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: .5px;
    color: #232121;
    margin: 0 0 10px;
}
.FootwearBgV2_download-popup__2LODj input {
    margin-right: 10%;
    border: 0;
    outline: 0;
}
.FootwearBgV2_download-popup__2LODj ul{
    padding-left: 15%;
    list-style: none;
    margin: 0 ;
}
.FootwearBgV2_download-popup__2LODj ul li{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: .5px;
    color: #232121;
}
.FootwearBgV2_download-popup__2LODj p{
    font-weight: 600;
    line-height: 25px;
    letter-spacing: .5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0!important;
}
.FootwearBgV2_download-popup__2LODj p span{
    color: #f70;
}
.FootwearBgV2_border-1__1wLTU{
  width: 100%;
border-top: 1px solid #ddd;
margin-bottom: 25px;
}
.FootwearBgV2_download-popup__2LODj button {
    border: 1px solid transparent;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 10px 40px;
    text-align: left;
    margin-bottom: 10px;
}
.FootwearBgV2_download-popup__2LODj button.FootwearBgV2_activ__3y92s, .FootwearBgV2_download-popup__2LODj button:hover {
    background: rgba(5,121,223,.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
}

.FootwearBgV2_download-popup__2LODj .FootwearBgV2_apply-btn__2zucN button{
    text-align: center!important;
    background: #f70!important;
    color: #fff!important;
border-radius: 5px !important;
border-color: #f70;
width: 80%;
}
.FootwearBgV2_bg-box__OUErt{
    position: absolute;
    z-index: 9;
    left: 4%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgb(128 91 58 / 40%);
    border-radius: 5px;
    margin-top: 10px;
}
.FootwearBgV2_bg-box__OUErt ul{
    padding: 0;
    list-style: none;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 2px 8px rgb(128 91 58 / 40%); */
    display: flex;
}
.FootwearBgV2_bg-box__OUErt ul li{
display: inline-block; 
margin: 0 auto;   
 padding: 5px 10px;
}
.FootwearBgV2_bg-box__OUErt ul li p{
    font-weight: 500;
    font-size: 9px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0;
    color: #5E5E5E;
}
.FootwearBgV2_bg-box__OUErt ul li p span{
    text-align: right; 
       margin-left: 10px;
}
.FootwearBgV2_bg-box__OUErt ul li img{
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.FootwearBgV2_bg-box__OUErt h6{
    font-weight: 500;
font-size: 12px;
line-height: 110%; 
text-align: center;
letter-spacing: 0.5px;

color: #5E5E5E;
}

.FootwearBgV2_bg-box__OUErt ul li p span img{
    width: 8px!important;
    height: auto!important;
    padding-left: 1px;
    border: 0!important;
}
.FootwearBgV2_bg-box__OUErt:after {
    content: '';
    position: absolute; 
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    left: 45%;
    margin: 0 auto;
}





.FootwearBgV2_right-top-selection__2n8_w{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0px;
    margin: 0px auto 7px;
    justify-content: center;
    text-align: center;
    /* border-top: 2px solid #e0e0e0; */
   
  }
  .FootwearBgV2_active-visual-selection__m5XvZ{ 
    border-top: 2px solid #FF7700;
    color: #FF7700;
    background: #fff;
    padding: 10px;
  }
  .FootwearBgV2_inactive-visual-selection__3PznB{
    /* border-bottom: 2px solid #FF7700; */
    color: #000;
    padding: 10px;
    background: #F5F5F5;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 0px 0px 5px 5px;
  }

  .FootwearBgV2_marketplaces__3U5Hl{
    /* display: flex; */
    justify-content: space-around;
    position: relative;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15%;
    grid-row-gap: 10px;
    margin-left: 20px;
  }
  .FootwearBgV2_side-margin__ZTU89{
    border-left: 1px dashed #ff7700;
      margin: 16px;
  }
  .FootwearBgV2_custom-wall__394eK p, .FootwearBgV2_adjust-height__2U_WI p{
    margin: 0 ;
  }
  .FootwearBgV2_adjust-height__2U_WI p{
    padding: 5px 0 ;
    font-size: 16px ;
  }
  .FootwearBgV2_custom-wall__394eK p span{
    background: #ff7700 ;
    color: #fff ;
    border-radius: 50% ;
    padding: 5px 13px ;
    margin-right: 10px ;
  }
  .FootwearBgV2_adjust-height__2U_WI p span{
    background: #E5E5E5 ;
    color: #fff ;
    border-radius: 50% ;
    padding: 5px 12px ;
    margin-right: 10px ;
  }
  
  
  
  .FootwearBgV2_custom-walls-area__1ivoq{
    padding-left: 10px ;
  }
  .FootwearBgV2_adjust-height-area__3ttue{
    padding: 5px 0px ;
    position: relative ;
  }
  .FootwearBgV2_adjust-height-area__3ttue ul{
    padding: 5px 10% ;
    list-style: none ;
    text-align: center ;
    display: flex ;
  }
  .FootwearBgV2_adjust-height-area__3ttue ul li{
    text-align: center ;
    width: 35% ;
    padding: 10px ;
    
    margin: 0 auto ;
  }
  .FootwearBgV2_adjust-height-area__3ttue ul li button{
    background: rgba(235 215 198 / 0%);
    border: 1px solid #000000;
    box-sizing: border-box ;
    padding: 10px ;
    width: 100% ;    
    height: auto ;
    border-radius: 0 ;
  }
  .FootwearBgV2_adjust-height-area__3ttue ul li button.FootwearBgV2_active__A9TRF, .FootwearBgV2_adjust-height-area__3ttue ul li button:hover{
    background: rgb(235 215 198 / 0%);
    border: 1px solid #FF7700; 
  }
  .FootwearBgV2_adjust-height-area__3ttue ul li h6{ 
    font-weight: normal ;
    font-size: 14px ; 
    color: #000000 ;
    margin: 0 ;
  }
  .FootwearBgV2_adjust-height-area__3ttue ul li img{
    width: 100% ;
  }
  

  .FootwearBgV2_border-1__1wLTU {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
    margin-top: 20px;
  }


  .FootwearBgV2_toggle1__2OOPK {
    position: relative;
    color: #f39e53;
  }
  
  .FootwearBgV2_toggle1__2OOPK input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .FootwearBgV2_slider2__s-axU {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 190px;
  
  box-shadow: 0px 4px 4px rgb(255, 255, 255);
  border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
  }
  
  .FootwearBgV2_slider2__s-axU::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top:3px
  
  }
  
  input:checked + .FootwearBgV2_slider2__s-axU {
    background-color: #78f353;
  }
  
  input:checked + .FootwearBgV2_slider2__s-axU::before {
    -webkit-transform: translateX(21.5px);
            transform: translateX(21.5px);
  }

@media (max-width: 800px){
      
}













.FootwearInsufficientCredit_footwear-slider__3ThWb{

}
.FootwearInsufficientCredit_footwear-slide-content__1E32L{

}
.FootwearInsufficientCredit_footwear-slide-title__2ZwDA{

    text-align: center;
}
.FootwearInsufficientCredit_footwear-slide-title__2ZwDA h4{
    font-weight: 600;
font-size: 20px;
line-height: 190.5%; 
letter-spacing: 0.03em;

color: #232121;
}

.FootwearInsufficientCredit_footwear-slide__D4ghg{
    margin-bottom: 15px;
}
.FootwearInsufficientCredit_footwear-single__2FkcK{

}
.FootwearInsufficientCredit_footwear-single__2FkcK p{
    font-weight: normal;
font-size: 14px;
line-height: 190.5%;
margin: 0;
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.FootwearInsufficientCredit_footwear-single__2FkcK img{
    width: 100%;
}
.FootwearInsufficientCredit_footwear-single__2FkcK h6{
    font-weight: 600;
font-size: 16px;
line-height: 190.5%;
/* or 34px */

text-align: center;
letter-spacing: 0.03em;

color: #232121;
}
.FootwearInsufficientCredit_footwear-slide-title__2ZwDA button{
    background: #FF7700;
border-radius: 26px;
margin-bottom: 20px;
}
.FootwearInsufficientCredit_footwear-slide-title__2ZwDA p{
    font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 190.5%; 
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.FootwearInsufficientCredit_bulk-images-area__1qKrb{
  padding: 15px 10px 10px;
}
.FootwearInsufficientCredit_bulk-image__3sIcd{
  background: #FFF0ED; 
  border-radius: 5px;
  padding: 11px 15px 15px;
}
.FootwearInsufficientCredit_bulk-image__3sIcd p{
  font-weight: normal;
font-size: 14px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;
margin: 0 0 8px;
color: #444444;
}
.FootwearInsufficientCredit_bulk-image__3sIcd img{
  border-radius: 5px;
  padding: 0;
  width: 100%;
}
.FootwearInsufficientCredit_cross-icon__3HrCv{
  background: #FFFFFF;
  position: absolute;
  width: 25px!important;
  z-index: 1;
  border-radius: 50%!important;
  padding: 5px!important;
  height: 25px;
  right: 5px;
  font-weight: 300;
  top: -5%;
  box-shadow: 0px 2px 5px 0px #ddd;
}

.FootwearInsufficientCredit_proceed-btn__1xBlE button{
  background: #fff!important;
  color: #FF7700!important;
  border: 1.5px solid #FF7700;
  border-radius: 5px;
}

.FootwearInsufficientCredit_bulk-images-slider__3l4PS .FootwearInsufficientCredit_bulk-image__3sIcd{
  position: relative;   
   border: 2px solid #E6A56C;
}
.FootwearInsufficientCredit_bulk-images-slider__3l4PS{
  width: 80%;
  margin: 0 auto 0 20%;
}
.FootwearInsufficientCredit_bulk-images-slider__3l4PS .FootwearInsufficientCredit_cross-icon__3HrCv{
  right: -5%;
  top: -10%;
}

.FootwearBulkBgV2_upload-footwear-image__21_qP{
  padding: 0px 0 25px;
}
.FootwearBulkBgV2_left-sku__1aH-5{
  margin: 0;

}
.FootwearBulkBgV2_left-sku__1aH-5 h5{
  font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 36px;
color: #FF7700;
margin: 0;

}
.FootwearBulkBgV2_upload-sec__3A1zE button img {
  width: 23px;
  margin-right: 10px;
}
.FootwearBulkBgV2_right-credits__LDuwi{
  font-size: 14px;
 text-align: right;
  color: #232121;
  line-height: 36px;
}
.FootwearBulkBgV2_right-credits__LDuwi img{
  margin-right: 10px;
  width: 45px;
  vertical-align: text-top;
}
.FootwearBulkBgV2_right-credits__LDuwi span{
  color: #ff7700;
}
.FootwearBulkBgV2_background-tabs__3mHJ9{
list-style: none;
padding: 0;
justify-content: center;
}
.FootwearBulkBgV2_background-tabs__3mHJ9 li{
  padding: 20px;
  display: inline-block;
  width: auto;
  margin: 0 30px;
}
.FootwearBulkBgV2_background-tabs__3mHJ9 li button{
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  border-bottom: 2px solid transparent;
  text-align: center;
  letter-spacing: 0.5px;
  background: transparent;
  color: #5E5E5E;
  border-radius: 0;
  padding: 0;
}

.FootwearBulkBgV2_background-tabs__3mHJ9 li button.FootwearBulkBgV2_active__1cvZd, .FootwearBulkBgV2_background-tabs__3mHJ9 li button:hover{
  border-bottom: 2px solid #FF7700;
  color: #FF7700;
}
.FootwearBulkBgV2_left-frame__1Ig0G{
  width: 20%;
  /* display: inline-block; */
  height: 360px;
  object-fit: contain;
}
.FootwearBulkBgV2_background-tabs__3mHJ9 div.FootwearBulkBgV2_edited_image__2QW7K{
  width: 80%;
  height: 360px;
  position: relative;
  object-fit: contain;
}
.FootwearBulkBgV2_main-image__qzzvM{
  width: 100%!important;
  height: 100%!important;
  object-fit: contain!important;
  object-position: center; 
  position: inherit!important;
}
.FootwearBulkBgV2_frame__d4b4I{
  position: absolute;
  width: 80%;
  right: 0%;
  height: 360px;
  border:1px solid #e9ecef;
}
.FootwearBulkBgV2_bottom-frame__3z9EC{
  width: 80%;
  margin: 0 0 auto 20%;
  padding: 20px;
}


.FootwearBulkBgV2_footwear-main__2Zxlg{
  padding: 10px 0 -1px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  margin-bottom: 50px;
  width: 76%;
  margin-left: 12%;
}
.FootwearBulkBgV2_background-content__12DBo{
  padding: 10px;
    width: 31%;
    margin-left: 1.6%;
    box-shadow: -1px 0 10px rgb(0 0 0 / 15%);

}
.FootwearBulkBgV2_background-content__12DBo form{
  margin: 0;
  margin-bottom: 10px; 
}
.FootwearBulkBgV2_background-content__12DBo form select{
  background: #FEFEFE;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 8px 0px 0px 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.15px;
  padding: 6px 10px;
  color: #999696;
  outline: 0;
  box-shadow: 0px 0px 0px;
  width: 90%;
  border-right: 0;
}
.FootwearBulkBgV2_background-content__12DBo form select option{
 
}

.FootwearBulkBgV2_background-content__12DBo form img{
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  /* border-radius: 8px; */
  padding: 12px;
  height: 45px;
  width: 10%;
  border-left: 0;
  border-radius: 0px 8px 8px 0px;
}
.FootwearBulkBgV2_background-content__12DBo h4{
  text-align: center;
  font-weight: bold;
font-size: 22px;
line-height: 110%; 
letter-spacing: 0.5px;
margin: 0 0 20px;
color: #232121;
}
.FootwearBulkBgV2_background-content__12DBo h5{
  font-weight: 500;
  font-size: 16px;
  line-height: 110%; 
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 0 15px;
  color: #232121;
}
.FootwearBulkBgV2_default-countries__1VLio{
  list-style: none;
  padding: 0;
  /* display: flex; */
  justify-content: center;
  text-align: center;
}
.FootwearBulkBgV2_default-countries__1VLio li{
  padding: 7px;
  display: inline-block;
  text-align: center;
}
.FootwearBulkBgV2_default-countries__1VLio li span{
  font-weight: normal;
  font-size: 10px;
  line-height: 16px; 
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px; 
  color: #343C44;
  background: #FFE5B1;
border-radius: 16px;
padding: 7px 10px;
}
.FootwearBulkBgV2_brand-tabs__LobN4{
  padding: 0;
  list-style: none;
  text-align: center;
}
.FootwearBulkBgV2_brand-tabs__LobN4 li{
  padding: 10px 15px;
  display: inline-block;

}
.FootwearBulkBgV2_brand-tabs__LobN4 li button{
  padding: 10px; 
background: #FFFFFF;
border: 2px solid #E2E2E2;
border-radius: 5px;
width: 85px;
height: 85px;
position: relative;
}
.FootwearBulkBgV2_cross-icon__12m35{ 
  width: 25px!important;
  position: absolute!important;
  top: -11px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  box-shadow: -3px 2px 6px 0px #ddd;
  right: -15%;  
}
.FootwearBulkBgV2_brand-tabs__LobN4 li button.FootwearBulkBgV2_active__1cvZd, .FootwearBulkBgV2_brand-tabs__LobN4 li button:hover{
  border: 2px solid #FF7700;
  color: #FF7700;
}
/* .brand-tabs li button:hover .cross-btn{
display: block;
} */
.FootwearBulkBgV2_brand-tabs__LobN4 li button img{
  width: 100%;
}
/* .apply-btn{
  margin-left: 12%;
}
.apply-btn button{
  width: 250px;
  margin: 3% auto;
} */

.FootwearBulkBgV2_note__2guey{
  margin: 20px 0;
}

.FootwearBulkBgV2_note__2guey p{
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 27px;

color: #000000;
}

.FootwearBulkBgV2_brand-slider__2llck{

}


.FootwearBulkBgV2_brand-slider__2llck .FootwearBulkBgV2_slide-content__38-td{
  padding: 15px 0px 0;

}
.FootwearBulkBgV2_brand-slider__2llck .FootwearBulkBgV2_slide-content__38-td button{
  padding: 10px; 
background: #FFFFFF;
border: 2px solid #E2E2E2;
border-radius: 5px;
width: 85px;
height: 85px;
position: relative;
}

.FootwearBulkBgV2_brand-slider__2llck .FootwearBulkBgV2_slide-content__38-td button.FootwearBulkBgV2_active__1cvZd, .FootwearBulkBgV2_brand-slider__2llck .FootwearBulkBgV2_slide-content__38-td button:hover{
  border: 1px solid #EAEAEA;
box-sizing: border-box;
box-shadow: 0px 1px 8px #FF7700;
}
.FootwearBulkBgV2_brand-slider__2llck .FootwearBulkBgV2_slide-content__38-td input{
  display: block;
  margin: 10px auto 0;
  outline: 0;
  box-shadow: 0px 0px 0px;
}

.FootwearBulkBgV2_brand-slider__2llck .FootwearBulkBgV2_slide-content__38-td button img{
  width: 100%;
}
.FootwearBulkBgV2_slide-content__38-td p{
  border: 2px solid #C9CDD3;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px auto;
  cursor: pointer;
}
.FootwearBulkBgV2_slide-content__38-td p.FootwearBulkBgV2_colored__35Ky6{
  background: #C9CDD3;
}
.FootwearBulkBgV2_download-popup__LNIon{
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 10px;
}
.FootwearBulkBgV2_download-popup__LNIon .FootwearBulkBgV2_cross-btn__eKi52{
  text-align: right;
  padding: 0 0 10px;
}
.FootwearBulkBgV2_download-popup__LNIon .FootwearBulkBgV2_cross-btn__eKi52 img{
  width: auto!important;
  cursor: pointer;
}
.FootwearBulkBgV2_download-popup__LNIon button h6{
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: .5px;
  color: #232121;
  margin: 0 0 10px;
}
.FootwearBulkBgV2_download-popup__LNIon input {
  margin-right: 10%;
  border: 0;
  outline: 0;
}
.FootwearBulkBgV2_download-popup__LNIon ul{
  padding-left: 15%;
  list-style: none;
  margin: 0 ;
}
.FootwearBulkBgV2_download-popup__LNIon ul li{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: .5px;
  color: #232121;
}
.FootwearBulkBgV2_download-popup__LNIon p{
  font-weight: 600;
  line-height: 25px;
  letter-spacing: .5px;
  color: #232121;
  padding-left: 15%;
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0!important;
}
.FootwearBulkBgV2_download-popup__LNIon p span{
  color: #f70;
}
.FootwearBulkBgV2_border-1__1Je4t{
width: 100%;
border-top: 1px solid #ddd;
margin-bottom: 25px;
}
.FootwearBulkBgV2_download-popup__LNIon button {
  border: 1px solid transparent;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 0;
  color: #000;
  padding: 10px 40px;
  text-align: left;
  margin-bottom: 10px;
}
.FootwearBulkBgV2_download-popup__LNIon button.FootwearBulkBgV2_activ___e_Nm, .FootwearBulkBgV2_download-popup__LNIon button:hover {
  background: rgba(5,121,223,.02);
  border: 1px solid #0579df;
  box-sizing: border-box;
  border-radius: 10px;
}

.FootwearBulkBgV2_download-popup__LNIon .FootwearBulkBgV2_apply-btn__3znpW button{
  text-align: center!important;
  background: #f70!important;
  color: #fff!important;
border-radius: 5px !important;
border-color: #f70;
width: 80%;
}
.FootwearBulkBgV2_bg-box__1f_wD{
  position: absolute;
  z-index: 9;
  left: 4%;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgb(128 91 58 / 40%);
  border-radius: 5px;
  margin-top: 10px;
}
.FootwearBulkBgV2_bg-box__1f_wD ul{
  padding: 0;
  list-style: none;
  /* background: #FFFFFF; */
  /* box-shadow: 0px 2px 8px rgb(128 91 58 / 40%); */
  display: flex;
}
.FootwearBulkBgV2_bg-box__1f_wD ul li{
display: inline-block; 
margin: 0 auto;   
padding: 5px 10px;
}
.FootwearBulkBgV2_bg-box__1f_wD ul li p{
  font-weight: 500;
  font-size: 9px;
  line-height: 110%; 
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0;
  color: #5E5E5E;
}
.FootwearBulkBgV2_bg-box__1f_wD ul li p span{
  text-align: right; 
     margin-left: 10px;
}
.FootwearBulkBgV2_bg-box__1f_wD ul li img{
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.FootwearBulkBgV2_bg-box__1f_wD h6{
  font-weight: 500;
font-size: 12px;
line-height: 110%; 
text-align: center;
letter-spacing: 0.5px;

color: #5E5E5E;
}

.FootwearBulkBgV2_bg-box__1f_wD ul li p span img{
  width: 8px!important;
  height: auto!important;
  padding-left: 1px;
  border: 0!important;
}
.FootwearBulkBgV2_bg-box__1f_wD:after {
  content: '';
  position: absolute; 
  top: -10px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  left: 45%;
  margin: 0 auto;
}
.FootwearBulkBgV2_add-more__20cu9 {
  position: absolute;
  left:58%;
  font-size: 30px;
  z-index: 1;
  background: #ff7700;
  font-weight: 100;
  color: #fff;
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
  top: 2%;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  cursor: pointer;
  top:20%
}

.FootwearBulkBgV2_more-text__1NCE7 {
  position: absolute;
  z-index: 999;
  left: 72%;
  top: 13.5%;
  display: none;
}


.FootwearBulkBgV2_add-more__20cu9:hover + .FootwearBulkBgV2_more-text__1NCE7 {
  display: block !important;
}

.FootwearBulkBgV2_add-more__20cu9 button {
  padding: 0 !important;
}


.FootwearBulkBgV2_upload-car-name-gif__El7O4 img {
  width: 40%;
  max-height: 20px;
}

.FootwearBulkBgV2_tab-content__1DZIF {
  overflow: hidden;
  height: 550px;
  overflow-y: scroll;
  margin: 0;
  padding: 22px 0px;
}

.FootwearBulkBgV2_container-fluid__2stAc {
  max-width: 100% !important;
  margin: 0 15px !important;
}

.FootwearBulkBgV2_uploaded-cars__2YU_G {
}
.FootwearBulkBgV2_uploaded-cars-single__3SIuF {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  margin: 0 0 20px;
  padding: 5px 0px;
}
.FootwearBulkBgV2_uploaded-cars-single__3SIuF img {
  width: 100%;
  padding: 0;
  height: 105px;
  object-fit: cover;
  cursor: pointer;
}
.FootwearBulkBgV2_uploaded-cars-single__3SIuF p {
  text-align: center;
  padding: 5px 5px;
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FootwearBulkBgV2_upload-car-name-gif__El7O4 img {
  width: 40%;
  max-height: 20px;
}

.FootwearBulkBgV2_reload-cancel__3pa61 span img {
}
.FootwearBulkBgV2_reload-cancel__3pa61 {
  opacity: 1;
}

.FootwearBulkBgV2_uploaded-cars-single__3SIuF:hover .FootwearBulkBgV2_reload-cancel__3pa61 {
  opacity: 1;
}

.FootwearBulkBgV2_reload-cancel2__3XTf6 {
  text-align: right;
  justify-content: flex-end;
  padding: 0;
  height: 20px;
  align-items: center;
}
.FootwearBulkBgV2_reload-cancel2__3XTf6 img {
  /* padding: 5px; */
  /* background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%; */
  height: 35px;
  width: 35px;
  margin: 0 0 5px 10px;
}
.FootwearBulkBgV2_reload-cancel2__3XTf6 span img {
  position: absolute;
  right: -2%;
  top: -10%;
  margin: 0;
  cursor: pointer;
}


.FootwearBulkBgV2_bulk-bg__32TRr button {
  border-radius: 5px;
  width: auto;
  margin: 10px auto;
}
.FootwearBulkBgV2_bulk-bg__32TRr {
  text-align: center;
}
.FootwearBulkBgV2_bulk-bg__32TRr {
  margin: 0;
}
.FootwearBulkBgV2_bulk-bg__32TRr img {
  margin-bottom: 10px;
}

.FootwearBulkBgV2_background-grid__1fTot h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.5px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin: 20px 0px;
  color: #ff7700;
}

.FootwearBulkBgV2_background-grid__1fTot {
  padding: 0 5px;
}

.FootwearBulkBgV2_background-grid__1fTot img {
  width: 100%;
  height: auto;
  padding: 0;
}
.FootwearBulkBgV2_background-grid__1fTot h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.5px;

  color: #232121;
}

.FootwearBulkBgV2_apply-btn-section__1wNBx {
  text-align: center;
  padding: 20px;
}
.FootwearBulkBgV2_apply-btn__3znpW,
.FootwearBulkBgV2_apply-btn-section__1wNBx button {
  background: #ff7700;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  width: 100%;
}

.FootwearBulkBgV2_apply-btn-section__1wNBx h6 {
  font-size: 18px;
  color: #2b2a2a;
  font-weight: 500;
}

.FootwearBulkBgV2_apply-btn-section__1wNBx p {
  font-size: 16px;
  color: #646464;
  font-weight: 400;
}

.FootwearBulkBgV2_apply-btn-section__1wNBx img {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0 5px;
}

.FootwearBulkBgV2_right-top-credits__1Segh{
  text-align: right;
  padding: 0;
  margin-bottom: 10px;
}
.FootwearBulkBgV2_right-top-credits__1Segh button{
  padding: 5px 10px;
  width: auto;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #FF7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.FootwearBulkBgV2_right-top-credits__1Segh img{
  width:25px !important;
}
.FootwearBulkBgV2_credit-text__AL5U3 {
  font-size: 14px;
  font-family: poppins;
  color: #232121;
  text-align: center;
  width: 86%;
    /* padding-left: 55%; */
  /* display: flex;
  justify-content:flex-end; */
}

.FootwearBulkBgV2_credit-text__AL5U3 span {
  color: #ff7700;
}

.FootwearBulkBgV2_credit-text__AL5U3 img {
  margin-right: 10px;
  width: 40px;
}
.FootwearBulkBgV2_add-credit__1jhuo{
  color: white !important;
}
.FootwearBulkBgV2_add-credit__1jhuo img{
 
}

.FootwearBulkBgV2_left-sec__2_wMA{
  width: 66.66%;
}

.FootwearBulkBgV2_border-1__1Je4t {
  width: 100%;
  /* height: 1px; */
  border-top: 1px solid #ddd;
  margin-bottom: 25px;
  margin-top: 20px;
}
.FootwearBulkBgV2_marketplaces__3nteO{
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15%;
  grid-row-gap: 10px;
  margin-left: 20px;
}

.FootwearBulkBgV2_toggle1__jALsP {
  position: relative;
  color: #f39e53;
}

.FootwearBulkBgV2_toggle1__jALsP input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.FootwearBulkBgV2_slider2__rl7gu {
  position: relative;
  display: block;
  cursor: pointer;
  background: #d64d4d;
  margin-left: 190px;

box-shadow: 0px 4px 4px rgb(255, 255, 255);
border-radius: 20px;
  transition: 0.2;
  width: 50px;
  height: 28px;
}

.FootwearBulkBgV2_slider2__rl7gu::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 22px;
  background: rgb(255, 255, 255);
  transition: 0.2s;
  border-radius: 50%;
  left: 3px;
  top:3px

}

input:checked + .FootwearBulkBgV2_slider2__rl7gu {
  background-color: #78f353;
}

input:checked + .FootwearBulkBgV2_slider2__rl7gu::before {
  -webkit-transform: translateX(21.5px);
          transform: translateX(21.5px);
}

@media (max-width: 800px){
    
}













body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.FootwearV2_drop-box-text__2QRBQ {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.FootwearV2_drop-box-text__2QRBQ div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.FootwearV2_car-main-wrapper__E6rj_ {
  background-size: cover !important;
  padding-bottom: 60px;
}
.FootwearV2_header-section__3bGZy {
  text-align: center;
  padding-top: 100px !important;
}
.FootwearV2_heading-text__3Ib1Y {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.FootwearV2_heading-para__1xbkB {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.FootwearV2_heading-para__1xbkB span {
  color: #ff7700 !important;
}

.FootwearV2_wrapper__1uykF {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.FootwearV2_left-section__24pKH {
  width: 40%;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.FootwearV2_mid-section__1JO2A {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.FootwearV2_sample-button__rYWlK {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.FootwearV2_sample-button__rYWlK:hover {
  color: black;
}
.FootwearV2_dropzone__3ABat {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.FootwearV2_dropzone__3ABat:hover {
  opacity: 1;
}
.FootwearV2_dropzone__3ABat:focus {
  outline: none !important;
  border: none !important;
}
.FootwearV2_para-1__QOuFK {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.FootwearV2_para-2__1CQlg { 
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #4f4444;
  margin: 0px;
}
.FootwearV2_para-2__1CQlg span {
  color: #0579df;
}
.FootwearV2_supports__ib4Qg { 
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.FootwearV2_choose-button__31jtd {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.FootwearV2_choose-button__31jtd button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.FootwearV2_choose-button__31jtd button:hover {
  color: #ffffff;
}
.FootwearV2_choose-button__31jtd button:focus {
  outline: none !important;
}
 
.FootwearV2_right-section__Uk4pp img {
  
  width: 100%;
}
 
 

.FootwearV2_request-demo__1YtUm {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 .FootwearV2_request-demo2__3zJ4Q {
  display: block;
}

.FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 p img {
  margin-top: 10px;
}

.FootwearV2_request-demo2__3zJ4Q img {
  height: 42px;
  /* margin-left: 20px; */
}

.FootwearV2_custom-backgrounds__1XqTt {
  padding: 60px 5% 40px;
}

.FootwearV2_title__3uT7n {
  text-align: center;
}

.FootwearV2_title__3uT7n h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

 
.FootwearV2_try-images-area__2kiHX {
  padding: 5px 15px;
}
.FootwearV2_car-left-section__3HO5j {
  margin: 25px auto 30px;
}
.FootwearV2_try-drag-drop__Sxi7z {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
}
.FootwearV2_try-drag-drop__Sxi7z .FootwearV2_dropzone1__2gO2u {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
  padding: 20px 20px 10px;
  cursor: pointer;
}
.FootwearV2_try-drag-drop__Sxi7z .FootwearV2_dropzone1__2gO2u:hover {
  opacity: 1;
}
.FootwearV2_try-drag-drop__Sxi7z .FootwearV2_dropzone1__2gO2u img {
    width: auto;
}
.FootwearV2_para-11__3Kw4i {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px; 
    color: #FF7700;
}
.FootwearV2_para-f1__rIYiW {
    color: #FF7700;
}
.FootwearV2_para-f1__rIYiW span{
    background: #FF7700;
border-radius: 10px;
font-weight: 500;
font-size: 12px;
line-height: 18px; 
color: #FFFFFF;
padding: 5px 10px;
margin-left: 10px;
}
.FootwearV2_para-f1__rIYiW span img{
    padding-right: 5px;
    width: 20px!important;
}
.FootwearV2_supports1__2dYjx {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
 
.FootwearV2_choose-output__GQJW4 {
}
.FootwearV2_heading__1Mypg h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.FootwearV2_heading__1Mypg h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.FootwearV2_heading__1Mypg h3 .FootwearV2_border-1__37PBS {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
 
.FootwearV2_bg-try__3Kl5f {
    padding: 100px 0 0;
}

.FootwearV2_choose-button__31jtd {
}
.FootwearV2_choose-button__31jtd button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: -webkit-max-content;
  width: max-content;
}
.FootwearV2_bg-recommended__2lpWt {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  /* overflow: hidden;
  overflow-y: scroll; */
}
 
 
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}




  .FootwearV2_signup-popup__2JWgr{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    padding: 0;
  }
.FootwearV2_signup-image__pZsrX{
    padding: 20px 0 20px 15px;
}
.FootwearV2_signup-image__pZsrX img{
width: 100%;
}
.FootwearV2_signup-content__3vGHW{

}

.FootwearV2_circle-area__38eSX {
    padding: 0;
    background: #f79c4c;
   
  }
  .FootwearV2_signup-inner__37qOy{
      margin: 0%;
      padding:30px 25px;
      height: 100%;
      width: 100%;
  }
  .FootwearV2_circle-area-inner__1AWpv {
    border: 2px dashed #ffffff;
    box-sizing: border-box;
    padding: 23% 35px 20px; 
    height: 100%;
    width: 100%;
  }
  .FootwearV2_circle-area-inner__1AWpv h3 {
    font-weight: 600;
font-size: 34px;
line-height: 139.5%; 
text-align: center; 
color: #FFFFFF;
margin-bottom: 20px;
  }
  .FootwearV2_circle-area-inner__1AWpv p {
    font-weight: normal;
    font-size: 14px;
    line-height: 139.5%;
    margin-bottom: 35px;
    text-align: center;
  
    color: #ffffff;
  }
.FootwearV2_signup-btn__OVLtE{
    background: #FFFFFF;
    border-radius: 28.4704px;
    font-weight: 600;
    font-size: 18px;
    line-height: 139.5%;
    text-align: center;
    color: #FF7700;
    width: 100%;
}

.FootwearV2_signup-btn__OVLtE:hover{
    background: #FFFFFF; 
    color: #FF7700; 
}



/* Footwear-Background Slider Popup CSS */
.FootwearV2_footwear-slider__2g5jM{

}
.FootwearV2_footwear-slide-content__1fYm0{

}
.FootwearV2_footwear-slide-title__3bUOe{

    text-align: center;
}
.FootwearV2_footwear-slide-title__3bUOe h4{
    font-weight: 600;
font-size: 20px;
line-height: 190.5%; 
letter-spacing: 0.03em;

color: #232121;
}

.FootwearV2_footwear-slide__2EEkf{
    margin-bottom: 15px;
}
.FootwearV2_footwear-single__3aq0e{

}
.FootwearV2_footwear-single__3aq0e p{
    font-weight: normal;
font-size: 14px;
line-height: 190.5%;
margin: 0;
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.FootwearV2_footwear-single__3aq0e img{
    width: 100%;
}
.FootwearV2_footwear-single__3aq0e h6{
    font-weight: 600;
font-size: 16px;
line-height: 190.5%;
/* or 34px */

text-align: center;
letter-spacing: 0.03em;

color: #232121;
}
.FootwearV2_footwear-slide-title__3bUOe button{
    background: #FF7700;
border-radius: 26px;
margin-bottom: 20px;
}
.FootwearV2_footwear-slide-title__3bUOe p{
    font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 190.5%; 
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.FootwearV2_bulk-images-area__1QPON{
  padding: 15px 10px 10px;
}
.FootwearV2_bulk-image__1MkRX{
  background: #FFF0ED; 
  border-radius: 5px;
  padding: 11px 15px 15px;
}
.FootwearV2_bulk-image__1MkRX p{
  font-weight: normal;
font-size: 14px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;
margin: 0 0 8px;
color: #444444;
}
.FootwearV2_bulk-image__1MkRX img{
  border-radius: 5px;
  padding: 0;
  width: 100%;
}
.FootwearV2_cross-icon__3z0D4{
  background: #FFFFFF;
  position: absolute;
  width: 25px!important;
  z-index: 1;
  border-radius: 50%!important;
  padding: 5px!important;
  height: 25px;
  right: 5px;
  font-weight: 300;
  top: -5%;
  box-shadow: 0px 2px 5px 0px #ddd;
}

.FootwearV2_proceed-btn__2l1SE button{
  background: #fff!important;
  color: #FF7700!important;
  border: 1.5px solid #FF7700;
  border-radius: 5px;
}

.FootwearV2_bulk-images-slider__1m7NR .FootwearV2_bulk-image__1MkRX{
  position: relative;   
   border: 2px solid #E6A56C;
}
.FootwearV2_bulk-images-slider__1m7NR{
  width: 80%;
  margin: 0 auto 0 20%;
}
.FootwearV2_bulk-images-slider__1m7NR .FootwearV2_cross-icon__3z0D4{
  right: -5%;
  top: -10%;
}



@media (max-width: 800px) {
  .FootwearV2_car-main-wrapper__E6rj_ {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .FootwearV2_wrapper__1uykF {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .FootwearV2_right-section__Uk4pp {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .FootwearV2_left-section__24pKH {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .FootwearV2_heading-text__3Ib1Y {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .FootwearV2_heading-para__1xbkB {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .FootwearV2_section-two__ZH0gg {
    padding: 27px;
  }
  .FootwearV2_section-two-one__1C-gZ {
    margin-top: 70px;
  }
  .FootwearV2_number-image__bDnZ2 {
    display: none;
  }
  .FootwearV2_section-two-one__1C-gZ h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .FootwearV2_section-two-one__1C-gZ p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .FootwearV2_section-two-one-right__15XI2 {
    margin-top: 0px;
  }
  .FootwearV2_section-3-right__39RR5 div {
    margin-left: 0px;
  }
  .FootwearV2_mob-change__2s3n1 {
    display: flex;
    flex-direction: column-reverse;
  }
  .FootwearV2_mob-change__2s3n1 h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .FootwearV2_section-two-three-right__3V40T img {
    margin-top: 120px;
  }
  .FootwearV2_section-3__NceAI {
    padding: 27px;
  }
  .FootwearV2_section-3-right__39RR5 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .FootwearV2_section-3-right__39RR5 button {
    width: 150px;
    height: 50px;
  }
  .FootwearV2_try-images-area__2kiHX {
    padding: 5px 0;
    order: 1;
  }
  .FootwearV2_car-left-section__3HO5j {
    order: 3;
  }
  .FootwearV2_perfect-output-images__1uUf1 p {
    text-align: center;
    margin: 0;
  }
  .FootwearV2_try-images-area__2kiHX img {
    width: 100%;
    margin: 20px 0;
  }
  .FootwearV2_choose-button__31jtd {
    padding: 10% 10px;
  }
  .FootwearV2_bg-try__3Kl5f {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .FootwearV2_perfect-output-images__1uUf1 {
    order: 3;
    padding: 15px 0;
  }
  .FootwearV2_bg-recomended-area__1aEcw {
    overflow: visible;
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .FootwearV2_bg-recomended-right__G3VZt img {
    margin: 0px 0px 30px;
  }
  .FootwearV2_custom-big__2-6cV {
    display: none;
  }
  .FootwearV2_bg-recomended-right__G3VZt {
    padding: 0;
  }
  .FootwearV2_bg-recommended__2lpWt {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
  .FootwearV2_active-background-sec__3VFNh {
    padding: 0 10px;
    border-radius: 5px;
  }
  .FootwearV2_inactive-background-sec__152r8 {
    padding: 3px;
    border: 2px solid transparent;
  }
  .FootwearV2_perfect-output-images__1uUf1 p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .FootwearV2_heading__1Mypg h3 {
    font-size: 16px;
  }
}
.FootwearV2_custom-backgrounds__1XqTt {
  padding: 60px 0%;
}

.FootwearV2_title__3uT7n {
  text-align: center;
}
.FootwearV2_title__3uT7n h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 45px;
  margin-top: 0;
}
.FootwearV2_title__3uT7n h1 span {
  border-bottom: 2px solid #fd8061;
  color: #1c1e52;
}

.FootwearV2_custom-image__3L3O2 {
  padding-top: 4%;
  position: relative;
  text-align: center;
}
.FootwearV2_custom-image__3L3O2 img {
  width: 100%;
  position: relative;
  height: 595px;
}
.FootwearV2_custom-links__3R-x_ {
}
.FootwearV2_custom-backgrounds__1XqTt .FootwearV2_container-fluid__j48Qt {
  width: 100% !important;
}

.FootwearV2_custom-links__3R-x_ ul {
  display: block;
  list-style: none;
  padding: 0;
  width: 65%;
  margin: 0 0 0 auto;
}
.FootwearV2_ecom-links__1bo3w {
  float: left;
}
.FootwearV2_custom-links__3R-x_ ul li {
  padding: 10px 10px 0;
  width: 100%;
  text-align: center;
}

.FootwearV2_custom-links__3R-x_ ul li button {
  background: #fff;
  padding: 3px;
  border: 2px solid #fff;
  position: relative;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
}
.FootwearV2_custom-links__3R-x_ ul li button.FootwearV2_active__1Y68y {
  border: 2px solid #fd8061;
}
.FootwearV2_custom-links__3R-x_ ul li button img {
  width: 100%;
  height: 95px;
}
.FootwearV2_abs-img__6keDu {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 40% !important;
  height: auto !important;
}
.FootwearV2_frame-img__pbJF8 {
  position: absolute !important;
  left: 10px !important;
  right: 15px !important;
  width: 97.5% !important;
  margin: 0 auto;
}

.FootwearV2_left-arrow__Oiax6 {
  width: 17%;
  float: right;
  height: 60%;
  /* margin-top: 20%; */
  position: relative;
  top: 18%;
}
.FootwearV2_btm-arrow__1Idw2 {
  position: relative;
  width: 70% !important;
  margin: 4% auto;
  height: auto !important;
}

.FootwearV2_gif-area__1gY5g {
  padding: 0px 0 60px;
}
.FootwearV2_no-border__1ph9H {
  border: none !important;
}

.FootwearV2_mac-pro-image__101NE {
  text-align: center;
}
.FootwearV2_mac-pro-image__101NE img {
  margin-bottom: 50px;
  width: 100%;
}

.FootwearV2_graph-area__3v-0x {
  padding: 60px 0px;
}
.FootwearV2_graph-area__3v-0x .FootwearV2_title__3uT7n {
}
.FootwearV2_graph-area__3v-0x .FootwearV2_title__3uT7n h1 {
  margin-bottom: 10px;
}
.FootwearV2_graph-image__KVzkf {
  padding: 0 3%;
  text-align: center;
}
.FootwearV2_graph-image__KVzkf img {
  width: 100%;
  margin-bottom: 7%;
}

.FootwearV2_calendly-area__hFX7M {
  padding: 60px 0px 8%;
}

.FootwearV2_cars-demo__3eITN {
  height: 450px;
  text-align: center;
}
.FootwearV2_cars-demo__3eITN p {
  margin-top: 30px;
}
.FootwearV2_cars-demo__3eITN iframe {
  height: 100%;
}
.FootwearV2_cars-video___0wAo {
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  height: 450px;
  background: #fff;
}
.FootwearV2_cars-calender__3t0Hw {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px;
}
.FootwearV2_cars-calender__3t0Hw h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.FootwearV2_calendly-inline-widget__3Ks-s {
  width: 100%;
}
.FootwearV2_main__ZRcWV {
  padding: 50px 0px;
}
.FootwearV2_book-demo__2yg6H {
  margin-bottom: 30px;
}
.FootwearV2_book-demo__2yg6H h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  letter-spacing: 0.2px;
  color: #5d5d6d;
  margin-bottom: 15px;
  padding-left: 5%;
}
.FootwearV2_book-demo__2yg6H h1 span {
  color: #ff7700;
}
.FootwearV2_book-demo__2yg6H p span {
  color: #ff7700;
}
.FootwearV2_book-demo__2yg6H p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}
.FootwearV2_book-demo__2yg6H img {
  float: left;
}
.FootwearV2_cars-calender__3t0Hw {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px 10px 20px;
  height: 100%;
  overflow: hidden;
  background: #fff;
}
.FootwearV2_cars-calender__3t0Hw h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.FootwearV2_calendly-inline-widget__3Ks-s {
  height: 100% !important;
}

.FootwearV2_ticker-headline__A_lqc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 15px 0;
  margin: 0;
  font-size: 18px;
}

.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI {
  height: 100%;
  width: auto;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_ {
  width: auto;
  padding-right: 50px;
  transition: 0.6s ease-in-out top;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_ {
    transition: 0.6s ease-in-out;
  }
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_.FootwearV2_next__2wh22,
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_.FootwearV2_active__1Y68y.FootwearV2_right__HjjFU {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    top: 0;
  }
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_.FootwearV2_prev__3TtTv,
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_.FootwearV2_active__1Y68y.FootwearV2_left__dYp3I {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    top: 0;
  }
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_.FootwearV2_next__2wh22.FootwearV2_left__dYp3I,
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_.FootwearV2_prev__3TtTv.FootwearV2_right__HjjFU,
  .FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_item__DlGT_.FootwearV2_active__1Y68y {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    top: 0;
  }
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_active__1Y68y,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_next__2wh22,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_prev__3TtTv {
  display: block;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_active__1Y68y {
  top: 0;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_next__2wh22,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_prev__3TtTv {
  position: absolute;
  top: 0;
  width: 100%;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_next__2wh22 {
  top: 100%;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_prev__3TtTv {
  top: -100%;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_next__2wh22.FootwearV2_left__dYp3I,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_prev__3TtTv.FootwearV2_right__HjjFU {
  top: 0;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_active__1Y68y.FootwearV2_left__dYp3I {
  top: -100%;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-inner__pzXdI > .FootwearV2_active__1Y68y.FootwearV2_right__HjjFU {
  top: 100%;
}

.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm {
  left: auto;
  width: 50px;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm.FootwearV2_up__2yvfN {
  top: 0;
  right: 0;
  bottom: 50%;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm.FootwearV2_down__3g8gn {
  top: 50%;
  right: 0;
  bottom: 0;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-prev__Fn7zU,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-next__1Iq2x,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_glyphicon-chevron-up__2uOtJ,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_glyphicon-chevron-down__2Y3Dn {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-prev__Fn7zU,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_glyphicon-chevron-up__2uOtJ {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-next__1Iq2x,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_glyphicon-chevron-down__2Y3Dn {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-up__STm45,
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-down__16EsY {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-prev__Fn7zU:before {
  content: '‹';
}
.FootwearV2_carousel__1k52I.FootwearV2_vertical__32G6B .FootwearV2_carousel-control__eqqCm .FootwearV2_icon-next__1Iq2x:before {
  content: '›';
}

.FootwearV2_slide-images__88h0H .FootwearV2_item__DlGT_ img {
  width: 100%;
  height: 100vh;
}

.FootwearV2_calendly-area__hFX7M .FootwearV2_title__3uT7n {
  margin-bottom: 25px;
}
.FootwearV2_calendly-area__hFX7M .FootwearV2_title__3uT7n h1 {
  margin-bottom: 0;
}
.FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 {
  text-align: left;
  padding: 1% 5% 0;
}
.FootwearV2_calendly-area__hFX7M {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.FootwearV2_mob-area__197Fl {
  padding-top: 1%;
}
.FootwearV2_custom-backgrounds__1XqTt .FootwearV2_title__3uT7n h1 {
  margin-bottom: 10px;
}
.FootwearV2_custom-backgrounds__1XqTt .FootwearV2_title__3uT7n p {
  margin-bottom: 30px;
}
.FootwearV2_calendly-area__hFX7M .FootwearV2_request-demo2__3zJ4Q img {
  margin: 10px 0;
}

.FootwearV2_mob-image__CyXpP {
}
.FootwearV2_mob-image__CyXpP img {
  width: 100%;
}
.FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 p {
  margin-bottom: 30px;
}
.FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 p img {
  margin-top: 30px;
  width: 140px;
}
.FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 .FootwearV2_request-demo2__3zJ4Q {
  display: block;
}

.FootwearV2_exterior-tabs__3LjVs ul li.FootwearV2_selected__LAvTd {
  background: #fff;
  color: black;
  border-radius: 0;
  border: 0;
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.FootwearV2_exterior-tabs__3LjVs ul li button {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background: transparent;
  color: #444444;
  padding: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 0 !important;
}
.FootwearV2_exterior-tabs__3LjVs ul li button.FootwearV2_activ__G2Rv_,
.FootwearV2_exterior-tabs__3LjVs ul li button:hover,
.FootwearV2_exterior-tabs__3LjVs ul li button.FootwearV2_virtual__2vyad {
  color: #ff7700 !important;
  border-bottom: 1px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.FootwearV2_exterior-tabs__3LjVs ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 0;
}
.FootwearV2_exterior-tabs__3LjVs ul li {
  padding: 3px;
  margin: 15px 20%;
  background: transparent;
  list-style: none;
  display: inline-block;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  box-shadow: 0px 2px 3px 0px #ddd;
}
.FootwearV2_slates-tabs__1BcQb li {
  padding: 5px 10px !important;
  margin: 10px !important;
}
.FootwearV2_custom-links__3R-x_ ul li {
  padding: 0px 5px 0;
  margin-bottom: 5px;
}

.FootwearV2_custom-backgrounds__1XqTt {
  padding: 75px 0px;
}
/* Virtual section  */
.FootwearV2_virtual-images__x3YxS {
}
.FootwearV2_virtual-images__x3YxS img {
  width: 100%;
}

.FootwearV2_slates-tabs__1BcQb li.FootwearV2_selected__LAvTd {
  border-top: 0px !important;
}

.FootwearV2_v-image__160uj {
  padding: 0 15px !important;
}
.FootwearV2_tab-middle__3Sopd {
  padding-left: 0;
}

@media (max-width: 600px) {
  .FootwearV2_heading-text__3Ib1Y {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .FootwearV2_heading-para__1xbkB {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .FootwearV2_section-two-one__1C-gZ p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .FootwearV2_mob-change__2s3n1 h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .FootwearV2_try-images-area__2kiHX img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .FootwearV2_heading-text__3Ib1Y {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .FootwearV2_heading-para__1xbkB {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .FootwearV2_left-section__24pKH {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .FootwearV2_left-section__24pKH {
    margin-top: 10%;
  }
}

@media (max-width: 800px){
  .FootwearV2_bulk-images-slider__1m7NR {
      width: 100%;
      margin: 0 auto ;
  }
}
@media screen and (max-width: 800px) and (min-width: 200px) {
  .FootwearV2_title-area__GfLmL h1 {
    display: none;
  }
  .FootwearV2_mac-pro-image__101NE img {
    width: 100%;
    margin-bottom: 20px;
  }

  .FootwearV2_request-demo2__3zJ4Q img {
    height: 40px;
    width: 130px;
  }
  .FootwearV2_abs-img__6keDu {
    top: 33%;
    width: 30% !important;
    left: 35%;
  }
  .FootwearV2_graph-image__KVzkf img {
    width: 100%;
  }
  .FootwearV2_graph-area__3v-0x .FootwearV2_title__3uT7n {
    margin-bottom: 0px;
    text-align: center;
    padding-top: 0%;
  }
  .FootwearV2_title__3uT7n h1 {
    font-size: 18px;
    line-height: 30px;

    margin-bottom: 20px;
  }
  .FootwearV2_request-demo__1YtUm {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    line-height: normal;
    line-height: initial;
    height: 40px;
    width: 130px;
    margin: 0 auto;
  }
  .FootwearV2_graph-area__3v-0x {
    padding: 0 0;
  }

  .FootwearV2_car-v2-left__1P7my .FootwearV2_why-tabs__3-pZ3 span {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_car-v2-left__1P7my .FootwearV2_why-tabs__3-pZ3 p {
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_why-tabs__3-pZ3 li button {
    padding: 10px;
  }
  .FootwearV2_why-tabs__3-pZ3 img {
    width: 15%;
    margin-top: 10px;
  }
  .FootwearV2_why-tabs__3-pZ3 li + li button img {
    margin-top: 7%;
  }
  .FootwearV2_car-v2-left__1P7my p {
    text-align: center;
  }
  .FootwearV2_car-v2-tabs__3TE_j {
    padding: 0;
    margin-bottom: 20px;
  }
  .FootwearV2_title-area__GfLmL {
    margin: 0;
  }
  .FootwearV2_car-v2-left__1P7my {
    order: 2;
    padding: 0;
  }
  .FootwearV2_why-use__1akj8 h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .FootwearV2_right-sections__30_aP div {
    margin: 0;
    text-align: center;
  }
  .FootwearV2_car-v2-left__1P7my p,
  .FootwearV2_why-use__1akj8 p {
    font-size: 12px;
    line-height: inherit;
    margin-bottom: 15px;
    text-align: center;
  }

  .FootwearV2_first-tab-content__3SQKQ img {
    width: 70%;
    margin: 3% auto 5%;
  }
  .FootwearV2_gif-area__1gY5g {
    padding: 0px 0 40px;
  }
  .FootwearV2_banner-slider__2HUmP {
    margin-bottom: 20px;
    margin-top: 55px;
  }
  .FootwearV2_why-text__thncz {
    width: 80%;
    float: right;
  }
  .FootwearV2_why-text__thncz h6 {
    font-size: 18px;
  }
  .FootwearV2_graph-image__KVzkf {
    padding: 0px 20px;
  }
  .FootwearV2_mac-pro-image__101NE {
    padding: 0 25px;
  }

  .FootwearV2_cars-demo__3eITN {
    height: auto;
    text-align: center;
    margin-top: 30px;
  }
  .FootwearV2_title__3uT7n h6 {
    font-size: 14px;
    margin: 0;
  }
  .FootwearV2_cars-video___0wAo {
    height: auto;
  }

  .FootwearV2_calendly-area__hFX7M {
    padding: 60px 0px 15%;
    margin-bottom: 0px;
  }
  .FootwearV2_scroll-tab__1C7Jk .FootwearV2_why-use__1akj8 {
    padding: 0;
    margin: 0;
  }

  .FootwearV2_graph-image__KVzkf img {
    padding: 0;
  }
  
  .FootwearV2_mob-image__CyXpP {
    order: 2;
  }
  .FootwearV2_calendly-area__hFX7M .FootwearV2_request-demo2__3zJ4Q img {
    margin: 0;
  }
  .FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 p img {
    margin-top: 0;
    margin-left: 15px;
  }
  .FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 .FootwearV2_request-demo2__3zJ4Q {
    display: inline-block;
  }
  .FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 {
    text-align: left;
    padding: 10% 2%;
  }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .FootwearV2_custom-request__2_Fwv {
    order: 4;
    margin-bottom: 20px;
  }
  .FootwearV2_custom-links__3R-x_ {
    order: 2;
  }
  .FootwearV2_custom-image__3L3O2 img {
    height: 200px;
  }
  .FootwearV2_custom-image__3L3O2 {
    padding: 0;
    height: -webkit-max-content;
    height: max-content;
  }
  .FootwearV2_custom-links__3R-x_ ul {
    display: flex;
    width: 100%;
  }
  .FootwearV2_custom-links__3R-x_ ul li button img {
    width: 100%;
    height: auto;
  }
  .FootwearV2_custom-links__3R-x_ ul li button {
  }

  .FootwearV2_custom-links__3R-x_ ul li {
    padding: 10px 5px 0;
  }
  .FootwearV2_custom-backgrounds__1XqTt {
    padding: 40px 0px 20px;
  }
  .FootwearV2_left-arrow__Oiax6 {
    width: auto;
    float: unset;
    height: 70%;
    position: absolute;
    top: 15%;
  }
  .FootwearV2_frame-img__pbJF8 {
    left: 0px !important;
    right: auto;
    width: 100% !important;
    margin: 0 auto;
  }
  .FootwearV2_arrows__IMV1X {
    text-align: center;
  }
  .FootwearV2_btm-arrow__1Idw2 {
    margin: 4% auto 0;
  }
  .FootwearV2_exterior-tabs__3LjVs ul {
    display: flex;
    padding: 5px 10% !important;
  }
  .FootwearV2_exterior-tabs__3LjVs ul li {
    margin: 5px 6px 0 !important;
    font-size: 12px;
    padding: 2px !important;
  }
  .FootwearV2_tab-middle__3Sopd {
    padding: 0 15px;
  }
  .FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 h1 {
    text-align: center;
  }
  .FootwearV2_custom-backgrounds__1XqTt .FootwearV2_title__3uT7n p { 
    font-weight: 400;
}
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .FootwearV2_graph-area__3v-0x {
    padding: 0 0 60px !important;
  }
  .FootwearV2_first-tab-content__3SQKQ img {
    width: 100% !important;
    margin: 0 auto;
    margin-top: 30% !important;
  }
  .FootwearV2_car-v2-left__1P7my {
    order: 1 !important;
  }
  .FootwearV2_right-sections__30_aP {
    order: 2;
  }
  .FootwearV2_graph-area__3v-0x .FootwearV2_title__3uT7n {
    order: 1 !important;
    padding-top: 8%;
    margin: 0;
  }
  .FootwearV2_graph-image__KVzkf {
    order: 2;
  }
  .FootwearV2_first-tab-content__3SQKQ img {
    width: 85%;
    margin-top: 10%;
  }
  .FootwearV2_cars-video___0wAo {
    height: 350px !important;
  }
  .FootwearV2_cars-demo__3eITN {
    height: 350px !important;
    margin-top: 0 !important;
  }
  .FootwearV2_calendly-area__hFX7M {
    padding: 60px 0px 12% !important;
    margin-bottom: 0px;
  }
  .FootwearV2_custom-links__3R-x_ {
    order: initial !important;
  }
  .FootwearV2_custom-links__3R-x_ ul li button img {
    width: 100%;
    height: auto;
  }
  .FootwearV2_custom-links__3R-x_ ul {
    width: 80%;
  }
  .FootwearV2_custom-image__3L3O2 img {
    height: 270px;
  }
  .FootwearV2_left-arrow__Oiax6 {
    width: 15%;
    height: 50%;
    top: 15%;
  }
  .FootwearV2_frame-img__pbJF8 {
    width: 95% !important;
    margin: 0 auto;
  }
  .FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 {
    padding: 5% 0% 0% !important;
  }
  .FootwearV2_mob-image__CyXpP img {
    margin-top: 10%;
  }
  .FootwearV2_mob-area__197Fl .FootwearV2_why-use__1akj8 p { 
    text-align: left;
}
.FootwearV2_car-v2-left__1P7my p, .FootwearV2_why-use__1akj8 p { 
  text-align: left;
}

}

@media screen and (max-width: 1200px) and (min-width: 901px) {
  .FootwearV2_why-text__thncz p {
    font-size: 12px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_why-text__thncz h6 {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_first-tab-content__3SQKQ img {
    width: 75%;
  }
  .FootwearV2_custom-links__3R-x_ ul li {
    padding: 30px 20px 0;
  }
  .FootwearV2_graph-area__3v-0x {
    padding: 0 0 50px;
  }
  .FootwearV2_custom-links__3R-x_ ul li button img {
    width: 100%;
    height: 45px;
  }
  .FootwearV2_custom-image__3L3O2 img {
    height: 420px;
  }
  .FootwearV2_custom-links__3R-x_ ul {
    width: 75%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .FootwearV2_custom-image__3L3O2 img {
    width: 100%;
    position: relative;
    height: 505px;
  }
  .FootwearV2_custom-links__3R-x_ ul li button img {
    width: 100%;
    height: 80px;
  }
}

 .AppLanding_bg-white__1QPKI{
background: #fff;
}.AppLanding_app-banner__l1Njr{
    padding: 100px 0 60px;
    background: linear-gradient(180deg, rgba(255, 119, 0, 0.5) 0%, rgba(255, 119, 0, 0) 100%);
}
.AppLanding_app-banner__l1Njr h4{
    font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 60px; 
letter-spacing: 0.2px; 
color: #000000;
margin-bottom: 0;
}
.AppLanding_app-banner__l1Njr p{
    font-weight: 500;
font-size: 15px;
line-height: 30px;  
letter-spacing: 0.2px; 
color: #737373;
padding: 0 ;
}
.AppLanding_business-online__2UDRK{
    padding: 60px 6%;
}
 
.AppLanding_title__2_QzV h3{
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 36px; 
letter-spacing: 0.1px; 
color: #000000; 
opacity: 0.8;
opacity: 0.8;
margin: 0 0 6%;
}
.AppLanding_business-images__kwLfA{
text-align: center;
}
.AppLanding_business-images__kwLfA img{
    height: 90%;
}
.AppLanding_business-content__1ra1E{
    position: relative;
}
.AppLanding_business-content__1ra1E:before {
    content: attr(data-name);
    position: absolute; 
    left: 0px;
    top: 0px;
    font-size: 190px;
    line-height: 190px;
    font-weight: 400;
    color: #F15E22; 
    opacity: 0.1;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}
.AppLanding_business-content__1ra1E{
    
    padding-left: 9%;
    padding-top: 8%;
    padding-right: 0;

}
.AppLanding_business-content__1ra1E h3{
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 70px;
    color: #000000;
    margin-bottom: 15px;
}
.AppLanding_business-content__1ra1E p{
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #18191F;
}
.AppLanding_business-content__1ra1E p a{
    color: #ff7700;
    font-size: 16px;
    transition: .3s ease-in-out;
    font-weight: 500;
}
 
.AppLanding_business-content__1ra1E p a:hover img{
    margin-left: 15px;
}
.AppLanding_business-content__1ra1E p a img{
    width: 20px;
    margin-left: 5px;
    transition: .2s ease-in-out;
}
.AppLanding_third-images__Zm0aF img{
    height: auto!important;
}
.AppLanding_spyne-ai-works__zNhET{
    padding: 60px 6%;
}
.AppLanding_spyne-ai-works__zNhET .AppLanding_title__2_QzV h3{
    margin-bottom: 10px;
}
.AppLanding_spyne-ai-works__zNhET .AppLanding_title__2_QzV {
    margin-bottom: 30px;
}
.AppLanding_spyne-works__1DUjB{
text-align: center;
}
.AppLanding_spyne-works__1DUjB img{
 width: 80%;
}
.AppLanding_spyne-ai-works__zNhET .AppLanding_title__2_QzV p{
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 35px; 
text-align: center;
letter-spacing: 0.2px; 
color: #737373;
}
.AppLanding_spyne-works__1DUjB h4{
text-align: right;
font-weight: 600;
font-size: 18px;
line-height: 36px; 
letter-spacing: 0.1px; 
color: #000000;
padding: 0;
opacity: 0.8;
transition: .2s ease-in-out;
}
.AppLanding_works-single__1PRcC:hover h4{
    color: #ff7700;
    opacity: 1;
}
.AppLanding_spyne-works__1DUjB p{
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    line-height: 35px; 
    text-align: right;
    letter-spacing: 0.2px;
    color: #737373;
    opacity: 0.8;
    padding: 0;
    margin: 0;
}
.AppLanding_works-single__1PRcC{
    margin: 0 0 20px;
}
.AppLanding_works-single__1PRcC img{
    width: 38px; 
    padding-right: 0; 
    margin: 0 auto 10px;
}
.AppLanding_categories__3WOge{
    padding: 60px 6%;
}
 
.AppLanding_create-content__2btj1{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/bg11.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    padding: 18% 5% 0;
    height: 740px;
    margin-bottom: 60px;
}

.AppLanding_account-details__3sVtM{
    width: 45%;
}
.AppLanding_account-details__3sVtM p span{
    font-weight: bold;
font-size: 18px;
line-height: 35px; 
letter-spacing: 0.1px; 
color: #FA4A0C;  
margin: 0px 0px;
}
.AppLanding_account-details__3sVtM h3{
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px; 
    letter-spacing: 0.2px; 
    color: #252B42;
}
.AppLanding_account-details__3sVtM p{
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: #737373;
    margin-bottom: 30px;
}
.AppLanding_spyne-wrk2__3tP64 h4, .AppLanding_spyne-wrk2__3tP64 p{
    text-align: left!important;

}
.AppLanding_spyne-wrk2__3tP64 img{
    width: 60px!important;
}

.AppLanding_community-grid__3Zsu6{
    display: grid; 
    grid-template-areas:
        "fashion automobile automobile"
        "fashion jewellery footwear"
        "food food footwear"
        "food food watch";
    grid-gap: 16px;
    grid-template-rows: repeat(4, minmax(300px,1fr));
}
.AppLanding_community-grid__3Zsu6 .AppLanding_child__2IBmj:nth-child(1) {
    grid-area: fashion;
}
.AppLanding_child__2IBmj {
    position: relative;
    height: auto;
    width: 100%;   
    background-size: cover!important;
    background-position: center!important;
    box-shadow: -5px 1px 8px 0px #ccc;
}
 
.AppLanding_community-grid__3Zsu6 .AppLanding_child__2IBmj:nth-child(2) {
    grid-area: automobile;
}
 
.AppLanding_community-grid__3Zsu6 .AppLanding_child__2IBmj:nth-child(3) {
    grid-area: jewellery;
}
.AppLanding_community-grid__3Zsu6 .AppLanding_child__2IBmj:nth-child(4) {
    grid-area: footwear;
}
.AppLanding_community-grid__3Zsu6 .AppLanding_child__2IBmj:nth-child(5) {
    grid-area: food;
}
.AppLanding_community-grid__3Zsu6 .AppLanding_child__2IBmj:nth-child(6) {
    grid-area: watch;
}
 
.AppLanding_work-auto__1goJD {
    position: relative;
    left: 5%;
    top: 5%;
    right: 0;
    z-index: 2;
}
.AppLanding_child__2IBmj img{
    width: 100%;
    position: relative;
}
.AppLanding_fashion__11p-T{
background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/work1.png');
}
.AppLanding_automobile__1pLqT{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/work3.png');
}
.AppLanding_jewellery__21WVI{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/work2.png');
}
.AppLanding_footwear__zVgYi{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/jutti.png');
}
.AppLanding_food__2YH0s{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/smose.png');
}
.AppLanding_watch__zhVKQ{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/watch.png');
}
 
.AppLanding_work-auto__1goJD a {
    border: 1px solid #212529; 
    color: #212529; 
    background: transparent; 
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    padding: 5px 15px;
    line-height: 40px; 
    transition: .3s ease-in-out;
}
  
.AppLanding_child__2IBmj:hover a{
    background: #FF7700;
    color: #fff;
}
.AppLanding_child__2IBmj::after{
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 75%));
    height: 100%;
    bottom: 0%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    z-index: 1;
}
.AppLanding_child__2IBmj:hover::after {
    opacity: 1 !important;
}

.AppLanding_img-google__3sUWk{
    width: 150px;
    margin: 15px 10px 0;
}

 


/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 366px){
    .AppLanding_banner-btns__xudtD img{
        width: 170px!important;
        margin: 0;
    }
    .AppLanding_business-content__1ra1E { 
        padding-left: 15px!important;
    }
    .AppLanding_img-google__3sUWk{
        width: 140px;
        margin: 15px 4px 0;
    }
    .AppLanding_business-images__kwLfA img {
        width: 65%!important;
    }
.AppLanding_third-images__Zm0aF img{
    width: 100%!important;
    margin: 10% 0 0 ;
}
.AppLanding_account-details__3sVtM img {
    width: 23%;
}
}
@media screen and (max-width: 365px){
    .AppLanding_img-google__3sUWk{
        
        width: 43%!important;
        padding-bottom: 0!important;
        margin: 10px 5px 15px;
        }
    .AppLanding_banner-btns__xudtD img{
        width: 50%!important;
        margin: 0;
    }
    .AppLanding_business-content__1ra1E { 
        padding-left: 5%!important; 
    }

}



@media screen and (max-width: 767px) and (min-width:200px){
    .AppLanding_app-banner__l1Njr img{
        width: 100%;
        padding: 0 5% 20px;
    }
    .AppLanding_app-banner__l1Njr h4 { 
        font-size: 16px;
        line-height: 30px;
        margin: 15px 0;
    }
    .AppLanding_app-banner__l1Njr p { 
        font-size: 12px;
        line-height: 20px;
    }
    .AppLanding_app-banner__l1Njr {
        padding: 100px 0 0px;
    }
    .AppLanding_business-online__2UDRK{
        padding: 25px 0% 0;
    }
    .AppLanding_title__2_QzV h3 { 
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 30px;
    }
   
    .AppLanding_business-content__1ra1E h3 {
        font-size: 21px;
    line-height: 45px;
    font-weight: 500;
        margin: 0;
    }
    .AppLanding_business-content__1ra1E:before {
        font-size: 70px;
        line-height: 70px;
        left: 5%; 
    }
    .AppLanding_business-content__1ra1E p {
        text-align: center;
    }
    .AppLanding_business-images__kwLfA img {
        height: auto;
        margin-bottom: 30px;
        width: 70%; 
    }
    .AppLanding_spyne-ai-works__zNhET{
        padding: 0px ;
    }
    .AppLanding_spyne-works__1DUjB{
        text-align: center!important;
        display: flex;
    padding: 0;
    }
    .AppLanding_works-single__1PRcC {
        margin: 0 0 20px;
        padding: 0 9px;
    }
    .AppLanding_spyne-works__1DUjB h4 {
        text-align: center!important; 
        font-size: 12px;
        line-height: 18px;
    }
    .AppLanding_spyne-works__1DUjB p {
        text-align: center!important;
        line-height: 18px;
        font-size: 10px;
    }
    .AppLanding_spyne-works__1DUjB img { 
        margin: 25px auto 40px;
        width: 85%;
        padding: 0 10%;
    }
    .AppLanding_works-single__1PRcC img {
        width: 25px;
        height: 50px;
        margin: 0 auto 10px;
        padding: 0;
        object-fit: contain;
    
    }

    .AppLanding_spyne-wrk2__3tP64 img {
        width: 60px!important;
        margin: 0 auto 10px!important;
    }
    .AppLanding_community-grid__3Zsu6 {
        grid-template-areas:
            "food food"
            "footwear jewellery"
            "footwear fashion"
            "watch fashion"
            "automobile automobile";
        grid-template-columns: auto auto;
        grid-template-rows: repeat(5, minmax(150px,1fr));
        grid-gap: 10px;
        padding: 0 5px; 
    }
    .AppLanding_categories__3WOge {
        padding: 60px 0%;
    }
    .AppLanding_create-content__2btj1 {
        background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/Group%205892487.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 70px 10px 35px;
        height: 100%;
        margin-bottom: 20px;
    
    }
    .AppLanding_account-details__3sVtM {
        width: 64%;
    }
    .AppLanding_account-details__3sVtM h3 { 
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 5px;
    }
    .AppLanding_account-details__3sVtM p {
        font-weight: 500;
        font-size: 8px;
        line-height: normal;
        line-height: initial;
        margin: 0 0 5px;
    }
    .AppLanding_account-details__3sVtM p span { 
        font-size: 12px;
        line-height: 20px
    }

.AppLanding_business-images__kwLfA img{
    width: 75%;
}
.AppLanding_spyne-ai-works__zNhET .AppLanding_title__2_QzV h3 {
    margin-bottom: 10px;
    font-size: 22px;
}
.AppLanding_work-auto__1goJD a {
    border: 1px solid #212529; 
    color: #212529; 
    font-size: 11px;
    padding: 2px 15px;
}
.AppLanding_business-content__1ra1E {
    padding-right: 15px;
    order: 2; 
    text-align: center;
    margin-bottom: 30px;
}
}


@media screen and (max-width: 900px) and (min-width:768px){
.AppLanding_categories__3WOge {
    padding: 60px 0%;
}
.AppLanding_business-online__2UDRK {
    padding: 60px 5%;
}
.AppLanding_business-content__1ra1E h3 {
    font-size: 35px;
    line-height: 55px;
}
.AppLanding_business-images__kwLfA img {
    height: 85%;
}
.AppLanding_business-content__1ra1E:before {
    left: -10%;
}
.AppLanding_business-content__1ra1E {
    padding-left: 5%;
}

.AppLanding_third-images__Zm0aF img { 
    margin-top: 40%;
}
.AppLanding_spyne-ai-works__zNhET {
    padding: 60px 0%;
}
.AppLanding_spyne-works__1DUjB {
    padding: 0;
}
.AppLanding_spyne-works__1DUjB p {
    font-size: 11px;
    line-height: 25px;
}
.AppLanding_spyne-works__1DUjB h4 {
    font-size: 13px;
    line-height: 25px;
}
.AppLanding_works-single__1PRcC img {
    width: 38px;
    padding: 0;
}
.AppLanding_create-content__2btj1 {
    background-size: 100%;
    padding: 13% 5%;
    height: 100%;
    margin: 0;
}
.AppLanding_account-details__3sVtM h3 {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 10px;
}

.AppLanding_account-details__3sVtM p {
    font-weight: 500;
    font-size: 11px;
    line-height: 25px;
    margin-bottom: 5px;
}
.AppLanding_account-details__3sVtM p span {
    font-size: 14px;
    line-height: 25px;
}
.AppLanding_account-details__3sVtM img{
    width: 100px; 
}

.AppLanding_spyne-works__1DUjB img { 
    margin-top: 15%;
}
.AppLanding_business-content__1ra1E{
    padding-left: 3%;
}
.AppLanding_spyne-ai-works__zNhET {
    padding: 10px 0% 0;
}
.AppLanding_account-details__3sVtM h3 {
    font-size: 30px;
    line-height: 40px;
}
.AppLanding_img-google__3sUWk {
    width: 120px;
    margin: 15px 5px 0;
}
}


@media screen and (max-width: 1024px) and (min-width:901px){
.AppLanding_create-content__2btj1 {
    padding: 15% 5% 0;
    height: 590px;
}
.AppLanding_business-online__2UDRK {
    padding: 60px 0%;

}
.AppLanding_create-content__2btj1 {
    background-size: 100%;
    height: 100%;
    padding: 15% 5% 9%;
}
.AppLanding_business-content__1ra1E{
    padding-left: 3%;
}
.AppLanding_spyne-ai-works__zNhET {
    padding: 10px 0% 0;
}
.AppLanding_account-details__3sVtM h3 {
    font-size: 29px;
    line-height: 40px;
}
.AppLanding_business-online__2UDRK {
    padding: 60px 0%;
}
.AppLanding_img-google__3sUWk {
    width: 140px;
    margin: 15px 5px 0;
}
}
.LoginPage_font-family__3UgMZ {
    font-family: 'Manrope', sans-serif;
	margin: 2% 5%;
	box-shadow: 0px 10px 20px 0px #ddd;
	padding: 0px 0 30px;
}

.LoginPage_logo__yYiBp {
	max-width: 87px;
    margin-top: -1%;
}
.LoginPage_logo2__1pLgj{
	max-height: 25px;
}
.LoginPage_back__29bw3 {
	font-size: 16px;
	font-weight: 600; 
line-height: 22px;  
letter-spacing: 0.5px;
border-left: 2px solid #FF7700;
color: #000000;
	cursor: pointer;  
	box-shadow: inset 0 0 0 0 #ff7700;
	transition:ease-out 0.4s;
	padding: 3px 20px 7px;
	margin: 0;

	display: inline-block;
	text-align: center;
}
.LoginPage_back__29bw3 span{ 
    font-weight: 900; 
    font-size: 22px;  
}
 .LoginPage_back__29bw3:hover{
    border-radius: 5px;
	box-shadow: inset 200px 0 0 0 #ff7700;
color: #fff;
}
/*.back:hover span{
	padding-left: 10px;
	padding-right: 5px;
} */
.LoginPage_create-account__3Trkj {
	font-size: 16px; 
	letter-spacing: 0.2px;
	font-family: 'Roboto', sans-serif;
	color: #979797;
	font-weight: 600; 
line-height: 25px; 
margin: 0;

}

.LoginPage_is-link__18ZwR {
	color: #ff7700;
	text-decoration: none;
	cursor: pointer;
}
.LoginPage_forget-pass__3n63-{ 
	font-weight: 600;
	font-size: 12px;
	line-height: 16px; 
	align-items: center;
	letter-spacing: 0.2px; 
	color: #696F79;
	padding: 15px 0 20px;
}
.LoginPage_forget-pass__3n63- .LoginPage_is-link__18ZwR {
	color: #696F79; 
}
.LoginPage_is-link__18ZwR:hover{
	color: #ff7700;
}
.LoginPage_is-primary__EyBYP {
	color: #ff7700;
}

.LoginPage_top-container__3WAho {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0 0;
}

.LoginPage_is-link__18ZwR:hover { 
	color: #ff7700;
	text-decoration: none;
}

.LoginPage_brand__YvcOy {
	font-size: 26px;
	color: #3C4043;
	font-weight: 500;
	line-height: 44px;
	letter-spacing: 0.5px;  
align-items: center; 
margin-bottom: 15px;;
}
.LoginPage_brand__YvcOy span{
	border-bottom: 2px solid #ff7700;
	padding-bottom: 4px;
}
.LoginPage_line-divider__2UqZa {
	background-color: rgba(0,0,0,0.2);
	width: 1px;
	height: 100%;
}

.LoginPage_line-divider_or__TV0Wk{
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.2px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: #787878;
	padding: 20px;
	text-align: center;
}

.LoginPage_line-container___ky8f {
	display: flex;
	-webkit-box-align: center;
	justify-content: stretch;
	align-items: center;
	flex-direction: column;
	align-self: stretch;
}

.LoginPage_input-container__k5hEu {
	/* border-bottom: 0.4px solid #C0C0C0; */
	padding: 5px;
	margin: 1rem auto;
}

.LoginPage_input-box__2tarY .LoginPage_inp__DepgK {
	border: none;
	border-bottom:1px solid #ff770047;
	width: 100%;
	outline: none;
	font-weight: 600;
font-size: 16px;
line-height: 22px; 
letter-spacing: 0.2px; 
color: #979797;
}

.LoginPage_input-box__2tarY > .LoginPage_spa__299lz {
	position: absolute;
	left: 0;
	top: 2px;
	border-right: 1px solid #ff770047;
	padding-right: 5px;
	display: inline-block; 
	font-weight: 600;
font-size: 16px;
line-height: 22px;  
letter-spacing: 0.2px; 
color: #979797;
}

.LoginPage_input-box__2tarY > .LoginPage_spa__299lz {
	letter-spacing: 0.2px;
    font-size: 16px;
    color: #979797;
    line-height: 22px;
}

.LoginPage_input-box__2tarY > .LoginPage_lab__2JbnT {
	letter-spacing: 0.2px;
    padding: 0;
    font-size: 16px;
    color: #979797;
    line-height: 22px;
}

.LoginPage_input-box__2tarY > .LoginPage_bor__2_394 {
	position: absolute;
	width: 10px;
	height: 1px;
	background-color: #ff7700;
	bottom: 0;
	left: 45%;
	visibility: hidden;
	transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.LoginPage_input-box__2tarY .LoginPage_inp__DepgK:focus{
	border-bottom: 1.5px solid #ff7700e8;
}
.LoginPage_form-floating__1h362 {
    position: relative;
}

.LoginPage_form-floating__1h362>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    transition: opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out,-webkit-transform .1s ease-in-out;
}

.LoginPage_input-box__2tarY > .LoginPage_inp__DepgK:focus~.LoginPage_lab__2JbnT,
.LoginPage_input-box__2tarY > .LoginPage_inp__DepgK~.LoginPage_lab__2JbnT.LoginPage_filled__2DJAx
 {
	opacity: 0.65;
	-webkit-transform: scale(0.85) translateY(-1.25rem) translateX(.15rem);
	        transform: scale(0.85) translateY(-1.25rem) translateX(.15rem);
}

.LoginPage_inp__DepgK:focus~.LoginPage_bor__2_394 {
	width: 100%;
	left: 0;
	visibility: visible;
}

.LoginPage_inp__DepgK:disabled {
    display: none;
}

.LoginPage_error-box__G0tv5,
.LoginPage_password-error-box__2qRc_ {
	color: #EB4335;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	font-weight: 500;
	position: absolute;
	bottom: -20px;
	left: 0;
	text-align: left;
}

.LoginPage_error-box__G0tv5 a {
	color: #EB4335;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	font-weight: 500;
}

.LoginPage_resend-otp__V_zZ1 {
	color: #ff7700;
	font-size: 14px;
	line-height: 19px;
	font-weight: 500;
	letter-spacing: 0.2px;
	position: absolute;
	right: 0;
	cursor: pointer;
	display: none;
}

.LoginPage_request-btn__Tz9ou {
	color: #fff; 
	padding: 0 10px;
	min-height: 40px;
	font-size: 15px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.2px;
	width: calc(100% - 50px);
	border: 1px solid #444444; 
	background: #444444;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
width: 100%;
}

.LoginPage_submit-btn__1dTNH {
	color: #fff;
	min-height: 40px;
    width: 100%;
    padding: 0 2rem;
    font-size: 15px;
    line-height: 20px;
	letter-spacing: 0.2px;
	font-weight: bold;
	border: none;
	background: #FF7700;
	box-shadow: 0px 3px 4px rgba(255, 146, 0, 0.25);
	border-radius: 5px;
}

.LoginPage_google-btn__TAMtk {
	background-color: transparent;
	min-height: 50px;
	padding: 0 2rem;
	font-size: 16px;
	line-height: 22px;
	font-weight: bold;
	letter-spacing: 0.2px;
	color: #2874F0;
	width: calc(100% - 50px);
}

.LoginPage_pl-40__1wYau {
	padding-left: 40px;
}

/***************************************  Reset Password  *****************************************/
#LoginPage_secondary-container__1W-ne {
	background-color: #FFFFFF;
	word-break: break-all;
	border-radius: 13px;
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.51);
}

.LoginPage_form-header__ovX67 {
	font-family: Segoe UI;
	color: #27A4A9;
	font-weight: bold;
	font-size: 2rem;
}

.LoginPage_btn1__1ee44 {
	background-color: #27A4A9;
	border: none;
	color: white;
	padding: 9px 38px 9px 37px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
	border-radius: 20px;
}

#LoginPage_nav-css__3h-uM {
	background-color: #263238;
	color: white;
}

.LoginPage_form-element-deco__1-Wi_ {
	text-align: left;
	font-size: 14px;
	font-family: Segoe UI, Regular;
	padding: 15px;
	width: 100%;
	border-radius: 5px;
}

.LoginPage_forgetFormWrapper__DyD3f {
	margin: 15% 30%;
}

.LoginPage_login__3SMSi.LoginPage_error-box__G0tv5 {
	background-color: #FFE4EA;
	border-radius: 5px;
	padding: 0.5rem 1rem;
	text-align: left;
	font-size: 12px;
	display: none;
}
 
/********************************************  Forgot Password  ****************************************************/
.LoginPage_alert-deco__3BOFw {
	font-family: Segoe UI;
	color: #27A4A9;
	font-weight: bold;
	font-size: 2rem;
}

.LoginPage_btn1__1ee44 {
	background-color: #27A4A9;
	border: none;
	color: white;
	padding: 9px 38px 9px 37px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	cursor: pointer;
	border-radius: 20px;
}

#LoginPage_nav-css__3h-uM {
	background-color: #263238;
	color: white;
}

.LoginPage_form-element-deco__1-Wi_ {
	text-align: left;
	font-size: 14px;
	font-family: Segoe UI, Regular;
	padding: 15px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #E1E1E1;
}

.LoginPage_fs-14__2hTr0 {
	font-size: 14px;
}

.LoginPage_swicthToLogin__3vkB_ {
	margin-top: 3rem;
}

@media screen and (min-width: 1200px) {
	#LoginPage_secondary-container__1W-ne {
		background-color: #FFFFFF;
		word-break: break-all;
		border-radius: 13px;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.51);
	}
	.LoginPage_forgotFormWrapper__3rqYZ {
		margin: 15% 20%
	}
}

.LoginPage_box__3Ywk4 {
	max-width: 970px;
	margin-top: 30px;
}

.LoginPage_text-field__3-rAJ::-webkit-input-placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.LoginPage_text-field__3-rAJ:-ms-input-placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.LoginPage_text-field__3-rAJ::placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.LoginPage_mdc__7Q8oq input::-webkit-input-placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.LoginPage_mdc__7Q8oq input:-ms-input-placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.LoginPage_mdc__7Q8oq input::placeholder {
	font-size: 14px;
	color: #ABABAB;
}

.LoginPage_text-field-email__1jey1:focus, .LoginPage_text-field-pswd__2Ej5_:focus {
	border: 1px solid #27A4A9 !important;
}

.LoginPage_text-field-email__1jey1, .LoginPage_text-field-pswd__2Ej5_ {
	border: 1px solid #ddd !important;
}

@media screen and (min-width: 767px) {
	.LoginPage_box__3Ywk4 {
		background-color: #fff;
		border-radius: 5px;
		/* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5); */
	}
}

.LoginPage_grid__28iKQ {
	display: grid;
	justify-content: center;
	align-items: center;
	height: 100%;
	grid-template-columns: 38% 62%;
}

.LoginPage_vh-100__3fU9B {
	height: 100vh !important;
}

/************************** Notch Styling *****************************/
.LoginPage_mdc__7Q8oq {
	max-width: 345px;
	display: inline-flex;
	position: relative;
	border: none;
	overflow: visible;
	box-sizing: border-box;
	height: 48px;
	width: 345px;
}

.LoginPage_mdc__7Q8oq input, .LoginPage_mdc__7Q8oq select {
	display: flex;
	border: none;
	z-index: 1;
	box-sizing: border-box;
	padding: 12px 16px 14px;
	width: 100%;
	height: 100%;
	background: transparent;
	outline: none;
	border-radius: 5px;
}

.LoginPage_mdc__7Q8oq select option {
	padding: 12px 16px 14px;
}

.LoginPage_notch-outline__2yc4Y {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	box-sizing: border-box;
	text-align: left;
	pointer-events: none;
}

.LoginPage_notch-leading__V8txv {
	border-left: 1px solid #5eb3d1;
	border-right: none;
	border-bottom: 1px solid #5eb3d1;
	border-top: 1px solid #5eb3d1;
	width: 12px;
	box-sizing: border-box;
	pointer-events: none;
	border-radius: 4px 0 0 4px;
}

.LoginPage_notch__3RZTK {
	border-top: 1px solid #5eb3d1;
	border-bottom: 1px solid #5eb3d1;
	box-sizing: border-box;
	pointer-events: none;
	width: auto;
	height: 100%;
	flex: 0 0 auto;
}

.LoginPage_notch__3RZTK label {
	top: 7px;
	left: 4px;
	right: auto;
	display: inline-block;
	position: relative;
	bottom: auto;
	color: #000;
}

.LoginPage_notch-trailing__166AI {
	border-right: 1px solid #5eb3d1;
	border-top: 1px solid #5eb3d1;
	border-bottom: 1px solid #5eb3d1;
	border-left: none;
	width: 100%;
	flex-grow: 1;
	pointer-events: none;
	border-radius: 0 4px 4px 0;
}

.LoginPage_notch__3RZTK.LoginPage_active__3QfjR label {
	left: 0;
	-webkit-transform: translateY(-120%) scale(0.75);
	        transform: translateY(-120%) scale(0.75);
	transition: -webkit-transform 0.4s ease-in;
	transition: transform 0.4s ease-in;
	transition: transform 0.4s ease-in, -webkit-transform 0.4s ease-in;
	color: #27A4A9;
}

.LoginPage_notch__3RZTK.LoginPage_active__3QfjR {
	width: 56.75px !important;
	border-top: none;
	transition: -webkit-transform 0.4s ease-in;
	transition: transform 0.4s ease-in;
	transition: transform 0.4s ease-in, -webkit-transform 0.4s ease-in;
}

.LoginPage_notch__3RZTK.LoginPage_valid__2AY1L {
	/* width: 56.75px !important; */
	width: auto;
	border-top: none;
}

/* For chrome autofill styling */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-webkit-animation-name: LoginPage_autofill__33db6;
	-webkit-animation-fill-mode: both;
}

@media screen and (max-width: 767px) {
	.LoginPage_grid-item__elXWj.LoginPage_hide-sm__2ivva {
		display: none;
	}
	.LoginPage_grid__28iKQ {
		grid-template-columns: 1fr;
	}
	.LoginPage_mob-pad-0__3Ooo0 {
		padding: 0px !important;
	}
	.LoginPage_box__3Ywk4 {
		width: 90%;
	}
	.LoginPage_mdc__7Q8oq input, .LoginPage_mdc__7Q8oq select {
		background-color: #FFF;
		border: 1px solid #27A4A9;
	}
	.LoginPage_forgetFormWrapper__DyD3f {
		margin: 0;
	}
	.LoginPage_form-header__ovX67 {
		font-size: 25px;
	}
	#LoginPage_secondary-container__1W-ne {
		background-color: transparent;
		word-break: break-all;
		border-radius: 0px;
		box-shadow: none;
	}
	.LoginPage_swicthToLogin__3vkB_ {
		font-size: 14px;
	}
	
	.LoginPage_top-container__3WAho {
		padding: 0px;
	}
	
	.LoginPage_line-container___ky8f {
		flex-direction: row;
	}
	
	.LoginPage_line-divider__2UqZa {
		height: 1px;
		width: 100%;
	}
	
	.LoginPage_logo__yYiBp {
		max-width: 45px;
	}
	
	.LoginPage_brand__YvcOy {
		font-size: 20px;
		margin-top: 0;
	}
	
	.LoginPage_request-btn__Tz9ou {
		width: 100%;
		font-size: 13px;
	}
	
	.LoginPage_google-btn__TAMtk {
		width: 100%;
	}
	
	.LoginPage_hide-in-mobile__2t4EX {
		display: none;
	}
}
.LoginPage_loading-image__VemnJ {
  position: absolute;
  top: 50%;
  z-index: 10;
}
.LoginPage_loader__2YGS1
{
    display: none;
    width:auto;
    height: auto;
    top: 50%;
    text-align:center;
    margin-left: -100px;
    margin-top: -100px;
    z-index:2;
    overflow: auto;
}

#LoginPage_cover-spin__1bNR2 {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes LoginPage_spin__2otI3 {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes LoginPage_spin__2otI3 {
	from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}

#LoginPage_cover-spin__1bNR2::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: LoginPage_spin__2otI3 .8s linear infinite;
    animation: LoginPage_spin__2otI3 .8s linear infinite;
}

.LoginPage_photographer-signIn__SInUu {
	color: #787878;
	font-size: 24px;
    line-height: 25px;
	font-family: 'Manrope';
	font-weight: 700;
}

.LoginPage_photographer-signIn__SInUu > span {
	color: #00C488;
}
.LoginPage_success__39DFy{
    font-weight: 500;
font-size: 20px;
line-height: 33px; 
align-items: center;
letter-spacing: 0.5px; 
color: #00C488;
}

@media screen and (max-width: 800px){
	.LoginPage_font-family__3UgMZ {
		padding: 20px 15px 30px;
		margin: 0;
		box-shadow: 0 0 0;
	}
	.LoginPage_back__29bw3 {
		padding: 3px 10px 7px;
	}
	.LoginPage_photographer-signIn__SInUu {
		color: #787878;
		font-size: 20px;
		margin-bottom: 10px;
	}
	.LoginPage_logo2__1pLgj {
		max-height: 22px;
	}
}

.LoginPage_bg-try-mob__1wYHH {
	display: none;
  }
  
  @media screen and (max-width: 768px) {
	.LoginPage_bg-try__1BICK {
	  display: none;
	}
	.LoginPage_bg-try-mob__1wYHH {
	  margin: 120px 0 100px;
	  display: block !important;
	}
	.LoginPage_bg-try-mob__1wYHH h4 {
	  font-size: 24px;
	  color: #18191f;
	  font-weight: 600;
	}
	.LoginPage_bg-try-mob__1wYHH h6 {
	  font-size: 14px;
	  color: #929292;
	  margin-top: 25px;
	}
	.LoginPage_bg-try-mob__1wYHH p {
	  font-size: 14px;
	  color: #929292;
	  margin-top: 25px;
	  font-weight: 600;
	}
	.LoginPage_bg-try-mob__1wYHH img {
	  margin-top: 20px;
	}
  }
/* .forget-ps{
    
}.forget-pass{

}
.forget-pass-single{
    
} */
.ForgetPassword_forget-pass-box__21SNh p{
    font-weight: 500;
font-size: 12px;
line-height: 16px; 
align-items: center;
letter-spacing: 0.5px; 
color: #696F79;
margin-bottom: 20px;
}
.ForgetPassword_forget-pass-box__21SNh{
    padding: 15px 15px 40px;
    background: #FFFFFF;
border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 10px;
}
.ForgetPassword_forget-pass-box__21SNh button{
    margin: 20px 0px ;
}
.ForgetPassword_logo__2k9i8{
    max-width: 70px;
    margin-top: 0;
    padding: 15px 0px;
}

form p{
    text-align: center;
    margin: 20px 0 0!important;
    font-weight: 500;
font-size: 12px;
line-height: 16px; 
align-items: center;
letter-spacing: 0.5px; 
color: #696F79;;
}

@media screen and (max-width: 800px){
    .ForgetPassword_forget-pass-box__21SNh{
        padding: 15px 0px 20px;
        margin: 20px 0 0;
    }
}
.LeftMenu_left-menu__2xKp1 {
  position: fixed;
  height: 100vh;
  box-shadow: 12px 0px 66px -53px rgb(124 124 124);
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 12px 0px 66px -53px rgb(124 124 124 / 25%);
  left: 0;
  z-index: 999;
  padding: 10px;
}

.LeftMenu_sidebar-logo__bV9t2 {
  margin-bottom: 20px;
}

.LeftMenu_logo__34bZc {
  width: 100%;
}

.LeftMenu_side-tab__2Mcx7 {
  cursor: pointer;
  margin-top: 10px !important;
  padding: 15px 15px 15px 0 !important;
  border-left: 4px solid transparent;
  text-align: left;
}

.LeftMenu_menu-icon__3h-TN {
  padding: 0;
}

.LeftMenu_tab-active__3vO_9 .LeftMenu_menu-icon__3h-TN span,
.LeftMenu_side-tab__2Mcx7:hover .LeftMenu_menu-icon__3h-TN span,
.LeftMenu_tab-active__3vO_9 .LeftMenu_menu-icon__3h-TN i,
.LeftMenu_side-tab__2Mcx7:hover .LeftMenu_menu-icon__3h-TN i {
  color: #ff7700;
}

.LeftMenu_menu-icon__3h-TN span {
  font-size: 16px;
  line-height: 23px;
  color: #abaeaf;
}

.LeftMenu_tab-active__3vO_9,
.LeftMenu_side-tab__2Mcx7:hover {
  background: linear-gradient(90deg, #fad8bb 0%, rgba(255, 255, 255, 0) 78.6%);
  border-left: 4px solid #ff7700;
}

.LeftMenu_menu-icon__3h-TN i {
  color: #5f6368;
  font-size: 24px;
  padding: 0px 10px;
  vertical-align: middle;
}

.TableSection_change-tabs__EwPr5 {
  padding: 10px 0 20px;
  display: flex;
  justify-content: left;
  list-style: none;
  margin-left: 10px;
}

.TableSection_change-tabs__EwPr5 li {
  padding: 0;
}

.TableSection_change-tabs__EwPr5 li button {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  border-radius: 0;
  border-bottom: 2px solid transparent;
}
.TableSection_change-tabs__EwPr5 li button.TableSection_active__2D_6h,
.TableSection_change-tabs__EwPr5 li button:hover,
.TableSection_change-tabs__EwPr5 li button.TableSection_activate__1NkTf {
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700;
}

.TableSection_change-tabs__EwPr5 li button {
  background: transparent;
}

.TableSection_dropdown-btn__1Ybx1 {
  margin-top: 8px;
  margin-left: 36px;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  padding: 5px 9px;
  color: #ff7700;
}

select:focus-visible {
  outline: none;
}

select {
  color: #000 !important;
}

/* option:hover {
  background-color: #ff7700;
}

select.dropdown-btn option:hover {
  box-shadow: 0 0 10px 100px #ff7700 inset;
} */

/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex='-1']:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.min.css.map */

#Spinner_modal-root__3XCdY {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
  box-sizing: border-box;
  padding: 20px;
  color: #fff;
}

.Spinner_spinner-box__2mmZr {
  position: absolute;
  left: 50%;
  top: 50%;
}

.Spinner_loading-icon__3inbg {
  display: flex;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #ff7700; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-animation-name: Spinner_spin__1hHAC;
          animation-name: Spinner_spin__1hHAC;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  transition-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation: Spinner_spin__1hHAC 2s linear infinite;
          animation: Spinner_spin__1hHAC 2s linear infinite;
}

.Spinner_loading-icon__3inbg div {
  margin: auto;
}

@-webkit-keyframes Spinner_spin__1hHAC {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__1hHAC {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Spinner_spinner-background__20yg6 {
  position: fixed;
  /* width: 60%;
  height: 60%; */
  z-index: 1;
  top: 50%;
  left: 55%;
}

.Spinner_spinner-box__2mmZr span {
  color: #fff;
}

.WhitepaperBackgrounds_border-bt__23eZ_{
    border-bottom: 3px solid #FF7700; 
    padding-bottom: 5px;

}
.WhitepaperBackgrounds_colored__3_L8E{
    color: #ff7700;
}
.WhitepaperBackgrounds_whitepaper-bg__24-Gx{
    padding: 100px 0 50px;
}
.WhitepaperBackgrounds_heading__3KuoN h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 1.5px;
    padding: 0 0 20px;
    color: #232121;
  }
   
  .WhitepaperBackgrounds_backgrounds__35lmd {
    background: #FFFFFF;
    box-shadow: 1px 4px 4px #ddd;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 15px 20px;
   
  }
  .WhitepaperBackgrounds_backgrounds__35lmd h6 {
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0px 0px 20px;
    font-weight: 600;
  }
  .WhitepaperBackgrounds_backgrounds__35lmd h6 span {
   text-decoration: underline;
  }
  .WhitepaperBackgrounds_background-images__3OfA_ {
    list-style: none;
    display: block;
    padding: 0;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    height: 450px;
  }
  
  .WhitepaperBackgrounds_background-images__3OfA_ li {
    display: inline-block;
    margin-bottom: 15px;
    padding: 0 10px;
    cursor: pointer;
    width: 50%;
  }
  .WhitepaperBackgrounds_background-images__3OfA_ li img {
    width: 100%;
    height: auto; 
       border-radius: 5px;
  }
   
   
  .WhitepaperBackgrounds_background-images__3OfA_ li span {
    font-size: 11px;
    font-weight: 500;
  }
  
  .WhitepaperBackgrounds_backgrounds__35lmd p {
    font-size: 14px;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 5px 0px 0px;
  }
  
  .WhitepaperBackgrounds_background-right__2xued {
  } 
  .WhitepaperBackgrounds_background-right__2xued img{
    width: 100%;
    padding: 5px;
    box-shadow: 1px 4px 4px #ddd;
  } 
  .WhitepaperBackgrounds_try-demo__P2g3_{
    justify-content: center;
  }
  .WhitepaperBackgrounds_try-demo__P2g3_ p{
    width: auto;
    margin: 30px 10px 0;
  }
  .WhitepaperBackgrounds_try-demo__P2g3_ p span{
    display: block;
    font-weight: bold;
font-size: 11px;
line-height: 25px;  
color: #000000;
margin-top: 10px;
}
.WhitepaperBackgrounds_try-demo__P2g3_ p a {  
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    background: #ff7700;
    color: #ffffff;
    transition: 0.2s ease-in-out;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
    padding: 8px 30px;
    border: 2px solid #ff7700;
}
.WhitepaperBackgrounds_demo__HiJe2 a{ 
border: 2px solid #ff7700!important; 
color: #FF7700!important;  
background: #fff!important;
}
.WhitepaperBackgrounds_back-credit__3AN97 {
  text-align: end;
  font-size: 12px!important;
  margin-bottom: 0!important;
  padding: 0 10px 0 0;

}
.WhitepaperBackgrounds_back-credit__3AN97 img {
  width: auto!important;
  height: auto!important;
  padding-left: 4px;
  border: 0!important;
}
.WhitepaperBackgrounds_inactive-background-sec__1phxm{
border: 1.5px solid transparent;
transition: .2s ease-in-out;
}
.WhitepaperBackgrounds_active-background-sec__13VR_{
  border: 1.5px solid #ff7700;
}



/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px){
  .WhitepaperBackgrounds_whitepaper-bg__24-Gx{
    padding: 70px 0 0;
}
.WhitepaperBackgrounds_heading__3KuoN h3 { 
  font-size: 17px;

}
.WhitepaperBackgrounds_try-demo__P2g3_ p a {
  font-size: 14px;
  padding: 6px 35px;
}
.WhitepaperBackgrounds_demo__HiJe2 a{
  padding: 6px 20px!important;
}
.WhitepaperBackgrounds_demo__HiJe2{
  text-align: left!important;
}
.WhitepaperBackgrounds_try-demo__P2g3_ p {
  width: 50%;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
}
.WhitepaperBackgrounds_try-demo__P2g3_ {
  margin: 25px 0;
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.WhitepaperBackgrounds_try-demo__P2g3_ p span {  
  line-height: normal;  
  line-height: initial;
  display: none;
}
.WhitepaperBackgrounds_backgrounds__35lmd { 
  padding: 10px 0px;
}
.WhitepaperBackgrounds_background-images__3OfA_ { 
  height: 250px;
}
.WhitepaperBackgrounds_backgrounds__35lmd p {
  font-size: 12px;
}


}
@media screen and (max-width: 900px) and (min-width: 768px){
.WhitepaperBackgrounds_background-images__3OfA_{
  margin: 0;
  height: 270px;
}
.WhitepaperBackgrounds_try-demo__P2g3_ p {
  margin: 30px 6px 0;
  padding: 0;
}
.WhitepaperBackgrounds_calendly-area__BWytk .WhitepaperBackgrounds_title__1PHEX h1 {
  margin-bottom: 10px;
}
}



@media screen  and (min-width: 1450px) {
  .WhitepaperBackgrounds_background-images__3OfA_ { 
    height: 550px;
  }
}








.User_user-section__3UIdw {
  padding: 60px 0 50px;
}
.User_user-form__3Gcmt {
}
.User_user-heading__1x77a h6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  color: #232323;
  margin-bottom: 35px;
  text-align: center;
}
.User_user-heading__1x77a h6 span {
  border-bottom: 2px solid #ff7700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.User_user-heading__1x77a h6 img {
  width: 60px;
  margin-right: 10px;
}
.User_user-form__3Gcmt form {
}
.User_user-form__3Gcmt form label {
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #ff7700;
  display: block;
  width: 100%;
}
.User_user-form__3Gcmt form input,
.User_user-form__3Gcmt form select {
  display: block;
  width: 100%;
  padding: 6px 10px;
  border: 2px solid #ff770063;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 10px;
  margin: 10px 0;
  outline: 0 !important;
  height: 45px;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  /* color: #313131; 
opacity: 0.5; */
}
.User_user-form__3Gcmt form input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #f9a826;
  opacity: 0.5;
}
.User_user-form__3Gcmt form input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #f9a826;
  opacity: 0.5;
}
.User_user-form__3Gcmt form input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #f9a826;
  opacity: 0.5;
}
.User_user-form__3Gcmt form button {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  background: #ff7700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 6px 20px;
}
.User_bg-select__2VPKK input {
  width: 60% !important;
  float: left;
  border-right: 0 !important;
  border-radius: 10px 0px 0px 10px !important;
}
.User_bg-select__2VPKK button {
  width: 40%;
  float: right;
  height: 45px;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 0px 10px 10px 0px !important;
}
.User_bg-select__2VPKK {
}

.User_user-form__3Gcmt form input:focus-visible {
  outline: 0 0 0 !important;
  border-color: #ff7700;
  box-shadow: 0 0 0 0 !important;
}
.User_bg-select__2VPKK button span {
  float: right;
  font-size: 25px;
}
.User_user-form__3Gcmt form label span {
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  line-height: initial;
  color: #000000;
}

.User_try-drag-drop__3HWZl {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin: 0px 0 40px;
  cursor: pointer;
}
.User_try-drag-drop__3HWZl .User_dropzone1__3IkUZ {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
  padding: 10px 10px 20px;
}
.User_try-drag-drop__3HWZl .User_dropzone1__3IkUZ:hover {
  opacity: 1;
}
.User_try-drag-drop__3HWZl .User_dropzone1__3IkUZ img {
  margin-bottom: 0px;
  width: 15%;
}
.User_try-drag-drop__3HWZl .User_dropzone1__3IkUZ p {
  margin: 10px 0 0 !important;
}
.User_para-11__15Bbb {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 1.7px;
  margin: 0 0 !important;
}

.User_para-2__3HR-6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 10px 0 0px !important;
}
.User_para-2__3HR-6 span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 10px;
  font-size: 14px;
}
.User_upload-dealership__3FvUd h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #ff7700;
  text-align: right;
  margin-top: 0%;
  padding-right: 10%;
  cursor: pointer;
}
.User_user-heading__1x77a h3 {
  text-align: center;
  margin: 0% 0 5%;
}
.User_user-heading__1x77a h3 img {
  width: 100px;
  height: 90px;
  border: 0.7px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
  object-fit: contain;
  margin-right: 20px;
}
.User_user-heading__1x77a h3 button {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 5px 15px;
}
.User_user-heading__1x77a h3 span {
  position: relative;
}
.User_user-heading__1x77a h3 span svg {
  position: absolute;
  box-shadow: 0px 2px 5px 0px #ddd;
  padding: 7px;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  right: 10px;
  margin-top: -12px;
  background: #fff;
  z-index: 1;
  cursor: pointer;
}
.User_user-heading__1x77a h4 {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  color: #000000;
  margin-bottom: 20px;
}
.User_logo-positioning__20Tgp {
  position: relative;
}
.User_logo-positioning__20Tgp img {
  width: 68%;
  margin: 0 auto;
}
.User_logo-area__3Fwv9 {
  position: absolute;
  top: 0;
  bottom: 0;
}
.User_logo-area__3Fwv9 .User_left__IimZ6 {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  left: 12%;
  z-index: 2;
  margin-top: 2%;
  cursor: pointer;
}

.User_logo-area__3Fwv9 .User_right__1dgTU {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  right: 12%;
  z-index: 2;
  margin-top: 2%;
  cursor: pointer;
}
.User_logo-area__3Fwv9 .User_right-bottom__15Siw {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  right: 5%;
  z-index: 2;
  margin-bottom: 2%;
  bottom: 0;
}
.User_logo-area__3Fwv9 .User_left-bottom__1PfIF {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid #ff881f;
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 10px;
  width: 90px;
  height: 90px;
  position: absolute;
  left: 5%;
  z-index: 2;
  margin-bottom: 2%;
  bottom: 0;
}
.User_logo-area__3Fwv9 img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 5px;
}

.User_upload-logo-btn__ebofE {
  border-radius: 5px !important;
  margin-top: 30px;
}

.AddEnterprise_user-section__zLsvR{
    padding: 60px 0 50px;
}
.AddEnterprise_user-form__8Y1YQ{

}
.AddEnterprise_user-heading__3KrIw h6{
    font-weight: 500;
    font-size: 22px;
    line-height: 39px; 
    color: #232323;
    margin-bottom: 35px;
    text-align: center;
}
.AddEnterprise_user-heading__3KrIw h6 span{
    border-bottom: 2px solid #FF7700; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.AddEnterprise_user-heading__3KrIw h6 img{
    width: 60px;
    margin-right: 10px;
}
.AddEnterprise_user-form__8Y1YQ form{

}
.AddEnterprise_user-form__8Y1YQ form label{
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #FF7700;
    display: block;
    width: 100%;
}
.AddEnterprise_user-form__8Y1YQ form input, .AddEnterprise_user-form__8Y1YQ form select{
    display: block;
    width: 100%;
    padding: 6px 10px;
    border: 2px solid #ff770063;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin: 10px 0;
    outline: 0!important;
    height: 45px; 
    font-weight: 400;
font-size: 14px;
line-height: 30px; 
/* color: #313131; 
opacity: 0.5; */
}
.AddEnterprise_user-form__8Y1YQ form input::-webkit-input-placeholder{
font-weight: 400;
font-size: 14px;
line-height: 30px; 
color: #F9A826; 
opacity: 0.5;
}
.AddEnterprise_user-form__8Y1YQ form input:-ms-input-placeholder{
font-weight: 400;
font-size: 14px;
line-height: 30px; 
color: #F9A826; 
opacity: 0.5;
}
.AddEnterprise_user-form__8Y1YQ form input::placeholder{
font-weight: 400;
font-size: 14px;
line-height: 30px; 
color: #F9A826; 
opacity: 0.5;
}
.AddEnterprise_user-form__8Y1YQ form button{
    font-weight: 500;
font-size: 16px;
line-height: 30px; 
color: #FFFFFF;
background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
border-radius: 12px;
padding: 6px 20px ;
}
.AddEnterprise_bg-select__Ej6yp input{
    width: 60%!important;
    float: left;
    border-right: 0!important;
    border-radius: 10px 0px 0px 10px!important;
}
.AddEnterprise_bg-select__Ej6yp button{
    width: 40%;
    float: right;
    height: 45px;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 0px 10px 10px 0px!important
}
.AddEnterprise_bg-select__Ej6yp{

}

.AddEnterprise_user-form__8Y1YQ form input:focus-visible {
outline: 0 0 0!important;
border-color: #FF7700;
box-shadow: 0 0 0 0 !important;
}
.AddEnterprise_bg-select__Ej6yp button span{
    float: right;
    font-size: 25px;
}
.AddEnterprise_color-picker__39ZKe{

}
.AddEnterprise_color-picker__39ZKe input{
    margin: 0!important;
    width: 10%!important;
    float: right;
    border-right: 0!important;
    line-height: normal!important;
    line-height: initial!important;
    border: 0!important;
    height: 40px!important;
}
.AddEnterprise_color-picker__39ZKe div{
    display: block;
    width: 100%;
    padding: 0px 0px 0 10px;
    border: 2px solid #ff770063;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin: 10px 0;
    outline: 0!important;
    height: 45px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    
}
.AddEnterprise_color-picker__39ZKe div span{
    font-weight: 400;
font-size: 14px;
line-height: 40px; 
color: #F9A826; 
opacity: 0.5;
}


:root {
  --primaryColor: #ff7700;
}

body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.CarsBackgroundReplacement_drop-box-text__3MfVk {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1.8%;
}

.CarsBackgroundReplacement_drop-box-text__3MfVk div {
  text-align: center;
  top: 26%;
  position: relative;
  color: #fff;
  display: flex;
  width: 100%;
  padding: 7px;
}

.CarsBackgroundReplacement_car-main-wrapper__16Es9 {
  background-size: cover !important;
  padding-bottom: 60px;
}
.CarsBackgroundReplacement_header-section__2nnQm {
  text-align: center;
  padding-top: 100px !important;
}
.CarsBackgroundReplacement_heading-text__1h_O0 {
  font-weight: 600;
  font-size: 34px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px;
  padding: 0px;
}
.CarsBackgroundReplacement_heading-para__2cNMD {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 15px 0;
  padding: 0px;
}
.CarsBackgroundReplacement_heading-para__2cNMD strong {
  font-weight: bold;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ff7700;
  vertical-align: middle;
  margin-right: 15px;
}
.CarsBackgroundReplacement_heading-para__2cNMD span {
  color: #ff7700 !important;
}
.CarsBackgroundReplacement_active-walls-sec__2juKn img {
  height: 35px;
}
.CarsBackgroundReplacement_inactive-walls-sec__3TYPt img {
  height: 35px;
}
.CarsBackgroundReplacement_inactive-walls-sec__3TYPt {
  padding: 5px;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.CarsBackgroundReplacement_backgrounds-grid-custom__1JZ0d {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 7px;
}
.CarsBackgroundReplacement_inactive-walls-sec__3TYPt span {
  font-size: 13px;
}
.CarsBackgroundReplacement_active-walls-sec__2juKn {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.CarsBackgroundReplacement_active-walls-sec__2juKn span {
  font-size: 13px;
}
.CarsBackgroundReplacement_active-walls-sec__2juKn img {
  width: 100%;
  height: 90px;
}
.CarsBackgroundReplacement_inactive-walls-sec__3TYPt img {
  width: 100%;
  height: 90px;
}
.CarsBackgroundReplacement_inactive-walls-sec__3TYPt {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.CarsBackgroundReplacement_wrapper__Gj0pl {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.CarsBackgroundReplacement_left-section__Q80oe {
  width: 40%;
  height: -webkit-max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.CarsBackgroundReplacement_mid-section__3ezqA {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.CarsBackgroundReplacement_sample-button__1xvWA {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.CarsBackgroundReplacement_sample-button__1xvWA:hover {
  color: black;
}
.CarsBackgroundReplacement_dropzone__xh5EV {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.CarsBackgroundReplacement_dropzone__xh5EV:hover {
  opacity: 1;
}
.CarsBackgroundReplacement_dropzone__xh5EV:focus {
  outline: none !important;
  border: none !important;
}
.CarsBackgroundReplacement_para-1__16r0t {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.CarsBackgroundReplacement_para-2__36vHj {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 0px 0 10px;
}
.CarsBackgroundReplacement_para-2__36vHj span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
}
.CarsBackgroundReplacement_supports__q-tIL {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.CarsBackgroundReplacement_choose-button__fmhOx {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.CarsBackgroundReplacement_choose-button__fmhOx button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.CarsBackgroundReplacement_choose-button__fmhOx button:hover {
  color: #ffffff;
}
.CarsBackgroundReplacement_choose-button__fmhOx button:focus {
  outline: none !important;
}
.CarsBackgroundReplacement_right-section__nK65z {
  width: 55%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 25px;
  margin-left: 5%;
  height: auto;
}
/* .right-section div{
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
} */
.CarsBackgroundReplacement_right-section__nK65z img {
  /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  width: 100%;
}
.CarsBackgroundReplacement_section-two__2rvPa {
  padding: 57px;
  background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Desktop+-+2+1.png');
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-repeat: repeat-x;
  background-color: white;
}
.CarsBackgroundReplacement_section-two-one__3zDHm {
  margin-top: 125px;
}
.CarsBackgroundReplacement_section-two-one__3zDHm h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 132%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  margin-top: -40px;
}
.CarsBackgroundReplacement_section-two-one__3zDHm p {
  max-width: 519px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}
.CarsBackgroundReplacement_section-two-one__3zDHm span {
  color: #ff7700;
}
.CarsBackgroundReplacement_section-two-one-right__sLZay {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5886+1.png');
  padding: 70px 50px;
  margin-top: -125px;
  align-items: center;
}
.CarsBackgroundReplacement_section-two-one-right__sLZay div {
  max-width: 571px;
}
.CarsBackgroundReplacement_section-two-three-right__1kX4h {
  margin-top: -125px;
  align-items: center;
}
.CarsBackgroundReplacement_section-3__3cTs8 {
  padding: 57px;
}
.CarsBackgroundReplacement_slider__2zCNo {
  height: 100%;
  width: 3px;
  background: white;
}
.CarsBackgroundReplacement_slider__2zCNo img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  margin-left: -25px;
}
.CarsBackgroundReplacement_section-3__3cTs8 {
  background: #ffffff;
}
.CarsBackgroundReplacement_section-3-right__25FvT div {
  vertical-align: center;
  /* position: absolute;
    top: 50%;
    transform: translate(-50%,-50%); */
  margin-left: 135px;
  margin-top: 20%;
  text-align: center;
}
.CarsBackgroundReplacement_section-3-right__25FvT p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.CarsBackgroundReplacement_section-3-right__25FvT button {
  border: unset;
  width: 220px;
  height: 60px;
  background: #ff7700;
  border-radius: 30.5px;
}
.CarsBackgroundReplacement_section-3-right__25FvT a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
}

.CarsBackgroundReplacement_mob-area__8EgqE {
  padding-top: 0%;
}

.CarsBackgroundReplacement_mob-area__8EgqE .CarsBackgroundReplacement_why-use__2n86y {
  text-align: left;
  padding: 10% 5% 0;
}

.CarsBackgroundReplacement_why-use__2n86y h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}

.CarsBackgroundReplacement_mob-area__8EgqE .CarsBackgroundReplacement_why-use__2n86y p {
  margin-bottom: 30px;
}

.CarsBackgroundReplacement_request-demo__25WqO {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.CarsBackgroundReplacement_mob-area__8EgqE .CarsBackgroundReplacement_why-use__2n86y .CarsBackgroundReplacement_request-demo2__R7Nri {
  display: block;
}

.CarsBackgroundReplacement_mob-area__8EgqE .CarsBackgroundReplacement_why-use__2n86y p img {
  margin-top: 30px;
}

.CarsBackgroundReplacement_request-demo2__R7Nri img {
  height: 42px;
  /* margin-left: 20px; */
}

.CarsBackgroundReplacement_custom-backgrounds__2zTFA {
  padding: 60px 5% 40px;
}

.CarsBackgroundReplacement_title__219Iv {
  text-align: center;
}

.CarsBackgroundReplacement_title__219Iv h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.CarsBackgroundReplacement_exterior-tabs__p5d-_ ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}

.CarsBackgroundReplacement_car-try-wrapper__3tOTZ {
}
.CarsBackgroundReplacement_try-images-area__2y-28 {
  padding: 5px 15px;
}
.CarsBackgroundReplacement_car-left-section__39va9 {
  margin-top: 25px;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  cursor: pointer;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS .CarsBackgroundReplacement_dropzone1__wNOFO {
  border: 1px dashed #ff7700;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS .CarsBackgroundReplacement_dropzone1__wNOFO:hover {
  opacity: 1;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS .CarsBackgroundReplacement_dropzone1__wNOFO img {
  width: 25%;
  margin: 0 auto;
}
.CarsBackgroundReplacement_para-11__1uy6d {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
}
.CarsBackgroundReplacement_supports1__2FKgc {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
.CarsBackgroundReplacement_perfect-output__y6qh5 {
}
.CarsBackgroundReplacement_choose-output__3sABu {
}
.CarsBackgroundReplacement_heading__2UIqs h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.CarsBackgroundReplacement_heading__2UIqs h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.CarsBackgroundReplacement_heading__2UIqs h3 .CarsBackgroundReplacement_border-1__3DQ34 {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.CarsBackgroundReplacement_perfect-output-images__1gzaN {
  text-align: center;
  padding: 20px 15px 0;
}
.CarsBackgroundReplacement_perfect-output-images__1gzaN img {
  width: 100%;
}
.CarsBackgroundReplacement_perfect-output-images__1gzaN p {
  text-align: center;
}
.CarsBackgroundReplacement_perfect-output-images__1gzaN p span a {
  font-style: normal;
  color: #ff7700 !important;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  cursor: pointer;

  box-shadow: 0px 4px 6px 0px #ccc;
}

.CarsBackgroundReplacement_perfect-output-images__1gzaN p a {
  background: #ff7700;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 10px;
  box-shadow: 0px 4px 6px 0px #ccc;
}
.CarsBackgroundReplacement_bg-try__2C7rW {
  background: url('https://storage.googleapis.com/spyne-website/whitelabel/darcars/Vector%20(2).png');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: right;
}

.CarsBackgroundReplacement_choose-button__fmhOx {
}
.CarsBackgroundReplacement_choose-button__fmhOx button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: -webkit-max-content;
  width: max-content;
}
.CarsBackgroundReplacement_bg-recommended__2GqfF {
  /* border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 5px 5px;
  
  margin-bottom: 20px; */
}
.CarsBackgroundReplacement_bg-recommended__2GqfF h6 {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
  font-weight: 600;
}
.CarsBackgroundReplacement_bg-recommended__2GqfF h6 span {
  color: #ff7700;
}
.CarsBackgroundReplacement_bg-recomended-images__gHrAT {
  list-style: none;
  display: block;
  padding: 0;
  width: 100%;
  /* border-bottom: 1px solid #ddd; */
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 400px;
}

.CarsBackgroundReplacement_bg-recomended-images__gHrAT li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 10px;
  cursor: pointer;
  width: 50%;
  position: relative;
}
.CarsBackgroundReplacement_bg-recomended-images__gHrAT li img {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 0.4px solid #333;
}
.CarsBackgroundReplacement_wall-cross__1GXpD {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.CarsBackgroundReplacement_active-background-sec__2gFST img {
  /* border: 2px solid #000;
  transform: scale(1.1); */
  border: 0;
}
.CarsBackgroundReplacement_active-background-sec__2gFST {
  border: 2px solid #000;
  padding: 3px !important;
}
.CarsBackgroundReplacement_bg-recomended-images__gHrAT li span {
  font-size: 11px;
  font-weight: 500;
}
.CarsBackgroundReplacement_perfect-output-1__20fd4 {
  padding: 40px 0px;
  background: #fff;
}
.CarsBackgroundReplacement_perfect-output-1__20fd4 .CarsBackgroundReplacement_heading__2UIqs {
  margin-bottom: 20px;
}
.CarsBackgroundReplacement_bg-recomended-area__rZJsP {
  /* overflow-y: scroll;
  height: 400px; */
}

.CarsBackgroundReplacement_bg-recomended-area-height__1bt-K {
  /* overflow-y: scroll;
  height: 400px; */
}

.CarsBackgroundReplacement_bg-recomended-area__rZJsP h3 {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.5px;
  padding: 0 10px;
}
.CarsBackgroundReplacement_bg-recomended-area__rZJsP h3 span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.CarsBackgroundReplacement_bg-recomended-right__27b7E img {
  width: 100%;
}

.CarsBackgroundReplacement_bg-recommended__2GqfF p {
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
}

.CarsBackgroundReplacement_bg-recomended-right__27b7E {
}
.CarsBackgroundReplacement_bg-recomended-right__27b7E img {
}

.CarsBackgroundReplacement_bg-recomended-area__rZJsP .CarsBackgroundReplacement_perfect-output-images__1gzaN p {
  margin-top: 2%;
}
.CarsBackgroundReplacement_bg-recomended-area__rZJsP .CarsBackgroundReplacement_perfect-output-images__1gzaN p a {
  padding: 10px;
}

.CarsBackgroundReplacement_download-popup__3upMB {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.CarsBackgroundReplacement_download-popup__3upMB button {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}
.CarsBackgroundReplacement_download-popup__3upMB ul {
  padding-left: 15%;
  margin: 0 0 5px;
}
.CarsBackgroundReplacement_download-popup__3upMB ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsBackgroundReplacement_download-popup__3upMB p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 10%;
}
.CarsBackgroundReplacement_download-popup__3upMB p span {
  color: #ff7700;
}
.CarsBackgroundReplacement_download-popup__3upMB h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsBackgroundReplacement_download-popup__3upMB input {
  margin-right: 7%;
  border: 0;
  outline: 0;
}
.CarsBackgroundReplacement_download-popup__3upMB input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.CarsBackgroundReplacement_download-popup__3upMB button {
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: left;
}
.CarsBackgroundReplacement_download-popup__3upMB p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.CarsBackgroundReplacement_download-popup__3upMB button.CarsBackgroundReplacement_activate__1HWUt,
.CarsBackgroundReplacement_download-popup__3upMB button:hover {
  background: rgba(5, 121, 223, 0.05) !important;
  border: 1px solid #78838d !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

/* .app-section {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
} */

/* //Favroute background css */

.CarsBackgroundReplacement_fav-icon__1bMbQ {
  position: absolute;
  top: 1px;
  right: 11px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 0px 5px;
  padding: 3px 7px;
}
.CarsBackgroundReplacement_fav-icon__1bMbQ svg {
}
.CarsBackgroundReplacement_white-icon__3FpH0 {
}
.CarsBackgroundReplacement_hoverable-icon__B12Le {
  display: none;
}
.CarsBackgroundReplacement_active-background-sec__2gFST:hover .CarsBackgroundReplacement_white-icon__3FpH0,
.CarsBackgroundReplacement_inactive-background-sec__uDiDX:hover .CarsBackgroundReplacement_white-icon__3FpH0 {
  display: none;
}
.CarsBackgroundReplacement_active-background-sec__2gFST:hover .CarsBackgroundReplacement_hoverable-icon__B12Le,
.CarsBackgroundReplacement_inactive-background-sec__uDiDX:hover .CarsBackgroundReplacement_hoverable-icon__B12Le {
  display: inline-block;
}
.CarsBackgroundReplacement_active-background-sec__2gFST .CarsBackgroundReplacement_fav-icon__1bMbQ {
  top: 3px;
  right: 3px;
}
.CarsBackgroundReplacement_more-btn-overlay__34ynO span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ff7700;
  display: block;
  text-align: center;
}

.CarsBackgroundReplacement_bg-recomended-right__27b7E h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 30px 0;
}
.CarsBackgroundReplacement_colored__5LXVk {
  color: #ff7700;
}
.CarsBackgroundReplacement_border-bt__gKch3 {
  border-bottom: 3px solid #ff7700;
}
.CarsBackgroundReplacement_fav-icon-2__1QAOk span {
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 5px;
  border-radius: 100px;
  margin-right: 5px;
}
.CarsBackgroundReplacement_fav-icon-2__1QAOk {
  font-weight: 800;
  font-size: 10px;
  line-height: 30px;
  color: #000000;
}
.CarsBackgroundReplacement_bg-accordion-tab__HlYad {
  /* border: 1px solid #e0e0e0; */
  box-sizing: border-box;
  padding: 10px 5px 5px;
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}

.CarsBackgroundReplacement_try-images-area__2y-28 {
  position: relative;
}
.CarsBackgroundReplacement_try-images-area__2y-28 p {
  background: rgba(20, 19, 19, 0.6);
  border-radius: 0px 14px 14px 0px;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 0px;
  position: absolute;
  bottom: 5px;
  margin: 0;
  width: 91%;
  display: block;
  height: 30px;
}
.CarsBackgroundReplacement_try-images-area__2y-28 img {
  width: 100%;
  padding: 0;
}
.CarsBackgroundReplacement_try-images-area__2y-28 p span {
  background: #ff7700;
  float: left;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 16px;
  line-height: normal;
  line-height: initial;
  margin-left: -13px;
}
.CarsBackgroundReplacement_try-png-image__29Myo img {
  background: url(https://storage.googleapis.com/spyne-website/whitelabel/background.png);
}
.CarsBackgroundReplacement_bg-accordion-tab__HlYad svg {
  margin-left: 10px;
}
.CarsBackgroundReplacement_color__EK84N {
  color: #ff7700 !important;
}

.CarsBackgroundReplacement_try-drag-drop1__2mMOl {
  margin: 0;
  border: 8px dashed #ffffff;
  box-sizing: border-box;
  display: block;
  position: inherit !important;
  min-height: 345px;
  padding: 25px 5px !important;
  display: block !important;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.CarsBackgroundReplacement_try-drag-drop1__2mMOl .CarsBackgroundReplacement_dropzone1__wNOFO {
  top: -2%;
  padding-left: 5%;
}

.CarsBackgroundReplacement_try-drag-drop1__2mMOl .CarsBackgroundReplacement_dropzone1__wNOFO h6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  /* display: flex; */
  letter-spacing: 1.7px;
  color: #ffffff;
  text-align: left;
}

/* ======= Responsive ======= */
@media (max-width: 800px) {
  .CarsBackgroundReplacement_car-main-wrapper__16Es9 {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .CarsBackgroundReplacement_wrapper__Gj0pl {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .CarsBackgroundReplacement_right-section__nK65z {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .CarsBackgroundReplacement_left-section__Q80oe {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .CarsBackgroundReplacement_heading-text__1h_O0 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .CarsBackgroundReplacement_heading-para__2cNMD {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .CarsBackgroundReplacement_section-two__2rvPa {
    padding: 27px;
  }
  .CarsBackgroundReplacement_section-two-one__3zDHm {
    margin-top: 70px;
  }
  .CarsBackgroundReplacement_number-image__17dh6 {
    display: none;
  }
  .CarsBackgroundReplacement_section-two-one__3zDHm h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .CarsBackgroundReplacement_section-two-one__3zDHm p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_section-two-one-right__sLZay {
    margin-top: 0px;
  }
  .CarsBackgroundReplacement_section-3-right__25FvT div {
    margin-left: 0px;
  }
  .CarsBackgroundReplacement_mob-change__JjOqS {
    display: flex;
    flex-direction: column-reverse;
  }
  .CarsBackgroundReplacement_mob-change__JjOqS h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_section-two-three-right__1kX4h img {
    margin-top: 120px;
  }
  .CarsBackgroundReplacement_section-3__3cTs8 {
    padding: 27px;
  }
  .CarsBackgroundReplacement_section-3-right__25FvT p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_section-3-right__25FvT button {
    width: 150px;
    height: 50px;
  }
  .CarsBackgroundReplacement_try-images-area__2y-28 {
    padding: 5px 0;
    order: 1;
  }
  .CarsBackgroundReplacement_car-left-section__39va9 {
    order: 3;
  }
  .CarsBackgroundReplacement_perfect-output-images__1gzaN p {
    text-align: center;
    margin: 0;
  }
  .CarsBackgroundReplacement_try-images-area__2y-28 img {
    width: 100%;
    margin: 20px 0;
  }
  .CarsBackgroundReplacement_choose-button__fmhOx {
    padding: 10% 10px;
  }
  .CarsBackgroundReplacement_bg-try__2C7rW {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .CarsBackgroundReplacement_perfect-output-images__1gzaN {
    order: 3;
    padding: 15px 0;
  }
  .CarsBackgroundReplacement_bg-recomended-area__rZJsP {
    overflow: visible;
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .CarsBackgroundReplacement_bg-recomended-right__27b7E img {
    margin: 0px 0px 30px;
  }
  .CarsBackgroundReplacement_custom-big__6sxCs {
    display: none;
  }
  .CarsBackgroundReplacement_bg-recomended-right__27b7E {
    padding: 0;
  }
  .CarsBackgroundReplacement_bg-recommended__2GqfF {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .CarsBackgroundReplacement_active-background-sec__2gFST {
    padding: 0 10px;
    border-radius: 5px;
  }
  .CarsBackgroundReplacement_custom-wall__3MDQn {
    height: 90px !important;
    width: 100%;
    margin-bottom: 16px;
  }

  .CarsBackgroundReplacement_inactive-background-sec__uDiDX {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .CarsBackgroundReplacement_active-background-sec__2gFST {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 5px;
    border: 2px solid #ff7700;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .CarsBackgroundReplacement_perfect-output-images__1gzaN p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .CarsBackgroundReplacement_heading__2UIqs h3 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .CarsBackgroundReplacement_heading-text__1h_O0 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .CarsBackgroundReplacement_heading-para__2cNMD {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .CarsBackgroundReplacement_section-two-one__3zDHm p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_mob-change__JjOqS h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .CarsBackgroundReplacement_try-images-area__2y-28 img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .CarsBackgroundReplacement_heading-text__1h_O0 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .CarsBackgroundReplacement_heading-para__2cNMD {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .CarsBackgroundReplacement_left-section__Q80oe {
    margin-top: 6%;
  }
  
}

@media (min-width: 1400px) {
  .CarsBackgroundReplacement_left-section__Q80oe {
    margin-top: 10%;
  }
}

.CarsBackgroundReplacement_product-hunt-strip__18weM {
  width: 100%;
  background-color: #cc4d29;
  position: fixed;
  top: 12%;
  z-index: 999;
}

.CarsBackgroundReplacement_product-hunt-strip__18weM h6 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding-top: 4px;
}

.CarsBackgroundReplacement_bg-try-mob__rDnIe {
  display: none;
}

@media screen and (max-width: 768px) {
  .CarsBackgroundReplacement_bg-try__2C7rW {
    display: none;
  }
  .CarsBackgroundReplacement_bg-try-mob__rDnIe {
    margin: 120px 0 100px;
    display: block !important;
  }
  .CarsBackgroundReplacement_bg-try-mob__rDnIe h4 {
    font-size: 24px;
    color: #18191f;
    font-weight: 600;
  }
  .CarsBackgroundReplacement_bg-try-mob__rDnIe h6 {
    font-size: 14px;
    color: #929292;
    margin-top: 25px;
  }
  .CarsBackgroundReplacement_bg-try-mob__rDnIe p {
    font-size: 14px;
    color: #929292;
    margin-top: 25px;
    font-weight: 600;
  }
  .CarsBackgroundReplacement_bg-try-mob__rDnIe img {
    margin-top: 20px;
  }
}

.CarsBackgroundReplacement_max-accordion-height__1TYNu {
  max-height: 380px;
  overflow-y: scroll;
  height: auto;
}

button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}

.CarsBackgroundReplacement_bg-try-mob__rDnIe span {
  color: #ff7700;
}

.CarsBackgroundReplacement_try-drag-drop__2HNoS h6 {
  text-align: left;
  padding: 10px;
  margin-bottom: 30px;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS h6 span {
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: 1.7px;
  color: #232121;
  display: inline-block;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS h6 .CarsBackgroundReplacement_cars-img__3j_Ly {
  width: 130px !important;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS h6 .CarsBackgroundReplacement_int-cars__1dtr2 {
  width: 200px !important;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS h6 img {
  float: right;
  margin: 0 !important;
}
.CarsBackgroundReplacement_try-drag-drop__2HNoS p {
  font-size: 12px;
  align-items: center;
  text-align: center;

  color: #5e5e5e;
}
.CarsBackgroundReplacement_drag-image-drop__1PHpJ {
  font-weight: 500;
  font-size: 25px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  position: static!important;
  position: initial!important;
  justify-content: center;
  letter-spacing: 1.7px;
  color: #ffffff;
}
@media screen and (max-width: 1400px) and (min-width: 1280px) {
  
  .CarsBackgroundReplacement_drop-box-text__3MfVk div { 
    top: 22%;
  }
  .CarsBackgroundReplacement_try-drag-drop1__2mMOl {
    min-height: 350px;
  }
  .CarsBackgroundReplacement_drop-box-text__3MfVk {
    padding:  0 1.5%;
}
.CarsBackgroundReplacement_try-drag-drop1__2mMOl .CarsBackgroundReplacement_dropzone1__wNOFO {
  top: -1%;
  padding-left: 6%;
  min-height: auto;
}
}

@media screen and (max-width: 1500px) and (min-width: 1401px) {
  
  .CarsBackgroundReplacement_drop-box-text__3MfVk div { 
    top: 20%;
    min-height: 355px;
  }
  .CarsBackgroundReplacement_try-drag-drop1__2mMOl {
    min-height: 325px;
  }
  .CarsBackgroundReplacement_drop-box-text__3MfVk {
    padding: 0 2%;
}
.CarsBackgroundReplacement_try-drag-drop1__2mMOl .CarsBackgroundReplacement_dropzone1__wNOFO {
  top: -3%;
  padding-left: 4%;
  min-height: auto;
}
}

@media screen and (min-width: 1501px) {
  
  .CarsBackgroundReplacement_drop-box-text__3MfVk div { 
    top: 20%;
    min-height: 375px;
  }
  .CarsBackgroundReplacement_try-drag-drop1__2mMOl {
    min-height: 325px;
  }
  .CarsBackgroundReplacement_drop-box-text__3MfVk {
    padding: 0 2%;
}
.CarsBackgroundReplacement_try-drag-drop1__2mMOl .CarsBackgroundReplacement_dropzone1__wNOFO {
  top: -3%;
  padding-left: 4%;
  min-height: auto;
}
}
@media screen and (min-width: 1900px) {
  
  .CarsBackgroundReplacement_drop-box-text__3MfVk div { 
    top: 17%;
    min-height: 400px;
  }
  .CarsBackgroundReplacement_try-drag-drop1__2mMOl .CarsBackgroundReplacement_dropzone1__wNOFO {
    top: -3%;
    padding-left: 3.5%;
    min-height: auto;
  }
  .CarsBackgroundReplacement_drag-image-drop__1PHpJ { 
    font-size: 35px;
  }
}
@media screen and (min-width: 2880px) {
  
  .CarsBackgroundReplacement_drop-box-text__3MfVk div { 
    top: 11%;
  min-height: 500px;
  }
  .CarsBackgroundReplacement_try-drag-drop1__2mMOl .CarsBackgroundReplacement_dropzone1__wNOFO {
    top: -2%;
    padding-left: 3%;
  }
}

 





.FootwearV2_bg-white__2f_4l {
  background: #fff;
}
.FootwearV2_banner-slider__1v6_j {
  margin: 65px 0;
}

.FootwearV2_car-v2-tabs__2tGAr {
  padding: 60px 0px 0px;
}
.FootwearV2_title-area__3CIvx {
  margin-bottom: 7%;
}
.FootwearV2_title-area__3CIvx h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  color: #323232;
  text-align: center;
  margin: 0;
  margin-bottom: 50px;
}
.FootwearV2_title-area__3CIvx p {
  text-align: center;
}
.FootwearV2_title__3j23_ h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #323232;
}
.FootwearV2_title__3j23_ h6 span {
  color: #ff7700;
}
.FootwearV2_colored__2M_yw {
  color: #ff7700 !important;
  border: none !important;
}

.FootwearV2_request-demo__1E37j {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 18px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}
.FootwearV2_get-app-now__337Wz {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  background: #fd8061;
}
.FootwearV2_request-demo__1E37j:hover {
  border: 2px solid #ff7700;
  background: #fff;
  color: #ff7700;
}
.FootwearV2_car-v2-left__1R2ku {
}
.FootwearV2_why-use__1xVof h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}
.FootwearV2_why-use__1xVof h1 span {
  border-bottom: 2px solid #fd8061;
  padding-bottom: 10px;
  color: #1c1e52;
}

.FootwearV2_car-v2-left__1R2ku p,
.FootwearV2_why-use__1xVof p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  color: #7c7c7c;
  padding: 0;
}
.FootwearV2_why-tabs__31-oU li button.FootwearV2_activate__18_EZ,
.FootwearV2_why-tabs__31-oU li button:hover {
  /* box-shadow: 0px 0px 20px #ff7700!important;  */
  border-left: 5px solid #ff7700;
}
.FootwearV2_car-v2-tabs__2tGAr {
  position: relative;
}

.FootwearV2_why-section__1iycP {
  padding: 0 5%;
}
.FootwearV2_why-tabs__31-oU li button {
  font-weight: 600 !important;
  font-size: 24px !important;
  border-radius: 5px !important;
  line-height: 45px !important;
  color: #1d293f !important;
  border-left: 5px solid #fff;
  background-color: #fff !important;
}
.FootwearV2_why-tabs__31-oU img {
  float: left;
  margin-right: 0;
  width: 11%;
  vertical-align: middle;
  margin-top: 1%;
}
.FootwearV2_car-v2-left__1R2ku .FootwearV2_why-tabs__31-oU span {
}
.FootwearV2_car-v2-left__1R2ku .FootwearV2_why-tabs__31-oU p {
}
.FootwearV2_first-tab-content__3eY-e {
  text-align: right;
  margin-bottom: 5% !important;
}
.FootwearV2_why-text__2FQV8 {
  width: 87%;
  float: right;
}
.FootwearV2_why-text__2FQV8 h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #1d293f;
  margin: 0;
}
.FootwearV2_why-text__2FQV8 p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #7c7c7c;
  margin: 0;
  display: flex;
}

.FootwearV2_scroll-tab__2O_Xu {
}
.FootwearV2_why-tabs__31-oU li button {
  background: #ffffff !important;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 25%) !important;
  border-radius: 10px !important;
  margin: 0 0px 10px !important;
  padding: 15px 10px 15px 5px;
  text-align: left;
}
.FootwearV2_why-tabs__31-oU {
  padding: 0;
  list-style: none;
  margin: 0;
}
.FootwearV2_request-demo2__3DfLQ img {
  height: 42px;
  margin-left: 20px;
}
.FootwearV2_right-sections__mmg6R div {
}
.FootwearV2_right-sections__mmg6R {
  position: relative;
}
.FootwearV2_why-tabs__31-oU li {
  margin: 5px 0px !important;
}
.FootwearV2_first-tab-content__3eY-e img {
  width: 70%;
  height: auto;
  margin: auto;
  margin-top: 2%;
}
.FootwearV2_buttons-div__3ZY5X {
  margin: 5% 0;
}

.FootwearV2_custom-backgrounds__pOsn0 {
  padding: 60px 0%;
}

.FootwearV2_title__3j23_ {
  text-align: center;
}
.FootwearV2_title__3j23_ h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 45px;
  margin-top: 0;
}
.FootwearV2_title__3j23_ h1 span {
  border-bottom: 2px solid #fd8061;
  color: #1c1e52;
}

.FootwearV2_custom-image__FsZY_ {
  padding-top: 4%;
  position: relative;
  text-align: center;
}
.FootwearV2_custom-image__FsZY_ img {
  width: 100%;
  position: relative;
  height: 595px;
}
.FootwearV2_custom-links__2lPU0 {
}
.FootwearV2_custom-backgrounds__pOsn0 .FootwearV2_container-fluid__3MzvL {
  width: 100% !important;
}

.FootwearV2_custom-links__2lPU0 ul {
  display: block;
  list-style: none;
  padding: 0;
  width: 65%;
  margin: 0 0 0 auto;
}
.FootwearV2_ecom-links__XrM7T {
  float: left;
}
.FootwearV2_custom-links__2lPU0 ul li {
  padding: 10px 10px 0;
  width: 100%;
  text-align: center;
}

.FootwearV2_custom-links__2lPU0 ul li button {
  background: #fff;
  padding: 3px;
  border: 2px solid #fff;
  position: relative;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
}
.FootwearV2_custom-links__2lPU0 ul li button.FootwearV2_active__3Hv5l {
  border: 2px solid #fd8061;
}
.FootwearV2_custom-links__2lPU0 ul li button img {
  width: 100%;
  height: 95px;
}
.FootwearV2_abs-img__T7V4G {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 40% !important;
  height: auto !important;
}
.FootwearV2_frame-img__1CJFE {
  position: absolute !important;
  left: 10px !important;
  right: 15px !important;
  width: 97.5% !important;
  margin: 0 auto;
}

.FootwearV2_left-arrow__26zxK {
  width: 17%;
  float: right;
  height: 60%;
  /* margin-top: 20%; */
  position: relative;
  top: 18%;
}
.FootwearV2_btm-arrow__1yqhq {
  position: relative;
  width: 70% !important;
  margin: 4% auto;
  height: auto !important;
}

.FootwearV2_gif-area__1CHa- {
  padding: 0px 0 60px;
}
.FootwearV2_no-border__2nUo_ {
  border: none !important;
}

.FootwearV2_mac-pro-image__3WVaw {
  text-align: center;
}
.FootwearV2_mac-pro-image__3WVaw img {
  margin-bottom: 50px;
  width: 100%;
}

.FootwearV2_graph-area__arM56 {
  padding: 60px 0px;
}
.FootwearV2_graph-area__arM56 .FootwearV2_title__3j23_ {
}
.FootwearV2_graph-area__arM56 .FootwearV2_title__3j23_ h1 {
  margin-bottom: 10px;
}
.FootwearV2_graph-image__11H-p {
  padding: 0 3%;
  text-align: center;
}
.FootwearV2_graph-image__11H-p img {
  width: 100%;
  margin-bottom: 7%;
}

.FootwearV2_calendly-area__1kao- {
  padding: 60px 0px 8%;
}

.FootwearV2_cars-demo__2B-xo {
  height: 450px;
  text-align: center;
}
.FootwearV2_cars-demo__2B-xo p {
  margin-top: 30px;
}
.FootwearV2_cars-demo__2B-xo iframe {
  height: 100%;
}
.FootwearV2_cars-video__1lQHR {
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  height: 450px;
  background: #fff;
}
.FootwearV2_cars-calender__i5DSa {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px;
}
.FootwearV2_cars-calender__i5DSa h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.FootwearV2_calendly-inline-widget__1Rg-4 {
  width: 100%;
}
.FootwearV2_main__1WVYz {
  padding: 50px 0px;
}
.FootwearV2_book-demo__3sTQ8 {
  margin-bottom: 30px;
}
.FootwearV2_book-demo__3sTQ8 h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  letter-spacing: 0.2px;
  color: #5d5d6d;
  margin-bottom: 15px;
  padding-left: 5%;
}
.FootwearV2_book-demo__3sTQ8 h1 span {
  color: #ff7700;
}
.FootwearV2_book-demo__3sTQ8 p span {
  color: #ff7700;
}
.FootwearV2_book-demo__3sTQ8 p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}
.FootwearV2_book-demo__3sTQ8 img {
  float: left;
}
.FootwearV2_cars-calender__i5DSa {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px 10px 20px;
  height: 100%;
  overflow: hidden;
  background: #fff;
}
.FootwearV2_cars-calender__i5DSa h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.FootwearV2_calendly-inline-widget__1Rg-4 {
  height: 100% !important;
}

.FootwearV2_ticker-headline__3_75k {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 15px 0;
  margin: 0;
  font-size: 18px;
}

.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg {
  height: 100%;
  width: auto;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk {
  width: auto;
  padding-right: 50px;
  transition: 0.6s ease-in-out top;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk {
    transition: 0.6s ease-in-out;
  }
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk.FootwearV2_next__Gzf65,
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk.FootwearV2_active__3Hv5l.FootwearV2_right__28Bf4 {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    top: 0;
  }
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk.FootwearV2_prev__IRphW,
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk.FootwearV2_active__3Hv5l.FootwearV2_left__3x5fN {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    top: 0;
  }
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk.FootwearV2_next__Gzf65.FootwearV2_left__3x5fN,
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk.FootwearV2_prev__IRphW.FootwearV2_right__28Bf4,
  .FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_item__2Kshk.FootwearV2_active__3Hv5l {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    top: 0;
  }
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_active__3Hv5l,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_next__Gzf65,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_prev__IRphW {
  display: block;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_active__3Hv5l {
  top: 0;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_next__Gzf65,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_prev__IRphW {
  position: absolute;
  top: 0;
  width: 100%;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_next__Gzf65 {
  top: 100%;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_prev__IRphW {
  top: -100%;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_next__Gzf65.FootwearV2_left__3x5fN,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_prev__IRphW.FootwearV2_right__28Bf4 {
  top: 0;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_active__3Hv5l.FootwearV2_left__3x5fN {
  top: -100%;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-inner__15mvg > .FootwearV2_active__3Hv5l.FootwearV2_right__28Bf4 {
  top: 100%;
}

.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 {
  left: auto;
  width: 50px;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2.FootwearV2_up__1f99Z {
  top: 0;
  right: 0;
  bottom: 50%;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2.FootwearV2_down__27uBQ {
  top: 50%;
  right: 0;
  bottom: 0;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-prev__2j0wn,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-next__h0MS-,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_glyphicon-chevron-up__28ki5,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_glyphicon-chevron-down__3qF3j {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-prev__2j0wn,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_glyphicon-chevron-up__28ki5 {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-next__h0MS-,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_glyphicon-chevron-down__3qF3j {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-up__27U9O,
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-down__1voZr {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-prev__2j0wn:before {
  content: '‹';
}
.FootwearV2_carousel__YlW-y.FootwearV2_vertical__353kr .FootwearV2_carousel-control__29Cy2 .FootwearV2_icon-next__h0MS-:before {
  content: '›';
}

.FootwearV2_slide-images__O3pqx .FootwearV2_item__2Kshk img {
  width: 100%;
  height: 100vh;
}

.FootwearV2_calendly-area__1kao- .FootwearV2_title__3j23_ {
  margin-bottom: 25px;
}
.FootwearV2_calendly-area__1kao- .FootwearV2_title__3j23_ h1 {
  margin-bottom: 0;
}
.FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof {
  text-align: left;
  padding: 10% 5% 0;
}
.FootwearV2_calendly-area__1kao- {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Footwear/Group%205892411.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.FootwearV2_mob-area__3sI6X {
  padding-top: 12%;
}
.FootwearV2_custom-backgrounds__pOsn0 .FootwearV2_title__3j23_ h1 {
  margin-bottom: 10px;
}
.FootwearV2_custom-backgrounds__pOsn0 .FootwearV2_title__3j23_ p {
  margin-bottom: 30px;
}
.FootwearV2_calendly-area__1kao- .FootwearV2_request-demo2__3DfLQ img {
  margin: 10px 0;
}

.FootwearV2_mob-image__16c-I {
}
.FootwearV2_mob-image__16c-I img {
  width: 100%;
}
.FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof p {
  margin-bottom: 30px;
}
.FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof p img {
  margin-top: 30px;
  width: 140px;
}
.FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof .FootwearV2_request-demo2__3DfLQ {
  display: block;
}

.FootwearV2_exterior-tabs__1SBN8 ul li.FootwearV2_selected__8Zpdv {
  background: #fff;
  color: black;
  border-radius: 0;
  border: 0;
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.FootwearV2_exterior-tabs__1SBN8 ul li button {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background: transparent;
  color: #444444;
  padding: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 0 !important;
}
.FootwearV2_exterior-tabs__1SBN8 ul li button.FootwearV2_activ__1IvvD,
.FootwearV2_exterior-tabs__1SBN8 ul li button:hover,
.FootwearV2_exterior-tabs__1SBN8 ul li button.FootwearV2_virtual__YOY7S {
  color: #ff7700 !important;
  border-bottom: 1px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.FootwearV2_exterior-tabs__1SBN8 ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 0;
}
.FootwearV2_exterior-tabs__1SBN8 ul li {
  padding: 3px;
  margin: 15px 20%;
  background: transparent;
  list-style: none;
  display: inline-block;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  box-shadow: 0px 2px 3px 0px #ddd;
}
.FootwearV2_slates-tabs__2k8cQ li {
  padding: 5px 10px !important;
  margin: 10px !important;
}
.FootwearV2_custom-links__2lPU0 ul li {
  padding: 0px 5px 0;
  margin-bottom: 5px;
}

.FootwearV2_custom-backgrounds__pOsn0 {
  padding: 75px 0px;
}
/* Virtual section  */
.FootwearV2_virtual-images__3zGPE {
}
.FootwearV2_virtual-images__3zGPE img {
  width: 100%;
}

.FootwearV2_slates-tabs__2k8cQ li.FootwearV2_selected__8Zpdv {
  border-top: 0px !important;
}

.FootwearV2_v-image__K6ne3 {
  padding: 0 15px !important;
}
.FootwearV2_tab-middle__2Ra2J {
  padding-left: 0;
}

/* Responsive Media Query */

@media screen and (max-width: 800px) and (min-width: 200px) {
  .FootwearV2_title-area__3CIvx h1 {
    display: none;
  }
  .FootwearV2_mac-pro-image__3WVaw img {
    width: 100%;
    margin-bottom: 20px;
  }

  .FootwearV2_request-demo2__3DfLQ img {
    height: 40px;
  }
  .FootwearV2_abs-img__T7V4G {
    top: 33%;
    width: 30% !important;
    left: 35%;
  }
  .FootwearV2_graph-image__11H-p img {
    width: 100%;
  }
  .FootwearV2_graph-area__arM56 .FootwearV2_title__3j23_ {
    margin-bottom: 0px;
    text-align: center;
    padding-top: 0%;
  }
  .FootwearV2_title__3j23_ h1 {
    font-size: 18px;
    line-height: 30px;

    margin-bottom: 20px;
  }
  .FootwearV2_request-demo__1E37j {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    line-height: normal;
    line-height: initial;
    height: 40px;
    margin: 0 auto;
  }
  .FootwearV2_graph-area__arM56 {
    padding: 0 0;
  }

  .FootwearV2_car-v2-left__1R2ku .FootwearV2_why-tabs__31-oU span {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_car-v2-left__1R2ku .FootwearV2_why-tabs__31-oU p {
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_why-tabs__31-oU li button {
    padding: 10px;
  }
  .FootwearV2_why-tabs__31-oU img {
    width: 15%;
    margin-top: 10px;
  }
  .FootwearV2_why-tabs__31-oU li + li button img {
    margin-top: 7%;
  }
  .FootwearV2_car-v2-left__1R2ku p {
    text-align: center;
  }
  .FootwearV2_car-v2-tabs__2tGAr {
    padding: 0;
    margin-bottom: 20px;
  }
  .FootwearV2_title-area__3CIvx {
    margin: 0;
  }
  .FootwearV2_car-v2-left__1R2ku {
    order: 2;
    padding: 0;
  }
  .FootwearV2_why-use__1xVof h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .FootwearV2_right-sections__mmg6R div {
    margin: 0;
    text-align: center;
  }
  .FootwearV2_car-v2-left__1R2ku p,
  .FootwearV2_why-use__1xVof p {
    font-size: 12px;
    line-height: inherit;
    margin-bottom: 15px;
    text-align: center;
  }

  .FootwearV2_first-tab-content__3eY-e img {
    width: 50%;
    margin: 3% auto 5%;
  }
  .FootwearV2_gif-area__1CHa- {
    padding: 0px 0 40px;
  }
  .FootwearV2_banner-slider__1v6_j {
    margin-bottom: 20px;
    margin-top: 55px;
  }
  .FootwearV2_why-text__2FQV8 {
    width: 80%;
    float: right;
  }
  .FootwearV2_why-text__2FQV8 h6 {
    font-size: 18px;
  }
  .FootwearV2_graph-image__11H-p {
    padding: 0px 20px;
  }
  .FootwearV2_mac-pro-image__3WVaw {
    padding: 0 25px;
  }

  .FootwearV2_cars-demo__2B-xo {
    height: auto;
    text-align: center;
    margin-top: 30px;
  }
  .FootwearV2_title__3j23_ h6 {
    font-size: 14px;
    margin: 0;
  }
  .FootwearV2_cars-video__1lQHR {
    height: auto;
  }

  .FootwearV2_calendly-area__1kao- {
    padding: 60px 0px 15%;
    margin-bottom: 0px;
  }
  .FootwearV2_scroll-tab__2O_Xu .FootwearV2_why-use__1xVof {
    padding: 0;
    margin: 0;
  }

  .FootwearV2_graph-image__11H-p img {
    padding: 0;
  }
  .FootwearV2_mob-area__3sI6X {
    padding-top: 5%;
  }
  .FootwearV2_mob-image__16c-I {
    order: 2;
  }
  .FootwearV2_calendly-area__1kao- .FootwearV2_request-demo2__3DfLQ img {
    margin: 0;
  }
  .FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof p img {
    margin-top: 0;
    margin-left: 15px;
  }
  .FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof .FootwearV2_request-demo2__3DfLQ {
    display: inline-block;
  }
  .FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof {
    text-align: left;
    padding: 10% 2%;
  }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .FootwearV2_custom-request__q4HD4 {
    order: 4;
    margin-bottom: 20px;
  }
  .FootwearV2_custom-links__2lPU0 {
    order: 2;
  }
  .FootwearV2_custom-image__FsZY_ img {
    height: 200px;
  }
  .FootwearV2_custom-image__FsZY_ {
    padding: 0;
    height: -webkit-max-content;
    height: max-content;
  }
  .FootwearV2_custom-links__2lPU0 ul {
    display: flex;
    width: 100%;
  }
  .FootwearV2_custom-links__2lPU0 ul li button img {
    width: 100%;
    height: auto;
  }
  .FootwearV2_custom-links__2lPU0 ul li button {
  }

  .FootwearV2_custom-links__2lPU0 ul li {
    padding: 10px 5px 0;
  }
  .FootwearV2_custom-backgrounds__pOsn0 {
    padding: 40px 0px 20px;
  }
  .FootwearV2_left-arrow__26zxK {
    width: auto;
    float: unset;
    height: 70%;
    position: absolute;
    top: 15%;
  }
  .FootwearV2_frame-img__1CJFE {
    left: 0px !important;
    right: auto;
    width: 100% !important;
    margin: 0 auto;
  }
  .FootwearV2_arrows__3xYyy {
    text-align: center;
  }
  .FootwearV2_btm-arrow__1yqhq {
    margin: 4% auto 0;
  }
  .FootwearV2_exterior-tabs__1SBN8 ul {
    display: flex;
    padding: 5px 10% !important;
  }
  .FootwearV2_exterior-tabs__1SBN8 ul li {
    margin: 5px 6px 0 !important;
    font-size: 12px;
    padding: 2px !important;
  }
  .FootwearV2_tab-middle__2Ra2J {
    padding: 0 15px;
  }
  .FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof h1 {
    text-align: center;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .FootwearV2_graph-area__arM56 {
    padding: 0 0 60px !important;
  }
  .FootwearV2_first-tab-content__3eY-e img {
    width: 100% !important;
    margin: 0 auto;
    margin-top: 30% !important;
  }
  .FootwearV2_car-v2-left__1R2ku {
    order: 1 !important;
  }
  .FootwearV2_right-sections__mmg6R {
    order: 2;
  }
  .FootwearV2_graph-area__arM56 .FootwearV2_title__3j23_ {
    order: 1 !important;
    padding-top: 8%;
    margin: 0;
  }
  .FootwearV2_graph-image__11H-p {
    order: 2;
  }
  .FootwearV2_first-tab-content__3eY-e img {
    width: 85%;
    margin-top: 10%;
  }
  .FootwearV2_cars-video__1lQHR {
    height: 350px !important;
  }
  .FootwearV2_cars-demo__2B-xo {
    height: 350px !important;
    margin-top: 0 !important;
  }
  .FootwearV2_calendly-area__1kao- {
    padding: 60px 0px 12% !important;
    margin-bottom: 0px;
  }
  .FootwearV2_custom-links__2lPU0 {
    order: initial !important;
  }
  .FootwearV2_custom-links__2lPU0 ul li button img {
    width: 100%;
    height: auto;
  }
  .FootwearV2_custom-links__2lPU0 ul {
    width: 80%;
  }
  .FootwearV2_custom-image__FsZY_ img {
    height: 270px;
  }
  .FootwearV2_left-arrow__26zxK {
    width: 15%;
    height: 50%;
    top: 15%;
  }
  .FootwearV2_frame-img__1CJFE {
    width: 95% !important;
    margin: 0 auto;
  }
  .FootwearV2_mob-area__3sI6X .FootwearV2_why-use__1xVof {
    padding: 10% 0% 0% !important;
  }
  .FootwearV2_mob-image__16c-I img {
    margin-top: 10%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 901px) {
  .FootwearV2_why-text__2FQV8 p {
    font-size: 12px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_why-text__2FQV8 h6 {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .FootwearV2_first-tab-content__3eY-e img {
    width: 75%;
  }
  .FootwearV2_custom-links__2lPU0 ul li {
    padding: 30px 20px 0;
  }
  .FootwearV2_graph-area__arM56 {
    padding: 0 0 50px;
  }
  .FootwearV2_custom-links__2lPU0 ul li button img {
    width: 100%;
    height: 45px;
  }
  .FootwearV2_custom-image__FsZY_ img {
    height: 420px;
  }
  .FootwearV2_custom-links__2lPU0 ul {
    width: 75%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .FootwearV2_custom-image__FsZY_ img {
    width: 100%;
    position: relative;
    height: 505px;
  }
  .FootwearV2_custom-links__2lPU0 ul li button img {
    width: 100%;
    height: 80px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 902px) {
}

:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.CarsScreenTwo_drop-box-text__2URS4 {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.CarsScreenTwo_drop-box-text__2URS4 div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.CarsScreenTwo_screen-two-main-wrapper__2ga_q {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  margin: 100px 50px;
}
.CarsScreenTwo_blured__g_cFR {
  -webkit-filter: blur(4px);
}
.CarsScreenTwo_left-section-with-image__16-3Q {
  width: 780.57px;
  height: 520px;
  /* background: #ffffff; */
  /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
  border-radius: 10px;
  padding: 7.87px 21.87px 10px;
}

.CarsScreenTwo_left-section-with-image-2__22fhQ {
  width: 100%;

  /* background: #ffffff; */
  /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
  border-radius: 10px;
  padding: 7.87px 21.87px 10px;
}
.CarsScreenTwo_blured__g_cFR {
  -webkit-filter: blur(4px);
}
.CarsScreenTwo_angle__1LoGD {
}
.CarsScreenTwo_right-top-credits__29AVq {
  text-align: right;
  padding: 0;
  margin-bottom: 10px;
}
.CarsScreenTwo_right-top-credits__29AVq button {
  padding: 5px 10px;
  width: auto;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.94) 0%,
      rgba(255, 255, 255, 0.24) 52.08%,
      rgba(255, 255, 255, 0) 52.09%,
      rgba(255, 255, 255, 0.29) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 51.56%
    ),
    #ff7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12),
    inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.CarsScreenTwo_right-top-credits__29AVq img {
  width: 25px !important;
}
.CarsScreenTwo_add-credit__3DygV {
  color: white !important;
}
.CarsScreenTwo_add-credit__3DygV img {
}
.CarsScreenTwo_on-off-row__2dZWo {
  padding: 15px 10px 0;
}
.CarsScreenTwo_angle__1LoGD p {
  margin: 0;
  color: #ff7700;
  font-size: 17px;
}
.CarsScreenTwo_left-section-with-image__16-3Q {
  width: 780.57px;
  height: 520px;
  background: #ffffff;
  /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
  border-radius: 10px;
  padding: 21.87px 38.87px 10px;
}
.CarsScreenTwo_with-image-inner__yCMiX {
  width: 100%;
  height: 90%;
  width: 780.57px;
  height: 441px;
  border: 0.6px solid #77797b;
  cursor: pointer;
  /* object-fit:fill; */
}
.CarsScreenTwo_with-image-inner__yCMiX {
  width: 100%;
  height: 90%;
  width: 702.57px;
  height: 389px;
  border: 0.6px solid #77797b;
  cursor: pointer;
  /* object-fit:fill; */
  /* object-fit: contain; */
  position: relative;
  overflow: hidden;
}
.CarsScreenTwo_with-image-inner__yCMiX img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}
.CarsScreenTwo_resizable__2mQIp {
  width: 120px;
  /* border-radius: 0.75rem;
  padding: 20px;
  margin: 1rem;
  background-color: #29e;
  color: white;
  font-size: 20px;
  font-family: sans-serif; */
  overflow: hidden;

  touch-action: none;

  /* This makes things *much* easier */
  object-fit: fill !important;
  box-sizing: border-box;
  position: absolute;
  opacity: 0.5;
  border-bottom: 10px solid #ff7700;
  z-index: 2;
}
.CarsScreenTwo_drag-button__2x-IM {
  width: 50px !important;
  height: auto;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* overflow: hidden; */
  margin-top: -27px;
}
.CarsScreenTwo_with-image-inner-2__1ZSL6 {
  width: 100%;
  /* height: 90%; */
  /* width: 783.96px; */
  /* height: 56% ; */
  border: 0.6px solid #77797b;
  cursor: pointer;
  /* object-fit:fill; */
}

.CarsScreenTwo_btn-sec__2FwNP button {
  width: 120px;
  height: 44px;
  border-radius: 5px;
}

.CarsScreenTwo_btn-sec-2__1TrxN button {
  width: 120px;
  height: 44px;
  border-radius: 5px;
  padding: 10px 20px;
  border: 1px solid #ff7700 !important;
  text-align: center !important;
  color: #ff7700 !important;
}

.CarsScreenTwo_btn-sec__2FwNP {
  text-align: center;
  padding-top: 10px;
  /* border-top: 1px solid #ebeaea;
  margin-top: 15px; */
}

.CarsScreenTwo_btn-sec-2__1TrxN {
  text-align: center;
  padding-top: 20px;
  margin-top: 15px;
}

.CarsScreenTwo_checkbox-section__19zgh {
  text-align: center;
  /* padding-top: 20px;
  border-top: 1px solid #ebeaea;
  margin-top: 15px; */
}

.CarsScreenTwo_checkbox-section__19zgh label {
  font-size: 14px;
  color: #232121;
  font-weight: 600;
  font-family: poppins;
}

.CarsScreenTwo_with-image-inner-2__1ZSL6 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.CarsScreenTwo_original_image__1fx85 {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
  object-position: center;
}

.CarsScreenTwo_toggle-section__VaPhn {
  margin-top: 00px !important;
}
.CarsScreenTwo_right-section-with-image__-m57o {
  /* height: 520px; */
  padding: 0 0 24.6px;
  /* margin-left: 40px; */
  background: #ffffff;
  /* box-shadow: 0px 0px 21px rgb(119 121 123 / 50%); */
  border-radius: 0px;
  width: 100%;
  border-left: 1px solid #ebeaea;
}
.CarsScreenTwo_sample-button__2nPjO {
  font-size: 16px !important;
  background: none !important;
  color: black !important;
  border: 3px solid #ff7700 !important;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%) !important;
  margin-top: -20px;
  margin-bottom: 10px;
}
.CarsScreenTwo_sample-button__2nPjO:hover {
  color: black;
}
.CarsScreenTwo_choose-angle__1jY1J {
  position: absolute;
  width: 308.95px;
  height: 115.27px;
  background: #ffffff;
  border: 0.4px solid #707070;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  padding: 18.34px 29.59px 13.98px 29.59px;
  display: flex;
  grid-gap: 31.88px !important;
  border-bottom: none;
}
.CarsScreenTwo_angle-selected__1wB_d {
  position: absolute;
  width: 308.95px;
  height: 115.27px;
  background: #ffffff;
  border: 0.4px solid #707070;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  padding: 18.34px 29.59px 13.98px 29.59px;
  display: flex;
  grid-gap: 31.88px !important;
  /* border-bottom: none; */
}
.CarsScreenTwo_choose-angle__1jY1J,
.CarsScreenTwo_angle-selected__1wB_d p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #77797b;
  margin-top: 5px;
}
.CarsScreenTwo_choose-angle__1jY1J p:hover,
.CarsScreenTwo_angle-selected__1wB_d p:hover {
  color: #77797b;
}
.CarsScreenTwo_active__1U48t {
  background-color: transparent;
  border: unset;
  width: 62px;
  height: 62px;
  padding: 0px;
  opacity: 1;
  border: unset !important;
}
.CarsScreenTwo_inactive__1K7bL {
  background-color: transparent;
  border: unset;
  width: 62px;
  height: 62px;
  padding: 0px;
  opacity: 0.3;
}
button:focus {
  /* border:unset !important; */
  outline: none !important;
}
.CarsScreenTwo_choose-angle__1jY1J img,
.CarsScreenTwo_angle-selected__1wB_d img {
  width: 100%;
  height: 45px;
  object-fit: contain;
}
.CarsScreenTwo_choose-angle-text__2FrhD {
  position: absolute;
  top: 220px;
  left: 803.81px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0579df;
  z-index: 100;
  display: flex;
  background-color: white;
  padding-left: 5px;
}
.CarsScreenTwo_choose-angle-text__2FrhD p {
  background-color: white;
  margin: 0px;
}
.CarsScreenTwo_tooltip-text__11BXL {
  padding-bottom: 10px;
}
.CarsScreenTwo_tooltip-text__11BXL p {
  background-color: #0579df;
  color: white !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  text-align: center;
  margin: 5px;
  cursor: pointer;
}
.CarsScreenTwo_tooltip-show__Rn8Q7 {
  width: 268px !important;
  align-items: center !important;
  padding: 16px 24px 20px !important;
}
.CarsScreenTwo_upload-button__3csVs button {
  width: 140px;
  height: 40px;
  margin-left: 340px;
  margin-top: 30px;
  background: #0579df;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.CarsScreenTwo_upload-button__3csVs button:hover {
  color: #ffffff;
}

.CarsScreenTwo_btn__2cKlR:focus {
  outline: none !important;
  box-shadow: none !important;
}
.CarsScreenTwo_right-mid-section__17jP1 {
  margin-top: 0px !important;
}
.CarsScreenTwo_logo-text-sec__1RSD1 {
  position: absolute;
  height: 21px;
  left: 803.91px;
  top: 333.76px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0579df;
  background-color: white;
  display: flex;
  padding: 0px 5px;
}
.CarsScreenTwo_righ-mid-setion-content___hR19 {
  display: flex;
  margin: 13px auto 10px 25px;
  text-align: center;
  opacity: 0.5;
  justify-content: space-around;
}

.CarsScreenTwo_white-bg___apx- {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}
.CarsScreenTwo_position-text__CAsxR {
  position: absolute;
  left: 1160.91px;
  top: 333.76px;
  background-color: white;
  padding: 0px 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0579df;
}

.CarsScreenTwo_angle-sec__1etU3 {
  background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
}

.CarsScreenTwo_right-section-background-section__2pDM0 {
  width: 95%;
  /* height: 120px; */
  background: #ffffff;
  border: 0.4px solid #707070;
  box-sizing: border-box;
  border-radius: 0px 10px 10px 10px;
  /* overflow: auto;
    overflow-x: hidden; */
  margin: 0 auto;
}
.CarsScreenTwo_background-text__4wKWF {
  position: absolute;
  top: 335px;
  left: 803.91px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0579df;
  background: white;
  padding: 0px 5px;
}
.CarsScreenTwo_background-text__4wKWF p {
  margin-bottom: 0px;
}
.CarsScreenTwo_overlay_edit__In19J {
  position: relative !important;
}
.CarsScreenTwo_original_image__1fx85 {
  position: relative !important;
  /* object-fit: contain !important; */
}
.CarsScreenTwo_edited_image__2ob-m {
  position: relative;
  object-fit: contain;
  width: 783.96px;
  height: 441px;
  border: 0.6px solid #77797b;
  cursor: pointer;
}
.CarsScreenTwo_car-text__3wcNn h1 {
  font-size: 30px;
}
.CarsScreenTwo_got-btn__1TWku {
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 140px;
  height: 40px;
  margin: 20px 0;
}

.CarsScreenTwo_overlay-img__1PeyL {
  /* object-fit: scale-down !important; */
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: black !important;

  z-index: 91;
  object-fit: contain !important;
  height: 60% !important;
  margin-top: 10%;
}
.CarsScreenTwo_lower-section-with-overlay__HKigg {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%); */
  background-color: #fff;
}
.CarsScreenTwo_lower-section-with-overlay-2__30w9C {
  display: grid;
  flex-direction: row;
  width: 100%;
  position: relative;
  grid-template-columns: 59% 40%;
}
.CarsScreenTwo_overlay-box__2RcTa {
  display: flex;
  flex-direction: row;
  /* border-bottom: 1px solid #ddd; */
  padding: 5px 0;
  /* border-top: 1px solid #ddd;  */
}

.CarsScreenTwo_backgrounds-grid__104lE {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 9px;
}
.CarsScreenTwo_backgrounds-grid-custom__RynM1 {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 7px;
}
.CarsScreenTwo_backgrounds-grid-below__2yk76 {
  display: grid;
  /* justify-content: center; */
  grid-template-columns: 50% 50%;
  grid-gap: 9px;
}
.CarsScreenTwo_below-button__2KjGo {
  background: unset !important;
  /* color: #ff7700; */
  border: 2px solid #ff7700 !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin-top: 2% !important;
  border-radius: 30px !important;
}

.CarsScreenTwo_background-list__1VgA6 {
  padding: 0px 15px 25px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

.CarsScreenTwo_background-list-2__32xB7 {
  padding: 0;
  border-top: 0px solid #ebeaea;
  margin-top: 0px;
}

.CarsScreenTwo_back-credit__13omT img {
  width: auto !important;
  height: auto !important;
  padding-left: 4px;
  border: 0 !important;
}

.CarsScreenTwo_upload-sec__3z85S {
  padding: 35px 0;
}
.CarsScreenTwo_upload-sec__3z85S button img {
  width: 23px;
  margin-right: 10px;
}
.CarsScreenTwo_active-background-sec__Lwoer img {
  width: 100%;
  height: 90px;
  border-radius: 5px;
}
.CarsScreenTwo_bg-below-sec__2IdMb {
  justify-content: center;
  margin: 0;
  padding: 5px 60px 0;
}
.CarsScreenTwo_inactive-background-sec__YZfEz img {
  width: 100%;
  height: 90px;
  cursor: pointer;
  border-radius: 5px;
  border: 0.4px solid #333;
}
.CarsScreenTwo_select-img__MJ5jb {
  height: 65%;
  margin-left: 10px;
  border-radius: 2px;
}
.CarsScreenTwo_below-bg-apply___9rtq {
  border-radius: 22px !important;
  background: unset;
  color: rgb(255, 119, 0) !important;
  border: 1px solid #ff7700;
  margin-top: 4%;
  padding: 0;
  width: 78px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #ff7700;
}
.CarsScreenTwo_below-bg-apply___9rtq :hover {
  border-radius: 30px !important;
  background: unset;
  color: rgb(255, 119, 0) !important;
  border: 2px solid #ff7700;
  margin-top: 4%;
}
.CarsScreenTwo_bg-complete-sec__3_PeZ {
  /* display: flex; */
  justify-content: center;
  /* flex-direction: column; */
  text-align: center;
  margin-top: 14px;
  border-top: 1px solid rgba(222, 222, 222, 0.85);
  padding-top: 5px;
}
.CarsScreenTwo_bg-complete-sec__3_PeZ h4 {
  font-size: 12px;
  color: #232121;
  font-weight: 400;
}
.CarsScreenTwo_select-sec__1ugU3 {
  /* display: flex; */
  justify-content: center;
  margin-top: 3%;
}
.CarsScreenTwo_select-sec__1ugU3 span {
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;

  color: #232121;
}
.CarsScreenTwo_active-background-sec-below__36WM2 {
  padding: 5px 5px 0;
  cursor: pointer;
}
.CarsScreenTwo_active-background-sec-below__36WM2 img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}
.CarsScreenTwo_inactive-background-sec-below__3KLZG img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}
.CarsScreenTwo_overlay_edit__In19J {
  position: relative !important;
}

.CarsScreenTwo_overlay-img__1PeyL {
  /* object-fit: scale-down !important; */
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: black !important;

  z-index: 91;
  object-fit: contain !important;
  height: 60% !important;
  margin-top: 10%;
}
.CarsScreenTwo_lower-section-with-overlay__HKigg {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.CarsScreenTwo_continue-btn__2feBf {
  display: flex;
  justify-content: center;
  padding: 2%;
}

.CarsScreenTwo_background-sec__3WRH6 {
  /* margin-top: 18px; */
}
.CarsScreenTwo_download-popup__2Mei4 {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin-top: 20px;
}

.CarsScreenTwo_download-popup__2Mei4 button {
  border: 1px solid transparent;
  margin-bottom: 35px;
  background: #fff;
  border-radius: 0;
  color: #000;
  padding: 15px 95px;
  text-align: left;
}
.CarsScreenTwo_cross-btn__2Zmpe {
  text-align: right;
  padding: 0 0 10px;
}
.CarsScreenTwo_download-popup__2Mei4 button:nth-of-type(2) {
  margin-bottom: 15px;
}
.CarsScreenTwo_download-popup__2Mei4 ul {
  padding-left: 15%;
  list-style: none;
  margin: 0 0 5px;
}
.CarsScreenTwo_download-popup__2Mei4 ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsScreenTwo_download-popup__2Mei4 p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 15%;
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0 !important;
}
.CarsScreenTwo_download-popup__2Mei4 p span {
  color: #ff7700;
}
.CarsScreenTwo_download-popup__2Mei4 h6 {
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0 0 10px;
}
.CarsScreenTwo_border-1__2rbqK {
  width: 100%;
  /* height: 1px; */
  border-top: 1px solid #ddd;
  margin-bottom: 25px;
}
.CarsScreenTwo_download-popup__2Mei4 input {
  margin-right: 10%;
  border: 0;
  outline: 0;
}
.CarsScreenTwo_download-popup__2Mei4 input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ff7700;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.CarsScreenTwo_download-btn-hd__3JHyF {
  background: #ff7700 !important;
  border-radius: 5px !important;
  color: #fff !important;
  width: 250px !important;
  text-align: center !important;
  padding: 10px 28px !important;
  font-size: 17px;
}
.CarsScreenTwo_request-demo-1__3FJac {
  background: #ff7700 !important;
  border-radius: 5px !important;
  color: #fff !important;
  width: 153px !important;
  text-align: center;
  padding: 10px 28px !important;
  font-size: 17px;
}

.CarsScreenTwo_img-overlay__26QA6 {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  );
  height: 100%;
  bottom: 0%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
}

.CarsScreenTwo_more-btn-overlay__PG9Dg {
  position: absolute;
  top: 32%;
  left: 24%;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.CarsScreenTwo_more-btn-overlay-2__HMMn0 {
  position: absolute;
  top: 28%;
  left: 8%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.CarsScreenTwo_overlay-pos___xgZd {
  position: relative;
}

.CarsScreenTwo_cross-img__27n5N {
  width: auto !important;
  cursor: pointer;
}

.CarsScreenTwo_request-demo-1__3FJac:hover {
  background-color: #ff7700 !important;
  border: 0 !important;
}

.CarsScreenTwo_download-popup__2Mei4 p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.CarsScreenTwo_download-popup__2Mei4 button.CarsScreenTwo_activate__2om-t,
.CarsScreenTwo_download-popup__2Mei4 button:hover {
  background: rgba(5, 121, 223, 0.02);
  border: 1px solid #0579df;
  box-sizing: border-box;
  border-radius: 10px;
}

.CarsScreenTwo_back-name__2iiEN {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0 !important;
}
.CarsScreenTwo_overlay-toggle__2UP59 {
  display: block;
  margin-top: 4%;
}
.CarsScreenTwo_active-toggle-btn__2sRbw {
}
.CarsScreenTwo_car-overlay-cat__3g-Z1 {
  padding: 0 0 0 10px;
}
.CarsScreenTwo_overlays-click-btn__gdv2Y {
  /* display: flex; */
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 5px;
  margin: 5px 10px 5px;
  border: 2px solid transparent;
}
.CarsScreenTwo_overlays-click-btn__gdv2Y span {
  color: #000;
}
.CarsScreenTwo_overlays-click-btn__gdv2Y:hover span {
  color: #ff7700;
}
.CarsScreenTwo_back-credit__13omT {
  text-align: end;
  font-size: 12px;
  padding-top: 5px;
  margin-bottom: 0 !important;
}

.CarsScreenTwo_logo-disp__9nzB0 {
  width: 86px;
  background-color: #e0e0e0;
  height: 34px;
}

.CarsScreenTwo_logo-upload__YXGpi {
  width: 157px;
  height: 44px;
  border-radius: 5px;
}

.CarsScreenTwo_logo-sec__1SwG2 {
  padding: 0px 13px 0;
  border-top: 1px solid #ebeaea;
  margin-top: 22px;
}

.CarsScreenTwo_background-list__1VgA6 h6 {
  color: #232121;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.CarsScreenTwo_overlays-click-btn__gdv2Y img {
  /* height: 120px; */
  width: 90%;
  object-fit: contain;
  padding: 0;
}

.CarsScreenTwo_overlay-toggle__2UP59 {
  display: flex;
  margin-top: 0%;
  padding: 0;
}
.CarsScreenTwo_credit-text__obqzQ {
  font-size: 14px;
  font-family: poppins;
  color: #232121;
  text-align: center;
  /* display: flex;
  justify-content:flex-end; */
}

.CarsScreenTwo_credit-text__obqzQ span {
  color: #ff7700;
}

.CarsScreenTwo_credit-text__obqzQ img {
  margin-right: 10px;
  width: 40px;
}

.CarsScreenTwo_active-toggle-btn__2sRbw {
}

/* .overlays-click-btn {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  padding: 5px;
  margin-top: 10px;
} */

.CarsScreenTwo_car-try-btns__3AjC9 {
  display: inline-block;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  padding: 5px 5px 0;
  width: 33%;
  margin: 0;
  text-align: center;
  font-size: 10px;
  height: 100%;
  width: 50%;
}
.CarsScreenTwo_car-active-btns__2c5tB {
  border-bottom: #ff7700;
}
.CarsScreenTwo_car-try-btns__3AjC9 img {
  height: auto;
  width: 70%;
  object-fit: contain;
  padding: 0;
  margin: 0 auto;
  object-fit: contain;
  padding: 0;
  margin: 0 auto;
  /* float: left; */
  display: block;
}
.CarsScreenTwo_car-try-btns__3AjC9 span {
  display: block;
  font-size: 14px;
  vertical-align: middle;
  width: 100%;
}
.CarsScreenTwo_all-overlays__1h2s9 {
  display: flex;
  justify-content: center;
  padding: 0;
}
.CarsScreenTwo_car_overlay_cat__21DJ5 {
  display: flex;
  flex-direction: column;
}
.CarsScreenTwo_overlay-section__23Qtw {
  padding: 0;
}
.CarsScreenTwo_overlay-toggle__2UP59 p {
  font-size: 14px;
  margin: 0;
}
.CarsScreenTwo_active__1U48t {
  /* border:2px solid #ff7700!important; */
}
.CarsScreenTwo_overlay-car-btn__1gVfY {
  padding: 0px 5px;
  background-color: rgb(255, 255, 255);
  color: rgb(10, 10, 10);
  text-align: center;
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  box-shadow: 0 2px 5px 0 #ddd;
  margin: 15px 0 10px;
}

.CarsScreenTwo_overlays-click-btn__gdv2Y:hover {
  border: 2px solid #ff7700;
  color: #ff7700;
}
.CarsScreenTwo_overlay-toggle__2UP59 label {
  margin: 0;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2145945c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #214595;
}

.CarsScreenTwo_overlay-car-btn__1gVfY:hover,
.CarsScreenTwo_overlays-click-btn__gdv2Y:focus {
  border-bottom: 3px solid #ff7700;
  color: #ff7700;
}

.CarsScreenTwo_backgrounds__1BZpR {
  overflow-y: scroll;
  display: flex;
  flex-flow: row wrap;
  height: 150px;
  margin-left: auto;
  white-space: nowrap;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center !important;
  margin: 10px 0;
  padding: 4px;
}
.CarsScreenTwo_backgrounds__1BZpR li {
  width: 20%;
  padding: 0px 5px;
}
.CarsScreenTwo_backgrounds__1BZpR li button {
  width: 100%;
  display: block;
  padding: 0 !important;
  height: 60px !important;
}
.CarsScreenTwo_inactive-background-images__2MP9B {
  padding: 0 10px 0 0 !important;
  margin: 0 0 !important;
  display: inline-block;
  width: 90px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.CarsScreenTwo_inactive-background-images__2MP9B p.CarsScreenTwo_bg_img-texts__18P1U,
.CarsScreenTwo_active-background-images__1to5x p.CarsScreenTwo_bg_img-texts__18P1U {
  font-size: 10px;
  width: 100%;
  margin: 0 auto !important;
  text-align: center !important;
  white-space: normal;
}
.CarsScreenTwo_bg_img-texts__18P1U {
  font-size: 71px;
  margin-bottom: 10px !important;
  color: #ff7700;
  margin-left: 14px !important;
  /* width:  65px; */
}
.CarsScreenTwo_active-background-images__1to5x {
  height: 70px !important;
  padding: 0 !important;
  margin: 0 10px 0 0px !important;
}
.CarsScreenTwo_active-background-images__1to5x img {
  border: 2px solid black;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  margin: 5px 0 0 0px !important;
}
.CarsScreenTwo_credit-para__1oFDq {
  position: relative;
  /*top: -18%; */
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
  color: #ffffff;

  margin: 0 auto;
  margin-top: -18%;
}
.CarsScreenTwo_inactive-background-images__2MP9B img,
.CarsScreenTwo_active-background-images__1to5x img {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5px;
  height: 65px;
  width: 65px;
}
.CarsScreenTwo_image-credit__1rSKL p {
  margin-top: 0px;
  width: 65px;
  height: 16.6px;
}
.CarsScreenTwo_middle__1rDU_ {
  transition: 0.5s ease;
  opacity: 0;
  position: relative;
  top: -60%;
  left: -10%;
  text-align: center;
  cursor: pointer;
}
.CarsScreenTwo_text__WnWKZ {
  background: rgba(0, 0, 0, 0.7) !important;
  border-radius: 0px 0px 5px 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
  color: #ffffff !important;
  text-align: center;
  z-index: 9999;
  padding-top: 5px;
  padding: 16px 32px;
}
.CarsScreenTwo_inactive-background-images__2MP9B:hover img,
.CarsScreenTwo_active-background-images__1to5x:hover img {
  opacity: 0.6;
}
.CarsScreenTwo_inactive-background-images__2MP9B:hover .CarsScreenTwo_middle__1rDU_,
.CarsScreenTwo_active-background-images__1to5x:hover .CarsScreenTwo_middle__1rDU_ {
  opacity: 1;
}
.CarsScreenTwo_right-bottom-section__3BfUe {
  padding: 18.94px 15px 0;
  text-align: center;
}
.CarsScreenTwo_right-bottom-section__3BfUe p:nth-child(1) {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #0579df !important;
}
.CarsScreenTwo_right-bottom-section__3BfUe p:nth-child(2) {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #000000;
  text-align: center;
  margin: 0;
}
.CarsScreenTwo_right-bottom-section__3BfUe span {
  color: #0579df !important;
}
.CarsScreenTwo_separation-border__2-o4K {
  position: absolute;
  width: 47.31px;
  height: 0px;
  left: 1029.53px;
  top: 660.72px;
  border: 0.4px solid #707070;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.CarsScreenTwo_bottom-apply-area__HXT_m {
  padding-top: 5px;
  text-align: left;
}
.CarsScreenTwo_overlay-vertical__2C61r {
  padding: 0;
  height: 225px;
  overflow-y: scroll;
}
.CarsScreenTwo_bottom-apply-area__HXT_m button {
  /* position: absolute; */
  width: 128.85px;
  height: 40px;
  left: 1091.92px;
  top: 634.63px;
  background: #ff7700;
  border-radius: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
button:hover {
  color: #ffffff;
}
.CarsScreenTwo_bottom-apply-area__HXT_m p:nth-child(2) {
  position: absolute;
  left: 1111.84px;
  top: 679.76px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 12px;
  color: #4f4444;
  text-align: center;
}
.CarsScreenTwo_bulk-pricing__11raJ {
  position: absolute;
  width: 221px;
  height: 19px;
  left: 917.59px;
  top: 750.81px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #77797b;
  text-decoration: none;
}
.CarsScreenTwo_bulk-pricing__11raJ:hover {
  text-decoration: none;
  color: #77797b;
}
.CarsScreenTwo_bulk-pricing__11raJ span {
  color: #0579df;
}
.CarsScreenTwo_spinner__Fbhti {
  width: 80px;
  height: 80px;
  position: relative;
  left: 40%;
  top: 40%;
}
.CarsScreenTwo_dealership-logo-section__3eLDm {
  width: 100%;
  /* border: 1px solid grey;
  margin: 1px auto 20px; */
  border-radius: 10px;
}
.CarsScreenTwo_center-title__3R1RA {
  text-align: center;
  margin-top: -10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
  display: block;
  width: 100%;
  margin-bottom: 0;
}
.CarsScreenTwo_center-title__3R1RA strong {
  background: #fff;
  padding: 5px 10px;
}
.CarsScreenTwo_center-title2__3AwZK {
  text-align: center;
  margin-top: -10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
  display: block;
  width: 100%;
  margin-bottom: 0;
  margin-bottom: -10px;
}
.CarsScreenTwo_center-title2__3AwZK strong {
  background: #fff;
  padding: 5px 10px;
}
input[type='checkbox']:checked {
  background: #ddd;
}
.CarsScreenTwo_pricing-plan-area__1GvAJ {
  border-right: 2px solid #707070a1;
}

.CarsScreenTwo_right-mid-section__17jP1 p.CarsScreenTwo_pricing-plan__Sqz4X {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0579df !important;
  text-align: center;
  margin: 5px 0;
}
.CarsScreenTwo_popup__2Vptc {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.CarsScreenTwo_popup_inner__1J2Ht {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}
.CarsScreenTwo_wall-cross__2Rz5V {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.CarsScreenTwo_right-mid-section__17jP1 p.CarsScreenTwo_available-cre__3WQ4a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
}

.CarsScreenTwo_image-box-area__10QGl {
  height: 100%;
  padding: 0 10px;
  margin: 0 auto;
  justify-content: center;
}
.CarsScreenTwo_image-box-area__10QGl video {
  width: 101.9%;
}
/* Animation */
/* @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
  }
  
  .animatedFadeInUp {
    opacity: 0;
  }
  
  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
   */
.CarsScreenTwo_box-text1__oOUx0,
.CarsScreenTwo_box-text2__f2Skj,
.CarsScreenTwo_box-text3__23fYR,
.CarsScreenTwo_box-text4__1z_Nv,
.CarsScreenTwo_box-text5__39XwN,
.CarsScreenTwo_box-text6__3Q6pD {
  padding: 0 10px;
  padding-top: 48%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 35px;
  margin: 0;
  transition: 0.5s ease-in-out;
  /* -webkit-animation: fadeInOut 4s linear forwards;
    animation: fadeInOut 4s linear forwards; */
  text-align: left;
  width: 65%;
  height: 30rem;
}

.CarsScreenTwo_box-text1__oOUx0 span,
.CarsScreenTwo_box-text2__f2Skj span,
.CarsScreenTwo_box-text3__23fYR span,
.CarsScreenTwo_box-text4__1z_Nv span,
.CarsScreenTwo_box-text5__39XwN span,
.CarsScreenTwo_box-text6__3Q6pD span {
  color: #ff7700;
}
.CarsScreenTwo_bg-right-top___9ErZ {
  display: flex;
  /* height: 21%; */
  /* border: 1px solid #e0e0e0; */
  /* border-radius: 5px; */
  padding: 0 0 15px;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
}
.CarsScreenTwo_active-visual-selection__31q-p {
  border-top: 2px solid #ff7700;
  color: #ff7700;
  background: #fff;
  padding: 10px;
}
.CarsScreenTwo_inactive-visual-selection__1cJj3 {
  /* border-bottom: 2px solid #ff7700; */
  color: #000;
  padding: 10px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
}
.CarsScreenTwo_img-floor__2ECKN {
  position: relative;
  width: 65px !important;
  height: 65px;
  overflow: hidden;
  border-radius: 50%;
  /* margin-left: 8%; */
  margin: 0 auto;
}
.CarsScreenTwo_right-top-selection__MDunR {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0px;
  margin: 0px auto 15px;
  justify-content: center;
  text-align: center;
  /* border-top: 2px solid #e0e0e0; */
}
.CarsScreenTwo_img-floor__2ECKN img {
  width: 100%;
  height: auto;
}

.CarsScreenTwo_backdrop__1h8AK {
  padding-top: 3%;
  padding-left: 2%;
  font-size: 100%;
  font-weight: 500;
  padding-bottom: 2%;
}

@-webkit-keyframes CarsScreenTwo_topFadeOut__LZu20 {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    top: 25%;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes CarsScreenTwo_topFadeOut__LZu20 {
  0% {
    position: absolute;
    top: -3rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    top: 25%;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes CarsScreenTwo_bottomFadeOut__qkaO_ {
  0% {
    bottom: -5rem;
    opacity: 0;
  }

  75% {
    bottom: 25%;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes CarsScreenTwo_bottomFadeOut__qkaO_ {
  0% {
    bottom: -5rem;
    opacity: 0;
  }

  75% {
    bottom: 25%;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* @keyframes topFadeOutVertical {
    0% {
      position: absolute;
      top: -3rem;
      opacity: 0;
    }
  
    75% {
      position: absolute;
      top: 45%;
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  } */

/* @keyframes rightFadeInOut {
    0% {
      position: absolute;
      right: -3rem;
      opacity: 0;
    }
  
    75% {
      position: absolute;
      right: 10rem;
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      right: 10rem;
    }
  } */

@-webkit-keyframes CarsScreenTwo_fadeInOut__TPKA4 {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 0%;
  }
}

@keyframes CarsScreenTwo_fadeInOut__TPKA4 {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 0%;
  }
}

.CarsScreenTwo_box-text1__oOUx0 {
  -webkit-animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
          animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}
.CarsScreenTwo_box-text2__f2Skj {
  -webkit-animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
          animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}
.CarsScreenTwo_box-text3__23fYR {
  -webkit-animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
          animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}
.CarsScreenTwo_box-text4__1z_Nv {
  -webkit-animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
          animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
.CarsScreenTwo_box-text5__39XwN {
  -webkit-animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
          animation-name: CarsScreenTwo_bottomFadeOut__qkaO_;
  -webkit-animation-delay: 13s;
          animation-delay: 13s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.CarsScreenTwo_box-img__14FJx {
  width: 35% !important;
  height: auto !important;
}
.CarsScreenTwo_bottom-contact-badge__3zWSi {
  background: #dee2e6;
  padding: 5px 15px;
  border-radius: 0px 0px 11px 10px;
  margin: 0px 50px;
  margin-bottom: 50px;
}
.CarsScreenTwo_bottom-contact-badge__3zWSi p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  width: -webkit-max-content;
  width: max-content;
  padding: 0px 15px;
  display: inline-block;
  margin: 0;
}
.CarsScreenTwo_bottom-contact-badge__3zWSi p span {
  color: #ff7700;
}
.CarsScreenTwo_bottom-contact-badge__3zWSi ul {
  list-style-type: none;
  display: inline-block;
  padding: 0;
  vertical-align: text-bottom;
  margin: 0;
}
.CarsScreenTwo_bottom-contact-badge__3zWSi ul li {
  display: inline-block;
  padding: 0 10px 0 0;
}

/* footer */
.CarsScreenTwo_footer__3IqkY {
  background: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/light+CONTACT+10+1.png);
  height: 300px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 60px 0px 0px;
  color: #fff;
}
.CarsScreenTwo_footer-left-text__24oUj {
}
.CarsScreenTwo_footer-left-text__24oUj h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 57px;
  margin-top: 18%;
  text-align: center;
}
.CarsScreenTwo_footer-left-text__24oUj h2 span {
  border-bottom: 2px solid #fff;
}
.CarsScreenTwo_footer-left-text__24oUj h2 span:after {
  content: '';
}
.CarsScreenTwo_footer-right-text__Ufkqa {
  padding: 5% 15px 0;
}
.CarsScreenTwo_footer-right-text__Ufkqa ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}
.CarsScreenTwo_footer-right-text__Ufkqa ul li {
  margin: 15px 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 50%;
  width: 85px;
  text-align: center;
  line-height: 80px;
}
.CarsScreenTwo_footer-right-text__Ufkqa ul li img {
  padding: 15px;
}

.CarsScreenTwo_footer-right-text__Ufkqa p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  font-weight: 400;
}

/* //footer */

@-webkit-keyframes CarsScreenTwo_fadeInUp__38t9w {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes CarsScreenTwo_fadeInUp__38t9w {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.CarsScreenTwo_box-text__tP3-R {
  opacity: 0;
  -webkit-animation: CarsScreenTwo_fadeInUp__38t9w 2s ease-in-out 0s forwards;
  animation: CarsScreenTwo_fadeInUp__38t9w 2s ease-in-out 0s forwards;
}
.CarsScreenTwo_box-text__tP3-R {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.CarsScreenTwo_email__1kaHd {
  position: relative !important;
  top: 80px !important;
  left: 25% !important;
  z-index: 999 !important;
  height: 0px !important;
}
.CarsScreenTwo_email__1kaHd form {
  background: rgba(98, 98, 98, 0.7);
  max-width: 415px;
  border-radius: 16px;
  text-align: center;
  align-items: center;
  padding: 20px;
}
.CarsScreenTwo_email__1kaHd img {
  width: 100% !important;
}
.CarsScreenTwo_email__1kaHd label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.CarsScreenTwo_email__1kaHd input {
  height: 48px;
  margin-bottom: 40px;
}
.CarsScreenTwo_email__1kaHd button {
  width: 124px;
  height: 35px;
  left: 399px;
  top: 651px;
  background: #ffffff;
  border-radius: 9px;
  color: #616161;
}

.CarsScreenTwo_load-video__yasiz {
  width: 100%;
  height: 461px;
}

.CarsScreenTwo_video-text-header__1RdE2 h6 {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.CarsScreenTwo_perfect-output__1WR5S {
  margin: 70px 0 50px;
  /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%);
  background-color: #fff; */
  background: #fff;
}

.CarsScreenTwo_top-bar__6jY_e {
  padding: 10px 0px;
  padding: 15px 0px 10px;
}

.CarsScreenTwo_video-sec__3FVNM {
  margin: 100px 0 50px;
}

.CarsScreenTwo_heading__14gjh h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.CarsScreenTwo_heading__14gjh h3 span {
  color: #ff7700;
  padding: 0 0 7px;
}
.CarsScreenTwo_heading__14gjh h3 .CarsScreenTwo_border-1__2rbqK {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.CarsScreenTwo_perfect-output-images__1u7Ce {
  text-align: center;
  padding: 20px 15px 0;
}
.CarsScreenTwo_perfect-output-images__1u7Ce img {
  width: 100%;
  -webkit-filter: drop-shadow(-4px 4px 7px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(-4px 4px 7px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  cursor: pointer;
}
.CarsScreenTwo_perfect-output-images__1u7Ce p {
  text-align: center;
  margin-top: 10px;
  color: #232121;
}
.CarsScreenTwo_perfect-output-images__1u7Ce p span a {
  font-style: normal;
  color: #ff7700;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
}

.CarsScreenTwo_perfect-output-images__1u7Ce p a {
  background: #ff7700;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  padding: 5px 20px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
}
.CarsScreenTwo_bg-try__37tVe {
  background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
  background-position: top right;
  background-repeat: no-repeat;
}

.CarsScreenTwo_floor-output-box__1b058 {
  box-shadow: -1px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  background-color: #fff;
  padding: 30px 20px;
  margin-top: 20px;
}
.CarsScreenTwo_custom-container__34L4v {
  max-width: 88%;
  margin: auto;
}

.CarsScreenTwo_try-again__1WO0P h6 {
  text-align: center;
  font-size: 16px;
  color: #000;
  font-family: poppins;
  font-weight: 400;
}

.CarsScreenTwo_try-again__1WO0P span {
  cursor: pointer;
  color: #ff7700;
}

.CarsScreenTwo_try-again__1WO0P {
  margin-top: 30px;
}
.CarsScreenTwo_try-again__1WO0P div {
  display: contents !important;
}

.CarsScreenTwo_perfect-output-images__1u7Ce button {
  background-color: transparent !important;
  padding: 0 !important;
  color: transparent !important;
}

.CarsScreenTwo_car-try-inner__36mQV {
  background: #fbfbfb;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.CarsScreenTwo_overlay-box-mob__M_CCM {
  display: none;
}

.CarsScreenTwo_inactive-background-sec__YZfEz {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 #ddd;
  /* padding: 3px; */
  /* border: 2px solid #333; */
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.CarsScreenTwo_inactive-background-sec__YZfEz span {
  font-size: 13px;
}
.CarsScreenTwo_active-background-sec__Lwoer {
  box-shadow: 0 2px 5px 0 #ddd;
  padding: 3px;
  border: 2px solid #333;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
}
.CarsScreenTwo_active-background-sec__Lwoer span {
  font-size: 13px;
}
.CarsScreenTwo_inactive-walls-sec__1VGA6 {
  padding: 5px;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.CarsScreenTwo_inactive-walls-sec__1VGA6 span {
  font-size: 13px;
}
.CarsScreenTwo_active-walls-sec__1G9R1 {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.CarsScreenTwo_active-walls-sec__1G9R1 span {
  font-size: 13px;
}
.CarsScreenTwo_active-walls-sec__1G9R1 img {
  width: 100%;
  height: 60px;
}
.CarsScreenTwo_inactive-walls-sec__1VGA6 img {
  width: 100%;
  height: auto;
  border: 0.4px solid #333;
}
.CarsScreenTwo_inactive-walls-sec__1VGA6 {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.CarsScreenTwo_custom__3Du2L {
  font-size: 13px;
  font-weight: 500;
  /* margin-left: 14%; */
}

.CarsScreenTwo_cback__3Z9Lm {
  display: none;
}
.CarsScreenTwo_bcredit__15Maf {
  display: none;
}

.CarsScreenTwo_mob-image__3xEGQ img {
  width: 100%;
  margin-top: 10%;
}
.CarsScreenTwo_mob-image__3xEGQ .CarsScreenTwo_mac-gif__EsSg1 {
  position: absolute;
  width: 56.1%;
  right: 10.2%;
  top: 6%;
}

.CarsScreenTwo_cars-demo__36VUU {
  /* height: 580px; */
  text-align: center;
}
.CarsScreenTwo_cars-calender__1DiVz {
}
.CarsScreenTwo_cars-calender__1DiVz h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  margin-bottom: -20px;
}
.CarsScreenTwo_apply-below-btn__2vSvi {
  width: 100%;
  text-align: center;
  padding: 5px 0px;
}
.CarsScreenTwo_output-apply-btn__36VQn {
  text-align: center;
  padding: 5px 0px;
}
/* Custom Walls */
.CarsScreenTwo_custom-background-list__572YV {
  padding: 20px 10px 20px 35px;
}
.CarsScreenTwo_custom-background-list__572YV:before,
.CarsScreenTwo_adjust-height-area__nTFyu:before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 1px dashed #ff7700;
}

.CarsScreenTwo_custom-wall__zioWD {
}
.CarsScreenTwo_custom-wall__zioWD p,
.CarsScreenTwo_adjust-height__3Y0mm p {
  margin: 0;
}
.CarsScreenTwo_adjust-height__3Y0mm p {
  padding: 5px 0;
  font-size: 16px;
}
.CarsScreenTwo_custom-wall__zioWD p span {
  background: #ff7700;
  color: #fff;
  border-radius: 50%;
  padding: 5px 13px;
  margin-right: 10px;
}
.CarsScreenTwo_adjust-height__3Y0mm p span {
  background: #e5e5e5;
  color: #fff;
  border-radius: 50%;
  padding: 5px 12px;
  margin-right: 10px;
}

.CarsScreenTwo_custom-walls-area__3Io0N {
  padding-left: 10px;
}
.CarsScreenTwo_adjust-height-area__nTFyu {
  padding: 5px 0px;
  position: relative;
}
.CarsScreenTwo_adjust-height-area__nTFyu ul {
  padding: 5px 10%;
  list-style: none;
  text-align: center;
  display: flex;
}
.CarsScreenTwo_adjust-height-area__nTFyu ul li {
  text-align: center;
  width: 35%;
  padding: 10px;

  margin: 0 auto;
}
.CarsScreenTwo_adjust-height-area__nTFyu ul li button {
  background: rgba(235 215 198 / 0%) !important;
  border: 1px solid #000000 !important;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: auto;
  border-radius: 0;
}
.CarsScreenTwo_adjust-height-area__nTFyu ul li button.CarsScreenTwo_active__1U48t,
.CarsScreenTwo_adjust-height-area__nTFyu ul li button:hover {
  background: rgb(235 215 198 / 0%) !important;
  border: 1px solid #ff7700 !important;
}
.CarsScreenTwo_adjust-height-area__nTFyu ul li h6 {
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  margin: 0;
}
.CarsScreenTwo_adjust-height-area__nTFyu ul li img {
  width: 100%;
  height: 113px;
  object-fit: contain;
}

.CarsScreenTwo_backgrounds-grid-custom__RynM1 .CarsScreenTwo_inactive-background-sec__YZfEz img {
  height: auto;
  border: 0;
}
.CarsScreenTwo_favroute-add__3r-wv {
  margin: 0;
}
.CarsScreenTwo_favroute-add__3r-wv img {
  width: 25%;
}
.CarsScreenTwo_favroute-add__3r-wv span {
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
}
.CarsScreenTwo_fav-icon__3p635 {
  position: absolute;
  top: 6px;
  right: 6px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 0px 5px;
  padding: 3px 7px;
}
.CarsScreenTwo_fav-icon__3p635 svg {
}
.CarsScreenTwo_white-icon___ApqU {
}
.CarsScreenTwo_hoverable-icon__RPLIw {
  display: none;
}
.CarsScreenTwo_active-background-sec__Lwoer:hover .CarsScreenTwo_white-icon___ApqU,
.CarsScreenTwo_inactive-background-sec__YZfEz:hover .CarsScreenTwo_white-icon___ApqU {
  display: none;
}
.CarsScreenTwo_active-background-sec__Lwoer:hover .CarsScreenTwo_hoverable-icon__RPLIw,
.CarsScreenTwo_inactive-background-sec__YZfEz:hover .CarsScreenTwo_hoverable-icon__RPLIw {
  display: inline-block;
}
.CarsScreenTwo_active-background-sec__Lwoer .CarsScreenTwo_fav-icon__3p635 {
  top: 3px;
  right: 3px;
}
.CarsScreenTwo_more-btn-overlay__PG9Dg span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ff7700;
  display: block;
  text-align: center;
}
.CarsScreenTwo_colored__35WF- {
  color: #ff7700;
}
.CarsScreenTwo_background-list__1VgA6 h6 span {
  text-decoration: underline;
}
.CarsScreenTwo_wallet-icon__2cyVQ svg {
  margin-right: 10px;
}

/* Responsive  */
@media (max-width: 800px) {
  body {
    background-size: cover;
    background-repeat: repeat-y;
  }
  .CarsScreenTwo_toggle-tab__1G2la {
    justify-content: flex-end;
    margin: 0 0 -5px !important;
  }

  /* //footer */

  @-webkit-keyframes CarsScreenTwo_fadeInUp__38t9w {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  .CarsScreenTwo_screen-two-main-wrapper__2ga_q {
    display: block;
    margin: 0px 15px;
    /* display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 20px;
        margin: 0px; */
  }
  .CarsScreenTwo_left-section-with-image__16-3Q {
    width: 100%;
    height: 343px;
  }
  .CarsScreenTwo_right-section-with-image__-m57o {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 0;
    padding-top: 0;
    height: auto;
  }
  .CarsScreenTwo_right-top-section__3jrn_ {
    /* display: flex;
        flex-direction: column; */
  }
  .CarsScreenTwo_choose-angle-text__2FrhD {
    position: absolute;
    /* width: 40px; */
    height: 12px;
    left: 51.7px;
    top: 560.7px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #0579df;
  }
  .CarsScreenTwo_tooltip-text__11BXL p {
    margin: 0px 5px;
  }
  .CarsScreenTwo_logo-text-sec__1RSD1 {
    position: absolute;
    height: 12px;
    left: 52.97px;
    top: 677.78px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #0579df;
  }
  .CarsScreenTwo_background-text__4wKWF {
    position: absolute;
    height: 12px;
    left: 52.93px;
    top: 680.7px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #0579df;
  }
  .CarsScreenTwo_position-text__CAsxR {
    display: none;
  }
  .CarsScreenTwo_bottom-apply-area__HXT_m {
    position: absolute;
    left: -850px !important;
    top: 355px !important;
  }
  .CarsScreenTwo_bulk-pricing__11raJ {
    position: absolute;
    left: 24%;
    top: 1150px;
    padding-bottom: 20px;
  }
  .CarsScreenTwo_separation-border__2-o4K {
    display: none;
  }
  .CarsScreenTwo_with-image-inner__yCMiX {
    width: 100%;
    height: 243px;
    border: 0.6px solid #77797b;
  }
  .CarsScreenTwo_with-image-inner__yCMiX div {
    height: 100%;
    width: 100%;
  }
  .CarsScreenTwo_right-section-background-section__2pDM0 {
    width: 95%;
    margin: 20px auto 20px;
    height: auto;
    overflow: hidden;
  }
  .CarsScreenTwo_right-mid-section__17jP1 {
    width: 100%;
    margin-top: 115px;
  }
  .CarsScreenTwo_with-image-inner__yCMiX img {
    width: 100% !important;
    /* height: 243px;
        object-fit: contain; */
    padding: 5px;
    /* object-fit: contain; */
  }
  .CarsScreenTwo_inactive-background-images__2MP9B img,
  .CarsScreenTwo_active-background-images__1to5x img {
    height: 45px;
    width: 45px;
  }
  .CarsScreenTwo_choose-angle__1jY1J {
    padding: 10px 15px;
    width: auto !important;
    grid-gap: 20px !important;
  }
  .CarsScreenTwo_angle-selected__1wB_d {
    padding: 10px 15px;
    width: auto !important;
    grid-gap: 20px !important;
  }
  .CarsScreenTwo_choose-angle__1jY1J button,
  .CarsScreenTwo_angle-selected__1wB_d button {
    width: 50px;
  }
  .CarsScreenTwo_upload-button__3csVs button {
    margin-left: 0px;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe {
    padding: 0px;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe div:nth-child(1) {
    /* display: none; */
    /* margin: -330px auto auto auto; */
  }
  .CarsScreenTwo_pricing-plan__Sqz4X {
  }
  .CarsScreenTwo_upload-button__3csVs {
    position: absolute;
    left: 270px;
  }
  .CarsScreenTwo_upload-button__3csVs button {
    width: auto;
    height: 35px;
    background: #0579df;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px;
    color: #ffffff;
  }
  .CarsScreenTwo_upload-logo-button__3bMKc button {
    /* width: 105.98px; */
    height: 35px;
    left: 174.18px;
    top: 673.2px;
    background: #0579df;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    /* identical to box height */

    color: #ffffff;
  }
  .CarsScreenTwo_logo-select__1bVu9 {
    margin-left: 22px;
  }
  .CarsScreenTwo_logo-image__UddxA {
    height: auto;
    width: 96.97px;
  }
  .CarsScreenTwo_logo-image__UddxA p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    color: #888888;
  }
  .CarsScreenTwo_credit-para__1oFDq {
    position: relative;
    top: -4%;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 45px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    color: #ffffff;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    /* margin: 10px 0;
        padding: 0px;
        display: flex;
        justify-content: space-evenly;
        height: 85px; */
    /* width: 100%; */
  }
  .CarsScreenTwo_bottom-apply-area__HXT_m p:nth-child(2) {
    display: none;
  }

  .CarsScreenTwo_center-title2__3AwZK {
    margin-bottom: -30px;
  }
  .CarsScreenTwo_pricing-plan-area__1GvAJ {
    border-right: 0;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe p:nth-child(2) {
    line-height: normal;
    line-height: initial;
    margin: 0 0 10px;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe p:nth-child(1) {
    line-height: normal;
    line-height: initial;
    margin-bottom: 10px;
  }
  .CarsScreenTwo_bottom-contact-badge__3zWSi {
    text-align: center;
    background: #dee2e6;
    padding: 5px 15px;
    border-radius: 0px 0px 11px 10px;
    margin: 0px 15px;
    margin-bottom: 50px;
  }
  .CarsScreenTwo_bg_img-texts__18P1U {
    margin-left: 0px !important;
  }
  .CarsScreenTwo_email__1kaHd {
    position: relative !important;
    top: 0px !important;
    left: 0% !important;
    z-index: 999 !important;
    height: 0px !important;
    width: 100% !important;
  }
  .CarsScreenTwo_email__1kaHd form {
    background: rgba(98, 98, 98, 0.7);
    border-radius: 0px;
    text-align: center;
    align-items: center;
    padding: 20px;
  }
  .CarsScreenTwo_email__1kaHd img {
    width: 100% !important;
  }
  .CarsScreenTwo_email__1kaHd label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 0px;
  }
  .CarsScreenTwo_email__1kaHd input {
    height: auto;
  }
  .CarsScreenTwo_email__1kaHd button {
    width: 124px;
    height: 35px;
    background: #ffffff;
    border-radius: 9px;
    color: #616161;
  }
  .CarsScreenTwo_download-popup__2Mei4 button {
    padding: 15px;
  }

  .CarsScreenTwo_right-top-selection__MDunR {
    grid-template-columns: 48% 48%;
    grid-gap: 10px;
  }
}

@media (max-width: 800px) {
  body {
    background-size: cover;
    background-repeat: repeat-y;
  }
  .CarsScreenTwo_screen-two-main-wrapper__2ga_q {
    display: block;
    margin: 0px 15px;
  }
  .CarsScreenTwo_left-section-with-image__16-3Q {
    width: 100%;
    height: 343px;
  }

  .CarsScreenTwo_right-top-section__3jrn_ {
    /* display: flex;
        flex-direction: column; */
  }
  .CarsScreenTwo_choose-angle-text__2FrhD {
    position: absolute;
    /* width: 40px; */
    height: 12px;
    left: 51.7px;
    top: 560.7px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #0579df;
  }
  .CarsScreenTwo_tooltip-text__11BXL p {
    margin: 0px 5px;
  }
  .CarsScreenTwo_logo-text-sec__1RSD1 {
    position: absolute;
    height: 12px;
    left: 52.97px;
    top: 677.78px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #0579df;
  }
  .CarsScreenTwo_background-text__4wKWF {
    position: absolute;
    height: 12px;
    left: 52.93px;
    top: 680.7px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #0579df;
  }
  .CarsScreenTwo_position-text__CAsxR {
    display: none;
  }
  .CarsScreenTwo_bottom-apply-area__HXT_m {
    position: absolute;
    left: -850px !important;
    top: 355px !important;
  }
  .CarsScreenTwo_bulk-pricing__11raJ {
    position: absolute;
    left: 24%;
    top: 1150px;
    padding-bottom: 20px;
  }
  .CarsScreenTwo_separation-border__2-o4K {
    display: none;
  }
  .CarsScreenTwo_with-image-inner__yCMiX {
    width: 100%;
    height: 243px;
    border: 0.6px solid #77797b;
  }
  .CarsScreenTwo_with-image-inner__yCMiX div {
    height: 100%;
    width: 100%;
  }
  .CarsScreenTwo_right-section-background-section__2pDM0 {
    width: 95%;
    margin: 20px auto 20px;
    height: auto;
    overflow: hidden;
  }
  .CarsScreenTwo_right-mid-section__17jP1 {
    width: 100%;
    margin-top: 115px;
  }
  .CarsScreenTwo_with-image-inner__yCMiX img {
    width: 100% !important;
    /* height: 243px;
        object-fit: contain; */
    padding: 5px;
    /* object-fit: contain; */
  }
  .CarsScreenTwo_inactive-background-images__2MP9B img,
  .CarsScreenTwo_active-background-images__1to5x img {
    height: 45px;
    width: 45px;
  }
  .CarsScreenTwo_choose-angle__1jY1J {
    padding: 10px 15px;
    width: auto !important;
    grid-gap: 20px !important;
  }
  .CarsScreenTwo_angle-selected__1wB_d {
    padding: 10px 15px;
    width: auto !important;
    grid-gap: 20px !important;
  }
  .CarsScreenTwo_choose-angle__1jY1J button,
  .CarsScreenTwo_angle-selected__1wB_d button {
    width: 50px;
  }
  .CarsScreenTwo_upload-button__3csVs button {
    margin-left: 0px;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe {
    padding: 0px;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe div:nth-child(1) {
  }
  .CarsScreenTwo_pricing-plan__Sqz4X {
  }
  .CarsScreenTwo_upload-button__3csVs {
    position: absolute;
    left: 270px;
  }
  .CarsScreenTwo_upload-button__3csVs button {
    width: auto;
    height: 35px;
    background: #0579df;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px;
    color: #ffffff;
  }
  .CarsScreenTwo_upload-logo-button__3bMKc button {
    /* width: 105.98px; */
    height: 35px;
    left: 174.18px;
    top: 673.2px;
    background: #0579df;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }
  .CarsScreenTwo_logo-select__1bVu9 {
    margin-left: 22px;
  }
  .CarsScreenTwo_logo-image__UddxA {
    height: auto;
    width: 96.97px;
  }
  .CarsScreenTwo_logo-image__UddxA p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    color: #888888;
  }
  .CarsScreenTwo_credit-para__1oFDq {
    position: relative;
    top: -4%;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 45px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    color: #ffffff;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
  }
  .CarsScreenTwo_bottom-apply-area__HXT_m p:nth-child(2) {
    display: none;
  }

  .CarsScreenTwo_center-title2__3AwZK {
    margin-bottom: -30px;
  }
  .CarsScreenTwo_pricing-plan-area__1GvAJ {
    border-right: 0;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe p:nth-child(2) {
    line-height: normal;
    line-height: initial;
    margin: 0 0 10px;
  }
  .CarsScreenTwo_right-bottom-section__3BfUe p:nth-child(1) {
    line-height: normal;
    line-height: initial;
    margin-bottom: 10px;
  }
  .CarsScreenTwo_bottom-contact-badge__3zWSi {
    text-align: center;
    background: #dee2e6;
    padding: 5px 15px;
    border-radius: 0px 0px 11px 10px;
    margin: 0px 15px;
    margin-bottom: 50px;
  }
  .CarsScreenTwo_bg_img-texts__18P1U {
    margin-left: 0px !important;
  }
  .CarsScreenTwo_email__1kaHd {
    position: relative !important;
    top: 0px !important;
    left: 0% !important;
    z-index: 999 !important;
    height: 0px !important;
    width: 100% !important;
  }
  .CarsScreenTwo_email__1kaHd form {
    background: rgba(98, 98, 98, 0.7);
    border-radius: 0px;
    text-align: center;
    align-items: center;
    padding: 20px;
  }
  .CarsScreenTwo_email__1kaHd img {
    width: 100% !important;
  }
  .CarsScreenTwo_email__1kaHd label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 0px;
  }
  .CarsScreenTwo_email__1kaHd input {
    height: auto;
  }
  .CarsScreenTwo_email__1kaHd button {
    width: 124px;
    height: 35px;
    background: #ffffff;
    border-radius: 9px;
    color: #616161;
  }
  .CarsScreenTwo_car-try-inner__36mQV {
    box-shadow: 0 0 0;
    padding-top: 20px;
  }
  .CarsScreenTwo_top-bar__6jY_e {
    padding: 0;
  }

  .CarsScreenTwo_backcredit__1y742 {
    display: none;
  }
  .CarsScreenTwo_back-credit-div__27L5m {
  }
  .CarsScreenTwo_back-credit__13omT {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 0 !important;

    padding: 0;
  }
  .CarsScreenTwo_backgrounds-grid__104lE .CarsScreenTwo_inactive-background-sec__YZfEz {
    position: relative;
  }
  .CarsScreenTwo_inactive-background-sec__YZfEz img {
    height: 47px;
  }
  .CarsScreenTwo_more-btn-overlay__PG9Dg {
    font-size: 12px;
  }

  .CarsScreenTwo_rmaining__1CNFe {
    padding: 10px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
  .CarsScreenTwo_rmaining__1CNFe span {
    color: #0579df;
  }
  .CarsScreenTwo_perfect-output__1WR5S {
    margin: 70px 0 0;
  }

  .CarsScreenTwo_download-popup__2Mei4 {
    border: 0;
    background: #ffffff;
    box-shadow: 0 0 0;
    padding: 20px 0;
  }

  .CarsScreenTwo_active-background-sec__Lwoer img {
    width: 100%;
    height: auto;
  }
  .CarsScreenTwo_inactive-background-sec__YZfEz {
    padding: 3px;
    border: 2px solid transparent;
  }

  .CarsScreenTwo_download-popup__2Mei4 {
    border: 0;
    background: #ffffff;
    box-shadow: 0 0 0;
    padding: 20px 0;
  }
  .CarsScreenTwo_backgrounds-grid__104lE {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    grid-gap: 9px;
    margin: 0 auto;
  }
  .CarsScreenTwo_checkbox-section__19zgh label {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
  .CarsScreenTwo_all-overlays__1h2s9 {
    padding: 15px 5px;
  }
  .CarsScreenTwo_select-sec__1ugU3 {
    display: none;
  }

  .CarsScreenTwo_active-background-sec-below__36WM2 img {
    width: 100%;
  }
  .CarsScreenTwo_apply-below-btn__2vSvi {
    width: 200%;
    text-align: center;
    padding: 10px 0px;
  }
  .CarsScreenTwo_bg-below-sec__2IdMb {
    padding: 15px 0px;
  }
  .CarsScreenTwo_output-apply-btn__36VQn {
    padding: 15px 0px;
  }
  .CarsScreenTwo_right-top-selection__MDunR {
    margin: 5px auto 20px;
  }
  .CarsScreenTwo_backgrounds-grid-custom__RynM1 .CarsScreenTwo_inactive-background-sec__YZfEz img {
    height: 35px;
  }
  .CarsScreenTwo_active-walls-sec__1G9R1 img {
    height: 35px;
  }
  .CarsScreenTwo_inactive-walls-sec__1VGA6 img {
    height: 35px;
  }
  .CarsScreenTwo_adjust-height-area__nTFyu ul li {
    width: 49%;
  }
}

@media screen and (max-width: 320px) and (min-width: 200px) {
  .CarsScreenTwo_bottom-apply-area__HXT_m button {
    width: 100%;
    position: fixed;
    top: 94%;
    z-index: 1000;
    left: 0;
    border-radius: 0;
  }
  .CarsScreenTwo_footer-left-text__24oUj h2 {
    font-size: 20px;
    line-height: 35px;
    margin-top: 0%;
  }
  .CarsScreenTwo_email__1kaHd input {
    height: auto;
    margin-bottom: 15px;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    width: 300px;
    justify-content: center !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 200px) {
  .CarsScreenTwo_box-text__tP3-R {
    padding-top: 41%;
    font-size: 14px;
    width: 70%;
    line-height: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 360px) {
  .CarsScreenTwo_box-text__tP3-R {
    padding-top: 32%;
    font-size: 22px;
    width: 70%;
  }
  .CarsScreenTwo_box-img__14FJx {
    width: 30% !important;
  }
  .CarsScreenTwo_original_image__1fx85 {
    width: 100% !important;
    height: auto;
    object-fit: contain !important;
    object-position: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 321px) {
  .CarsScreenTwo_bottom-apply-area__HXT_m button {
    width: 100%;
    position: fixed;
    top: 94%;
    z-index: 1000;
    left: 0;
    border-radius: 0;
  }
  .CarsScreenTwo_footer-left-text__24oUj h2 {
    font-size: 20px;
    line-height: 45px;
    margin-top: 0%;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    width: 400px;
    justify-content: center !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .CarsScreenTwo_footer__3IqkY {
    background: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5945+1.png);
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 10px 0px;
  }
  .CarsScreenTwo_footer-left-text__24oUj {
    font-size: 20px;
    line-height: 35px;
    margin-top: 0;
  }
  .CarsScreenTwo_footer-right-text__Ufkqa {
    padding: 10px 10px 0;
  }
  .CarsScreenTwo_footer-right-text__Ufkqa ul li {
    margin: 0px 10px;
    width: 10%;
    line-height: 27px;
  }
  .CarsScreenTwo_footer-right-text__Ufkqa ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  .CarsScreenTwo_footer-right-text__Ufkqa ul li img {
    padding: 5px;
    width: 100%;
  }
  .CarsScreenTwo_footer__3IqkY {
    height: 100%;
  }

  .CarsScreenTwo_footer-right-text__Ufkqa p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    justify-content: center !important;
  }
  .CarsScreenTwo_car-overlay-cat__3g-Z1 {
    border-right: 1px solid #ddd;
    padding: 0 5px;
  }
  .CarsScreenTwo_overlay-car-btn__1gVfY {
    padding: 0px 5px;
    display: inline-block;
    margin: 0 3px;
    width: 31%;
  }
  .CarsScreenTwo_car-try-btns__3AjC9 img {
  }
  .CarsScreenTwo_overlay-box-mob__M_CCM {
    display: flex;
    border-bottom: 1px solid #ddd;
  }
  .CarsScreenTwo_car-mobile-angle__3jF0M {
    padding: 0 10px 0.5rem;
    font-size: 12px;
    color: #ff7700;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  .CarsScreenTwo_car-overlay-cat-mob__MBQ-O {
    padding: 0;
  }
  .CarsScreenTwo_logo-sec__1SwG2 {
    display: none;
  }
  .CarsScreenTwo_lower-section-with-overlay-2__30w9C {
    display: block;
    grid-template-columns: 100%;
  }

  .CarsScreenTwo_overlays-click-btn__gdv2Y img {
    height: 40px;
    margin: 0 auto;
  }
  .CarsScreenTwo_credit-text__obqzQ {
    display: none;
  }
  .CarsScreenTwo_angle-sec__1etU3 {
    border-bottom: 0;
  }
  .CarsScreenTwo_overlays-click-btn__gdv2Y {
    margin: 5px 10px 5px 0px;
  }
  .CarsScreenTwo_all-overlays-slider__2NhGK {
    padding: 0;
  }
  .CarsScreenTwo_edited_image__2ob-m {
    height: 196px;
    width: 348px !important;
  }
  .CarsScreenTwo_with-image-inner-2__1ZSL6 img {
    height: 100%;
    object-fit: cover;
  }
  .CarsScreenTwo_left-section-with-image-2__22fhQ {
    width: 100%;
    padding: 15px 10px;
  }
  .CarsScreenTwo_mobile-uploads__FMhC9 {
    line-height: 35px;
    padding: 5px 15px 0;
  }
  .CarsScreenTwo_upload-sm-img__1JOka {
    float: right;
    border: 1px solid #ff7700;
    color: #ff7700 !important;
    background: #fff;
    padding: 5px 15px;
    border-radius: 3px;
    height: auto;
    width: 125px;
    line-height: 20px;
  }
  .CarsScreenTwo_left-section-with-image-2__22fhQ .CarsScreenTwo_mobile-uploads__FMhC9 {
    padding: 20px 5px;
    border-bottom: 1px solid #ddd;
    line-height: 30px;
  }
  .CarsScreenTwo_mobile-uploads__FMhC9 p span {
    font-size: 15px;
    line-height: 20px;
  }
  .CarsScreenTwo_toggle-section__VaPhn {
    display: none;
  }

  .CarsScreenTwo_mobile-uploads__FMhC9 p {
    margin: 0;
  }
  .CarsScreenTwo_background-list__1VgA6 {
    margin-top: 20px;
    padding: 15px 10px;
  }
  .CarsScreenTwo_upload-sec__3z85S {
    display: none;
  }
  .CarsScreenTwo_backgrounds-content__1pyGd {
    margin: 0;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .CarsScreenTwo_car-text__3wcNn h1 {
    font-size: 19px;
  }
  .CarsScreenTwo_box-text__tP3-R {
    padding-top: 15%;
    font-size: 35px;
    width: 70%;
  }
  .CarsScreenTwo_footer__3IqkY {
    height: auto;
  }
  .CarsScreenTwo_footer-right-text__Ufkqa ul {
    margin: 0;
  }
  .CarsScreenTwo_footer-left-text__24oUj h2 {
    margin-top: 28%;
  }
  .CarsScreenTwo_footer-right-text__Ufkqa ul li img {
    padding: 12px;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    width: 100%;
    justify-content: center !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 901px) {
  .CarsScreenTwo_footer-left-text__24oUj h2 {
    margin-top: 22%;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    justify-content: center !important;
  }
  .CarsScreenTwo_with-image-inner__yCMiX {
    width: 566.57px;
    height: 314px;
    border: 0.6px solid #77797b;
  }
  .CarsScreenTwo_left-section-with-image__16-3Q {
    width: 607.57px;
    height: 347px;
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
    padding: 21.87px 18.87px 10px;
  }
}
@media screen and (max-width: 1290px) and (min-width: 1025px) {
  .CarsScreenTwo_backgrounds__1BZpR {
    width: 300px;
    justify-content: center !important;
  }
  .CarsScreenTwo_car-overlay-cat__3g-Z1 {
    padding: 0 0 0 5px;
  }
  .CarsScreenTwo_overlay-car-btn__1gVfY {
    padding: 5px 0px;
  }
  .CarsScreenTwo_car-try-btns__3AjC9 span {
    margin-top: 0%;
  }

  .CarsScreenTwo_overlay-vertical__2C61r {
    height: 255px;
  }
  .CarsScreenTwo_download-popup__2Mei4 button {
    padding: 15px 60px;
  }
  .CarsScreenTwo_download-popup__2Mei4 {
    padding: 15px 30px;
  }
  .CarsScreenTwo_cross-btn__2Zmpe {
    padding: 0 0 20px;
  }
}

@media (min-width: 1400px) {
  .CarsScreenTwo_footer-left-text__24oUj h2 {
    margin-top: 15%;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    width: 100%;
    justify-content: flex-start !important;
  }
  .CarsScreenTwo_overlay-toggle__2UP59 {
    display: flex;
    margin-top: 0%;
  }
  .CarsScreenTwo_overlay-vertical__2C61r {
    height: 315px;
  }
}

@media (max-width: 1299px) {
  .CarsScreenTwo_footer-left-text__24oUj h2 {
    margin-top: 15%;
  }
  .CarsScreenTwo_backgrounds__1BZpR {
    width: 100%;
    justify-content: center !important;
  }
}

.CarsScreenTwo_container-2__3czio {
  display: block;
  position: relative;
  padding-left: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.CarsScreenTwo_container-2__3czio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.CarsScreenTwo_checkmark__2pnHj {
  position: absolute;
  top: 9%;
  left: 9%;
  height: 18px;
  width: 18px;
  border: 2px solid #b9b6b6;
}

/* On mouse-over, add a grey background color */
.CarsScreenTwo_container-2__3czio:hover input ~ .CarsScreenTwo_checkmark__2pnHj {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.CarsScreenTwo_container-2__3czio input:checked ~ .CarsScreenTwo_checkmark__2pnHj {
  background-color: #fff;
  border: 2px solid #ff7700;
}

/* Create the checkmark/indicator (hidden when not checked) */
.CarsScreenTwo_checkmark__2pnHj:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.CarsScreenTwo_container-2__3czio input:checked ~ .CarsScreenTwo_checkmark__2pnHj:after {
  display: block;
}

/* Style the checkmark/indicator */
.CarsScreenTwo_container-2__3czio .CarsScreenTwo_checkmark__2pnHj:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #ff7700;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.CarsScreenTwo_product-hunt-tag__62IHa {
  width: auto;
  /* top: 0; */
  position: absolute;
  right: 115px;
  top: 105px;
}
.CarsScreenTwo_product-hunt-tag2__3lKPR {
  text-align: center;
  margin: -10% 0 20px;
}
.CarsScreenTwo_product-hunt__HcXyb img {
  width: 250px;
  margin: 0 0 0 10px;
}

@media screen and (max-width: 800px) {
  .CarsScreenTwo_checkmark__2pnHj {
    left: 0;
    height: 15px;
    width: 15px;
  }
  .CarsScreenTwo_check-window__2qH6f {
    padding: 0;
  }
}
@media (min-width: 1366px) {
  .CarsScreenTwo_perfect-output__1WR5S {
    max-width: 93.5%;
    margin: 0 auto;
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1440px) {
  .CarsScreenTwo_perfect-output__1WR5S {
    max-width: 89%;
    margin: 0 auto;
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }
  .CarsScreenTwo_product-hunt-tag__62IHa {
    right: 20%;
  }
}

@media (min-width: 1680px) {
  .CarsScreenTwo_perfect-output__1WR5S {
    max-width: 76.3%;
    margin: 0 auto;
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1920px) {
  .CarsScreenTwo_perfect-output__1WR5S {
    max-width: 66.3%;
    margin: 0 auto;
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2560px) {
  .CarsScreenTwo_perfect-output__1WR5S {
    max-width: 50%;
    margin: 0 auto;
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2880px) {
  .CarsScreenTwo_perfect-output__1WR5S {
    max-width: 44.2%;
    margin: 0 auto;
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }
}

.CarsScreenTwo_superscript__13kLD {
  background-color: #ffdcbe;
  padding: 1px 2px;
  color: #ff7700;
  font-weight: 500;
  font-size: 9px;
  top: -1em;
}

.LoginModal_left-sec__tRtuU img{
    width: 100%;
}
.LoginModal_left-sec__tRtuU{
    text-align: center;
    margin-top: 0;
    border-right: 0;
    padding: 0px 50px;
}
.LoginModal_right-sec__3IIAq{
    margin-top: 5px;
    width: 100%;
    padding: 0px 50px;
}
.LoginModal_email__sxdFk{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #696F79;
}
.LoginModal_send-otp__3FS9b{
    width: 130px;
    height: 40px;
    background: #0579DF;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #FFFFFF;
    margin: 17px auto;
    padding-left: 30px;
    padding-top: 10px;
}
.LoginModal_otp-send-info__fd7Tn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4A4A4A;
}
.LoginModal_otp-input__jXX_V{
    width: 288.11px;
}
.LoginModal_verify__2xl3t{
    width: 150px;
    height: 40px;
    background: #0579DF;
    border-radius: 5px;
    margin: 17px auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding-left: 55px;
}
.LoginModal_verification__2ubp6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #4A4A4A;
}
.LoginModal_footwear-image__9gYtx{
    /* width: auto !important; */
    height: 221.71px !important;
    object-fit: contain;
}
.LoginModal_dynamic-logo-popup__1pxQy{
    width: 30%;
}
.LoginModal_dynamic-logo-popup__1pxQy img{
    width: 100%;
    height: auto;
}

.LoginModal_right-sec__3IIAq form input{
    width: 100%;
}

@media(max-width:800px){
    .LoginModal_left-sec__tRtuU img{
        width: 100%;
    }
    .LoginModal_left-sec__tRtuU{ 
        padding: 0px 15px;
    }
    .LoginModal_right-sec__3IIAq{ 
        padding: 0px 15px;
    }
    .LoginModal_email__sxdFk{
       
    }
    .LoginModal_otp-input__jXX_V{
        width: 100%;
    }
    .LoginModal_footwear-image__9gYtx{
        height: auto !important;
    }
    .LoginModal_modal-open__1Rab- .LoginModal_modal__3Uex3{
        padding: 0px 15px!important;
    }
}
.FootwearScreenTwo_upload-section__C837f{
    width: 95%;
    background: #FFFFFF;
    border: 1px dashed #0579DF;
    border-radius: 10px;
    margin: 0 auto 20px;
    text-align: center;
    padding: 10px;
}
.FootwearScreenTwo_screen-2-drop__3jQhD{
    width: 391.09px !important;
    height: 100%;
    outline: 0;
    border: 0;
}
.FootwearScreenTwo_upload-section-car__3kryZ{
    width: 95%;
    background: #FFFFFF;
    border: 1px dashed #0579DF;
    border-radius: 10px;
    margin: 0 auto 25px;
    text-align: center;
    padding: 10px;
}
.FootwearScreenTwo_screen-2-drop-car__1gaqR{
    width: 100%;
   
}
.FootwearScreenTwo_screen-2-drop-car__1gaqR:focus{
    outline: none;
}
.FootwearScreenTwo_right-section-background-section__w7-d-{
    width: 95%;
    height: auto;
    background: #FFFFFF;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: auto;
    overflow-x: hidden;
    margin: 0 auto;
}
.FootwearScreenTwo_backgrounds__1XWVc{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-gap: 10px;
    padding: 0px 30px;
    margin: 13px 0px;
    padding-bottom: 20px;
}
@media(max-width:800px){
    .FootwearScreenTwo_screen-2-drop__3jQhD{
        width: auto;
    }
    .FootwearScreenTwo_screen-2-drop-car__1gaqR{
        width: auto !important;
    }
    .FootwearScreenTwo_right-section-background-section__w7-d- { 
        margin-bottom: 20px;
    }
    .FootwearScreenTwo_backgrounds__1XWVc { 
        grid-gap: 0px;
        padding: 0px 10px;
        margin: 0px 0px 15px;
        padding-bottom: 20px;
    }
}
:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.CreditModal_credit-modal-text__2CC7B h4 {
  font-size: 24px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 10px;
}

.CreditModal_credit-modal-text__2CC7B p {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #5f5f5f;
  margin-top: 11px;
}

.CreditModal_credit-modal-text__2CC7B {
  margin-top: 0px;
}

.CreditModal_credit-modal-text__2CC7B button {
  border-radius: 26px;
  color: #fff;
  border: 1px solid #fff;
  background-color: #ff7700;
  margin: 20px 0px 0;
}

.CreditModal_credit-modal-text__2CC7B img {
  width: 60%;
  margin-top: 15px;
}

.CreditModal_credit-modal-text__2CC7B h6 {
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 0px;
  color: #000;
  margin-top: 10px;
}

@media (max-width: 768px) {
  /* .credit-modal-text h4 {
    font-size: 30px;
  }

  .credit-modal-text p {
    font-size: 20px;
  } */
}

:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.DownloadCompleteModal_download-img__2IHhV img {
  width: 100%;
  margin-bottom: 15px;
}

.DownloadCompleteModal_download-right__1XxPK h4 {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 15px;
}

.DownloadCompleteModal_download-right__1XxPK p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  margin-bottom: 10px;
}

.DownloadCompleteModal_download-right__1XxPK {
  padding-top: 0px;
}

.DownloadCompleteModal_gif-sec__2UP9h img {
  width: 125px;
}

.DownloadCompleteModal_download-right__1XxPK img {
  padding-right: 10px;
  cursor: pointer;
  width: auto !important;
}

.DownloadCompleteModal_success-cmnt__34AK- img {
  padding-right: 15px;
  width: 6%;
}

.DownloadCompleteModal_success-cmnt__34AK- p {
  font-size: 25px;
  margin-bottom: 0;
}

.DownloadCompleteModal_close-btn__2BMwr {
  cursor: pointer;
}

.DownloadCompleteModal_cnmt-sec__2stBE h6 {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #ff7700;
}

.DownloadCompleteModal_cnmt-sec__2stBE p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  margin-bottom: 20px;
  color: #5f5f5f;
}

.DownloadCompleteModal_cnmt-sec__2stBE textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px !important;
  min-height: 150px;
}

.DownloadCompleteModal_cnmt-sec__2stBE button {
  background-color: #ff7700;
  color: #fff;
  width: 380px;
  height: 52px;
  font-size: 18px;
  margin: 25px 0;
  border-radius: 25px;
}

::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #7c7c7c;
}

:-ms-input-placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #7c7c7c;
}

::placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #7c7c7c;
}

@media (max-width: 768px) {
  .DownloadCompleteModal_download-right__1XxPK {
    padding-top: 0px;
  }
  .DownloadCompleteModal_download-right__1XxPK h4 {
    font-size: 20px;
  }
  .DownloadCompleteModal_success-cmnt__34AK- img {
    padding-right: 11px;
    width: 11%;
  }
  .DownloadCompleteModal_success-cmnt__34AK- p {
    font-size: 18px;
  }

  .DownloadCompleteModal_cnmt-sec__2stBE button {
    background-color: #ff7700;
    color: #fff;
    width: 245px;
    height: 42px;
    font-size: 17px;
    margin: 25px 0;
    border-radius: 25px;
    padding: 0;
  }

  .DownloadCompleteModal_cnmt-sec__2stBE h6 {
    font-size: 20px;
  }

  .DownloadCompleteModal_cnmt-sec__2stBE p {
    font-size: 12px;
  }
  .DownloadCompleteModal_cnmt-sec__2stBE textarea {
    margin-top: 0px !important;
  }
}

:root {
  --primaryColor: #ff7700;
}
.BulkProcessingV2_bulk-try__1Jlgg {
  padding: 80px 0;
}

.BulkProcessingV2_change-tabs__dcX8A {
  padding: 10px 0 20px;
  display: flex;
  justify-content: left;
  list-style: none;
  margin-left: 10px;
}
.BulkProcessingV2_change-tab2__20AZH {
  padding: 10px 0 0 !important;
  border-bottom: 1px solid #ddd;
}
.BulkProcessingV2_change-tabs__dcX8A li {
  padding: 0;
}
.BulkProcessingV2_right-top-credits__3GCJY {
  text-align: left;
  padding: 0;
}
.BulkProcessingV2_right-top-credits__3GCJY button {
  padding: 11px 9px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.94) 0%,
      rgba(255, 255, 255, 0.24) 52.08%,
      rgba(255, 255, 255, 0) 52.09%,
      rgba(255, 255, 255, 0.29) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 51.56%
    ),
    #ff7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12),
    inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.BulkProcessingV2_right-top-credits__3GCJY img {
  width: 25px !important;
}
.BulkProcessingV2_add-credit__1woNq {
  color: white !important;
}
.BulkProcessingV2_add-credit__1woNq img {
}
.BulkProcessingV2_change-tabs__dcX8A li button {
  background: transparent;
}
.BulkProcessingV2_change-tabs__dcX8A li button {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  border-radius: 0;
  border-bottom: 2px solid transparent;
}
.BulkProcessingV2_change-tabs__dcX8A li button.BulkProcessingV2_active__bXbyN,
.BulkProcessingV2_change-tabs__dcX8A li button:hover,
.BulkProcessingV2_change-tabs__dcX8A li button.BulkProcessingV2_activate__3IpB3 {
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700;
}

.BulkProcessingV2_tab-content__2juBw {
  overflow: hidden;
  height: 550px;
  overflow-y: scroll;
  margin: 0;
  padding: 0px 0px 22px;
  /* background: #FFE7D2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.BulkProcessingV2_tab-content__2juBw h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  margin-bottom: 30px;
}
.BulkProcessingV2_tab-content__2juBw .BulkProcessingV2_add-section__9VhW4 {
  display: contents;
  margin-left: 10px;
  margin-bottom: 20px;
}
.BulkProcessingV2_tab-content__2juBw .BulkProcessingV2_add-section__9VhW4 button {
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  width: 26px;
  height: 26px;
  -webkit-filter: drop-shadow(0px 0px 11.4706px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 0px 11.4706px rgba(0, 0, 0, 0.15));
  line-height: 100%;
  font-size: 14px;
}
.BulkProcessingV2_tab-content__2juBw .BulkProcessingV2_add-section__9VhW4 h6 {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #555555;
}
.BulkProcessingV2_add-more-hover-box__1c1PN {
  display: none;
}
.BulkProcessingV2_add-section__9VhW4 h6 span {
  color: #ec4848;
}
.BulkProcessingV2_uploaded-cars__2fEEE {
}
.BulkProcessingV2_uploaded-cars-single__3gSSG {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  margin: 0 0 20px;
  padding: 5px 0px;
}
.BulkProcessingV2_uploaded-cars-single__3gSSG img {
  width: 100%;
  padding: 0;
  height: 105px;
  object-fit: cover;
  cursor: pointer;
}
.BulkProcessingV2_uploaded-cars-single__3gSSG p {
  text-align: center;
  padding: 5px 5px;
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BulkProcessingV2_reload-cancel__2Q2cH {
  text-align: right;
  justify-content: flex-end;
  padding: 0;
  height: 30px;
  align-items: center;
  padding: 0 10px;
}
/* .reload-cancel img {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0 0 5px 10px;
} */
.BulkProcessingV2_reload-cancel__2Q2cH span img {
}
.BulkProcessingV2_reload-cancel__2Q2cH {
  opacity: 1;
}

.BulkProcessingV2_uploaded-cars-single__3gSSG:hover .BulkProcessingV2_reload-cancel__2Q2cH {
  opacity: 1;
}

.BulkProcessingV2_reload-cancel2__2z5iT {
  text-align: right;
  justify-content: flex-end;
  padding: 0;
  height: 20px;
  align-items: center;
}
.BulkProcessingV2_reload-cancel2__2z5iT img {
  /* padding: 5px; */
  /* background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%; */
  height: 35px;
  width: 35px;
  margin: 0 0 5px 10px;
}
.BulkProcessingV2_reload-cancel2__2z5iT span svg {
  position: absolute;
  right: 0%;
  top: -6%;
  margin: 0;
  cursor: pointer;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 2px 5px 0px #ddd;
  padding: 5px;
  height: 25px;
  width: 25px;
  border-radius: 100px;
}
.BulkProcessingV2_bulk-right-box__2rRyH {
  background: #ffffff;
  box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.BulkProcessingV2_credits-available__cUSsl {
  padding: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* text-align: center; */
  letter-spacing: 0.5px;

  color: #232121;
  border-bottom: 1px solid #ebeaea;
  margin-left: 0px;
}

.BulkProcessingV2_credits-available__cUSsl span {
  color: #ff7700;
}
.BulkProcessingV2_credits-available__cUSsl img {
  margin-right: 10px;
  width: 35px;
}
.BulkProcessingV2_credits-available__cUSsl sub {
  background: #ff7700;
  border: 2px solid #ffffff;
  padding: 3px;
  color: #fff;
  font-weight: 500;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 8px;
  margin-left: -5%;
  bottom: -5px;
}
.BulkProcessingV2_credits-available2__2LydN {
  padding: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* text-align: center; */
  letter-spacing: 0.5px;

  color: #232121;
  margin-left: 0px;
}
.BulkProcessingV2_credits-available2__2LydN span {
  color: #ff7700;
}
.BulkProcessingV2_credits-available2__2LydN img {
  margin-right: 10px;
  width: 35px;
}
.BulkProcessingV2_credits-available2__2LydN sub {
  background: #ff7700;
  border: 2px solid #ffffff;
  padding: 3px;
  color: #fff;
  font-weight: 500;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 8px;
  margin-left: -5%;
  bottom: -5px;
}
.BulkProcessingV2_uploadImg__3hXXC {
  padding-left: 35% !important;
  border-bottom: 1px solid #ebeaea;
}

.BulkProcessingV2_bulk-accordion__1kF09 {
  padding: 12px;
  border-bottom: 1px solid #ebeaea;
}

.BulkProcessingV2_background-grid__3mGQo {
  padding: 0 5px;
}

.BulkProcessingV2_background-grid__3mGQo img {
  width: 100%;
  height: auto;
  padding: 0;
}
.BulkProcessingV2_background-grid__3mGQo h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.5px;

  color: #232121;
}
.BulkProcessingV2_back-credit__22lM1 {
}
.BulkProcessingV2_back-credit__22lM1 {
  font-weight: 500;
  font-size: 11px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  /* padding: 5px 0; */
}
.BulkProcessingV2_back-credit__22lM1 span {
  float: right;
  font-size: 10px;
}
.BulkProcessingV2_bulk-bg__2u5Wd {
  margin: 0;
}
.BulkProcessingV2_bulk-bg__2u5Wd img {
  margin-bottom: 10px;
}
.BulkProcessingV2_back-credit__22lM1 span img {
  width: 10px;
}
[role='button'] {
  cursor: pointer;
  outline: 0;
}
.BulkProcessingV2_logo-sec__19TJQ {
  border-bottom: 1px solid #ebeaea;
  padding: 12px;
}
.BulkProcessingV2_bulk-accordion__1kF09 h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  margin: 0 0 20px;
  letter-spacing: 0.5px;

  color: #232121;
}
.BulkProcessingV2_checkbox-section__2UCux {
  padding: 20px 0;
}
.BulkProcessingV2_checkbox-section__2UCux label {
  font-size: 14px;
  color: #232121;
  font-weight: 600;
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.BulkProcessingV2_container-2__3f64l input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.BulkProcessingV2_container-2__3f64l:hover input ~ .BulkProcessingV2_checkmark__2Ow5L {
  background-color: #ccc;
}
.BulkProcessingV2_checkmark__2Ow5L {
  position: absolute;
  top: 9%;
  left: 9%;
  height: 18px;
  width: 18px;
  border: 2px solid #b9b6b6;
}
.BulkProcessingV2_container-2__3f64l input:checked ~ .BulkProcessingV2_checkmark__2Ow5L {
  background-color: #fff;
  border: 2px solid #f70;
}
.BulkProcessingV2_container-2__3f64l input:checked ~ .BulkProcessingV2_checkmark__2Ow5L:after {
  display: block;
}

.BulkProcessingV2_container-2__3f64l .BulkProcessingV2_checkmark__2Ow5L:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #f70;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.BulkProcessingV2_checkmark__2Ow5L:after {
  content: '';
  position: absolute;
  display: none;
}
.BulkProcessingV2_apply-btn-section__2oC6e {
  text-align: center;
  padding: 20px;
}
.BulkProcessingV2_apply-btn__3SIi9,
.BulkProcessingV2_apply-btn-section__2oC6e button {
  background: #ff7700;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  width: 100%;
}
.BulkProcessingV2_add-more__1vhoz {
  position: absolute;
  right: 20px;
  font-size: 30px;
  z-index: 1;
  background: #ff7700;
  font-weight: 100;
  color: #fff;
  width: 60px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
  top: 11%;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  cursor: pointer;
}
.BulkProcessingV2_add-more__1vhoz span {
}
.BulkProcessingV2_background-grid__3mGQo h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.5px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin: 20px 0px;
  color: #ff7700;
}

/* Payment Success Page Css */

.BulkProcessingV2_payment-success__trwtf {
  padding: 0;
  height: 100vh;
}
.BulkProcessingV2_payment-success__trwtf img {
  width: 100%;
}
.BulkProcessingV2_payment-success__trwtf h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;
  letter-spacing: 0.5px;

  color: #232121;
}
.BulkProcessingV2_payment-success__trwtf p {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 0 25px;
  color: #5f5f5f;
}
.BulkProcessingV2_payment-success__trwtf h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 0 15px;
  color: #ff7700;
}
.BulkProcessingV2_payment-success__trwtf h6 span {
  color: #5f5f5f;
}
.BulkProcessingV2_payment-success-content__3mchF {
  padding: 0 40px;
}
.BulkProcessingV2_proceed-btn__2DDNN {
  background: #ff7700;
  border-radius: 31px;
  margin-top: 26px;
  min-width: 180px;
}
.BulkProcessingV2_proceed-btn__2DDNN a {
  color: #fff;
}
/* Popups Css */
.BulkProcessingV2_popup-content__2Sgur {
  padding: 0px 0px 20px;
}
.BulkProcessingV2_popup-content__2Sgur h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #ff7700;
  margin: 0;
}

.BulkProcessingV2_popup-content__2Sgur img {
  width: 25%;
}
.BulkProcessingV2_popup-content__2Sgur p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 27px; */
  text-align: center;
  margin: 15px 0px;
  color: #232121;
}
.BulkProcessingV2_popup-content__2Sgur p span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #999696;
}
.BulkProcessingV2_popup-content__2Sgur h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #ff7700;
}
.BulkProcessingV2_popup-content__2Sgur h6 img {
  margin-left: 10px;
  width: auto;
  transition: 0.3s ease-in-out;
}
.BulkProcessingV2_popup-content__2Sgur h6:hover img {
  margin-left: 20px;
}
.BulkProcessingV2_proceed-btn2__2nSPX {
  background: #fff;
  color: #ff7700;
  border-radius: 31px;
  margin-top: 26px;
  min-width: 180px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
}
.BulkProcessingV2_proceed-btn2__2nSPX:hover {
  color: #ff7700;
}
.BulkProcessingV2_popup-content__2Sgur h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  /* color: #ff7700; */
}
.BulkProcessingV2_popup-content__2Sgur h3 span {
  color: #232121;
}
.BulkProcessingV2_bulk-bg__2u5Wd button {
  border-radius: 5px;
  width: auto;
  margin: 10px auto;
}
.BulkProcessingV2_bulk-bg__2u5Wd {
  text-align: center;
}

.BulkProcessingV2_light-item__246sw {
  padding: 0 !important;
}
.BulkProcessingV2_react_lightgallery_item__1K-gH {
  padding: 0 !important;
}
.BulkProcessingV2_popup-content__2Sgur h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #232121;
  margin: 0 0 25px;
}
.BulkProcessingV2_popup-content__2Sgur h4 span {
  border-bottom: 4px solid #ff7700;
  padding-bottom: 5px;
}
.BulkProcessingV2_popup-content__2Sgur ul {
  list-style: none;
  padding: 10px 0;
}
.BulkProcessingV2_popup-content__2Sgur ul li {
  display: inline-block;
  padding: 20px 5px;
}
.BulkProcessingV2_popup-content__2Sgur ul li span {
  border: 1px solid #999696;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 10px;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #999696;
}
.BulkProcessingV2_popup-content__2Sgur textarea {
  /* border: none; */
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #999696 !important;
  min-height: 100px !important;
}

.BulkProcessingV2_popup-text__2K_z7 p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #5f5f5f;
}

/* 360 View Tab page csss  */

.BulkProcessingV2_sixty-link__3qKBV {
}
.BulkProcessingV2_sixty-content__2f_H1 {
}

.BulkProcessingV2_sixty-link-copy__2EnCY {
}
.BulkProcessingV2_sixty-link-copy__2EnCY h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: #232121;
}
.BulkProcessingV2_sixty-link-copy__2EnCY h4 img {
  width: 30px;
  margin-right: 10px;
}
.BulkProcessingV2_sixty-link-copy__2EnCY p input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px 0px 0px 5px;
  height: 45px;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 70%;
  color: #292929;
}
.BulkProcessingV2_sixty-link-copy__2EnCY p button {
  border-radius: 0 5px 5px 0;
  height: 45px;
  width: 30%;
  float: right;
}
.BulkProcessingV2_sixty-link-copy__2EnCY textarea {
  width: 100%;
  padding: 15px 10px;
  color: #232121;
  border: 1px solid #ddd;
  background: #f0f0f0;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 105px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.BulkProcessingV2_sixty-satisfy__3x7BL p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;

  color: #232121;
}
.BulkProcessingV2_download-complete__2gO5u {
}
.BulkProcessingV2_download-complete__2gO5u img {
  width: 50%;
}

.BulkProcessingV2_download-complete__2gO5u h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #232121;
}
.BulkProcessingV2_upload-image__1DwvS {
  text-align: center;
  padding: 20px 0;
}
.BulkProcessingV2_upload-image__1DwvS button img {
  width: 20px;
  margin-right: 10px;
}

.BulkProcessingV2_perfect-output__1_KsG {
  margin: 80px 0 50px;
  /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%);
    background-color: #fff; */
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.BulkProcessingV2_wallet-icon__1smqa svg {
  margin-right: 10px;
}

@media (min-width: 1366px) {
  .BulkProcessingV2_perfect-output__1_KsG {
    max-width: 93.5%;
    margin: 0 auto;
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1440px) {
  .BulkProcessingV2_perfect-output__1_KsG {
    max-width: 89%;
    margin: 0 auto;
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1680px) {
  .BulkProcessingV2_perfect-output__1_KsG {
    max-width: 76.3%;
    margin: 0 auto;
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1920px) {
  .BulkProcessingV2_perfect-output__1_KsG {
    max-width: 66.3%;
    margin: 0 auto;
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2560px) {
  .BulkProcessingV2_perfect-output__1_KsG {
    max-width: 50%;
    margin: 0 auto;
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2880px) {
  .BulkProcessingV2_perfect-output__1_KsG {
    max-width: 44.2%;
    margin: 0 auto;
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

.BulkProcessingV2_backgrounds-grid__26yHC {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 9px;
}

.BulkProcessingV2_active-background-sec__29JqH img {
  width: 100%;
  height: 75px;
  border-radius: 5px;
}
.BulkProcessingV2_bg-below-sec__344Lw {
  justify-content: center;
  margin: 0;
  padding: 5px 60px 0;
}
.BulkProcessingV2_inactive-background-sec__EwDQT img {
  width: 100%;
  height: 75px;
  cursor: pointer;
  border-radius: 5px;
}

.BulkProcessingV2_back-credit__22lM1 {
  text-align: end;
  font-size: 12px;
  padding-top: 11px;
  margin-bottom: 0 !important;
}

.BulkProcessingV2_bcredit__23HP8 {
  display: none;
}

.BulkProcessingV2_back-name__2WnWD {
  font-size: 11px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0 !important;
}

.BulkProcessingV2_back-credit__22lM1 img {
  width: auto !important;
  height: auto !important;
  padding-left: 4px;
  border: 0 !important;
}

.BulkProcessingV2_active-background-sec-below__3SRLi {
  padding: 5px 5px 0;
  cursor: pointer;
}
.BulkProcessingV2_active-background-sec-below__3SRLi img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}
.BulkProcessingV2_inactive-background-sec-below__1g0bq img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}
.BulkProcessingV2_overlay_edit__rwGly {
  position: relative !important;
}

.BulkProcessingV2_overlay-img__2KqgX {
  /* object-fit: scale-down !important; */
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: black !important;

  z-index: 91;
  object-fit: contain !important;
  height: 60% !important;
  margin-top: 10%;
}
.BulkProcessingV2_lower-section-with-overlay__2XPoI {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.BulkProcessingV2_continue-btn__3SZfW {
  display: flex;
  justify-content: center;
  padding: 2%;
}

.BulkProcessingV2_overlay-pos__3AxO1 {
  position: relative;
}

.BulkProcessingV2_img-overlay__2tUCn {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  );
  height: 100%;
  bottom: 0%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
}

.BulkProcessingV2_more-btn-overlay__Jtmri {
  position: absolute;
  top: 32%;
  left: 15%;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.BulkProcessingV2_add-more__1vhoz button {
  padding: 0 !important;
}

.BulkProcessingV2_inactive-background-sec__EwDQT {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 5px;
  cursor: pointer;
}
.BulkProcessingV2_active-background-sec__29JqH {
  box-shadow: 0 2px 5px 0 #ddd;
  padding: 3px;
  border: 2px solid #333;
  -webkit-transform: scale(1);
          transform: scale(1);
  cursor: pointer;
}

.BulkProcessingV2_modal-header__AAu2y {
  background-color: #ff7700;
  border-radius: 5px 5px 0px 0px;
}

.BulkProcessingV2_create-project__EM7oP {
  text-align: center;
}

.BulkProcessingV2_modal-header__AAu2y h5 {
  padding-left: 29px;
  color: #fff;
  font-weight: 500;
  padding-top: 10px;
}

.BulkProcessingV2_modal-header__AAu2y img {
  margin-right: 8px;
  width: 17%;
  margin-bottom: 3px;
}

.BulkProcessingV2_download-card-gif__1pTfK {
}

.BulkProcessingV2_upload-car-name-gif__1NS1t img {
  width: 40%;
  max-height: 20px;
}

.BulkProcessingV2_apply-btn-section__2oC6e h6 {
  font-size: 18px;
  color: #2b2a2a;
  font-weight: 500;
}

.BulkProcessingV2_apply-btn-section__2oC6e p {
  font-size: 16px;
  color: #646464;
  font-weight: 400;
}

.BulkProcessingV2_apply-btn-section__2oC6e svg {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0 5px;
}

.BulkProcessingV2_btn-book__QZZOH {
  width: auto;
  height: 45px;
  margin: 9px;
  cursor: pointer;
  background-color: #fff !important;
  border: 1px solid #a1afb7 !important;
  color: #999696;
  font-size: 14px;
  border-radius: 100px;
}

.BulkProcessingV2_btn-book-selected__3RsWF {
  width: auto;
  height: 45px;
  margin: 9px;
  cursor: pointer;
  border: 1px solid #a1afb7 !important;
  font-size: 14px;
  border-radius: 100px;
  background-color: #a1afb7;
  color: #000;
}

.BulkProcessingV2_btn-book__QZZOH:hover {
  background-color: #a1afb7 !important;
  color: #000 !important;
}
.BulkProcessingV2_choose-button__SZAJQ {
}
.BulkProcessingV2_choose-button__SZAJQ button {
  padding: 10px 20px;
  margin-top: 20px;
}

.BulkProcessingV2_container-fluid__LXWd9 {
  max-width: 100% !important;
  padding: 0 15px !important;
}

.BulkProcessingV2_cross-popup__2zFtd {
  cursor: pointer;
}

.BulkProcessingV2_add-more__1vhoz:hover + .BulkProcessingV2_more-text__8VsRh {
  display: block !important;
}

.BulkProcessingV2_more-text__8VsRh {
  position: absolute;
  z-index: 999;
  left: 72%;
  top: 13.5%;
  display: none;
}
.BulkProcessingV2_interior-null__2tjSt {
  background: #e5e8f2;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 10px 0;
  min-height: 250px;
}

.BulkProcessingV2_info-null__3CgZg {
  background: #e0f3ed;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 10px 0;
  min-height: 250px;
  margin-top: 15px;
}

.BulkProcessingV2_exterior-notnull__3yeU3 {
  background: #ffe7d2;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 10px 0px;
}
.BulkProcessingV2_null-text__3Gobp {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  text-align: center;
}

.BulkProcessingV2_red-strip__3E5uj {
  width: 100%;
  background-color: #e46c6c;
  text-align: center;
}

.BulkProcessingV2_red-strip__3E5uj h6 {
  font-size: 16px;
  color: #fff;
  padding: 30px 0px;
}

.BulkProcessingV2_green-strip__2688b {
  width: 100%;
  background-color: #6ce49a;
  text-align: center;
}

.BulkProcessingV2_green-strip__2688b h6 {
  font-size: 16px;
  color: #fff;
  padding: 30px 0px;
}

.BulkProcessingV2_progress-strip__1_8n6 {
  margin: 5% 10%;
}

.BulkProcessingV2_try-drag-drop-1__10cWl {
  padding-bottom: 30px;
}

.BulkProcessingV2_progress-strip__1_8n6 h4 {
  text-align: center;
  margin-top: 50px;
  font-weight: 800;
}

.BulkProcessingV2_download-card-gif-1__1_Of6 {
  width: 100%;
  display: block;
  text-align: center;
  margin: 2%;
}

.BulkProcessingV2_download-card-gif-1__1_Of6 img {
  width: 19%;
}

.BulkProcessingV2_button-sec__4n4j2 {
  text-align: center;
  padding: 25px 0;
}

.BulkProcessingV2_button-sec__4n4j2 h4 {
  font-size: 16px;
  color: #ff7700;
  text-decoration: underline;
  cursor: pointer;
}
.BulkProcessingV2_uploadImg__3hXXC {
  padding-top: 10%;
}

.BulkProcessingV2_apply-btn__3SIi9,
.BulkProcessingV2_apply-btn-section-1__2wNvB button {
  background: #ff7700;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 35px; */
  color: #ffffff;
  width: 250px;
  height: 50px;
}

.BulkProcessingV2_apply-btn-section-1__2wNvB {
  text-align: center;
  margin: 80px 0;
}

.BulkProcessingV2_project-details-box__2yyOO {
  border: 1px solid gray;
  padding: 14px;
  border-radius: 8px;
  margin-top: 40px;
}

.BulkProcessingV2_project-details-box__2yyOO h4 {
  color: #999696;
  font-size: 15px;
  padding-top: 15px;
}

.BulkProcessingV2_project-details-box__2yyOO h6 {
  color: #000;
  font-size: 12px;
}

.BulkProcessingV2_project-head__1aGvg {
  color: #000;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}

.BulkProcessingV2_bulk-right-box-1__2qZGT {
  background: #ffffff;
  box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.15);
  /* height: 100%; */
}

.BulkProcessingV2_exterior-notnull-1__2CiCP {
  /* background: #ffe7d2;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  padding: 10px 0px;
}

.BulkProcessingV2_bulk-header__1Dw4L {
  color: #000;
  font-size: 34px;
  text-align: center;
  font-weight: 600;
}

.BulkProcessingV2_progress-strip-1__33EUG {
  margin: 2% 10%;
}

.BulkProcessingV2_progress-strip-1__33EUG h4 {
  text-align: center;
  margin-top: 20px;
  font-weight: 800;
}

.BulkProcessingV2_manual-editing__2LYMV {
  margin-top: 20px;
}

/* .dealership-logo-section-wrapper{
    border: 2px solid red;
    margin: 0px 21.39px;
} */
.dealerhipLogo_right-mid-setion-content__3jkqQ {
  display: flex;
  text-align: center;
  justify-content: space-around;
  width: 100%;
  margin: 0;
  margin-top: 20px;
}
.dealerhipLogo_logo-image__2GwuD p {
  margin: 10px 0 0;
  display: inline;
  display: initial;
  font-size: 14px;
}
.dealerhipLogo_logo-image__2GwuD {
  height: 50px;
  background: #e4e4e4;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  padding: 12px 0px;
}
.dealerhipLogo_logo-image__2GwuD img {
  height: -webkit-fill-available !important;
}
.dealerhipLogo_upload-button__1DwiZ {
  width: 140px;
  height: 40px;
  border-color: #ff7700;
  border-radius: 5px;
  margin-top: 38px;
  margin-left: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ff7700;
  border: 2px solid #ff7700;
}
.dealerhipLogo_upload-button__1DwiZ button:hover {
  color: #ff7700 !important;
}
.dealerhipLogo_upload-logo-button__HyrND button:hover {
  color: #ff7700 !important;
}
.dealerhipLogo_logo-select__1pJvD {
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}
.dealerhipLogo_logo-select__1pJvD input {
  margin-top: 5px;
}
.dealerhipLogo_left-top-logo-preview__3CzQU {
  position: absolute;
  top: 5%;
  width: 100px;
  /* height: 0; */
  left: 13%;
}
.dealerhipLogo_left-bottom-logo-preview__2wDim {
  position: absolute;
  top: 43%;
  width: 100px;
  /* height: 0; */
  left: 13%;
}
.dealerhipLogo_right-top-logo-preview__2fiNE {
  position: absolute;
  top: 5%;
  right: 42.8%;
  width: 100px;
}
.dealerhipLogo_right-bottom-logo-preview__1vb5z {
  position: absolute;
  right: 42.8%;
  width: 100px;
  /* height: 0; */
  top: 43%;
}
.dealerhipLogo_left-top-logo-preview__3CzQU img,
.dealerhipLogo_left-bottom-logo-preview__2wDim img,
.dealerhipLogo_right-top-logo-preview__2fiNE img,
.dealerhipLogo_right-bottom-logo-preview__1vb5z img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.dealerhipLogo_small-image-checkboxes__nG_JX {
  padding: 0;
}

input.dealerhipLogo_checkboxField__3ef9C[type='checkbox']:checked {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  outline: 0;
}
.dealerhipLogo_checkboxField__3ef9C:before {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 0;
  border-radius: 0;
  background-color: white;
  border-color: transparent;
  box-sizing: border-box;
  color: grey;
  content: close-quote;
  display: inline-block;
  height: 13px;
  outline: 1px solid grey;
  transition-duration: 0.5s;
  transition-property: background-color, border-color;
  width: 13px;
  position: absolute;
}

.dealerhipLogo_checkboxField__3ef9C:checked:before {
  background-color: grey;
  border-color: grey;
}

/*== Moving checkbox Icons ==*/
.dealerhipLogo_sm-car-checkbox-1__3nWxm {
  width: 30px;
  height: 2px;
  margin: 5px 0 0;
  border-bottom: 1px solid grey;
}
.dealerhipLogo_sm-car-checkbox-2__7qkYR {
  width: 2px;
  height: 40px;
  margin: 0 0px 0 5px;
  border-left: 1px solid grey;
}

.dealerhipLogo_sm-car-checkbox-3__nWavq {
  width: 2px;
  height: 40px;
  border-left: 1px solid grey;
  margin-left: 50px;
  margin-top: -50px;
}
.dealerhipLogo_sm-car-checkbox-4__2jOn8 {
  width: 30px;
  height: 2px;
  border-bottom: 1px solid grey;
  margin-top: 5px;
}

.dealerhipLogo_logo-text__3y1oX {
  font-size: 14px;
  font-family: poppins;
  text-align: left;
  font-weight: 600;
}

.dealerhipLogo_logo-text__3y1oX span {
  font-size: 10px;
}

.dealerhipLogo_logo-pos-text__1IsXQ {
  font-size: 14px;
  font-family: poppins;
  text-align: left;
  font-weight: 600;
}

.dealerhipLogo_logo-image__2GwuD .dealerhipLogo_cross-logo__3KEYa {
  width: 10px;
  height: 10px !important;
  position: absolute;
  top: 42%;
  right: 11%;
  z-index: 999;
  cursor: pointer;
}

/* ==//Moving checkbox Icons== */

@media (max-width: 800px) {
  .dealerhipLogo_right-mid-section__9eOEh {
    margin-top: 0px !important;
  }
  .dealerhipLogo_right-mid-setion-content__3jkqQ {
    justify-content: space-around !important;
  }
  .dealerhipLogo_logo-image__2GwuD {
    order: 1;
    padding: 12px 5px 5px;
  }
  .dealerhipLogo_logo-image__2GwuD p {
    /* font-size: 14px; */
  }
  .dealerhipLogo_upload-logo-button__HyrND {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .dealerhipLogo_small-image-checkboxes__nG_JX {
    padding-left: 70px;
    order: 2;
    text-align: right;
  }
  /* .left-top-logo-preview, .left-bottom-logo-preview, .right-top-logo-preview, .right-bottom-logo-preview{
        display: none;
    } */
}

@media screen and (max-width: 767px) and (min-width: 200px) {
  .dealerhipLogo_upload-logo-button__HyrND {
    order: 3;
    margin: 10px 0px 0px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .dealerhipLogo_upload-logo-button__HyrND {
    order: 3;
    margin: 0;
    text-align: right;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 320px) and (min-width: 200px) {
  .dealerhipLogo_small-image-checkboxes__nG_JX {
    padding-left: 45px !important;
  }
  .dealerhipLogo_logo-image__2GwuD {
    padding: 3px 5px 5px !important;
    margin-top: 5px;
  }
  .dealerhipLogo_left-top-logo-preview__3CzQU {
    top: 42%;
    width: 60px;
    left: 50px;
  }
  .dealerhipLogo_right-top-logo-preview__2fiNE {
    top: 42%;
    right: 16%;
    width: 60px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1vb5z {
    right: 16%;
    width: 60px;
    bottom: 120px;
  }
  .dealerhipLogo_left-bottom-logo-preview__2wDim {
    width: 60px;
    bottom: 120px;
    left: 50px;
  }
}
@media screen and (max-width: 360px) and (min-width: 321px) {
  .dealerhipLogo_right-top-logo-preview__2fiNE {
    top: 37%;
    right: 15%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__3CzQU {
    width: 60px;
    left: 50px;
  }

  .dealerhipLogo_left-bottom-logo-preview__2wDim {
    bottom: 200px;
    width: 60px;
    left: 50px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1vb5z {
    bottom: 200px;
    width: 60px;
    right: 15%;
  }
}

@media screen and (max-width: 374px) and (min-width: 361px) {
  .dealerhipLogo_right-top-logo-preview__2fiNE {
    top: 33%;
    right: 14%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__3CzQU {
    width: 60px;
    left: 55px;
    top: 33%;
  }

  .dealerhipLogo_left-bottom-logo-preview__2wDim {
    bottom: 285px;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1vb5z {
    bottom: 285px;
    width: 60px;
    right: 13%;
  }
}

@media screen and (max-width: 410px) and (min-width: 375px) {
  .dealerhipLogo_right-top-logo-preview__2fiNE {
    top: 36%;
    right: 14%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__3CzQU {
    width: 60px;
    left: 55px;
    top: 36%;
  }

  .dealerhipLogo_left-bottom-logo-preview__2wDim {
    bottom: 225px;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1vb5z {
    bottom: 225px;
    width: 60px;
    right: 15%;
  }
}

@media screen and (max-width: 767px) and (min-width: 411px) {
  .dealerhipLogo_right-top-logo-preview__2fiNE {
    top: 33%;
    right: 14%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__3CzQU {
    width: 60px;
    left: 55px;
    top: 33%;
  }

  .dealerhipLogo_left-bottom-logo-preview__2wDim {
    bottom: 295px;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1vb5z {
    bottom: 295px;
    width: 60px;
    right: 15%;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .dealerhipLogo_right-top-logo-preview__2fiNE {
    top: 21%;
    right: 8%;
    width: 60px;
  }
  .dealerhipLogo_left-top-logo-preview__3CzQU {
    width: 60px;
    left: 55px;
    top: 21%;
  }

  .dealerhipLogo_left-bottom-logo-preview__2wDim {
    bottom: 59%;
    width: 60px;
    left: 55px;
  }
  .dealerhipLogo_right-bottom-logo-preview__1vb5z {
    bottom: 59%;
    width: 60px;
    right: 8%;
  }
}

/* @media screen and (max-width: 1280px) and (min-width: 900px) {
  .upload-button {
    width: 80%;
    font-size: 13px;
  }
  .logo-image {
    padding: 5px 5px;
  }
  .upload-logo-button {
    padding: 0;
  }

  .sm-car-checkbox-3 {
    margin-left: 44px;
  }
  .left-top-logo-preview {
    top: 31%;
    width: 100px;
    left: 95px;
  }
  .right-top-logo-preview {
    position: absolute;
    top: 31%;
    right: 35%;
  }
  .right-bottom-logo-preview {
    right: 35%;
    bottom: 150px;
  }
  .left-bottom-logo-preview {
    bottom: 150px;
    left: 95px;
  }
} */

/* @media screen and (max-width: 1300px) and (min-width: 1200px) {
  .logo-image p {
    line-height: 2.5;
  }
  .sm-car-checkbox-3 {
    margin-left: 49px;
  }
}

@media screen and (max-width: 1498px) and (min-width: 1440px) {
  .left-top-logo-preview {
    top: 31%;
    left: 90px;
  }
  .right-top-logo-preview {
    top: 31%;
    right: 42%;
  }
  .right-bottom-logo-preview {
    right: 42%;
    bottom: 22%;
  }
  .left-bottom-logo-preview {
    left: 90px;
    bottom: 22%;
  }
}

@media screen and (max-width: 1599px) and (min-width: 1499px) {
  .left-top-logo-preview {
    top: 25%;
    left: 55px;
  }
  .right-top-logo-preview {
    top: 25%;
    right: 45%;
  }
  .right-bottom-logo-preview {
    right: 47%;
    bottom: 20%;
  }
  .left-bottom-logo-preview {
    left: 90px;
    bottom: 20%;
  }
}

@media screen and (min-width: 1601px) {
  .left-top-logo-preview {
    top: 25%;
    left: 45px;
  }
  .right-top-logo-preview {
    top: 25%;
    right: 51%;
  }
  .right-bottom-logo-preview {
    right: 51%;
    bottom: 22%;
  }

  .left-bottom-logo-preview {
    bottom: 22%;
    left: 45px;
  }
} */

:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.LandingModal_firstStep__1dkVI {
  /* background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/ezgif%201.png'); */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: contain;
}
.LandingModal_firstStep__1dkVI img {
  width: 69%;
}
.LandingModal_signup-button__Owynt {
  /* position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%); */
  background-color: white !important;
  color: #ff7700 !important;
  width: 150px;
  border-radius: 45px;
  font-size: 16px !important;
  margin-bottom: 12px;
}

.LandingModal_circle-area-inner__2ibuD a {
  border-bottom: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.LandingModal_login-check-modal__HovIN img {
  width: 60px;
  height: auto;
}

.LandingModal_got-btn__1F6IY {
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 140px;
  height: 40px;
  margin: 20px 0;
}

.LandingModal_cars-img__1FO3f {
  width: 100%;
}

.LandingModal_img-sec__2eYg5 {
  height: 355px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 62px;
  text-align: center;
  width: 85%;
}

.LandingModal_close-btn__3WBVv {
  width: 15px !important;
  cursor: pointer;
}

.LandingModal_cars-circle__2Gc9- {
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 50%;
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.LandingModal_cars-circle-active__21Teo {
  border: 1px solid #ff7700 !important;
  background: #f1d7bf !important;
  border-radius: 50%;
  margin-top: 40px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.LandingModal_cars-circle__2Gc9- img {
  margin-top: -13px;
  margin-left: 14px;
}

.LandingModal_cars-circle-active__21Teo img {
  margin-top: -13px;
  margin-left: 14px;
}

.LandingModal_login-here__1QA2M {
  cursor: pointer;
  border-bottom: 1px solid #ff0000;
}

.LandingModal_forgot-password__2ItVV {
  font-size: 15px;
  color: #ff7700;
  /* border-bottom: 1px solid #ff7700; */
  cursor: pointer;
}

.LandingModal_login-check-modal__HovIN input {
  width: 80%;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  margin: 25px 0 20px;
}
.LandingModal_login-check-modal__HovIN p {
  padding-top: 1%;
  font-size: 25px;
  margin-bottom: 0%;
}
.LandingModal_login-check-modal__HovIN form p {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #2d2b29;
  font-weight: 400;
  padding: 0;
}
.LandingModal_login-check-modal__HovIN form p a {
  color: #ff7700;
}
.LandingModal_login-check-modal__HovIN button {
  border: unset;
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 50%;
  height: 45px;
  margin: 25px 0 25px;
}

.LandingModal_or__1Wuuz {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.2px;
  color: #787878;
  border-top: 1px solid #ddd;
  margin: 0px 20% 0px;
}
.LandingModal_or__1Wuuz span {
  background: #fff;
  position: relative;
  top: -12px;
  padding: 10px;
}

.LandingModal_img-sec__2eYg5 img {
  object-fit: contain;
  width: 82%;
  text-align: center;
  padding-bottom: 40px;
}

.LandingModal_car-text__1R9af h1 {
  font-size: 30px;
}

.LandingModal_car-text__1R9af h1 span {
  color: #ff7700;
}

.LandingModal_circle-area__3FuNB {
  padding: 25px;
  background: #ff7700;
  width: 62%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  margin: 0 auto 0;
  margin-right: -10%;
  top: 0;
  right: 0;
}
.LandingModal_circle-area-inner__2ibuD {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  padding: 28% 35px 20px;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  text-align: center;
}
.LandingModal_circle-area-inner__2ibuD h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 139.5%;
  text-align: center;

  color: #ffffff;
}
.LandingModal_circle-area-inner__2ibuD p {
  font-weight: normal;
  font-size: 17px;
  line-height: 139.5%;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 22px !important;
  color: #ffffff;
}

.LandingModal_circle-area-inner__2ibuD span {
  font-weight: 600;
}
.LandingModal_overlays-models-text__18g-N {
}
.LandingModal_overlays-models-text__18g-N p {
  padding: 10px 0 0;
  margin: 0;
}

.LandingModal_login-check-modal__HovIN h6 {
  text-align: left;
  padding-left: 87px;
  color: red;
  font-size: 12px;
}
.LandingModal_product-hunt__3jInh img {
  width: 250px;
  margin: 0 0 0 10px;
}
.LandingModal_product-hunt-tag__2XNAe {
  padding: 0;
  margin: -10% 0 10px !important;
}
.LandingModal_product-hunt-tag__2XNAe a {
  border: 0 !important;
}

@media (max-width: 768px) {
  .LandingModal_hide-in-mobile__2ta44 {
    display: none !important;
  }
  .LandingModal_car-text__1R9af h1 {
    font-size: 19px;
  }

  .LandingModal_firstStep__1dkVI {
    height: 370px;
    width: 100%;
  }

  .LandingModal_signup-button__Owynt {
    width: 100px;
    height: 30px;
    padding: 0;
    font-size: 11px !important;
  }

  .LandingModal_login-check-modal__HovIN img {
    width: 40px;
    height: auto;
  }

  .LandingModal_login-check-modal__HovIN p {
    padding-top: 10px;
    font-size: 18px;
  }
  .LandingModal_login-check-modal__HovIN input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ced4da;
    margin-top: 35px;
  }

  .LandingModal_login-check-modal__HovIN button {
    border: unset;
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 230px;
    height: 40px;
    margin: 35px 0 20px;
  }

  .LandingModal_cars-circle__2Gc9- {
    width: 55px;
    height: 55px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
  }

  .LandingModal_cars-circle-active__21Teo {
    border: 1px solid #ff7700 !important;
    background: #f1d7bf !important;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 55px;
  }

  .LandingModal_cars-circle__2Gc9- img {
    margin-top: 0px;
    margin-left: 0px;
  }

  .LandingModal_cars-circle-active__21Teo img {
    margin-top: 0px;
    margin-left: 0px;
  }
  .LandingModal_img-sec__2eYg5 {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 26px;
    text-align: center;
    width: 98%;
    height: 275px;
  }
  .LandingModal_img-sec__2eYg5 img {
    width: 57%;
  }

  .LandingModal_firstStep__1dkVI img {
    display: block;
  }
  .LandingModal_circle-area__3FuNB {
    padding: 10px;
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-right: 0;
    position: relative;
  }
  .LandingModal_circle-area-inner__2ibuD {
    border-radius: 0;
    padding: 10px;
  }
  .LandingModal_circle-area-inner__2ibuD h3 {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .LandingModal_circle-area-inner__2ibuD p {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .LandingModal_firstStep__1dkVI {
    height: 450px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 901px) {
  .LandingModal_circle-area__3FuNB {
    margin-right: -15%;
  }
}

:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.CarV2_bg-white__1upyH {
  background: #fff;
}
.CarV2_banner-slider__2uzw3 {
  padding: 10% 0 5%;
}

.CarV2_car-v2-tabs__1r7Ng {
  padding: 60px 0px 0px;
}
.CarV2_title-area__JfGuJ {
  margin-bottom: 7%;
}
.CarV2_title-area__JfGuJ h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  color: #323232;
  text-align: center;
  margin: 0;
  margin-bottom: 50px;
}
.CarV2_title-area__JfGuJ p {
  text-align: center;
}
.CarV2_title__2MWCn h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #323232;
}
.CarV2_title__2MWCn h6 span {
  color: #ff7700;
}
.CarV2_request-demo__1DC9f {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.CarV2_request-demo__1DC9f:hover {
  border: 2px solid #ff7700;
  background: #fff;
  color: #ff7700;
}
.CarV2_car-v2-left__3nfo5 {
}
.CarV2_why-use__3qa4O h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}
.CarV2_why-use__3qa4O h1 span {
  border-bottom: 2px solid #fd8061;
  padding-bottom: 10px;
  color: #1c1e52;
}

.CarV2_car-v2-left__3nfo5 p,
.CarV2_why-use__3qa4O p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  color: #7c7c7c;
  padding: 0;
}
.CarV2_why-tabs__eVAss li button.CarV2_activate__PnG1r,
.CarV2_why-tabs__eVAss li button:hover {
  /* box-shadow: 0px 0px 20px #ff7700!important;  */
  border-left: 5px solid #ff7700;
}
.CarV2_car-v2-tabs__1r7Ng {
  position: relative;
}

.CarV2_why-section__3rpgu {
  padding: 0 5%;
}
.CarV2_why-tabs__eVAss li button {
  font-weight: 600 !important;
  font-size: 24px !important;
  border-radius: 10px !important;
  line-height: 45px !important;
  color: #1d293f !important;
  border-left: 5px solid #fff;
  background-color: #fff !important;
  transition: 0.3s ease-in-out;
}
.CarV2_why-tabs__eVAss img {
  float: left;
  margin-right: 0;
  width: 11%;
  vertical-align: middle;
  margin-top: 1%;
}
.CarV2_car-v2-left__3nfo5 .CarV2_why-tabs__eVAss span {
}
.CarV2_car-v2-left__3nfo5 .CarV2_why-tabs__eVAss p {
}
.CarV2_first-tab-content__2tPt8 {
  text-align: right;
  margin-bottom: 5% !important;
}
.CarV2_why-text__39A1W {
  width: 87%;
  float: right;
}
.CarV2_why-text__39A1W h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #1d293f;
  margin: 0;
}
.CarV2_why-text__39A1W p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #7c7c7c;
  margin: 0;
  display: flex;
}

.CarV2_scroll-tab__1iO_l {
}
.CarV2_why-tabs__eVAss li button {
  background: #ffffff !important;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 25%) !important;
  border-radius: 10px !important;
  margin: 0 0px 10px !important;
  padding: 15px 20px;
  text-align: left;

  width: 100%;
  height: 100%;
}
.CarV2_why-tabs__eVAss {
  padding: 0;
  list-style: none;
  margin: 0 0 10px;
}
.CarV2_request-demo2__3mEGE img {
  height: 44px;
  margin-left: 20px;
}
.CarV2_right-sections___LGm9 div {
}
.CarV2_right-sections___LGm9 {
  position: relative;
}
.CarV2_why-tabs__eVAss li {
  margin: 5px 0px !important;
}
.CarV2_first-tab-content__2tPt8 img {
  width: 65%;
  height: auto;
  margin: auto;
  margin-top: 2%;
}
.CarV2_buttons-div___zi7C {
  margin: 5% 0;
}

.CarV2_custom-backgrounds__23TTR {
  padding: 60px 5% 40px;
}

.CarV2_title__2MWCn {
  text-align: center;
}
.CarV2_title__2MWCn h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #ff7700;
  margin-bottom: 45px;
  margin-top: 0;
}
.CarV2_title__2MWCn h1 span {
  border-bottom: 2px solid #fd8061;
  color: #1c1e52;
}
.CarV2_title__2MWCn h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.CarV2_custom-image__Gng9g {
}
.CarV2_custom-image__Gng9g img {
  width: 100%;
  box-shadow: -2px 5px 10px 0px #6c757d63;
}
.CarV2_custom-links__3QAIi {
}
.CarV2_custom-image-360__3I0Um {
}
.CarV2_custom-image-360__3I0Um div,
.CarV2_custom-image-360__3I0Um div iframe {
  width: 100% !important;
  box-shadow: -2px 5px 10px 0px #6c757d63;
}
.CarV2_custom-image-360__3I0Um div ol {
  background: #969ca1d1 !important;
}
.CarV2_custom-image-360__3I0Um img {
  width: 100%;
  padding: 0;
}
.CarV2_custom-backgrounds__23TTR .CarV2_container-fluid__1ufVE {
  width: 100% !important;
}
.CarV2_custom-links__3QAIi {
}
.CarV2_exterior-links__uNA2u ul {
  display: flex;
  list-style: none;
  padding: 30px 0;
  margin: 0;
}
.CarV2_exterior-links__uNA2u ul li {
  padding: 0 5px 0;
  width: 20%;
  text-align: center;
}

.CarV2_exterior-links__uNA2u ul li button {
  background: #fff;
  padding: 0px;
  border: 2px solid #fff;
  position: relative;
  border-radius: 0;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 2px 0px #6c757d63;
}

.CarV2_custom-links__3QAIi ul {
  display: block;
  list-style: none;
  padding: 15px 0 0;
  margin: 0;
}
.CarV2_custom-links__3QAIi ul li {
  padding: 0 5px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.CarV2_custom-links__3QAIi ul li button {
  background: #fff;
  padding: 2px;
  border: 2px solid #fff;
  position: relative;
  border-radius: 0;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 2px 0px #6c757d63;
}
.CarV2_custom-links__3QAIi ul li button.CarV2_active__2dQiM,
.CarV2_custom-links__3QAIi ul li button:hover {
  border: 2px solid #ff7700;
}
.CarV2_custom-links__3QAIi ul li button img,
.CarV2_exterior-links__uNA2u ul li button img {
  width: 100%;
}
.CarV2_exterior-links__uNA2u ul li button img {
  height: 75px;
}
.CarV2_exterior-links__uNA2u ul li button p {
  color: #fff;
  font-size: 11px;
  padding: 3px 5px;
  margin: 0;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.CarV2_exterior-tabs__3ac6Q ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}
.CarV2_exterior-tabs__3ac6Q ul li {
  padding: 3px;
  margin: 10px 5px;
  background: transparent;
  list-style: none;
  display: inline-block;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  box-shadow: 0px 2px 3px 0px #ddd;
}
.CarV2_slates-tabs__2RsBx li {
  padding: 5px 10px !important;
  margin: 10px !important;
}
.CarV2_exterior-tabs__3ac6Q ul li.CarV2_selected__u9-Sd {
  background: #fff;
  color: black;
  border-radius: 0;
  border: 0;
  color: #ff7700 !important;
  border-bottom: 2px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}
.CarV2_exterior-tabs__3ac6Q ul li button {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  background: transparent;
  color: #444444;
  padding: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 0 !important;
}
.CarV2_exterior-tabs__3ac6Q ul li button.CarV2_activ__3lCMQ,
.CarV2_exterior-tabs__3ac6Q ul li button:hover,
.CarV2_exterior-tabs__3ac6Q ul li button.CarV2_virtual__esJ7c {
  color: #ff7700 !important;
  border-bottom: 1px solid #ff7700 !important;
  border-radius: 0;
  background: transparent;
}

.CarV2_abs-img__2GJda {
  position: absolute;
  left: 33%;
  top: 20%;
  width: 30% !important;
}

.CarV2_gif-area__37pQp {
  padding: 60px 0 40px;
}
.CarV2_no-border__1XGmx {
  border: none !important;
}

.CarV2_mac-pro-image__30x8X {
  text-align: center;
}
.CarV2_mac-pro-image__30x8X img {
  margin-bottom: 50px;
  width: 100%;
}

.CarV2_graph-area__101pN {
  padding: 60px 0px;
}
.CarV2_graph-area__101pN .CarV2_title__2MWCn {
  text-align: left;
  padding-top: 10%;
}

.CarV2_graph-image__2BTf- {
}
.CarV2_graph-image__2BTf- img {
  width: 100%;
}

.CarV2_calendly-area__2DUbJ {
  padding: 60px 0px;
}

.CarV2_cars-demo__2GIv4 {
  height: 450px;
  text-align: center;
}
.CarV2_cars-demo__2GIv4 p {
  margin-top: 30px;
}
.CarV2_cars-demo__2GIv4 iframe {
  height: 100%;
}
.CarV2_cars-video__1hodU {
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  height: 450px;
}
.CarV2_cars-calender__2MvU6 {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px;
}
.CarV2_cars-calender__2MvU6 h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.CarV2_calendly-inline-widget__3ixKF {
  width: 100%;
}
.CarV2_main__2K105 {
  padding: 50px 0px;
}
.CarV2_book-demo__1ba48 {
  margin-bottom: 30px;
}
.CarV2_book-demo__1ba48 h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  letter-spacing: 0.2px;
  color: #5d5d6d;
  margin-bottom: 15px;
  padding-left: 5%;
}
.CarV2_book-demo__1ba48 h1 span {
  color: #ff7700;
}
.CarV2_book-demo__1ba48 p span {
  color: #ff7700;
}
.CarV2_book-demo__1ba48 p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}
.CarV2_book-demo__1ba48 img {
  float: left;
}
.CarV2_cars-calender__2MvU6 {
  box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
  border-radius: 10px;
  padding: 10px 10px 20px;
  height: 100%;
  overflow: hidden;
}
.CarV2_cars-calender__2MvU6 h4 {
  text-align: center;
  width: 100%;
  color: #ff7700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.CarV2_calendly-inline-widget__3ixKF {
  height: 100% !important;
}

.CarV2_ticker-headline__EiNGa {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 15px 0;
  margin: 0;
  font-size: 18px;
}

.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV {
  height: 100%;
  width: auto;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI {
  width: auto;
  padding-right: 50px;
  transition: 0.6s ease-in-out top;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI {
    transition: 0.6s ease-in-out;
  }
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI.CarV2_next__2BFHy,
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI.CarV2_active__2dQiM.CarV2_right__6Fz_I {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    top: 0;
  }
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI.CarV2_prev__14L01,
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI.CarV2_active__2dQiM.CarV2_left__2ee0D {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    top: 0;
  }
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI.CarV2_next__2BFHy.CarV2_left__2ee0D,
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI.CarV2_prev__14L01.CarV2_right__6Fz_I,
  .CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_item__NUtEI.CarV2_active__2dQiM {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    top: 0;
  }
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_active__2dQiM,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_next__2BFHy,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_prev__14L01 {
  display: block;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_active__2dQiM {
  top: 0;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_next__2BFHy,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_prev__14L01 {
  position: absolute;
  top: 0;
  width: 100%;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_next__2BFHy {
  top: 100%;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_prev__14L01 {
  top: -100%;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_next__2BFHy.CarV2_left__2ee0D,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_prev__14L01.CarV2_right__6Fz_I {
  top: 0;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_active__2dQiM.CarV2_left__2ee0D {
  top: -100%;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-inner__1lbTV > .CarV2_active__2dQiM.CarV2_right__6Fz_I {
  top: 100%;
}

.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU {
  left: auto;
  width: 50px;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU.CarV2_up__2TkRc {
  top: 0;
  right: 0;
  bottom: 50%;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU.CarV2_down__3LpTM {
  top: 50%;
  right: 0;
  bottom: 0;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-prev__3TFI-,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-next__3gp_3,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_glyphicon-chevron-up__2QqNI,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_glyphicon-chevron-down__2sXiA {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-prev__3TFI-,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_glyphicon-chevron-up__2QqNI {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-next__3gp_3,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_glyphicon-chevron-down__2sXiA {
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-up__3DgqT,
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-down__1aQOb {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-prev__3TFI-:before {
  content: '‹';
}
.CarV2_carousel__3Se9N.CarV2_vertical__3iLUL .CarV2_carousel-control__3a7hU .CarV2_icon-next__3gp_3:before {
  content: '›';
}

.CarV2_slide-images__3M_Zx .CarV2_item__NUtEI img {
  width: 100%;
  height: 100vh;
}

.CarV2_calendly-area__2DUbJ .CarV2_title__2MWCn {
  margin-bottom: 25px;
}
.CarV2_calendly-area__2DUbJ .CarV2_title__2MWCn h1 {
  margin-bottom: 0;
}
.CarV2_slide-images__3M_Zx img {
  max-height: calc(100vh - 65px);
  object-fit: cover;
}

/* Virtual section  */
.CarV2_virtual-images__1Q5KO {
}
.CarV2_virtual-images__1Q5KO img {
  width: 100%;
}

.CarV2_slates-tabs__2RsBx li.CarV2_selected__u9-Sd {
  border-top: 0px !important;
}

.CarV2_v-image__2NSG1 {
  padding: 0 15px !important;
}
.CarV2_banner-text__3U_BV {
  margin-top: 5%;
  text-align: left;
}
.CarV2_banner-text__3U_BV h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  color: #1c1e52;
  margin: 0 0 25px;
  letter-spacing: 1px;
}
.CarV2_banner-text__3U_BV p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  color: #444444;
}
.CarV2_banner-text__3U_BV p span {
  color: #ff7700;
}

.CarV2_banner-gif__MiukO img {
  width: 100%;
  position: relative;
}
.CarV2_banner-gif__MiukO {
}
.CarV2_banner-gif__MiukO .CarV2_mac-gif__EVi6Z {
  position: absolute;
  width: 56.1%;
  right: 10.2%;
  top: 6%;
}
.CarV2_banner-text__3U_BV p a img {
  width: 250px;
}
.CarV2_product-hunt__3bCit img {
  width: 250px;
  margin: 0 0 0 10px;
}

@media screen and (max-width: 800px) and (min-width: 200px) {
  .CarV2_title-area__JfGuJ h1 {
    display: none;
  }
  .CarV2_mac-pro-image__30x8X img {
    width: 100%;
    margin-bottom: 20px;
  }
  .CarV2_custom-links__3QAIi ul li button img {
    width: 100%;
  }
  .CarV2_abs-img__2GJda {
    top: 18%;
    width: 30% !important;
  }
  .CarV2_graph-image__2BTf- img {
    width: 100%;
  }
  .CarV2_graph-area__101pN .CarV2_title__2MWCn {
    margin-bottom: 0px;
    text-align: center;
    padding-top: 0%;
    order: 2;
  }
  .CarV2_title__2MWCn h1 {
    font-size: 18px;
    line-height: 30px;

    margin-bottom: 20px;
  }
  .CarV2_request-demo__1DC9f {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    width: auto;
    line-height: normal;
    line-height: initial;
    height: 40px;
    margin: 0 auto;
  }
  .CarV2_graph-area__101pN {
    padding: 0 0;
  }
  .CarV2_custom-links__3QAIi ul li button {
  }

  .CarV2_custom-links__3QAIi ul li {
    padding: 0px 5px 0;
    margin-bottom: 5px;
  }

  .CarV2_custom-backgrounds__23TTR {
    padding: 40px 0px 20px;
  }
  .CarV2_request-demo2__3mEGE img {
    height: 40px;
  }
  .CarV2_car-v2-left__3nfo5 .CarV2_why-tabs__eVAss span {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .CarV2_car-v2-left__3nfo5 .CarV2_why-tabs__eVAss p {
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: normal;
    line-height: initial;
  }
  .CarV2_why-tabs__eVAss li button {
    padding: 10px;
  }
  .CarV2_why-tabs__eVAss img {
    width: 15%;
    margin-top: 10px;
  }
  .CarV2_why-tabs__eVAss li + li button img {
    margin-top: 7%;
  }
  .CarV2_car-v2-left__3nfo5 p {
    text-align: center;
  }
  .CarV2_car-v2-tabs__1r7Ng {
    padding: 0;
    margin-bottom: 20px;
  }
  .CarV2_title-area__JfGuJ {
    margin: 0;
  }
  .CarV2_car-v2-left__3nfo5 {
    order: 2;
    padding: 0;
  }
  .CarV2_why-use__3qa4O h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .CarV2_right-sections___LGm9 div {
    margin: 0;
    text-align: center;
  }
  .CarV2_car-v2-left__3nfo5 p,
  .CarV2_why-use__3qa4O p {
    font-size: 12px;
    line-height: inherit;
    margin-bottom: 15px;
  }

  .CarV2_first-tab-content__2tPt8 img {
    width: 50%;
    margin: 3% auto 5%;
  }
  .CarV2_gif-area__37pQp {
    padding: 0px 0 40px;
  }
  .CarV2_banner-slider__2uzw3 {
    margin-bottom: 0;
    margin-top: 55px;
    padding-bottom: 0;
  }
  .CarV2_why-text__39A1W {
    width: 80%;
    float: right;
  }
  .CarV2_why-text__39A1W h6 {
    font-size: 18px;
  }
  .CarV2_graph-image__2BTf- {
    padding: 0 30px;
  }
  .CarV2_mac-pro-image__30x8X {
    padding: 0 25px;
  }
  .CarV2_custom-image__Gng9g {
    padding: 0;
    margin: 10px 0;
  }
  .CarV2_cars-demo__2GIv4 {
    height: auto;
    text-align: center;
    margin-top: 30px;
  }
  .CarV2_title__2MWCn h6 {
    font-size: 14px;
    margin: 0;
  }
  .CarV2_cars-video__1hodU {
    height: auto;
  }

  .CarV2_calendly-area__2DUbJ {
    padding: 60px 0px;
    margin-bottom: 0px;
  }
  .CarV2_custom-links__3QAIi ul li button p {
    color: #323232;
    font-size: 9px;
  }
  .CarV2_custom-links__3QAIi {
    padding: 0 5px;
  }
  .CarV2_scroll-tab__1iO_l .CarV2_why-use__3qa4O {
    padding: 0;
    margin: 0;
  }
  .CarV2_custom-links__3QAIi ul {
    padding: 0 0 0;
    display: flex;
  }
  .CarV2_exterior-tabs__3ac6Q ul {
    display: flex;
  }
  .CarV2_exterior-tabs__3ac6Q ul li {
    margin: 5px 6px 0 !important;
    font-size: 12px;
    padding: 2px !important;
  }
  .CarV2_exterior-tabs__3ac6Q ul li button {
    font-size: 12px;
  }
  .CarV2_exterior-links__uNA2u ul {
    padding: 10px 0;
    width: 100%;
    overflow: auto;
    display: block;
  }
  .CarV2_exterior-links__uNA2u ul li {
    width: 12.5%;
    display: inline-block;
    margin-bottom: 10px;
  }
  .CarV2_exterior-links__uNA2u ul li button img {
    height: 40px;
  }
  .CarV2_exterior-links__uNA2u ul li button p {
    color: #fff;
    font-size: 8px;
  }

  .CarV2_virtual-tabs__n6yGp {
    display: block !important;
  }
  .CarV2_custom-bg__2k51U {
    margin-bottom: 10px;
  }
  .CarV2_virtual-sm__1nwb_ {
    display: block !important;
  }

  .CarV2_banner-text__3U_BV {
    margin-top: 10%;
    text-align: center;
    order: 2;
  }
  .CarV2_banner-text__3U_BV h1 {
    font-size: 20px;
    line-height: normal;
    line-height: initial;
    margin: 0 0 15px;
  }
  .CarV2_banner-text__3U_BV p {
    font-size: 12px;
    line-height: normal;
    line-height: initial;
  }
  .CarV2_why-section__3rpgu {
    padding: 20px 5% 0;
  }
  .CarV2_banner-gif__MiukO .CarV2_mac-gif__EVi6Z {
    width: 54%;
    right: 11.5%;
    top: 6%;
  }
  .CarV2_product-hunt__3bCit img {
    margin: 10px 0;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .CarV2_graph-area__101pN {
    padding: 0 0 60px !important;
  }
  .CarV2_first-tab-content__2tPt8 img {
    width: 100% !important;
    margin: 0 auto;
    margin-top: 30% !important;
  }
  .CarV2_car-v2-left__3nfo5 {
    order: 1 !important;
  }
  .CarV2_right-sections___LGm9 {
    order: 2;
  }
  .CarV2_graph-area__101pN .CarV2_title__2MWCn {
    order: 1 !important;
    padding-top: 8%;
    margin: 0;
  }
  .CarV2_graph-image__2BTf- {
    order: 2;
  }
  .CarV2_first-tab-content__2tPt8 img {
    width: 85%;
    margin-top: 10%;
  }
  .CarV2_cars-video__1hodU {
    height: 350px !important;
  }
  .CarV2_cars-demo__2GIv4 {
    height: 350px !important;
    margin-top: 0 !important;
  }
  .CarV2_calendly-area__2DUbJ {
    padding: 60px 0px;
    margin-bottom: 0px;
  }
  .CarV2_title-area__JfGuJ p {
    display: none;
  }
  .CarV2_banner-text__3U_BV {
    margin-top: 5%;
    text-align: left;
    order: inherit;
  }
  .CarV2_banner-text__3U_BV .CarV2_request-demo__1DC9f {
    margin: 0 0;
  }

  .CarV2_virtual-tabs__n6yGp {
    margin-bottom: 10px;
  }
  .CarV2_graph-area__101pN .CarV2_title__2MWCn {
    text-align: left !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 901px) {
  .CarV2_why-text__39A1W p {
    font-size: 12px;
    line-height: normal;
    line-height: initial;
  }
  .CarV2_why-text__39A1W h6 {
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
  .CarV2_first-tab-content__2tPt8 img {
    width: 75%;
  }
  .CarV2_custom-links__3QAIi ul li {
    padding: 30px 20px 0;
  }
  .CarV2_graph-area__101pN {
    padding: 0 0 50px;
  }
}

/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex='-1']:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.min.css.map */

#Spinner_modal-root__30l-I {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
  box-sizing: border-box;
  padding: 20px;
  color: #fff;
}

.Spinner_spinner-box__2jemQ {
  position: absolute;
  left: 50%;
  top: 50%;
}

.Spinner_loading-icon__nyEb2 {
  display: flex;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #ff7700; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-animation-name: Spinner_spin__lke7j;
          animation-name: Spinner_spin__lke7j;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  transition-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation: Spinner_spin__lke7j 2s linear infinite;
          animation: Spinner_spin__lke7j 2s linear infinite;
}

.Spinner_loading-icon__nyEb2 div {
  margin: auto;
}

@-webkit-keyframes Spinner_spin__lke7j {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__lke7j {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Spinner_spinner-background__jnyoz {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  );
  top: 0;
}

.Spinner_spinner-box__2jemQ span {
  color: #fff;
}

.SelectModelPose_select-model__1wldS{
    padding: 100px 0 60px;
}
.SelectModelPose_upload__2gy90{
    text-align: center;
}
.SelectModelPose_upload-btn__nLW1z{
    background: #FF7700;
    border-radius: 5px;
    min-width: 120px;
}
.SelectModelPose_previous__27MBN{
    float:left;
}

.SelectModelPose_prev-screen__2W6oq{

    text-align: center;
    background: #FF7700;
    border-radius: 50%;
    font-size: 30px;
    padding: 3px 10px 5px;
}
.SelectModelPose_upload-btn__nLW1z img{
    width: 22px;
    margin-right: 7px;
}
.SelectModelPose_credit-area__Gh_Vf{
    padding: 15px 0 0;
}
.SelectModelPose_available-credit__1lO_4{
    font-size: 14px;
    line-height: 150%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #232121;
    
}
.SelectModelPose_available-credit__1lO_4 img{
    width: 35px;
    margin-right: 10px;
}
.SelectModelPose_available-credit__1lO_4 span{
    color: #f70;
   
}
.SelectModelPose_right-top-credits__45ZOF{
text-align: right;

}
.SelectModelPose_right-top-credits__45ZOF button{
    padding: 5px 10px;
    width: auto;
    background: linear-gradient(
180deg
,hsla(0,0%,100%,.94),hsla(0,0%,100%,.24) 52.08%,hsla(0,0%,100%,0) 52.09%,hsla(0,0%,100%,.29)),linear-gradient(
180deg
,hsla(0,0%,100%,.4),hsla(0,0%,100%,0) 51.56%),#f70;
    background-blend-mode: soft-light,normal,normal;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), inset 0 -2px 6px rgb(44 0 0 / 35%);
    border-radius: 32px;
}

.SelectModelPose_active__cs4dr{
color: #FF7700!important;
}
.SelectModelPose_model-tabs__DgEYq{
border-right: 1px solid #ddd;
}
.SelectModelPose_model-tabs__DgEYq {
    padding: 10px 0;
    list-style: none;
    margin: 0;

}
 
.SelectModelPose_model-tabs__DgEYq li{
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px; 
    padding: 10px 20px;
    color: #989898;
}
.SelectModelPose_model-tabs__DgEYq li span{
    background: #989898;
    color: #fff;
    border-radius: 50%;
    padding: 4px 10px;
    margin-right: 5px;
}
.SelectModelPose_model-tabs__DgEYq li--selected span{
    background: #FF7700;
}
.SelectModelPose_model-area__1PHC0{
    background: #FFFFFF;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
border-radius: 5px;
margin: 30px 0;

padding: 0;

}
 
.SelectModelPose_model-saree__uR9NR h4{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px; 
color: #000000; 
padding: 0px 20px 5px;
}
.SelectModelPose_model-saree__uR9NR h4 span{
    color: #FF7700;
    /* float: right; */
}
.SelectModelPose_pose__Gheob h4 span{
     float: right;
}
.SelectModelPose_model-saree__uR9NR h4 label{
    padding: 5px 10px;
    background: #F0F0F1;
    border-radius: 77.7857px;
    font-size: 15px;
    float: right;
    order: 3;
    margin: 0 0 0 10px;
    min-width: 28%;
    text-align: left;
    margin-top: -5px;
}
.SelectModelPose_model-saree__uR9NR h4 label img{
width: auto;
}
.SelectModelPose_model-saree__uR9NR h4 label input{
    border: 0;
    font-size: 14px;
    background: transparent;
    width: 80%;
}
.SelectModelPose_model-saree__uR9NR h4 label input::-webkit-input-placeholder{
    font-size: 14px;
}
.SelectModelPose_model-saree__uR9NR h4 label input:-ms-input-placeholder{
    font-size: 14px;
}
.SelectModelPose_model-saree__uR9NR h4 label input::placeholder{
    font-size: 14px;
}
.SelectModelPose_single-model__3uUCd{
    padding: 15px 10px;
}
.SelectModelPose_single-model__3uUCd img{
    /* border: 1.55921px solid #C9C9C9;
box-sizing: border-box;
border-radius: 23.3882px; */
width: 100%;
height: 400px;
    object-fit: contain;
}

.SelectModelPose_single-model__3uUCd button{
    background: transparent;
    padding: 0;
    margin-bottom: 0px;
    position: relative;
    border: 1.55921px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 23.3882px;
}
.SelectModelPose_single-model__3uUCd button.SelectModelPose_active__cs4dr {
    border-color: #FF7700 !important;
}
.SelectModelPose_single-model__3uUCd button p{
    position: absolute;
    bottom: 0px;
    background: #fff; 
    left: 1px;
    margin: 0;
    padding: 8px 10px;
    border-radius: 0px 0px 23.3882px 23.3882px;
    right: 2px;
    color: #333;
    transition: .2s ease-in-out;
    font-weight: 500;
    font-size: 10px;
    line-height: 110%;
    letter-spacing: 0.5px;
    text-align: left;
    color: #232121;
    transition: .2s ease-in-out;
}
.SelectModelPose_single-model__3uUCd button p span{
    float: right;
}
.SelectModelPose_single-model__3uUCd h5{
    position: absolute;
    top: -47%;
    right: -10px;
    width: auto;
    height: auto;
    transition: .2s ease-in-out;
}
.SelectModelPose_single-model__3uUCd  button p span img{
    width: 11px;
    margin-right: 5px;
    height: auto;
    border: 0;
}
.SelectModelPose_selection-model__2fcG5{
    height: 460px;
    overflow: auto;
}
.SelectModelPose_display-none__3gJUu{
    display: none;
}
.SelectModelPose_model-tabdetails__1Y1wH{
    border-top: 1px solid #EBEAEA;
    padding: 15px 0;
}
.SelectModelPose_model-saree__uR9NR h6{
    text-align: center;
    padding: 10px 0;
}
.SelectModelPose_marketplace-select__1uIfQ button{
    background: #f4f4f4;
    width: 100%;
}
.SelectModelPose_marketplace__1GCzr{
text-align: center;
}
.SelectModelPose_text-head__1-iYV{
    float: left!important;
    color: #000!important;
}

.SelectModelPose_marketplace__1GCzr button img{
    width: 100%;
    height: 165px;
    object-fit: contain;
}
.SelectModelPose_single-model__3uUCd h6 img{
    height: auto;
    width: auto;
    /* position: absolute;
    top: -10px;
    right: -10px; */
}




.SelectModelPose_try-drag-drop__baqKw {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
    cursor: pointer;
  }

  .SelectModelPose_drop-box-text__2K2R0 {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .SelectModelPose_drop-box-text__2K2R0 div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .SelectModelPose_back-button__10MEf{
    border: unset;

    border-radius: 11px;
  
    padding: 0;
  }

  .SelectModelPose_toggle1__1H2ym {
    position: relative;
    color: #f39e53;
  }
  .SelectModelPose_toggle1__1H2ym input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .SelectModelPose_slider1__36zZY {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 190px;
  box-shadow: 0px 4px 4px rgb(255, 255, 255);
  border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
  }
  
  .SelectModelPose_slider1__36zZY::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top:3px
  
  }
  
  input:checked + .SelectModelPose_slider1__36zZY {
    background-color: #78f353;
  }
  
  input:checked + .SelectModelPose_slider1__36zZY::before {
    -webkit-transform: translateX(21.5px);
            transform: translateX(21.5px);
  }
  .SelectModelPose_button-padding__17s5h{
    padding: 0px 0px 0px 40px;


  }

  /* @Responsive  */
  @media screen and (max-width: 767px) and (min-width: 200px){
 .SelectModelPose_main-box__1kz89{
     padding-left: 60px;
 }
  .SelectModelPose_select-model__1wldS{
    padding: 80px 0 60px;
    margin-left: -60px;
}
.SelectModelPose_available-credit__1lO_4 {
    font-size: 12px;
    text-align: left;
}
.SelectModelPose_right-top-credits__45ZOF button {
    font-size: 12px;
}
.SelectModelPose_right-top-credits__45ZOF{
    padding-left: 0;
    margin-bottom: 10px;
}
.SelectModelPose_main-box__1kz89{
    padding-left: 60px;
}
.SelectModelPose_single-model__3uUCd img { 
    height: 150px; 
}
.SelectModelPose_selection-model__2fcG5 {
    height: auto; 
}
.SelectModelPose_single-model__3uUCd button{
overflow: hidden;
}
  }

  @media screen and (max-width: 900px) and (min-width: 768px){
 .SelectModelPose_main-box__1kz89{
     padding-left: 60px;
 }
    .SelectModelPose_select-model__1wldS{
      margin-left: -60px;
  }
 
}
 
  @media screen and (max-width: 1024px) and (min-width: 901px){
    .SelectModelPose_main-box__1kz89{
        padding-left: 60px;
    }
     
      }
  
.UploadSaree_custom-saree__hZ1GW{
    padding: 30px 20px;
    margin: 10px 0 0;
    border-top: 1px solid #ddd;
}
.UploadSaree_custom-tabs__2d_Al{
padding:10px;
}
.UploadSaree_custom-tabs__2d_Al li{
    padding: 5px;
    background: #fff;
    border: 0.735807px solid #C9C9C9!important;
    box-sizing: border-box;
    border-radius: 11.0371px!important;
    margin-bottom: 15px;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    width: 100%;
    text-align: center;
    height: 105px;
}
.UploadSaree_custom-tabs__2d_Al li img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
 
.UploadSaree_custom-tabs__2d_Al li:last-child{
    background: #EBEBEB;
}
.UploadSaree_custom-tabs__2d_Al li span{
    font-weight: 500;
font-size: 16px;
line-height: 24px; 
color: #000000;
position: relative;
top: 20px;
}
.UploadSaree_custom-big-image__36lVT p{
    font-weight: 600;
}
.UploadSaree_custom-big-image__36lVT{
    padding: 10px 20px;
    text-align: center;
}
.UploadSaree_custom-big-image__36lVT img{
    width: 100%;
    height: 500px;
    object-fit: contain; 
}

.UploadSaree_download-popup__1BFwm {
    border: 1px solid #ddd;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 10px 30px;
    margin-top: 0px;
  }
  
  .UploadSaree_download-popup__1BFwm button {
    border: 1px solid transparent;
    margin-bottom: 35px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 95px;
    text-align: left;
  }
  .UploadSaree_cross-btn__161QI {
    text-align: right;
    padding: 0 0 10px;
  }
  .UploadSaree_download-popup__1BFwm button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .UploadSaree_download-popup__1BFwm ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .UploadSaree_download-popup__1BFwm ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .UploadSaree_download-popup__1BFwm p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .UploadSaree_download-popup__1BFwm p span {
    color: #ff7700;
  }
  .UploadSaree_download-popup__1BFwm h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .UploadSaree_border-1__3KnKl {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
  }
  .UploadSaree_download-popup__1BFwm input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .UploadSaree_download-popup__1BFwm input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .UploadSaree_download-btn-hd__2Vemd {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px;
  }
  .UploadSaree_request-demo-1__2kuGx {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 153px !important;
    text-align: center;
    padding: 10px 28px !important;
    font-size: 17px;
  }
  
  .UploadSaree_download-popup__1BFwm button.UploadSaree_activate__2824b, .UploadSaree_download-popup__1BFwm button:hover {
    background: rgba(5,121,223,.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
}
.UploadSaree_total-credits__9NO-Q{
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}
.UploadSaree_total-credits__9NO-Q h5{
    padding: 0;
    font-weight: normal;
font-size: 14px;
line-height: 150%; 
letter-spacing: 0.5px; 
color: #232121;
}
.UploadSaree_total-credits__9NO-Q h6{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;  
    letter-spacing: 0.5px; 
    color: #FF7700;
    
}
.UploadSaree_total-credits__9NO-Q h5 span{
    color: #FF7700;
}
.UploadSaree_skelton__CSEpI{
  width: 100%!important;
  height: 400px;
  margin: 0;
}
.UploadSaree_download-skelton__2PJwJ{
  width:85%!important;
  height:114px;
  margin:  50px 0 25px;
  border-radius: 10px;
}
.UploadSaree_download-skelton3__3mTy4{
  width:85%!important;
  height:114px;
  margin:  0px 0 25px;
  border-radius: 10px;
}
.UploadSaree_download-skelton2__oyqhC{
  width:60%!important;
  height:50px;
  margin:  0px 80px 25px 0;
  border-radius: 10px;
}
.UploadSaree_download-skelton4__2k6ze{
  width:100%!important;
  height:100px;
  margin:  20px 0;
  border-radius: 10px;
}

 /* @Responsive  */
 @media screen and (max-width: 767px) and (min-width: 200px){
 
  .UploadSaree_skelton__CSEpI{
    width: 100%;
    height: 200px;
  }
.UploadSaree_custom-big-image__36lVT img { 
  height: auto;
}
.UploadSaree_download-popup__1BFwm {
  padding: 10px 15px;
}
.UploadSaree_custom-saree__hZ1GW{
  padding: 15px 0px;
}
.UploadSaree_download-popup__1BFwm button {
  width: 100%!important;
}
.UploadSaree_download-popup__1BFwm button { 
  padding: 15px;
}
.UploadSaree_custom-big-image__36lVT{
  padding: 10px 0px;
}
.UploadSaree_custom-tabs__2d_Al{
  padding: 10px 0; 
}
.UploadSaree_custom-tabs__2d_Al li {
  width: 29%;
  margin: 0 5px 15px;
}
.UploadSaree_download-skelton__2PJwJ, .UploadSaree_download-skelton3__3mTy4{
  width:100%!important; 
}
.UploadSaree_download-skelton2__oyqhC{
  margin: 0 auto 20px; 
}
 }

 @media screen and (max-width: 900px) and (min-width: 768px){
  .UploadSaree_download-popup__1BFwm button { 
    padding: 15px;
    width: 100%!important;
  }
  .UploadSaree_custom-saree__hZ1GW{
    padding: 30px 0px;
  }
  .UploadSaree_custom-tabs__2d_Al li {
    height: auto;
}
}

.Upload1_upload-screen__17MR8{
padding: 100px 0 60px;
}
.Upload1_upload__30QNH{

}
.Upload1_upload__30QNH h1{
    font-weight: 800;
    font-size: 35px;
    line-height: 50px;  
    color: #18191F;
}
.Upload1_upload__30QNH p{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;  
    color: #000000;
}
.Upload1_upload__30QNH p span{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; 
    color: #FF7700;
}
.Upload1_float-right__1Lq2U{
    float: right;
}
.Upload1_upload-cols__3gHB4{
    text-align: center;
}
.Upload1_upload-single__vyT9d{

}
.Upload1_upload-single__vyT9d:after{
    display: inline-block;
    content: "";
    position: absolute;
    top: 25%;
    left: 50%;
    width: 100%;
    /* height: 10px; */
    border-top: 1px dashed #B5B4B4;
    z-index: -1;
}
.Upload1_upload-single__vyT9d+.Upload1_upload-single__vyT9d:after{
    border: 0!important;
}
.Upload1_upload-single__vyT9d p{
    font-weight: normal;
    font-size: 16px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px;
    
    color: #B5B4B4;
}
.Upload1_upload-single__vyT9d p span{
    display: block;
    background-color: #B5B4B4;
    padding: 5px;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0 auto 15px;
}
.Upload1_active__2MXZO{
    color: #FF7700!important;
    }
    .Upload1_upload-single__vyT9d p.Upload1_active__2MXZO {
        color: #FF7700!important;
    }
    .Upload1_upload-single__vyT9d p.Upload1_active__2MXZO span{
        background: #FF7700!important;
    }
.Upload1_upload-box__3_rAu{
    background: rgba(255, 119, 0, 0.05);
border: 0.587273px dashed #FF7700;
box-sizing: border-box;
border-radius: 11.7455px;
text-align: center;
padding: 10px;
color: #FF7700;
margin: 20px;
}
.Upload1_upload-box__3_rAu h4{
    font-weight: 500;
font-size: 17.6182px;
line-height: 100%; 
align-items: center;
text-align: center;
letter-spacing: 0.998364px; 
color: #FF7700;
}
.Upload1_upload-box__3_rAu p{
    font-weight: normal;
font-size: 7.04727px;
line-height: 100%;  
align-items: center;
text-align: center;

color: #FF7700;
}
.Upload1_upload-text__3rh9B{
    text-align: center;
}
.Upload1_upload-text__3rh9B h3{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px; 
    color: #18191F;
}
 
.Upload1_skip-btn__2BZrw{
    background: #FFFFFF;
border: 2px solid #FF7700;
box-sizing: border-box;
box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px;
color: #FF7700;
min-width: 120px;
padding: 7px 15px;
margin-right: 15px;
}
.Upload1_next-btn__2FWR2{
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px;
padding: 7px 15px;
min-width: 120px;
border: 2px solid #FF7700;
}
.Upload1_heading__2QrhQ{

}
.Upload1_heading__2QrhQ h2{
    font-weight: normal;
font-size: 24px;
line-height: 110%; 
text-align: center;
letter-spacing: 0.5px; 
color: #000000;
margin-bottom: 30px;
}
.Upload1_saree-pallu__ajFqI{
    border-top: 1px solid #CBCBCB;
}
.Upload1_saree-left__a2YQf{
    border-right: 1px solid #CBCBCB;
    padding: 20px;
}
.Upload1_saree-left__a2YQf h6{
    text-align: center;
}
.Upload1_saree-left__a2YQf button{
    margin: 0 10px;
    font-weight: bold;
font-size: 12px;
line-height: 12px; 
color: #FFFFFF;
}
.Upload1_saree-image__3R1yR{

}
.Upload1_saree-image__3R1yR img{
    width: 100%;
    margin: 20px auto;
}

.Upload1_upload-btn__3Qt70{
    background: #FF7700;
border-radius: 17.4889px;
font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
}
.Upload1_saree-text__K1xvF{

}
.Upload1_saree-text__K1xvF p{
    font-weight: normal;
    font-size: 11px;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 0.02em; 
    color: #000000;
    margin-top: 100%;
}
.Upload1_saree-text__K1xvF p span{
display: block;
margin-top: 100%;
}
.Upload1_saree-image__3R1yR p{

}
.Upload1_saree-image__3R1yR p span{

}
.Upload1_saree-right__3VsMg{

}
.Upload1_saree-right__3VsMg h4{
    font-weight: bold;
font-size: 18px;
line-height: 21px; 
align-items: center;
text-align: center;
letter-spacing: 0.05em; 
color: #000000;
margin-top: 20px;
}
.Upload1_saree-image__3R1yR svg{

}
.Upload1_saree-image__3R1yR h3{
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #000000;
    -webkit-transform: rotate(
-90deg
);
            transform: rotate(
-90deg
);
    float: right;
    margin-top: -60%;
    position: relative;
    margin-right: -20%;

}
.Upload1_right-image__2RBXX{
    text-align: center;
}
.Upload1_right-image__2RBXX img{
    padding: 15px 10%;
    width: 80%;
}
.Upload1_right-image__2RBXX svg{
    width: 10%;
}
 .Upload1_right-image__2RBXX p img{
    padding: 0;
    width: auto!important;
    margin-right: 5px!important;
 }
 .Upload1_right-image__2RBXX p {
     text-align: left;
     font-weight: 300;
font-size: 13px;
line-height: 15px;  
letter-spacing: 0.02em; 
color: #686464;
 }








.CreateModel_create-model__PsM_X{
    background: url('https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/bg1.png');
  
    background-repeat: no-repeat;
    padding: 100px 0 50px;
    background-size: 100% 95%;
    font-family: 'Manrope';
}
.CreateModel_heading__2SV5g h6{
    font-weight: bold;
font-size: 20px;
line-height: 38px; 
color: #929292;
font-family: 'Manrope';
}
.CreateModel_heading__2SV5g{
    padding: 5% 15px 0 5%;

}
.CreateModel_heading__2SV5g h1{
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 50px;
    line-height: 70px;  
    color: #18191F;
    margin: 10px 0px 30px;
}
.CreateModel_heading__2SV5g h1 span{
    color: #FF7700;
}
.CreateModel_heading__2SV5g p{
    font-family: 'Manrope';
}

.CreateModel_demo__3tdz9{
    background: #FFFFFF;
border: 2px solid #FF7700;
box-sizing: border-box;
box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px; 
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #ff7700; 
min-width: 180px; 
margin-left: 10px;;
}
.CreateModel_nxt-btn__3HzR3{
    background: #FF7700;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.CreateModel_model-gif__1yPIR{
    text-align: center;
}
.CreateModel_model-gif__1yPIR img{
    max-width: 100%;
    width: 75%;
}

/* ########## Category ############# */

.CreateModel_category-select__UnJmy{
    padding: 120px 0 50px;
}

.CreateModel_heading__2SV5g{

}
.CreateModel_heading__2SV5g h1{
    font-weight: 800;
    font-size: 45px;
    line-height: 72px;  
    color: #18191F;
    text-align: center;
    margin-bottom: 30px;
}
.CreateModel_category-list__2oYS6 ul{
padding: 2% 0 5%;
list-style: none;
}
.CreateModel_category-list__2oYS6 ul li{
    display: inline-block;
    width: 20%;
    padding: 10px 15px;
}
.CreateModel_category-list__2oYS6 ul li:hover{
    border: 2px solid black;
    cursor: pointer;
}
 
.CreateModel_selected__3igG7{
    border: 2px solid red; 
}

.CreateModel_category-list__2oYS6 ul li div{
    width: 100%; 
    text-align: center;
}
.CreateModel_category-list__2oYS6 ul li img{
    width: 100%;
}
.CreateModel_category-list__2oYS6 ul li p{
    font-weight: 500;
font-size: 18px;
line-height: 36px; 
color: #4F4F4F;
}
/* .cat1{
    background: #E5E5E5;
    border-radius: 50%;
}
.cat2{
    background: #E5E5E5;
    border-radius: 50%;
}
.cat3{
    background: #E5E5E5;
    border-radius: 50%;
}
.cat4{
    background: #E5E5E5;
    border-radius: 50%;
}
.cat5{
    background: #E5E5E5;
    border-radius: 50%;
} */
.CreateModel_nxt-btn__3HzR3{
    background: #FF7700;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.CreateModel_upload-image__3jqgH{
    text-align: center;
}
.CreateModel_upload-heading__pZ6dP h1{
    font-weight: 500;
    font-size: 22px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.397475px; 
    color: #232121;
    margin-bottom: 30px;
}
.CreateModel_upload-heading__pZ6dP h1 span{
    border-bottom: 3.1798px solid #FF7700;
}
.CreateModel_upload-go__2Vkpz{
    background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
margin-bottom: 30px;
min-width: 180px;
-webkit-filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
}
.CreateModel_upload-heading__pZ6dP img{
    width: 100%;
    margin-bottom: 30px;
}


/* upload Section */

.CreateModel_upload-screen__1bqqa{
    text-align: center;
    padding: 100px 0 20px;
    }
    .CreateModel_upload___DjPL{
    
    }
    .CreateModel_upload___DjPL h1{
        font-weight: 800;
        font-size: 35px;
        line-height: 50px;  
        color: #18191F;
    }
    .CreateModel_upload___DjPL p{
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;  
        color: #000000;
    }
    .CreateModel_upload___DjPL p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .CreateModel_float-right__Bjt3l{
        float: right;
    }
    .CreateModel_upload-cols__3XeES{
        text-align: center;
    }
    .CreateModel_upload-single__3hnfc{
    
    }
    .CreateModel_upload-single__3hnfc:after{
        display: inline-block;
        content: "";
        position: absolute;
        top: 25%;
        left: 50%;
        width: 100%;
        /* height: 10px; */
        /* border-top: 1px dashed #B5B4B4; */
        z-index: -1;
    }
    .CreateModel_upload-single__3hnfc+.CreateModel_upload-single__3hnfc:after{
        border: 0!important;
    }
    .CreateModel_upload-single__3hnfc p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .CreateModel_upload-single__3hnfc p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .CreateModel_active___rCLP{
        color: #FF7700!important;
        }
        .CreateModel_upload-single__3hnfc p.CreateModel_active___rCLP {
            color: #FF7700!important;
        }
        .CreateModel_upload-single__3hnfc p.CreateModel_active___rCLP span{
            background: #FF7700!important;
        }
    .CreateModel_upload-box__Zsh36{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 20px;
    }
    .CreateModel_upload-box__Zsh36 h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .CreateModel_upload-box__Zsh36 p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .CreateModel_upload-text__KkPW2{
        text-align: center;
    }
    .CreateModel_upload-text__KkPW2 h3{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px; 
        color: #18191F;
    }
     
    .CreateModel_skip-btn__Fvqm_{
        text-align: center;
        background: #FFFFFF;
    border: 2px solid #FF7700;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #FF7700;
    min-width: 120px;
    padding: 7px 15px;
    margin-right: 15px;
    }
    .CreateModel_next-btn__1fJ6L{
        text-align: center;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    padding: 7px 15px;
    min-width: 120px;
    border: 2px solid #FF7700;
    }

    .CreateModel_disabled-next__AK2Hi{
        text-align: center;
        border-radius: 8px;
        padding: 7px 15px;
        min-width: 120px;
        background-color:#B5B4B4
    }

/* dropzone */

.CreateModel_try-drag-drop__3woim {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
    cursor: pointer;
  }

  .CreateModel_drop-box-text__OsJaN {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .CreateModel_drop-box-text__OsJaN div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .CreateModel_back-button__1Lp7y{
    border: unset;
    border-radius: 11px;
    padding-top: 100px !important;
    background-color: #fff;
  }


  /* @Responsive  */
  @media screen and (max-width: 767px) and (min-width: 200px){
  .CreateModel_upload___DjPL h1 {
      font-size: 20px;
      line-height: 30px;
  }
  .CreateModel_upload-screen__1bqqa{
      padding: 80px 0 0 60px;
  }
  .CreateModel_upload-box__Zsh36{
    margin: 0px 0 5px;
}
.CreateModel_upload-text__KkPW2 h3 {
    font-size: 16px;
    line-height: 25px;
    margin: 0;
}
.CreateModel_upload-box__Zsh36 h4 {
    font-size: 14.6182px;
}
.CreateModel_upload-box__Zsh36 p { 
    margin: 0 0 5px;
}
.CreateModel_upload-box__Zsh36 img{
    width: 50px;
}
}


.youtubeGif_upload-image__3_cQo{
    text-align: center;
}
.youtubeGif_upload-heading__1pBfD h1{
    font-weight: 500;
    font-size: 22px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.397475px; 
    color: #232121;
    margin-bottom: 30px;
}
.youtubeGif_upload-heading__1pBfD h1 span{
    border-bottom: 3.1798px solid #FF7700;
}
.youtubeGif_upload-go__1H9Oe{
    background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
margin-bottom: 30px;
min-width: 180px;
-webkit-filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
}
.youtubeGif_upload-heading__1pBfD img{
    width: 100%;
    margin-bottom: 30px;
}
.Saree_upload-screen__3UhiS{
    padding: 100px 0 60px;
    }
    .Saree_upload__1yFAo{
    
    }
    .Saree_upload__1yFAo h1{
        font-weight: 800;
        font-size: 35px;
        line-height: 50px;  
        color: #18191F;
    }
    .Saree_upload__1yFAo p{
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;  
        color: #000000;
    }
    .Saree_upload__1yFAo p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .Saree_float-right__29NmT{
        float: right;
    }
    .Saree_upload-cols__4e_ZW{
        text-align: center;
    }
    .Saree_upload-single__3h1xV{
    
    }
    .Saree_upload-single__3h1xV:after{
        display: inline-block;
        content: "";
        position: absolute;
        top: 25%;
        left: 50%;
        width: 100%;
        /* height: 10px; */
        border-top: 1px dashed #B5B4B4;
        z-index: -1;
    }
    .Saree_upload-single__3h1xV+.Saree_upload-single__3h1xV:after{
        border: 0!important;
    }
    .Saree_upload-single__3h1xV p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .Saree_upload-single__3h1xV p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .Saree_active__5tWX7{
        color: #FF7700!important;
        }
        .Saree_upload-single__3h1xV p.Saree_active__5tWX7 {
            color: #FF7700!important;
        }
        .Saree_upload-single__3h1xV p.Saree_active__5tWX7 span{
            background: #FF7700!important;
        }
    .Saree_upload-box__iokTr{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 20px;
    }
    .Saree_upload-box__iokTr h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .Saree_upload-box__iokTr p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .Saree_upload-text__2OsWB{
        text-align: center;
    }
    .Saree_upload-text__2OsWB h3{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px; 
        color: #18191F;
    }
     
    .Saree_skip-btn__kZjFd{
        background: #FFFFFF;
    border: 2px solid #FF7700;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #FF7700;
    min-width: 120px;
    padding: 7px 15px;
    margin-right: 15px;
    }
    .Saree_next-btn__j848n{
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    padding: 7px 15px;
    min-width: 120px;
    border: 2px solid #FF7700;
    }
    .Saree_heading__2juat{
    
    }
    .Saree_heading__2juat h2{
        font-weight: normal;
    font-size: 24px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #000000;
    margin-bottom: 30px;
    }
    .Saree_saree-pallu__29IZ-{
        border-top: 1px solid #CBCBCB;
    }
    .Saree_saree-left__2UPRL{
        border-right: 1px solid #CBCBCB;
        padding: 20px;
    }
    .Saree_saree-left__2UPRL h6{
        text-align: center;
    }
    .Saree_saree-left__2UPRL button{
        margin: 0 10px;
        font-weight: bold;
    font-size: 12px;
    line-height: 12px; 
    color: #FFFFFF;
    }
    .Saree_saree-image__1ul_L{
    
    }
    .Saree_saree-image__1ul_L img{
        width: 100%;
        margin: 50px auto;
        object-fit: contain;
    }
    
    .Saree_upload-btn__1kI8K{
        background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
    font-weight: 500;
    font-size: 14.3091px;
    line-height: 21px; 
    color: #FFFFFF;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    }
    .Saree_saree-text__JXCwZ{
    
    }
    .Saree_saree-text__JXCwZ p{
        font-weight: normal;
        font-size: 11px;
        line-height: 20px; 
        text-align: center;
        letter-spacing: 0.02em; 
        color: #000000;
        margin-top: 100%;
    }
    .Saree_saree-text__JXCwZ p span{
    display: block;
    margin-top: 100%;
    }
    .Saree_saree-image__1ul_L p{
    
    }
    .Saree_saree-image__1ul_L p span{
    
    }
    .Saree_saree-right__3o_sR{
    
    }
    .Saree_saree-right__3o_sR h4{
        font-weight: bold;
    font-size: 18px;
    line-height: 21px; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em; 
    color: #000000;
    margin-top: 20px;
    }
    .Saree_saree-image__1ul_L svg{
    
    }
    .Saree_saree-image__1ul_L h3{
        font-size: 16px;
        line-height: 110%;
        letter-spacing: 0.5px;
        color: #000000;
        -webkit-transform: rotate(
    -90deg
    );
                transform: rotate(
    -90deg
    );
        float: right;
        margin-top: -60%;
        position: relative;
        margin-right: -20%;
    
    }
    .Saree_right-image__1Gjux{
        text-align: center;
    }
    .Saree_right-image__1Gjux img{
        padding: 15px 10%;
        width: 80%;
    }
    .Saree_right-image__1Gjux svg{
        width: 10%;
    }
     .Saree_right-image__1Gjux p img{
        padding: 0;
        width: auto!important;
        margin-right: 5px!important;
     }
     .Saree_right-image__1Gjux p {
         text-align: left;
         font-weight: 300;
    font-size: 13px;
    line-height: 15px;  
    letter-spacing: 0.02em; 
    color: #686464;
     }
    
    
 /* @Responsive  */
 @media screen and (max-width: 767px) and (min-width: 200px){
    .Saree_saree-text__JXCwZ p { 
        margin-top: 15px;
    }
    .Saree_saree-text__JXCwZ p span {
        margin-top: 15px;
    }
    .Saree_saree-image__1ul_L img {
        margin: 15px auto;
    }
    .Saree_heading__2juat h2 {
        font-size: 17px;
    } 
    .Saree_saree-left__2UPRL{
        padding: 15px;
    }
    .Saree_saree-left__2UPRL button {
        min-width: auto;
        margin: 0 5px;
    }
 }
    
 @media screen and (max-width: 767px) and (min-width: 366px){
    .Saree_saree-image__1ul_L h3 { 
        margin-top: -50%!important; 
        margin-right: -10%!important;
    }
 }
 @media screen and (max-width: 365px){
    .Saree_saree-image__1ul_L h3 { 
        margin-top: -60%!important; 
        margin-right: -25%!important;
    }
 }  
 @media screen and (max-width: 1024px) and (min-width: 768px){   
 .Saree_saree-image__1ul_L h3 { 
    margin-top: -100%; 
    margin-right: -75%;
    
 }
 .Saree_right-image__1Gjux p img { 
    margin: 0;
 }
 .Saree_saree-text__JXCwZ p span { 
    margin-top: 60%;
}
.Saree_single-model__2A14i img {
    height: 130px;
    border-radius: 25px;
}

}
.Blouse_upload-screen__2hjZr{
    padding: 100px 0 60px;
    }
    .Blouse_upload__1seyq{
    
    }
    .Blouse_upload__1seyq h1{
        font-weight: 800;
        font-size: 35px;
        line-height: 50px;  
        color: #18191F;
    }
    .Blouse_upload__1seyq p{
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;  
        color: #000000;
    }
    .Blouse_upload__1seyq p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .Blouse_float-right__13Mwk{
        float: right;
    }
    .Blouse_upload-cols__2kgyw{
        text-align: center;
    }
    .Blouse_upload-single__S-3jz{
    
    }
    .Blouse_upload-single__S-3jz:after{
        display: inline-block;
        content: "";
        position: absolute;
        top: 25%;
        left: 50%;
        width: 100%;
        /* height: 10px; */
        border-top: 1px dashed #B5B4B4;
        z-index: -1;
    }
    .Blouse_upload-single__S-3jz+.Blouse_upload-single__S-3jz:after{
        border: 0!important;
    }
    .Blouse_upload-single__S-3jz p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .Blouse_upload-single__S-3jz p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .Blouse_active__3cVQ7{
        color: #FF7700!important;
        }
        .Blouse_upload-single__S-3jz p.Blouse_active__3cVQ7 {
            color: #FF7700!important;
        }
        .Blouse_upload-single__S-3jz p.Blouse_active__3cVQ7 span{
            background: #FF7700!important;
        }
    .Blouse_upload-box__188up{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 20px;
    }
    .Blouse_upload-box__188up h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .Blouse_upload-box__188up p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .Blouse_upload-text__3WK6G{
        text-align: center;
    }
    .Blouse_upload-text__3WK6G h3{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px; 
        color: #18191F;
    }
     
    .Blouse_skip-btn__yu9wK{
        background: #FFFFFF;
    border: 2px solid #FF7700;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #FF7700;
    min-width: 120px;
    padding: 7px 15px;
    margin-right: 15px;
    }
    .Blouse_next-btn__2gOo5{
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    padding: 7px 15px;
    min-width: 120px;
    border: 2px solid #FF7700;
    }
    .Blouse_heading__1MNwC{
    
    }
    .Blouse_heading__1MNwC h2{
        font-weight: normal;
    font-size: 24px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #000000;
    margin-bottom: 30px;
    }
    .Blouse_saree-pallu__194Yg{
        border-top: 1px solid #CBCBCB;
    }
    .Blouse_saree-left__77Mr9{
        border-right: 1px solid #CBCBCB;
        padding: 20px;
    }
    .Blouse_saree-left__77Mr9 h6{
        text-align: center;
    }
    .Blouse_saree-left__77Mr9 button{
        margin: 0 10px;
        font-weight: bold;
    font-size: 12px;
    line-height: 12px; 
    color: #FFFFFF;
    }
    .Blouse_saree-image__1NRoR{
    
    }
    .Blouse_saree-image__1NRoR img{
        width: 100%;
        margin: 20px auto;
    }
    
    .Blouse_upload-btn__11Hth{
        background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
    font-weight: 500;
    font-size: 14.3091px;
    line-height: 21px; 
    color: #FFFFFF;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    }
    .Blouse_saree-text__1t1cS{
    
    }
    .Blouse_saree-text__1t1cS p{
        font-weight: normal;
        font-size: 11px;
        line-height: 20px; 
        text-align: center;
        letter-spacing: 0.02em; 
        color: #000000;
        margin-top: 100%;
    }
    .Blouse_saree-text__1t1cS p span{
    display: block;
    margin-top: 100%;
    }
    .Blouse_saree-image__1NRoR p{
    
    }
    .Blouse_saree-image__1NRoR p span{
    
    }
    .Blouse_saree-right__19tM2{
    
    }
    .Blouse_saree-right__19tM2 h4{
        font-weight: bold;
    font-size: 18px;
    line-height: 21px; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em; 
    color: #000000;
    margin-top: 20px;
    }
    .Blouse_saree-image__1NRoR svg{
    
    }
    .Blouse_saree-image__1NRoR h3{
        font-size: 16px;
        line-height: 110%;
        letter-spacing: 0.5px;
        color: #000000;
        -webkit-transform: rotate(
    -90deg
    );
                transform: rotate(
    -90deg
    );
        float: right;
        margin-top: -60%;
        position: relative;
        margin-right: -20%;
    
    }
    .Blouse_right-image__3AKgM{
        text-align: center;
    }
    .Blouse_right-image__3AKgM img{
        padding: 15px 10%;
        width: 80%;
    }
    .Blouse_right-image__3AKgM svg{
        width: 10%;
    }
     .Blouse_right-image__3AKgM p img{
        padding: 0;
        width: auto!important;
        margin-right: 5px!important;
     }
     .Blouse_right-image__3AKgM p {
         text-align: left;
         font-weight: 300;
    font-size: 13px;
    line-height: 15px;  
    letter-spacing: 0.02em; 
    color: #686464;
     }
    
    
    
    
    
    
    
    


.UploadSuit_suit-upload__1h6do{
    padding: 0 10%;
}

.UploadSuit_next-btn__1SOxj{ 
    border-radius: 5px;
    padding: 5px 15px;
    min-width: 120px;
    border: 2px solid #FF7700;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}
.UploadSuit_next-btn__1SOxj img{
    width: 20px;
    vertical-align: sub;
    margin-right: 5px;
}
 
.UploadSuit_heading__2CbYh h2{
    font-weight: normal;
font-size: 24px;
line-height: 110%; 
text-align: center;
letter-spacing: 0.5px; 
color: #000000;
margin-bottom: 30px;
}
 .UploadSuit_fabric-upload__22rsr{
    margin-bottom: 15px;
 }
.UploadSuit_fabric-upload__22rsr h3{
font-size: 16px;
line-height: 110%; 
text-align: center;
letter-spacing: 0.5px;
font-weight: normal;
color: #FF7700;
margin-bottom: 20px;
}
.UploadSuit_fabric-upload__22rsr img{
width: 75%;
}
.UploadSuit_fabric-upload__22rsr span{
color: #ff7700;
font-size: 20px;
border: 1px solid #ff7700;
border-radius: 50%;
padding: 0px 8px;
line-height: 0px;
vertical-align: top;
position: relative;
margin-left: -15px;
cursor: pointer;
}
.UploadSuit_upload-cols__3f0KG button{
    border-radius: 5px;
    margin: 0 5px;
    font-size: 14px;
    padding: 10px 25px;
    min-width: 40%;
}
.UploadSuit_create-model__hj30l{
background: url('https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/bg1.png');

background-repeat: no-repeat;
padding: 100px 0 50px;
background-size: 100% 95%;
font-family: 'Manrope';
}
.UploadSuit_heading__2CbYh h6{
font-weight: bold;
font-size: 20px;
line-height: 38px; 
color: #929292;
font-family: 'Manrope';
}
.UploadSuit_heading__2CbYh{
padding: 5% 15px 0 5%;

}
.UploadSuit_heading__2CbYh h1{
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 50px;
    line-height: 70px;  
    color: #18191F;
    margin: 10px 0px 30px;
}
.UploadSuit_heading__2CbYh h1 span{
    color: #FF7700;
}
.UploadSuit_heading__2CbYh p{
    font-family: 'Manrope';
}

.UploadSuit_demo__1VUuh{
    background: #FFFFFF;
border: 2px solid #FF7700;
box-sizing: border-box;
box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px; 
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #ff7700; 
min-width: 180px; 
margin-left: 10px;;
}
.UploadSuit_nxt-btn__2J3Iq{
    background: #FF7700;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.UploadSuit_model-gif__C9vhM{
    text-align: center;
}
.UploadSuit_model-gif__C9vhM img{
    max-width: 100%;
    width: 75%;
}

/* ########## Category ############# */

.UploadSuit_category-select__2nX5C{
    padding: 120px 0 50px;
}
 
.UploadSuit_heading__2CbYh h1{
    font-weight: 800;
    font-size: 45px;
    line-height: 72px;  
    color: #18191F;
    text-align: center;
    margin-bottom: 30px;
}
.UploadSuit_category-list__16dpR ul{
padding: 2% 0 5%;
list-style: none;
}
.UploadSuit_category-list__16dpR ul li{
    display: inline-block;
    width: 20%;
    padding: 10px 15px;
}
.UploadSuit_category-list__16dpR ul li:hover{
    border: 2px solid black;
    cursor: pointer;
}
 
.UploadSuit_selected__3sNdA{
    border: 2px solid red; 
}

.UploadSuit_category-list__16dpR ul li div{
    width: 100%; 
    text-align: center;
}
.UploadSuit_category-list__16dpR ul li img{
    width: 100%;
}
.UploadSuit_category-list__16dpR ul li p{
    font-weight: 500;
font-size: 18px;
line-height: 36px; 
color: #4F4F4F;
}

.UploadSuit_nxt-btn__2J3Iq{
    background: #FF7700;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.UploadSuit_upload-image__1njY_{
    text-align: center;
}
.UploadSuit_upload-heading__2hg_A h1{
    font-weight: 500;
    font-size: 22px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.397475px; 
    color: #232121;
    margin-bottom: 30px;
}
.UploadSuit_upload-heading__2hg_A h1 span{
    border-bottom: 3.1798px solid #FF7700;
}
.UploadSuit_upload-go__3sa9U{
    background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
margin-bottom: 30px;
min-width: 180px;
-webkit-filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
}
.UploadSuit_upload-heading__2hg_A img{
    width: 100%;
    margin-bottom: 30px;
}
.UploadSuit_active__Ir6jz{
    color: #FF7700!important;
    }
    .UploadSuit_upload-single__36_NF p.UploadSuit_active__Ir6jz {
        color: #FF7700!important;
    }
    .UploadSuit_upload-single__36_NF p.UploadSuit_active__Ir6jz span{
        background: #FF7700!important;
    }


/* upload Section */

.UploadSuit_back-category-btn__30_A0{
    position: absolute;
    margin-left: 3%;
}

.UploadSuit_upload-screen__2TL71{
    padding: 100px 0 0px;
    }
     
    .UploadSuit_upload__ACp9S h1{
        font-weight: 800;
        font-size: 35px;
        line-height: 50px;  
        color: #18191F;
    }
    .UploadSuit_upload__ACp9S p{
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;  
        color: #000000;
    }
    .UploadSuit_upload__ACp9S p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .UploadSuit_float-right__QIhQB{
        float: right;
    }
    .UploadSuit_upload-cols__3f0KG{
        text-align: center;
    }
     
    .UploadSuit_upload-single__36_NF:after{
        display: inline-block;
        content: "";
        position: absolute;
        top: 25%;
        left: 50%;
        width: 100%;
        z-index: -1;
    }
    .UploadSuit_upload-single__36_NF+.UploadSuit_upload-single__36_NF:after{
        border: 0!important;
    }
    .UploadSuit_upload-single__36_NF p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .UploadSuit_upload-single__36_NF p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .UploadSuit_upload-box__1lW4h{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 20px;
    }
    .UploadSuit_upload-box__1lW4h h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .UploadSuit_upload-box__1lW4h p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .UploadSuit_upload-text__19Dbr{
        text-align: center;
    }
    .UploadSuit_upload-text__19Dbr h3{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px; 
        color: #18191F;
    }
     
    .UploadSuit_skip-btn__1rita{
        text-align: center;
        background: #FFFFFF;
    border: 2px solid #FF7700;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #FF7700;
    min-width: 120px;
    padding: 7px 15px;
    margin-right: 15px;
    }
    .UploadSuit_next-btn__1SOxj{
        text-align: center;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    padding: 7px 15px;
    min-width: 120px;
    border: 2px solid #FF7700;
    }

    .UploadSuit_disabled-next__w7nye{
        text-align: center;
        border-radius: 8px;
        padding: 7px 15px;
        min-width: 120px;
        background-color:#B5B4B4
    }

/* dropzone */
.UploadSuit_upload-kurti__38Np6{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 3%;
    padding-left: 6%;
}
.UploadSuit_select-model__17IAf{
    text-align: center;
    padding: 5%;
}
.UploadSuit_try-drag-drop__31Grz {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
    cursor: pointer;
  }

  .UploadSuit_drop-box-text__3fEqF {
    position: fixed;
    width: 50%;
    height: 50%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .UploadSuit_drop-box-text__3fEqF div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .UploadSuit_back-button__3lObr{
    padding-top: 100px !important;
    border: unset;
    border-radius: 11px;
    background-color: #fff;
  }

  /* @responsive  */
@media screen and (max-width: 767px) and (min-width: 200px){
    .UploadSuit_main-box__3yvd7{
        padding-left: 60px;
    }
    .UploadSuit_upload__ACp9S h1 { 
        font-size: 20px;
        line-height: 30px;
    }
    .UploadSuit_upload__ACp9S p { 
        font-size: 14px;
    }
    .UploadSuit_upload-screen__2TL71{
        padding: 80px 0 0px;
    }
.UploadSuit_select-model__17IAf button{
    padding: 7px 20px;
    font-size: 14px;
    line-height: 22px;
}
.UploadSuit_upload-kurti__38Np6{ 
    grid-template-columns: 100%;
    grid-gap: 0%;
    padding: 0 15px;
}
.UploadSuit_upload-box__1lW4h{
    margin: 0px 0 5px;
}
.UploadSuit_upload-text__19Dbr h3 {
    font-size: 16px;
    line-height: 25px;
    margin: 0;
}
.UploadSuit_upload-box__1lW4h h4 {
    font-size: 14.6182px;
}
.UploadSuit_upload-box__1lW4h p { 
    margin: 0 0 5px;
}
.UploadSuit_upload-box__1lW4h img{
    width: 50px;
}
.UploadSuit_select-model__17IAf {
    margin-bottom: 20px;
}
.UploadSuit_fabric-upload__22rsr img {
    margin-bottom: 15px;
}
.UploadSuit_upload-cols__3f0KG button { 
    margin: 0 5px 20px;
    font-size: 12px;
    padding: 10px 12px;
    width: 45%;
}
.UploadSuit_upload-main__3jGRH{
    padding-left: 60px;
}
}

@media screen and (max-width: 900px) and (min-width: 768px){
    .UploadSuit_main-box__3yvd7{
        padding-left: 60px;
    }
    .UploadSuit_upload-kurti__38Np6 {
        grid-template-columns: 31% 31% 31%;
        padding: 0 15px;
    }
    .UploadSuit_upload-box__1lW4h {
        margin: 0 0 5px;
    }
    .UploadSuit_upload-text__19Dbr h3 {
        font-size: 18px;
    }
    .UploadSuit_upload-cols__3f0KG button {
        border-radius: 5px;
        margin: 0 2px;
        font-size: 12px;
        padding: 10px 10px;
        width: 46%;
}
.UploadSuit_single-model__ONKSs img {
    width: 100%;
    height: 150px;
    object-fit: contain;
}
 
}


.SelectModelSuit_select-model__3sqLG{
    padding: 100px 0 60px;
}
.SelectModelSuit_upload__KApP2{
    text-align: center;
}
.SelectModelSuit_upload-btn__3c8UK{
    background: #FF7700;
    border-radius: 5px;
    min-width: 120px;
}
.SelectModelSuit_previous__3-hj9{
    float:left;
}

.SelectModelSuit_prev-screen__23jX1{

    text-align: center;
    background: #FF7700;
    border-radius: 50%;
    font-size: 30px;
    padding: 3px 10px 5px;
}
.SelectModelSuit_upload-btn__3c8UK img{
    width: 22px;
    margin-right: 7px;
}
.SelectModelSuit_credit-area__2EPtA{
    padding: 15px 0 0;
}
.SelectModelSuit_available-credit__zI2Yf{
    font-size: 14px;
    line-height: 150%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #232121;
    
}
.SelectModelSuit_available-credit__zI2Yf img{
    width: 35px;
    margin-right: 10px;
}
.SelectModelSuit_available-credit__zI2Yf span{
    color: #f70;
   
}
.SelectModelSuit_right-top-credits__2QM9q{
text-align: right;

}
.SelectModelSuit_right-top-credits__2QM9q button{
    padding: 5px 10px;
    width: auto;
    background: linear-gradient(
180deg
,hsla(0,0%,100%,.94),hsla(0,0%,100%,.24) 52.08%,hsla(0,0%,100%,0) 52.09%,hsla(0,0%,100%,.29)),linear-gradient(
180deg
,hsla(0,0%,100%,.4),hsla(0,0%,100%,0) 51.56%),#f70;
    background-blend-mode: soft-light,normal,normal;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), inset 0 -2px 6px rgb(44 0 0 / 35%);
    border-radius: 32px;
}
.SelectModelSuit_right-top-credits__2QM9q a{

}
.SelectModelSuit_right-top-credits__2QM9q a span{
    
}
.SelectModelSuit_active__2W2GV{
color: #FF7700!important;
}
.SelectModelSuit_model-tabs__1CcXm{
border-right: 1px solid #ddd;
}
.SelectModelSuit_model-tabs__1CcXm {
    padding: 10px 0;
    list-style: none;
    margin: 0;

}
 
.SelectModelSuit_model-tabs__1CcXm li{
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px; 
    padding: 10px 20px;
    color: #989898;
}
.SelectModelSuit_model-tabs__1CcXm li span{
    background: #989898;
    color: #fff;
    border-radius: 50%;
    padding: 4px 10px;
    margin-right: 5px;
}
.SelectModelSuit_model-tabs__1CcXm li--selected span{
    background: #FF7700;
}
.SelectModelSuit_model-area__2bkzY{
    background: #FFFFFF;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
border-radius: 5px;
margin: 30px 0;

padding: 0;

}
.SelectModelSuit_model-saree__3lOhe{
    /* border-top: 1px solid #EBEAEA;
    padding: 10px 0; */
}
.SelectModelSuit_model-saree__3lOhe h4{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px; 
color: #000000; 
padding: 0px 20px 5px;
}
.SelectModelSuit_model-saree__3lOhe h4 span{
    color: #FF7700;
    /* float: right; */
}
.SelectModelSuit_pose__O4zI_ h4 span{
     float: right;
}
.SelectModelSuit_model-saree__3lOhe h4 label{
    padding: 5px 10px;
    background: #F0F0F1;
    border-radius: 77.7857px;
    font-size: 15px;
    float: right;
    order: 3;
    margin: 0 0 0 10px;
    min-width: 28%;
    text-align: left;
    margin-top: -5px;
}
.SelectModelSuit_model-saree__3lOhe h4 label img{
width: auto;
}
.SelectModelSuit_model-saree__3lOhe h4 label input{
    border: 0;
    font-size: 14px;
    background: transparent;
    width: 80%;
}
.SelectModelSuit_model-saree__3lOhe h4 label input::-webkit-input-placeholder{
    font-size: 14px;
}
.SelectModelSuit_model-saree__3lOhe h4 label input:-ms-input-placeholder{
    font-size: 14px;
}
.SelectModelSuit_model-saree__3lOhe h4 label input::placeholder{
    font-size: 14px;
}
.SelectModelSuit_single-model__1dKz5{
    padding: 15px 10px;
}
.SelectModelSuit_single-model__1dKz5 img{
    /* border: 1.55921px solid #C9C9C9;
box-sizing: border-box;
border-radius: 23.3882px; */
width: 100%;
height: 400px;
    object-fit: contain;
}

.SelectModelSuit_single-model__1dKz5 button{
    background: transparent;
    padding: 0;
    margin-bottom: 0px;
    position: relative;
    border: 1.55921px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 23.3882px;
}
.SelectModelSuit_single-model__1dKz5 button.SelectModelSuit_active__2W2GV {
    border-color: #FF7700 !important;
}
.SelectModelSuit_single-model__1dKz5 button p{
    position: absolute;
    bottom: 0px;
    background: #fff; 
    left: 1px;
    margin: 0;
    padding: 8px 10px;
    border-radius: 0px 0px 23.3882px 23.3882px;
    right: 2px;
    color: #333;
    transition: .2s ease-in-out;
    font-weight: 500;
    font-size: 10px;
    line-height: 110%;
    letter-spacing: 0.5px;
    text-align: left;
    color: #232121;
    transition: .2s ease-in-out;
}
.SelectModelSuit_single-model__1dKz5 button p span{
    float: right;
}
.SelectModelSuit_single-model__1dKz5 h5{
    position: absolute;
    top: -47%;
    right: -10px;
    width: auto;
    height: auto;
    transition: .2s ease-in-out;
}
.SelectModelSuit_single-model__1dKz5  button p span img{
    width: 11px;
    margin-right: 5px;
    height: auto;
    border: 0;
}
.SelectModelSuit_selection-model__ZHJ6D{
    height: 460px;
    overflow: auto;
}
.SelectModelSuit_display-none__3zLxi{
    display: none;
}
.SelectModelSuit_model-tabdetails__37D3v{
    border-top: 1px solid #EBEAEA;
    padding: 15px 0;
}
.SelectModelSuit_model-saree__3lOhe h6{
    text-align: center;
    padding: 10px 0;
}
.SelectModelSuit_marketplace-select__JPwmF button{
    background: #f4f4f4;
    width: 100%;
}
.SelectModelSuit_marketplace__3tzR2{
text-align: center;
}
.SelectModelSuit_text-head__2I24m{
    float: left!important;
    color: #000!important;
}

.SelectModelSuit_marketplace__3tzR2 button img{
    width: 100%;
    height: 165px;
    object-fit: contain;
}
.SelectModelSuit_single-model__1dKz5 h6 img{
    height: auto;
    width: auto;
    /* position: absolute;
    top: -10px;
    right: -10px; */
}




.SelectModelSuit_try-drag-drop__3dL5t {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
    cursor: pointer;
  }

  .SelectModelSuit_drop-box-text__2k3QK {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .SelectModelSuit_drop-box-text__2k3QK div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .SelectModelSuit_back-button__2TsNw{
    border: unset;

    border-radius: 11px;
  
    padding: 0;
  }

  .SelectModelSuit_toggle1__3chjq {
    position: relative;
    color: #f39e53;
  }
  .SelectModelSuit_toggle1__3chjq input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .SelectModelSuit_slider1__Ck32U {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 190px;
  box-shadow: 0px 4px 4px rgb(255, 255, 255);
  border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
  }
  
  .SelectModelSuit_slider1__Ck32U::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top:3px
  
  }
  
  input:checked + .SelectModelSuit_slider1__Ck32U {
    background-color: #78f353;
  }
  
  input:checked + .SelectModelSuit_slider1__Ck32U::before {
    -webkit-transform: translateX(21.5px);
            transform: translateX(21.5px);
  }
  .SelectModelSuit_button-padding__2k9iI{
    padding: 0px 0px 0px 40px;

  }


/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px){
    .SelectModelSuit_right-top-credits__2QM9q{
        padding-left: 0;
        margin-bottom: 10px;
    }
    .SelectModelSuit_select-model__3sqLG{
        margin-left: -60px;
        padding: 80px 0 30px;
    }
    .SelectModelSuit_single-model__1dKz5 img {
        height: 100px;
        padding: 5px;
    }
    .SelectModelSuit_selection-model__ZHJ6D {
        height: auto;
    }
    .SelectModelSuit_model-tabs__1CcXm li {
        padding: 10px 15px;
    }
    .SelectModelSuit_available-credit__zI2Yf {
        font-size: 12px;
        text-align: left;
    }
    .SelectModelSuit_right-top-credits__2QM9q button {
        font-size: 12px;
    }
    .SelectModelSuit_model-saree__3lOhe h4 {
        font-size: 12px;
        
        padding: 0px 15px 5px;
    }
    .SelectModelSuit_main-box__3DtBt{
        padding-left: 60px;
    }
    .SelectModelSuit_pose__O4zI_ h4 span {
        float: left;
    }
    .SelectModelSuit_button-padding__2k9iI {
        padding: 0 10px;
    }
    .SelectModelSuit_single-model__1dKz5 {
        text-align: center;
    }
    .SelectModelSuit_single-model__1dKz5 button {
        overflow: hidden;
    }
    }
@media screen and (max-width: 900px) and (min-width: 768px){
  .SelectModelSuit_main-box__3DtBt{
      padding-left: 60px;
  }
    .SelectModelSuit_select-model__3sqLG{
        margin-left: -60px;
    }
    .SelectModelSuit_single-model__1dKz5 img { 
        height: 200px;
    }
    }
@media screen and (max-width: 1024px) and (min-width: 901px){
  
    .SelectModelSuit_select-model__3sqLG{
        margin-left: -60px;
    }
    .SelectModelSuit_single-model__1dKz5 img { 
        height: 200px;
    }
    .SelectModelSuit_main-box__3DtBt{
        padding-left: 60px;
    }
    }






.UploadCurtains_suit-upload__2BPa0{
    padding: 0 10%;
}
.UploadCurtains_upload-screen__32wbR{
    padding: 100px 0 60px;
    }
    .UploadCurtains_upload__bYIqS{
    text-align: center;
    }
    .UploadCurtains_upload__bYIqS h1{
        font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    color: #18191F;
    margin: 0;
    }
    .UploadCurtains_upload__bYIqS p{
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;  
        color: #000000;
        margin-bottom: 30px;
    }
    .UploadCurtains_upload__bYIqS p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .UploadCurtains_float-right__23x_W{
        float: right;
    }
    .UploadCurtains_upload-cols__21FwB{
        text-align: center;
    }
    .UploadCurtains_upload-single__2gF3f{
    
    }
   
    .UploadCurtains_upload-single__2gF3f p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .UploadCurtains_upload-single__2gF3f p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .UploadCurtains_rotate-btn__3YrTo{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 1%;
        margin-top: 5%;
    }
    .UploadCurtains_active__bK2NX{
        color: #FF7700!important;
        }
        .UploadCurtains_upload-single__2gF3f p.UploadCurtains_active__bK2NX {
            color: #FF7700!important;
        }
        .UploadCurtains_upload-single__2gF3f p.UploadCurtains_active__bK2NX span{
            background: #FF7700!important;
        }
    .UploadCurtains_upload-box__WbOLb{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 0px;
    }
    .UploadCurtains_upload-box__WbOLb img{
        width: auto;
        margin: 0 auto 10px;
    }
    .UploadCurtains_upload-box__WbOLb h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .UploadCurtains_upload-box__WbOLb p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .UploadCurtains_upload-text__3gFDR{
        text-align: center;
    }
    .UploadCurtains_upload-text__3gFDR h3{
        font-weight: 500;
        font-size: 20px;
        line-height: 45px; 
        color: #18191F;
        margin-bottom: 35px;
    }
     
    
    .UploadCurtains_next-btn__1bCeh{ 
        border-radius: 5px;
        padding: 5px 15px;
        min-width: 120px;
        border: 2px solid #FF7700;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
    }
    .UploadCurtains_next-btn__1bCeh img{
        width: 20px;
        vertical-align: sub;
        margin-right: 5px;
    }
    .UploadCurtains_heading__1K5Rk{
    
    }
    .UploadCurtains_heading__1K5Rk h2{
        font-weight: normal;
    font-size: 24px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #000000;
    margin-bottom: 30px;
    }
    .UploadCurtains_fabric-upload__2gwou{

    }
.UploadCurtains_fabric-upload__2gwou h3{
    font-size: 16px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: normal;
    color: #FF7700;
    margin-bottom: 20px;
}
.UploadCurtains_fabric-upload__2gwou img{
    width: 75%;
}
.UploadCurtains_fabric-upload__2gwou span{
    color: #ff7700;
    font-size: 20px;
    border: 1px solid #ff7700;
    border-radius: 50%;
    padding: 0px 8px;
    line-height: 0px;
    vertical-align: top;
    position: relative;
    margin-left: -15px;
    cursor: pointer;
}



.UploadCurtains_create-model__1ku6_{
    background: url('https://storage.googleapis.com/spyne-website/non-automobile-try/3DSaree/bg1.png');
  
    background-repeat: no-repeat;
    padding: 100px 0 50px;
    background-size: 100% 95%;
    font-family: 'Manrope';
}
.UploadCurtains_heading__1K5Rk h6{
    font-weight: bold;
font-size: 20px;
line-height: 38px; 
color: #929292;
font-family: 'Manrope';
}
.UploadCurtains_heading__1K5Rk{
    padding: 5% 15px 0 5%;

}
.UploadCurtains_heading__1K5Rk h1{
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 50px;
    line-height: 70px;  
    color: #18191F;
    margin: 10px 0px 30px;
}
.UploadCurtains_heading__1K5Rk h1 span{
    color: #FF7700;
}
.UploadCurtains_heading__1K5Rk p{
    font-family: 'Manrope';
}

.UploadCurtains_demo__3BIPP{
    background: #FFFFFF;
border: 2px solid #FF7700;
box-sizing: border-box;
box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px; 
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #ff7700; 
min-width: 180px; 
margin-left: 10px;;
}
.UploadCurtains_nxt-btn__3czj-{
    background: #FF7700;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.UploadCurtains_model-gif__q2Zn2{
    text-align: center;
}
.UploadCurtains_model-gif__q2Zn2 img{
    max-width: 100%;
    width: 75%;
}

/* ########## Category ############# */

.UploadCurtains_category-select__W8-sk{
    padding: 120px 0 50px;
}

.UploadCurtains_heading__1K5Rk{

}
.UploadCurtains_heading__1K5Rk h1{
    font-weight: 800;
    font-size: 45px;
    line-height: 72px;  
    color: #18191F;
    text-align: center;
    margin-bottom: 30px;
}
.UploadCurtains_category-list___M3Ba ul{
padding: 2% 0 5%;
list-style: none;
}
.UploadCurtains_category-list___M3Ba ul li{
    display: inline-block;
    width: 20%;
    padding: 10px 15px;
}
.UploadCurtains_category-list___M3Ba ul li:hover{
    border: 2px solid black;
    cursor: pointer;
}
 
.UploadCurtains_selected__jmU_0{
    border: 2px solid red; 
}

.UploadCurtains_category-list___M3Ba ul li div{
    width: 100%; 
    text-align: center;
}
.UploadCurtains_category-list___M3Ba ul li img{
    width: 100%;
}
.UploadCurtains_category-list___M3Ba ul li p{
    font-weight: 500;
font-size: 18px;
line-height: 36px; 
color: #4F4F4F;
}

.UploadCurtains_nxt-btn__3czj-{
    background: #FF7700;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #fff;
    padding: 6px 15px;
    min-width: 180px;
    font-weight: bold;
font-size: 16px;
line-height: 36px; 
text-align: center;  
}
.UploadCurtains_upload-image__259yd{
    text-align: center;
}
.UploadCurtains_upload-heading__d9cR5 h1{
    font-weight: 500;
    font-size: 22px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.397475px; 
    color: #232121;
    margin-bottom: 30px;
}
.UploadCurtains_upload-heading__d9cR5 h1 span{
    border-bottom: 3.1798px solid #FF7700;
}
.UploadCurtains_upload-go__2xQOE{
    background: #FF7700;
    border-radius: 17.4889px;
    font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
margin-bottom: 30px;
min-width: 180px;
-webkit-filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(-0.79495px 3.1798px 7.9495px rgba(0, 0, 0, 0.25));
}
.UploadCurtains_upload-heading__d9cR5 img{
    width: 100%;
    margin-bottom: 30px;
}


/* upload Section */

.UploadCurtains_back-category-btn__2sVk5{
    /* position: absolute; */
    margin-left: 3%;
}

.UploadCurtains_upload-screen__32wbR{
    padding: 100px 0 60px;
    }
    .UploadCurtains_upload__bYIqS{
    
    }
    .UploadCurtains_upload__bYIqS h1{
        font-weight: 800;
        font-size: 35px;
        line-height: 50px;  
        color: #18191F;
    }
    .UploadCurtains_upload__bYIqS p{
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;  
        color: #000000;
    }
    .UploadCurtains_upload__bYIqS p span{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; 
        color: #FF7700;
    }
    .UploadCurtains_float-right__23x_W{
        float: right;
    }
    .UploadCurtains_upload-cols__21FwB{
        text-align: center;
    }
    .UploadCurtains_upload-single__2gF3f{
    
    }
    .UploadCurtains_upload-single__2gF3f:after{
        display: inline-block;
        content: "";
        position: absolute;
        top: 25%;
        left: 50%;
        width: 100%;
        /* height: 10px; */
        /* border-top: 1px dashed #B5B4B4; */
        z-index: -1;
    }
    .UploadCurtains_upload-single__2gF3f+.UploadCurtains_upload-single__2gF3f:after{
        border: 0!important;
    }
    .UploadCurtains_upload-single__2gF3f p{
        font-weight: normal;
        font-size: 16px;
        line-height: 110%; 
        text-align: center;
        letter-spacing: 0.5px;
        
        color: #B5B4B4;
    }
    .UploadCurtains_upload-single__2gF3f p span{
        display: block;
        background-color: #B5B4B4;
        padding: 5px;
        color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 auto 15px;
    }
    .UploadCurtains_upload-box__WbOLb{
        background: rgba(255, 119, 0, 0.05);
    border: 0.587273px dashed #FF7700;
    box-sizing: border-box;
    border-radius: 11.7455px;
    text-align: center;
    padding: 10px;
    color: #FF7700;
    margin: 20px;
    }
    .UploadCurtains_upload-box__WbOLb h4{
        font-weight: 500;
    font-size: 17.6182px;
    line-height: 100%; 
    align-items: center;
    text-align: center;
    letter-spacing: 0.998364px; 
    color: #FF7700;
    }
    .UploadCurtains_upload-box__WbOLb p{
        font-weight: normal;
    font-size: 7.04727px;
    line-height: 100%;  
    align-items: center;
    text-align: center;
    
    color: #FF7700;
    }
    .UploadCurtains_upload-text__3gFDR{
        text-align: center;
    }
   
     
    .UploadCurtains_skip-btn__22p--{
        text-align: center;
        background: #FFFFFF;
    border: 2px solid #FF7700;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    color: #FF7700;
    min-width: 120px;
    padding: 7px 15px;
    margin-right: 15px;
    }
    .UploadCurtains_next-btn__1bCeh{
        text-align: center;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    padding: 7px 15px;
    min-width: 120px;
    border: 2px solid #FF7700;
    }

    .UploadCurtains_disabled-next__1QrEU{
        text-align: center;
        border-radius: 8px;
        padding: 7px 15px;
        min-width: 120px;
        background-color:#B5B4B4
    }

/* dropzone */
.UploadCurtains_upload-kurti__eSshv{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 3%;
    padding-left: 3%;
}
.UploadCurtains_select-model__14HLS{
    text-align: center;
    padding: 5%;
}
.UploadCurtains_try-drag-drop__21UNt {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
    cursor: pointer;
  }

  .UploadCurtains_drop-box-text__1VxZQ {
    position: fixed;
    width: 50%;
    height: 50%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .UploadCurtains_drop-box-text__1VxZQ div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
.UploadCurtains_uploaded-img__3bLFg{
    padding: 100px 0 60px;
    width: 33.3%;
    margin-left: 33.34%;
    text-align: center;
}
.UploadCurtains_select-template__19Zdc{
    text-align: center;
    padding: 5%;
}
.UploadCurtains_back-button__1hmvC{
    border: unset;
    border-radius: 11px;
    padding: 0;
  }


  /* @Responsive  */
  @media screen and (max-width: 767px) and (min-width: 200px){
  .UploadCurtains_upload-screen__32wbR{
    padding: 80px 0 60px 60px;
}
.UploadCurtains_upload__bYIqS h1 {
    font-size: 20px;
    line-height: 30px;
}
.UploadCurtains_upload-box__WbOLb {
    margin: 0;
}
.UploadCurtains_upload-box__WbOLb img {
    width: 50px;
}
.UploadCurtains_upload-box__WbOLb h4 {
    font-size: 15.6182px;
}
.UploadCurtains_upload-box__WbOLb{
    margin-bottom: 5px;
}
.UploadCurtains_upload-text__3gFDR h3{
font-size: 16px;
margin-bottom: 0;
line-height: 25px;
}
.UploadCurtains_uploaded-img__3bLFg {
    padding: 80px 0 40px;
    width: 100%;
    margin-left: 0; 
}
.UploadCurtains_select-template__19Zdc button{
    padding: 7px 20px;
}
  }




:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.one {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* position: absolute; */
  /* left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); */
  text-align: center;
  margin: auto;
  margin-top: 5%;
  color: #ff7700;
}

.two {
  height: 20vh;
  width: 12vw;
  display: block;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
}

.three {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */
  text-align: center;
  margin-top: 4%;
  color: #232121;
}

.button {
  text-align: center;
  margin: auto;
  margin-bottom: 10%;
}


.Fabric_fabric-section__2dajq{
    margin: 75px 0 0px;
    background: url('https://storage.googleapis.com/spyne-website/try-categories/fab-bg.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.06));
            filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.06));
    margin-bottom: 30px;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 6%);
}

.Fabric_fabric-back__3lOAY span{
color: #18191F;
font-size: 45px;
margin: 0px 10px 0 0;
vertical-align: top;

}
.Fabric_fabric-back__3lOAY img{
    width: 90%;
}
.Fabric_fabric-content__1J904{
    padding: 30px 15px 0;
}
.Fabric_fabric-content__1J904 h4{
   
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 54px;
    letter-spacing: 0.03em;
    color: #18191F;

}
.Fabric_fabric-content__1J904 h4 span{
    color: #ff7700;
}

.Fabric_fabric-content__1J904 p{
    font-weight: 500;
font-size: 20px;
line-height: 44px; 
text-transform: capitalize; 
}
.Fabric_img-container__1pAGR img {
     
    width: 100%;
    object-fit: contain; 
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal7.png');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: 47%!important;
    height: 250px;
}
.Fabric_card__1Xizz h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    text-align: center;
    margin: 10px 0 0;
}
 
.Fabric_fabric2__3I49o img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal8.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.Fabric_card__1Xizz { 
    
    background: #FFFFFF;
mix-blend-mode: normal;
border: 1px solid #FF9200;
box-sizing: border-box;
box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
-webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px); 
border-radius: 9px;
padding: 20px;
transition: .2s ease-in-out;
}   
.Fabric_card__1Xizz:hover{ 
    border: 1.5px solid #FF9200; 
    box-shadow: 4px 5px 12px rgb(255 152 0 / 27%);
}
.Fabric_fabric-main__309-g{
    padding: 30px 0 50px;
}
link:hover{
    text-decoration: none;
}
.Fabric_text-black__ecNdS:hover {
    color: #000;
    text-decoration: none;
}

/* @Responsive  */


@media screen and (max-width: 767px) and (min-width: 366px){
    
}
@media screen and (max-width: 365px){
    
}
@media screen and (max-width: 900px) and (min-width: 768px){
    
}
@media screen and (max-width: 767px) and (min-width: 200px){
    
.Fabric_fabric-section__2dajq{ 
    background-position: top right;
    margin: 60px 0 0;
}
.Fabric_fabric-back__3lOAY span {
    font-size: 36px;
    vertical-align: sub;
    line-height: normal;
    line-height: initial;
}
.Fabric_fabric-back__3lOAY img {
    width: 80%;
}
.Fabric_fabric-back__3lOAY{
    text-align: center;
}
.Fabric_fabric-content__1J904 h4 { 
    font-size: 24px;
    line-height: 35px;
}
.Fabric_fabric-back__3lOAY a{
    float: left;
}
.Fabric_fabric-content__1J904 p { 
    font-size: 14px;
    line-height: 25px;
}
.Fabric_card__1Xizz {
    margin: 15px 0;
}

}




.mactive {
    background-color:white !important;
    color:#ff7700 !important; 
    border-bottom: 4px solid #ff7700 !important; 
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 100%;
    text-align: center;
    /* or 17px */
    letter-spacing: 1.7px;
  }
  .myclass{
      margin-bottom: 10px;
  }
.btn1{
    font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 100%;
/* or 17px */
letter-spacing: 1.7px;
border-bottom: 4px solid rgba(153, 150, 150, 0.1);
color: rgba(24, 25, 31, 0.51);
    background-color:white; 
  text-align: center;
  }
.btn1:hover {
    color:#ff7700 !important;
    border-bottom: 4px solid #ff7700 !important; 
  }
.circle {
    margin-left: 40%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;;
  }
.activecircle{
    background:#ff7700 ;
}
.ntactivecircle{
    background:#999696;
}
.textcircle{
    color: #fff;
    padding-top: 8px;
}
.activet{
    margin-left: 30px;
    color:#ff7700;
}
.ntactivet{
    margin-left: 30px;
    color:#999696 ;
}
.activet2{
    margin-right: 13%;
    color:#ff7700;
}
.ntactivet2{
    margin-right: 12%;
    color: #999696;
}

.downtext{
    padding-right: 17%;
}
hr.newhr{
    border-width: 0 !important;
    border-top: 3px dashed #999696 !important;
    background-color: #fff;
}
@media screen and (max-width: 600px) {
    .header-section {
      display:none;
    }
  }


.HomeFurnishing_fabric-section__3Xhrh{
    margin: 75px 0 0px;
    background: url('https://storage.googleapis.com/spyne-website/try-categories/fab-bg.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.06));
            filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.06));
    margin-bottom: 30px;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 6%);
}


.HomeFurnishing_fabric__2V7Yo{
    
}
.HomeFurnishing_fabric-back__3AUmF{

}
.HomeFurnishing_fabric-back__3AUmF span{
color: #18191F;
font-size: 45px;
margin: 0px 10px 0 0;
vertical-align: top;

}
.HomeFurnishing_fabric-back__3AUmF img{
    width: 90%;
}
.HomeFurnishing_fabric-content__3ikFD{
    padding: 30px 15px 0;
}
.HomeFurnishing_fabric-content__3ikFD h4{
   
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 54px;
    letter-spacing: 0.03em;
    color: #18191F;

}
.HomeFurnishing_fabric-content__3ikFD h4 span{
    color: #ff7700;
}

.HomeFurnishing_fabric-content__3ikFD p{
    font-weight: 500;
font-size: 20px;
line-height: 44px; 
text-transform: capitalize; 
}
.HomeFurnishing_img-container__3DNZu img {
     
    width: 100%;
    object-fit: contain; 
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal9.svg');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: 55%!important;
    height: 250px;
}
.HomeFurnishing_card__1cNhS h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    text-align: center;
    margin: 10px 0 0;
}
 
.HomeFurnishing_fabric12__3DuV_ img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal10.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.HomeFurnishing_fabric13__3Te9U img{
    background: url('https://storage.googleapis.com/spyne-website/try-categories/bal11.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.HomeFurnishing_card__1cNhS { 
    
    background: #FFFFFF;
mix-blend-mode: normal;
border: 1px solid #FF9200;
box-sizing: border-box;
box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
-webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px); 
border-radius: 9px;
padding: 20px;
transition: .2s ease-in-out;
}   
.HomeFurnishing_card__1cNhS:hover{ 
    border: 1.5px solid #FF9200; 
    box-shadow: 4px 5px 12px rgb(255 152 0 / 27%);
}  
.HomeFurnishing_fabric-main__2XMUP{
    padding: 30px 0 50px;
}
link:hover{
    text-decoration: none;
}
.HomeFurnishing_text-black__1toSO:hover {
    color: #000;
    text-decoration: none;
}

/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px){
    
    .HomeFurnishing_fabric-section__3Xhrh{ 
        background-position: top right;
        margin: 60px 0 0;
    }
    .HomeFurnishing_fabric-back__3AUmF span {
        font-size: 36px;
        vertical-align: sub;
        line-height: normal;
        line-height: initial;
    }
    .HomeFurnishing_fabric-back__3AUmF img {
        width: 80%;
    }
    .HomeFurnishing_fabric-back__3AUmF{
        text-align: center;
    }
    .HomeFurnishing_fabric-content__3ikFD h4 { 
        font-size: 24px;
        line-height: 35px;
    }
    .HomeFurnishing_fabric-back__3AUmF a{
        float: left;
    }
    .HomeFurnishing_fabric-content__3ikFD p { 
        font-size: 14px;
        line-height: 25px;
    }
    .HomeFurnishing_card__1cNhS {
        margin: 15px 0;
    }
    
    }
    


.ScreenTwo_upload-btn__2M4B7{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.ScreenTwo_upload-btn__2M4B7 img{ 
        width: 23px;
        margin-right: 10px; 
}

.ScreenTwo_upload-btn__2M4B7 span{

}
.ScreenTwo_download-section-inner__1ebTK{
  margin-bottom: 25px;
}
.ScreenTwo_upload-sec__1aTsx{
    /* padding-bottom: 20px; */
}
 .ScreenTwo_upload-top-btn__3F2qU{
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.ScreenTwo_screen-2-drop__2SxI1{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}
.ScreenTwo_solid-background__1o_ES{
  padding: 0;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 9px;
  padding: 0 10px 15px;
  border-bottom: 1px solid #ddd;
margin-bottom: 20px;
}
.ScreenTwo_solid-__2GuQQ span{
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0!important;
  line-height: normal;
}
.ScreenTwo_solid-background__1o_ES img{
  width: 100%;
  /* padding: 5px; */
  /* height: 65px; */
}
.ScreenTwo_right-top-selection__1Ubpo{
  /* display: grid; */
  /* grid-template-columns: 50% 50%; */
  /* grid-gap: 0px; */
  padding: 15px 0;
  margin: 0px auto 7px;
  justify-content: center;
  text-align: center;
  /* border-top: 2px solid #e0e0e0; */
 
}
.ScreenTwo_angle-sec__31nfs {
  /* background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  width: 90%;
  margin-left: 5%; */
  background-color: #fbfbfb;
    border-bottom: 1px solid #ddd;
}
.ScreenTwo_top-bar__2Daca {
  padding: 10px 0px;
  padding: 15px 0px 10px;
}
.ScreenTwo_credit-text__1CQ6c {
  font-size: 14px;
  font-family: poppins;
  color: #232121;
  text-align: center;
  /* display: flex;
  justify-content:flex-end; */
}.ScreenTwo_credit-text__1CQ6c p{
  font-weight: 600;
font-size: 14px;
line-height: 150%; 
text-align: left;
letter-spacing: 0.5px; 
color: #232121;
margin: 0;
}
.ScreenTwo_credit-text__1CQ6c p span{
  text-decoration: underline;
}
.ScreenTwo_avail-credit__3r5-h{
  text-align: left!important;
}
.ScreenTwo_right-top-credits__3_4V4{
  text-align: right;
  padding: 0;
  margin-bottom: 0px;
  
}
.ScreenTwo_credit-pos__1F4tA{
 
}
.ScreenTwo_right-top-credits__3_4V4 button{
  padding: 5px 10px;
  width: auto;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #FF7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.ScreenTwo_right-top-credits__3_4V4 img{
  width:25px !important;
}
.ScreenTwo_add-credit__1L0Ka{
  color: white !important;
}
.ScreenTwo_overlay-pos__H14Ac {
  position: absolute;
  width: 100%;
}
.ScreenTwo_more-btn-overlay-2__NZWTP {
  position: absolute;
  top: 25%;
    left: 20%;
    color: black;
    font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.ScreenTwo_more-btn-overlay-2__NZWTP span{
  font-size: 17px;
  display: block;
}
.ScreenTwo_credit-left-area__bVy8h{
  width:64%
}
.ScreenTwo_upload-area__32CJV{
    text-align: center;
    justify-content: center;
}
.ScreenTwo_bg-main__1YD3s{
    /* background: #FBFBFB;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);  */
padding: 35px 0;
margin-top: 70px;
}
.ScreenTwo_inactive-background-sec-below__1yyb6 img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}

.ScreenTwo_inactive-background-sec__2A77S{
  padding: 5px;
  box-shadow: 0 2px 5px 0 #ddd;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  width: auto;
}
.ScreenTwo_inactive-background-sec__2A77S span{
  font-size: 13px;
}
.ScreenTwo_inactive-background-sec__2A77S img {
  width: 100%;
  height: 46px;
  cursor: pointer;
  border-radius: 5px;
  /* border: 0.4px solid #333; */
}
.ScreenTwo_img-overlay__1rXuU {
  position: absolute;
  /* background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  ); */
  height: 100%;
  bottom: 0%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
}
.ScreenTwo_marketplaces__1ANsc{
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-row-gap: 9px;
  margin-left: 20px;
  padding: 25px 0;
}
.ScreenTwo_marketplaces__1ANsc img{
  width: 100%;
  height: 80px;
  object-fit: contain;
  padding: 10px;
}
.ScreenTwo_more-box__3dYwN{
  box-shadow: 0 0 0!important;
  padding: 5px;
}
.ScreenTwo_more-box__3dYwN img{
  border: 0!important;
  margin-top: 0;
  height: 100%!important;
  padding: 10px;
}
.ScreenTwo_active-visual-selection__3paME{ 
  padding: 0 ;
 
}
.ScreenTwo_inactive-visual-selection__2gI_4{
  /* border-bottom: 2px solid #FF7700; */
  /* color: #000; */
  /* padding: 10px; */
  /* background: #F5F5F5; */
  /* border: 1px solid #E0E0E0; */
  /* box-sizing: border-box; */
  /* border-radius: 0px 0px 5px 5px; */
}
.ScreenTwo_custom-background-list__1RMgl{
  padding: 20px 10px 20px 35px ;
}
.ScreenTwo_active-visual-selection__3paME h6{
  color: #232121;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: left;
  text-decoration: underline;
  
  padding: 0 15px;
}
.ScreenTwo_colored__2w9L2{
  text-decoration: underline;
  color: #ff7700;
}
/* .custom-background-list:before, .adjust-height-area:before{
  display: inline-block ;
  content: '' ; 
  position: absolute ;
  top: 0 ;
  left: 15px ;
  width: 10px ;
  height: 100% ;
  border-left: 1px dashed #ff7700 ;
} */
 
.ScreenTwo_custom-wall__1Wl2j{
  padding-top: 10px;
}
.ScreenTwo_side-margin__1Qw27{
  
}
.ScreenTwo_custom-wall__1Wl2j p, .ScreenTwo_adjust-height__3qbAy p{
  margin: 0 ;
}
.ScreenTwo_adjust-height__3qbAy p{
  padding: 5px 0 ;
  font-size: 16px ;
}
.ScreenTwo_custom-wall__1Wl2j p span{
  background: #ff7700 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 13px ;
  margin-right: 10px ;
}
.ScreenTwo_adjust-height__3qbAy p span{
  background: #E5E5E5 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 12px ;
  margin-right: 10px ;
}



.ScreenTwo_custom-walls-area__1mYhs{
 
}
.ScreenTwo_adjust-height-area__JfJHo{
  padding: 5px 0px ;
  position: relative ;
}
.ScreenTwo_adjust-height-area__JfJHo ul{
  padding: 5px 10% ;
  list-style: none ;
  text-align: center ;
  display: flex ;
}
.ScreenTwo_adjust-height-area__JfJHo ul li{
  text-align: center ;
  width: 35% ;
  padding: 10px ;
  
  margin: 0 auto ;
}
.ScreenTwo_adjust-height-area__JfJHo ul li button{
  background: rgba(235 215 198 / 0%);
  border: 1px solid #000000;
  box-sizing: border-box ;
  padding: 10px ;
  width: 100% ;    
  height: auto ;
  border-radius: 0 ;
}
.ScreenTwo_adjust-height-area__JfJHo ul li button.ScreenTwo_active__kUDhq, .ScreenTwo_adjust-height-area__JfJHo ul li button:hover{
  background: rgb(235 215 198 / 0%);
  border: 1px solid #FF7700; 
}
.ScreenTwo_adjust-height-area__JfJHo ul li h6{ 
  font-weight: normal ;
  font-size: 14px ; 
  color: #000000 ;
  margin: 0 ;
}
.ScreenTwo_adjust-height-area__JfJHo ul li img{
  width: 100% ;
}

.ScreenTwo_backgrounds-grid-custom__1mnv7 .ScreenTwo_inactive-background-sec__2A77S img{
  height: 60px ;
  border: 0 ;
}
.ScreenTwo_active-background-sec__21rxM:hover .ScreenTwo_white-icon__2jnLL, .ScreenTwo_inactive-background-sec__2A77S:hover .ScreenTwo_white-icon__2jnLL{
  display: none;
}
.ScreenTwo_active-background-sec__21rxM:hover .ScreenTwo_hoverable-icon__3-UmF, .ScreenTwo_inactive-background-sec__2A77S:hover .ScreenTwo_hoverable-icon__3-UmF{
  display: inline-block;
  }
.ScreenTwo_backgrounds-grid__EEP2e .ScreenTwo_inactive-background-sec__2A77S {
  position: relative;
}
.ScreenTwo_inactive-background-sec__2A77S img {
  height: 47px;
}

.ScreenTwo_backgrounds-grid__EEP2e {
  display: grid ;
  grid-template-columns: 30.8% 30.8% 30.8%;
  grid-gap: 9px ;
}
.ScreenTwo_backgrounds-grid-custom__1mnv7 {
  display: grid ;
  grid-template-columns: 30.8% 30.8% 30.8%  ;
  grid-gap: 7px ;
  margin-top: 10px;
}
.ScreenTwo_backgrounds-grid-below___Z80s {
  display: grid ;
  /* justify-content: center ; */
  grid-template-columns: 50% 50% ;
  grid-gap: 9px ;
}
.ScreenTwo_below-button__RRyFE {
  background: unset !;
  /* color; */
  border: 2px solid #ff7700  ;
  height: -webkit-fit-content !;
  height: -moz-fit-content !;
  height: fit-content !;
  margin-top: 2% !;
  border-radius: 30px  ;
}
.ScreenTwo_active-background-sec__21rxM span{
  font-size: 13px ;
}
.ScreenTwo_inactive-walls-sec__2WWgh {
  padding: 5px ;
  cursor: pointer ;
  -webkit-transform: scale(1) ;
          transform: scale(1) ;
  cursor: pointer ;
  /* height: 85px ; */
  width: auto ;
}
.ScreenTwo_inactive-walls-sec__2WWgh span{
  font-size: 13px ;
}
.ScreenTwo_active-walls-sec__1FH1O {
  /* box-shadow: 0 2px 5px 0 #ddd ; */
  padding: 3px ;
  border: 2px solid #ff7700 ;
  -webkit-transform: scale(1) ;
          transform: scale(1) ;
  cursor: pointer ;
  /* height: 110px ; */
  width: auto ;
}
.ScreenTwo_active-walls-sec__1FH1O span{
  font-size: 13px ;
}
.ScreenTwo_active-walls-sec__1FH1O img {
  width: 100% ;
  /* height: 60px ; */
}
.ScreenTwo_inactive-walls-sec__2WWgh img {
  width: 100% ;
  /* height: 60px ; */
  border: 0.4px solid #333 ;
}
.ScreenTwo_inactive-walls-sec__2WWgh {
  padding: 3px ;
  border: 2px solid transparent ;
  box-shadow: 0 2px 5px 0 #ddd ;
}



.ScreenTwo_custom__38dlp {
  font-size: 13px !important;
  font-weight: 500 ;
  /* margin-left: 14% ; */
}
.ScreenTwo_resizable__14ZPP {
  width: 120px ;
  /* border-radius: 0.75rem ;
  padding: 20px ;
  margin: 1rem ;
  background-color: #29e ;
  color: white ;
  font-size: 20px ;
  font-family: sans-serif ; */
  overflow: hidden ;

  touch-action: none ;

  /* This makes things *much* easier */
  object-fit: fill ;
  box-sizing: border-box ;
  position: absolute ;
  opacity: 0.5 ;
  border-bottom: 10px solid #FF7700 ;
  z-index: 2 ;
}
.ScreenTwo_drag-button__2Iawe{
  width: 50px !;
  height: auto ;
  -webkit-transform: rotate(-90deg) ;
          transform: rotate(-90deg) ;
  /* overflow: hidden ; */
  margin-top: -27px ;
}
.ScreenTwo_wall-cross__1rlmp{
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width : 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 0!important;
}
.ScreenTwo_car-text___60VP h1 {
  font-size: 30px;
}
.ScreenTwo_original-image-div__1kt63 {
  width: 100%;
  height: 100%;
}
.ScreenTwo_remove-bg-box__28eE5{
  background: #FFFFFF;
  margin: 0;
/* border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
width:90%;
margin-left: 5%; */
}
.ScreenTwo_remove-bg-area__1nS7o{
    padding: 0;
    background: #fbfbfb;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    border-radius: 5px;

}
.ScreenTwo_credit-text__1CQ6c img {
    margin-right: 10px;
    width: 40px;
}
.ScreenTwo_credit-text__1CQ6c span {
    color: #f70;
}
.ScreenTwo_cross-btn__3-yWO {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:-webkit-max-content;
    width:max-content;
  }
  .ScreenTwo_cross-img__2ELi0 {
    width: auto !important;
    cursor: pointer;
    margin-left: 50%;
  }
  .ScreenTwo_credit-text__1CQ6c{
    
  }

  .ScreenTwo_download-popup__1QKKy p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 15px;
  }
  .ScreenTwo_download-popup__1QKKy button.ScreenTwo_activate__2Qaka,
  .ScreenTwo_download-popup__1QKKy button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .ScreenTwo_download-popup__1QKKy {
    box-shadow: 0px 4px 20px #00000038;
    border: 1px solid #ddd;
    background: #fff;
    box-shadow: 0 4px 20px rgb(0 0 0 / 20%);
    padding: 30px;
    margin-top: 20px;
  }
  .ScreenTwo_try-drag-drop__2sDDZ .ScreenTwo_dropzone1__3vnfa {
    border: 1px dashed #ffa64d;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    opacity: 0.7;
  }
  .ScreenTwo_try-drag-drop__2sDDZ .ScreenTwo_dropzone1__3vnfa:hover {
    opacity: 1;
  }
  .ScreenTwo_try-drag-drop__2sDDZ .ScreenTwo_dropzone1__3vnfa img {
    margin-bottom: -30px;
    width: 15%;
  }
  
  .ScreenTwo_drop-box-text__2hEq1 {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .ScreenTwo_drop-box-text__2hEq1 div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .ScreenTwo_download-popup__1QKKy button {
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 85%;;
  }
  .ScreenTwo_cross-btn__3-yWO {
    text-align: right;
    padding: 0 0 10px;
  }
  .ScreenTwo_download-popup__1QKKy button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .ScreenTwo_download-popup__1QKKy ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .ScreenTwo_download-popup__1QKKy ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .ScreenTwo_download-popup__1QKKy p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .ScreenTwo_download-popup__1QKKy p span {
    color: #ff7700;
  }
  .ScreenTwo_download-popup__1QKKy h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .ScreenTwo_border-1__1d6Ul {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
    margin-top: 20px;
  }
  .ScreenTwo_download-popup__1QKKy input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .ScreenTwo_download-popup__1QKKy input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .ScreenTwo_download-btn-hd__3wOhS {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .ScreenTwo_containers__Z645h {
    max-width: 600px !important;
    border-radius: 10px;
}
  .ScreenTwo_containers__Z645h img {
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    margin-top: 100px;
    /* margin: 50px auto 0; */
    margin-top: 20px;
    position: relative !important
}
.ScreenTwo_left-sec-upload-image__2U5rE {
    width: auto;
    height: 415px;
}
.ScreenTwo_rating-area__3e9Nf{

}
.ScreenTwo_rating-area__3e9Nf p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.ScreenTwo_rating-area__3e9Nf p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.ScreenTwo_sec-2-right-card__1tG2J{ 
    width:41%;
    padding: 0 15px  24.6px;
    background: #fff;
    border-radius: 0;  
  
    border-left: 1px solid #ebeaea
   
}
.ScreenTwo_sec-2-left__3tuPn{
  width:58%
}
.ScreenTwo_get-mrktplace__1hE8f{
    line-height: 50px!important;
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.ScreenTwo_get-mrktplace__1hE8f a{
    margin-left: 10px;
}

.ScreenTwo_css-1eg0sfo-skeletonStyles-Skeleton__ufDZb {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: ScreenTwo_animation-bzdot9__3u2Z_ 1.2s ease-in-out infinite;
    animation: ScreenTwo_animation-bzdot9__3u2Z_ 1.2s ease-in-out infinite;
}
.ScreenTwo_categories__1miuF{

}
.ScreenTwo_categories__1miuF h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.ScreenTwo_categories__1miuF h2 span{
    font-weight: 600;
font-size: 24px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.ScreenTwo_categories__1miuF h2:before {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_categories__1miuF h2:after {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .ScreenTwo_product-hunt-tag__2e5JQ{
    width: auto;
    position: absolute;
    right: 115px;
    top: 0px;
}

.ScreenTwo_last-enhancement__9S7Li{
  margin-left: 40px;
  padding: 20px 0;
}
.ScreenTwo_last-enhancement__9S7Li h6{
  font-style: normal;
font-weight: normal;
font-size: 11.3151px;
line-height: 17px; 
color: #FF7700;
}
.ScreenTwo_skin-retouch__3W8WX{

}
.ScreenTwo_skin-retouch__3W8WX snap{
  font-weight: 500;
font-size: 14.5882px;
line-height: 35px; 
color: #5F5F5F;
}
.ScreenTwo_second-side__jPRzh{
  border: 0!important;
}

.ScreenTwo_btn-sec__3-TEl button {
  width: 120px;
  height: 44px;
  border-radius: 5px;
}
.ScreenTwo_bg-fashion__aWcFV{
  padding: 5px;
}

.ScreenTwo_info-box__19IrW{
  background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 6px;
    opacity: 0;
    padding: 10px;
    width: 100px;
    min-height: 50px; 
    z-index: 9;
    position: absolute;
    left: 10px;
    top: 0;
}
.ScreenTwo_info__2Ul5K:hover .ScreenTwo_info-box__19IrW{
  opacity: 1;
  /* width: 2px; */
}
.ScreenTwo_info-box__19IrW p{
  padding: 0;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px; 
  color: #FF7700;
}



.ScreenTwo_toggle1__3uEOy {
  position: relative;
  color: #f39e53;
}

.ScreenTwo_toggle1__3uEOy input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


.ScreenTwo_slider1__wPDqf {
  position: relative;
  display: block;
  cursor: pointer;
  background: #f39e53;;
  margin-left: 190px;

box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
border-radius: 6px;
  transition: 0.4s;
  width: 100px;
  height: 22px;
}

.ScreenTwo_slider1__wPDqf::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 50px;
  background: rgba(39, 35, 35, 0.38);
  transition: 0.7s;
  border-radius: 6px;

}

input:checked + .ScreenTwo_slider1__wPDqf {
  background-color: #f39e53;
}

input:checked + .ScreenTwo_slider1__wPDqf::before {
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
}

.ScreenTwo_slider2__2rqGY {
  position: relative;
  display: block;
  cursor: pointer;
  background: #adaba9;
  margin-left: 190px;

box-shadow: 0px 4px 4px rgba(179, 172, 172, 0.945);
border-radius: 6px;
  transition: 0.4s;
  width: 59px;
  height: 22px;
}

.ScreenTwo_slider2__2rqGY::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 29.5px;
  background: rgba(39, 35, 35, 0.38);
  transition: 0.7s;
  border-radius: 6px;

}

input:checked + .ScreenTwo_slider2__2rqGY {
  background-color: #f39e53;
}

input:checked + .ScreenTwo_slider2__2rqGY::before {
  -webkit-transform: translateX(29.5px);
          transform: translateX(29.5px);
}
.ScreenTwo_bg-fashion__aWcFV p{
  font-weight: 500;
font-size: 8px;
line-height: 110%;  
letter-spacing: 0.5px; 
color: #232121;
text-align: left;
padding: 5px 0 0;
margin: 0;
}
.ScreenTwo_bg-fashion__aWcFV p span{
  float: right;
}
.ScreenTwo_bg-fashion__aWcFV p span img{
  width: 8px;
}























/* @Responsive */





  @media screen and (max-width: 1290px) and (min-width: 1025px){
.ScreenTwo_cross-btn__3-yWO {
    padding: 0 0 20px;
}


}

.ScreenTwo_main-box__2NH4M{
  margin: 0px 0 50px;
  background: #fff;
}

@media (min-width: 1366px) {
  .ScreenTwo_main-box__2NH4M {
    max-width: 93.5%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1440px) {
  .ScreenTwo_main-box__2NH4M {
    max-width: 89%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
  .ScreenTwo_product-hunt-tag__2e5JQ {
    right: 20%;
  }
}

@media (min-width: 1680px) {
  .ScreenTwo_main-box__2NH4M {
    max-width: 76.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1920px) {
  .ScreenTwo_main-box__2NH4M {
    max-width: 66.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2560px) {
  .ScreenTwo_main-box__2NH4M {
    max-width: 50%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2880px) {
  .ScreenTwo_main-box__2NH4M {
    max-width: 44.2%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}





.SelectFoodBgModal_food-angle__2QeIn{

}
.SelectFoodBgModal_food-image-area__urHQv h6{
    font-weight: normal;
    font-size: 14px;
    line-height: 150%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #5E5E5E;
}
.SelectFoodBgModal_food-image-area__urHQv img{
width: 100%;
}
.SelectFoodBgModal_food-bottom__3kK1T h4{
    font-weight: 600;
    font-size: 24px;
    line-height: 150%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #232121;
    margin-bottom: 15px;
}


.SelectFoodBgModal_food-angle-single__2gVoZ img{
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
}
.SelectFoodBgModal_food-angle-single__2gVoZ{

}
.SelectFoodBgModal_food-angle-single__2gVoZ button{
    -webkit-filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.12));
            filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.12));
    font-weight: 600;
font-size: 12px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px; 
color: #FFFFFF;
background: rgba(20, 19, 19, 0.4);
border-radius: 5px;
}
.SelectFoodBgModal_food-angle-single__2gVoZ button:hover{
    background: #FF9200;
box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 5px;
}
.SelectFoodBgModal_food-image-area__urHQv{
    

}
.SelectFoodBgModal_food-image-area__urHQv img{
    width: 100%;
   
}
.SelectFoodBgModal_food-bottom__3kK1T{
    border-top: 1px solid rgba(162, 162, 162, 0.27);
    box-shadow: 0px -2px 4px rgb(0 0 0 / 12%);
    padding: 20px 65px;
    margin-top: 10px;
    transition: .3s ease-in-out;
}







.ScreenFirst_credits-data__3V6v7{
    padding: 100px 6% 15px;
    text-align: right;
}
.ScreenFirst_credits-data__3V6v7 span{
    border: 2px solid #ffe7d2;
    border-radius: 10px; 
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 12px 10px;
    letter-spacing: .5px;
    margin-top: 7px;
    color: #232121;
    /* position: absolute;
    right: 20px; */
   
}
.ScreenFirst_credits-data__3V6v7 span img {
    margin-right: 15px;
}
.ScreenFirst_credits-data__3V6v7 span b {
    color: #f70;
}


.Affiliate_Affiliate-hero__1jeQc{
    padding: 120px 0 60px;
    font-family: 'Poppins';
}
.Affiliate_hero-content__1r1o0{

}
.Affiliate_hero-content__1r1o0 h1 { 
    font-weight: 800;
    font-size: 43px;
    line-height: 60px;
    color: #18191F;
    margin-bottom: 20px;
}
.Affiliate_hero-content__1r1o0 h1 span{
    color: #f70;
    display: block;
}
.Affiliate_hero-content__1r1o0 p{
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #18191F;
    margin-bottom: 20px;
}
.Affiliate_hero-img__VbU6C{

}
.Affiliate_hero-img__VbU6C img{
    width: 100%;
}

.Affiliate_products__1c4Rc{
    padding: 60px 0 ;
    margin: 50px 0;
    box-shadow: 0px 0px 90px 0px #e5e5e5;
}
.Affiliate_heading__12d0j{
text-align: center;
margin-bottom: 40px;
}
.Affiliate_heading__12d0j h2{
    font-weight: 800;
    font-size: 38px;
    line-height: 53px; 
    color: #18191F;
}
.Affiliate_heading__12d0j p{
    font-size: 18px;
    line-height: 25px; 
    color: #18191F;
    margin: 0;
}
.Affiliate_become-a-partner__ckWXO{
    transition: .2s ease-in-out; 
    padding: 10px 20px;
    box-shadow: 2px 7px 12px 0px #ddd;
    transition: .2s ease-in-out;
}
.Affiliate_become-a-partner__ckWXO span{
    margin-right: 5px;
}
.Affiliate_become-a-partner__ckWXO:hover span{
    margin-left: 5px;
    margin-right: 0;
}
.Affiliate_become-a-partner__ckWXO:hover{  
    box-shadow: 2px 7px 12px 0px #ff77008c;
}
.Affiliate_product-image__znj8f{

}
.Affiliate_product-image__znj8f img{
width: 100%;
}
.Affiliate_product-content__2es0I{

}
.Affiliate_product-content__2es0I h3{
    font-weight: 800;
    font-size: 33px;
    line-height: 45px;
    color: #18191F;
    margin-bottom: 15px;
}
.Affiliate_product-content__2es0I p{
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #4F5665;
}
.Affiliate_product-content__2es0I ul{
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
}
.Affiliate_product-content__2es0I ul li{
    font-weight: normal;
    font-size: 17px;
    line-height: 33px;
    color: #4F5665;
}
.Affiliate_product-content__2es0I ul li img{
    width: 15px;
    vertical-align: middle;
    margin: 0 5px;
}
.Affiliate_product-content__2es0I ul li span{

}
.Affiliate_tab-names__2JAEk{
    border-bottom: 1px solid #D1CECE;
}
.Affiliate_tab-names__2JAEk{
    margin-bottom: 30px;
}
.Affiliate_benefits-partner__3gcXg{
    padding: 60px 0;
}
.Affiliate_benefits-single__b-d2v{
    background: rgba(255, 193, 138, 0.15);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    padding: 0px;
    border: 2px dashed transparent;
    transition: .4s ease-in-out;
    height: 100%;
}
.Affiliate_benefits-single__b-d2v h4{
    font-weight: bold;
font-size: 16px;
line-height: 25px; 
text-align: center; 
color: #18191F;
padding: 10px 10px 0;
}
.Affiliate_benefits-single__b-d2v p{
    font-weight: normal;
font-size: 14px;
line-height: 25px; 
text-align: center; 
color: #18191F;
padding: 0 9px;
}
.Affiliate_benefits-single__b-d2v img{
    background: #fff;
    width: 100%;
    padding: 0;
    object-fit: contain;
    height: 100px;
    padding: 15px;
    border-radius: 20px 20px 0px 0px;
    transition: .4s ease-in-out;
}
.Affiliate_benefits-single__b-d2v:hover{
    border: 2px dashed #ddd;
    border-radius: 19px 0;
}
.Affiliate_benefits-single__b-d2v:hover img{
    background: #fff6ee;
}
.Affiliate_image__2NPP8{
text-align: center;
}
.Affiliate_image__2NPP8 img{
    width: 100%; 
    object-fit: contain;
}
.Affiliate_who-can-be__bnTBh{
    padding: 0 0 60px;
}
.Affiliate_qa-question__3WQ8O{
    background: #FFFFFF;
    border: 1px solid #FD816C;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 20px;
}
.Affiliate_qa-question__3WQ8O h6{
    font-weight: 600;
    font-size: 16px;
    line-height: 25px; 
    color: #18191F;
    margin: 0;
}
.Affiliate_qa-description__1xnHQ{
    padding: 10px; 
    background: #FFF8F3;
    box-shadow: 0px 4px 22px #fff8f3;
    border-radius: 10px;
    position: relative;
}
.Affiliate_qa-description__1xnHQ p{
margin: 0;
font-weight: normal;
font-size: 15px;
line-height: 25px; 
color: #18191F;
}
.Affiliate_affiliate-item__2yOux{
    margin-bottom: 20px;
}

 
.Affiliate_qa-question__3WQ8O[aria-expanded='true']::after, .Affiliate_qa-question__3WQ8O[aria-selected='true']::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .Affiliate_qa-question__3WQ8O:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-left: 12px;
    border-bottom: 2px solid #ff7700;
    border-right: 2px solid #ff7700;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); 
    float: right;
    margin: -18px 0 0 15px;
    background: #fff;
  }
  .Affiliate_enjoy-lifetime__7XkeA .Affiliate_heading__12d0j{
      text-align: left;
      margin: 0!important;
      padding-top: 25px;
  }
.Affiliate_enjoy-lifetime__7XkeA .Affiliate_heading__12d0j h2 span{
    color: #ff7700;
    /* display: block; */
}
.Affiliate_enjoy-row__24efC{
    box-shadow: 0px 0px 90px 0px #f1f1f2;
    background: #fff;
    border-radius: 10px;
    padding: 20px 10px 0;
}
.Affiliate_page-break___qeav{
padding: 30px 0;
}
.Affiliate_page-break___qeav .Affiliate_heading__12d0j{
margin-bottom: 0;
}
.Affiliate_page-break___qeav .Affiliate_heading__12d0j h5{
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;  
    color: #000000;
    margin: 0;
    
}
.Affiliate_page-break___qeav .Affiliate_heading__12d0j h5 span {
   font-weight: 400;
   font-size: 28px;
   display: block;
}
.Affiliate_page-break___qeav .Affiliate_heading__12d0j h5 span a{
    color: #ff7700;
    text-decoration: none;
}
.Affiliate_enjoy-image__a5my2{

}
.Affiliate_enjoy-image__a5my2 img{
    width: 100%;
}
.Affiliate_enjoy-lifetime__7XkeA button{
    box-shadow: 0px 0px 40px 0px #ff77008c;
    margin-top: 15%!important;
}
.Affiliate_dollar-img__2UyGK{
    width: auto!important;
    position: absolute;
    top: 10%;
    -webkit-animation: Affiliate_float__1-7nV 6s ease-in-out infinite;
          animation: Affiliate_float__1-7nV 6s ease-in-out infinite;
    background: #FFF3EC;
    border-radius: 50%;
}

@-webkit-keyframes Affiliate_float__1-7nV {
    0% {
   
      -webkit-transform: translatex(0px);
   
              transform: translatex(0px);
    }
    50% {
   
      -webkit-transform: translatex(-20px);
   
              transform: translatex(-20px);
    }
    100% {
   
      -webkit-transform: translatex(0px);
   
              transform: translatex(0px);
    }
  }
  
  @keyframes Affiliate_float__1-7nV {
    0% {
  
      -webkit-transform: translatex(0px);
  
              transform: translatex(0px);
    }
    50% {
   
      -webkit-transform: translatex(-20px);
   
              transform: translatex(-20px);
    }
    100% {
   
      -webkit-transform: translatex(0px);
   
              transform: translatex(0px);
    }
  }
   




/* @Responsive Media  */
@media screen and (max-width: 767px) and (min-width: 200px){
    .Affiliate_hero-content__1r1o0 h1 {
        font-size: 20px;
        line-height: 35px;
    }
    .Affiliate_Affiliate-hero__1jeQc {
        padding: 90px 0 45px;
    }
    .Affiliate_hero-content__1r1o0 p {
        font-size: 12px;
        line-height: 18px;
    }
    .Affiliate_hero-img__VbU6C img {
        width: 100%;
        margin: 30px 0 0;
    }
    .Affiliate_products__1c4Rc{
        padding: 40px 0;
        margin: 30px 0;
    }
    .Affiliate_heading__12d0j  h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .Affiliate_heading__12d0j p {
        font-size: 12px;
        line-height: 24px;
    }
    .Affiliate_tab-names__2JAEk{
        margin: 40px 0 0;
    padding: 0 5px;
}
    .Affiliate_product-image__znj8f {
        padding: 0;
        margin-bottom: 15px;
    }
    .Affiliate_product-content__2es0I{
        padding: 0;
    }
    .Affiliate_product-content__2es0I h3 {
        font-size: 13px;
        line-height: 20px;
    }
    .Affiliate_product-content__2es0I p {
        font-size: 10px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .Affiliate_product-content__2es0I ul li img {
        width: 10px;
        margin: 0 5px 0 0;
    }
    .Affiliate_product-content__2es0I ul li {
        font-size: 8px;
    line-height: 16px;
    }
.Affiliate_product-content__2es0I ul{
    margin-bottom: 10px;
}
    .Affiliate_tab-names__2JAEk{
        border: 0;
        border-right: 1px solid #D1CECE;
    }
    .Affiliate_benefits-partner__3gcXg {
        padding: 25px 0;
    }
    .Affiliate_benefit-cols__2-orh{
        margin-bottom: 30px!important;
    }
    .Affiliate_benefits-single__b-d2v:last-child {
    }

    .Affiliate_who-can-be__bnTBh {
        padding: 20px 0 40px;
    }
    .Affiliate_image__2NPP8 img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
.Affiliate_mob-esponsive__30IRv{
    margin: 0!important;
}
.Affiliate_qa-question__3WQ8O h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}
.Affiliate_qa-question__3WQ8O{
    padding: 10px;
}
.Affiliate_qa-description__1xnHQ p {
    font-size: 10px;
    line-height: 18px;
}
.Affiliate_page-break___qeav .Affiliate_heading__12d0j h5 {
    font-size: 18px;
    line-height: 25px;
}
.Affiliate_page-break___qeav .Affiliate_heading__12d0j h5 span {
    font-size: 20px;
    line-height: 30px;
}
.Affiliate_Abenefits-single__20SZJ p {
    font-size: 12px;
    line-height: 20px;
}
.Affiliate_become-a-partner__ckWXO  { 
    padding: 5px 10px; 
    font-size: 12px;

}
.Affiliate_enjoy-lifetime__7XkeA button { 
    margin-top: 3%!important;
}
.Affiliate_qa-question__3WQ8O:after{
    display: none;
}
.Affiliate_enjoy-lifetime__7XkeA{
    padding: 0 10px;
}
.Affiliate_dollar-img__2UyGK {
    width: 40px!important;
}
.Affiliate_enjoy-lifetime__7XkeA .Affiliate_heading__12d0j{ 
    padding-top: 0px;
}
.Affiliate_hero-content__1r1o0 h1 span { 
    display: inline; 
    display: initial;
}
.Affiliate_heading__12d0j{
    margin-bottom: 25px;
}
.Affiliate_tab-names__2JAEk li{
    margin-bottom: 20px!important;
}
}

@media screen and (max-width: 900px) and (min-width: 768px){
    .Affiliate_hero-content__1r1o0 h1 { 
        font-size: 30px;
        line-height: 40px;
    }
    .Affiliate_hero-content__1r1o0 p { 
        font-size: 14px;
        line-height: 25px;
    }
    .Affiliate_product-content__2es0I h3 {
        font-size: 28px;
        line-height: 38px;
    }
    .Affiliate_product-content__2es0I ul li {
        font-size: 14px;
    }
    .Affiliate_benefits-single__b-d2v h4 {
        font-size: 14px;
    }
    .Affiliate_benefits-single__b-d2v p {
        font-size: 12px;
    }
    .Affiliate_heading__12d0j h2 {
        font-size: 34px;
    }
    .Affiliate_enjoy-lifetime__7XkeA button {
        margin-top: 3%!important;
    
}
.Affiliate_enjoy-image__a5my2 {
    padding-top: 5%;
}
}










.AffiliateForm_affiliate-form__x4jiM{
    padding: 100px 0 60px;
    background: rgb(255 231 210 / 75%);
}
.AffiliateForm_heading__135BS{
    text-align: center;
    margin-bottom: 40px;
    padding: 0;
    }
    .AffiliateForm_heading__135BS h2{
        font-weight: bold;
        font-size: 33px;
        line-height: 50px;
        color: #18191F;
    }
    .AffiliateForm_heading__135BS h2 span{
        color: #ff7700;
    }
    .AffiliateForm_heading__135BS p{
        font-size: 18px;
        line-height: 25px; 
        color: #18191F;
        margin: 0;
    }
.AffiliateForm_affiliate-form__x4jiM form{
    padding: 20px 20px 40px;
    background: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
}

.AffiliateForm_form-area__Gf3VL{

}
.AffiliateForm_form-area__Gf3VL label{
display: flex;
width: 100%;
font-weight: 600;
font-size: 16px;
line-height: 24px; 
color: #444150;
}
.AffiliateForm_form-area__Gf3VL label span, .AffiliateForm_form-area__Gf3VL select span{
width: 40%;
text-align: left;
}
.AffiliateForm_form-area__Gf3VL label input, .AffiliateForm_form-area__Gf3VL select{
width: 60%;
font-weight: normal;
font-size: 16px;
line-height: 24px; 
color: #444150!important; 
opacity: 0.5;
background: #FBFBFB;
border: 1px solid #D9D9D9;
box-sizing: border-box;
border-radius: 6px;
}
.AffiliateForm_form-area__Gf3VL label sup{
color: #f70;
font-size: 15px;
}
.AffiliateForm_form-area__Gf3VL label input:focus{
    opacity: 1;
    border-color: #ff7700;
}
.AffiliateForm_submit__iHq_v{
    transition: .2s ease-in-out; 
    padding: 10px 20px;
    width: 150px;
    margin: 25px auto 0;

    box-shadow: 2px 7px 12px 0px #ddd;
}
.AffiliateForm_submit__iHq_v span{
    margin-left: 5px;
}
.AffiliateForm_submit__iHq_v:hover span{
    margin-left: 0;
    margin-right: 5px;
}
.AffiliateForm_submit__iHq_v:hover{
    box-shadow: 2px 7px 12px 0px #ff77008c;
}
.AffiliateForm_back__3PraL{
    color: #ff7700!important;
    font-size: 45px!important;
    text-align: left;
    margin: 0!important;
    font-weight: 200;
}
 .AffiliateForm_form__O2nHB{
    background: #FFFFFF;
box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04);
border-radius: 10px;
    padding: 20px 0px 0px;
}
.AffiliateForm_form__O2nHB img{
    width: auto;
    margin: 15px auto;
}
.AffiliateForm_bottom-pitch__2ymI1{
    background: #FFF0E3;
border-radius: 0px 0px 10px 10px;
padding: 15px;
margin: 30px 0 0;
}
.AffiliateForm_bottom-pitch__2ymI1 ul{
padding: 0;
list-style: none; 
margin: 0;
}
.AffiliateForm_bottom-pitch__2ymI1 ul li{
    display: inline-block;
    padding: 0 20px;
}
.AffiliateForm_bottom-pitch__2ymI1 ul li a{

}

.AffiliateForm_bottom-pitch__2ymI1 h6{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px; 
    color: #000000; 
    opacity: 0.5;
}






/* @Responsive Media  */
@media screen and (max-width: 768px) and (min-width: 200px){
  
    .AffiliateForm_heading__135BS  h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .AffiliateForm_heading__135BS p {
        font-size: 12px;
        line-height: 24px;
    }
    
.AffiliateForm_submit__iHq_v { 
    padding: 5px 10px; 
    font-size: 12px;
    margin: 10px auto 0;
    width: auto;
}
.AffiliateForm_affiliate-form__x4jiM{
    padding: 100px 10px 60px;
}
.AffiliateForm_affiliate-form__x4jiM .AffiliateForm_heading__135BS img{
    margin-bottom: 10px;
}
.AffiliateForm_form-area__Gf3VL {
    padding: 0;
}
.AffiliateForm_form-area__Gf3VL label span, .AffiliateForm_form-area__Gf3VL select span {
    width: 100%;
    text-align: left;
    display: block;
}
.AffiliateForm_form-area__Gf3VL label input, .AffiliateForm_form-area__Gf3VL select {
    width: 100%;
    display: block; 
    font-size: 12px;
    margin: 10px 0 0;
}
.AffiliateForm_form-area__Gf3VL label {
    display: block; 
    font-size: 12px;
    line-height: 20px; 
}
.AffiliateForm_form-area__Gf3VL label sup {
    vertical-align: text-bottom;
}

.AffiliateForm_form-area__Gf3VL label input::-webkit-input-placeholder{
    font-size: 12px;
}

.AffiliateForm_form-area__Gf3VL label input:-ms-input-placeholder{
    font-size: 12px;
}

.AffiliateForm_form-area__Gf3VL label input::placeholder{
    font-size: 12px;
}







}

















.CaseStudiesLanding_case-studies__-cdBX {
  background: #ffecdb;
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  padding: 100px 0 60px;
}
.CaseStudiesLanding_case-container__3_2Ro {
  width: 100%;
}
.CaseStudiesLanding_case-card__exkYF {
  padding: 20px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 17px rgba(255, 119, 0, 0.24),
    0px 3px 10px -1px rgba(255, 119, 0, 0.05);
  border-radius: 16px;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
  margin: 0;
  text-decoration: none;
  border: 1.5px dashed rgb(248, 246, 246);
  cursor: pointer;
  margin-top: 25px;
}
a.CaseStudiesLanding_case-card__exkYF {
  text-decoration: none;
  margin-bottom: 20px;
}
.CaseStudiesLanding_case-card__exkYF:hover {
  box-shadow: 0px 2px 17px rgba(255, 119, 0, 0.24),
    0px 4px 10px -1px rgba(255, 119, 0, 0.05);
  border: 1.5px dashed #ff9800;
}
.CaseStudiesLanding_case-studies-img__3nhh8 img {
  width: 100%;
  max-height: 221px;
  object-fit: contain;
}
.CaseStudiesLanding_case-content2__20bUA {
  padding-top: 5%;
  padding-right: 5%;
}
.CaseStudiesLanding_case-content__1STpy h3,
.CaseStudiesLanding_case-content2__20bUA h3 {
  font-weight: bold;
  font-size: 23px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 15px;
}
.CaseStudiesLanding_case-content__1STpy p {
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #606060;
  margin: 0;
}
.CaseStudiesLanding_case-content2__20bUA p {
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 25px;
}
.CaseStudiesLanding_case-content2__20bUA input {
  background: #ffffff;
  border: 1px solid #b3b6da;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.06em;
  color: #8f92a1;
  padding: 10px;
  width: 45%;
}
.CaseStudiesLanding_case-content2__20bUA button {
  display: block;
  margin-bottom: 30px;
}
.CaseStudiesLanding_case-content2__20bUA small {
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.CaseStudiesLanding_case-image__2B_cx img {
  width: 100%;
}
.CaseStudiesLanding_case-image__2B_cx {
  padding-top: 5%;
}

/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px) {
  .CaseStudiesLanding_case-studies-img__3nhh8 img {
    margin-bottom: 15px;
  }
  .CaseStudiesLanding_case-content__1STpy h3,
  .CaseStudiesLanding_case-content2__20bUA h3 {
    font-size: 18px;
  }
  .CaseStudiesLanding_case-content__1STpy p,
  .CaseStudiesLanding_case-content2__20bUA p {
    font-size: 13px;
  }
  .CaseStudiesLanding_case-container__3_2Ro {
    width: 100%;
    padding: 0 15px;
  }
  .CaseStudiesLanding_case-image__2B_cx {
    margin-bottom: 20px;
    padding: 0 0;
  }
  .CaseStudiesLanding_case-content2__20bUA input {
    width: 100%;
  }
  .CaseStudiesLanding_case-content2__20bUA button {
    width: auto;
    padding: 6px 15px;
  }
  .CaseStudiesLanding_case-content2__20bUA small {
    font-size: 12px;
    line-height: 19px;
  }
  .CaseStudiesLanding_case-content2__20bUA {
    padding: 0 15px;
  }
  .CaseStudiesLanding_case-studies__-cdBX {
    padding: 100px 0 35px;
  }
}

:root {
  --textColor: #fff;
  --resColor: #fff;
  --cropColor: #fff;
  --blurColor: #fff;
}
h3,h4,h5,h6,p,a,span, div{
  font-family: 'Inter'!important;
}
h1,h2{
  font-family: 'Rozha One'!important;
}
.UploadScreen_image-scoring__3DZmo {
  padding: 0px 0 0px;
  height: 100vh;
}
.UploadScreen_image-container__3o4qg {
  width: 100%;
  padding: 0 12px 0 60px;
}
.UploadScreen_scoring-col__2WLHS {
  background: conic-gradient(
    from 250.23deg at 50% 50%,
    #eee28d -15.84deg,
    #b19de3 109.29deg,
    #ef8b9c 256.45deg,
    #eee28d 344.16deg,
    #b19de3 469.29deg
  );
  border-radius: 70px 70px 0 0;
    padding: 0px 30px 30px;
    border: 35px solid #fff;
    border-bottom: 0;
    margin-top: 21vh;
    min-height: 79vh;
}
.UploadScreen_scoring-col2__1LsGs{
  margin-top: 18vh;
  padding: 0 30px 20px;
}
.UploadScreen_scoring-upload__OOItN {
  background: #000000;
  border-radius: 19px;
  padding: 15px;
  margin: -55px 0 0;
  transition: 0.3s ease-in-out;
}
.UploadScreen_scoring-upload__OOItN:hover{
  margin: -60px 0 0;
}
 
.UploadScreen_scoring-upload-box__2iJlD {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 60px 20px 15px;
  text-align: center;
  color: #fff;
}
.UploadScreen_scoring-upload-box__2iJlD img {
  margin: 0 auto 35px;
      cursor: pointer;
      width: 35%;
}
.UploadScreen_slider-area__3y10f {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(255, 146, 0, 0.29);
  border-radius: 15px;
}
.UploadScreen_slider-area__3y10f p {
  background: linear-gradient(90.04deg, #ff9200 0.05%, #ff7700 96.78%);
  border-radius: 15px 15px 0px 0px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  padding: 5px 20px;
  color: #ffffff;
}
.UploadScreen_slider-area__3y10f p span {
  float: right;
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 5px;
  padding: 0 3px;
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.UploadScreen_image-slider__3MBX2 {
  padding: 15px 20px 10px;
}
.UploadScreen_scoring-upload-box__2iJlD h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 35px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 15px;
}
.UploadScreen_scoring-upload-box__2iJlD h6 span {
  border-bottom: 1px solid #c5c5c5;
  padding-bottom: 10px;
  cursor: pointer;
}
.UploadScreen_scoring-upload-box__2iJlD p {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #ff7700;
  margin-bottom: 20px;
}
.UploadScreen_slider-area__3y10f p {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 0px;
  text-align: center;
  padding: 5px 10px;
}
.UploadScreen_slide__2IC5k {
  padding: 0 5px;
}
.UploadScreen_slide__2IC5k img {
  border-radius: 5px;
  margin: 0;
  transition: 0.3s ease-in-out;
  height: 60px !important;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  border: 0.5px solid rgba(50, 50, 50, 0.1);
box-sizing: border-box;
border-radius: 5px;
}
.UploadScreen_slide2__DMDFw img{
  height: 47px!important;
}
.UploadScreen_slide__2IC5k img:hover { 
  box-shadow: 0px 0px 4px 0px #ff9200;
}
.UploadScreen_scoring-title__vmWbv {
  padding: 50px 60px 0px 35px;
  margin-top: 22vh;
}
.UploadScreen_scoring-title2__bJHnh { 
  margin-top: 10vh;
}
.UploadScreen_scoring-title__vmWbv h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 62px;
  line-height: 72px;
  color: #000000;
  font-family: 'Rozha One'!important;
}
.UploadScreen_scoring-title__vmWbv h1 span {
  color: #e03813;
  font-family: 'Rozha One'!important;
}
img.UploadScreen_rectangle__1Ud10,
.UploadScreen_rectangle2__2nj-a {
  right: 0;
  bottom: 0;
  position: absolute;
  transition: 0.3s ease-in-out;
}
img.UploadScreen_rectangle__1Ud10 {
  width: 120px;
}
img.UploadScreen_rectangle__1Ud10:hover {
  width: 125px;
  box-shadow: 0px 2px 45px 0px #bca9d5;
  border-radius: 35px 0 0 0;
}
.UploadScreen_rectangle2__2nj-a:hover {
  width: auto;
  box-shadow: 0px 2px 45px 0px #bca9d5;
  border-radius: 33px 0 0 0;
}
.UploadScreen_scoring-title__vmWbv p {
  font-weight: normal;
  font-size: 22px;
  line-height: 39px;
  color: #000000;
}
.UploadScreen_scoring-title__vmWbv p span {
  color: #e03813;
}
.UploadScreen_scoring-upload2__2_m-r {
  background: rgba(0, 0, 0, 0.79);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 19px;
  padding: 15px;
  margin: -40px 0 0;
  position: relative;
}
.UploadScreen_scoring-upload-box2__LKdeh {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 5px;
  text-align: center;
  color: #fff;
  height: 300px;
}
.UploadScreen_scoring-upload2__2_m-r p {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 20px 0px 50px;
}
.UploadScreen_scoring-upload2__2_m-r p span{
  color: #ff7700;
  cursor: pointer;
}
.UploadScreen_scoring-upload-box2__LKdeh img {
  width: 100%;
  /* height: auto; */
  height: 289px;
  object-fit: contain;
}
.UploadScreen_slider2__wjW1I {
  margin: -60px 55px 0 !important;
  z-index: 1;
  position: relative;
}

.UploadScreen_scoring-title2__bJHnh h1 { 
  font-weight: normal;
  font-size: 75px;
  line-height: 70px;
  color: #000000;
  font-family: 'Rozha One';
}
.UploadScreen_scoring-title2__bJHnh h1 span {
  font-weight: normal;
  font-size: 47px;
  line-height: 39px;
  color: #fff;
  color: var(--textColor);
}
.UploadScreen_scoring-title2__bJHnh ul {
  padding: 0;
  list-style: none;
  margin: 35px 0 30px;
}
.UploadScreen_scoring-title2__bJHnh ul li {
}
.UploadScreen_scoring-title2__bJHnh ul li p {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}
.UploadScreen_scoring-title2__bJHnh ul li p button {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #e94949;
  border-radius: 7px;
  padding: 0px !important;
  margin-right: 20px;
  width: 85px;
  height: 33px;
  cursor: auto;
  cursor: initial;
}

.UploadScreen_comming-2__2ez-u {
  background: #ffffff !important;
  border: 1px solid #f7f7f7 !important;
  box-sizing: border-box;
  border-radius: 7px;
  font-weight: 600;
  font-size: 8px !important;
  margin-right: 10px;
  line-height: 15px;
  color: #000000 !important;
  min-width: 86px !important;
  padding: 0 !important;
  font-weight: 800 !important;
}

.UploadScreen_nine__3IWuT {
  background: #fff !important;
  background: var(--resColor) !important;
}

.UploadScreen_six__39aFn {
  background: #fff !important;
  background: var(--blurColor) !important;
}
.UploadScreen_five__3ZEeV {
  background: #fff !important;
  background: var(--cropColor) !important;
  /* padding: 5px 20px !important; */
}
.UploadScreen_scoring-title2__bJHnh ul li p i {
  margin-left: 15px;
  color: #ca3e12;
  border: 1px solid;
  font-weight: 300;
  padding: 2px 6px;
  border-radius: 45px;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
} 
.UploadScreen_tooltip__y3Lc- {
  position: relative;
text-decoration: none;
}
.UploadScreen_tooltip__y3Lc- span {
	background: none repeat scroll 0 0 #222222d1;
    color: #fff!important; 
    font-size: 10px;
    font-weight: normal;
    line-height: 19px;
    padding: 10px;
    width: 260px;
    
    margin-left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    z-index: 2;
    text-transform: none;
    transition: all 0.3s ease-in-out;
}


.UploadScreen_tooltip__y3Lc-:hover span {
  opacity: 1;
filter: alpha(opacity=100);
top: -2em; 
z-index: 99;
transition: all 0.2s ease;
}

.UploadScreen_comming__2uKwa {
  background: #ffffff !important;
  border: 1px solid #f7f7f7 !important;
  box-sizing: border-box;
  border-radius: 7px;
  font-weight: 600;
  font-size: 8px !important;
  margin-right: 10px;
  line-height: 15px;
  color: #000000 !important;
}
.UploadScreen_scoring-title2__bJHnh ul li p b {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.4);
}

.UploadScreen_marketplace-dimension__3SDld h5 {
  font-family: 'Rozha One';
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;

  color: #000000;
}
.UploadScreen_marketplace-dimension__3SDld h5 span {
  /* color: #ff7700; */
}
.UploadScreen_marketplace-dimension__3SDld li {
  display: inline-block;
  padding: 5px;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  color: #565656;
}
.UploadScreen_marketplace-img__1chK9 {
  opacity: 0.4;
  -webkit-filter: drop-shadow(0px 10px 20px rgba(41, 41, 42, 0.07));
          filter: drop-shadow(0px 10px 20px rgba(41, 41, 42, 0.07));
  background: transparent;
  padding: 5px;
}
.UploadScreen_marketplace-img__1chK9 img {
  width: 50px;
}
.UploadScreen_marketplace-img__1chK9.UploadScreen_active__1_1Ar {
  border: 3px solid #14ac20;
  border-radius: 50%;
  width: 100%;
  opacity: 1;
  position: relative;
  z-index: 0;
  height: 65px;
  margin: 0;
}
.UploadScreen_check__2MKT1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 17px !important;
  background: #fff;
  border-radius: 50%;
  padding: 2px;
  transition: 0.3s ease-in-out;
}
.UploadScreen_marketplace-img__1chK9:hover .UploadScreen_check__2MKT1,
.UploadScreen_slider-area__3y10f p span:hover i {
  -webkit-transform: rotate(310deg);
          transform: rotate(310deg);
}
.UploadScreen_scoring-title2__bJHnh p {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #262525;
}
.UploadScreen_scoring-title2__bJHnh h5 button {
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  transition: 0.2s ease;
  min-width: 150px;
  margin-right: 10px;
}
.UploadScreen_try-btn__2mUpk {
  border: 2px solid #ff7700;
}
.UploadScreen_try-btn__2mUpk:hover {
  box-shadow: 0px 3px 5px 0px #9e9e9e6e;
}
.UploadScreen_try-api__1tvfK {
  border: 2px solid #121211;
  box-sizing: border-box;
  background: #ffffff;
  color: #131312;
}
.UploadScreen_try-api__1tvfK:hover {
  color: #ff7700;
  border-color: #ff7700;
}
.UploadScreen_get-app__13qE1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  background: transparent;
  border: 0;
}
.UploadScreen_get-app__13qE1 i {
  color: #ff7700;
}
.UploadScreen_get-app__13qE1:hover {
  color: #ff7700;
}
.UploadScreen_get-app__13qE1:hover i {
  margin-left: 5px;
}
.UploadScreen_loading-gif__3Pagu {
  position: absolute;
  top: 40%;
}

.UploadScreen_drop-box-text__32otT {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.UploadScreen_drop-box-text__32otT div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.UploadScreen_try-img__1IGXx {
  width: 150px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 5px;
}
.UploadScreen_scan__3o51r{
  -webkit-animation: UploadScreen_slide2__DMDFw 1.5s ease-in-out infinite;
          animation: UploadScreen_slide2__DMDFw 1.5s ease-in-out infinite;
}
@-webkit-keyframes UploadScreen_slide2__DMDFw {
  0%, 100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg); opacity: 0.2;
  }
  50% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg); opacity: 0.5;
  }
}
@keyframes UploadScreen_slide2__DMDFw {
  0%, 100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg); opacity: 0.2;
  }
  50% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg); opacity: 0.5;
  }
}
.UploadScreen_progress__2xYwL { 
  border-radius: 8.5px;
}
.UploadScreen_progress__2xYwL .UploadScreen_progress-bar__2bfAI { 
  border-radius: 8.5px;
}


/* @Responsive  */
 

@media screen and (min-width: 1366px) {
  .UploadScreen_scoring-col__2WLHS{
    padding: 0px 40px 25px;
    margin-top: 18vh;
    min-height: 82vh;
  }
  .UploadScreen_scoring-upload-box__2iJlD{
    padding: 90px 20px 25px;
}
.UploadScreen_scoring-title__vmWbv h1 {
  font-size: 70px;
  line-height: 85px;
}
.UploadScreen_scoring-upload-box__2iJlD img {
  margin-bottom: 60px;

}
.UploadScreen_scoring-title__vmWbv{
  padding: 50px 60px 25px;
}
.UploadScreen_slide__2IC5k img{
  margin: 0%;
}
.UploadScreen_image-container__3o4qg{
  padding: 0 12px 0 70px;
}
.UploadScreen_scoring-upload-box2__LKdeh{
  height: 331px;
}
.UploadScreen_scoring-upload-box2__LKdeh img {
  height: 320px;
}
.UploadScreen_scoring-title2__bJHnh {
  margin-top: 12vh;
}
}
@media screen and (min-width: 1440px) {
  
.UploadScreen_image-container__3o4qg {
  width: 100%;
  padding: 0 12px 0 80px;
}
.UploadScreen_scoring-title__vmWbv h1 {
  font-size: 70px;
  line-height: 90px;
}
.UploadScreen_image-scoring__3DZmo {
  padding: 0px 0 0px;
}
.UploadScreen_scoring-upload-box__2iJlD p {
  margin-bottom: 35px;
}
.UploadScreen_slider-area__3y10f p{
  margin-bottom: 0;
}
.UploadScreen_scoring-title__vmWbv p {
  font-size: 23px;
}
.UploadScreen_scoring-title__vmWbv {
  padding: 80px 125px 0 80px;
  margin-top: 22vh;
}
.UploadScreen_scoring-title2__bJHnh {
  padding: 100px 80px 0;
  margin-top: 5vh;
}
.UploadScreen_scoring-col__2WLHS{ 
  margin-top: 17vh;
    min-height: 83vh;
}
.UploadScreen_scoring-upload-box__2iJlD{
  padding: 110px 20px 25px;
}
.UploadScreen_slide__2IC5k img {
  height: 70px!important;
}
.UploadScreen_slide2__DMDFw img {
  height: 60px!important;
}
.UploadScreen_scoring-upload-box2__LKdeh{
  height: 430px;
}
.UploadScreen_scoring-upload-box2__LKdeh img { 
  height: 419px;
}
.UploadScreen_scoring-upload-box__2iJlD img {
  margin-bottom: 70px;
  
  }
.UploadScreen_progress__2xYwL{
  margin-bottom: 40px;
}
.UploadScreen_scoring-title2__bJHnh ul li p {
  font-size: 25px;
}
.UploadScreen_scoring-title2__bJHnh ul li p button {
  font-size: 19px;
  line-height: 30px;
  width: 110px;
  height: 45px;
}
.UploadScreen_comming__2uKwa {
  font-size: 12px !important;
}
.UploadScreen_scoring-title2__bJHnh ul {
  margin: 35px 0 40px;
}
.UploadScreen_scoring-title2__bJHnh h5 button {
  font-size: 17px;
  line-height: 35px; 
  min-width: 170px;
  margin-right: 20px;   
   padding: 7px 15px;
}
.UploadScreen_try-img__1IGXx{
  width: 170px;
  margin-right: 20px;
  margin-top: 15px;
}
.UploadScreen_scoring-upload2__2_m-r {
  margin: -55px 0 0;
}
.UploadScreen_slide__2IC5k img{
  margin: 0%;
}
}



@media screen and (min-width: 1680px) {
  
  .UploadScreen_image-container__3o4qg { 
    padding: 0 12px 0 100px;
  }
  .UploadScreen_scoring-title__vmWbv h1 {
    font-size: 88px;
    line-height: 115px;
  }
  .UploadScreen_scoring-title2__bJHnh h1 {
    font-size: 105px;
    line-height: 115px;
  }
  .UploadScreen_scoring-title2__bJHnh h1 span {
    font-size: 65px;
  }
  .UploadScreen_image-scoring__3DZmo {
    padding: 0px 0 0px;
  }
  .UploadScreen_scoring-upload-box__2iJlD p {
    margin-bottom: 35px;
  }
  .UploadScreen_slider-area__3y10f p{
    margin-bottom: 0;
  }
  .UploadScreen_scoring-title__vmWbv p {
    font-size: 25px;
  }
  /* .scoring-title {
    padding: 80px 80px 0;
    margin-top: 16vh;
  } */
  .UploadScreen_scoring-upload__OOItN {
    margin: -65px 0 0;
    padding: 25px;
}
.UploadScreen_scoring-upload-box__2iJlD h6 {
  font-size: 20px;
}
.UploadScreen_scoring-upload-box__2iJlD p {
  font-size: 19px;
}
.UploadScreen_scoring-upload-box2__LKdeh{
  height: 475px;
}
.UploadScreen_scoring-upload-box2__LKdeh img { 
  height: 466px;
}
.UploadScreen_scoring-upload-box__2iJlD img {
margin-bottom: 100px; 
}
.UploadScreen_slide__2IC5k img{
  margin: 0%;
}
.UploadScreen_scoring-upload-box__2iJlD{
  padding: 120px 20px 25px;
}
.UploadScreen_scoring-col__2WLHS{
  padding: 0px 45px 30px;
  margin-top: 15.5vh;
    min-height: 84.5vh;
}
}

@media screen and (min-width: 1920px){
  .UploadScreen_scoring-title__vmWbv h1 {
    font-size: 96px;
    line-height: 125px;
  }
  .UploadScreen_scoring-upload-box__2iJlD {
    padding: 135px 20px 5px;
}
.UploadScreen_scoring-upload-box__2iJlD img {
  margin-bottom: 120px; 
}
.UploadScreen_slide__2IC5k img{
  margin: 0%;
}
.UploadScreen_scoring-title__vmWbv {
  padding: 100px 100px 5px;
  
}
.UploadScreen_scoring-upload2__2_m-r { 
  padding: 23px;
  margin: -50px 0 0; 
}
.UploadScreen_scoring-upload-box2__LKdeh{
  height: 540px;
}
.UploadScreen_scoring-upload-box2__LKdeh img { 
  height: 529px;
}
.UploadScreen_image-container__3o4qg{
  padding: 0 12px 0 150px;
}
.UploadScreen_scoring-col__2WLHS{ 
  margin-top: 15vh;
      min-height: 85vh;
      border: 45px solid #fff;
      border-bottom: 0;
      border-radius: 100px 100px 0px 0px;
}
.UploadScreen_scoring-col2__1LsGs{ 
  margin-top: 12vh;
      min-height: 88vh;
}
.UploadScreen_scoring-title2__bJHnh ul li p button {
  font-size: 20px;
  line-height: 32px;
  width: 115px;
  height: 52px;
}
.UploadScreen_scoring-title2__bJHnh  h1 {
  font-size: 110px;
  line-height: 133px;
}
.UploadScreen_scoring-title2O__2Qm78 ul {
  margin: 45px 0 50px;
}
.UploadScreen_scoring-title2__bJHnh ul li p {
  font-size: 27px;
  margin-bottom: 25px;
}
.UploadScreen_scoring-title2__bJHnh ul li p i { 
  font-size: 18px;
}
.UploadScreen_slide__2IC5k img {
  height: 80px!important;
} 
}
@media screen and (max-width: 767px) {
  .UploadScreen_image-scoring__3DZmo {
    padding: 70px 0 0px;
}

.UploadScreen_scoring-title__vmWbv {
  padding: 0px 10px 60px;
  order: 1;
  margin: 0;
}
.UploadScreen_image-container__3o4qg { 
  padding: 0 12px;
}
.UploadScreen_scoring-col__2WLHS{
  order: 2;
  padding: 0px 20px 30px;
  margin: 0;
  border: 0;
  min-height: auto;
  height: auto;
  border-radius: 31px 31px 0 0;
}
.UploadScreen_rectangle2__2nj-a, .UploadScreen_rectangle__1Ud10{
  display: none;
}
.UploadScreen_scoring-title__vmWbv h1 {
  font-size: 35px;
  line-height: 45px;
}
.UploadScreen_scoring-title__vmWbv p {
  font-size: 14px;
  line-height: 30px;
}
.UploadScreen_scoring-upload-box__2iJlD img {
  margin:0 auto 35px;
  width: 35%;
}
.UploadScreen_scoring-upload-box__2iJlD h6 { 
  font-size: 16px;
  margin-bottom: 9px;
}
.UploadScreen_scoring-upload-box__2iJlD p{
  font-size: 16px;
  padding: 5px;
}
.UploadScreen_slider-area__3y10f p{
  font-size: 10px;
  text-align: left; 
  padding: 5px 10px;
}
.UploadScreen_slide__2IC5k img{
  width: 100%;
  margin: 0;
  height: 45px!important;
}
.UploadScreen_scoring-col2__1LsGs{
  order: 1;
  margin: 50px 0 30px;
  border-radius: 31px;
}
.UploadScreen_scoring-upload-box2__LKdeh { 
  height: 280px;
}
.UploadScreen_scoring-upload-box2__LKdeh img {
  height: 269px;
}
.UploadScreen_slider2__wjW1I{
  margin: -60px 40px 0 !important;
}
.UploadScreen_scoring-title2__bJHnh ul li:nth-child(1) p b, .UploadScreen_scoring-title2__bJHnh ul li:nth-child(2) p b, .UploadScreen_scoring-title2__bJHnh ul li:nth-child(3) p b{
  display: none;
}
.UploadScreen_scoring-title2__bJHnh ul li p b {
  font-size: 14px;
  
}
.UploadScreen_scoring-title2__bJHnh h1 span { 
  font-size: 26px;
}
 
.UploadScreen_scoring-title2__bJHnh h5 {
  text-align: center;
  justify-content: center;
}
.UploadScreen_scoring-title2__bJHnh h5 button {
  min-width: 46%;
  margin: 0 5px;
  line-height: 24px;
}
.UploadScreen_try-img__1IGXx {
  width: 46%;
  margin: 10px 5px 0;
}
.UploadScreen_scoring-title2__bJHnh ul li p {
  font-size: 16px;
}
.UploadScreen_scoring-title2__bJHnh ul li p button { 
  font-size: 14px;  
  width: 80px;
  height: 30px;
}


}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .UploadScreen_scoring-col__2WLHS{
    height: auto; 
    margin: 14vh auto 0;
    padding: 0px 15px 30px;
  }
  .UploadScreen_image-container__3o4qg {
    width: 100%;
    padding: 0 15px;
}
.UploadScreen_scoring-title__vmWbv {
  padding: 50px 0px 60px 0;
  margin-top: 9vh; 
}
.UploadScreen_scoring-title__vmWbv  h1{
  font-size: 40px;
  line-height: 60px;
}
.UploadScreen_scoring-title2__bJHnh  h1{
  font-size: 60px;
}
.UploadScreen_scoring-title2__bJHnh  h1 span{
  font-size: 40px;
}
.UploadScreen_rectangle2__2nj-a, .UploadScreen_rectangle__1Ud10{
  display: none;
}
.UploadScreen_scoring-upload-box__2iJlD h6 {
  font-size: 10px;
  margin-bottom: 10px;
}
.UploadScreen_scoring-upload-box__2iJlD p {
  font-size: 10px;
}
.UploadScreen_slide__2IC5k img{
  height: 45px!important;
}

}





.scan {
  /* width: 98%; */
  text-align: center;
  padding: 6px 2px;
  /* position: absolute; */
  left: 50%;
  right: 0;
  top: 0%;
  margin: auto;
  /* border: dashed 0.25
    em
     rgb(90, 85, 85); */
     bottom: 0%;
     height: 100%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  opacity: 0.8;
}

.scan::after {
  content: '';
  /* background: conic-gradient(
    from 250.23deg at 50% 50%,
    #eee28d -15.84deg,
    #b19de3 109.29deg,
    #ef8b9c 256.45deg,
    #eee28d 344.16deg,
    #b19de3 469.29deg
  ); */
  background: #ef8b9c;
  width: 100%;
  height: 0.5em;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.8;
  z-index: 2;
  -webkit-animation: 2s infinite ease-in-out roll;
          animation: 2s infinite ease-in-out roll;
}

/* .scan img {
  height: 30px;
  width: 98%;
} */



@-webkit-keyframes roll {
  0% {
    -webkit-transform: translatey(-150px);
            transform: translatey(-150px); 
  }
  50% {
    -webkit-transform: translatey(150px);
            transform: translatey(150px); 
  }
  100% {
    -webkit-transform: translatey(-150px);
            transform: translatey(-150px);
  }
}



@keyframes roll {
  0% {
    -webkit-transform: translatey(-150px);
            transform: translatey(-150px); 
  }
  50% {
    -webkit-transform: translatey(150px);
            transform: translatey(150px); 
  }
  100% {
    -webkit-transform: translatey(-150px);
            transform: translatey(-150px);
  }
}

/* @Responsive */
@media screen and (min-width: 1440px) {
@-webkit-keyframes roll {
  0% {
    -webkit-transform: translatey(-210px);
            transform: translatey(-210px); 
  }
  50% {
    -webkit-transform: translatey(210px);
            transform: translatey(210px); 
  }
  100% {
    -webkit-transform: translatey(-210px);
            transform: translatey(-210px);
  }
}
@keyframes roll {
  0% {
    -webkit-transform: translatey(-210px);
            transform: translatey(-210px); 
  }
  50% {
    -webkit-transform: translatey(210px);
            transform: translatey(210px); 
  }
  100% {
    -webkit-transform: translatey(-210px);
            transform: translatey(-210px);
  }
}

}

@media screen and (min-width: 1680px) {
  @-webkit-keyframes roll {
    0% {
      -webkit-transform: translatey(-240px);
              transform: translatey(-240px); 
    }
    50% {
      -webkit-transform: translatey(240px);
              transform: translatey(240px); 
    }
    100% {
      -webkit-transform: translatey(-240px);
              transform: translatey(-240px);
    }
  }
  @keyframes roll {
    0% {
      -webkit-transform: translatey(-240px);
              transform: translatey(-240px); 
    }
    50% {
      -webkit-transform: translatey(240px);
              transform: translatey(240px); 
    }
    100% {
      -webkit-transform: translatey(-240px);
              transform: translatey(-240px);
    }
  }
  
  }
  @media screen and (min-width: 1920px) {
    @-webkit-keyframes roll {
      0% {
        -webkit-transform: translatey(-270px);
                transform: translatey(-270px); 
      }
      50% {
        -webkit-transform: translatey(270px);
                transform: translatey(270px); 
      }
      100% {
        -webkit-transform: translatey(-270px);
                transform: translatey(-270px);
      }
    }
    @keyframes roll {
      0% {
        -webkit-transform: translatey(-270px);
                transform: translatey(-270px); 
      }
      50% {
        -webkit-transform: translatey(270px);
                transform: translatey(270px); 
      }
      100% {
        -webkit-transform: translatey(-270px);
                transform: translatey(-270px);
      }
    }
    
    }
  @media screen and (max-width: 767px) {
    @-webkit-keyframes roll {
      0% {
        -webkit-transform: translatey(-135px);
                transform: translatey(-135px); 
      }
      50% {
        -webkit-transform: translatey(135px);
                transform: translatey(135px); 
      }
      100% {
        -webkit-transform: translatey(-135px);
                transform: translatey(-135px);
      }
    }
    @keyframes roll {
      0% {
        -webkit-transform: translatey(-135px);
                transform: translatey(-135px); 
      }
      50% {
        -webkit-transform: translatey(135px);
                transform: translatey(135px); 
      }
      100% {
        -webkit-transform: translatey(-135px);
                transform: translatey(-135px);
      }
    }
    
    }



.Admin_admin-area__qI3Sj{
    padding: 100px 0 60px;
}
.Admin_admin-form__1HZan{
    box-shadow: 0px 2px 10px 0px #ddd;
    padding: 15px;
}
.Admin_admin-form__1HZan form {

}
.Admin_admin-form__1HZan form label{
    width: 100%;
    display: block;
    margin-bottom: 15px;
}
.Admin_admin-form__1HZan form input{
    width: 75%;
    margin-right: 0;
    margin-left: 10%;
    font-size: 14px;
    box-shadow: 0 0 0;
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px 15px;
    font-weight: 200;
}
.Admin_admin-area__qI3Sj table{
width: 100%;
}
.Admin_admin-area__qI3Sj table select{
    padding: 5px 15px;
    border: 1px solid #ddd;
    width: 70%;
    margin-right: 0;
    margin-left: 10%;
}

.Admin_enterprise-list__2m8U1{
    display: grid;
    grid-template-columns: 50% 50%;
}

.Admin_table-area__2v-Ef{
    box-shadow: 0px 2px 10px 0px #ddd;
    padding: 15px;
    text-align: center;
}

/* @Responsive  */

@media screen and (max-width: 767px) {
    .Admin_admin-area__qI3Sj {
        padding: 100px 10px 60px;
    }
    .Admin_admin-form__1HZan form input {
        width: 100%;
        margin: 10px  0;
            border: 1px solid #ddd;
 
    }
    .Admin_admin-area__qI3Sj table td{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .Admin_admin-area__qI3Sj table td select{
        width: 100%;
        margin: 0;
    }
}



@font-face {
    font-family: Helvetica;
    src: url('https://storage.googleapis.com/spyne-website/Facebook-template/Helvetica-Font/Helvetica-Bold.ttf');
}

h1,
h2,
h3 {
    font-family: Helvetica!important;
}

h4,
h5,
h6,
p,
a,
span,
div {
    font-family: 'Inter'!important;
    padding: 0;
}

.Template_banner-area__37VAA {
    margin-top: 11vh;
    background: url('https://storage.googleapis.com/spyne-website/Facebook-template/banner-bg.png');
    height: 89vh;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 4%;
}

.Template_banner-text__3c8xJ {
    padding-bottom: 15px;
}

.Template_banner-text__3c8xJ h1 {
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    color: #181818;
    padding-top: 18vh;
    margin-bottom: 30px;
}

.Template_banner-text__3c8xJ h1 span {
    color: #f70;
}

.Template_banner-text__3c8xJ button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #000000;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Template_banner-image__1lTnW {
    padding-top: 6vh;
    margin: 0 auto;
    text-align: center;
}

.Template_banner-image__1lTnW img {
    height: 73vh;
    width: auto;
    margin: 0 auto;
}

.Template_black-box__3KnGx {
    background: #373535;
    height: 150px;
}

.Template_form-section__1vyLu {
    background: #F9F9F9;
}

.Template_form-area__1work {
    background: #FFFFFF;
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
    border-radius: 37px;
    padding: 40px 55px;
    margin-top: -15%;
    margin-bottom: 70px;
}

.Template_form-area__1work h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 127.13%;
    text-align: center;
    color: #020202;
    padding: 0 25px;
}

.Template_form-area__1work p {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    margin-bottom: 35px;
    padding: 0 25px;
}

.Template_form-area__1work label {
    font-weight: bold;
    font-size: 18px;
    line-height: 127.13%;
    text-transform: capitalize;
    margin-right: 5px;
    color: #020202;
}

.Template_form-area__1work input,
.Template_form-area__1work fieldset {
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 10px;
}

.Template_form-area__1work input,
.Template_form-area__1work input::-webkit-input-placeholder {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    height: 46px;
}

.Template_form-area__1work h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 127.13%;
    text-transform: capitalize;
    color: #020202;
    margin: 20px 0 15px;
}

.Template_radio-select__3gtyv input {
    box-shadow: 0 0;
    height: auto!important;
}

.Template_form-area__1work .Template_radio-select__3gtyv label {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    margin-left: 10px;
}

.Template_sumbition-popup__3vecN p {
    font-size: 15px !important;
}

.Template_form-content__313R3 input {
    color: #000000 !important;
}

.Template_form-content__313R3 input::-webkit-input-placeholder {
    color: #9c9696 !important;
}

.Template_form-content__313R3 input:-ms-input-placeholder {
    color: #9c9696 !important;
}

.Template_form-content__313R3 input::placeholder {
    color: #9c9696 !important;
}

.Template_form-content__313R3 span {
    font-size: 12px !important;
    margin: 0 !important;
    padding: 0;
    color: red;
    text-align: left;
    margin-left: 5px;
}

.Template_btn-area__8Xted {
    padding: 10px 0;
}
.Template_btn-area__8Xted button, .Template_expand-btn__2rXcO button {
    background: #000000;
    /* box-shadow: 0px 0px 3.73333px rgba(0, 0, 0, 0.25); */
    border-radius: 5.33333px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF; 
}
.Template_banner-text__3c8xJ a {
    background: #000000;
    /* box-shadow: 0px 0px 3.73333px rgba(0, 0, 0, 0.25); */
    border-radius: 5.33333px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none!important;
}

.Template_application-loaded__vgPLd {
    padding: 50px 0 30px;
}

.Template_heading__2UZXG {
    margin-bottom: 40px;
    padding: 0 5%;
}

.Template_heading__2UZXG h2 {
    font-size: 45px;
    line-height: 130%;
    text-align: center;
    text-transform: capitalize;
    color: #020202;
    margin-bottom: 0px;
    font-weight: bold;
}

.Template_heading__2UZXG p {
    font-size: 18px;
    line-height: 32px;
    color: #959599;
    text-align: center;
}

.Template_left-app__3I3RR {}

.Template_left-single__2h_wJ {
    position: relative;
    margin: 12vh 0 0;
}

.Template_left-single__2h_wJ h5 {
    font-weight: bold;
    font-size: 23px;
    line-height: 32px;
    text-transform: capitalize;
    color: #020202;
}

.Template_left-single__2h_wJ p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #18191F;
}

.Template_left-single__2h_wJ:before {
    content: attr(data-name);
    position: absolute;
    left: 10px;
    top: -40px;
    font-weight: 800;
    font-size: 20px;
    line-height: 37px;
    text-transform: capitalize;
    color: rgba(65, 35, 152, 0.15);
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}

.Template_middle-image__ykWI6 {
    text-align: center;
}

.Template_middle-image__ykWI6 img {
    height: 74vh;
    width: auto;
    background: url('https://storage.googleapis.com/spyne-website/Facebook-template/temp-bg.png');
    background-size: contain;
}

.Template_amazing-web__mx-hG {
    padding: 50px 0 25px;
}

.Template_left-web__33xwy {
    margin: 0;
}

.Template_left-web__33xwy img {
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0;
    transition: .3s ease-out;
}

.Template_left-web__33xwy img:hover {
    box-shadow: 0px 2px 12px 0px #9e9e9e6e;
}

.Template_left-web__33xwy h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    color: #020202;
}

.Template_left-web__33xwy p {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #18191F;
}

.Template_templates__34_fc {
    padding: 50px 0;
}

.Template_expand-btn__2rXcO {
    text-align: center;
    transition: .5s ease-out;
}

.Template_expand-btn__2rXcO button {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    background: #F2F3F5;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 15px 0 0;
    transition: .5s ease-in-out;
}

.Template_single-dash-image__2jmDK {
    text-align: center;
}

.Template_single-dash-image__2jmDK img {
    width: 85%;
    margin: 0 auto;
}

.Template_dashboard-content__3EWIm {
    padding-left: 20px;
    margin-bottom: 10px;
}

.Template_dashboard-content__3EWIm h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #18191F;
}

.Template_dashboard-content__3EWIm p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #18191F;
}

.Template_dashboard-image__39LeC img {
    width: auto;
}

.Template_dashboard-image__39LeC {
    padding: 0 10px 0 0;
}

.Template_power-your-marketplace__2JHGZ {
    background: #f2f2f25e;
    margin-top: 30px;
}

.Template_power-your-marketplace__2JHGZ .Template_heading__2UZXG {
    text-align: left;
    padding: 50px 20px;
    margin: 0;
}

.Template_power-your-marketplace__2JHGZ h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #18191F;
    text-align: left;
    margin-bottom: 10px;
}

.Template_power-your-marketplace__2JHGZ p {
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    color: #18191F!important;
    text-align: left;
}

.Template_power-your-marketplace__2JHGZ h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #18191F!important;
    margin-top: 20px;
}

.Template_power-your-marketplace__2JHGZ h5 {}

.Template_power-your-marketplace__2JHGZ h5 img {
    width: 145px;
    margin-right: 10px;
}

.Template_marketplace-images__WuW5f img {
    width: 100%;
    height: 100%;
}

.Template_fade-in__3yaU4 {}

.Template_left-web__33xwy img {
    animation: Template_fadeIn__2nbnc ease-in-out 5s;
    -webkit-animation: Template_fadeIn__2nbnc ease-in-out 5s;
    -moz-animation: Template_fadeIn__2nbnc ease-in-out 5s;
    -o-animation: Template_fadeIn__2nbnc ease-in-out 5s;
    -ms-animation: Template_fadeIn__2nbnc ease-in-out 5s;
}

@keyframes Template_fadeIn__2nbnc {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes Template_fadeIn__2nbnc {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* @Responsive */

@media screen and (max-width:767px) and (min-width: 200px) {
    .Template_banner-area__37VAA {
        padding: 0;
        height: auto;
        background: url('https://storage.googleapis.com/spyne-website/Facebook-template/mob-bg.png');
        background-position: left center;
        background-size: 100%;
    }
    .Template_banner-image__1lTnW {
        padding-top: 3vh;
    }
    .Template_banner-text__3c8xJ h1 {
        font-size: 25px;
        line-height: 30px;
        padding-top: 3vh;
        margin-bottom: 0;
    }
    .Template_banner-image__1lTnW img {
        height: 56vh;
        width: auto;
        margin: 0 auto 20px;
    }
    .Template_black-box__3KnGx {
        height: 70px;
    }
    .Template_form-section__1vyLu {
        padding: 0 15px;
    }
    .Template_form-area__1work {
        padding: 30px 15px;
        margin-top: -31%;
        margin-bottom: 40px;
    }
    .Template_form-area__1work h3 {
        font-size: 22px;
        margin-bottom: 10px;
        padding: 0;
    }
    .Template_form-area__1work p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
        padding: 0;
    }
    .Template_form-area__1work input,
    .Template_form-area__1work input::-webkit-input-placeholder {
        font-size: 14px;
        line-height: 24px;
        height: auto;
        margin-bottom: 0;
    }
    .Template_form-area__1work input {
        color: #000 !important;
        margin: 0;
    }
    .Template_form-area__1work label {
        font-size: 16px;
    }
    .Template_heading__2UZXG {
        margin-bottom: 25px;
        padding: 0 10px;
    }
    .Template_heading__2UZXG h2 {
        font-size: 20px;
        margin-bottom: 5px;
        line-height: 152%;
    }
    .Template_left-single__2h_wJ {
        margin: 9vh 0 0;
    }
    .Template_application-loaded__vgPLd {
        padding: 35px 0 0px;
    }
    .Template_middle-image__ykWI6 img {
        padding: 15px 0;
    }
    .Template_amazing-web__mx-hG {
        padding: 35px 0 0px;
    }
    .Template_left-web__33xwy {
        margin: 0 0 15px;
    }
    .Template_templates__34_fc {
        padding: 20px 0 30px;
    }
    .Template_heading__2UZXG p {
        font-size: 12px;
        line-height: 18px;
        margin: 5px 0 10px;
    }
    .Template_left-web__33xwy p {
        padding: 0 5px;
        font-size: 12px;
        line-height: 20px;
    }
    .Template_left-web__33xwy h5 {
        padding: 0 5px;
        font-size: 16px;
    }
    .Template_left-web__33xwy img {
        margin-bottom: 10px;
    }
    .Template_left-single__2h_wJ h5 {
        font-size: 18px;
        line-height: 20px;
        padding: 0;
    }
    .Template_left-single__2h_wJ p {
        font-size: 12px;
        line-height: 21px;
        padding: 0;
        margin-bottom: 10px;
    }
    .Template_left-single__2h_wJ:before {
        left: 0;
    }
    .Template_ai-app__2vUlR .Template_heading__2UZXG {
        margin-bottom: 10px;
    }
    .Template_dashboard-content__3EWIm {
        padding-left: 10px;
    }
    .Template_dashboard-image__39LeC {
        padding: 0 10px 10px;
    }
    .Template_single-dash-image__2jmDK img {
        width: 100%;
        margin: 0 auto 25px;
    }
    .Template_power-your-marketplace__2JHGZ {
        margin-top: 10px;
    }
    .Template_power-your-marketplace__2JHGZ .Template_heading__2UZXG {
        padding: 35px 10px;
        margin: 0;
        order: 2;
    }
    .Template_power-your-marketplace__2JHGZ h5 {
        text-align: center;
    }
    .Template_power-your-marketplace__2JHGZ h5 img {
        width: 135px;
        margin: 0 5px;
    }
    .Template_dashboard-image__39LeC img {
        width: 100%;
    }
    .Template_dashboard-content__3EWIm p {
        font-size: 12px;
        line-height: 20px;
    }
    .Template_dashboard-content__3EWIm h6 {
        font-size: 16px;
        line-height: 20px;
    }
    .Template_power-your-marketplace__2JHGZ h2 {
        line-height: 27px;
        margin-bottom: 10px;
    }
    .Template_fade-in__3yaU4 {
        padding: 0 5px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .Template_banner-area__37VAA {
        margin-top: 6vh;
        height: auto;
        padding-bottom: 70px;
    }
    .Template_banner-image__1lTnW img {
        height: 43vh;
    }
    .Template_banner-text__3c8xJ h1 {
        font-size: 39px;
        line-height: 60px;
        padding-top: 15vh;
    }
    .Template_black-box__3KnGx {
        height: 100px;
    }
    .Template_form-area__1work {
        padding: 30px 35px;
        margin-top: -17%;
    }
    .Template_form-area__1work h3 {
        font-size: 30px;
        padding: 0;
    }
    .Template_form-area__1work p {
        padding: 0;
        font-size: 14px;
    }
    .Template_heading__2UZXG h2 {
        font-size: 40px;
    }
    .Template_middle-image__ykWI6 img {
        height: 42vh;
    }
    .Template_left-single__2h_wJ {
        margin: 5vh 0 0;
    }
    .Template_single-dash-image__2jmDK img {
        width: 100%;
        margin: 0 auto;
    }
    .Template_dashboard-content__3EWIm {
        padding-left: 10px;
    }
    .Template_dashboard-content__3EWIm p {
        font-size: 13px;
        line-height: 20px;
    }
    .Template_power-your-marketplace__2JHGZ h2 {
        font-size: 25px;
        line-height: normal;
        line-height: initial;
    }
    .Template_power-your-marketplace__2JHGZ p {
        font-size: 14px;
        line-height: 22px;
    }
    .Template_power-your-marketplace__2JHGZ h5 img {
        width: 130px;
    }
    .Template_power-your-marketplace__2JHGZ .Template_heading__2UZXG {
        padding: 25px 15px;
        margin: 0;
    }
    .Template_power-your-marketplace__2JHGZ h6 {
        font-size: 17px;
        line-height: 22px;
        margin-top: 15px;
    }
}

@media (min-width: 1366px) {
    .Template_banner-area__37VAA {
        padding: 0;
    }
    .Template_form-area__1work {
        margin-top: -15%;
    }
    .Template_heading__2UZXG p {
        font-size: 20px;
        line-height: 32px;
        color: #959599;
    }
}

@media (min-width: 1440px) {
    .Template_form-area__1work input,
    .Template_form-area__1work input::-webkit-input-placeholder {
        height: 58px;
        font-size: 17px;
    }
    .Template_banner-text__3c8xJ h1 {
        font-size: 70px;
        line-height: 80px;
        padding-top: 20vh;
    }
    .Template_form-area__1work {
        margin-top: -14%;
    }
    .Template_heading__2UZXG h2 {
        font-size: 55px;
        line-height: 100%;
        line-height: 127.13%;
    }
    .Template_left-single__2h_wJ h5 {
        font-size: 30px;
        line-height: 45px;
    }
    .Template_left-single__2h_wJ p {
        font-size: 18px;
        line-height: 30px;
    }
    .Template_left-single__2h_wJ:before {
        font-size: 30px;
        line-height: 37px;
        top: -50px;
    }
    .Template_left-single__2h_wJ {
        position: relative;
        margin: 10vh 0 0;
    }
    .Template_left-web__33xwy h5 {
        font-size: 27px;
        line-height: 33px;
    }
    .Template_left-web__33xwy p {
        font-size: 18px;
        line-height: 30px;
    }
    .Template_left-web__33xwy img {
        margin-bottom: 20px;
    }
    .Template_dashboard-content__3EWIm p {
        font-size: 17px;
    }
    .Template_dashboard-content__3EWIm h6 {
        font-size: 22px;
        line-height: 25px;
    }
    .Template_power-your-marketplace__2JHGZ h2 {
        font-size: 40px;
    }
    .Template_power-your-marketplace__2JHGZ h6 {
        font-size: 25px;
        line-height: 40px;
    }
    .Template_power-your-marketplace__2JHGZ h5 img {
        width: 160px;
    }
    .Template_form-area__1work p {
        font-size: 18px;
    }
}

@media (min-width: 1680px) {
    .Template_heading__2UZXG p {
        font-size: 24px;
        line-height: 32px;
        color: #959599;
    }
    .Template_banner-area__37VAA {
        margin-top: 10vh;
        height: 90vh;
        padding: 0 0%;
    }
    .Template_banner-text__3c8xJ h1 {
        font-size: 75px;
        line-height: 100px;
        margin-bottom: 15px;
    }
    .Template_banner-text__3c8xJ button {
        font-size: 20px;
        line-height: 36px;
        min-width: 200px;
        margin-top: 15px;
    }
    .Template_form-area__1work h3 {
        font-size: 47px;
        margin-bottom: 15px;
    }
    .Template_form-area__1work {
        margin-top: -12%;
        padding: 6%;
        margin-bottom: 80px;
    }
    .Template_form-area__1work p {
        font-size: 22px;
        line-height: 35px;
    }
    .Template_form-area__1work label {
        font-size: 24px;
    }
    .Template_form-area__1work h4 {
        font-size: 25px;
    }
    input[type='radio']:after {
        width: 20px;
        height: 20px;
    }
    input[type='radio']:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #09980f;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #ddd;
    }
    .Template_form-area__1work .Template_radio-select__3gtyv label {
        font-size: 16px;
        margin-left: 20px;
    }
    button {
        font-size: 18px;
        line-height: 30px;
    }
    .Template_form-area__1work form {
        padding: 10px 0;
    }
    .Template_left-single__2h_wJ h5 {
        font-size: 35px;
        line-height: 45px;
    }
    .Template_left-single__2h_wJ p {
        font-size: 21px;
        line-height: 30px;
    }
    .Template_left-single__2h_wJ:before {
        font-size: 30px;
        line-height: 37px;
        top: -50px;
    }
    .Template_left-single__2h_wJ {
        position: relative;
        margin: 10vh 0 0;
    }
    .Template_left-web__33xwy h5 {
        font-size: 30px;
    }
    .Template_left-web__33xwy p {
        font-size: 23px;
        line-height: 32px;
    }
    .Template_left-web__33xwy img {
        margin-bottom: 25px;
    }
}

@media (min-width: 1920px) {
    .Template_banner-text__3c8xJ h1 {
        font-size: 85px;
    }
}
