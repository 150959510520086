.select-model{
    padding: 100px 0 60px;
}
.upload{
    text-align: center;
}
.upload-btn{
    background: #FF7700;
    border-radius: 5px;
    min-width: 120px;
}
.previous{
    float:left;
}

.prev-screen{

    text-align: center;
    background: #FF7700;
    border-radius: 50%;
    font-size: 30px;
    padding: 3px 10px 5px;
}
.upload-btn img{
    width: 22px;
    margin-right: 7px;
}
.credit-area{
    padding: 15px 0 0;
}
.available-credit{
    font-size: 14px;
    line-height: 150%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #232121;
    
}
.available-credit img{
    width: 35px;
    margin-right: 10px;
}
.available-credit span{
    color: #f70;
   
}
.right-top-credits{
text-align: right;

}
.right-top-credits button{
    padding: 5px 10px;
    width: auto;
    background: linear-gradient(
180deg
,hsla(0,0%,100%,.94),hsla(0,0%,100%,.24) 52.08%,hsla(0,0%,100%,0) 52.09%,hsla(0,0%,100%,.29)),linear-gradient(
180deg
,hsla(0,0%,100%,.4),hsla(0,0%,100%,0) 51.56%),#f70;
    background-blend-mode: soft-light,normal,normal;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), inset 0 -2px 6px rgb(44 0 0 / 35%);
    border-radius: 32px;
}
.right-top-credits a{

}
.right-top-credits a span{
    
}
.active{
color: #FF7700!important;
}
.model-tabs{
border-right: 1px solid #ddd;
}
.model-tabs {
    padding: 10px 0;
    list-style: none;
    margin: 0;

}
 
.model-tabs li{
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px; 
    padding: 10px 20px;
    color: #989898;
}
.model-tabs li span{
    background: #989898;
    color: #fff;
    border-radius: 50%;
    padding: 4px 10px;
    margin-right: 5px;
}
.model-tabs li--selected span{
    background: #FF7700;
}
.model-area{
    background: #FFFFFF;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
border-radius: 5px;
margin: 30px 0;

padding: 0;

}
.model-saree{
    /* border-top: 1px solid #EBEAEA;
    padding: 10px 0; */
}
.model-saree h4{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px; 
color: #000000; 
padding: 0px 20px 5px;
}
.model-saree h4 span{
    color: #FF7700;
    /* float: right; */
}
.pose h4 span{
     float: right;
}
.model-saree h4 label{
    padding: 5px 10px;
    background: #F0F0F1;
    border-radius: 77.7857px;
    font-size: 15px;
    float: right;
    order: 3;
    margin: 0 0 0 10px;
    min-width: 28%;
    text-align: left;
    margin-top: -5px;
}
.model-saree h4 label img{
width: auto;
}
.model-saree h4 label input{
    border: 0;
    font-size: 14px;
    background: transparent;
    width: 80%;
}
.model-saree h4 label input::placeholder{
    font-size: 14px;
}
.single-model{
    padding: 15px 10px;
}
.single-model img{
    /* border: 1.55921px solid #C9C9C9;
box-sizing: border-box;
border-radius: 23.3882px; */
width: 100%;
height: 400px;
    object-fit: contain;
}

.single-model button{
    background: transparent;
    padding: 0;
    margin-bottom: 0px;
    position: relative;
    border: 1.55921px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 23.3882px;
}
.single-model button.active {
    border-color: #FF7700 !important;
}
.single-model button p{
    position: absolute;
    bottom: 0px;
    background: #fff; 
    left: 1px;
    margin: 0;
    padding: 8px 10px;
    border-radius: 0px 0px 23.3882px 23.3882px;
    right: 2px;
    color: #333;
    transition: .2s ease-in-out;
    font-weight: 500;
    font-size: 10px;
    line-height: 110%;
    letter-spacing: 0.5px;
    text-align: left;
    color: #232121;
    transition: .2s ease-in-out;
}
.single-model button p span{
    float: right;
}
.single-model h5{
    position: absolute;
    top: -47%;
    right: -10px;
    width: auto;
    height: auto;
    transition: .2s ease-in-out;
}
.single-model  button p span img{
    width: 11px;
    margin-right: 5px;
    height: auto;
    border: 0;
}
.selection-model{
    height: 460px;
    overflow: auto;
}
.display-none{
    display: none;
}
.model-tabdetails{
    border-top: 1px solid #EBEAEA;
    padding: 15px 0;
}
.model-saree h6{
    text-align: center;
    padding: 10px 0;
}
.marketplace-select button{
    background: #f4f4f4;
    width: 100%;
}
.marketplace{
text-align: center;
}
.text-head{
    float: left!important;
    color: #000!important;
}

.marketplace button img{
    width: 100%;
    height: 165px;
    object-fit: contain;
}
.single-model h6 img{
    height: auto;
    width: auto;
    /* position: absolute;
    top: -10px;
    right: -10px; */
}




.try-drag-drop {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
    margin-bottom: 30px;
    cursor: pointer;
  }

  .drop-box-text {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .drop-box-text div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .back-button{
    border: unset;

    border-radius: 11px;
  
    padding: 0;
  }

  .toggle1 {
    position: relative;
    color: #f39e53;
  }
  .toggle1 input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider1 {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 190px;
  box-shadow: 0px 4px 4px rgb(255, 255, 255);
  border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
  }
  
  .slider1::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top:3px
  
  }
  
  input:checked + .slider1 {
    background-color: #78f353;
  }
  
  input:checked + .slider1::before {
    transform: translateX(21.5px);
  }
  .button-padding{
    padding: 0px 0px 0px 40px;

  }


/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px){
    .right-top-credits{
        padding-left: 0;
        margin-bottom: 10px;
    }
    .select-model{
        margin-left: -60px;
        padding: 80px 0 30px;
    }
    .single-model img {
        height: 100px;
        padding: 5px;
    }
    .selection-model {
        height: auto;
    }
    .model-tabs li {
        padding: 10px 15px;
    }
    .available-credit {
        font-size: 12px;
        text-align: left;
    }
    .right-top-credits button {
        font-size: 12px;
    }
    .model-saree h4 {
        font-size: 12px;
        
        padding: 0px 15px 5px;
    }
    .main-box{
        padding-left: 60px;
    }
    .pose h4 span {
        float: left;
    }
    .button-padding {
        padding: 0 10px;
    }
    .single-model {
        text-align: center;
    }
    .single-model button {
        overflow: hidden;
    }
    }
@media screen and (max-width: 900px) and (min-width: 768px){
  .main-box{
      padding-left: 60px;
  }
    .select-model{
        margin-left: -60px;
    }
    .single-model img { 
        height: 200px;
    }
    }
@media screen and (max-width: 1024px) and (min-width: 901px){
  
    .select-model{
        margin-left: -60px;
    }
    .single-model img { 
        height: 200px;
    }
    .main-box{
        padding-left: 60px;
    }
    }



