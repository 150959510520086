.wrapper {
  padding: 0px 72px;
  /* height: 521px; */

  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.left-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  height: 540px;
  text-align: center;
}
.left-box img {
  height: 100%;
  object-fit: contain;
}
.left-box-scaling {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  height: 630px;
  text-align: center;
}
.left-box-scaling img {
  height: 100%;
  object-fit: contain;
}
.display-image-with-bg {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/Model/background+3.png');
  height: 90%;
  background-repeat: no-repeat;
  background-size: cover;
}
.display-image {
  height: 90%;
  background-repeat: no-repeat;
  background-size: cover;
}
.right-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 540px;
  padding: 12px 32px;
  /* margin-left: 48px; */
}
.right-box span {
  background-color: grey;
  border-radius: 50%;
}
.right-box-scaling {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  padding: 12px 32px;
  /* margin-left: 48px; */
}
.right-box-scaling span {
  background-color: grey;
  border-radius: 50%;
}
.download-section {
  margin-top: 100px;
  text-align: center;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.screen-2-drop {
  /* max-width: 100% !important; */
  /* padding: 0px !important; */
  /* margin: 0px !important; */
}
.screen-2-drop:focus {
  border: 0;
  outline: 0;
}
@media (max-width: 800px) {
  .wrapper {
    padding: 0px 25px;
    width: auto;
  }
  .left-box {
    height: auto;
  }
  .left-box-scaling {
    height: auto;
  }
  .display-image {
    height: auto;
  }
  .left-box img {
    height: auto;
  }
  .left-box-scaling img {
    height: auto;
  }
  .right-box {
    height: auto;
    margin-top: 20px;
    margin-left: 0px !important;
    padding: 20px 10px;
  }
  .right-box-scaling {
    height: auto;
    margin-top: 20px;
    margin-left: 0px !important;
    padding: 20px 10px;
  }
  .right-box-scaling .col-auto {
    padding: 0 5px !important;
  }
}
