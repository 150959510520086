@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
body {
  /* padding: 0px !important; */
  background: #fff !important;
  font-family: 'Poppins';
}

.drop-box-text {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.drop-box-text div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.car-main-wrapper {
  background-size: cover !important;
  padding-bottom: 0px;
}
.back-button{
  padding-top: 100px !important;
  background-color: #fff;
  border: unset;
  border-radius: 11px;
  justify-content: left;
}
.header-section {
  text-align: center;
}
.heading-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.heading-para {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #4f4444;
  margin: 0px;
  padding: 0px;
}
.heading-para span {
  color: #ff7700 !important;
}
.active-walls-sec img {
  height: 35px;
}
.inactive-walls-sec img {
  height: 35px;
}
.inactive-walls-sec {
  padding: 5px;
  cursor: pointer;
  transform: scale(1);
  cursor: pointer;
  /* height: 85px; */
  width: auto;
}
.backgrounds-grid-custom {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 7px;
}
.inactive-walls-sec span {
  font-size: 13px;
}
.active-walls-sec {
  /* box-shadow: 0 2px 5px 0 #ddd; */
  padding: 3px;
  border: 2px solid #ff7700;
  transform: scale(1);
  cursor: pointer;
  /* height: 110px; */
  width: auto;
}
.active-walls-sec span {
  font-size: 13px;
}
.active-walls-sec img {
  width: 100%;
  height: 90px;
}
.inactive-walls-sec img {
  width: 100%;
  height: 90px;
}
.inactive-walls-sec {
  padding: 3px;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px 0 #ddd;
}

.wrapper {
  padding: 0;
  padding-top: 17px !important;
  display: flex;
  width: 100% !important;
}
.left-section {
  width: 40%;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-top: 8%;
  padding-bottom: 20px;
}
.mid-section {
  width: 75%;
  height: 250.04px;
  border: 1px dashed #77797b;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px auto 55px auto;
}
.sample-button {
  font-size: 16px;
  background: none;
  color: black;
  border: 3px solid #ff7700;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
}
.sample-button:hover {
  color: black;
}
.dropzone {
  width: 100%;
  height: 261.94px;
  text-align: center;
  padding-top: 60px;
  cursor: pointer;
  opacity: 0.3;
}
.dropzone:hover {
  opacity: 1;
}
.dropzone:focus {
  outline: none !important;
  border: none !important;
}
.para-1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0579df;
  margin: 0px;
  margin-top: 25px;
}
.para-2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff7700;
  margin: 0px 0 10px;
}
.para-2 span {
  color: #fff;
  background: #ff7700;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 14px;
}
.supports {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #c4c4c4;
}
.choose-button {
  margin-top: 0px;
  display: flex;
  justify-content: center;
}
.choose-button button {
  width: 140px;
  height: 40px;
  background: #ff7700 !important;
  border-radius: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.choose-button button:hover {
  color: #ffffff;
}
.choose-button button:focus {
  outline: none !important;
}
.right-section {
  width: 55%;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgb(119 121 123 / 50%);
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 25px;
  margin-left: 5%;
  height: auto;
}
/* .right-section div{
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
} */
.right-section img {
  /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Right+side.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  width: 100%;
}
.section-two {
  padding: 57px;
  background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Desktop+-+2+1.png');
  /* background-repeat:no-repeat; */
  background-size: contain;
  background-repeat: repeat-x;
  background-color: white;
}
.section-two-one {
  margin-top: 125px;
}
.section-two-one h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 132%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  margin-top: -40px;
}
.section-two-one p {
  max-width: 519px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}
.section-two-one span {
  color: #ff7700;
}
.section-two-one-right {
  background-image: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5886+1.png');
  padding: 70px 50px;
  margin-top: -125px;
  align-items: center;
}
.section-two-one-right div {
  max-width: 571px;
}
.section-two-three-right {
  margin-top: -125px;
  align-items: center;
}
.section-3 {
  padding: 57px;
}
.slider {
  height: 100%;
  width: 3px;
  background: white;
}
.slider img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  margin-left: -25px;
}
.section-3 {
  background: #ffffff;
}
.section-3-right div {
  vertical-align: center;
  /* position: absolute;
    top: 50%;
    transform: translate(-50%,-50%); */
  margin-left: 135px;
  margin-top: 20%;
  text-align: center;
}
.section-3-right p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.section-3-right button {
  border: unset;
  width: 220px;
  height: 60px;
  background: #ff7700;
  border-radius: 30.5px;
}
.section-3-right a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
}

.mob-area {
  padding-top: 0%;
}

.mob-area .why-use {
  text-align: left;
  padding: 10% 5% 0;
}

.why-use h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 25px;
  letter-spacing: 0.02em;
  color: #323232;
  padding: 0;
}

.mob-area .why-use p {
  margin-bottom: 30px;
}

.request-demo {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
}

.mob-area .why-use .request-demo2 {
  display: block;
}

.mob-area .why-use p img {
  margin-top: 30px;
}

.request-demo2 img {
  height: 42px;
  /* margin-left: 20px; */
}

.custom-backgrounds {
  padding: 60px 5% 40px;
}

.title {
  text-align: center;
}

.title h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  color: #ff7700;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  font-size: 33px;
  line-height: 54px;
  letter-spacing: 0.1px;
}

.exterior-tabs ul {
  text-align: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 25px;
}

.car-try-wrapper {
}
.try-images-area {
  padding: 5px 15px;
}
.car-left-section {
  margin-top: 25px;
}
.try-drag-drop {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -2px 3px 6px 0px rgb(0 0 0 / 15%);
  margin-bottom: 30px;
  cursor: pointer;
}
.try-drag-drop .dropzone1 {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
}
.try-drag-drop .dropzone1:hover {
  opacity: 1;
}
.try-drag-drop .dropzone1 img {
  margin-bottom: -30px;
  width: 15%;
}
.para-11 {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin: 15px 0 5px;
  color: #232121;
}
.supports1 {
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #232121;
}

/* Choose perfect output */
.perfect-output {
}
.choose-output {
}
.heading h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 0 0 10px;
  color: #232121;
}
.heading h3 span {
  border-bottom: 2px solid #ff7700;
  padding: 0 0 7px;
}
.heading h3 .border-1 {
  color: #232121;
  border-bottom: 2px solid #ff7700;
}
.perfect-output-images {
  text-align: center;
  padding: 20px 15px 0;
}
.perfect-output-images img {
  width: 100%;
}
.perfect-output-images p {
  text-align: center;
}
.perfect-output-images p span a {
  font-style: normal;
  color: #ff7700 !important;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  cursor: pointer;
}

.perfect-output-images p a {
  background: #ff7700;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  padding: 6px 30px;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.bg-try {
  /* background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
  background-position: top right;
  background-repeat: no-repeat; */
}

.choose-button {
}
.choose-button button {
  font-style: normal;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  background: #ff7700;
  border-radius: 10px;
  color: #ffffff;
  margin: 0 auto;
  padding: 8px 25px;
  transition: 0.2s ease-in-out;
  border: 2px solid #ff7700;
  height: 42px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #6c757d;
  width: max-content;
}
.bg-recommended {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  /* overflow: hidden;
  overflow-y: scroll; */
}
.bg-recommended h6 {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
  font-weight: 600;
}
.bg-recommended h6 span {
  color: #ff7700;
}
.bg-recomended-images {
  list-style: none;
  display: block;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.bg-recomended-images li {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 10px;
  cursor: pointer;
  width: 50%;
}
.bg-recomended-images li img {
  width: 100%;
  height: 56px;
}
.wall-cross {
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width: 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.active-background-sec img {
  /* border: 2px solid #000;
  transform: scale(1.1); */
}
.active-background-sec {
  border: 2px solid #000;
  padding: 3px !important;
}
.bg-recomended-images li span {
  font-size: 11px;
  font-weight: 500;
}
.perfect-output-1 {
  padding: 40px 0px;
  background: #fff;
}
.perfect-output-1 .heading {
  margin-bottom: 20px;
}
.bg-recomended-area {
  overflow-y: scroll;
  height: 400px;
}

.bg-recomended-right img {
  width: 100%;
}

.bg-recommended p {
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 0px 0px 20px;
}

.bg-recomended-right {
}
.bg-recomended-right img {
  margin-top: 20%;
}

.bg-recomended-area .perfect-output-images p {
  margin-top: 2%;
}
.bg-recomended-area .perfect-output-images p a {
  padding: 10px;
}

.download-popup {
  border: 1px solid #ddd;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.download-popup button {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}
.download-popup ul {
  padding-left: 15%;
  margin: 0 0 5px;
}
.download-popup ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
}
.download-popup p {
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #232121;
  padding-left: 10%;
}
.download-popup p span {
  color: #ff7700;
}
.download-popup h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.5px;
  color: #232121;
}
.download-popup input {
  margin-right: 7%;
  border: 0;
  outline: 0;
}
.download-popup input:focus {
  border: 0;
  outline: 0;
}
input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ec761b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #ddd;
}
.download-popup button {
  background: #fff;
  color: #000;
  padding: 10px;
  text-align: left;
}
.download-popup p button {
  background: #ff7700 !important;
  color: #fff !important;
  padding: 7px 15px;
}
.download-popup button.activate,
.download-popup button:hover {
  background: rgba(5, 121, 223, 0.05) !important;
  border: 1px solid #78838d !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.heading-text{
  padding: 0 20px;
}
/* Upload screen User credits */

.try-credit{
padding: 100px 6% 15px;
text-align: right;
}
 
.try-credit span{
  border: 2px solid #ffe7d2;
    border-radius: 10px; 
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding: 12px 10px;
    letter-spacing: .5px;
    margin-top: 7px;
    color: #232121;
  /* position: absolute;
  right: 20px; */
}
.try-credit span b{
  color: #ff7700;
}
.try-credit span img{
  margin-right: 20px;
}









/* @Responsive */
@media (max-width: 800px) {
  .car-main-wrapper {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .wrapper {
    display: flex;
    margin: 0;
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .right-section {
    width: 100%;
    height: auto;
    margin: 0px !important;
    padding: 13px 21px;
  }
  .left-section {
    width: 100%;
    margin: 0px 0 20px !important;
    margin-top: 12px !important;
  }
  .heading-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    color: #4f4444;
  }
  .heading-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .section-two {
    padding: 27px;
  }
  .section-two-one {
    margin-top: 70px;
  }
  .number-image {
    display: none;
  }
  .section-two-one h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
    margin-top: -40px;
  }
  .section-two-one p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .section-two-one-right {
    margin-top: 0px;
  }
  .section-3-right div {
    margin-left: 0px;
  }
  .mob-change {
    display: flex;
    flex-direction: column-reverse;
  }
  .mob-change h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .section-two-three-right img {
    margin-top: 120px;
  }
  .section-3 {
    padding: 27px;
  }
  .section-3-right p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .section-3-right button {
    width: 150px;
    height: 50px;
  }
  .try-images-area {
    padding: 5px 0;
    order: 1;
  }
  .car-left-section {
    order: 3;
  }
  .perfect-output-images p {
    text-align: center;
    margin: 0;
  }
  .try-images-area img {
    width: 100%;
    margin: 20px 0;
  }
  .choose-button {
    padding: 10% 10px;
  }
  .bg-try {
    background-position: right;
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg2.png);
    background-size: 100% 22%;
    background-repeat: no-repeat;
  }
  .perfect-output-images {
    order: 3;
    padding: 15px 0;
  }
  .bg-recomended-area {
    overflow: initial;
    order: 2;
    padding: 0 0 15px;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .bg-recomended-right img {
    margin: 0px 0px 30px;
  }
  .custom-big {
    display: none;
  }
  .bg-recomended-right {
    padding: 0;
  }
  .bg-recommended {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .active-background-sec {
    padding: 0 10px;
    border-radius: 5px;
  }
  .custom-wall {
    height: 90px !important;
    width: 100%;
    margin-bottom: 16px;
  }

  .inactive-background-sec {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .active-background-sec {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 5px;
    border: 2px solid #ff7700;
    transform: scale(1);
    cursor: pointer;
    height: 68px;
    width: 92% !important;
  }
  .perfect-output-images p a {
    font-size: 12px;
    padding: 5px 25px;
  }
  .heading h3 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .heading-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    color: #4f4444;
  }
  .heading-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
  .section-two-one p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .mob-change h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132%;
    text-align: center;
    color: #000000;
  }
  .try-images-area img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .heading-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 34px;
    color: #4f4444;
  }
  .heading-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4f4444;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 901px) {
  .left-section {
    margin-top: 6%;
  }
}

@media (min-width: 1400px) {
  .left-section {
    margin-top: 10%;
  }
}

.product-hunt-strip {
  width: 100%;
  background-color: #cc4d29;
  position: fixed;
  top: 12%;
  z-index: 999;
}

.product-hunt-strip h6 {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding-top: 4px;
}
