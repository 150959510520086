.landing-screen-three{
    padding: 50px 0;
    margin-top: 65px;
    background: #fff;
}
.screen-three-area{
    padding: 30px 20px 10px;
    margin: 0;
    border: 2px solid  #C4C4C4;
    box-sizing: border-box; 
    border-radius: 13px 13px 0 0;
    background: #fff;
    border-bottom: 0;
    text-align: center;
    transition: .2s ease-in-out;
}

.screen-three-area h1{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 45px;
color: #1C1E52;
}
.screen-three-area p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px; 
    color: #444444;
}

.screen-three-area .mybtn{
    width: 178px;
height: 43px;
background: #FF3503;
color: #fff;
margin: 15% 0 0;
border-radius: 20px;
}

.screen-three-area p span{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 45px;
color: #B4B4B4;
}
/* .screen-three-area:hover{
    background: linear-gradient(180deg, #FFFBF4 0%, rgba(250, 189, 175, 0) 100%);
} */
.screen-three-area ul{
    display: flex;
    width: 100%;
    margin-bottom: 5px;
}
.screen-three-area ul li{
    display: inline-block;
    padding: 10px 10px 0;
}
.screen-three-area ul li img{
    width: 100%;
}

.right-drag-drop{
    padding-top: 20px;
}
.image-editing{
    padding: 10px 15px 0;
}
.image-editing ul{

}
.image-editing ul li{
    
}
.image-editing ul li button{

}
.active{ 
    color: #FF3503 !important;
    border-bottom: 1px solid #FF3503 !important;
    border-radius: 0;
        background: transparent;
}
.image-editing ul {
    padding: 0px;
}
.image-editing ul li {
    padding: 0;
    margin: 5px 25px 0 0;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 1px solid transparent;
}
.image-editing ul li button {
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #696969;
    padding: 0;
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 25px;
    border-radius: 0!important;
    border-bottom: 1px solid transparent;
}
.image-editing ul li button:hover {
    color: #FF3503;
    border-bottom: 1px solid #FF3503;
    border-radius: 0;
    background: transparent;
}
.screen-3-drop-model{
    border: 1px dashed #696969;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;
}
.screen-3-drop-model img{
    width: auto;
    margin: 0 auto;
    margin-bottom: 5px;
}
.screen-3-drop-model .para-1{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 25px;
    color: #000;
}
.screen-3-drop-model .para-2{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 0;
    color: #000;
    line-height: 20px;
}
.mybtn-border{ 
    border: 1px solid #FF3503;
    box-sizing: border-box;
    border-radius: 20px;
    width: 178px;
    height: 43px;
}
.right-drag-drop p{
    font-weight: 500;
font-size: 9px;
line-height: 25px;
color: #949494; 
}
.tab-contents p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    color: #232323;
    text-align: left;
    margin: 0;
}
.right-drag-drop a{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 16px; 
text-decoration-line: underline;

color: #FF3503;

}
.right-drag-drop h6{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 35px;
color: #292929;
margin: 0;
}
.bottom-contact-badge{
    background: #dee2e6;
    padding: 0;
    border-radius: 0 0 11px 10px; 
    text-align: left;
}
.bottom-contact-badge p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px; 
    padding: 5px 30px; 
    margin: 0;
}
.bottom-contact-badge p span {
    color: #f70;
}
 
.switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 29px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .toggle-switch:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .toggle-switch {
    background-color: #FF3503;
  }
  
  input:focus + .toggle-switch {
    box-shadow: 0 0 1px #FF3503;
  }
  
  input:checked + .toggle-switch:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
.toggle-switch{
    border-radius: 55px;
}
.toggle-switch:before{
    border-radius: 50%;
}
.image-editing p{
    display: inline;
    font-size: 14px;
}




