@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
    font-family: Helvetica;
    src: url('https://storage.googleapis.com/spyne-website/Facebook-template/Helvetica-Font/Helvetica-Bold.ttf');
}

h1,
h2,
h3 {
    font-family: Helvetica!important;
}

h4,
h5,
h6,
p,
a,
span,
div {
    font-family: 'Inter'!important;
    padding: 0;
}

.banner-area {
    margin-top: 11vh;
    background: url('https://storage.googleapis.com/spyne-website/Facebook-template/banner-bg.png');
    height: 89vh;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 4%;
}

.banner-text {
    padding-bottom: 15px;
}

.banner-text h1 {
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    color: #181818;
    padding-top: 18vh;
    margin-bottom: 30px;
}

.banner-text h1 span {
    color: #f70;
}

.banner-text button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #000000;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.banner-image {
    padding-top: 6vh;
    margin: 0 auto;
    text-align: center;
}

.banner-image img {
    height: 73vh;
    width: auto;
    margin: 0 auto;
}

.black-box {
    background: #373535;
    height: 150px;
}

.form-section {
    background: #F9F9F9;
}

.form-area {
    background: #FFFFFF;
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
    border-radius: 37px;
    padding: 40px 55px;
    margin-top: -15%;
    margin-bottom: 70px;
}

.form-area h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 127.13%;
    text-align: center;
    color: #020202;
    padding: 0 25px;
}

.form-area p {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    margin-bottom: 35px;
    padding: 0 25px;
}

.form-area label {
    font-weight: bold;
    font-size: 18px;
    line-height: 127.13%;
    text-transform: capitalize;
    margin-right: 5px;
    color: #020202;
}

.form-area input,
.form-area fieldset {
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 10px;
}

.form-area input,
.form-area input::-webkit-input-placeholder {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    height: 46px;
}

.form-area h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 127.13%;
    text-transform: capitalize;
    color: #020202;
    margin: 20px 0 15px;
}

.radio-select input {
    box-shadow: 0 0;
    height: auto!important;
}

.form-area .radio-select label {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    margin-left: 10px;
}

.sumbition-popup p {
    font-size: 15px !important;
}

.form-content input {
    color: #000000 !important;
}

.form-content input::placeholder {
    color: #9c9696 !important;
}

.form-content span {
    font-size: 12px !important;
    margin: 0 !important;
    padding: 0;
    color: red;
    text-align: left;
    margin-left: 5px;
}

.btn-area {
    padding: 10px 0;
}
.btn-area button, .expand-btn button {
    background: #000000;
    /* box-shadow: 0px 0px 3.73333px rgba(0, 0, 0, 0.25); */
    border-radius: 5.33333px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF; 
}
.banner-text a {
    background: #000000;
    /* box-shadow: 0px 0px 3.73333px rgba(0, 0, 0, 0.25); */
    border-radius: 5.33333px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 20px;
    width: fit-content;
    text-decoration: none!important;
}

.application-loaded {
    padding: 50px 0 30px;
}

.heading {
    margin-bottom: 40px;
    padding: 0 5%;
}

.heading h2 {
    font-size: 45px;
    line-height: 130%;
    text-align: center;
    text-transform: capitalize;
    color: #020202;
    margin-bottom: 0px;
    font-weight: bold;
}

.heading p {
    font-size: 18px;
    line-height: 32px;
    color: #959599;
    text-align: center;
}

.left-app {}

.left-single {
    position: relative;
    margin: 12vh 0 0;
}

.left-single h5 {
    font-weight: bold;
    font-size: 23px;
    line-height: 32px;
    text-transform: capitalize;
    color: #020202;
}

.left-single p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #18191F;
}

.left-single:before {
    content: attr(data-name);
    position: absolute;
    left: 10px;
    top: -40px;
    font-weight: 800;
    font-size: 20px;
    line-height: 37px;
    text-transform: capitalize;
    color: rgba(65, 35, 152, 0.15);
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}

.middle-image {
    text-align: center;
}

.middle-image img {
    height: 74vh;
    width: auto;
    background: url('https://storage.googleapis.com/spyne-website/Facebook-template/temp-bg.png');
    background-size: contain;
}

.amazing-web {
    padding: 50px 0 25px;
}

.left-web {
    margin: 0;
}

.left-web img {
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0;
    transition: .3s ease-out;
}

.left-web img:hover {
    box-shadow: 0px 2px 12px 0px #9e9e9e6e;
}

.left-web h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    color: #020202;
}

.left-web p {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #18191F;
}

.templates {
    padding: 50px 0;
}

.expand-btn {
    text-align: center;
    transition: .5s ease-out;
}

.expand-btn button {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    background: #F2F3F5;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 15px 0 0;
    transition: .5s ease-in-out;
}

.single-dash-image {
    text-align: center;
}

.single-dash-image img {
    width: 85%;
    margin: 0 auto;
}

.dashboard-content {
    padding-left: 20px;
    margin-bottom: 10px;
}

.dashboard-content h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #18191F;
}

.dashboard-content p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #18191F;
}

.dashboard-image img {
    width: auto;
}

.dashboard-image {
    padding: 0 10px 0 0;
}

.power-your-marketplace {
    background: #f2f2f25e;
    margin-top: 30px;
}

.power-your-marketplace .heading {
    text-align: left;
    padding: 50px 20px;
    margin: 0;
}

.power-your-marketplace h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #18191F;
    text-align: left;
    margin-bottom: 10px;
}

.power-your-marketplace p {
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    color: #18191F!important;
    text-align: left;
}

.power-your-marketplace h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #18191F!important;
    margin-top: 20px;
}

.power-your-marketplace h5 {}

.power-your-marketplace h5 img {
    width: 145px;
    margin-right: 10px;
}

.marketplace-images img {
    width: 100%;
    height: 100%;
}

.fade-in {}

.left-web img {
    animation: fadeIn ease-in-out 5s;
    -webkit-animation: fadeIn ease-in-out 5s;
    -moz-animation: fadeIn ease-in-out 5s;
    -o-animation: fadeIn ease-in-out 5s;
    -ms-animation: fadeIn ease-in-out 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* @Responsive */

@media screen and (max-width:767px) and (min-width: 200px) {
    .banner-area {
        padding: 0;
        height: auto;
        background: url('https://storage.googleapis.com/spyne-website/Facebook-template/mob-bg.png');
        background-position: left center;
        background-size: 100%;
    }
    .banner-image {
        padding-top: 3vh;
    }
    .banner-text h1 {
        font-size: 25px;
        line-height: 30px;
        padding-top: 3vh;
        margin-bottom: 0;
    }
    .banner-image img {
        height: 56vh;
        width: auto;
        margin: 0 auto 20px;
    }
    .black-box {
        height: 70px;
    }
    .form-section {
        padding: 0 15px;
    }
    .form-area {
        padding: 30px 15px;
        margin-top: -31%;
        margin-bottom: 40px;
    }
    .form-area h3 {
        font-size: 22px;
        margin-bottom: 10px;
        padding: 0;
    }
    .form-area p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
        padding: 0;
    }
    .form-area input,
    .form-area input::-webkit-input-placeholder {
        font-size: 14px;
        line-height: 24px;
        height: auto;
        margin-bottom: 0;
    }
    .form-area input {
        color: #000 !important;
        margin: 0;
    }
    .form-area label {
        font-size: 16px;
    }
    .heading {
        margin-bottom: 25px;
        padding: 0 10px;
    }
    .heading h2 {
        font-size: 20px;
        margin-bottom: 5px;
        line-height: 152%;
    }
    .left-single {
        margin: 9vh 0 0;
    }
    .application-loaded {
        padding: 35px 0 0px;
    }
    .middle-image img {
        padding: 15px 0;
    }
    .amazing-web {
        padding: 35px 0 0px;
    }
    .left-web {
        margin: 0 0 15px;
    }
    .templates {
        padding: 20px 0 30px;
    }
    .heading p {
        font-size: 12px;
        line-height: 18px;
        margin: 5px 0 10px;
    }
    .left-web p {
        padding: 0 5px;
        font-size: 12px;
        line-height: 20px;
    }
    .left-web h5 {
        padding: 0 5px;
        font-size: 16px;
    }
    .left-web img {
        margin-bottom: 10px;
    }
    .left-single h5 {
        font-size: 18px;
        line-height: 20px;
        padding: 0;
    }
    .left-single p {
        font-size: 12px;
        line-height: 21px;
        padding: 0;
        margin-bottom: 10px;
    }
    .left-single:before {
        left: 0;
    }
    .ai-app .heading {
        margin-bottom: 10px;
    }
    .dashboard-content {
        padding-left: 10px;
    }
    .dashboard-image {
        padding: 0 10px 10px;
    }
    .single-dash-image img {
        width: 100%;
        margin: 0 auto 25px;
    }
    .power-your-marketplace {
        margin-top: 10px;
    }
    .power-your-marketplace .heading {
        padding: 35px 10px;
        margin: 0;
        order: 2;
    }
    .power-your-marketplace h5 {
        text-align: center;
    }
    .power-your-marketplace h5 img {
        width: 135px;
        margin: 0 5px;
    }
    .dashboard-image img {
        width: 100%;
    }
    .dashboard-content p {
        font-size: 12px;
        line-height: 20px;
    }
    .dashboard-content h6 {
        font-size: 16px;
        line-height: 20px;
    }
    .power-your-marketplace h2 {
        line-height: 27px;
        margin-bottom: 10px;
    }
    .fade-in {
        padding: 0 5px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .banner-area {
        margin-top: 6vh;
        height: auto;
        padding-bottom: 70px;
    }
    .banner-image img {
        height: 43vh;
    }
    .banner-text h1 {
        font-size: 39px;
        line-height: 60px;
        padding-top: 15vh;
    }
    .black-box {
        height: 100px;
    }
    .form-area {
        padding: 30px 35px;
        margin-top: -17%;
    }
    .form-area h3 {
        font-size: 30px;
        padding: 0;
    }
    .form-area p {
        padding: 0;
        font-size: 14px;
    }
    .heading h2 {
        font-size: 40px;
    }
    .middle-image img {
        height: 42vh;
    }
    .left-single {
        margin: 5vh 0 0;
    }
    .single-dash-image img {
        width: 100%;
        margin: 0 auto;
    }
    .dashboard-content {
        padding-left: 10px;
    }
    .dashboard-content p {
        font-size: 13px;
        line-height: 20px;
    }
    .power-your-marketplace h2 {
        font-size: 25px;
        line-height: initial;
    }
    .power-your-marketplace p {
        font-size: 14px;
        line-height: 22px;
    }
    .power-your-marketplace h5 img {
        width: 130px;
    }
    .power-your-marketplace .heading {
        padding: 25px 15px;
        margin: 0;
    }
    .power-your-marketplace h6 {
        font-size: 17px;
        line-height: 22px;
        margin-top: 15px;
    }
}

@media (min-width: 1366px) {
    .banner-area {
        padding: 0;
    }
    .form-area {
        margin-top: -15%;
    }
    .heading p {
        font-size: 20px;
        line-height: 32px;
        color: #959599;
    }
}

@media (min-width: 1440px) {
    .form-area input,
    .form-area input::-webkit-input-placeholder {
        height: 58px;
        font-size: 17px;
    }
    .banner-text h1 {
        font-size: 70px;
        line-height: 80px;
        padding-top: 20vh;
    }
    .form-area {
        margin-top: -14%;
    }
    .heading h2 {
        font-size: 55px;
        line-height: 100%;
        line-height: 127.13%;
    }
    .left-single h5 {
        font-size: 30px;
        line-height: 45px;
    }
    .left-single p {
        font-size: 18px;
        line-height: 30px;
    }
    .left-single:before {
        font-size: 30px;
        line-height: 37px;
        top: -50px;
    }
    .left-single {
        position: relative;
        margin: 10vh 0 0;
    }
    .left-web h5 {
        font-size: 27px;
        line-height: 33px;
    }
    .left-web p {
        font-size: 18px;
        line-height: 30px;
    }
    .left-web img {
        margin-bottom: 20px;
    }
    .dashboard-content p {
        font-size: 17px;
    }
    .dashboard-content h6 {
        font-size: 22px;
        line-height: 25px;
    }
    .power-your-marketplace h2 {
        font-size: 40px;
    }
    .power-your-marketplace h6 {
        font-size: 25px;
        line-height: 40px;
    }
    .power-your-marketplace h5 img {
        width: 160px;
    }
    .form-area p {
        font-size: 18px;
    }
}

@media (min-width: 1680px) {
    .heading p {
        font-size: 24px;
        line-height: 32px;
        color: #959599;
    }
    .banner-area {
        margin-top: 10vh;
        height: 90vh;
        padding: 0 0%;
    }
    .banner-text h1 {
        font-size: 75px;
        line-height: 100px;
        margin-bottom: 15px;
    }
    .banner-text button {
        font-size: 20px;
        line-height: 36px;
        min-width: 200px;
        margin-top: 15px;
    }
    .form-area h3 {
        font-size: 47px;
        margin-bottom: 15px;
    }
    .form-area {
        margin-top: -12%;
        padding: 6%;
        margin-bottom: 80px;
    }
    .form-area p {
        font-size: 22px;
        line-height: 35px;
    }
    .form-area label {
        font-size: 24px;
    }
    .form-area h4 {
        font-size: 25px;
    }
    input[type='radio']:after {
        width: 20px;
        height: 20px;
    }
    input[type='radio']:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #09980f;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #ddd;
    }
    .form-area .radio-select label {
        font-size: 16px;
        margin-left: 20px;
    }
    button {
        font-size: 18px;
        line-height: 30px;
    }
    .form-area form {
        padding: 10px 0;
    }
    .left-single h5 {
        font-size: 35px;
        line-height: 45px;
    }
    .left-single p {
        font-size: 21px;
        line-height: 30px;
    }
    .left-single:before {
        font-size: 30px;
        line-height: 37px;
        top: -50px;
    }
    .left-single {
        position: relative;
        margin: 10vh 0 0;
    }
    .left-web h5 {
        font-size: 30px;
    }
    .left-web p {
        font-size: 23px;
        line-height: 32px;
    }
    .left-web img {
        margin-bottom: 25px;
    }
}

@media (min-width: 1920px) {
    .banner-text h1 {
        font-size: 85px;
    }
}