.bulk-try {
    padding: 80px 0;
}

.change-tabs {
    padding: 10px 0 20px;
    display: flex;
    justify-content: left;
    list-style: none;
    margin-left: 10px;
}

.change-tab2 {
    padding: 10px 0 0 !important;
    border-bottom: 1px solid #ddd;
}

.change-tabs li {
    padding: 0;
}

.right-top-credits {
    text-align: left;
    padding: 0;
}

.toggle1 {
    position: relative;
    color: #f39e53;
}

.toggle1 input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.slider1 {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 190px;
    box-shadow: 0px 4px 4px rgb(255, 255, 255);
    border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
}

.slider1::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top: 3px
}

input:checked+.slider1 {
    background-color: #78f353;
}

input:checked+.slider1::before {
    transform: translateX(21.5px);
}

.right-top-credits button {
    padding: 11px 9px;
    background: linear-gradient( 180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient( 180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #ff7700;
    background-blend-mode: soft-light, normal, normal;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
    border-radius: 32px;
}

.right-top-credits img {
    width: 25px !important;
}

.add-credit {
    color: white !important;
}

.add-credit img {}

.change-tabs li button {
    background: transparent;
}

.change-tabs li button {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
    border-radius: 0;
    border-bottom: 2px solid transparent;
}

.change-tabs li button.active,
.change-tabs li button:hover,
.change-tabs li button.activate {
    color: #ff7700 !important;
    border-bottom: 2px solid #ff7700;
}

.tab-content {
    overflow: hidden;
    height: 550px;
    overflow-y: scroll;
    margin: 0;
    padding: 22px 0px;
}

.uploaded-cars {}

.uploaded-cars-single {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    margin: 0 0 20px;
    padding: 5px 0px;
}

.uploaded-cars-single img {
    width: 100%;
    padding: 0;
    height: 105px;
    object-fit: cover;
    cursor: pointer;
}

.uploaded-cars-single p {
    text-align: center;
    padding: 5px 5px;
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reload-cancel {
    text-align: right;
    justify-content: flex-end;
    padding: 0;
    height: 30px;
    align-items: center;
    padding: 0 10px;
}


/* .reload-cancel img {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0 0 5px 10px;
} */

.reload-cancel span img {}

.reload-cancel {
    opacity: 1;
}

.uploaded-cars-single:hover .reload-cancel {
    opacity: 1;
}

.reload-cancel2 {
    text-align: right;
    justify-content: flex-end;
    padding: 0;
    height: 20px;
    align-items: center;
}

.reload-cancel2 img {
    /* padding: 5px; */
    /* background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%; */
    height: 35px;
    width: 35px;
    margin: 0 0 5px 10px;
}

.reload-cancel2 span img {
    position: absolute;
    right: -2%;
    top: -10%;
    margin: 0;
    cursor: pointer;
}

.bulk-right-box {
    background: #ffffff;
    box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.15);
    height: 100%;
}

.credits-available {
    padding: 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    /* text-align: center; */
    letter-spacing: 0.5px;
    color: #232121;
    border-bottom: 1px solid #ebeaea;
    margin-left: 0px;
}

.credits-available span {
    color: #f70;
}

.credits-available img {
    margin-right: 10px;
    width: 35px;
}

.credits-available sub {
    background: #ff7700;
    border: 2px solid #ffffff;
    padding: 3px;
    color: #fff;
    font-weight: 500;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 8px;
    margin-left: -5%;
    bottom: -5px;
}

.bulk-accordion {
    padding: 12px;
    border-bottom: 1px solid #ebeaea;
}

.background-grid {
    padding: 0 5px;
}

.background-grid img {
    width: 100%;
    height: auto;
    padding: 0;
}

.background-grid h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.back-credit {}

.back-credit {
    font-weight: 500;
    font-size: 11px;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #232121;
    /* padding: 5px 0; */
}

.back-credit span {
    float: right;
    font-size: 10px;
}

.bulk-bg {
    margin: 0;
}

.bulk-bg img {
    margin-bottom: 10px;
}

.back-credit span img {
    width: 10px;
}

[role='button'] {
    cursor: pointer;
    outline: 0;
}

.logo-sec {
    border-bottom: 1px solid #ebeaea;
    padding: 12px;
}

.bulk-accordion h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    margin: 0 0 20px;
    letter-spacing: 0.5px;
    color: #232121;
}

.checkbox-section {
    padding: 20px 0;
}

.checkbox-section label {
    font-size: 14px;
    color: #232121;
    font-weight: 600;
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.container-2:hover input~.checkmark {
    background-color: #ccc;
}

.checkmark {
    position: absolute;
    top: 9%;
    left: 9%;
    height: 18px;
    width: 18px;
    border: 2px solid #b9b6b6;
}

.container-2 input:checked~.checkmark {
    background-color: #fff;
    border: 2px solid #f70;
}

.container-2 input:checked~.checkmark:after {
    display: block;
}

.container-2 .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #f70;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.apply-btn-section {
    text-align: center;
    padding: 20px;
}

.apply-btn,
.apply-btn-section button {
    background: #ff7700;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    color: #ffffff;
    width: 100%;
}

.add-more {
    position: absolute;
    right: 20px;
    font-size: 30px;
    z-index: 1;
    background: #ff7700;
    font-weight: 100;
    color: #fff;
    width: 60px;
    height: 60px;
    vertical-align: middle;
    text-align: center;
    padding: 8px;
    border-radius: 50%;
    top: 11%;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.add-more span {}

.background-grid h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    margin: 20px 0px;
    color: #ff7700;
}


/* Payment Success Page Css */

.payment-success {
    padding: 0;
    height: 100vh;
}

.payment-success img {
    width: 100%;
}

.payment-success h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.payment-success p {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0 0 25px;
    color: #5f5f5f;
}

.payment-success h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    margin: 0 0 15px;
    color: #ff7700;
}

.payment-success h6 span {
    color: #5f5f5f;
}

.payment-success-content {
    padding: 0 40px;
}

.proceed-btn {
    background: #ff7700;
    border-radius: 31px;
    margin-top: 26px;
    min-width: 180px;
}


/* Popups Css */

.popup-content {
    padding: 0px 0px 20px;
}

.popup-content h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #ff7700;
    margin: 0;
}

.popup-content img {
    width: 25%;
}

.popup-content p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 27px; */
    text-align: center;
    margin: 15px 0px;
    color: #232121;
}

.popup-content p span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #999696;
}

.popup-content h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-decoration-line: underline;
    color: #ff7700;
}

.popup-content h6 img {
    margin-left: 10px;
    width: auto;
    transition: 0.3s ease-in-out;
}

.popup-content h6:hover img {
    margin-left: 20px;
}

.proceed-btn2 {
    background: #fff;
    color: #ff7700;
    border-radius: 31px;
    margin-top: 26px;
    min-width: 180px;
    border: 1px solid #ff7700;
    box-sizing: border-box;
}

.proceed-btn2:hover {
    color: #ff7700;
}

.popup-content h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    /* color: #ff7700; */
}

.popup-content h3 span {
    color: #232121;
}

.bulk-bg button {
    border-radius: 5px;
    width: auto;
    margin: 10px auto;
}

.bulk-bg {
    text-align: center;
}

.light-item {
    padding: 0 !important;
}

.react_lightgallery_item {
    padding: 0 !important;
}

.popup-content h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #232121;
    margin: 0 0 25px;
}

.popup-content h4 span {
    border-bottom: 4px solid #ff7700;
    padding-bottom: 5px;
}

.popup-content ul {
    list-style: none;
    padding: 10px 0;
}

.popup-content ul li {
    display: inline-block;
    padding: 20px 5px;
}

.popup-content ul li span {
    border: 1px solid #999696;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 10px;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #999696;
}

.popup-content textarea {
    /* border: none; */
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #999696 !important;
    min-height: 100px !important;
}

.popup-text p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #5f5f5f;
}


/* 360 View Tab page csss  */

.sixty-link {}

.sixty-content {}

.sixty-link-copy {}

.sixty-link-copy h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #232121;
}

.sixty-link-copy h4 img {
    width: 30px;
    margin-right: 10px;
}

.sixty-link-copy p input {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;
    height: 45px;
    padding: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 70%;
    color: #292929;
}

.sixty-link-copy p button {
    border-radius: 0 5px 5px 0;
    height: 45px;
    width: 30%;
    float: right;
}

.sixty-link-copy textarea {
    width: 100%;
    padding: 15px 10px;
    color: #232121;
    border: 1px solid #ddd;
    background: #f0f0f0;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px;
    height: 105px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.sixty-satisfy p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.download-complete {}

.download-complete img {
    width: 50%;
}

.download-complete h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #232121;
}

.upload-image {
    text-align: center;
    padding: 20px 0;
}

.upload-image button img {
    width: 20px;
    margin-right: 10px;
}

.perfect-output {
    margin: 80px 0 50px;
    /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%);
    background-color: #fff; */
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.logo-sec {
    padding: 0px 13px 23px;
    border-bottom: 1px solid #ebeaea;
    margin: 22px 0;
}

.logo-sec span {
    color: orange;
}

.dealership-logo-section {
    width: 100%;
    /* border: 1px solid grey;
  margin: 1px auto 20px; */
    border-radius: 10px;
}

.important-image-aspects img {
    display: block;
    max-width: 100%;
}

.page {
    margin: 1em auto;
    max-width: 768px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
}

.box {
    padding: 0.5em;
    width: 100%;
    margin: 0.5em;
}

.box-2 {
    padding: 0.5em;
    width: calc(100% / 2 - 1em);
}

.options label,
.options input {
    width: 4em;
    padding: 0.5em 1em;
}

.btn {
    background: white;
    color: black;
    border: 1px solid black;
    padding: 0.5em 1em;
    text-decoration: none;
    margin: 0.8em 0.3em;
    display: inline-block;
    cursor: pointer;
}

.hide {
    display: none;
}

img {
    max-width: 100%;
}

.button-padding {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: 18%;
}

.enhancements {
    font-size: 13px;
    /* margin-left: 10px; */
}

.enhancements input {
    margin-left: 12px;
    margin-right: 4px;
    vertical-align: middle;
}

.enhancement-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
}

.enhancement-container {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ebeaea;
}

.brightness-container {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 10px 0px;
    margin-bottom: 10px !important;
    font-size: 13px;
    border-bottom: 1px solid #ebeaea;
}

.brightness-container input {
    vertical-align: middle;
}

.tint-opt {}

.tint-opt label {}

.tint-opt input {
    margin-right: 12px;
}

@media (min-width: 1366px) {
    .perfect-output {
        max-width: 93.5%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1440px) {
    .perfect-output {
        max-width: 89%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1680px) {
    .perfect-output {
        max-width: 76.3%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1920px) {
    .perfect-output {
        max-width: 66.3%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2560px) {
    .perfect-output {
        max-width: 50%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2880px) {
    .perfect-output {
        max-width: 44.2%;
        margin: 0 auto;
        margin-top: 100px !important;
        margin-bottom: 50px !important;
    }
}

.backgrounds-grid {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 9px;
}

.active-background-sec img {
    width: 100%;
    height: 75px;
    border-radius: 5px;
}

.bg-below-sec {
    justify-content: center;
    margin: 0;
    padding: 5px 60px 0;
}

.inactive-background-sec img {
    width: 100%;
    height: 75px;
    cursor: pointer;
    border-radius: 5px;
}

.back-credit {
    text-align: end;
    font-size: 12px;
    padding-top: 11px;
    margin-bottom: 0 !important;
}

.bcredit {
    display: none;
}

.back-name {
    font-size: 11px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
}

.back-credit img {
    width: auto !important;
    height: auto !important;
    padding-left: 4px;
    border: 0 !important;
}

.active-background-sec-below {
    padding: 5px 5px 0;
    cursor: pointer;
}

.active-background-sec-below img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.inactive-background-sec-below img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.overlay_edit {
    position: relative !important;
}

.overlay-img {
    /* object-fit: scale-down !important; */
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black !important;
    z-index: 91;
    object-fit: contain !important;
    height: 60% !important;
    margin-top: 10%;
}

.lower-section-with-overlay {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.continue-btn {
    display: flex;
    justify-content: center;
    padding: 2%;
}

.overlay-pos {
    position: relative;
}

.img-overlay {
    position: absolute;
    background: linear-gradient( 0deg, rgba(62, 60, 60, 0.7), rgba(62, 60, 60, 0.7));
    height: 100%;
    bottom: 0%;
    width: 100%;
    opacity: 1;
    transition: 0.5s ease;
}

.more-btn-overlay {
    position: absolute;
    top: 32%;
    left: 15%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.add-more button {
    padding: 0 !important;
}

.inactive-background-sec {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
}

.active-background-sec {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 3px;
    border: 2px solid #333;
    transform: scale(1);
    cursor: pointer;
}

.modal-header {
    background-color: rgba(255, 145, 0, 0.1);
    border-radius: 5px 5px 0px 0px;
}

.create-project {
    text-align: center;
}

.modal-header h5 {
    padding-left: 29px;
    color: #232121;
    font-weight: 500;
    padding-top: 10px;
}

.modal-header img {
    margin-right: 8px;
    width: 17%;
    margin-bottom: 3px;
}

.download-card-gif {}

.upload-car-name-gif img {
    width: 40%;
    max-height: 20px;
}

.apply-btn-section h6 {
    font-size: 18px;
    color: #2b2a2a;
    font-weight: 500;
}

.apply-btn-section p {
    font-size: 16px;
    color: #646464;
    font-weight: 400;
}

.apply-btn-section img {
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px #ddd;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    margin: 0 5px;
}

.btn-book {
    width: auto;
    height: 45px;
    margin: 9px;
    cursor: pointer;
    background-color: #fff !important;
    border: 1px solid #a1afb7 !important;
    color: #999696;
    font-size: 14px;
    border-radius: 100px;
}

.btn-book-selected {
    width: auto;
    height: 45px;
    margin: 9px;
    cursor: pointer;
    border: 1px solid #a1afb7 !important;
    font-size: 14px;
    border-radius: 100px;
    background-color: #a1afb7;
    color: #000;
}

.btn-book:hover {
    background-color: #a1afb7 !important;
    color: #000 !important;
}

.choose-button {}

.choose-button button {
    padding: 10px 20px;
    margin-top: 20px;
}

.container-fluid {
    max-width: 100% !important;
    margin: 0 15px !important;
}

.cross-popup {
    cursor: pointer;
}

.add-more:hover+.more-text {
    display: block !important;
}

.more-text {
    position: absolute;
    z-index: 999;
    left: 72%;
    top: 13.5%;
    display: none;
}

.controls {
    margin-top: 325px;
}

.cancel-crop {
    background-color: #fff !important;
    color: #ff7700;
    border: 1px solid #ff7700;
    border-radius: 10px;
    width: 108px;
    height: 40px;
    padding: 0 !important;
}

.cancel-crop:hover {
    color: #ff7700 !important;
}

.save-crop {
    background-color: #ff7700 !important;
    color: #fff;
    border: 1px solid #ff7700;
    border-radius: 10px;
    width: 108px;
    height: 40px;
    padding: 0 !important;
}

.crop-header p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.crop-header img {
    cursor: pointer;
    text-align: right;
}

.video-sec {
    margin-top: 30px;
    margin-bottom: 15px;
}