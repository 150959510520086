:root {
  --primaryColor: #ff7700;
}
button {
  border: unset;
  background-color: #ff7700;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px 30px;
}
.download-img img {
  width: 100%;
  margin-bottom: 15px;
}

.download-right h4 {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 15px;
}

.download-right p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  margin-bottom: 10px;
}

.download-right {
  padding-top: 0px;
}

.gif-sec img {
  width: 125px;
}

.download-right img {
  padding-right: 10px;
  cursor: pointer;
  width: auto !important;
}

.success-cmnt img {
  padding-right: 15px;
  width: 6%;
}

.success-cmnt p {
  font-size: 25px;
  margin-bottom: 0;
}

.close-btn {
  cursor: pointer;
}

.cnmt-sec h6 {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #ff7700;
}

.cnmt-sec p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  margin-bottom: 20px;
  color: #5f5f5f;
}

.cnmt-sec textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px !important;
  min-height: 150px;
}

.cnmt-sec button {
  background-color: #ff7700;
  color: #fff;
  width: 380px;
  height: 52px;
  font-size: 18px;
  margin: 25px 0;
  border-radius: 25px;
}

::placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #7c7c7c;
}

@media (max-width: 768px) {
  .download-right {
    padding-top: 0px;
  }
  .download-right h4 {
    font-size: 20px;
  }
  .success-cmnt img {
    padding-right: 11px;
    width: 11%;
  }
  .success-cmnt p {
    font-size: 18px;
  }

  .cnmt-sec button {
    background-color: #ff7700;
    color: #fff;
    width: 245px;
    height: 42px;
    font-size: 17px;
    margin: 25px 0;
    border-radius: 25px;
    padding: 0;
  }

  .cnmt-sec h6 {
    font-size: 20px;
  }

  .cnmt-sec p {
    font-size: 12px;
  }
  .cnmt-sec textarea {
    margin-top: 0px !important;
  }
}
