.upload-btn{
    border: unset;
    background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-weight: 500;
font-size: 14px;
line-height: 24px; 
color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}
.upload-btn img{ 
        width: 23px;
        margin-right: 10px; 
}

.upload-btn span{

}
.download-section-inner{
  margin-bottom: 25px;
}
.upload-sec{
    /* padding-bottom: 20px; */
}
 .upload-top-btn{
    width: max-content;
    text-align: center;
    margin: 0 auto;
}
.screen-2-drop{
    width: fit-content;
    text-align: center;
}
.solid-background{
  padding: 25px 0px 25px 25px;
  display: grid;
  grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  grid-gap: 9px;
}
.solid- span{
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0!important;
  line-height: normal;
}
.solid-background img{
  width: 100%;
  /* padding: 5px; */
  height: 65px;
}
.right-top-selection{
  /* display: grid; */
  /* grid-template-columns: 50% 50%; */
  /* grid-gap: 0px; */
  margin: 0px auto 7px;
  justify-content: center;
  text-align: center;
  /* border-top: 2px solid #e0e0e0; */
 
}
.angle-sec {
  /* background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  width: 90%;
  margin-left: 5%; */
  background-color: #fbfbfb;
    border-bottom: 1px solid #ddd;
}
.top-bar {
  padding: 10px 0px;
  padding: 15px 0px 10px;
}
.credit-text {
  font-size: 14px;
  font-family: poppins;
  color: #232121;
  text-align: center;
  /* display: flex;
  justify-content:flex-end; */
}
.avail-credit{
  text-align: left!important;
}
.right-top-credits{
  text-align: right;
  padding: 0;
  margin-bottom: 0px;
  
}
.credit-pos{
 
}
.right-top-credits button{
  padding: 5px 10px;
  width: auto;
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #FF7700;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
  border-radius: 32px;
}
.right-top-credits img{
  width:25px !important;
}
.add-credit{
  color: white !important;
}
.overlay-pos {
  position: absolute;
  width: 100%;
}
.more-btn-overlay-2 {
  position: absolute;
  top: 25%;
    left: 20%;
    color: black;
    font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.more-btn-overlay-2 span{
  font-size: 17px;
  display: block;
}
.credit-left-area{
  width:64%
}
.upload-area{
    text-align: center;
    justify-content: center;
}
.bg-main{
    /* background: #FBFBFB;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);  */
padding: 35px 0;
margin-top: 70px;
}
.inactive-background-sec-below img {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 0;
}

.inactive-background-sec{
  padding: 5px;
  box-shadow: 0 2px 5px 0 #ddd;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  width: auto;
}
.inactive-background-sec span{
  font-size: 13px;
}
.inactive-background-sec img {
  width: 100%;
  height: 46px;
  cursor: pointer;
  border-radius: 5px;
  /* border: 0.4px solid #333; */
}
.img-overlay {
  position: absolute;
  /* background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  ); */
  height: 100%;
  bottom: 0%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
}
.marketplaces{
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-row-gap: 9px;
  margin-left: 20px;
  padding: 25px 0;
}
.marketplaces img{
  width: 100%;
  height: 80px;
  object-fit: contain;
  padding: 10px;
}
.more-box{
  box-shadow: 0 0 0!important;
  padding: 5px;
}
.more-box img{
  border: 0!important;
  margin-top: 0;
  height: 100%!important;
  padding: 10px;
}
.active-visual-selection{ 
  color: #FF7700;
  background: #fff;
  padding: 10px;
}
.inactive-visual-selection{
  /* border-bottom: 2px solid #FF7700; */
  /* color: #000; */
  /* padding: 10px; */
  /* background: #F5F5F5; */
  /* border: 1px solid #E0E0E0; */
  /* box-sizing: border-box; */
  /* border-radius: 0px 0px 5px 5px; */
}
.custom-background-list{
  padding: 20px 10px 20px 35px ;
}
.custom-background-list:before, .adjust-height-area:before{
  display: inline-block ;
  content: '' ; 
  position: absolute ;
  top: 0 ;
  left: 15px ;
  width: 10px ;
  height: 100% ;
  /* border-left: 1px dashed #ff7700 ; */
}
 
.custom-wall{
  padding-top: 10px;
}
.side-margin{
  border-left: 1px dashed #ff7700;
    margin: 0 16px;
}
.custom-wall p, .adjust-height p{
  margin: 0 ;
}
.adjust-height p{
  padding: 5px 0 ;
  font-size: 16px ;
}
.custom-wall p span{
  background: #ff7700 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 13px ;
  margin-right: 10px ;
}
.adjust-height p span{
  background: #E5E5E5 ;
  color: #fff ;
  border-radius: 50% ;
  padding: 5px 12px ;
  margin-right: 10px ;
}



.custom-walls-area{
  padding-left: 10px ;
}
.adjust-height-area{
  padding: 5px 0px ;
  position: relative ;
}
.adjust-height-area ul{
  padding: 5px 10% ;
  list-style: none ;
  text-align: center ;
  display: flex ;
}
.adjust-height-area ul li{
  text-align: center ;
  width: 35% ;
  padding: 10px ;
  
  margin: 0 auto ;
}
.adjust-height-area ul li button{
  background: rgba(235 215 198 / 0%);
  border: 1px solid #000000;
  box-sizing: border-box ;
  padding: 10px ;
  width: 100% ;    
  height: auto ;
  border-radius: 0 ;
}
.adjust-height-area ul li button.active, .adjust-height-area ul li button:hover{
  background: rgb(235 215 198 / 0%);
  border: 1px solid #FF7700; 
}
.adjust-height-area ul li h6{ 
  font-weight: normal ;
  font-size: 14px ; 
  color: #000000 ;
  margin: 0 ;
}
.adjust-height-area ul li img{
  width: 100% ;
}

.backgrounds-grid-custom .inactive-background-sec img{
  height: 60px ;
  border: 0 ;
}
.active-background-sec:hover .white-icon, .inactive-background-sec:hover .white-icon{
  display: none;
}
.active-background-sec:hover .hoverable-icon, .inactive-background-sec:hover .hoverable-icon{
  display: inline-block;
  }
.backgrounds-grid .inactive-background-sec {
  position: relative;
}
.inactive-background-sec img {
  height: 47px;
}

.backgrounds-grid {
  display: grid ;
  grid-template-columns: 30.8% 30.8% 30.8%;
  grid-gap: 9px ;
}
.backgrounds-grid-custom {
  display: grid ;
  grid-template-columns: 30.8% 30.8% 30.8%  ;
  grid-gap: 7px ;
  margin-top: 10px;
}
.backgrounds-grid-below {
  display: grid ;
  /* justify-content: center ; */
  grid-template-columns: 50% 50% ;
  grid-gap: 9px ;
}
.below-button {
  background: unset !;
  /* color; */
  border: 2px solid #ff7700  ;
  height: fit-content !;
  margin-top: 2% !;
  border-radius: 30px  ;
}
.active-background-sec span{
  font-size: 13px ;
}
.inactive-walls-sec {
  padding: 5px ;
  cursor: pointer ;
  transform: scale(1) ;
  cursor: pointer ;
  /* height: 85px ; */
  width: auto ;
}
.inactive-walls-sec span{
  font-size: 13px ;
}
.active-walls-sec {
  /* box-shadow: 0 2px 5px 0 #ddd ; */
  padding: 3px ;
  border: 2px solid #ff7700 ;
  transform: scale(1) ;
  cursor: pointer ;
  /* height: 110px ; */
  width: auto ;
}
.active-walls-sec span{
  font-size: 13px ;
}
.active-walls-sec img {
  width: 100% ;
  /* height: 60px ; */
}
.inactive-walls-sec img {
  width: 100% ;
  /* height: 60px ; */
  border: 0.4px solid #333 ;
}
.inactive-walls-sec {
  padding: 3px ;
  border: 2px solid transparent ;
  box-shadow: 0 2px 5px 0 #ddd ;
}



.custom {
  font-size: 13px !important;
  font-weight: 500 ;
  /* margin-left: 14% ; */
}
.resizable {
  width: 120px ;
  /* border-radius: 0.75rem ;
  padding: 20px ;
  margin: 1rem ;
  background-color: #29e ;
  color: white ;
  font-size: 20px ;
  font-family: sans-serif ; */
  overflow: hidden ;

  touch-action: none ;

  /* This makes things *much* easier */
  object-fit: fill ;
  box-sizing: border-box ;
  position: absolute ;
  opacity: 0.5 ;
  border-bottom: 10px solid #FF7700 ;
  z-index: 2 ;
}
.drag-button{
  width: 50px !;
  height: auto ;
  transform: rotate(-90deg) ;
  /* overflow: hidden ; */
  margin-top: -27px ;
}
.wall-cross{
  position: absolute !important;
  right: -7%;
  top: -9%;
  margin: 0;
  cursor: pointer;
  height: 22px !important;
  width : 20% !important;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 0!important;
}
.car-text h1 {
  font-size: 30px;
}
.original-image-div {
  width: 100%;
  height: 100%;
}
.remove-bg-box{
  background: #FFFFFF;
  margin: 0;
/* border: 2px solid #EAEAEA;
box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 5px;
width:90%;
margin-left: 5%; */
}
.remove-bg-area{
    padding: 0;
    background: #fbfbfb;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    border-radius: 5px;

}
.credit-text img {
    margin-right: 10px;
    width: 40px;
}
.credit-text span {
    color: #f70;
}
.cross-btn {
    text-align: right;
    padding: 0 0 10px;
    float: right;
    width:max-content;
  }
  .cross-img {
    width: auto !important;
    cursor: pointer;
    margin-left: 50%;
  }
  .credit-text{
    
  }

  .download-popup p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 15px;
  }
  .download-popup button.activate,
  .download-popup button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .download-popup {
    box-shadow: 0px 4px 20px #00000038;
    
  }
  .try-drag-drop .dropzone1 {
    border: 1px dashed #ffa64d;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    opacity: 0.7;
  }
  .try-drag-drop .dropzone1:hover {
    opacity: 1;
  }
  .try-drag-drop .dropzone1 img {
    margin-bottom: -30px;
    width: 15%;
  }
  
  .drop-box-text {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(62, 60, 60, 0.7);
    top: 0;
  }
  
  .drop-box-text div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
  }
  .download-popup button {
    border: 1px solid transparent;
    margin-bottom: 25px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 75px;
    text-align: left;
    width: 85%;;
  }
  .cross-btn {
    text-align: right;
    padding: 0 0 10px;
  }
  .download-popup button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .download-popup ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .download-popup ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .download-popup p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .download-popup p span {
    color: #ff7700;
  }
  .download-popup h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .border-1 {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
    margin-top: 20px;
  }
  .download-popup input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .download-popup input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .download-btn-hd {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .containers {
    max-width: 600px !important;
    border-radius: 10px;
}
  .containers img {
    width: auto;
    height: 400px;
    max-width: 100%;
    object-fit: contain;
    margin-top: 100px;
    /* margin: 50px auto 0; */
    margin-top: 20px;
    position: relative !important
}
.left-sec-upload-image {
    width: auto;
    height: 415px;
}
.rating-area{

}
.rating-area p{
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FF7700;
}
.rating-area p span svg{
    color: #565656;
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
}

.sec-2-right-card{ 
    width:41%;
    padding: 0 0  24.6px;
    background: #fff;
    border-radius: 0;  
  
    border-left: 1px solid #ebeaea
   
}
.sec-2-left{
  width:58%
}
.get-mrktplace{
    line-height: 50px!important;
    text-align: center;
    letter-spacing: 0.5px;
    color: #333333!important;
    padding: 0!important;
}
.get-mrktplace a{
    margin-left: 10px;
}

.css-1eg0sfo-skeletonStyles-Skeleton {
    background-color: #ff7700;
    background-image: linear-gradient( 
90deg
,#ff7700,#f5f5f5,#ff7700 );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    -webkit-animation: animation-bzdot9 1.2s ease-in-out infinite;
    animation: animation-bzdot9 1.2s ease-in-out infinite;
}
.categories{

}
.categories h2{
   width: 100%;
    text-align:center; 
    /* border-bottom: 1px solid #EAEAEA; */
    line-height: 1em; 
    margin: 10px 0 20px;
     padding:0 1em;
     position: relative;
}
.categories h2 span{
    font-weight: 600;
font-size: 24px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;

color: #232121;
background:#fff;
 padding:0 10px;
}
.categories h2:before {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .categories h2:after {
    content: "";
    display: block;
    width: 36%;
    height: 1px;
    background: #EAEAEA;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .product-hunt-tag{
    width: auto;
    position: absolute;
    right: 115px;
    top: 0px;
}

.last-enhancement{
  margin-left: 40px;
  padding: 20px 0;
}
.last-enhancement h6{
  font-style: normal;
font-weight: normal;
font-size: 11.3151px;
line-height: 17px; 
color: #FF7700;
}
.skin-retouch{

}
.skin-retouch snap{
  font-weight: 500;
font-size: 14.5882px;
line-height: 35px; 
color: #5F5F5F;
}
.second-side{
  border: 0!important;
}

.btn-sec button {
  width: 120px;
  height: 44px;
  border-radius: 5px;
}
.bg-fashion{
  padding: 5px;
}

.info-box{
  background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 6px;
    opacity: 0;
    padding: 10px;
    width: 100px;
    min-height: 50px; 
    z-index: 9;
    position: absolute;
    left: 10px;
    top: 0;
}
.info:hover .info-box{
  opacity: 1;
  /* width: 2px; */
}
.info-box p{
  padding: 0;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px; 
  color: #FF7700;
}



.toggle1 {
  position: relative;
  color: #f39e53;
  padding: 5px 0px 5px 5px;
}

.toggle1 input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


.slider1 {
  position: relative;
  display: block;
  cursor: pointer;
  background: #d64d4d;
  margin-left: 190px;

  
box-shadow: 0px 4px 4px rgb(255, 255, 255);
border-radius: 20px;
  transition: 0.2;
  width: 50px;
  height: 28px;
}

.slider1::before {
  content: "";
  position: absolute;
  height: 22px;
  width: 22px;
  background: rgb(255, 255, 255);
  transition: 0.2s;
  border-radius: 50%;
  left: 3px;
  top:3px

}

input:checked + .slider1 {
  background-color: #78f353;
}

input:checked + .slider1::before {
  transform: translateX(21.5px);
}



























/* @Responsive */





  @media screen and (max-width: 1290px) and (min-width: 1025px){
.cross-btn {
    padding: 0 0 20px;
}


}

.main-box{
  margin: 0px 0 50px;
  background: #fff;
}

@media (min-width: 1366px) {
  .main-box {
    max-width: 93.5%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1440px) {
  .main-box {
    max-width: 89%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
  .product-hunt-tag {
    right: 20%;
  }
}

@media (min-width: 1680px) {
  .main-box {
    max-width: 76.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 1920px) {
  .main-box {
    max-width: 66.3%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2560px) {
  .main-box {
    max-width: 50%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}

@media (min-width: 2880px) {
  .main-box {
    max-width: 44.2%;
    margin: 0 auto;
    /* margin-top: 70px !important; */
    margin-bottom: 50px !important;
  }
}
