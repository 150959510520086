.Affiliate-hero{
    padding: 120px 0 60px;
    font-family: 'Poppins';
}
.hero-content{

}
.hero-content h1 { 
    font-weight: 800;
    font-size: 43px;
    line-height: 60px;
    color: #18191F;
    margin-bottom: 20px;
}
.hero-content h1 span{
    color: #f70;
    display: block;
}
.hero-content p{
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #18191F;
    margin-bottom: 20px;
}
.hero-img{

}
.hero-img img{
    width: 100%;
}

.products{
    padding: 60px 0 ;
    margin: 50px 0;
    box-shadow: 0px 0px 90px 0px #e5e5e5;
}
.heading{
text-align: center;
margin-bottom: 40px;
}
.heading h2{
    font-weight: 800;
    font-size: 38px;
    line-height: 53px; 
    color: #18191F;
}
.heading p{
    font-size: 18px;
    line-height: 25px; 
    color: #18191F;
    margin: 0;
}
.become-a-partner{
    transition: .2s ease-in-out; 
    padding: 10px 20px;
    box-shadow: 2px 7px 12px 0px #ddd;
    transition: .2s ease-in-out;
}
.become-a-partner span{
    margin-right: 5px;
}
.become-a-partner:hover span{
    margin-left: 5px;
    margin-right: 0;
}
.become-a-partner:hover{  
    box-shadow: 2px 7px 12px 0px #ff77008c;
}
.product-image{

}
.product-image img{
width: 100%;
}
.product-content{

}
.product-content h3{
    font-weight: 800;
    font-size: 33px;
    line-height: 45px;
    color: #18191F;
    margin-bottom: 15px;
}
.product-content p{
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #4F5665;
}
.product-content ul{
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
}
.product-content ul li{
    font-weight: normal;
    font-size: 17px;
    line-height: 33px;
    color: #4F5665;
}
.product-content ul li img{
    width: 15px;
    vertical-align: middle;
    margin: 0 5px;
}
.product-content ul li span{

}
.tab-names{
    border-bottom: 1px solid #D1CECE;
}
.tab-names{
    margin-bottom: 30px;
}
.benefits-partner{
    padding: 60px 0;
}
.benefits-single{
    background: rgba(255, 193, 138, 0.15);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    padding: 0px;
    border: 2px dashed transparent;
    transition: .4s ease-in-out;
    height: 100%;
}
.benefits-single h4{
    font-weight: bold;
font-size: 16px;
line-height: 25px; 
text-align: center; 
color: #18191F;
padding: 10px 10px 0;
}
.benefits-single p{
    font-weight: normal;
font-size: 14px;
line-height: 25px; 
text-align: center; 
color: #18191F;
padding: 0 9px;
}
.benefits-single img{
    background: #fff;
    width: 100%;
    padding: 0;
    object-fit: contain;
    height: 100px;
    padding: 15px;
    border-radius: 20px 20px 0px 0px;
    transition: .4s ease-in-out;
}
.benefits-single:hover{
    border: 2px dashed #ddd;
    border-radius: 19px 0;
}
.benefits-single:hover img{
    background: #fff6ee;
}
.image{
text-align: center;
}
.image img{
    width: 100%; 
    object-fit: contain;
}
.who-can-be{
    padding: 0 0 60px;
}
.qa-question{
    background: #FFFFFF;
    border: 1px solid #FD816C;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 20px;
}
.qa-question h6{
    font-weight: 600;
    font-size: 16px;
    line-height: 25px; 
    color: #18191F;
    margin: 0;
}
.qa-description{
    padding: 10px; 
    background: #FFF8F3;
    box-shadow: 0px 4px 22px #fff8f3;
    border-radius: 10px;
    position: relative;
}
.qa-description p{
margin: 0;
font-weight: normal;
font-size: 15px;
line-height: 25px; 
color: #18191F;
}
.affiliate-item{
    margin-bottom: 20px;
}

 
.qa-question[aria-expanded='true']::after, .qa-question[aria-selected='true']::after {
    transform: rotate(45deg);
  }
  .qa-question:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-left: 12px;
    border-bottom: 2px solid #ff7700;
    border-right: 2px solid #ff7700;
    transform: rotate(-45deg); 
    float: right;
    margin: -18px 0 0 15px;
    background: #fff;
  }
  .enjoy-lifetime .heading{
      text-align: left;
      margin: 0!important;
      padding-top: 25px;
  }
.enjoy-lifetime .heading h2 span{
    color: #ff7700;
    /* display: block; */
}
.enjoy-row{
    box-shadow: 0px 0px 90px 0px #f1f1f2;
    background: #fff;
    border-radius: 10px;
    padding: 20px 10px 0;
}
.page-break{
padding: 30px 0;
}
.page-break .heading{
margin-bottom: 0;
}
.page-break .heading h5{
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;  
    color: #000000;
    margin: 0;
    
}
.page-break .heading h5 span {
   font-weight: 400;
   font-size: 28px;
   display: block;
}
.page-break .heading h5 span a{
    color: #ff7700;
    text-decoration: none;
}
.enjoy-image{

}
.enjoy-image img{
    width: 100%;
}
.enjoy-lifetime button{
    box-shadow: 0px 0px 40px 0px #ff77008c;
    margin-top: 15%!important;
}
.dollar-img{
    width: auto!important;
    position: absolute;
    top: 10%;
    -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
    background: #FFF3EC;
    border-radius: 50%;
}

@-webkit-keyframes float {
    0% {
   
      transform: translatex(0px);
    }
    50% {
   
      transform: translatex(-20px);
    }
    100% {
   
      transform: translatex(0px);
    }
  }
  
  @keyframes float {
    0% {
  
      transform: translatex(0px);
    }
    50% {
   
      transform: translatex(-20px);
    }
    100% {
   
      transform: translatex(0px);
    }
  }
   




/* @Responsive Media  */
@media screen and (max-width: 767px) and (min-width: 200px){
    .hero-content h1 {
        font-size: 20px;
        line-height: 35px;
    }
    .Affiliate-hero {
        padding: 90px 0 45px;
    }
    .hero-content p {
        font-size: 12px;
        line-height: 18px;
    }
    .hero-img img {
        width: 100%;
        margin: 30px 0 0;
    }
    .products{
        padding: 40px 0;
        margin: 30px 0;
    }
    .heading  h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .heading p {
        font-size: 12px;
        line-height: 24px;
    }
    .tab-names{
        margin: 40px 0 0;
    padding: 0 5px;
}
    .product-image {
        padding: 0;
        margin-bottom: 15px;
    }
    .product-content{
        padding: 0;
    }
    .product-content h3 {
        font-size: 13px;
        line-height: 20px;
    }
    .product-content p {
        font-size: 10px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .product-content ul li img {
        width: 10px;
        margin: 0 5px 0 0;
    }
    .product-content ul li {
        font-size: 8px;
    line-height: 16px;
    }
.product-content ul{
    margin-bottom: 10px;
}
    .tab-names{
        border: 0;
        border-right: 1px solid #D1CECE;
    }
    .benefits-partner {
        padding: 25px 0;
    }
    .benefit-cols{
        margin-bottom: 30px!important;
    }
    .benefits-single:last-child {
    }

    .who-can-be {
        padding: 20px 0 40px;
    }
    .image img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
.mob-esponsive{
    margin: 0!important;
}
.qa-question h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}
.qa-question{
    padding: 10px;
}
.qa-description p {
    font-size: 10px;
    line-height: 18px;
}
.page-break .heading h5 {
    font-size: 18px;
    line-height: 25px;
}
.page-break .heading h5 span {
    font-size: 20px;
    line-height: 30px;
}
.Abenefits-single p {
    font-size: 12px;
    line-height: 20px;
}
.become-a-partner  { 
    padding: 5px 10px; 
    font-size: 12px;

}
.enjoy-lifetime button { 
    margin-top: 3%!important;
}
.qa-question:after{
    display: none;
}
.enjoy-lifetime{
    padding: 0 10px;
}
.dollar-img {
    width: 40px!important;
}
.enjoy-lifetime .heading{ 
    padding-top: 0px;
}
.hero-content h1 span { 
    display: initial;
}
.heading{
    margin-bottom: 25px;
}
.tab-names li{
    margin-bottom: 20px!important;
}
}

@media screen and (max-width: 900px) and (min-width: 768px){
    .hero-content h1 { 
        font-size: 30px;
        line-height: 40px;
    }
    .hero-content p { 
        font-size: 14px;
        line-height: 25px;
    }
    .product-content h3 {
        font-size: 28px;
        line-height: 38px;
    }
    .product-content ul li {
        font-size: 14px;
    }
    .benefits-single h4 {
        font-size: 14px;
    }
    .benefits-single p {
        font-size: 12px;
    }
    .heading h2 {
        font-size: 34px;
    }
    .enjoy-lifetime button {
        margin-top: 3%!important;
    
}
.enjoy-image {
    padding-top: 5%;
}
}









