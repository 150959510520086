.firstStep {
  /* background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/car-landing-page-v3/ezgif%201.png'); */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: contain;
}
.firstStep img {
  width: 69%;
}
.drop-menu{
  padding: 0 10px !important;
  /* color: #5b646d !important; */
  font-weight: 500 !important;
  margin: 0px 0px 20px;

}
.drop-menu button{
  color: #5b646d !important;
  padding: 0px 10px;
}
.drop-menu button:hover{
  color: #5b646d !important;
}
.drop-list{
  width: 79.5% !important;

}
.distributor {
  width: 81.5% !important;
  background: white !important;
  /* border-bottom: 0px solid #928b8b; */
  /* color: #5b646d !important; */
  border: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #ced4da !important;
  /* color:#5b646d !important; */
  text-align: left;
}

.signup-button {
  /* position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%); */
  background-color: white !important;
  color: #ff7700 !important;
  width: 150px;
  border-radius: 45px;
  font-size: 16px !important;
  margin-bottom: 12px;
}

.circle-area-inner a {
  border-bottom: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.login-check-modal img {
  width: 60px;
  height: auto;
}

.got-btn {
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 140px;
  height: 40px;
  margin: 20px 0;
}

.cars-img {
  width: 100%;
}

.img-sec {
  height: 355px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 62px;
  text-align: center;
  width: 85%;
}

.close-btn {
  width: 15px !important;
  cursor: pointer;
}

.cars-circle {
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 50%;
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cars-circle-active {
  border: 1px solid #ff7700 !important;
  background: #f1d7bf !important;
  border-radius: 50%;
  margin-top: 40px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cars-circle img {
  margin-top: -13px;
  margin-left: 14px;
}

.cars-circle-active img {
  margin-top: -13px;
  margin-left: 14px;
}

.login-here {
  cursor: pointer;
  border-bottom: 1px solid #ff0000;
}

.forgot-password {
  font-size: 15px;
  color: #ff7700;
  /* border-bottom: 1px solid #ff7700; */
  cursor: pointer;
}

.login-check-modal input {
  width: 80%;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  margin: 25px 0 20px;
}
.login-check-modal p {
  padding-top: 1%;
  font-size: 25px;
  margin-bottom: 0%;
}
.login-check-modal form p {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #2d2b29;
  font-weight: 400;
  padding: 0;
}
.login-check-modal form p a {
  color: #ff7700;
}
.login-check-modal button {
  border: unset;
  background-color: #ff7700;
  border-radius: 50px;
  color: #ffffff;
  padding: 0;
  width: 50%;
  height: 45px;
  margin: 25px 0 25px;
}

.or {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.2px;
  color: #787878;
  border-top: 1px solid #ddd;
  margin: 0px 20% 0px;
}
.or span {
  background: #fff;
  position: relative;
  top: -12px;
  padding: 10px;
}

.img-sec img {
  object-fit: contain;
  width: 82%;
  text-align: center;
  padding-bottom: 40px;
}

.car-text h1 {
  font-size: 30px;
}
.circle-area {
  padding: 25px;
  background: #f79c4c;
  width: 62%;
  height: 100%;
  border-radius: 25%;
  position: absolute;
  margin: 0 auto 0;
  margin-right: -15%;
  top: 0;
  right: 0;
}
.circle-area-inner {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 25%;
  height: 100%;
  width: 100%;
  text-align: center;
}
.circle-area-inner h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 139.5%;
  text-align: center;

  color: #ffffff;
}
.circle-area-inner p {
  font-weight: normal;
  font-size: 17px;
  line-height: 139.5%;
  text-align: center;
  color: #ffffff;
}

.circle-area-inner span {
  font-weight: 600;
}
.overlays-models-text {
}
.overlays-models-text p {
  padding: 10px 0 0;
  margin: 0;
}

.login-check-modal h6 {
  text-align: left;
  padding-left: 87px;
  color: red;
  font-size: 12px;
}
.product-hunt img{
  width: 250px;  
  margin: 0 0 0 10px;
}
.product-hunt-tag{
  padding: 0;
  margin-top: 5px;
}
.product-hunt-tag a{
border: 0!important;
}




@media (max-width: 768px) {
  .hide-in-mobile {
    display: none !important;
  }
  .car-text h1 {
    font-size: 19px;
  }

  .firstStep {
    height: 370px;
    width: 100%;
  }

  .signup-button {
    width: 100px;
    height: 30px;
    padding: 0;
    font-size: 11px !important;
  }

  .login-check-modal img {
    width: 40px;
    height: auto;
  }

  .login-check-modal p {
    padding-top: 10px;
    font-size: 18px;
  }
  .login-check-modal input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ced4da;
    margin-top: 35px;
  }

  .login-check-modal button {
    border: unset;
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 230px;
    height: 40px;
    margin: 35px 0 20px;
  }

  .cars-circle {
    width: 55px;
    height: 55px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
  }

  .cars-circle-active {
    border: 1px solid #ff7700 !important;
    background: #f1d7bf !important;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 55px;
  }

  .cars-circle img {
    margin-top: 0px;
    margin-left: 0px;
  }

  .cars-circle-active img {
    margin-top: 0px;
    margin-left: 0px;
  }
  .img-sec {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 26px;
    text-align: center;
    width: 98%;
    height: 275px;
  }
  .img-sec img {
    width: 57%;
  }

  .firstStep img {
    display: block;
  }
  .circle-area {
    padding: 10px;
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-right: 0;
    position: relative;
  }
  .circle-area-inner {
    border-radius: 0;
    padding: 10px;
  }
  .circle-area-inner h3 {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .circle-area-inner p {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .firstStep {
    height: 450px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 901px) {
  .circle-area {
    margin-right: -15%;
  }
}
