.footwear-slider{

}
.footwear-slide-content{

}
.footwear-slide-title{

    text-align: center;
}
.footwear-slide-title h4{
    font-weight: 600;
font-size: 20px;
line-height: 190.5%; 
letter-spacing: 0.03em;

color: #232121;
}

.footwear-slide{
    margin-bottom: 15px;
}
.footwear-single{

}
.footwear-single p{
    font-weight: normal;
font-size: 14px;
line-height: 190.5%;
margin: 0;
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.footwear-single img{
    width: 100%;
}
.footwear-single h6{
    font-weight: 600;
font-size: 16px;
line-height: 190.5%;
/* or 34px */

text-align: center;
letter-spacing: 0.03em;

color: #232121;
}
.footwear-slide-title button{
    background: #FF7700;
border-radius: 26px;
margin-bottom: 20px;
}
.footwear-slide-title p{
    font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 190.5%; 
text-align: center;
letter-spacing: 0.03em;

color: #5F5F5F;
}
.bulk-images-area{
  padding: 15px 10px 10px;
}
.bulk-image{
  background: #FFF0ED; 
  border-radius: 5px;
  padding: 11px 15px 15px;
}
.bulk-image p{
  font-weight: normal;
font-size: 14px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px;
margin: 0 0 8px;
color: #444444;
}
.bulk-image img{
  border-radius: 5px;
  padding: 0;
  width: 100%;
}
.cross-icon{
  background: #FFFFFF;
  position: absolute;
  width: 25px!important;
  z-index: 1;
  border-radius: 50%!important;
  padding: 5px!important;
  height: 25px;
  right: 5px;
  font-weight: 300;
  top: -5%;
  box-shadow: 0px 2px 5px 0px #ddd;
}

.proceed-btn button{
  background: #fff!important;
  color: #FF7700!important;
  border: 1.5px solid #FF7700;
  border-radius: 5px;
}

.bulk-images-slider .bulk-image{
  position: relative;   
   border: 2px solid #E6A56C;
}
.bulk-images-slider{
  width: 80%;
  margin: 0 auto 0 20%;
}
.bulk-images-slider .cross-icon{
  right: -5%;
  top: -10%;
}
