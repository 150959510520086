body{
    font-family: 'Poppins';
}
.upload-section{
    border: 1px dashed #696969;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 0px;
    margin: 0;
    text-align: center;
}
.para-1, .para-2{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000000;
    margin: 0;
    padding: 0;
}
.category-buttons{
    display: flex;
    justify-content: space-around;
}
.inactive-button, .inactive-button:hover{
    width: 112px;
    height: 29px;
    border: 1px solid #707070;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0;
    background-color: white !important;
    color: #000000 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}
.active-button, .active-button:hover,.active-button:focus{
    width: 112px;
    height: 29px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0;
    background-color: white !important;
    color: #000000 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border: 1px solid #ff7700 !important;
}
.marketplaces{
    display: flex;
    justify-content: space-around;
}

.marketplaces img{
    width: 100%;
    width: 60px;
    height: auto;
    cursor: pointer;
}
.input-left{
    width: 83px !important;
}
.input-right{
    max-width: 60px !important;
}
.processing{
    text-align: center;
}
@media(max-width:800px){
    .category-buttons{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
    }
    .category-buttons button:nth-last-child(1):nth-child(odd) {
        grid-column: 1 / span 2;
        margin-top: 20px;
    }
    .inactive-button, .inactive-button:hover{
        width: 100px;
        font-weight: 500;
    }
    .active-button, .active-button:hover,.active-button:focus{
        width: 100px;
        font-weight: 500;
    }
    .marketplaces{
        display: grid;
        grid-template-columns: auto auto auto;
    }
    .input-left{
        width: 75px !important;
    }
    .input-right{
        max-width: 50px !important;
    }
    
}