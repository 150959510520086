.affiliate-form{
    padding: 100px 0 60px;
    background: rgb(255 231 210 / 75%);
}
.heading{
    text-align: center;
    margin-bottom: 40px;
    padding: 0;
    }
    .heading h2{
        font-weight: bold;
        font-size: 33px;
        line-height: 50px;
        color: #18191F;
    }
    .heading h2 span{
        color: #ff7700;
    }
    .heading p{
        font-size: 18px;
        line-height: 25px; 
        color: #18191F;
        margin: 0;
    }
.affiliate-form form{
    padding: 20px 20px 40px;
    background: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
}

.form-area{

}
.form-area label{
display: flex;
width: 100%;
font-weight: 600;
font-size: 16px;
line-height: 24px; 
color: #444150;
}
.form-area label span, .form-area select span{
width: 40%;
text-align: left;
}
.form-area label input, .form-area select{
width: 60%;
font-weight: normal;
font-size: 16px;
line-height: 24px; 
color: #444150!important; 
opacity: 0.5;
background: #FBFBFB;
border: 1px solid #D9D9D9;
box-sizing: border-box;
border-radius: 6px;
}
.form-area label sup{
color: #f70;
font-size: 15px;
}
.form-area label input:focus{
    opacity: 1;
    border-color: #ff7700;
}
.submit{
    transition: .2s ease-in-out; 
    padding: 10px 20px;
    width: 150px;
    margin: 25px auto 0;

    box-shadow: 2px 7px 12px 0px #ddd;
}
.submit span{
    margin-left: 5px;
}
.submit:hover span{
    margin-left: 0;
    margin-right: 5px;
}
.submit:hover{
    box-shadow: 2px 7px 12px 0px #ff77008c;
}
.back{
    color: #ff7700!important;
    font-size: 45px!important;
    text-align: left;
    margin: 0!important;
    font-weight: 200;
}
 .form{
    background: #FFFFFF;
box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04);
border-radius: 10px;
    padding: 20px 0px 0px;
}
.form img{
    width: auto;
    margin: 15px auto;
}
.bottom-pitch{
    background: #FFF0E3;
border-radius: 0px 0px 10px 10px;
padding: 15px;
margin: 30px 0 0;
}
.bottom-pitch ul{
padding: 0;
list-style: none; 
margin: 0;
}
.bottom-pitch ul li{
    display: inline-block;
    padding: 0 20px;
}
.bottom-pitch ul li a{

}

.bottom-pitch h6{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px; 
    color: #000000; 
    opacity: 0.5;
}






/* @Responsive Media  */
@media screen and (max-width: 768px) and (min-width: 200px){
  
    .heading  h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .heading p {
        font-size: 12px;
        line-height: 24px;
    }
    
.submit { 
    padding: 5px 10px; 
    font-size: 12px;
    margin: 10px auto 0;
    width: auto;
}
.affiliate-form{
    padding: 100px 10px 60px;
}
.affiliate-form .heading img{
    margin-bottom: 10px;
}
.form-area {
    padding: 0;
}
.form-area label span, .form-area select span {
    width: 100%;
    text-align: left;
    display: block;
}
.form-area label input, .form-area select {
    width: 100%;
    display: block; 
    font-size: 12px;
    margin: 10px 0 0;
}
.form-area label {
    display: block; 
    font-size: 12px;
    line-height: 20px; 
}
.form-area label sup {
    vertical-align: text-bottom;
}

.form-area label input::placeholder{
    font-size: 12px;
}







}
















