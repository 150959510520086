.custom-saree{
    padding: 30px 20px;
    margin: 10px 0 0;
    border-top: 1px solid #ddd;
}
.custom-tabs{
padding:10px;
}
.custom-tabs li{
    padding: 5px;
    background: #fff;
    border: 0.735807px solid #C9C9C9!important;
    box-sizing: border-box;
    border-radius: 11.0371px!important;
    margin-bottom: 15px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    width: 100%;
    text-align: center;
    height: 105px;
}
.custom-tabs li img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
 
.custom-tabs li:last-child{
    background: #EBEBEB;
}
.custom-tabs li span{
    font-weight: 500;
font-size: 16px;
line-height: 24px; 
color: #000000;
position: relative;
top: 20px;
}
.custom-big-image p{
    font-weight: 600;
}
.custom-big-image{
    padding: 10px 20px;
    text-align: center;
}
.custom-big-image img{
    width: 100%;
    height: 500px;
    object-fit: contain; 
}

.download-popup {
    border: 1px solid #ddd;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 10px 30px;
    margin-top: 0px;
  }
  
  .download-popup button {
    border: 1px solid transparent;
    margin-bottom: 35px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 95px;
    text-align: left;
  }
  .cross-btn {
    text-align: right;
    padding: 0 0 10px;
  }
  .download-popup button:nth-of-type(2) {
    margin-bottom: 15px;
  }
  .download-popup ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
  }
  .download-popup ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
  }
  .download-popup p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
  }
  .download-popup p span {
    color: #ff7700;
  }
  .download-popup h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
  }
  .border-1 {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
  }
  .download-popup input {
    margin-right: 10%;
    border: 0;
    outline: 0;
  }
  .download-popup input:focus {
    border: 0;
    outline: 0;
  }
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
  }
  .download-btn-hd {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px;
  }
  .request-demo-1 {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 153px !important;
    text-align: center;
    padding: 10px 28px !important;
    font-size: 17px;
  }
  
  .download-popup button.activate, .download-popup button:hover {
    background: rgba(5,121,223,.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
}
.total-credits{
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}
.total-credits h5{
    padding: 0;
    font-weight: normal;
font-size: 14px;
line-height: 150%; 
letter-spacing: 0.5px; 
color: #232121;
}
.total-credits h6{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;  
    letter-spacing: 0.5px; 
    color: #FF7700;
    
}
.total-credits h5 span{
    color: #FF7700;
}
.skelton{
  width: 100%!important;
  height: 400px;
  margin: 0;
}
.download-skelton{
  width:85%!important;
  height:114px;
  margin:  50px 0 25px;
  border-radius: 10px;
}
.download-skelton3{
  width:85%!important;
  height:114px;
  margin:  0px 0 25px;
  border-radius: 10px;
}
.download-skelton2{
  width:60%!important;
  height:50px;
  margin:  0px 80px 25px 0;
  border-radius: 10px;
}
.download-skelton4{
  width:100%!important;
  height:100px;
  margin:  20px 0;
  border-radius: 10px;
}

 /* @Responsive  */
 @media screen and (max-width: 767px) and (min-width: 200px){
 
  .skelton{
    width: 100%;
    height: 200px;
  }
.custom-big-image img { 
  height: auto;
}
.download-popup {
  padding: 10px 15px;
}
.custom-saree{
  padding: 15px 0px;
}
.download-popup button {
  width: 100%!important;
}
.download-popup button { 
  padding: 15px;
}
.custom-big-image{
  padding: 10px 0px;
}
.custom-tabs{
  padding: 10px 0; 
}
.custom-tabs li {
  width: 29%;
  margin: 0 5px 15px;
}
.download-skelton, .download-skelton3{
  width:100%!important; 
}
.download-skelton2{
  margin: 0 auto 20px; 
}
 }

 @media screen and (max-width: 900px) and (min-width: 768px){
  .download-popup button { 
    padding: 15px;
    width: 100%!important;
  }
  .custom-saree{
    padding: 30px 0px;
  }
  .custom-tabs li {
    height: auto;
}
}
