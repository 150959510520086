.real-estate{
    padding: 20px 0% 60px;
}
.real-estate-image-area{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    margin: 0;
    padding: 20px 10px;
    height: 630px;
 
}
.real-estate-process{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    margin: 0;
    height: 100%;
    padding: 20px 10px;
   
 
}
.sky-image-inner{

}
.real-estate-image-area div:nth-child(1){
    height: 90%;
    background-repeat: no-repeat;
    background-size: cover;
}
.real-estate-image-area div.mt-0{
    height: 100%!important;
    background-repeat: no-repeat;
    background-size: cover;
}
.real-estate-image-area img{
    width: 100%!important;
    height: 100%;
    object-fit: contain;
}
.sky-header-section{
margin-top: 100px;
text-align: center;
}
.sky-header-section h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #1C1E52;
    margin: 0px;
    padding: 0px;
}
.sky-header-section p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 45px;
    color: #444444;
    margin: 0px;
    padding: 0px;
}
.sky-header-section p span{
    color: #FF7700;
}

.display-image{
    height: 90%;
    background-repeat: no-repeat;
    background-size: cover;
}
.display-image img{
    height: 100%;
    object-fit: contain;
}

.category-options, .category-options2{

}
.category-options ul{
width: 100%;
display: flex;
list-style-type: none;
padding: 0;
}
.category-options2 ul{
width: 100%;
display: block;
list-style-type: none;
padding: 0;
}
.category-options ul li{
    margin-right: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    width: 50px;
    height: 45px;
    text-align: center;
}
.category-options2 ul li{
    margin-right: 0;
    background: #FFFFFF;
    border-radius: 50%;
    width: 20%;
    height: 45px;
    text-align: center;
    display: inline-block;
    height: auto;
}
.category-options ul li img, .category-options2 ul li img{
    width: auto;
    height: auto;
    padding: 5px;
}
.category-options ul li button {
    background: #FFFFFF;
border: 1px solid #fff;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
padding:0;
width: 48px;
    height: 48px;
}
.category-options2 ul li button {
    background: none;
    border-radius: 0;
    padding: 0;
}
.category-options ul li button.active { 
border: 1px solid #FF3503; 
}

.mybtn{
    background: #FF3503;
border-radius: 20px;
width: 150px;
height: 45px;
}
.brush{
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval.png'), auto;
    
}

.brush1 {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(1).png'), auto;
}
.brush2 {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(2).png'), auto;
}

.category-options2 ul li span{
    color: #fff;
    position: absolute;
    margin-left: -22px;
    font-size: 10px;
    background: #333;
    padding: 2px 6px;
    border-radius: 50%;
}
