.border-bt{
    border-bottom: 3px solid #FF7700; 
    padding-bottom: 5px;

}
.colored{
    color: #ff7700;
}
.whitepaper-bg{
    padding: 100px 0 50px;
}
.heading h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 1.5px;
    padding: 0 0 20px;
    color: #232121;
  }
   
  .backgrounds {
    background: #FFFFFF;
    box-shadow: 1px 4px 4px #ddd;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 15px 20px;
   
  }
  .backgrounds h6 {
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0px 0px 20px;
    font-weight: 600;
  }
  .backgrounds h6 span {
   text-decoration: underline;
  }
  .background-images {
    list-style: none;
    display: block;
    padding: 0;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    height: 450px;
  }
  
  .background-images li {
    display: inline-block;
    margin-bottom: 15px;
    padding: 0 10px;
    cursor: pointer;
    width: 50%;
  }
  .background-images li img {
    width: 100%;
    height: auto; 
       border-radius: 5px;
  }
   
   
  .background-images li span {
    font-size: 11px;
    font-weight: 500;
  }
  
  .backgrounds p {
    font-size: 14px;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 5px 0px 0px;
  }
  
  .background-right {
  } 
  .background-right img{
    width: 100%;
    padding: 5px;
    box-shadow: 1px 4px 4px #ddd;
  } 
  .try-demo{
    justify-content: center;
  }
  .try-demo p{
    width: auto;
    margin: 30px 10px 0;
  }
  .try-demo p span{
    display: block;
    font-weight: bold;
font-size: 11px;
line-height: 25px;  
color: #000000;
margin-top: 10px;
}
.try-demo p a {  
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    background: #ff7700;
    color: #ffffff;
    transition: 0.2s ease-in-out;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
    padding: 8px 30px;
    border: 2px solid #ff7700;
}
.demo a{ 
border: 2px solid #ff7700!important; 
color: #FF7700!important;  
background: #fff!important;
}
.back-credit {
  text-align: end;
  font-size: 12px!important;
  margin-bottom: 0!important;
  padding: 0 10px 0 0;

}
.back-credit img {
  width: auto!important;
  height: auto!important;
  padding-left: 4px;
  border: 0!important;
}
.inactive-background-sec{
border: 1.5px solid transparent;
transition: .2s ease-in-out;
}
.active-background-sec{
  border: 1.5px solid #ff7700;
}



/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px){
  .whitepaper-bg{
    padding: 70px 0 0;
}
.heading h3 { 
  font-size: 17px;

}
.try-demo p a {
  font-size: 14px;
  padding: 6px 35px;
}
.demo a{
  padding: 6px 20px!important;
}
.demo{
  text-align: left!important;
}
.try-demo p {
  width: 50%;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
}
.try-demo {
  margin: 25px 0;
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.try-demo p span {  
  line-height: initial;
  display: none;
}
.backgrounds { 
  padding: 10px 0px;
}
.background-images { 
  height: 250px;
}
.backgrounds p {
  font-size: 12px;
}


}
@media screen and (max-width: 900px) and (min-width: 768px){
.background-images{
  margin: 0;
  height: 270px;
}
.try-demo p {
  margin: 30px 6px 0;
  padding: 0;
}
.calendly-area .title h1 {
  margin-bottom: 10px;
}
}



@media screen  and (min-width: 1450px) {
  .background-images { 
    height: 550px;
  }
}







