@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rozha+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');

:root {
  --textColor: #fff;
  --resColor: #fff;
  --cropColor: #fff;
  --blurColor: #fff;
}
h3,h4,h5,h6,p,a,span, div{
  font-family: 'Inter'!important;
}
h1,h2{
  font-family: 'Rozha One'!important;
}
.image-scoring {
  padding: 0px 0 0px;
  height: 100vh;
}
.image-container {
  width: 100%;
  padding: 0 12px 0 60px;
}
.scoring-col {
  background: conic-gradient(
    from 250.23deg at 50% 50%,
    #eee28d -15.84deg,
    #b19de3 109.29deg,
    #ef8b9c 256.45deg,
    #eee28d 344.16deg,
    #b19de3 469.29deg
  );
  border-radius: 70px 70px 0 0;
    padding: 0px 30px 30px;
    border: 35px solid #fff;
    border-bottom: 0;
    margin-top: 21vh;
    min-height: 79vh;
}
.scoring-col2{
  margin-top: 18vh;
  padding: 0 30px 20px;
}
.scoring-upload {
  background: #000000;
  border-radius: 19px;
  padding: 15px;
  margin: -55px 0 0;
  transition: 0.3s ease-in-out;
}
.scoring-upload:hover{
  margin: -60px 0 0;
}
 
.scoring-upload-box {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 60px 20px 15px;
  text-align: center;
  color: #fff;
}
.scoring-upload-box img {
  margin: 0 auto 35px;
      cursor: pointer;
      width: 35%;
}
.slider-area {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(255, 146, 0, 0.29);
  border-radius: 15px;
}
.slider-area p {
  background: linear-gradient(90.04deg, #ff9200 0.05%, #ff7700 96.78%);
  border-radius: 15px 15px 0px 0px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  padding: 5px 20px;
  color: #ffffff;
}
.slider-area p span {
  float: right;
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 5px;
  padding: 0 3px;
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.image-slider {
  padding: 15px 20px 10px;
}
.scoring-upload-box h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 35px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 15px;
}
.scoring-upload-box h6 span {
  border-bottom: 1px solid #c5c5c5;
  padding-bottom: 10px;
  cursor: pointer;
}
.scoring-upload-box p {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #ff7700;
  margin-bottom: 20px;
}
.slider-area p {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 0px;
  text-align: center;
  padding: 5px 10px;
}
.slide {
  padding: 0 5px;
}
.slide img {
  border-radius: 5px;
  margin: 0;
  transition: 0.3s ease-in-out;
  height: 60px !important;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  border: 0.5px solid rgba(50, 50, 50, 0.1);
box-sizing: border-box;
border-radius: 5px;
}
.slide2 img{
  height: 47px!important;
}
.slide img:hover { 
  box-shadow: 0px 0px 4px 0px #ff9200;
}
.scoring-title {
  padding: 50px 60px 0px 35px;
  margin-top: 22vh;
}
.scoring-title2 { 
  margin-top: 10vh;
}
.scoring-title h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 62px;
  line-height: 72px;
  color: #000000;
  font-family: 'Rozha One'!important;
}
.scoring-title h1 span {
  color: #e03813;
  font-family: 'Rozha One'!important;
}
img.rectangle,
.rectangle2 {
  right: 0;
  bottom: 0;
  position: absolute;
  transition: 0.3s ease-in-out;
}
img.rectangle {
  width: 120px;
}
img.rectangle:hover {
  width: 125px;
  box-shadow: 0px 2px 45px 0px #bca9d5;
  border-radius: 35px 0 0 0;
}
.rectangle2:hover {
  width: auto;
  box-shadow: 0px 2px 45px 0px #bca9d5;
  border-radius: 33px 0 0 0;
}
.scoring-title p {
  font-weight: normal;
  font-size: 22px;
  line-height: 39px;
  color: #000000;
}
.scoring-title p span {
  color: #e03813;
}
.scoring-upload2 {
  background: rgba(0, 0, 0, 0.79);
  backdrop-filter: blur(10px);
  border-radius: 19px;
  padding: 15px;
  margin: -40px 0 0;
  position: relative;
}
.scoring-upload-box2 {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 5px;
  text-align: center;
  color: #fff;
  height: 300px;
}
.scoring-upload2 p {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 20px 0px 50px;
}
.scoring-upload2 p span{
  color: #ff7700;
  cursor: pointer;
}
.scoring-upload-box2 img {
  width: 100%;
  /* height: auto; */
  height: 289px;
  object-fit: contain;
}
.slider2 {
  margin: -60px 55px 0 !important;
  z-index: 1;
  position: relative;
}

.scoring-title2 h1 { 
  font-weight: normal;
  font-size: 75px;
  line-height: 70px;
  color: #000000;
  font-family: 'Rozha One';
}
.scoring-title2 h1 span {
  font-weight: normal;
  font-size: 47px;
  line-height: 39px;
  color: var(--textColor);
}
.scoring-title2 ul {
  padding: 0;
  list-style: none;
  margin: 35px 0 30px;
}
.scoring-title2 ul li {
}
.scoring-title2 ul li p {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}
.scoring-title2 ul li p button {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #e94949;
  border-radius: 7px;
  padding: 0px !important;
  margin-right: 20px;
  width: 85px;
  height: 33px;
  cursor: initial;
}

.comming-2 {
  background: #ffffff !important;
  border: 1px solid #f7f7f7 !important;
  box-sizing: border-box;
  border-radius: 7px;
  font-weight: 600;
  font-size: 8px !important;
  margin-right: 10px;
  line-height: 15px;
  color: #000000 !important;
  min-width: 86px !important;
  padding: 0 !important;
  font-weight: 800 !important;
}

.nine {
  background: var(--resColor) !important;
}

.six {
  background: var(--blurColor) !important;
}
.five {
  background: var(--cropColor) !important;
  /* padding: 5px 20px !important; */
}
.scoring-title2 ul li p i {
  margin-left: 15px;
  color: #ca3e12;
  border: 1px solid;
  font-weight: 300;
  padding: 2px 6px;
  border-radius: 45px;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
} 
.tooltip {
  position: relative;
text-decoration: none;
}
.tooltip span {
	background: none repeat scroll 0 0 #222222d1;
    color: #fff!important; 
    font-size: 10px;
    font-weight: normal;
    line-height: 19px;
    padding: 10px;
    width: 260px;
    
    margin-left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    z-index: 2;
    text-transform: none;
    transition: all 0.3s ease-in-out;
}


.tooltip:hover span {
  opacity: 1;
filter: alpha(opacity=100);
top: -2em; 
z-index: 99;
-webkit-transition: all 0.2s ease;
-moz-transition: all 0.2s ease;
-o-transition: all 0.2s ease;
transition: all 0.2s ease;
}

.comming {
  background: #ffffff !important;
  border: 1px solid #f7f7f7 !important;
  box-sizing: border-box;
  border-radius: 7px;
  font-weight: 600;
  font-size: 8px !important;
  margin-right: 10px;
  line-height: 15px;
  color: #000000 !important;
}
.scoring-title2 ul li p b {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.4);
}

.marketplace-dimension h5 {
  font-family: 'Rozha One';
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;

  color: #000000;
}
.marketplace-dimension h5 span {
  /* color: #ff7700; */
}
.marketplace-dimension li {
  display: inline-block;
  padding: 5px;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  color: #565656;
}
.marketplace-img {
  opacity: 0.4;
  filter: drop-shadow(0px 10px 20px rgba(41, 41, 42, 0.07));
  background: transparent;
  padding: 5px;
}
.marketplace-img img {
  width: 50px;
}
.marketplace-img.active {
  border: 3px solid #14ac20;
  border-radius: 50%;
  width: 100%;
  opacity: 1;
  position: relative;
  z-index: 0;
  height: 65px;
  margin: 0;
}
.check {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 17px !important;
  background: #fff;
  border-radius: 50%;
  padding: 2px;
  transition: 0.3s ease-in-out;
}
.marketplace-img:hover .check,
.slider-area p span:hover i {
  transform: rotate(310deg);
}
.scoring-title2 p {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #262525;
}
.scoring-title2 h5 button {
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  transition: 0.2s ease;
  min-width: 150px;
  margin-right: 10px;
}
.try-btn {
  border: 2px solid #ff7700;
}
.try-btn:hover {
  box-shadow: 0px 3px 5px 0px #9e9e9e6e;
}
.try-api {
  border: 2px solid #121211;
  box-sizing: border-box;
  background: #ffffff;
  color: #131312;
}
.try-api:hover {
  color: #ff7700;
  border-color: #ff7700;
}
.get-app {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  background: transparent;
  border: 0;
}
.get-app i {
  color: #ff7700;
}
.get-app:hover {
  color: #ff7700;
}
.get-app:hover i {
  margin-left: 5px;
}
.loading-gif {
  position: absolute;
  top: 40%;
}

.drop-box-text {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(62, 60, 60, 0.7);
  top: 0;
}

.drop-box-text div {
  text-align: center;
  top: 40%;
  position: relative;
  font-size: 50px;
  color: #fff;
}

.try-img {
  width: 150px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 5px;
}
.scan{
  animation: slide2 1.5s ease-in-out infinite;
}
@keyframes slide2 {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg); opacity: 0.2;
  }
  50% {
    transform: translate(0px, 0px) rotate(0deg); opacity: 0.5;
  }
}
.progress { 
  border-radius: 8.5px;
}
.progress .progress-bar { 
  border-radius: 8.5px;
}


/* @Responsive  */
 

@media screen and (min-width: 1366px) {
  .scoring-col{
    padding: 0px 40px 25px;
    margin-top: 18vh;
    min-height: 82vh;
  }
  .scoring-upload-box{
    padding: 90px 20px 25px;
}
.scoring-title h1 {
  font-size: 70px;
  line-height: 85px;
}
.scoring-upload-box img {
  margin-bottom: 60px;

}
.scoring-title{
  padding: 50px 60px 25px;
}
.slide img{
  margin: 0%;
}
.image-container{
  padding: 0 12px 0 70px;
}
.scoring-upload-box2{
  height: 331px;
}
.scoring-upload-box2 img {
  height: 320px;
}
.scoring-title2 {
  margin-top: 12vh;
}
}
@media screen and (min-width: 1440px) {
  
.image-container {
  width: 100%;
  padding: 0 12px 0 80px;
}
.scoring-title h1 {
  font-size: 70px;
  line-height: 90px;
}
.image-scoring {
  padding: 0px 0 0px;
}
.scoring-upload-box p {
  margin-bottom: 35px;
}
.slider-area p{
  margin-bottom: 0;
}
.scoring-title p {
  font-size: 23px;
}
.scoring-title {
  padding: 80px 125px 0 80px;
  margin-top: 22vh;
}
.scoring-title2 {
  padding: 100px 80px 0;
  margin-top: 5vh;
}
.scoring-col{ 
  margin-top: 17vh;
    min-height: 83vh;
}
.scoring-upload-box{
  padding: 110px 20px 25px;
}
.slide img {
  height: 70px!important;
}
.slide2 img {
  height: 60px!important;
}
.scoring-upload-box2{
  height: 430px;
}
.scoring-upload-box2 img { 
  height: 419px;
}
.scoring-upload-box img {
  margin-bottom: 70px;
  
  }
.progress{
  margin-bottom: 40px;
}
.scoring-title2 ul li p {
  font-size: 25px;
}
.scoring-title2 ul li p button {
  font-size: 19px;
  line-height: 30px;
  width: 110px;
  height: 45px;
}
.comming {
  font-size: 12px !important;
}
.scoring-title2 ul {
  margin: 35px 0 40px;
}
.scoring-title2 h5 button {
  font-size: 17px;
  line-height: 35px; 
  min-width: 170px;
  margin-right: 20px;   
   padding: 7px 15px;
}
.try-img{
  width: 170px;
  margin-right: 20px;
  margin-top: 15px;
}
.scoring-upload2 {
  margin: -55px 0 0;
}
.slide img{
  margin: 0%;
}
}



@media screen and (min-width: 1680px) {
  
  .image-container { 
    padding: 0 12px 0 100px;
  }
  .scoring-title h1 {
    font-size: 88px;
    line-height: 115px;
  }
  .scoring-title2 h1 {
    font-size: 105px;
    line-height: 115px;
  }
  .scoring-title2 h1 span {
    font-size: 65px;
  }
  .image-scoring {
    padding: 0px 0 0px;
  }
  .scoring-upload-box p {
    margin-bottom: 35px;
  }
  .slider-area p{
    margin-bottom: 0;
  }
  .scoring-title p {
    font-size: 25px;
  }
  /* .scoring-title {
    padding: 80px 80px 0;
    margin-top: 16vh;
  } */
  .scoring-upload {
    margin: -65px 0 0;
    padding: 25px;
}
.scoring-upload-box h6 {
  font-size: 20px;
}
.scoring-upload-box p {
  font-size: 19px;
}
.scoring-upload-box2{
  height: 475px;
}
.scoring-upload-box2 img { 
  height: 466px;
}
.scoring-upload-box img {
margin-bottom: 100px; 
}
.slide img{
  margin: 0%;
}
.scoring-upload-box{
  padding: 120px 20px 25px;
}
.scoring-col{
  padding: 0px 45px 30px;
  margin-top: 15.5vh;
    min-height: 84.5vh;
}
}

@media screen and (min-width: 1920px){
  .scoring-title h1 {
    font-size: 96px;
    line-height: 125px;
  }
  .scoring-upload-box {
    padding: 135px 20px 5px;
}
.scoring-upload-box img {
  margin-bottom: 120px; 
}
.slide img{
  margin: 0%;
}
.scoring-title {
  padding: 100px 100px 5px;
  
}
.scoring-upload2 { 
  padding: 23px;
  margin: -50px 0 0; 
}
.scoring-upload-box2{
  height: 540px;
}
.scoring-upload-box2 img { 
  height: 529px;
}
.image-container{
  padding: 0 12px 0 150px;
}
.scoring-col{ 
  margin-top: 15vh;
      min-height: 85vh;
      border: 45px solid #fff;
      border-bottom: 0;
      border-radius: 100px 100px 0px 0px;
}
.scoring-col2{ 
  margin-top: 12vh;
      min-height: 88vh;
}
.scoring-title2 ul li p button {
  font-size: 20px;
  line-height: 32px;
  width: 115px;
  height: 52px;
}
.scoring-title2  h1 {
  font-size: 110px;
  line-height: 133px;
}
.scoring-title2O ul {
  margin: 45px 0 50px;
}
.scoring-title2 ul li p {
  font-size: 27px;
  margin-bottom: 25px;
}
.scoring-title2 ul li p i { 
  font-size: 18px;
}
.slide img {
  height: 80px!important;
} 
}
@media screen and (max-width: 767px) {
  .image-scoring {
    padding: 70px 0 0px;
}

.scoring-title {
  padding: 0px 10px 60px;
  order: 1;
  margin: 0;
}
.image-container { 
  padding: 0 12px;
}
.scoring-col{
  order: 2;
  padding: 0px 20px 30px;
  margin: 0;
  border: 0;
  min-height: auto;
  height: auto;
  border-radius: 31px 31px 0 0;
}
.rectangle2, .rectangle{
  display: none;
}
.scoring-title h1 {
  font-size: 35px;
  line-height: 45px;
}
.scoring-title p {
  font-size: 14px;
  line-height: 30px;
}
.scoring-upload-box img {
  margin:0 auto 35px;
  width: 35%;
}
.scoring-upload-box h6 { 
  font-size: 16px;
  margin-bottom: 9px;
}
.scoring-upload-box p{
  font-size: 16px;
  padding: 5px;
}
.slider-area p{
  font-size: 10px;
  text-align: left; 
  padding: 5px 10px;
}
.slide img{
  width: 100%;
  margin: 0;
  height: 45px!important;
}
.scoring-col2{
  order: 1;
  margin: 50px 0 30px;
  border-radius: 31px;
}
.scoring-upload-box2 { 
  height: 280px;
}
.scoring-upload-box2 img {
  height: 269px;
}
.slider2{
  margin: -60px 40px 0 !important;
}
.scoring-title2 ul li:nth-child(1) p b, .scoring-title2 ul li:nth-child(2) p b, .scoring-title2 ul li:nth-child(3) p b{
  display: none;
}
.scoring-title2 ul li p b {
  font-size: 14px;
  
}
.scoring-title2 h1 span { 
  font-size: 26px;
}
 
.scoring-title2 h5 {
  text-align: center;
  justify-content: center;
}
.scoring-title2 h5 button {
  min-width: 46%;
  margin: 0 5px;
  line-height: 24px;
}
.try-img {
  width: 46%;
  margin: 10px 5px 0;
}
.scoring-title2 ul li p {
  font-size: 16px;
}
.scoring-title2 ul li p button { 
  font-size: 14px;  
  width: 80px;
  height: 30px;
}


}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .scoring-col{
    height: auto; 
    margin: 14vh auto 0;
    padding: 0px 15px 30px;
  }
  .image-container {
    width: 100%;
    padding: 0 15px;
}
.scoring-title {
  padding: 50px 0px 60px 0;
  margin-top: 9vh; 
}
.scoring-title  h1{
  font-size: 40px;
  line-height: 60px;
}
.scoring-title2  h1{
  font-size: 60px;
}
.scoring-title2  h1 span{
  font-size: 40px;
}
.rectangle2, .rectangle{
  display: none;
}
.scoring-upload-box h6 {
  font-size: 10px;
  margin-bottom: 10px;
}
.scoring-upload-box p {
  font-size: 10px;
}
.slide img{
  height: 45px!important;
}

}




