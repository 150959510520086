.user-section{
    padding: 60px 0 50px;
}
.user-form{

}
.user-heading h6{
    font-weight: 500;
    font-size: 22px;
    line-height: 39px; 
    color: #232323;
    margin-bottom: 35px;
    text-align: center;
}
.user-heading h6 span{
    border-bottom: 2px solid #FF7700; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.user-heading h6 img{
    width: 60px;
    margin-right: 10px;
}
.user-form form{

}
.user-form form label{
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #FF7700;
    display: block;
    width: 100%;
}
.user-form form input, .user-form form select{
    display: block;
    width: 100%;
    padding: 6px 10px;
    border: 2px solid #ff770063;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin: 10px 0;
    outline: 0!important;
    height: 45px; 
    font-weight: 400;
font-size: 14px;
line-height: 30px; 
/* color: #313131; 
opacity: 0.5; */
}
.user-form form input::placeholder{
font-weight: 400;
font-size: 14px;
line-height: 30px; 
color: #F9A826; 
opacity: 0.5;
}
.user-form form button{
    font-weight: 500;
font-size: 16px;
line-height: 30px; 
color: #FFFFFF;
background: #FF7700;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
border-radius: 12px;
padding: 6px 20px ;
}
.bg-select input{
    width: 60%!important;
    float: left;
    border-right: 0!important;
    border-radius: 10px 0px 0px 10px!important;
}
.bg-select button{
    width: 40%;
    float: right;
    height: 45px;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 0px 10px 10px 0px!important
}
.bg-select{

}

.user-form form input:focus-visible {
outline: 0 0 0!important;
border-color: #FF7700;
box-shadow: 0 0 0 0 !important;
}
.bg-select button span{
    float: right;
    font-size: 25px;
}
.color-picker{

}
.color-picker input{
    margin: 0!important;
    width: 10%!important;
    float: right;
    border-right: 0!important;
    line-height: initial!important;
    border: 0!important;
    height: 40px!important;
}
.color-picker div{
    display: block;
    width: 100%;
    padding: 0px 0px 0 10px;
    border: 2px solid #ff770063;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin: 10px 0;
    outline: 0!important;
    height: 45px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    
}
.color-picker div span{
    font-weight: 400;
font-size: 14px;
line-height: 40px; 
color: #F9A826; 
opacity: 0.5;
}

