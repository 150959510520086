.mactive {
    background-color:white !important;
    color:#ff7700 !important; 
    border-bottom: 4px solid #ff7700 !important; 
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 100%;
    text-align: center;
    /* or 17px */
    letter-spacing: 1.7px;
  }
  .myclass{
      margin-bottom: 10px;
  }
.btn1{
    font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 100%;
/* or 17px */
letter-spacing: 1.7px;
border-bottom: 4px solid rgba(153, 150, 150, 0.1);
color: rgba(24, 25, 31, 0.51);
    background-color:white; 
  text-align: center;
  }
.btn1:hover {
    color:#ff7700 !important;
    border-bottom: 4px solid #ff7700 !important; 
  }
.circle {
    margin-left: 40%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;;
  }
.activecircle{
    background:#ff7700 ;
}
.ntactivecircle{
    background:#999696;
}
.textcircle{
    color: #fff;
    padding-top: 8px;
}
.activet{
    margin-left: 30px;
    color:#ff7700;
}
.ntactivet{
    margin-left: 30px;
    color:#999696 ;
}
.activet2{
    margin-right: 13%;
    color:#ff7700;
}
.ntactivet2{
    margin-right: 12%;
    color: #999696;
}

.downtext{
    padding-right: 17%;
}
hr.newhr{
    border-width: 0 !important;
    border-top: 3px dashed #999696 !important;
    background-color: #fff;
}
@media screen and (max-width: 600px) {
    .header-section {
      display:none;
    }
  }