.video-upload {
}
.video-upload h6 {
  background: rgba(252, 121, 26, 0.26);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: #393939;
  font-family: 'Poppins';
  border-radius: 0 10px 10px 0;
  padding: 0px 0px 0 15px;
  margin: 20px auto 35px;
}
.video-upload h6 button {
  background: #ff7700;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  float: right;
  padding: 7px 15px;
}

.video-upload h6 span {
  font-size: 13px;
}

/* CarsThreeSixtyVideo  */
.video-section {
  padding: 100px 0 60px;
}
.video-title {
  margin-bottom: 35px;
}
.video-title h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 150%;
  text-align: center;

  color: #232121;
}
.video-title p {
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #232121;
}
.video-area-right {
  padding: 20px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
  background: #fff;
  border-radius: 5px;
}
.video-area-simple {
  border: 1px dashed #ffa64d;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}
.video-area-simple p {
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  padding: 5px 0 0;
}
.video-area-simple h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 1.7px;
  margin-bottom: 30px;

  color: #232121;
}
.video-area-simple img {
  width: auto;
  margin: 0 auto 20px;
}

.create-three-sixty {
  padding: 100px 0 60px;
}
.notification {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
  color: rgba(244, 6, 6, 0.75);
}
.create-three-sixty .video-area-simple h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
  margin: 20px 0 10px;
}
.create-three-sixty .video-area-simple p {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #55575b;
}
.create-three-sixty .video-area-simple p span {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #232121;
}
.create-three-sixty .video-area-simple button {
  filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
  width: 150px;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  margin: 0px auto 0;
}
.highlighter-box {
}
.highlighter-box-single {
  /* height: 150px; */
}

.after-upload {
  margin-top: 100px;
  margin-left: 50px;
}

.wrapper {
  width: 600px;
  max-width: 90%;
  /* background:black; */
  padding: 1rem;
  border-radius: 4px;
  /* margin:auto;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 5px 10px rgba(0,0,0,.3); */
}

.no-reupload {
  background-color: #fff;
  border: 1px solid #ff7700;
  color: #ff7700 !important;
  width: 155px !important;
  margin-right: 25px !important;
}
.upload-btn-rotate{
  background-color: white;
}