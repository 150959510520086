.active-button{
    background: #FFFFFF;
    border: 2px solid #0084FE;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100px;
    height: 95px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    border: unset;
    padding: 0px;
    margin: 0px;
}
.inactive-button{
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100px;
    height: 95px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    border: unset;
    padding: 0px;
    margin: 0px;
}  
.new-bg-imges{
    height: 220px;
    overflow: auto;
}
.car-step-text{
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}
.car-step-text-fixed{
    color: #0579DF;
    position: absolute;
    top: 10px;
    left: 40px;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
}
.angle-seclection-sec{
    border: 1px solid #A8A8A880;
    border-radius: 20px;
    height: 120px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}
.angle-seclection-sec  p {
    padding-top:10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-family: 'Roboto';
    color: black;
    font-weight: normal;
}
.angle-seclection-sec button{
    border: unset;
    background: transparent !important;
}
.right-top-section{
    display: grid;
    width: auto;
    grid-template-columns: 50% 48%;
    grid-gap: 10px;
}
.delarship-logo-sec{
    border: 1px solid #A8A8A880;
    border-radius: 20px;
    height: 120px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px !important;
}
.delarship-logo-sec button{
    height: 48px;
    color: white;
}
.angle-seclection-sec button:focus {
    border: unset !important;
    outline: none;
}
.dealership-text-fixed{
    color: #0579DF;
    position: absolute;
    top: 10px;
    left: 350px;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
}
.high-opecity{
    opacity: 1;
}
.low-opecity{
    opacity: 0.1;
}
.white_bg {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    margin-top: 5px;
}
.cat-img-cont button{
    /* width: 60px; */
    border: unset;
    background: transparent;
}

@media(max-width:800px){
   .new-bg-imges{
       display: grid !important;
       grid-template-columns: 33% 33% 33% !important;
   }
   .active-button{
       width: 100px;
       height: 90px;
   }
   .inactive-button{
       width: 100px;
       height: 90px;
   }
   .small-screen-buttons{
       justify-content: space-between !important;
       padding: 20px;
       height: 120px;
   }
   .right-top-section{
        display: grid;
        grid-template-columns: 100%;
    }
    .delarship-logo-sec{
        margin-top:20px
    }
    .dealership-text-fixed{
        color: #0579DF;
        position: absolute;
        top: 160px;
        left:30px;
        background: white;
        padding-left: 5px;
        padding-right: 5px;
    }
}