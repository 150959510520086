.upload-section{
    width: 95%;
    background: #FFFFFF;
    border: 1px dashed #0579DF;
    border-radius: 10px;
    margin: 0 auto 20px;
    text-align: center;
    padding: 10px;
}
.screen-2-drop{
    width: 391.09px !important;
    height: 100%;
    outline: 0;
    border: 0;
}
.upload-section-car{
    width: 95%;
    background: #FFFFFF;
    border: 1px dashed #0579DF;
    border-radius: 10px;
    margin: 0 auto 25px;
    text-align: center;
    padding: 10px;
}
.screen-2-drop-car{
    width: 100%;
   
}
.screen-2-drop-car:focus{
    outline: none;
}
.right-section-background-section{
    width: 95%;
    height: auto;
    background: #FFFFFF;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: auto;
    overflow-x: hidden;
    margin: 0 auto;
}
.backgrounds{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-gap: 10px;
    padding: 0px 30px;
    margin: 13px 0px;
    padding-bottom: 20px;
}
@media(max-width:800px){
    .screen-2-drop{
        width: auto;
    }
    .screen-2-drop-car{
        width: auto !important;
    }
    .right-section-background-section { 
        margin-bottom: 20px;
    }
    .backgrounds { 
        grid-gap: 0px;
        padding: 0px 10px;
        margin: 0px 0px 15px;
        padding-bottom: 20px;
    }
}