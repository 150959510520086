.case-studies {
  background: #ffecdb;
  backdrop-filter: blur(100px);
  padding: 100px 0 60px;
}
.case-container {
  width: 100%;
}
.case-card {
  padding: 20px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 17px rgba(255, 119, 0, 0.24),
    0px 3px 10px -1px rgba(255, 119, 0, 0.05);
  border-radius: 16px;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
  margin: 0;
  text-decoration: none;
  border: 1.5px dashed rgb(248, 246, 246);
  cursor: pointer;
  margin-top: 25px;
}
a.case-card {
  text-decoration: none;
  margin-bottom: 20px;
}
.case-card:hover {
  box-shadow: 0px 2px 17px rgba(255, 119, 0, 0.24),
    0px 4px 10px -1px rgba(255, 119, 0, 0.05);
  border: 1.5px dashed #ff9800;
}
.case-studies-img img {
  width: 100%;
  max-height: 221px;
  object-fit: contain;
}
.case-content2 {
  padding-top: 5%;
  padding-right: 5%;
}
.case-content h3,
.case-content2 h3 {
  font-weight: bold;
  font-size: 23px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 15px;
}
.case-content p {
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #606060;
  margin: 0;
}
.case-content2 p {
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 25px;
}
.case-content2 input {
  background: #ffffff;
  border: 1px solid #b3b6da;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.06em;
  color: #8f92a1;
  padding: 10px;
  width: 45%;
}
.case-content2 button {
  display: block;
  margin-bottom: 30px;
}
.case-content2 small {
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.case-image img {
  width: 100%;
}
.case-image {
  padding-top: 5%;
}

/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 200px) {
  .case-studies-img img {
    margin-bottom: 15px;
  }
  .case-content h3,
  .case-content2 h3 {
    font-size: 18px;
  }
  .case-content p,
  .case-content2 p {
    font-size: 13px;
  }
  .case-container {
    width: 100%;
    padding: 0 15px;
  }
  .case-image {
    margin-bottom: 20px;
    padding: 0 0;
  }
  .case-content2 input {
    width: 100%;
  }
  .case-content2 button {
    width: auto;
    padding: 6px 15px;
  }
  .case-content2 small {
    font-size: 12px;
    line-height: 19px;
  }
  .case-content2 {
    padding: 0 15px;
  }
  .case-studies {
    padding: 100px 0 35px;
  }
}
