.landing-screen-two{
    padding: 50px;
    margin-top: 65px;
    background: #fff;
}
.screen-two-area{
    padding: 50px;
    margin: 0;
    border: 2px dashed #C4C4C4;
    box-sizing: border-box; 
    border-radius: 13px;
    background: #fff;
    text-align: center;
    transition: .2s ease-in-out;
}

.screen-two-area h1{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 45px;
color: #1C1E52;
}
.screen-two-area p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px; 
    color: #444444;
}

.screen-two-area .mybtn{
    width: 178px;
height: 43px;
background: #FF3503;
color: #fff;
margin: 5% 0;
border-radius: 20px;
}

.screen-two-area p span{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 45px;
color: #B4B4B4;
}
.screen-two-area:hover{
    background: linear-gradient(180deg, #FFFBF4 0%, rgba(250, 189, 175, 0) 100%);
}
.screen-two-area ul{
    display: flex;
    margin: 0 auto;
    width: 75%;
    justify-content: center;
}
.screen-two-area ul li{
    display: inline-block;
    padding: 10px 10px 0;
}
.screen-two-area ul li img{
    width: 100%;
}







