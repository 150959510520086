.upload-screen{
padding: 100px 0 60px;
}
.upload{

}
.upload h1{
    font-weight: 800;
    font-size: 35px;
    line-height: 50px;  
    color: #18191F;
}
.upload p{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;  
    color: #000000;
}
.upload p span{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; 
    color: #FF7700;
}
.float-right{
    float: right;
}
.upload-cols{
    text-align: center;
}
.upload-single{

}
.upload-single:after{
    display: inline-block;
    content: "";
    position: absolute;
    top: 25%;
    left: 50%;
    width: 100%;
    /* height: 10px; */
    border-top: 1px dashed #B5B4B4;
    z-index: -1;
}
.upload-single+.upload-single:after{
    border: 0!important;
}
.upload-single p{
    font-weight: normal;
    font-size: 16px;
    line-height: 110%; 
    text-align: center;
    letter-spacing: 0.5px;
    
    color: #B5B4B4;
}
.upload-single p span{
    display: block;
    background-color: #B5B4B4;
    padding: 5px;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0 auto 15px;
}
.active{
    color: #FF7700!important;
    }
    .upload-single p.active {
        color: #FF7700!important;
    }
    .upload-single p.active span{
        background: #FF7700!important;
    }
.upload-box{
    background: rgba(255, 119, 0, 0.05);
border: 0.587273px dashed #FF7700;
box-sizing: border-box;
border-radius: 11.7455px;
text-align: center;
padding: 10px;
color: #FF7700;
margin: 20px;
}
.upload-box h4{
    font-weight: 500;
font-size: 17.6182px;
line-height: 100%; 
align-items: center;
text-align: center;
letter-spacing: 0.998364px; 
color: #FF7700;
}
.upload-box p{
    font-weight: normal;
font-size: 7.04727px;
line-height: 100%;  
align-items: center;
text-align: center;

color: #FF7700;
}
.upload-text{
    text-align: center;
}
.upload-text h3{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px; 
    color: #18191F;
}
 
.skip-btn{
    background: #FFFFFF;
border: 2px solid #FF7700;
box-sizing: border-box;
box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px;
color: #FF7700;
min-width: 120px;
padding: 7px 15px;
margin-right: 15px;
}
.next-btn{
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
border-radius: 8px;
padding: 7px 15px;
min-width: 120px;
border: 2px solid #FF7700;
}
.heading{

}
.heading h2{
    font-weight: normal;
font-size: 24px;
line-height: 110%; 
text-align: center;
letter-spacing: 0.5px; 
color: #000000;
margin-bottom: 30px;
}
.saree-pallu{
    border-top: 1px solid #CBCBCB;
}
.saree-left{
    border-right: 1px solid #CBCBCB;
    padding: 20px;
}
.saree-left h6{
    text-align: center;
}
.saree-left button{
    margin: 0 10px;
    font-weight: bold;
font-size: 12px;
line-height: 12px; 
color: #FFFFFF;
}
.saree-image{

}
.saree-image img{
    width: 100%;
    margin: 20px auto;
}

.upload-btn{
    background: #FF7700;
border-radius: 17.4889px;
font-style: normal;
font-weight: 500;
font-size: 14.3091px;
line-height: 21px; 
color: #FFFFFF;
box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
}
.saree-text{

}
.saree-text p{
    font-weight: normal;
    font-size: 11px;
    line-height: 20px; 
    text-align: center;
    letter-spacing: 0.02em; 
    color: #000000;
    margin-top: 100%;
}
.saree-text p span{
display: block;
margin-top: 100%;
}
.saree-image p{

}
.saree-image p span{

}
.saree-right{

}
.saree-right h4{
    font-weight: bold;
font-size: 18px;
line-height: 21px; 
align-items: center;
text-align: center;
letter-spacing: 0.05em; 
color: #000000;
margin-top: 20px;
}
.saree-image svg{

}
.saree-image h3{
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.5px;
    color: #000000;
    transform: rotate(
-90deg
);
    float: right;
    margin-top: -60%;
    position: relative;
    margin-right: -20%;

}
.right-image{
    text-align: center;
}
.right-image img{
    padding: 15px 10%;
    width: 80%;
}
.right-image svg{
    width: 10%;
}
 .right-image p img{
    padding: 0;
    width: auto!important;
    margin-right: 5px!important;
 }
 .right-image p {
     text-align: left;
     font-weight: 300;
font-size: 13px;
line-height: 15px;  
letter-spacing: 0.02em; 
color: #686464;
 }







