.container-fluid{
    width: 95%!important; 
    margin: 0 auto;
}
a{
    text-decoration: none;
}
section {
    /* padding: 50px 0px; */
}
.active, .activate{
    /* color: #fff;  */
    color: #FF3503 !important;
    border-bottom: 1px solid #FF3503 !important;
    border-radius: 0;
        background: transparent;
}
/**************==============
Bannner Section CSS
=============**************/
.banner-area{
    margin-top: 50px;
    background: url('https://storage.googleapis.com/clippr-image-processing/Static/try.png');
    background-size: cover;
    height: 100vh;
    background-position: bottom center;
    transition: .2s ease-in-out;
}
.banner-area2{
    margin-top: 50px;
    background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/landing+page+2.png');
    background-size: cover;
    height: 100vh;
    background-position: bottom center;
    transition: .2s ease-in-out;

}
.drag-image-area{
    padding-top: 5%;
}
.drag-image-area h1{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 42px;
line-height: 45px;
color: #1C1E52;
margin-bottom: 30px;
}
.drag-image-area h1 span{
    display: block;
    font-size: 20px;
    line-height: 45px;
    font-weight: 500;
  
}
.drag-image-area h1 span a{
    line-height: 45px;
    color: #FF3503;
    font-size: 20px;
    text-decoration: none;
}

label.file-drag {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    text-align: -webkit-center;
    padding: 40px 20px;
    cursor: pointer;
 }
 label.file-drag img{
     display: block;
 }

 label.file-drag span{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px; 
    color: #000;
 }

.button {
	background      : #005f95;
	border          : none;
	border-radius   : 3px;
	color           : white;
	display         : inline-block;
	font-size       : 19px;
	font-weight     : bolder;
	letter-spacing  : 0.02em;
	padding         : 10px 20px;
	text-align      : center;
	text-shadow     : 0px 1px 2px rgba(0, 0, 0, 0.75);
	text-decoration : none;
	text-transform  : uppercase;
	transition      : all 0.3s;
}

.btn:hover {
	background : #4499c9;
}

.btn:active {
	background : #49ADE5;
}

input[type="file"] {
	display : none;
}

.file-drag { 
	width: 309px;
height: 218px;
background: #FEFEFE;
border: 1px dashed #000000;
box-sizing: border-box;
box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.15);
border-radius: 23px;
}

.file-drag span {
     display: block;
    font-size: 15px;
}

.file-drag:hover { 
     background: #FFF6F4;	
}
 
.file-progress {
	display : none;
	margin  : 1em auto;
	width   : 100%;
}

.file-upload-btn {
	margin : auto;
}

.file-upload-btn:hover {
	background : #4499c9;
}

.file-upload-form {
	margin : 0;	
   
    margin-bottom: 35px;
  
}

 
 
.drag-image-area ul{
    padding: 0;
}
 .drag-image-area ul li{
    font-size: 16px;
    line-height: 30px;
    /* margin: 0px 8px; */
    list-style: none;
 }
 .drag-image-area ul li img{
    padding-right: 10px; 
 }
 
 
.image-area2{
    /*background: url('../images/Group 5754 (1).png');*/
   
} 
.image-area img{
    width: 100%;
    padding-top: 10%;
} 
 
.file-upload-form:hover + .banner-area{
    background: url('https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/try.png');
}
  
 /***************=================
Stand Out Section CSS
===============***************/ 
 
.subject-stand-out{
    padding: 50px 0px;
    background: #fff;
}
.title{
    
} 
.title h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 45px;
    color: #1C1E52;
    text-align: center;
} 
.title h1 span{
        color: #FF3503;
} 

.subject-tabs ul, .marketplace-tabs ul{
        text-align: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 25px;
}
.subject-tabs {
    
} 
.marketplace-tabs{

}
.subject-tabs ul li, .marketplace-tabs ul li{ 
    padding: 0;
    margin: 5px 25px;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 1px solid transparent;
}
.subject-tabs ul li button, .marketplace-tabs ul li button{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    border-radius: 0!important;
    border-bottom: 1px solid transparent;
}
.subject-tabs ul li button:hover, .marketplace-tabs ul li button:hover {
     color: #FF3503;
    border-bottom: 1px solid #FF3503;
    border-radius: 0;
        background: transparent;
} 
 .tab-contents{
    justify-content: center;
 }

 .slider{
    height: 100%;
    width: 3px;
    background: #fff;
}
.slider  img {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 43%;
    margin-left: -25px;
}


/***************=================
 Extract The Subject Section CSS
===============***************/ 
.extract-the-subject{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);
    padding: 50px 0;
}
.extract-image{
    
} 
.extract-image img{
     width: 100%;
}     
.extract-content .title{
         padding: 3% 18% 0;
} 
.extract-content .title h1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    text-align: left;
} 
 .extract-content p{
    font-weight: 100;
    color: #1C1E52;
} 
.extract-content h6{
        margin-top: 20%;
    margin-bottom: 15px;
    color: #1C1E52;
        line-height: 27px;
        font-weight: 600;
} 
 
 .extract-content h6 span{
       color: #FF3503;
} 
 a.try-button{
    background: #FF3503;
    border-radius: 20px;
    padding: 5px 15px;
    color: #fff;
    display: block;
    width: 150px;
    width: 178px;
    height: 43px;
    text-align: center;
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 35px;
 }
.subject-tabs{
       text-align: center; 
} 
 .marketable-content{
    padding: 5% 0 0;
 }
.marketable-content h6{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
margin-top: 30%;
margin-bottom: 15px;
color: #1C1E52;
line-height: 30px;
}
.marketable-content h6 span{
    color: #FF3503;
}
.marketable-content p{

}

 

.best-useful{
    padding: 50px 0px;
} 
 
.best-useful-col{
        padding: 0 50px;
            margin-bottom: 60px;
} 
.best-useful-single, .best-useful-single2{
    padding: 15px;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-top: 20px;
    width: 420px;
height: 267px;
} 
.best-useful-single img{
     width: 100%;   
} 
.best-content{
    
} 
.best-content h4{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    color: #444;
    margin: 0;
} 
.best-content p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #8B8B8B;
} 
   
 
 
.best-useful-single::after{ 
content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 53, 3, 0.15) 0%, rgba(205, 109, 85, 0.0855) 100%);
    border-radius: 32px; 
    left: 0;
    z-index: -1;
    width: 245px;
    height: 218px;
    bottom: -45px;
}
 
.best-useful-single2::after{ 
    content: '';
        position: absolute;
        background: linear-gradient(180deg, rgba(255, 53, 3, 0.15) 0%, rgba(205, 109, 85, 0.0855) 100%);
        border-radius: 32px; 
        left: auto;
        right: 0;
        z-index: -1;
        width: 245px;
        height: 218px;
        bottom: -45px;
    }
.text-section{
background: linear-gradient(
    90deg
    , rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);
        padding: 50px 0;
    }
.text-content{
    
} 
 .text-content p{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: black;
 }
  .text-section{
      padding-bottom: 30px;
  }



/* Product Sell Section */

.product-sell{
    background: linear-gradient(90deg, rgba(196, 196, 196, 0.15) 3.51%, rgba(243, 48, 0, 0.15) 100%);
    overflow: hidden;
}
  .product-images{ 
    padding-right: 30px;
  }
  .product-images img{
    width: auto;
    margin-top: 10%;
    margin-bottom: 46px;
    margin-right: 15px;
  }
  .product-images img::after{
    content: '';
    position: absolute;
  }
.pro-img2 {
    
}
.pro-img2 img{
    width: auto;
    margin-bottom: 45px;    
}
.pro-img2 img:after{
    content: ''; 
}
.product-sell  p{
    padding: 0px 15px 0 20px;
    width: 50%;
    text-align: left;
    margin: 0 0 0 auto;
 }
 .pro-img2 p{
     margin: 0 auto 0 0;
 }
.product-sell .title{
    margin-bottom: 50px;
}
.progress-bar{
    margin: 0;
    justify-content: flex-end;
}
.progress-bar2{
    margin: 0; 
}
/* .progress-bar-danger{
    width: 5%;
    background: #D10D0D;
    padding: 0;
    margin-bottom: 46px;
    
}
.progress-bar-danger div{
    width: 100%;
    height: 75%;
    background: #f4e8e4;
    -webkit-transition : height 3s ease linear;
    -moz-transition : height 3s ease linear;
      -o-transition : height 3s ease linear;
         transition : height 3s ease linear;
}
.progress-bar2 .progress-bar-danger{
   background: #26840E;
}
.progress-bar2 .progress-bar-danger div{
    width: 100%;
    height: 30%;
    background: #f6e0db;
} */



 
  .progress-bar1, .progress-barrr {
    position: relative;
    /* margin: 0 auto; */
    width: 15%;
    margin-top: 40%;
    /* width: 5%; */
    padding: 0;
    margin-bottom: 46px;
  }
  .progress-bar1{
    position: relative;
    /* margin: 0 auto; */
    width: 5%;
    margin-top: 40%;
    /* width: 5%; */
    padding: 0;
    margin-bottom: 46px;
  }
  .progress-barrr {
    position: relative; 
    width: 5%;
    margin-top: 10%; 
    padding: 0;
    margin-bottom: 46px;
  }
  .progress-bar1 span, .progress-barrr span{
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    background: #f4e9e6;
    
    overflow: hidden;
    transform: rotate(-180deg);
     
    height: 100%;
    background: #f4e8e4;
  }
  
  .progress-bar1 > span:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #D10D0D;
    z-index: 1;
    /* background-size: 50px 50px; */
    overflow: hidden;
  }
  .progress-barrr > span:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #26840E;
    z-index: 1;
    /* background-size: 50px 50px; */
    overflow: hidden;
  }
  .progress-bar1 > span:after, .animate > span > span{ 
    animation: expandHeight 3s linear;
  }
  .progress-barrr > span:after, .animate > span > span{ 
    animation: expandHeight 3s linear;
  }
  
  @keyframes expandHeight {
     0% { height: 0; }
     100% { height: 100%; }
  }
  


   









@-webkit-keyframes fadeInRight {
    from {
        opacity:0;
        -webkit-transform: translatex(10px);
        -moz-transform: translatex(10px);
        -o-transform: translatex(10px);
        transform: translatex(10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeInRight {
    from {
        opacity:0;
        -webkit-transform: translatex(10px);
        -moz-transform: translatex(10px);
        -o-transform: translatex(10px);
        transform: translatex(10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeInRight {
    from {
        opacity:0;
        -webkit-transform: translatex(100px);
        -moz-transform: translatex(100px);
        -o-transform: translatex(100px);
        transform: translatex(100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}

/** fadeInLeft **/

@-webkit-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-100px);
        -moz-transform: translatex(-100px);
        -o-transform: translatex(-100px);
        transform: translatex(-100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
/* .pro-img2 {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -o-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-duration:4s;
    animation-delay: 0s;
}
.product-images{
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -o-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-duration:4s;
    animation-delay: 0s;
} */

.product-sell .title h1{
    text-align: left!important;
    
    /* -webkit-animation-name: fadeInRight;
    -moz-animation-name: fadeInRight;
    -o-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    -o-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-duration:4s;
    animation-delay: 0s; */
}
.mybutton {
    background:#e85f3d!important;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
}












/* @media ===Responsive === */

@media screen and (max-width: 767px) and (min-width: 200px){
    .best-useful-single, .best-useful-single2{ 
        width: 100%;
        height: 186px;
        border-radius: 13px;
}
.best-content p { 
    font-size: 10px;
    line-height: 16px; 
    margin: 0;
}
.best-content h4 { 
    font-size: 12px;
    line-height: 30px;
    margin: 0;
}
.best-useful-single::after { 
    width: 144px;
    height: 130px; 
    left: 15px;
    border-radius: 8px;
    bottom: -30px;
}

.best-useful-col{
    padding: 0 40px; 
}

.best-useful-single2::after { 
    width: 144px;
    height: 130px; 
    right: 15px;
    border-radius: 8px;
    bottom: -30px;
}
.best-useful-single2 img{
    width: 100%;
}

.text-content p { 
    font-size: 10px; 
}
.text-content {
    padding: 0px 25px;
}
.container-fluid {
    max-width: 100%!important;
}
.title h1 { 
    font-size: 18px;
    line-height: 23px;

}
.marketplace-tabs {
    padding: 0;
}
.subject-tabs ul li, .marketplace-tabs ul li {
    padding: 0;
    margin: 5px 7px;
}

.subject-tabs ul li button, .marketplace-tabs ul li button{
    font-size: 12px;
    line-height: 25px;
}
.extract-image {
    order: 2;
}
.extract-content   {
    order: 1;
}

.extract-content .title h1 { 
    font-size: 18px;
    line-height: 45px; 
}
.extract-content .title {
    padding: 0px 0px 0px;
}

.extract-content h6 {
    margin-top: 0;
}
.drag-image-area {
    padding: 0 0px;
}
.drag-image-area h1 { 
    font-size: 23px;
    line-height: 25px;
    margin-bottom: 5px;
}
.drag-image-area h1 span a { 
    font-size: 16px; 
}

 
label.file-drag {
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    padding: 15px;
}
.file-drag {
    width: 80%;
    height: auto; 
}
label.file-drag img { 
    width: 30px;
}
label.file-drag span { 
    font-size: 12px;
}
.drag-image-area h1 span {
    display: block;
    font-size: 12px;
}
.drag-image-area ul li {
    font-size: 10px;
    line-height: 20px;
}
.banner-area {  
    background-repeat: no-repeat;
}
.mybutton{
    width: 45%;
    margin: 0;
    font-size: 11px;
    margin-bottom: 15px;
    /* line-height: 1; */
    padding: 10px 10px;
    border-radius: 5px;
}
.product-images img {
    width: 80%;
    margin-top: 0;
    margin-bottom: 46px;
    margin-right: 0px;
}
.progress-bar1 { 
    margin-top: 40%; 
    padding: 0;
    margin-bottom: 46px;
}
.product-sell p {
    padding: 0 10px;
    width: 50%;
    text-align: left;
    margin: 0 0 0 auto;
    float: right;
    font-size: 12px;
}
.product-images {
    padding-right: 10px;
}
.progress-bar {
    margin: 0;
    justify-content: flex-start;
    width: 50%;
    float: left;
}
.pro-img2 img {
    width: 90%; 
}
.progress-bar2 {
    margin: 0;
    width: 50%;
    float: right;
}
 
.marketable-content {
    padding: 5% 15px 0;
}
.marketable-content h6 {
    
    display: none;
}

a.try-button {
    background: #E85F3D;
    margin: 0 auto;
}
p{
    font-size: 12px;
}
.footer-links {
    margin-bottom: 15px;
}


}


@media (min-width: 1400px){
.product-sell p { 
    width: 42%;
}
.progress-bar1 { 
    margin-top: 35%;
}



}


