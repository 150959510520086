.drop-box-text {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(44, 40, 40, 0.7);
    top: 0;
}

.slider-button {
    display: grid;
    grid-template-columns: 100%;
    font-family: Arial, Helvetica, sans-serif;
    grid-gap: 23px;
    padding: 10px 26px;
}

.toggle1 {
    position: relative;
    color: #f39e53;
    font-size: 13px;
}

.toggle1 input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.greyscale {
    position: absolute;
    width: 100%;
}

.slider1 {
    position: relative;
    display: block;
    cursor: pointer;
    background: #d64d4d;
    margin-left: 200px;
    box-shadow: 0px 4px 4px rgb(255, 255, 255);
    border-radius: 20px;
    transition: 0.2;
    width: 50px;
    height: 28px;
}

.slider1::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background: rgb(255, 255, 255);
    transition: 0.2s;
    border-radius: 50%;
    left: 3px;
    top: 3px
}

input:checked+.slider1 {
    background-color: #78f353;
}

input:checked+.slider1::before {
    transform: translateX(21.5px);
}

.drop-box-text div {
    text-align: center;
    top: 40%;
    position: relative;
    font-size: 50px;
    color: #fff;
}

.screen-two-main-wrapper {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 133px rgb(0 0 0 / 15%);
    margin: 100px 50px;
}

.blured {
    -webkit-filter: blur(4px);
}

.left-section-with-image {
    width: 780.57px;
    min-height: 520px;
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
    border-radius: 10px;
    padding: 7.87px 21.87px 10px;
}

.left-section-with-image-2 {
    width: 100%;
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
    border-radius: 10px;
    padding: 7.87px 21.87px 10px;
}

.blured {
    -webkit-filter: blur(4px);
}

.angle {}

.right-top-credits {
    text-align: right;
    padding: 0;
    margin-bottom: 10px;
}

.right-top-credits button {
    padding: 5px 10px;
    width: auto;
    background: linear-gradient( 180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.24) 52.08%, rgba(255, 255, 255, 0) 52.09%, rgba(255, 255, 255, 0.29) 100%), linear-gradient( 180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 51.56%), #ff7700;
    background-blend-mode: soft-light, normal, normal;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), inset 0px -2px 6px rgba(44, 0, 0, 0.35);
    border-radius: 32px;
}

.right-top-credits img {
    width: 25px !important;
}

.add-credit {
    color: white !important;
}

.add-credit img {}

.on-off-row {
    padding: 15px 10px 0;
}

.angle p {
    margin: 0;
    color: #ff7700;
    font-size: 17px;
}

.left-section-with-image {
    width: 780.57px;
    min-height: 520px;
    background: #ffffff;
    /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
    border-radius: 10px;
    padding: 21.87px 38.87px 10px;
}

.with-image-inner {
    width: 100%;
    height: 90%;
    width: 780.57px;
    height: 441px;
    border: 0.6px solid #77797b;
    cursor: pointer;
    /* object-fit:fill; */
}

.with-image-inner {
    width: 100%;
    height: 90%;
    width: 705.57px;
    height: 397px;
    border: 0.6px solid #77797b;
    cursor: pointer;
    /* object-fit:fill; */
    /* object-fit: contain; */
    position: relative;
    overflow: hidden;
}

.with-image-inner>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}

.resizable {
    width: 120px;
    /* border-radius: 0.75rem;
  padding: 20px;
  margin: 1rem;
  background-color: #29e;
  color: white;
  font-size: 20px;
  font-family: sans-serif; */
    overflow: hidden;
    touch-action: none;
    /* This makes things *much* easier */
    object-fit: fill !important;
    box-sizing: border-box;
    position: absolute;
    opacity: 0.5;
    border-bottom: 10px solid #ff7700;
    z-index: 2;
}

.drag-button {
    width: 50px !important;
    height: auto;
    transform: rotate(-90deg);
    /* overflow: hidden; */
    margin-top: -27px;
}

.with-image-inner-2 {
    width: 100%;
    /* height: 90%; */
    /* width: 783.96px; */
    /* height: 56% ; */
    border: 0.6px solid #77797b;
    cursor: pointer;
    /* object-fit:fill; */
}

.btn-sec button {
    width: 120px;
    height: 44px;
    border-radius: 5px;
}

.btn-sec-2 button {
    width: 120px;
    height: 44px;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #ff7700 !important;
    text-align: center !important;
    color: #ff7700 !important;
}

.btn-sec {
    text-align: center;
    padding-top: 10px;
    /* border-top: 1px solid #ebeaea;
  margin-top: 15px; */
}

.btn-sec-2 {
    text-align: center;
    padding-top: 20px;
    margin-top: 15px;
}

.checkbox-section {
    text-align: center;
    /* padding-top: 20px;
  border-top: 1px solid #ebeaea;
  margin-top: 15px; */
}

.checkbox-section label {
    font-size: 14px;
    color: #232121;
    font-weight: 600;
    font-family: poppins;
}

.with-image-inner-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.original_image {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: center;
}

.toggle-section {
    margin-top: 15px !important;
    justify-content: center;
}


/* .toggle-section button{
  margin: 0% 1% 0 15%;
  padding: 5px 15px;
  font-size: 14px;
} */

.right-section-with-image {
    /* height: 520px; */
    padding: 0 0 24.6px;
    /* margin-left: 40px; */
    background: #ffffff;
    /* box-shadow: 0px 0px 21px rgb(119 121 123 / 50%); */
    border-radius: 0px;
    width: 100%;
    border-left: 1px solid #ebeaea;
}

.sample-button {
    font-size: 16px !important;
    background: none !important;
    color: black !important;
    border: 3px solid #ff7700 !important;
    box-shadow: 0px 0px 21px rgb(119 121 123 / 50%) !important;
    margin-top: -20px;
    margin-bottom: 10px;
}

.sample-button:hover {
    color: black;
}

.choose-angle {
    position: absolute;
    width: 308.95px;
    height: 115.27px;
    background: #ffffff;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    padding: 18.34px 29.59px 13.98px 29.59px;
    display: flex;
    grid-gap: 31.88px !important;
    border-bottom: none;
}

.angle-selected {
    position: absolute;
    width: 308.95px;
    height: 115.27px;
    background: #ffffff;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    padding: 18.34px 29.59px 13.98px 29.59px;
    display: flex;
    grid-gap: 31.88px !important;
    /* border-bottom: none; */
}

.choose-angle,
.angle-selected p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #77797b;
    margin-top: 5px;
}

.choose-angle p:hover,
.angle-selected p:hover {
    color: #77797b;
}

.active {
    background-color: transparent;
    border: unset;
    width: 62px;
    height: 62px;
    padding: 0px;
    opacity: 1;
    border: unset !important;
}

.inactive {
    background-color: transparent;
    border: unset;
    width: 62px;
    height: 62px;
    padding: 0px;
    opacity: 0.3;
}

button:focus {
    /* border:unset !important; */
    outline: none !important;
}

.choose-angle img,
.angle-selected img {
    width: 100%;
    height: 45px;
    object-fit: contain;
}

.choose-angle-text {
    position: absolute;
    top: 220px;
    left: 803.81px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
    z-index: 100;
    display: flex;
    background-color: white;
    padding-left: 5px;
}

.choose-angle-text p {
    background-color: white;
    margin: 0px;
}

.tooltip-text {
    padding-bottom: 10px;
}

.tooltip-text p {
    background-color: #0579df;
    color: white !important;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}

.tooltip-show {
    width: 268px !important;
    align-items: center !important;
    padding: 16px 24px 20px !important;
}

.upload-button button {
    width: 140px;
    height: 40px;
    margin-left: 340px;
    margin-top: 30px;
    background: #0579df;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}

.upload-button button:hover {
    color: #ffffff;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.right-mid-section {
    margin-top: 0px !important;
}

.logo-text-sec {
    position: absolute;
    height: 21px;
    left: 803.91px;
    top: 333.76px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
    background-color: white;
    display: flex;
    padding: 0px 5px;
}

.righ-mid-setion-content {
    display: flex;
    margin: 13px auto 10px 25px;
    text-align: center;
    opacity: 0.5;
    justify-content: space-around;
}

.white-bg {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.position-text {
    position: absolute;
    left: 1160.91px;
    top: 333.76px;
    background-color: white;
    padding: 0px 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
}

.angle-sec {
    background-color: #fbfbfb;
    border-bottom: 1px solid #ddd;
}

.right-section-background-section {
    width: 95%;
    /* height: 120px; */
    background: #ffffff;
    border: 0.4px solid #707070;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 10px;
    /* overflow: auto;
    overflow-x: hidden; */
    margin: 0 auto;
}

.background-text {
    position: absolute;
    top: 335px;
    left: 803.91px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df;
    background: white;
    padding: 0px 5px;
}

.background-text p {
    margin-bottom: 0px;
}

.overlay_edit {
    position: relative !important;
}

.original_image {
    position: relative !important;
    /* object-fit: contain !important; */
}

.edited_image {
    position: relative;
    object-fit: contain;
    width: 783.96px;
    height: 441px;
    border: 0.6px solid #77797b;
    cursor: pointer;
}

.car-text h1 {
    font-size: 30px;
}

.got-btn {
    background-color: #ff7700;
    border-radius: 50px;
    color: #ffffff;
    padding: 0;
    width: 140px;
    height: 40px;
    margin: 20px 0;
}

.overlay-img {
    /* object-fit: scale-down !important; */
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black !important;
    z-index: 91;
    object-fit: contain !important;
    height: 60% !important;
    margin-top: 10%;
}

.lower-section-with-overlay {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%); */
    background-color: #fff;
}

.lower-section-with-overlay-2 {
    display: grid;
    flex-direction: row;
    width: 100%;
    position: relative;
    grid-template-columns: 59% 40%;
}

.overlay-box {
    display: flex;
    flex-direction: row;
    /* border-bottom: 1px solid #ddd; */
    padding: 5px 0;
    /* border-top: 1px solid #ddd;  */
}

.backgrounds-grid {
    display: grid;
    grid-template-columns: 18.5% 18.5% 18.5% 18.5% 18.5%;
    grid-gap: 9px;
}

.backgrounds-grid-custom {
    display: grid;
    grid-template-columns: 18.5% 18.5% 18.5% 18.5% 18.5%;
    grid-gap: 7px;
}

.backgrounds-grid-below {
    display: grid;
    /* justify-content: center; */
    grid-template-columns: 50% 50%;
    grid-gap: 9px;
}

.below-button {
    background: unset !important;
    /* color: #FF7700; */
    border: 2px solid #ff7700 !important;
    height: fit-content !important;
    margin-top: 2% !important;
    border-radius: 30px !important;
}

.background-list {
    padding: 0px 10px 15px;
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
}

.background-list-2 {
    padding: 0;
    border-top: 0px solid #ebeaea;
    margin-top: 0px;
}

.back-credit img {
    width: auto !important;
    height: auto !important;
    padding-left: 4px;
    border: 0 !important;
}

.upload-sec {
    padding: 35px 0;
}

.upload-sec button img {
    width: 23px;
    margin-right: 10px;
}

.active-background-sec img {
    width: 100%;
    height: 46px;
    border-radius: 5px;
}

.bg-below-sec {
    justify-content: center;
    margin: 0;
    padding: 5px 60px 0;
}

.inactive-background-sec img {
    width: 100%;
    height: 46px;
    cursor: pointer;
    border-radius: 5px;
    border: 0.4px solid #333;
}

.select-img {
    height: 65%;
    margin-left: 10px;
    border-radius: 2px;
}

.below-bg-apply {
    border-radius: 22px !important;
    background: unset;
    color: rgb(255, 119, 0) !important;
    border: 1px solid #ff7700;
    margin-top: 4%;
    padding: 0;
    width: 78px;
    height: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ff7700;
}

.below-bg-apply :hover {
    border-radius: 30px !important;
    background: unset;
    color: rgb(255, 119, 0) !important;
    border: 2px solid #ff7700;
    margin-top: 4%;
}

.bg-complete-sec {
    /* display: flex; */
    justify-content: center;
    /* flex-direction: column; */
    text-align: center;
    margin-top: 14px;
    border-top: 1px solid rgba(222, 222, 222, 0.85);
    padding-top: 5px;
}

.bg-complete-sec h4 {
    font-size: 12px;
    color: #232121;
    font-weight: 400;
}

.select-sec {
    /* display: flex; */
    justify-content: center;
    margin-top: 3%;
}

.select-sec span {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.active-background-sec-below {
    padding: 5px 5px 0;
    cursor: pointer;
}

.active-background-sec-below img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.inactive-background-sec-below img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 0;
}

.overlay_edit {
    position: relative !important;
}

.overlay-img {
    /* object-fit: scale-down !important; */
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black !important;
    z-index: 91;
    object-fit: contain !important;
    height: 60% !important;
    margin-top: 10%;
}

.lower-section-with-overlay {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.continue-btn {
    display: flex;
    justify-content: center;
    padding: 2%;
}

.background-sec {
    /* margin-top: 18px; */
}

.download-popup {
    border: 1px solid #ddd;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-top: 20px;
}

.download-popup button {
    border: 1px solid transparent;
    margin-bottom: 35px;
    background: #fff;
    border-radius: 0;
    color: #000;
    padding: 15px 95px;
    text-align: left;
}

.cross-btn {
    text-align: right;
    padding: 0 0 10px;
}

.download-popup button:nth-of-type(2) {
    margin-bottom: 15px;
}

.download-popup ul {
    padding-left: 15%;
    list-style: none;
    margin: 0 0 5px;
}

.download-popup ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
}

.download-popup p {
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #232121;
    padding-left: 15%;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
}

.download-popup p span {
    color: #ff7700;
}

.download-popup h6 {
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: #232121;
    margin: 0 0 10px;
}

.border-1 {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
}

.download-popup input {
    margin-right: 10%;
    border: 0;
    outline: 0;
}

.download-popup input:focus {
    border: 0;
    outline: 0;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ec761b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #ddd;
}

.download-btn-hd {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 250px !important;
    text-align: center !important;
    padding: 10px 28px !important;
    font-size: 17px;
}

.request-demo-1 {
    background: #ff7700 !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 153px !important;
    text-align: center;
    padding: 10px 28px !important;
    font-size: 17px;
}

.img-overlay {
    position: absolute;
    /* background: linear-gradient(
    0deg,
    rgba(62, 60, 60, 0.7),
    rgba(62, 60, 60, 0.7)
  ); */
    height: 100%;
    bottom: 0%;
    width: 100%;
    opacity: 1;
    transition: 0.5s ease;
}

.more-btn-overlay {
    position: absolute;
    top: 25%;
    left: 1%;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    width: 90%;
    line-height: initial;
}

.more-btn-overlay-2 {
    position: absolute;
    top: 28%;
    left: 8%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.overlay-pos {
    position: relative;
}

.cross-img {
    width: auto !important;
    cursor: pointer;
}

.request-demo-1:hover {
    background-color: #ff7700 !important;
    border: 0 !important;
}

.download-popup p button {
    background: #ff7700 !important;
    color: #fff !important;
    padding: 7px 15px;
}

.download-popup button.activate,
.download-popup button:hover {
    background: rgba(5, 121, 223, 0.02);
    border: 1px solid #0579df;
    box-sizing: border-box;
    border-radius: 10px;
}

.back-name {
    font-size: 10px;
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 0 !important;
    line-height: initial;
}

.overlay-toggle {
    display: block;
    margin-top: 4%;
}

.active-toggle-btn {}

.car-overlay-cat {
    padding: 0 0 0 10px;
}

.overlays-click-btn {
    /* display: flex; */
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 5px;
    margin: 5px 10px 5px;
    border: 2px solid transparent;
}

.overlays-click-btn span {
    color: #000;
}

.overlays-click-btn:hover span {
    color: #ff7700;
}

.back-credit {
    text-align: end;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 0;
    margin-bottom: 0 !important;
}

.logo-disp {
    width: 86px;
    background-color: #e0e0e0;
    height: 34px;
}

.logo-upload {
    width: 157px;
    height: 44px;
    border-radius: 5px;
}

.logo-sec {
    padding: 0px 13px 23px;
    border-bottom: 1px solid #ebeaea;
    margin: 22px 0;
}

.logo-sec span {
    color: orange;
}

.background-list h6 {
    color: #232121;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}

.overlays-click-btn img {
    /* height: 120px; */
    width: 90%;
    object-fit: contain;
    padding: 0;
}

.overlay-toggle {
    display: flex;
    margin-top: 0%;
    padding: 0;
}

.credit-text {
    font-size: 14px;
    font-family: poppins;
    color: #232121;
    text-align: center;
    /* display: flex;
  justify-content:flex-end; */
}

.credit-text span {
    color: #ff7700;
}

.credit-text img {
    margin-right: 10px;
    width: 40px;
}

.active-toggle-btn {}


/* .overlays-click-btn {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  padding: 5px;
  margin-top: 10px;
} */

.car-try-btns {
    display: inline-block;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0px;
    padding: 5px 5px 0;
    width: 33%;
    margin: 0;
    text-align: center;
    font-size: 10px;
    height: 100%;
    width: 50%;
}

.car-active-btns {
    border-bottom: #ff7700;
}

.car-try-btns img {
    height: auto;
    width: 70%;
    object-fit: contain;
    padding: 0;
    margin: 0 auto;
    object-fit: contain;
    padding: 0;
    margin: 0 auto;
    /* float: left; */
    display: block;
}

.car-try-btns span {
    display: block;
    font-size: 14px;
    vertical-align: middle;
    width: 100%;
}

.all-overlays {
    display: flex;
    justify-content: center;
    padding: 0;
}

.car_overlay_cat {
    display: flex;
    flex-direction: column;
}

.overlay-section {
    padding: 0;
}

.overlay-toggle p {
    font-size: 14px;
    margin: 0;
}

.active {
    /* border:2px solid #FF7700!important; */
}

.overlay-car-btn {
    padding: 0px 5px;
    background-color: rgb(255, 255, 255);
    color: rgb(10, 10, 10);
    text-align: center;
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    box-shadow: 0 2px 5px 0 #ddd;
    margin: 15px 0 10px;
}

.overlays-click-btn:hover {
    border: 2px solid #ff7700;
    color: #ff7700;
}

.overlay-toggle label {
    margin: 0;
}

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #fae6d2;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #f1923e;
}

.overlay-car-btn:hover,
.overlays-click-btn:focus {
    border-bottom: 3px solid #ff7700;
    color: #ff7700;
}

.backgrounds {
    overflow-y: scroll;
    display: flex;
    flex-flow: row wrap;
    height: 150px;
    margin-left: auto;
    white-space: nowrap;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center !important;
    margin: 10px 0;
    padding: 4px;
}

.backgrounds li {
    width: 20%;
    padding: 0px 5px;
}

.backgrounds li button {
    width: 100%;
    display: block;
    padding: 0 !important;
    height: 60px !important;
}

.inactive-background-images {
    padding: 0 10px 0 0 !important;
    margin: 0 0 !important;
    display: inline-block;
    width: 90px;
    height: fit-content;
}

.inactive-background-images p.bg_img-texts,
.active-background-images p.bg_img-texts {
    font-size: 10px;
    width: 100%;
    margin: 0 auto !important;
    text-align: center !important;
    white-space: normal;
}

.bg_img-texts {
    font-size: 71px;
    margin-bottom: 10px !important;
    color: #ff7700;
    margin-left: 14px !important;
    /* width:  65px; */
}

.active-background-images {
    height: 70px !important;
    padding: 0 !important;
    margin: 0 10px 0 0px !important;
}

.active-background-images img {
    border: 2px solid black;
    transform: scale(1.1);
    margin: 5px 0 0 0px !important;
}

.credit-para {
    position: relative;
    /*top: -18%; */
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: -18%;
}

.inactive-background-images img,
.active-background-images img {
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
    height: 65px;
    width: 65px;
}

.image-credit p {
    margin-top: 0px;
    width: 65px;
    height: 16.6px;
}

.middle {
    transition: 0.5s ease;
    opacity: 0;
    position: relative;
    top: -60%;
    left: -10%;
    text-align: center;
    cursor: pointer;
}

.text {
    background: rgba(0, 0, 0, 0.7) !important;
    border-radius: 0px 0px 5px 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    color: #ffffff !important;
    text-align: center;
    z-index: 9999;
    padding-top: 5px;
    padding: 16px 32px;
}

.inactive-background-images:hover img,
.active-background-images:hover img {
    opacity: 0.6;
}

.inactive-background-images:hover .middle,
.active-background-images:hover .middle {
    opacity: 1;
}

.right-bottom-section {
    padding: 18.94px 15px 0;
    text-align: center;
}

.right-bottom-section p:nth-child(1) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    color: #0579df !important;
}

.right-bottom-section p:nth-child(2) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #000000;
    text-align: center;
    margin: 0;
}

.right-bottom-section span {
    color: #0579df !important;
}

.separation-border {
    position: absolute;
    width: 47.31px;
    height: 0px;
    left: 1029.53px;
    top: 660.72px;
    border: 0.4px solid #707070;
    transform: rotate(90deg);
}

.bottom-apply-area {
    padding-top: 5px;
    text-align: left;
}

.overlay-vertical {
    padding: 0;
    height: 225px;
    overflow-y: scroll;
}

.bottom-apply-area button {
    /* position: absolute; */
    width: 128.85px;
    height: 40px;
    left: 1091.92px;
    top: 634.63px;
    background: #ff7700;
    border-radius: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}

button:hover {
    color: #ffffff;
}

.bottom-apply-area p:nth-child(2) {
    position: absolute;
    left: 1111.84px;
    top: 679.76px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 12px;
    color: #4f4444;
    text-align: center;
}

.bulk-pricing {
    position: absolute;
    width: 221px;
    height: 19px;
    left: 917.59px;
    top: 750.81px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #77797b;
    text-decoration: none;
}

.bulk-pricing:hover {
    text-decoration: none;
    color: #77797b;
}

.bulk-pricing span {
    color: #0579df;
}

.spinner {
    width: 80px;
    height: 80px;
    position: relative;
    left: 40%;
    top: 40%;
}

.dealership-logo-section {
    width: 100%;
    /* border: 1px solid grey;
  margin: 1px auto 20px; */
    border-radius: 10px;
}

.center-title {
    text-align: center;
    margin-top: -10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #707070;
    display: block;
    width: 100%;
    margin-bottom: 0;
}

.center-title strong {
    background: #fff;
    padding: 5px 10px;
}

.center-title2 {
    text-align: center;
    margin-top: -10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #707070;
    display: block;
    width: 100%;
    margin-bottom: 0;
    margin-bottom: -10px;
}

.center-title2 strong {
    background: #fff;
    padding: 5px 10px;
}

input[type='checkbox']:checked {
    background: #ddd;
}

.pricing-plan-area {
    border-right: 2px solid #707070a1;
}

.right-mid-section p.pricing-plan {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0579df !important;
    text-align: center;
    margin: 5px 0;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}

.wall-cross {
    position: absolute !important;
    right: -7%;
    top: -9%;
    margin: 0;
    cursor: pointer;
    height: 22px !important;
    width: 24% !important;
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px #ddd;
    border-radius: 80px;
    height: 25px;
    width: 25px;
    border: 0 !important;
}

.right-mid-section p.available-cre {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
}

.image-box-area {
    height: 100%;
    padding: 0 10px;
    margin: 0 auto;
    justify-content: center;
}

.image-box-area video {
    width: 101.9%;
}


/* Animation */


/* @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
  }
  
  .animatedFadeInUp {
    opacity: 0;
  }
  
  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
   */

.box-text1,
.box-text2,
.box-text3,
.box-text4,
.box-text5,
.box-text6 {
    padding: 0 10px;
    padding-top: 48%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    margin: 0;
    transition: 0.5s ease-in-out;
    /* -webkit-animation: fadeInOut 4s linear forwards;
    animation: fadeInOut 4s linear forwards; */
    text-align: left;
    width: 65%;
    height: 30rem;
}

.box-text1 span,
.box-text2 span,
.box-text3 span,
.box-text4 span,
.box-text5 span,
.box-text6 span {
    color: #ff7700;
}

.bg-right-top {
    display: flex;
    /* height: 21%; */
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 5px; */
    padding: 0 0 15px;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
}

.active-visual-selection {
    border-top: 2px solid #ff7700;
    color: #ff7700;
    background: #fff;
    padding: 10px;
}

.inactive-visual-selection {
    /* border-bottom: 2px solid #FF7700; */
    color: #000;
    padding: 10px;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 0px 0px 5px 5px;
}

.img-floor {
    position: relative;
    width: 65px !important;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
    /* margin-left: 8%; */
    margin: 0 auto;
}

.right-top-selection {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0px;
    margin: 0px auto 7px;
    justify-content: center;
    text-align: center;
    /* border-top: 2px solid #e0e0e0; */
}

.img-floor img {
    width: 100%;
    height: auto;
}

.backdrop {
    padding-top: 3%;
    padding-left: 2%;
    font-size: 100%;
    font-weight: 500;
    padding-bottom: 2%;
}

@keyframes topFadeOut {
    0% {
        position: absolute;
        top: -3rem;
        opacity: 0;
    }
    75% {
        position: absolute;
        top: 25%;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bottomFadeOut {
    0% {
        bottom: -5rem;
        opacity: 0;
    }
    75% {
        bottom: 25%;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* @keyframes topFadeOutVertical {
    0% {
      position: absolute;
      top: -3rem;
      opacity: 0;
    }
  
    75% {
      position: absolute;
      top: 45%;
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  } */


/* @keyframes rightFadeInOut {
    0% {
      position: absolute;
      right: -3rem;
      opacity: 0;
    }
  
    75% {
      position: absolute;
      right: 10rem;
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      right: 10rem;
    }
  } */

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    100% {
        opacity: 0%;
    }
}

.box-text1 {
    animation-name: bottomFadeOut;
    animation-delay: 0s;
    animation-duration: 5s;
}

.box-text2 {
    animation-name: bottomFadeOut;
    animation-delay: 0s;
    animation-duration: 5s;
}

.box-text3 {
    animation-name: bottomFadeOut;
    animation-delay: 0s;
    animation-duration: 5s;
}

.box-text4 {
    animation-name: bottomFadeOut;
    animation-delay: 10s;
    animation-duration: 2s;
}

.box-text5 {
    animation-name: bottomFadeOut;
    animation-delay: 13s;
    animation-duration: 2s;
}

.box-img {
    width: 35% !important;
    height: auto !important;
}

.bottom-contact-badge {
    background: #dee2e6;
    padding: 5px 15px;
    border-radius: 0px 0px 11px 10px;
    margin: 0px 50px;
    margin-bottom: 50px;
}

.bottom-contact-badge p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: max-content;
    padding: 0px 15px;
    display: inline-block;
    margin: 0;
}

.bottom-contact-badge p span {
    color: #ff7700;
}

.bottom-contact-badge ul {
    list-style-type: none;
    display: inline-block;
    padding: 0;
    vertical-align: text-bottom;
    margin: 0;
}

.bottom-contact-badge ul li {
    display: inline-block;
    padding: 0 10px 0 0;
}


/* footer */

.footer {
    background: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/light+CONTACT+10+1.png);
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 60px 0px 0px;
    color: #fff;
}

.footer-left-text {}

.footer-left-text h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 57px;
    margin-top: 18%;
    text-align: center;
}

.footer-left-text h2 span {
    border-bottom: 2px solid #fff;
}

.footer-left-text h2 span:after {
    content: '';
}

.footer-right-text {
    padding: 5% 15px 0;
}

.footer-right-text ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
}

.footer-right-text ul li {
    margin: 15px 20px;
    background: linear-gradient( 180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-radius: 50%;
    width: 85px;
    text-align: center;
    line-height: 80px;
}

.footer-right-text ul li img {
    padding: 15px;
}

.footer-right-text p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    font-weight: 400;
}


/* //footer */

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.box-text {
    opacity: 0;
    -webkit-animation: fadeInUp 2s ease-in-out 0s forwards;
    animation: fadeInUp 2s ease-in-out 0s forwards;
}

.box-text {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.email {
    position: relative !important;
    top: 80px !important;
    left: 25% !important;
    z-index: 999 !important;
    height: 0px !important;
}

.email form {
    background: rgba(98, 98, 98, 0.7);
    max-width: 415px;
    border-radius: 16px;
    text-align: center;
    align-items: center;
    padding: 20px;
}

.email img {
    width: 100% !important;
}

.email label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.email input {
    height: 48px;
    margin-bottom: 40px;
}

.email button {
    width: 124px;
    height: 35px;
    left: 399px;
    top: 651px;
    background: #ffffff;
    border-radius: 9px;
    color: #616161;
}

.load-video {
    width: 100%;
    height: 461px;
}

.video-text-header h6 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.perfect-output {
    margin: 70px 0 50px;
    /* box-shadow: 0px 1px 20px rgb(0 0 0 / 20%);
  background-color: #fff; */
    background: #fff;
}

.top-bar {
    padding: 10px 0px;
    padding: 15px 0px 10px;
}

.video-sec {
    margin: 100px 0 50px;
}

.heading h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1.5px;
    padding: 0 0 10px;
    color: #232121;
}

.heading h3 span {
    color: #ff7700;
    padding: 0 0 7px;
}

.heading h3 .border-1 {
    color: #232121;
    border-bottom: 2px solid #ff7700;
}

.perfect-output-images {
    text-align: center;
    padding: 20px 15px 0;
}

.perfect-output-images img {
    width: 100%;
    filter: drop-shadow(-4px 4px 7px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    cursor: pointer;
}

.perfect-output-images p {
    text-align: center;
    margin-top: 10px;
    color: #232121;
}

.perfect-output-images p span a {
    font-style: normal;
    color: #ff7700;
    margin-right: 20px;
    background: #fff;
    border: 1px solid #ff7700;
    box-sizing: border-box;
    border-radius: 20px;
}

.perfect-output-images p a {
    background: #ff7700;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    padding: 5px 20px;
    border: 1px solid #ff7700;
    box-sizing: border-box;
    border-radius: 20px;
}

.bg-try {
    background: url(https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/images/bg1.png);
    background-position: top right;
    background-repeat: no-repeat;
}

.floor-output-box {
    box-shadow: -1px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    background-color: #fff;
    padding: 30px 20px;
    margin-top: 20px;
}

.custom-container {
    max-width: 88%;
    margin: auto;
}

.try-again h6 {
    text-align: center;
    font-size: 16px;
    color: #000;
    font-family: poppins;
    font-weight: 400;
}

.try-again span {
    cursor: pointer;
    color: #ff7700;
}

.try-again {
    margin-top: 30px;
}

.try-again div {
    display: contents !important;
}

.perfect-output-images button {
    background-color: transparent !important;
    padding: 0 !important;
    color: transparent !important;
}

.car-try-inner {
    background: #fbfbfb;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.overlay-box-mob {
    display: none;
}

.inactive-background-sec {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #ddd;
    /* padding: 3px; */
    /* border: 2px solid #333; */
    transform: scale(1);
    cursor: pointer;
    /* height: 110px; */
    width: auto;
}

.inactive-background-sec span {
    font-size: 13px;
}

.active-background-sec {
    box-shadow: 0 2px 5px 0 #ddd;
    padding: 3px;
    border: 2px solid #333;
    transform: scale(1);
    cursor: pointer;
}

.active-background-sec span {
    font-size: 13px;
}

.inactive-walls-sec {
    padding: 5px;
    cursor: pointer;
    transform: scale(1);
    cursor: pointer;
    /* height: 85px; */
    width: auto;
}

.inactive-walls-sec span {
    font-size: 13px;
}

.active-walls-sec {
    /* box-shadow: 0 2px 5px 0 #ddd; */
    padding: 3px;
    border: 2px solid #ff7700;
    transform: scale(1);
    cursor: pointer;
    /* height: 110px; */
    width: auto;
}

.active-walls-sec span {
    font-size: 13px;
}

.active-walls-sec img {
    width: 100%;
    height: auto;
}

.inactive-walls-sec img {
    width: 100%;
    height: auto;
    border: 0.4px solid #333;
}

.inactive-walls-sec {
    padding: 3px;
    border: 2px solid transparent;
    box-shadow: 0 2px 5px 0 #ddd;
}

.custom {
    font-size: 13px;
    font-weight: 500;
    /* margin-left: 14%; */
}

.cback {
    display: none;
}

.bcredit {
    display: none;
}

.mob-image img {
    width: 100%;
    margin-top: 10%;
}

.mob-image .mac-gif {
    position: absolute;
    width: 56.1%;
    right: 10.2%;
    top: 6%;
}

.cars-demo {
    /* height: 580px; */
    text-align: center;
}

.cars-calender {}

.cars-calender h4 {
    text-align: center;
    width: 100%;
    color: #ff7700;
    margin-bottom: -20px;
}

.apply-below-btn {
    width: 100%;
    text-align: center;
    padding: 5px 0px;
}

.output-apply-btn {
    text-align: center;
    padding: 5px 0px;
}


/* Custom Walls */

.custom-background-list {
    padding: 20px 10px 20px 35px;
}

.custom-background-list:before,
.adjust-height-area:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 1px dashed #ff7700;
}

.custom-wall {}

.custom-wall p,
.adjust-height p {
    margin: 0;
}

.adjust-height p {
    padding: 5px 0;
    font-size: 16px;
}

.custom-wall p span {
    background: #ff7700;
    color: #fff;
    border-radius: 50%;
    padding: 5px 13px;
    margin-right: 10px;
}

.adjust-height p span {
    background: #e5e5e5;
    color: #fff;
    border-radius: 50%;
    padding: 5px 12px;
    margin-right: 10px;
}

.custom-walls-area {
    padding-left: 10px;
}

.adjust-height-area {
    padding: 5px 0px;
    position: relative;
}

.adjust-height-area ul {
    padding: 5px 10%;
    list-style: none;
    text-align: center;
    display: flex;
}

.adjust-height-area ul li {
    text-align: center;
    width: 35%;
    padding: 10px;
    margin: 0 auto;
}

.adjust-height-area ul li button {
    background: rgba(235 215 198 / 0%) !important;
    border: 1px solid #000000 !important;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: auto;
    border-radius: 0;
}

.adjust-height-area ul li button.active,
.adjust-height-area ul li button:hover {
    background: rgb(235 215 198 / 0%) !important;
    border: 1px solid #ff7700 !important;
}

.adjust-height-area ul li h6 {
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    margin: 0;
}

.adjust-height-area ul li img {
    width: 100%;
    height: 115px;
    object-fit: contain;
}

.backgrounds-grid-custom .inactive-background-sec img {
    height: auto;
    border: 0;
}

.favroute-add {
    margin: 0;
}

.favroute-add img {
    width: 25%;
}

.favroute-add span {
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #232121;
}

.fav-icon {
    position: absolute;
    top: 6px;
    right: 6px;
    background: #ddddddd9;
    backdrop-filter: blur(4px);
    border-radius: 0px 5px;
    padding: 0px 5px;
}

.fav-icon img {
    height: 12px;
    width: 12px;
    border: 0;
}

.white-icon {}

.hoverable-icon {
    display: none;
}

.active-background-sec:hover .white-icon,
.inactive-background-sec:hover .white-icon {
    display: none;
}

.active-background-sec:hover .hoverable-icon,
.inactive-background-sec:hover .hoverable-icon {
    display: inline-block;
}

.active-background-sec .fav-icon {
    top: 3px;
    right: 3px;
}

.more-btn-overlay span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ff7700;
    display: block;
    text-align: center;
}

.colored {
    color: #ff7700;
}

.background-list h6 span {
    text-decoration: underline;
}

.more-box {
    box-shadow: 0 0 0 !important;
}

.more-box img {
    border: 0 !important;
    margin-top: 10%;
    height: auto;
}

.crop-features {}

.crop-options {
    text-align: center;
    margin: 0px 0;
}

.crop-options button {
    border: 1.5px dashed #444444;
    background: #fff;
    color: #444444;
    margin: 0 10px;
}

.crop-button {
    text-align: center;
    margin: 10px 0;
}

.crop-button button {
    margin: 0px 10px;
    text-transform: capitalize;
}

.crop-button button.cancel {
    background: #ffffff;
    border: 1.5px solid #ff7700;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ff7700;
}

.crop-active {
    color: #ff7700 !important;
    border-color: #ff7700 !important;
}

.original_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.with-image-inner .cropreact div img,
.cropreact,
.cropreact div {
    object-fit: contain;
}

.original-image-div {
    width: 100%;
    height: 100%;
}

.cropper-image {
    width: auto !important;
    height: auto !important;
}


/* History Section Slider  */

.history-area {}

.history-slider {}

.history-slider-title {}

.history-slider-title h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #232121;
}

.history-slider-title h6 span {
    float: right;
}

.history-slider-title h6 span a {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FF9100;
}

.history-slide-single {
    padding: 15px;
}

.history-slide {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 15px;
    /* width: 90%!important;
  margin: 0px 5%!important; */
    transition: .2s ease-in-out;
    text-align: center;
    border: 1.5px dashed transparent;
}

.history-slide img {
    width: 100%;
}

.history-slide p {
    font-weight: normal;
    font-size: 14px;
    line-height: initial;
    letter-spacing: 0.2px;
    color: #787885;
    text-align: left;
    padding: 10px 0;
    margin: 0;
}

.history-slide p span {
    float: right;
}

.history-slide h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 79.5%;
    text-align: center;
    letter-spacing: 0.2px;
    color: #232121;
    margin-bottom: 15px;
}

.history-slide ul {
    list-style: none;
    padding: 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.history-slide ul li {}

.history-slide ul li img {
    background: rgba(246, 156, 76, 0.1);
    padding: 5px;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: none;
    margin-right: 10px;
}

.history-slide ul li p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #C4C4C4;
    display: grid;
    padding: 0;
}

.history-slide ul li p span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #232323;
}

.history-slide button {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #FF7700;
    border: 1px solid #FF7700;
    box-sizing: border-box;
    border-radius: 23.5px;
    background: #fff;
    transition: .2s ease-in-out;
}

.history-slide:hover {
    box-shadow: 0px 4px 20px rgb(255 152 0 / 48%);
    border: 1.5px dashed #ff980061;
}

.history-slide:hover button {
    background: #ff9800;
    color: #fff;
}

.enhancements {
    font-size: 13px;
    /* margin-left: 10px; */
}

.enhancements input {
    margin-left: 12px;
    margin-right: 4px;
    vertical-align: middle;
}

.enhancement-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
}

.tint-opt {}

.tint-opt label {}

.tint-opt input {
    margin-right: 12px;
}

.enhancement-container {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ebeaea;
}

.brightness-container {
    /* box-shadow: 0px 0px 10px 0px #ddd; */
    padding: 10px 0px;
    margin-bottom: 10px !important;
    font-size: 13px;
    border-bottom: 1px solid #ebeaea;
}

.brightness-container input {
    vertical-align: middle;
}


/* Responsive  */

@media (max-width: 800px) {
    body {
        background-size: cover;
        background-repeat: repeat-y;
    }
    .toggle-tab {
        justify-content: flex-end;
        margin: 0 0 -5px !important;
    }
    /* //footer */
    @-webkit-keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }
        to {
            opacity: 1;
            transform: none;
        }
    }
    .screen-two-main-wrapper {
        display: block;
        margin: 0px 15px;
        /* display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 20px;
        margin: 0px; */
    }
    .left-section-with-image {
        width: 100%;
        height: 343px;
    }
    .right-section-with-image {
        width: 100%;
        margin-left: 0px !important;
        margin-top: 0;
        padding-top: 0;
        height: auto;
    }
    .right-top-section {
        /* display: flex;
        flex-direction: column; */
    }
    .choose-angle-text {
        position: absolute;
        /* width: 40px; */
        height: 12px;
        left: 51.7px;
        top: 560.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .tooltip-text p {
        margin: 0px 5px;
    }
    .logo-text-sec {
        position: absolute;
        height: 12px;
        left: 52.97px;
        top: 677.78px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .background-text {
        position: absolute;
        height: 12px;
        left: 52.93px;
        top: 680.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .position-text {
        display: none;
    }
    .bottom-apply-area {
        position: absolute;
        left: -850px !important;
        top: 355px !important;
    }
    .bulk-pricing {
        position: absolute;
        left: 24%;
        top: 1150px;
        padding-bottom: 20px;
    }
    .separation-border {
        display: none;
    }
    .with-image-inner {
        width: 100%;
        height: 243px;
        border: 0.6px solid #77797b;
    }
    .with-image-inner div {
        height: 100%;
        width: 100%;
    }
    .right-section-background-section {
        width: 95%;
        margin: 20px auto 20px;
        height: auto;
        overflow: hidden;
    }
    .right-mid-section {
        width: 100%;
        margin-top: 115px;
    }
    .with-image-inner img {
        width: 100% !important;
        /* height: 243px;
        object-fit: contain; */
        padding: 5px;
        /* object-fit: contain; */
    }
    .inactive-background-images img,
    .active-background-images img {
        height: 45px;
        width: 45px;
    }
    .choose-angle {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .angle-selected {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .choose-angle button,
    .angle-selected button {
        width: 50px;
    }
    .upload-button button {
        margin-left: 0px;
    }
    .right-bottom-section {
        padding: 0px;
    }
    .right-bottom-section div:nth-child(1) {
        /* display: none; */
        /* margin: -330px auto auto auto; */
    }
    .pricing-plan {}
    .upload-button {
        position: absolute;
        left: 270px;
    }
    .upload-button button {
        width: auto;
        height: 35px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 18px;
        color: #ffffff;
    }
    .upload-logo-button button {
        /* width: 105.98px; */
        height: 35px;
        left: 174.18px;
        top: 673.2px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* padding-left: 20px; */
        /* padding-right: 20px; */
        /* identical to box height */
        color: #ffffff;
    }
    .logo-select {
        margin-left: 22px;
    }
    .logo-image {
        height: auto;
        width: 96.97px;
    }
    .logo-image p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 11px;
        color: #888888;
    }
    .credit-para {
        position: relative;
        top: -4%;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        width: 45px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 12px;
        color: #ffffff;
    }
    .backgrounds {
        /* margin: 10px 0;
        padding: 0px;
        display: flex;
        justify-content: space-evenly;
        height: 85px; */
        /* width: 100%; */
    }
    .bottom-apply-area p:nth-child(2) {
        display: none;
    }
    .center-title2 {
        margin-bottom: -30px;
    }
    .pricing-plan-area {
        border-right: 0;
    }
    .right-bottom-section p:nth-child(2) {
        line-height: initial;
        margin: 0 0 10px;
    }
    .right-bottom-section p:nth-child(1) {
        line-height: initial;
        margin-bottom: 10px;
    }
    .bottom-contact-badge {
        text-align: center;
        background: #dee2e6;
        padding: 5px 15px;
        border-radius: 0px 0px 11px 10px;
        margin: 0px 15px;
        margin-bottom: 50px;
    }
    .bg_img-texts {
        margin-left: 0px !important;
    }
    .email {
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
        z-index: 999 !important;
        height: 0px !important;
        width: 100% !important;
    }
    .email form {
        background: rgba(98, 98, 98, 0.7);
        border-radius: 0px;
        text-align: center;
        align-items: center;
        padding: 20px;
    }
    .email img {
        width: 100% !important;
    }
    .email label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 27px;
        color: #ffffff;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .email input {
        height: auto;
    }
    .email button {
        width: 124px;
        height: 35px;
        background: #ffffff;
        border-radius: 9px;
        color: #616161;
    }
    .download-popup button {
        padding: 15px;
    }
    .right-top-selection {
        grid-template-columns: 48% 48%;
        grid-gap: 10px;
    }
}

@media (max-width: 800px) {
    body {
        background-size: cover;
        background-repeat: repeat-y;
    }
    .screen-two-main-wrapper {
        display: block;
        margin: 0px 15px;
    }
    .left-section-with-image {
        width: 100%;
        height: 343px;
    }
    .right-top-section {
        /* display: flex;
        flex-direction: column; */
    }
    .choose-angle-text {
        position: absolute;
        /* width: 40px; */
        height: 12px;
        left: 51.7px;
        top: 560.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .tooltip-text p {
        margin: 0px 5px;
    }
    .logo-text-sec {
        position: absolute;
        height: 12px;
        left: 52.97px;
        top: 677.78px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .background-text {
        position: absolute;
        height: 12px;
        left: 52.93px;
        top: 680.7px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #0579df;
    }
    .position-text {
        display: none;
    }
    .bottom-apply-area {
        position: absolute;
        left: -850px !important;
        top: 355px !important;
    }
    .bulk-pricing {
        position: absolute;
        left: 24%;
        top: 1150px;
        padding-bottom: 20px;
    }
    .separation-border {
        display: none;
    }
    .with-image-inner {
        width: 100%;
        height: 243px;
        border: 0.6px solid #77797b;
    }
    .with-image-inner div {
        height: 100%;
        width: 100%;
    }
    .right-section-background-section {
        width: 95%;
        margin: 20px auto 20px;
        height: auto;
        overflow: hidden;
    }
    .right-mid-section {
        width: 100%;
        margin-top: 115px;
    }
    .with-image-inner img {
        width: 100% !important;
        /* height: 243px;
        object-fit: contain; */
        padding: 5px;
        /* object-fit: contain; */
    }
    .inactive-background-images img,
    .active-background-images img {
        height: 45px;
        width: 45px;
    }
    .choose-angle {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .angle-selected {
        padding: 10px 15px;
        width: auto !important;
        grid-gap: 20px !important;
    }
    .choose-angle button,
    .angle-selected button {
        width: 50px;
    }
    .upload-button button {
        margin-left: 0px;
    }
    .right-bottom-section {
        padding: 0px;
    }
    .right-bottom-section div:nth-child(1) {}
    .pricing-plan {}
    .upload-button {
        position: absolute;
        left: 270px;
    }
    .upload-button button {
        width: auto;
        height: 35px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 18px;
        color: #ffffff;
    }
    .upload-logo-button button {
        /* width: 105.98px; */
        height: 35px;
        left: 174.18px;
        top: 673.2px;
        background: #0579df;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
    }
    .logo-select {
        margin-left: 22px;
    }
    .logo-image {
        height: auto;
        width: 96.97px;
    }
    .logo-image p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 11px;
        color: #888888;
    }
    .credit-para {
        position: relative;
        top: -4%;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        width: 45px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 12px;
        color: #ffffff;
    }
    .backgrounds {}
    .bottom-apply-area p:nth-child(2) {
        display: none;
    }
    .center-title2 {
        margin-bottom: -30px;
    }
    .pricing-plan-area {
        border-right: 0;
    }
    .right-bottom-section p:nth-child(2) {
        line-height: initial;
        margin: 0 0 10px;
    }
    .right-bottom-section p:nth-child(1) {
        line-height: initial;
        margin-bottom: 10px;
    }
    .bottom-contact-badge {
        text-align: center;
        background: #dee2e6;
        padding: 5px 15px;
        border-radius: 0px 0px 11px 10px;
        margin: 0px 15px;
        margin-bottom: 50px;
    }
    .bg_img-texts {
        margin-left: 0px !important;
    }
    .email {
        position: relative !important;
        top: 0px !important;
        left: 0% !important;
        z-index: 999 !important;
        height: 0px !important;
        width: 100% !important;
    }
    .email form {
        background: rgba(98, 98, 98, 0.7);
        border-radius: 0px;
        text-align: center;
        align-items: center;
        padding: 20px;
    }
    .email img {
        width: 100% !important;
    }
    .email label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 27px;
        color: #ffffff;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .email input {
        height: auto;
    }
    .email button {
        width: 124px;
        height: 35px;
        background: #ffffff;
        border-radius: 9px;
        color: #616161;
    }
    .car-try-inner {
        box-shadow: 0 0 0;
        padding-top: 20px;
    }
    .top-bar {
        padding: 0;
    }
    .backcredit {
        display: none;
    }
    .back-credit-div {}
    .back-credit {
        position: absolute;
        top: 0;
        right: 10px;
        padding: 0 !important;
        padding: 0;
    }
    .backgrounds-grid .inactive-background-sec {
        position: relative;
    }
    .inactive-background-sec img {
        height: 47px;
    }
    .more-btn-overlay {
        font-size: 12px;
    }
    .rmaining {
        padding: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
    }
    .rmaining span {
        color: #0579df;
    }
    .perfect-output {
        margin: 70px 0 0;
    }
    .download-popup {
        border: 0;
        background: #ffffff;
        box-shadow: 0 0 0;
        padding: 20px 0;
    }
    .active-background-sec img {
        width: 100%;
        height: auto;
    }
    .inactive-background-sec {
        padding: 3px;
        border: 2px solid transparent;
    }
    .download-popup {
        border: 0;
        background: #ffffff;
        box-shadow: 0 0 0;
        padding: 20px 0;
    }
    .backgrounds-grid {
        display: grid;
        grid-template-columns: 31% 31% 31%;
        grid-gap: 9px;
        margin: 0 auto;
    }
    .checkbox-section label {
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
    .all-overlays {
        padding: 15px 5px;
    }
    .select-sec {
        display: none;
    }
    .active-background-sec-below img {
        width: 100%;
    }
    .apply-below-btn {
        width: 200%;
        text-align: center;
        padding: 10px 0px;
    }
    .bg-below-sec {
        padding: 15px 0px;
    }
    .output-apply-btn {
        padding: 15px 0px;
    }
    .right-top-selection {
        margin: 5px auto 20px;
    }
    .backgrounds-grid-custom .inactive-background-sec img {
        height: 35px;
    }
    .active-walls-sec img {
        height: 35px;
    }
    .inactive-walls-sec img {
        height: 35px;
    }
    .adjust-height-area ul li {
        width: 49%;
    }
}

@media screen and (max-width: 320px) and (min-width: 200px) {
    .bottom-apply-area button {
        width: 100%;
        position: fixed;
        top: 94%;
        z-index: 1000;
        left: 0;
        border-radius: 0;
    }
    .footer-left-text h2 {
        font-size: 20px;
        line-height: 35px;
        margin-top: 0%;
    }
    .email input {
        height: auto;
        margin-bottom: 15px;
    }
    .backgrounds {
        width: 300px;
        justify-content: center !important;
    }
}

@media screen and (max-width: 359px) and (min-width: 200px) {
    .box-text {
        padding-top: 41%;
        font-size: 14px;
        width: 70%;
        line-height: 25px;
    }
}

@media screen and (max-width: 767px) and (min-width: 360px) {
    .box-text {
        padding-top: 32%;
        font-size: 22px;
        width: 70%;
    }
    .box-img {
        width: 30% !important;
    }
    .original_image {
        width: 100% !important;
        height: auto;
        object-fit: contain !important;
        object-position: center;
    }
}

@media screen and (max-width: 767px) and (min-width: 321px) {
    .bottom-apply-area button {
        width: 100%;
        position: fixed;
        top: 94%;
        z-index: 1000;
        left: 0;
        border-radius: 0;
    }
    .footer-left-text h2 {
        font-size: 20px;
        line-height: 45px;
        margin-top: 0%;
    }
    .backgrounds {
        width: 400px;
        justify-content: center !important;
    }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
    .footer {
        background: url(https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/BackgroundReplacement/CarsFront/Group+5945+1.png);
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 10px 0px;
    }
    .footer-left-text {
        font-size: 20px;
        line-height: 35px;
        margin-top: 0;
    }
    .footer-right-text {
        padding: 10px 10px 0;
    }
    .footer-right-text ul li {
        margin: 0px 10px;
        width: 10%;
        line-height: 27px;
    }
    .footer-right-text ul {
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    .footer-right-text ul li img {
        padding: 5px;
        width: 100%;
    }
    .footer {
        height: 100%;
    }
    .footer-right-text p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 10px;
    }
    .backgrounds {
        justify-content: center !important;
    }
    .car-overlay-cat {
        border-right: 1px solid #ddd;
        padding: 0 5px;
    }
    .overlay-car-btn {
        padding: 0px 5px;
        display: inline-block;
        margin: 0 3px;
        width: 31%;
    }
    .car-try-btns img {}
    .overlay-box-mob {
        display: flex;
        border-bottom: 1px solid #ddd;
    }
    .car-mobile-angle {
        padding: 0 10px 0.5rem;
        font-size: 12px;
        color: #ff7700;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
    }
    .car-overlay-cat-mob {
        padding: 0;
    }
    .logo-sec {
        display: none;
    }
    .lower-section-with-overlay-2 {
        display: block;
        grid-template-columns: 100%;
    }
    .overlays-click-btn img {
        height: 40px;
        margin: 0 auto;
    }
    .credit-text {
        display: none;
    }
    .angle-sec {
        border-bottom: 0;
    }
    .overlays-click-btn {
        margin: 5px 10px 5px 0px;
    }
    .all-overlays-slider {
        padding: 0;
    }
    .edited_image {
        height: 196px;
        width: 348px !important;
    }
    .with-image-inner-2 img {
        height: 100%;
        object-fit: cover;
    }
    .left-section-with-image-2 {
        width: 100%;
        padding: 15px 10px;
    }
    .mobile-uploads {
        line-height: 35px;
        padding: 5px 15px 0;
    }
    .upload-sm-img {
        float: right;
        border: 1px solid #ff7700;
        color: #ff7700 !important;
        background: #fff;
        padding: 5px 15px;
        border-radius: 3px;
        height: auto;
        width: 125px;
        line-height: 20px;
    }
    .left-section-with-image-2 .mobile-uploads {
        padding: 20px 5px;
        border-bottom: 1px solid #ddd;
        line-height: 30px;
    }
    .mobile-uploads p span {
        font-size: 15px;
        line-height: 20px;
    }
    .toggle-section {
        display: none;
    }
    .mobile-uploads p {
        margin: 0;
    }
    .background-list {
        margin-top: 20px;
        padding: 15px 10px;
    }
    .upload-sec {
        display: none;
    }
    .backgrounds-content {
        margin: 0;
    }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
    .car-text h1 {
        font-size: 19px;
    }
    .box-text {
        padding-top: 15%;
        font-size: 35px;
        width: 70%;
    }
    .footer {
        height: auto;
    }
    .footer-right-text ul {
        margin: 0;
    }
    .footer-left-text h2 {
        margin-top: 28%;
    }
    .footer-right-text ul li img {
        padding: 12px;
    }
    .backgrounds {
        width: 100%;
        justify-content: center !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 901px) {
    .footer-left-text h2 {
        margin-top: 22%;
    }
    .backgrounds {
        justify-content: center !important;
    }
    .with-image-inner {
        width: 566.57px;
        height: 314px;
        border: 0.6px solid #77797b;
    }
    .left-section-with-image {
        width: 607.57px;
        height: 347px;
        /* background: #ffffff; */
        /* box-shadow: 0px 0px 133px rgb(0 0 0 / 15%); */
        padding: 21.87px 18.87px 10px;
    }
}

@media screen and (max-width: 1290px) and (min-width: 1025px) {
    .backgrounds {
        width: 300px;
        justify-content: center !important;
    }
    .car-overlay-cat {
        padding: 0 0 0 5px;
    }
    .overlay-car-btn {
        padding: 5px 0px;
    }
    .car-try-btns span {
        margin-top: 0%;
    }
    .overlay-vertical {
        height: 255px;
    }
    .download-popup button {
        padding: 15px 60px;
    }
    .download-popup {
        padding: 15px 30px;
    }
    .cross-btn {
        padding: 0 0 20px;
    }
}

@media (min-width: 1400px) {
    .footer-left-text h2 {
        margin-top: 15%;
    }
    .backgrounds {
        width: 100%;
        justify-content: flex-start !important;
    }
    .overlay-toggle {
        display: flex;
        margin-top: 0%;
    }
    .overlay-vertical {
        height: 315px;
    }
}

@media (max-width: 1299px) {
    .footer-left-text h2 {
        margin-top: 15%;
    }
    .backgrounds {
        width: 100%;
        justify-content: center !important;
    }
}

.container-2 {
    display: block;
    position: relative;
    padding-left: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.container-2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 9%;
    left: 9%;
    height: 18px;
    width: 18px;
    border: 2px solid #b9b6b6;
}


/* On mouse-over, add a grey background color */

.container-2:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.container-2 input:checked~.checkmark {
    background-color: #fff;
    border: 2px solid #ff7700;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container-2 input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container-2 .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #ff7700;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.product-hunt-tag {
    width: auto;
    /* top: 0; */
    position: absolute;
    right: 115px;
    top: 105px;
}

.product-hunt-tag2 {
    text-align: center;
    margin: -10% 0 20px;
}

.product-hunt img {
    width: 250px;
    margin: 0 0 0 10px;
}

@media screen and (max-width: 800px) {
    .checkmark {
        left: 0;
        height: 15px;
        width: 15px;
    }
    .check-window {
        padding: 0;
    }
}

@media (min-width: 1366px) {
    .perfect-output {
        max-width: 93.5%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1440px) {
    .perfect-output {
        max-width: 89%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
    .product-hunt-tag {
        right: 20%;
    }
}

@media (min-width: 1680px) {
    .perfect-output {
        max-width: 76.3%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 1920px) {
    .perfect-output {
        max-width: 66.3%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2560px) {
    .perfect-output {
        max-width: 50%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

@media (min-width: 2880px) {
    .perfect-output {
        max-width: 44.2%;
        margin: 0 auto;
        margin-top: 70px !important;
        margin-bottom: 50px !important;
    }
}

.superscript {
    background-color: #ffdcbe;
    padding: 1px 2px;
    color: #ff7700;
    font-weight: 500;
    font-size: 9px;
    top: -1em;
}