 .bg-white{
background: #fff;
}.app-banner{
    padding: 100px 0 60px;
    background: linear-gradient(180deg, rgba(255, 119, 0, 0.5) 0%, rgba(255, 119, 0, 0) 100%);
}
.app-banner h4{
    font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 60px; 
letter-spacing: 0.2px; 
color: #000000;
margin-bottom: 0;
}
.app-banner p{
    font-weight: 500;
font-size: 15px;
line-height: 30px;  
letter-spacing: 0.2px; 
color: #737373;
padding: 0 ;
}
.business-online{
    padding: 60px 6%;
}
 
.title h3{
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 36px; 
letter-spacing: 0.1px; 
color: #000000; 
opacity: 0.8;
opacity: 0.8;
margin: 0 0 6%;
}
.business-images{
text-align: center;
}
.business-images img{
    height: 90%;
}
.business-content{
    position: relative;
}
.business-content:before {
    content: attr(data-name);
    position: absolute; 
    left: 0px;
    top: 0px;
    font-size: 190px;
    line-height: 190px;
    font-weight: 400;
    color: #F15E22; 
    opacity: 0.1;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
}
.business-content{
    
    padding-left: 9%;
    padding-top: 8%;
    padding-right: 0;

}
.business-content h3{
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 70px;
    color: #000000;
    margin-bottom: 15px;
}
.business-content p{
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #18191F;
}
.business-content p a{
    color: #ff7700;
    font-size: 16px;
    transition: .3s ease-in-out;
    font-weight: 500;
}
 
.business-content p a:hover img{
    margin-left: 15px;
}
.business-content p a img{
    width: 20px;
    margin-left: 5px;
    transition: .2s ease-in-out;
}
.third-images img{
    height: auto!important;
}
.spyne-ai-works{
    padding: 60px 6%;
}
.spyne-ai-works .title h3{
    margin-bottom: 10px;
}
.spyne-ai-works .title {
    margin-bottom: 30px;
}
.spyne-works{
text-align: center;
}
.spyne-works img{
 width: 80%;
}
.spyne-ai-works .title p{
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 35px; 
text-align: center;
letter-spacing: 0.2px; 
color: #737373;
}
.spyne-works h4{
text-align: right;
font-weight: 600;
font-size: 18px;
line-height: 36px; 
letter-spacing: 0.1px; 
color: #000000;
padding: 0;
opacity: 0.8;
transition: .2s ease-in-out;
}
.works-single:hover h4{
    color: #ff7700;
    opacity: 1;
}
.spyne-works p{
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    line-height: 35px; 
    text-align: right;
    letter-spacing: 0.2px;
    color: #737373;
    opacity: 0.8;
    padding: 0;
    margin: 0;
}
.works-single{
    margin: 0 0 20px;
}
.works-single img{
    width: 38px; 
    padding-right: 0; 
    margin: 0 auto 10px;
}
.categories{
    padding: 60px 6%;
}
 
.create-content{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/bg11.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    padding: 18% 5% 0;
    height: 740px;
    margin-bottom: 60px;
}

.account-details{
    width: 45%;
}
.account-details p span{
    font-weight: bold;
font-size: 18px;
line-height: 35px; 
letter-spacing: 0.1px; 
color: #FA4A0C;  
margin: 0px 0px;
}
.account-details h3{
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px; 
    letter-spacing: 0.2px; 
    color: #252B42;
}
.account-details p{
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: #737373;
    margin-bottom: 30px;
}
.spyne-wrk2 h4, .spyne-wrk2 p{
    text-align: left!important;

}
.spyne-wrk2 img{
    width: 60px!important;
}

.community-grid{
    display: grid; 
    grid-template-areas:
        "fashion automobile automobile"
        "fashion jewellery footwear"
        "food food footwear"
        "food food watch";
    grid-gap: 16px;
    grid-template-rows: repeat(4, minmax(300px,1fr));
}
.community-grid .child:nth-child(1) {
    grid-area: fashion;
}
.child {
    position: relative;
    height: auto;
    width: 100%;   
    background-size: cover!important;
    background-position: center!important;
    box-shadow: -5px 1px 8px 0px #ccc;
}
 
.community-grid .child:nth-child(2) {
    grid-area: automobile;
}
 
.community-grid .child:nth-child(3) {
    grid-area: jewellery;
}
.community-grid .child:nth-child(4) {
    grid-area: footwear;
}
.community-grid .child:nth-child(5) {
    grid-area: food;
}
.community-grid .child:nth-child(6) {
    grid-area: watch;
}
 
.work-auto {
    position: relative;
    left: 5%;
    top: 5%;
    right: 0;
    z-index: 2;
}
.child img{
    width: 100%;
    position: relative;
}
.fashion{
background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/work1.png');
}
.automobile{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/work3.png');
}
.jewellery{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/work2.png');
}
.footwear{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/jutti.png');
}
.food{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/smose.png');
}
.watch{
    background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/watch.png');
}
 
.work-auto a {
    border: 1px solid #212529; 
    color: #212529; 
    background: transparent; 
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    padding: 5px 15px;
    line-height: 40px; 
    transition: .3s ease-in-out;
}
  
.child:hover a{
    background: #FF7700;
    color: #fff;
}
.child::after{
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 75%));
    height: 100%;
    bottom: 0%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    z-index: 1;
}
.child:hover::after {
    opacity: 1 !important;
}

.img-google{
    width: 150px;
    margin: 15px 10px 0;
}

 


/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 366px){
    .banner-btns img{
        width: 170px!important;
        margin: 0;
    }
    .business-content { 
        padding-left: 15px!important;
    }
    .img-google{
        width: 140px;
        margin: 15px 4px 0;
    }
    .business-images img {
        width: 65%!important;
    }
.third-images img{
    width: 100%!important;
    margin: 10% 0 0 ;
}
.account-details img {
    width: 23%;
}
}
@media screen and (max-width: 365px){
    .img-google{
        
        width: 43%!important;
        padding-bottom: 0!important;
        margin: 10px 5px 15px;
        }
    .banner-btns img{
        width: 50%!important;
        margin: 0;
    }
    .business-content { 
        padding-left: 5%!important; 
    }

}



@media screen and (max-width: 767px) and (min-width:200px){
    .app-banner img{
        width: 100%;
        padding: 0 5% 20px;
    }
    .app-banner h4 { 
        font-size: 16px;
        line-height: 30px;
        margin: 15px 0;
    }
    .app-banner p { 
        font-size: 12px;
        line-height: 20px;
    }
    .app-banner {
        padding: 100px 0 0px;
    }
    .business-online{
        padding: 25px 0% 0;
    }
    .title h3 { 
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 30px;
    }
   
    .business-content h3 {
        font-size: 21px;
    line-height: 45px;
    font-weight: 500;
        margin: 0;
    }
    .business-content:before {
        font-size: 70px;
        line-height: 70px;
        left: 5%; 
    }
    .business-content p {
        text-align: center;
    }
    .business-images img {
        height: auto;
        margin-bottom: 30px;
        width: 70%; 
    }
    .spyne-ai-works{
        padding: 0px ;
    }
    .spyne-works{
        text-align: center!important;
        display: flex;
    padding: 0;
    }
    .works-single {
        margin: 0 0 20px;
        padding: 0 9px;
    }
    .spyne-works h4 {
        text-align: center!important; 
        font-size: 12px;
        line-height: 18px;
    }
    .spyne-works p {
        text-align: center!important;
        line-height: 18px;
        font-size: 10px;
    }
    .spyne-works img { 
        margin: 25px auto 40px;
        width: 85%;
        padding: 0 10%;
    }
    .works-single img {
        width: 25px;
        height: 50px;
        margin: 0 auto 10px;
        padding: 0;
        object-fit: contain;
    
    }

    .spyne-wrk2 img {
        width: 60px!important;
        margin: 0 auto 10px!important;
    }
    .community-grid {
        grid-template-areas:
            "food food"
            "footwear jewellery"
            "footwear fashion"
            "watch fashion"
            "automobile automobile";
        grid-template-columns: auto auto;
        grid-template-rows: repeat(5, minmax(150px,1fr));
        grid-gap: 10px;
        padding: 0 5px; 
    }
    .categories {
        padding: 60px 0%;
    }
    .create-content {
        background: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/App-Landing/Group%205892487.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 70px 10px 35px;
        height: 100%;
        margin-bottom: 20px;
    
    }
    .account-details {
        width: 64%;
    }
    .account-details h3 { 
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 5px;
    }
    .account-details p {
        font-weight: 500;
        font-size: 8px;
        line-height: initial;
        margin: 0 0 5px;
    }
    .account-details p span { 
        font-size: 12px;
        line-height: 20px
    }

.business-images img{
    width: 75%;
}
.spyne-ai-works .title h3 {
    margin-bottom: 10px;
    font-size: 22px;
}
.work-auto a {
    border: 1px solid #212529; 
    color: #212529; 
    font-size: 11px;
    padding: 2px 15px;
}
.business-content {
    padding-right: 15px;
    order: 2; 
    text-align: center;
    margin-bottom: 30px;
}
}


@media screen and (max-width: 900px) and (min-width:768px){
.categories {
    padding: 60px 0%;
}
.business-online {
    padding: 60px 5%;
}
.business-content h3 {
    font-size: 35px;
    line-height: 55px;
}
.business-images img {
    height: 85%;
}
.business-content:before {
    left: -10%;
}
.business-content {
    padding-left: 5%;
}

.third-images img { 
    margin-top: 40%;
}
.spyne-ai-works {
    padding: 60px 0%;
}
.spyne-works {
    padding: 0;
}
.spyne-works p {
    font-size: 11px;
    line-height: 25px;
}
.spyne-works h4 {
    font-size: 13px;
    line-height: 25px;
}
.works-single img {
    width: 38px;
    padding: 0;
}
.create-content {
    background-size: 100%;
    padding: 13% 5%;
    height: 100%;
    margin: 0;
}
.account-details h3 {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 10px;
}

.account-details p {
    font-weight: 500;
    font-size: 11px;
    line-height: 25px;
    margin-bottom: 5px;
}
.account-details p span {
    font-size: 14px;
    line-height: 25px;
}
.account-details img{
    width: 100px; 
}

.spyne-works img { 
    margin-top: 15%;
}
.business-content{
    padding-left: 3%;
}
.spyne-ai-works {
    padding: 10px 0% 0;
}
.account-details h3 {
    font-size: 30px;
    line-height: 40px;
}
.img-google {
    width: 120px;
    margin: 15px 5px 0;
}
}


@media screen and (max-width: 1024px) and (min-width:901px){
.create-content {
    padding: 15% 5% 0;
    height: 590px;
}
.business-online {
    padding: 60px 0%;

}
.create-content {
    background-size: 100%;
    height: 100%;
    padding: 15% 5% 9%;
}
.business-content{
    padding-left: 3%;
}
.spyne-ai-works {
    padding: 10px 0% 0;
}
.account-details h3 {
    font-size: 29px;
    line-height: 40px;
}
.business-online {
    padding: 60px 0%;
}
.img-google {
    width: 140px;
    margin: 15px 5px 0;
}
}