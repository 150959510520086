@import url('https://fonts.googleapis.com/css2?family=Inria+Sans&display=swap');
body{
    /* background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Landing+Page+%E2%80%93+1%402x.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 100vh !important; */
}
.landing-main-wrapper{
    padding: 200px 40px;
    background-image: url("https://spyne-website.s3.ap-south-1.amazonaws.com/static/website-themes/clippr/static/Landing+Page+%E2%80%93+1%402x.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 100vh !important;
}
.mobile-view{
    display: none !important; 
}
.left-section{
    padding-left: 100px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}
.banner-head{
    color: black;
    font-family: 'Inria Sans';
    font-size: 40px;
    letter-spacing: -1.37px;
    margin: 0px;
    padding: 0px;
    font-weight: bolder;
}
.automated{
    margin-top: 20px;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 20px;

}
.email-input {
    height: 48px !important;
    border-radius: 40px !important;
    padding-left: 30px !important;
    color: #495057 !important;
    max-width: 365px !important;
}
.free-trail-button{
    height: 46px;
    border-radius: 40px;
    border: unset;
    background-color: #0579DF;
    color: white;
    padding: 0px 20px;
    font-family: 'Roboto';
    cursor: pointer;
    margin-left: -27.5% !important;
    margin-top: 1px;
}
.free-trail-button:disabled{
    background-color: #C6E4FF !important;
    opacity: 1 !important;
}
.image-cnt{
    color: grey;
    opacity: 0.8;
    margin: 0px;
    margin-top: 10px;
    font-size: 16px;
}
.try-now-button{
    position: initial;
    width: 200px;
    margin: 0px !important;
    height: 45px;
    border-radius: 40px;
    border: unset;
    background-color: #0579DF;
    color: white;
    padding: 0px 20px;
    font-family: 'Roboto';
    cursor: pointer;
}
.seo-content-sec{
    background-color: white !important;
    margin-top: 50px;
    font: normal normal 'Inria';
    font-size: 12px;
    color: #242424;
    opacity: 1;
    color: #979BA1;
    letter-spacing: 0.72px;
}
.paraaa{
    margin-top: 20px;
    color: #4A4A82;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
   text-align: justify;
    max-width: 500px;
}

@media(max-width:800px){
    body{
        background-repeat: repeat-y !important;
    }
    .web-view{
        display: none !important;
    }
    .mobile-view{
        display: block !important;
    }
    .landing-main-wrapper{
        padding: 100px 0px !important;
        padding-bottom: 50px !important;
    }
    .mob-banner-section {
        margin: 0px auto;
        padding: 0px 20px;
        display: block !important;
    }
    .para-heading {
        font-size: 24px;
        text-align: center;
        color: black;
        margin-top: 40px;
        font-family: 'Inria Sans';
    }
    .automated {
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        margin-top: -3%;
        color: black;
    }
    .mob-gif-image {
        border-radius: 10px;
        margin-top: 30px;
    }
    .banner-section-2-bottom-para-1 {
        color: #000000;
        font-size: 20px;
        letter-spacing: -0.72px;
        font-family: 'Roboto';
        font-weight: 400;
        text-align: center;
        margin-top: 30px;
    }
    .mob-banner-section-2-bottom button {
        height: 48px;
        border-radius: 5px;
        right: 10px;
        border: unset;
        background-color: #0579DF;
        color: white;
        padding: 0px 20px;
        font-family: 'Inria Sans';
        width: 100%;
        margin-top: 15px;
    }
    .try-now-mob{
        height: 48px;
        border-radius: 5px;
        right: 10px;
        border: unset;
        background-color: #0579DF;
        color: white;
        padding: 0px 20px;
        font-family: 'Inria Sans';
        /* margin-left: -10%; */
        width: 100%;
        margin-top: 15px;
    }
    .mob-email-input {
        border-radius: 5px !important;
        padding: 0px auto !important;
        height: 48px !important;
    }
    .image-processing {
        color: grey;
        opacity: 0.8;
        margin: 0px;
        margin-top: 10px;
        font-size: 16px;
        text-align: center;
        padding-bottom: 20px;
    }
}