



.food-angle{

}
.food-image-area h6{
    font-weight: normal;
    font-size: 14px;
    line-height: 150%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #5E5E5E;
}
.food-image-area img{
width: 100%;
}
.food-bottom h4{
    font-weight: 600;
    font-size: 24px;
    line-height: 150%; 
    text-align: center;
    letter-spacing: 0.5px; 
    color: #232121;
    margin-bottom: 15px;
}


.food-angle-single img{
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
}
.food-angle-single{

}
.food-angle-single button{
    filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.12));
    font-weight: 600;
font-size: 12px;
line-height: 150%; 
text-align: center;
letter-spacing: 0.5px; 
color: #FFFFFF;
background: rgba(20, 19, 19, 0.4);
border-radius: 5px;
}
.food-angle-single button:hover{
    background: #FF9200;
box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 5px;
}
.food-image-area{
    

}
.food-image-area img{
    width: 100%;
   
}
.food-bottom{
    border-top: 1px solid rgba(162, 162, 162, 0.27);
    box-shadow: 0px -2px 4px rgb(0 0 0 / 12%);
    padding: 20px 65px;
    margin-top: 10px;
    transition: .3s ease-in-out;
}






