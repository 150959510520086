a{
    text-decoration: none;
}
.bg-white{
    background: #fff;
}
.banner-slider{
    padding: 10% 0 5%;
}

.car-v2-tabs{
    padding: 60px 0px 0px;
}
.title-area{
    margin-bottom: 7%;
}
.title-area h1{
    font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 45px; 
color: #323232;
text-align: center; 
margin: 0;
margin-bottom: 50px;
}
.title-area p {
text-align: center;
}
.title h6{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.2px;
color: #323232;
}
.title h6 span{
    color: #FF7700;
}
.request-demo{
    font-style: normal;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.1px;
    background: #FF7700;
    border-radius: 10px;
    color: #FFFFFF;
    margin: 0;
    padding: 8px 25px;
    transition: .2s ease-in-out;
    border: 2px solid #FF7700;
    height: 42px;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #6c757d;
}

.request-demo:hover{
    border: 2px solid #FF7700;
    background: #fff;
    color: #FF7700;
}
.car-v2-left{

}
.why-use h1{
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 42px;   
     margin-bottom: 25px;
    letter-spacing: 0.02em;
    color: #323232;
    padding: 0;
}
.why-use h1 span{
    border-bottom: 2px solid #FD8061;
    padding-bottom: 10px;
    color: #1C1E52;
}

.car-v2-left p, .why-use p{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
    color: #7C7C7C;
    padding: 0;
}
.why-tabs li button.activate, .why-tabs li button:hover {
  /* box-shadow: 0px 0px 20px #ff7700!important;  */
  border-left: 5px solid #FF7700;
}
.car-v2-tabs{
    position: relative;
}



.why-section{
    padding: 0 5%;
}
.why-tabs li button{
    font-weight: 600!important;
    font-size: 24px!important;
    border-radius: 10px!important;
    line-height: 45px!important; 
    color: #1D293F!important;
    border-left: 5px solid #fff;
    background-color: #fff!important; 
    transition: .3s ease-in-out;
}
.why-tabs img{
    float: left;
    margin-right: 0;
    width: 11%;
    vertical-align: middle;
    margin-top: 1%;
}
.car-v2-left .why-tabs span{
   
}
.car-v2-left .why-tabs p {
   
}
.first-tab-content{
    text-align: right;
    margin-bottom: 5%!important;
}
.why-text{
    width: 87%;
    float: right;
}
.why-text h6{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: #1D293F;
    margin: 0;
}
.why-text p{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px; 
    color: #7C7C7C;
    margin: 0;
    display: flex;
}

.scroll-tab {

  }
.why-tabs li button { 
    background: #FFFFFF!important;
    box-shadow: 0px 4px 11px rgb(0 0 0 / 25%)!important;
    border-radius: 10px!important;
    margin: 0 0px 10px!important;
    padding: 15px 20px;
    text-align: left;

    width: 100%;
    height: 100%;

}
.why-tabs{
    padding: 0;
    list-style: none;
    margin: 0 0 10px;
}
.request-demo2 img{
    height: 44px;
    margin-left: 10px;

}
.right-sections div{
   
}
.right-sections{
    position: relative;  
}
.why-tabs li{
    margin: 5px 0px!important;
}
.first-tab-content img{
    width: 65%;
    height: auto;
    margin: auto;
    margin-top: 2%;
}
.buttons-div{
margin: 5% 0;
}


.custom-backgrounds{
    padding: 60px 5% 40px;
}

.title{
text-align: center;
}
.title h1{
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 35px; 
color: #FF7700;
margin-bottom: 45px; 
margin-top: 0;
}
.title h1 span{
    border-bottom: 2px solid #FD8061;
    color: #1C1E52;
}
.title h3{
    font-style: normal;
font-weight: 600;
font-size: 33px;
line-height: 45px; 
color: #FF7700;
margin-bottom: 10px; 
margin-top: 0;
    font-weight: 600;
font-size: 33px;
line-height: 54px; 
letter-spacing: 0.1px;
}

.custom-image{

}
.custom-image img{
    width: 100%;
    box-shadow: -2px 5px 10px 0px #6c757d63;
} 
 .custom-links{

 }
 .custom-image-360{
     
 }
 .custom-image-360 div, .custom-image-360 div iframe {
    width: 100%!important;
    box-shadow: -2px 5px 10px 0px #6c757d63;
 }
 .custom-image-360 div ol{
     background: #969ca1d1!important;
 }
 .custom-image-360 img{
     width: 100%;
     padding: 0;
 }
.custom-backgrounds .container-fluid{
    width: 100%!important;
}
.custom-links{

}
.exterior-links ul{
    display: flex;
    list-style: none;
    padding: 30px 0 ;
    margin: 0;
}
.exterior-links ul li{
    padding: 0 5px 0;
    width: 20%;
    text-align: center;
}


.exterior-links ul li button{
    background: #fff;
    padding: 0px;
    border: 2px solid #fff;
    position: relative;
    border-radius: 0;
    transition: .3s ease-in-out;
    box-shadow: 0px 2px 2px 0px #6c757d63;
}




.custom-links ul{
    display: block;
    list-style: none;
    padding:  15px 0 0 ;
    margin: 0;
}
.custom-links ul li{
    padding: 0 5px 0;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}


.custom-links ul li button{
    background: #fff;
    padding: 2px;
    border: 2px solid #fff;
    position: relative;
    border-radius: 0;
    transition: .3s ease-in-out; 
    box-shadow: 0px 2px 2px 0px #6c757d63;
}
.custom-links ul li button.active, .custom-links ul li button:hover{
   border: 2px solid #ff7700;
}
.custom-links ul li button img, .exterior-links ul li button img{
    width: 100%;
    
}
.exterior-links ul li button img{
    height: 75px;
}
.exterior-links ul li button p{
    color: #fff;
    font-size: 11px;
    padding: 3px 5px ;
    margin: 0;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
.exterior-tabs  ul{
    text-align: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 25px;
}
.exterior-tabs  ul li{ 
    padding: 3px;
    margin: 10px 5px;
    background: transparent;
    list-style: none;
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    box-shadow: 0px 2px 3px 0px #ddd;
}
.slates-tabs li{
    padding: 5px 10px!important; 
    margin: 10px!important;
}
.exterior-tabs  ul li.selected{
    background: #fff; 
    color: black;
    border-radius: 0;
    border: 0;
    color: #ff7700 !important;
    border-bottom: 2px solid #FF7700 !important;
    border-radius: 0;
    background: transparent;
}
.exterior-tabs  ul li button{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: transparent;
    color: #444444;
    padding: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    border-radius: 0!important;
}
.exterior-tabs  ul li button.activ, .exterior-tabs  ul li button:hover, .exterior-tabs  ul li button.virtual{
   color: #ff7700 !important;
border-bottom: 1px solid #FF7700 !important;
border-radius: 0;
background: transparent;
}


.abs-img{
    position: absolute;
    left: 33%;
    top: 20%;
    width: 30%!important;
}

.gif-area{
    padding:60px 0 40px; 
}
.no-border{
    border: none!important;
}

.mac-pro-image{
    text-align: center;
}
.mac-pro-image img{
    margin-bottom: 50px;
    width: 100%;
}


.graph-area{
    padding: 60px 0px;
}
.graph-area .title{
    text-align: left;
    padding-top: 10%;
}

.graph-image{

}
.graph-image img{
width: 100%;
}

.calendly-area{
    padding: 60px 0px; 
}

.cars-demo{
height: 450px;
text-align: center;
}
 .cars-demo p{
    margin-top: 30px;
 }
.cars-demo iframe{
    height: 100%;
}
.cars-video{
    padding: 0px;
    border-radius: 5px;
    overflow: hidden; 
    box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
    height: 450px;
}
.cars-calender{
    box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
    border-radius: 10px;
    padding: 10px;
}
.cars-calender h4{
    text-align: center;
    width: 100%;
    color: #FF7700;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.calendly-inline-widget{
    width: 100%;
}
.main{
padding: 50px 0px;
}
.book-demo { 
    margin-bottom: 30px;
}
.book-demo h1{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 800;
font-size: 27px;  
letter-spacing: 0.2px; 
color: #5D5D6D;
margin-bottom: 15px;
padding-left: 5%;
}
.book-demo h1 span{
    color: #FF7700;
}
.book-demo p span{ 
    color: #FF7700;
}
.book-demo p{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 30px;
letter-spacing: 0.2px;
margin-bottom: 15px;

}
.book-demo img{
    float: left;

}
.cars-calender {
    box-shadow: 0px 2px 10px 0px rgb(77 80 85 / 63%);
    border-radius: 10px;
    padding: 10px 10px 20px;
    height: 100%;
    overflow: hidden;
}
.cars-calender h4 {
    text-align: center;
    width: 100%;
    color: #FF7700;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.calendly-inline-widget{
    height: 100%!important;
}
 

.ticker-headline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px 0;
    margin: 0;
    font-size: 18px;
  }
  
  .carousel.vertical .carousel-inner {
    height: 100%;
    width: auto;
  }
  .carousel.vertical .carousel-inner > .item {
    width: auto;
    padding-right: 50px;
    transition: 0.6s ease-in-out top;
  }
  @media all and (transform-3d), (-webkit-transform-3d) {
    .carousel.vertical .carousel-inner > .item {
      transition: 0.6s ease-in-out;
    }
    .carousel.vertical .carousel-inner > .item.next, .carousel.vertical .carousel-inner > .item.active.right {
      transform: translate3d(0, 100%, 0);
      top: 0;
    }
    .carousel.vertical .carousel-inner > .item.prev, .carousel.vertical .carousel-inner > .item.active.left {
      transform: translate3d(0, -100%, 0);
      top: 0;
    }
    .carousel.vertical .carousel-inner > .item.next.left, .carousel.vertical .carousel-inner > .item.prev.right, .carousel.vertical .carousel-inner > .item.active {
      transform: translate3d(0, 0, 0);
      top: 0;
    }
  }
  .carousel.vertical .carousel-inner > .active,
  .carousel.vertical .carousel-inner > .next,
  .carousel.vertical .carousel-inner > .prev {
    display: block;
  }
  .carousel.vertical .carousel-inner > .active {
    top: 0;
  }
  .carousel.vertical .carousel-inner > .next,
  .carousel.vertical .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .carousel.vertical .carousel-inner > .next {
    top: 100%;
  }
  .carousel.vertical .carousel-inner > .prev {
    top: -100%;
  }
  .carousel.vertical .carousel-inner > .next.left,
  .carousel.vertical .carousel-inner > .prev.right {
    top: 0;
  }
  .carousel.vertical .carousel-inner > .active.left {
    top: -100%;
  }
  .carousel.vertical .carousel-inner > .active.right {
    top: 100%;
  }
  
  .carousel.vertical .carousel-control {
    left: auto;
    width: 50px;
  }
  .carousel.vertical .carousel-control.up {
    top: 0;
    right: 0;
    bottom: 50%;
  }
  .carousel.vertical .carousel-control.down {
    top: 50%;
    right: 0;
    bottom: 0;
  }
  .carousel.vertical .carousel-control .icon-prev,
  .carousel.vertical .carousel-control .icon-next,
  .carousel.vertical .carousel-control .glyphicon-chevron-up,
  .carousel.vertical .carousel-control .glyphicon-chevron-down {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
  }
  .carousel.vertical .carousel-control .icon-prev,
  .carousel.vertical .carousel-control .glyphicon-chevron-up {
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
  }
  .carousel.vertical .carousel-control .icon-next,
  .carousel.vertical .carousel-control .glyphicon-chevron-down {
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
  }
  .carousel.vertical .carousel-control .icon-up,
  .carousel.vertical .carousel-control .icon-down {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
  }
  .carousel.vertical .carousel-control .icon-prev:before {
    content: "‹";
  }
  .carousel.vertical .carousel-control .icon-next:before {
    content: "›";
  }


  .slide-images .item img{
      width: 100%;
      height: 100vh;
  }

  .calendly-area .title{
      margin-bottom: 25px;
  }
.calendly-area .title h1{
    margin-bottom: 0;
}
.slide-images img{
    max-height: calc(100vh - 65px);
 object-fit: cover;
}

/* Virtual section  */
.virtual-images{

}
.virtual-images img{
    width: 100%;
}
 
.slates-tabs li.selected{
border-top: 0px!important;
}

.v-image{
    padding: 0 15px!important;
}
.banner-text{
margin-top: 5%;
text-align: left;
}
.banner-text h1{
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: #1C1E52;
    margin: 0 0 25px;
    letter-spacing: 1px;
}
.banner-text p{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 45px; 
    color: #444444;
}
.banner-text p span{
color: #FF7700;
}

.banner-gif img{
    width: 100%;
    position: relative;
}
.banner-gif{
    
}
.banner-gif .mac-gif{
    position: absolute;
    width: 56.1%; 
    right: 10.2%;
    top: 6%;
}
.banner-text p a img{
    width: 250px; 
}
.product-hunt img{
    width: 250px;  
    margin: 0 0 0 10px;
}

/* History section CSS  */
.history{

}
.history-title{
  
}
.history-title h3{
    font-weight: 600;
    font-size: 18px;
    line-height: 210%;
    letter-spacing: 0.5px;
    color: #232121;
    border-right: 1.5px solid #C4C4C4;
    margin-top: 20%;
    text-align: center;
    padding: 10px 0;
}
.history-title h3 span{
  border-bottom: 4px solid #FF7700;
  padding: 5px 0;
}
.upload-history{
    background: #FAFAFA;
    box-shadow: 0px 6px 20px rgb(0 0 0 / 20%);
    border-radius: 0px 0px 20px 20px;
    padding: 20px;
}
.history-img{
  
 
}
.history-img img{
width: 100%;
margin-bottom: 10px;
border: 1.5px dashed transparent;
transition: .2s ease-in-out;
}
.history-img:hover img{
    box-shadow: 0px 2px 10px 0px rgb(255 152 0 / 48%);
      border: 1.5px dashed #ff980061;
  }
  .history-img:hover h5, .history-img:hover p{
      color: #ff7700;
  }
.history-img h5{
  font-weight: 600;
  font-size: 12px;
  line-height: 79.5%; 
  text-align: center;
  letter-spacing: 0.2px; 
  color: #232121;
  margin-bottom: 10px;
  transition: .2s ease-in-out;
}
.history-img p{
  font-weight: normal;
font-size: 10px;
line-height: initial; 
text-align: center;
letter-spacing: 0.2px; 
color: #000000;
margin: 0;
transition: .2s ease-in-out;
}
.history-img-row{
    padding-right: 30px;
}
.arrow-history{
    padding: 8% 0 0 15px; 
}
.arrow-history span{
    color: #2E3A59;
    border: 2px solid #2E3A59;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50%;
    padding: 1px 6px;
}
.arrow-history span a{
    color: #2E3A59;
    text-decoration: none;
}
.no-upload-message{
    text-align: center;
  }

















/* @Responsive  */
@media screen and (max-width: 767px) and (min-width: 360px){
    .banner-text {
        margin-top: 0%;
    }
    .applestore img {
        margin-top: 0;
    }
    .request-demo {
        min-width: 25%;
    }
    .cars-demo { 
        margin-top: 0px;
    }
}
@media screen and (max-width: 360px){
    .banner-text {
        margin-top: 10%;
    }
    .applestore img{
        margin-top: 20px;
      }
    .request-demo {
        min-width: 46%;
    }
    .cars-demo { 
        margin-top: 30px;
    }
}

@media screen and (max-width: 767px) and (min-width: 200px){
    .title-area h1 {
        display: none;
    }
    .mac-pro-image img { 
        width: 100%;
        margin-bottom: 20px;
    }
    .custom-links ul li button img{
        width: 100%;
    }
    .abs-img {
        top: 18%;
        width: 30%!important;
    }
    .graph-image img{
    width: 100%;

    }
    .graph-area .title {
        margin-bottom: 0px;
        text-align: center;
        padding-top: 0%;
        order: 2;
    }
    .title h1 { 
        font-size: 17px;
        line-height: 30px; 
    margin-bottom: 20px; 
    }
    .request-demo{
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
        width: auto;
        line-height: initial;
        height: 40px;
        margin: 0 auto;
        
}
.graph-area {
    padding: 0 0 ;
} 
.custom-links ul li {
    padding: 0px 5px 0;
    margin-bottom: 5px;
} 
.custom-backgrounds {
    padding: 40px 0px 20px;
}
.request-demo2 img {
    height: 40px;
}
.car-v2-left .why-tabs span{
font-size: 18px;
line-height: initial;
}
.car-v2-left .why-tabs p{
    font-size: 10px;
    display: flex;
    margin: 0;
    text-align: left;
    line-height: initial;
    }
.why-tabs li button { 
    padding: 10px;
}
.why-tabs img { 
    width: 15%;
    margin-top: 10px;
}
.why-tabs li+li button img{
    margin-top: 7%;
}
.car-v2-left  p { 
    text-align: center;
}
.car-v2-tabs{
    padding: 0;
    margin-bottom: 20px; 
}
.title-area{
margin: 0;
}
.car-v2-left {
    order: 2;
    padding: 0;
}
.why-use h1{
font-size: 18px;
margin-bottom: 10px;
}
.right-sections div {
    margin: 0;
    text-align: center;
}
.car-v2-left p, .why-use p{
    font-size: 12px;
    line-height: inherit;
    margin-bottom: 15px;
}

.first-tab-content img { 
    width: 50%;
    margin: 3% auto 5%;
}
.gif-area {
    padding: 0px;
}
.banner-slider {
    margin-bottom: 0;
    margin-top: 55px;
    padding-bottom: 0;
}
.why-text {
    width: 80%;
    float: right;
}
.why-text h6 { 
    font-size: 18px;
}
.graph-image{
    padding: 0 30px;
}
.mac-pro-image { 
    padding: 0 25px;
}
.custom-image {
    padding: 0;
    margin: 10px 0;
}
.cars-demo {
    height: auto;
    text-align: center; 
}
.title h6 { 
    font-size: 12px; 
    margin: 0;
}
.cars-video{
    height: auto;
    margin-bottom: 25px!important;
}

.calendly-area {
    padding: 60px 0px;
    margin-bottom: 0px;
}
.custom-links ul li button p {
    color: #323232;
    font-size: 9px;
}
.custom-links {
    padding: 0 5px;
}
.scroll-tab .why-use{
    padding: 0;
    margin: 0;

    }
    .custom-links ul { 
        padding: 0 0 0; 
        display: flex;
    }
    .exterior-tabs  ul {
        display: flex;
        padding: 0 10px;
    }
    .exterior-tabs  ul li {
        margin: 5px 5px 0!important;
        font-size: 12px;
        padding: 2px!important;
    }
    .exterior-tabs  ul li button {
        font-size: 12px;
    }
    .exterior-links ul {
        padding: 10px 0;
        width: 100%;
        overflow: auto;
        display: block;
    }
    .exterior-links ul li{ 
    width: 12.5%; 
    display: inline-block;
    margin-bottom: 10px;
}
.exterior-links ul li button img {
    height: 40px;
}
.exterior-links ul li button p {
    color: #fff;
    font-size: 8px;
    }

.virtual-tabs{
display: block!important;
}
.custom-bg{
    margin-bottom: 10px;
}
.virtual-sm {
    display: block!important;
    padding: 0!important;
}
 
.banner-text {
   
    text-align: center;
    order: 2;
}
.banner-text h1 { 
    font-size: 20px;
    line-height: initial; 
    margin: 0 0 15px;
}
.banner-text p { 
    font-size: 12px;
    line-height: initial; 
}
.why-section {
    padding: 20px 5% 0;
}
.banner-gif .mac-gif { 
    width: 54%;
    right: 11.5%;
    top: 6%;
}
.product-hunt img {
    margin: 0px 0 0 10px;
    width: 150px;
}

  .virtual-images {
    margin-bottom: 10px;
}
.why-use .product-hunt img{
    margin: 20px 0 0 10px!important;
    width: 150px;
}
}

@media screen and (max-width: 900px) and (min-width: 768px){
.graph-area {
    padding: 0 0 60px!important;
}
.first-tab-content img {
    width: 100%!important;
    margin: 0 auto;
    margin-top: 30%!important;
}
.car-v2-left {
        order: 1!important; 
}
.right-sections{
    order: 2;
}
.graph-area .title{
    order: 1!important;
    padding-top: 8%;
    margin: 0;
}
.graph-image{
    order: 2;
}
.first-tab-content img {
    width: 85%;
    margin-top: 10%;
}
.cars-video{
    height: 350px!important;
}
.cars-demo {
    height: 350px!important; 
    margin-top: 0!important;
}
.calendly-area {
    padding: 60px 0px;
    margin-bottom: 0px;
}
.title-area p {
    display: none;
}
.banner-text {
    margin-top: 5%;
    text-align: left;
    order: inherit;
}
.banner-text .request-demo {
    margin: 0 0;
}
    
.virtual-tabs {
    /* margin-bottom: 10px; */
}
.graph-area .title {
    text-align: left!important; 
}
.banner-gif img { 
    margin-top: 15%;
}
.banner-text h1 { 
    font-size: 32px;
}
.banner-text p a img {
    width: 170px;
}
.car-v2-left p, .why-use p { 
    font-size: 13px;
    line-height: 20px;
}
.why-section {
    padding: 0 1%;
}
.product-hunt img {
    width: 155px;
    margin: 0px 0 0 10px!important;
}

.request-demo { 
    margin-bottom: 20px; 
}
.gif-area{
    padding: 0;
}

}


@media screen and (max-width: 1200px) and (min-width: 901px){
    .why-text p { 
        font-size: 12px;
        line-height: initial; 
}
.why-text h6 {
    font-size: 18px;
    line-height: initial;
}
.first-tab-content img {
    width: 75%;
}
.custom-links ul li {
    padding: 30px 20px 0;
}
.graph-area {
    padding: 0 0 50px;
}



}
