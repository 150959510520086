.beautification-image{

}

.beautification-image img{
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.upload-beauti p{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    color: #444444;
    margin: 0;
}
.dropzone-section{
    width: 100%;
    cursor: pointer;
}
.dropzone-section img{
    margin-bottom: 10px;
}
.dropzone-section p{
    font-weight: normal; 
    line-height: 25px;
    color: #000000; 
}
.category-options{

}
.category-options ul{
width: 100%;
display: flex;
list-style-type: none;
padding: 0;
}
.category-options ul li{
    margin-right: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    width: 50px;
    height: 45px;
    text-align: center;
}
.category-options ul li img{
    width: auto;
    height: auto;
    padding: 5px;
}
.category-options ul li button {
    background: #FFFFFF;
border: 1px solid #fff;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
padding:0;
width: 48px;
    height: 48px;
}

.category-options ul li button.active { 
border: 1px solid #FF3503; 
}

.mybtn{
    background: #FF3503;
border-radius: 20px;
width: 150px;
height: 45px;
}
.brush{
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval.png'), auto;
    
}

.brush1 {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(1).png'), auto;
}
.brush2 {
    cursor: url('https://storage.googleapis.com/spyne-website/static/website-themes/clippr/static/BackgroundReplacement/Model/oval%20(2).png'), auto;
}



 








