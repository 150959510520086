.credit-modal-text h4 {
  font-size: 24px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 10px;
}

.credit-modal-text p {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  margin-bottom: 10px;
  color: #5f5f5f;
  margin-top: 11px;
}

.credit-modal-text {
  margin-top: 0px;
}

.credit-modal-text button {
  border-radius: 26px;
  color: #fff;
  border: 1px solid #fff;
  background-color: #ff7700;
  margin: 20px 0px 0;
}

.credit-modal-text img {
  width: 60%;
  margin-top: 15px;
}

.credit-modal-text h6 {
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 0px;
  color: #000;
  margin-top: 10px;
}

@media (max-width: 768px) {
  /* .credit-modal-text h4 {
    font-size: 30px;
  }

  .credit-modal-text p {
    font-size: 20px;
  } */
}
